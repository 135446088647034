import React, { useState } from "react";
import {
  PracticeGuideHeaderContainer,
  PracticeGuideHeaderItem,
  PracticeGuideHeaderItemContainer,
  SearchButton,
  SearchInput,
} from "./styled";
import SearchIcon from "@material-ui/icons/Search";
import { FlexWrap, Pad } from "../../../../components";

export function PracticeGuideHeader({ selected, onSearch, onSelect }) {
  const [search, setSearch] = useState("");

  return (
    <PracticeGuideHeaderContainer>
      <PracticeGuideHeaderItemContainer>
        <PracticeGuideHeaderItem
          onClick={() => onSelect("albums")}
          active={selected === "albums"}
        >
          Album
        </PracticeGuideHeaderItem>
        <PracticeGuideHeaderItem
          onClick={() => onSelect("practiceGuides")}
          active={selected === "practiceGuides"}
        >
          Practice Guide
        </PracticeGuideHeaderItem>
        <PracticeGuideHeaderItem
          onClick={() => onSelect("tipsAndDrills")}
          active={selected === "tipsAndDrills"}
        >
          Tips & Drills
        </PracticeGuideHeaderItem>
      </PracticeGuideHeaderItemContainer>
      <FlexWrap width="fit-content">
        <SearchInput
          placeholder="Search"
          onChange={(e) => setSearch(e.target.value)}
          type="text"
        />
        <Pad width="10px" />
        <SearchButton onClick={() => onSearch(search)}>
          <SearchIcon style={{ color: "white" }} />
        </SearchButton>
      </FlexWrap>
    </PracticeGuideHeaderContainer>
  );
}
