import { Dialog, DialogContent } from "@material-ui/core";
import React from "react";
import { useEffect } from "react";
import { FlexWrap, Pad } from "../../../components";
import { FilledButton } from "./common/styled";
import { ModalTitle } from "./styled";

export function ExitDetailsModal({ open, onClose, onConfirm }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent style={{ padding: "30px" }}>
        <ModalTitle>Are you sure?</ModalTitle>
        <div>
          Are you sure you want to close this draft? You will lose your progress
        </div>
        <Pad height="20px" />
        <FlexWrap justify="space-between">
          <FilledButton onClick={() => onClose()} width="48%" color="#808080">
            cancel
          </FilledButton>
          <FilledButton onClick={() => onConfirm()} width="48%" color="#580CE3">
            close
          </FilledButton>
        </FlexWrap>
      </DialogContent>
    </Dialog>
  );
}
