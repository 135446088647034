import React, { useEffect, useState, useCallback } from "react";
import {
  Avatar,
  Button,
  CircularProgress,
  IconButton,
  Container,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  withStyles,
} from "@material-ui/core";
import AssignmentIcon from "@material-ui/icons/Assignment";
import PropTypes from "prop-types";
import { useStyles, theme } from "./config";
import { getReportByCoach, getReportData } from "./api/temp";
import { CoachReportData } from "./components/CoachReportData";
import { OverallReportData } from "./components/OverallReportData";
import { FlexWrap } from "../../components";
import { Bar } from "react-chartjs-2";
import { DateRangePicker } from "react-date-range";
import { DateRange } from "@material-ui/icons";
import {
  getFreeUsersReport,
  getInvitesReport,
  getUsersReport,
} from "./api/report";
import * as moment from "moment";

const Reports = (props) => {
  const [barData, setBarData] = useState([]);
  const [barSelect, setBarSelect] = useState("totalUsers");
  const [barUnitSelect, setBarUnitSelect] = useState("months");
  const [loading, setLoading] = useState(false);
  const [selectionRange, setSelectionRange] = useState({
    startDate: moment("01-01-2021").valueOf(),
    endDate: moment(Date.now()).valueOf(),
    key: "range1",
  });
  const [rangeModal, setRangeModal] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);

      const initFrom = moment("01-01-2021").valueOf();
      const initTo = moment(Date.now()).valueOf();

      const dateRange = dateSplitter(initFrom, initTo, "month");

      const data = await getUsersReport({
        dateRange,
        dateUnit: "month",
      });
      setBarData(objectMapper(data));
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (selectionRange.startDate && selectionRange.endDate) {
      const from = new Date(selectionRange.startDate).getTime();
      const to = new Date(selectionRange.endDate).getTime();

      const timeUnit = barUnitSelect;

      const result = dateSplitter(from, to, timeUnit);

      const fetch = async () => {
        let ret = null;
        switch (barSelect) {
          case "totalUsers": {
            const data = await getUsersReport({
              dateRange: result,
              dateUnit: timeUnit,
            });
            ret = objectMapper(data);
            break;
          }
          case "totalInvites": {
            const data = await getInvitesReport({
              dateRange: result,
              dateUnit: timeUnit,
              approved: false,
            });
            ret = objectMapper(data);
            break;
          }
          case "totalAcceptedInvites": {
            const data = await getInvitesReport({
              dateRange: result,
              dateUnit: timeUnit,
              approved: true,
            });
            ret = objectMapper(data);
            break;
          }
          case "totalFreeUsers": {
            const data = await getFreeUsersReport({
              dateRange: result,
              dateUnit: timeUnit,
            });
            ret = objectMapper(data);
            break;
          }
        }
        setBarData(ret);
      };
      fetch();
    }
  }, [selectionRange, barSelect, barUnitSelect]);

  const selectUnitValues = [
    { value: "months", label: "Months" },
    { value: "weeks", label: "Weeks" },
    { value: "days", label: "Days" },
    { value: "years", label: "Years" },
  ];

  const selectValues = [
    { value: "totalUsers", label: "Total Users" },
    { value: "totalFreeUsers", label: "Total Free Accounts" },
    { value: "totalInvites", label: "Total Invites" },
    { value: "totalAcceptedInvites", label: "Total Accepted Invites" },
  ];

  const chartLabels = {
    totalUsers: "Total Users",
    totalFreeUsers: "Total Free Accounts",
    totalInvites: "Total Invites",
    totalAcceptedInvites: "Total Accepted Invites",
  };

  const objectMapper = (dateArray) => {
    const colorArr = ["#9b6384", "#639b66", "#9b9663", "#639a9b", "#63649b"];

    //complex mapping
    const labelsArray = Object.keys(dateArray[Object.keys(dateArray)[0]]);
    const datasetsArray = Object.entries(dateArray).map((el, id) => {
      return {
        id,
        label: el[0]
          .split(/(?=[A-Z])/)
          .map((el) => el[0].toUpperCase() + el.slice(1))
          .join(" "),
        data: Object.values(el[1]),
        backgroundColor: colorArr[id],
        borderColor: colorArr[id],
      };
    });

    const obj = {
      labels: labelsArray,
      datasets: datasetsArray,
    };

    return obj;
  };

  const dateSplitter = (from, to, dateUnit) => {
    const timeUnit = dateUnit;

    let startDate = moment(from);
    let endDate = moment(to);

    let result = [];

    //   let isFirstIteration = true;

    while (startDate.isBefore(endDate)) {
      const from = startDate.clone().startOf(timeUnit).valueOf();
      const to = startDate.clone().endOf(timeUnit).valueOf();
      result.push({ from: from, to: to });
      startDate.add(1, timeUnit);
    }
    return result;
  };

  const data = {
    labels: ["Jun", "Jul", "Aug"],
    datasets: [
      {
        id: 1,
        label: "",
        data: [5, 6, 7],
      },
      {
        id: 2,
        label: "",
        data: [3, 2, 1],
      },
    ],
  };

  return (
    <>
      <div>
        <Container className={props.classes.paper} component="main">
          <CssBaseline />
          <div style={{ width: "100%" }} className={props.classes.paper}>
            <Avatar className={props.classes.paper}>
              <AssignmentIcon />
            </Avatar>
            <Typography
              component="h1"
              variant="h4"
              style={{ marginBottom: "10px" }}
            >
              REPORTS
            </Typography>
          </div>
          <FlexWrap width="100%">
            <FlexWrap justify="space-between">
              <FormControl style={{ width: "50%" }}>
                <InputLabel id="chartSelectLabel">Select Chart Data</InputLabel>
                <Select
                  labelId="chartSelectLabel"
                  id="chartSelect"
                  value={barSelect}
                  onChange={(e) => setBarSelect(e.target.value)}
                >
                  {selectValues.map((el) => (
                    <MenuItem value={el.value}>{el.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl style={{ width: "35%" }}>
                <InputLabel id="intervalSelectLabel">
                  Select Date Interval
                </InputLabel>
                <Select
                  labelId="intervalSelectLabel"
                  id="intervalSelect"
                  value={barUnitSelect}
                  onChange={(e) => setBarUnitSelect(e.target.value)}
                >
                  {selectUnitValues.map((el) => (
                    <MenuItem value={el.value}>{el.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <IconButton onClick={() => setRangeModal(true)}>
                <DateRange />
              </IconButton>
            </FlexWrap>
            <Grid item xs={12}>
              {!loading ? (
                <Bar
                  options={{
                    scales: {
                      yAxes: [
                        {
                          ticks: {
                            beginAtZero: true,
                            min: 0,
                          },
                        },
                      ],
                    },
                    title: {
                      display: true,
                      text: chartLabels[barSelect],
                      fontSize: "20",
                    },
                  }}
                  data={barData}
                />
              ) : (
                <div>loading</div>
              )}
            </Grid>
          </FlexWrap>
        </Container>
        <Dialog fullWidth open={rangeModal}>
          <DialogContent>
            <DateRangePicker
              ranges={[selectionRange]}
              onChange={(ranges) => setSelectionRange(ranges.range1)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setRangeModal(false)} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

Reports.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
};

export default withStyles(useStyles)(Reports);
