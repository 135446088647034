import React, { forwardRef, Component } from 'react';
import PropTypes from 'prop-types';
import firebase, { functionBaseUrl, axiosWithToken } from '../../common/firebase'
import { withStyles } from '@material-ui/styles';
import { createMuiTheme, MuiThemeProvider, Typography, CssBaseline, Container, Avatar, Grid, CircularProgress, Menu, Fade, MenuItem, Button } from '@material-ui/core';
import palette from '../../theme/palette';
import MaterialTable from 'material-table';
import { AccountNotFound } from '../account/components';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import { AddBox, ArrowDownward, Check, ChevronLeft, ChevronRight, Clear, DeleteOutline, Edit, FilterList, FirstPage, LastPage, Remove, SaveAlt, Search, SubdirectoryArrowLeftRounded, ViewColumn } from '@material-ui/icons';
import AddCoupon from './AddCoupon';
import Swal from 'sweetalert2';

const useStyles = theme => ({
  paper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    marginTop: theme.spacing(4),
  },
  buttonLink: {  // This is a link component surrounding a button component
    width: '100%'
  },
  linkedButton: {  // This is a button component surrounded by a link
    margin: theme.spacing(1, 0),
  },
  createButton: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginTop: theme.spacing(4),
  },
});

const theme = createMuiTheme({
  typography: {
    h6: {
      fontWeight: 500,
      fontSize: '20px',
      letterSpacing: '-0.05px',
      lineHeight: '20px',
    }
  },
  palette: {
    secondary: {
      main: palette.primary.main,
    },
  },
})

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const tableHeads = {
  promoCodes: [
    {field: 'code', title: 'Code'},
    {field: 'createdAt', title: 'Created Date', defaultSort: 'desc'},
    {field: 'expiredAt', title: 'Expiry Date'},
    {field: 'type', title: 'Type'},
    {field: 'discount', title: 'Discount'},
    {field: 'applicability', title: 'Applicability'},
    {field: 'onlyForFirstTimeUsers', title: 'Only For First Time Users'},
  ],
}

class Coupons extends Component {
  constructor(props) {
    const firebaseUser = firebase.auth().currentUser
    super(props)
    this.state = {
      uid : firebaseUser.uid,
      error : "",
      loading : true,
      role : '',
      promoCodes : [],
      popUpOpen : false,
      selectedRows : {
        promoCodes : []
      },
      deleteMenu: {
        anchor: null,
        type: ''
      },
    }
  }

  componentDidMount() {
    if (!this.state.uid) {   
      this.setState({error : "Please log in to view coupons"})
      this.setState({loading : false})  
    }
    else {
      axiosWithToken(functionBaseUrl + '/api/users/' + this.state.uid, {
        method: 'get',
      }).then(response => {
        var role = response.data.role;
        this.setState({ role: role })
        if (!["admin", "data-admin"].includes(role)) {
          this.setState({ loading: false, error: "You are not authorized to access this page." });
        }

        axiosWithToken(functionBaseUrl + '/api/promoCode', {
          method: 'get',
        }).then(response => {
          this.setState({ promoCodes: response.data.data, loading: false })
        })
      })
    }
  }

  dateFormatter = (date) => {
    let dd = new Date(date * 1)
    //return dd.toLocaleDateString()
    let res = dd.toJSON()
    if (res) {
      res = res.substr(0, 10)
      res = res.replace(/(\d{4})-(\d{1,2})-(\d{1,2})/, function (match, y, m, d) {
        return m + '/' + d + '/' + y;
      });
    }
    return res
  }

  titleCase = (str) => {
    if(str) {
      str = str.replaceAll('-', ' ');
      var splitStr = str.toLowerCase().split(' ');
      for (var i = 0; i < splitStr.length; i++) {
          splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
      }
      return splitStr.join(' '); 
    } else {
      return ""
    }
  }

  discountedPlans = (plans) => {
    let res = []
    if(plans.length > 0) {
      for(const str of plans) {
        str = str.replaceAll('_', ' ');
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
        }
        res.push(' ' + splitStr.join(' '))
      }
      return res.toString()
    } else {
      return ""
    }
  }

  closePopUp = () => {
    this.setState({ popUpOpen: false })
  }

  openPopUp = () => {
    if(this.state.role === 'admin') {
      this.setState({ popUpOpen: true })
      return false;
    }
  }

  getTableHeader = (name) => {
    return tableHeads[name];
  }

  handleDelete = (type) => (event, data) => {
    this.setState({deleteMenu: {anchor: event.currentTarget, type: type}, selectedRows: {...this.state.selectedRows, [type]: data}})
  }

  deleteCoupon = () => {
    const ids = this.state.selectedRows.promoCodes.map((item) => item.id)
    for(const id of ids) {
      axiosWithToken(functionBaseUrl + '/api/promoCode/' + id, {
        method: 'delete',
      })
      .catch(err => {
        console.log(err)
        Swal.fire({
          title: '<p style="font-size:70%;">There was an error, please try again!</p>',
          icon: 'error',
          allowOutsideClick: true,
          timer: 2000
        })
      });
    }
    Swal.fire({
      title: '<p style="font-size:70%;">Coupon(s) successfully removed</p>',
      icon: 'success',
      confirmButtonText: 'Ok',
      allowOutsideClick: true,
      timer: 2000
    }).then(() => {
      window.location.reload()
    })
  }

  render() {
    const classes = this.props.classes;
    let promoCodesData = [];
    if (this.state.promoCodes) {
      const keys = Object.keys(this.state.promoCodes)
      promoCodesData = keys.map((key) => {
        if (this.state.promoCodes[key]) {
          const pc = this.state.promoCodes[key];
          const out = {
            id: pc.id,
            code: pc.code,
            createdAt: this.dateFormatter(pc.createdAt),
            expiredAt: this.dateFormatter(pc.expiredAt),
            type: this.titleCase(pc.type),
            discount: pc.discount,
            applicability: this.discountedPlans(pc.applicability),
            onlyForFirstTimeUsers: pc.onlyForFirstTimeUsers,
          }
          return out;
        }
        return null;
      })
    }

    const tableElements = [];
    tableElements.push(
      <div style={{width: '100%'}}>
        <MaterialTable
          style={{ marginTop: '10px', marginBottom: '10px' }}
          icons={tableIcons}
          key='promoCodesTable'
          title='Coupons'
          columns={this.getTableHeader('promoCodes')}
          data={promoCodesData.map(row => this.state.selectedRows.promoCodes.find(selected => selected.id === row.id) ? { ...row, tableData: { checked: true } } : row)}
          options={{
            selection: true,
            sorting: true,
          }}
          actions={[{
            tooltip: 'Delete selected coupon(s)',
            icon: tableIcons.Delete,
            onClick: this.handleDelete('promoCodes')
          }]}
        />
      </div>
    )

    /*if (tableElements.length < 1) {
      //return (<Typography align='center' style={{marginTop: '20px'}}>There are no items to display</Typography>)
    }*/

    return (<div >
      <Container
        className={classes.paper}
        component="main">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LocalOfferIcon />
          </Avatar>
          <Typography
            component="h1"
            variant="h4"
            style={{ marginBottom: '10px' }}
          >
            Coupons
          </Typography>
        </div>
        <div className={this.props.classes.createButton}>
          <Button
            variant="contained"
            color="primary"
            className={this.props.classes.linkedButton}
            onClick={this.openPopUp}
          >
            CREATE A NEW COUPON CODE
          </Button>
          <AddCoupon
            open={this.state.popUpOpen}
            onClose={this.closePopUp}
          />
        </div>
        <div style={{width: '100%'}}>
          <MuiThemeProvider theme={theme}>
            {tableElements}
            <Menu
              id="menu"
              anchorEl={this.state.deleteMenu.anchor}
              keepMounted
              open={Boolean(this.state.deleteMenu.anchor)}
              onClose={() => {this.setState({deleteMenu: {anchor: null, type: ''}})}}
              TransitionComponent={Fade}
            >
              <MenuItem onClick={this.deleteCoupon}>Delete</MenuItem>
            </Menu>

            {/* Loading screen*/}
            <Grid
              style={{ margin: "auto", padding: 100, display: (this.state.loading) ? "block" : "none" }}
            >
              <CircularProgress />
            </Grid>
            {/* Error screen*/}
            <Grid
              item
              lg={12}
              md={12}
              xl={12}
              xs={12}
              style={{ display: (this.state.error && !this.state.loading) ? "block" : "none" }}
            >
              <AccountNotFound
                error={{ error: this.state.error }}
              />
            </Grid>
          </MuiThemeProvider>
        </div>
      </Container>
    </div>
    )
  }
}

Coupons.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
};

export default withStyles(useStyles)(Coupons);