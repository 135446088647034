import React, {Component} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import firebase from '../../common/firebase'
import {withStyles} from '@material-ui/styles';
import {Grid, Button, Container, Avatar, Link, Typography,
  CssBaseline, CircularProgress} from '@material-ui/core';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AssignmentIcon from '@material-ui/icons/Assignment';
import {AccountNotFound} from '../account/components';
import * as admin from 'firebase/app';
import 'firebase/firestore';
import DisplayLabReqTable from './components/display/DisplayLabReqTable';
import DisplayLabJobTable from './components/display/DisplayLabJobTable';
import DisplayLabResTable from './components/display/DisplayLabResTable';

const useStyles = theme => ({
  tables: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    marginTop: theme.spacing(4),
  },
  buttonLink: {  // This is a link component surrounding a button component
    width: '100%',
    textAlign: 'center'
  },
  linkedButton: {  // This is a button component surrounded by a link
    margin: theme.spacing(1, 0),
  },
  centeredText: {
    textAlign: 'center',
  }
});

class Labeling extends Component {
  constructor(props) {
    const firebaseUser = firebase.auth().currentUser
    super(props)
    this.state = {
      uid : firebaseUser ? firebaseUser.uid : "",
      error : "",
      loading : true,
      success : false,
      labelingRequests : [],
      labelingJobs : [],
      labelingResults : []
    }
  }

  componentDidMount() {
    if (!this.state.uid) {   
      this.setState({error : "Please log in to view your labeling requests"})
      this.setState({loading : false})  
    }
    else {
        // get all the labeling requests
        let tempLabReqs = []
        admin.firestore().collection('labelingRequests').get().then(snapshot => {
            snapshot.forEach(function(doc) {
                tempLabReqs.push({...doc.data(), id: doc.id});
            });
            this.setState({ labelingRequests: tempLabReqs });
        })
        .catch(err => {
            console.log('Error getting documents', err);
        });

        // get all the labeling jobs
        let tempLabJobs = []
        admin.firestore().collection('labelingJobs').get().then(snapshot => {
            snapshot.forEach(function(doc) {
                tempLabJobs.push({...doc.data(), id: doc.id});
            });
            this.setState({ labelingJobs: tempLabJobs });
        })
        .catch(err => {
            console.log('Error getting documents', err);
        });

        // get all the labelled results
        let tempLabRes = []
        admin.firestore().collection('labelingResults').get().then(snapshot => {
            snapshot.forEach(function(doc) {
                tempLabRes.push({...doc.data(), id: doc.id});
            });
            this.setState({ labelingResults: tempLabRes });
        })
        .catch(err => {
            console.log('Error getting documents', err);
        });

        // set loading state to false so screens are displayed
        this.setState({
          loading : false
        })
    }    
  }

  render() {
    const classes = this.props.classes;
    return (<div>
        <Container
            className={classes.tables}
            component="main">
            <CssBaseline />
            <div className={classes.tables}>
                <Avatar className={classes.avatar}>
                    <AssignmentIndIcon/>
                </Avatar>
                <Typography
                    component="h1"
                    variant="h4"
                    style={{marginBottom: '10px'}}
                >
                    Labeling Requests
                </Typography>
                <Link className={this.props.classes.buttonLink} component={RouterLink} to="/create-labelingRequest">
                    <Button
                        variant="contained"
                        color="primary"
                        className={this.props.classes.linkedButton}
                        onClick={() => {
                            this.setState({ success: false })
                        }}
                    >
                        CREATE NEW LABELING REQUEST
                    </Button>
                </Link>
                <div style={{width: '80%'}}>
                    <DisplayLabReqTable labelingRequests={this.state.labelingRequests}/>
                </div>
            </div>
        </Container>

        <Container
            className={classes.tables}
            component="main">
            <CssBaseline />
            <div className={classes.tables}>
                <Avatar className={classes.avatar}>
                    <AssessmentIcon/>
                </Avatar>
                <Typography
                    component="h1"
                    variant="h4"
                    style={{marginBottom: '10px'}}
                >
                    Labeling Jobs
                </Typography>
                <Link className={this.props.classes.buttonLink} component={RouterLink} to="/create-labelingJob">
                    <Button
                        variant="contained"
                        color="primary"
                        className={this.props.classes.linkedButton}
                        onClick={() => {
                            this.setState({ success: false })
                        }}
                    >
                        CREATE NEW LABELING JOB
                    </Button>
                </Link>
                <div style={{width: '80%'}}>
                    <DisplayLabJobTable labelingJobs={this.state.labelingJobs}/>
                </div>
            </div>
        </Container>

        <Container
            className={classes.tables}
            component="main">
            <CssBaseline />
            <div className={classes.tables}>
                <Avatar className={classes.avatar}>
                    <AssignmentIcon/>
                </Avatar>
                <Typography
                    component="h1"
                    variant="h4"
                    style={{marginBottom: '10px'}}
                >
                    Labelled Results
                </Typography>
                <div style={{width: '80%'}}>
                    <DisplayLabResTable labelingResults={this.state.labelingResults}/>
                </div>
            </div>
        </Container>

        {/* Loading screen*/}
        <Grid
            style = {{margin : "auto", padding : 100, display: (this.state.loading) ? "block" : "none"}}
        >
            <CircularProgress />
        </Grid>

        {/* Error screen*/}
        <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
            style = {{display: (this.state.error && !this.state.loading) ? "block" : "none"}}
        >
            <AccountNotFound            
            error = {{error : this.state.error}}
            />
        </Grid>
    </div>
    );
  }
};

Labeling.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
};

export default withStyles(useStyles)(Labeling);