import React, { Component } from 'react';
import 'firebase/firestore';
import Devices from './Devices';
import ManageSession from './ManageSession'


class DevicesController extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pairing: 0,
            devices: [],
            connectedDev: [],
            swings: 0, 
            selectedDevice: '',
            selectedClub: '',
        }
    }

    pageController = () => { //controls page renders 
        if (this.state.pairing == 0) { //changes pages based on pairing state 
            return(
                <div>
                    <Devices    
                        connectedDev={this.state.connectedDev}
                        setConnectedDev={this.setConnectedDev}
                        setPairing={this.setPairing}
                    />
                </div>
            )
        } else if (this.state.pairing == 1) {
            return (
                <div>
                    <ManageSession
                        connectedDev={this.state.connectedDev}
                        setConnectedDev={this.setConnectedDev}
                        setPairing={this.setPairing}
                        swings={this.state.swings}
                        selectedDevice={this.state.selectedDevice}
                        selectedClub={this.state.selectedClub}
                    />
                </div>
            )
        }
    }

    setConnectedDev = (inputDev) => {
        this.setState({
            connectedDev: inputDev
        })
    }

    setPairing = (input) => {
        this.setState({
            pairing: input
        })
        console.log(input)
        
    }

    setSwings = (input) => {
        this.setState({
            swings: input
        })
    }

    setSelectedDevice = (input) => {
        this.setState({
            selectedDevice: input
        })
    }

    setSelectedClub = (input) => {
        this.setState({
            selectedClub: input
        })
    }
   

    render() {
        console.log(this.state)
        return (
            this.pageController()
        )
    }
    
};

export default DevicesController;