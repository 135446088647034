import React from "react";
import { Select, MenuItem } from "@material-ui/core";

export function AccessLevelSelect({ value, onChange }) {
    const levelSelectLabels = {
        Internal: "Internal",
        Public: "Public",
        Private: "Private",
    };

    const handleOnChange = (e) => {
        onChange(e.target.value, "accessLevel");
    };

    return (
        <Select
            labelId="accessLevelSelect"
            name="accessLevelSelect"
            onChange={handleOnChange}
            value={value}
        >
            <MenuItem value="Internal">{levelSelectLabels.Internal}</MenuItem>
            <MenuItem value="Public">{levelSelectLabels.Public}</MenuItem>
            <MenuItem value="Private">{levelSelectLabels.Private}</MenuItem>
        </Select>
    );
}
