import { Dialog, DialogContent } from "@material-ui/core";
import React, { useState } from "react";
import { useEffect } from "react";
import { FlexWrap, Pad } from "../../../../components";
import { hardcodedData } from "../../config";
import { ModalTitle } from "../styled";
import { CustomRadio } from "./CustomRadio";
import { FilledButton, TagContainer, TagsModalItem } from "./styled";
import ClearIcon from "@material-ui/icons/Clear";

export const Tags = ({ form, onSave }) => {
  const [selected, setSelected] = useState([]);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (form.length !== 0) {
      setSelected(form);
    }
  }, [form]);

  const handleSelect = (id) => {
    if (selected.includes(id)) {
      setSelected((prev) => prev.filter((el) => el !== id));
    } else {
      setSelected((prev) => [...prev, id]);
    }
  };

  const handleClose = () => {
    setSelected([]);
    setModal(false);
  };

  const handleSave = () => {
    onSave("tags", selected);
    handleClose(false);
  };

  return (
    <>
      <div>
        {form.length === 0 ? (
          <TagContainer button onClick={() => setModal(true)}>
            Add
          </TagContainer>
        ) : (
          <FlexWrap justify="start">
            {form.map((el) => (
              <>
                <TagContainer button={false}>{el}</TagContainer>
                <Pad width="10px" />
              </>
            ))}
            <TagContainer button onClick={() => setModal(true)}>
              Add
            </TagContainer>
          </FlexWrap>
        )}
      </div>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={modal}
        onClose={() => setModal(false)}
      >
        <DialogContent style={{ padding: "30px" }}>
          <ModalTitle>Tags</ModalTitle>
          {hardcodedData.tags.map((el) => (
            <>
              <TagsModalItem onClick={() => handleSelect(el)}>
                <div>{el}</div>
                <CustomRadio active={selected.includes(el)} />
              </TagsModalItem>
              <Pad height="10px" />
            </>
          ))}
          <FlexWrap justify="space-between">
            <FilledButton onClick={handleClose} width="48%" color="#808080">
              cancel
            </FilledButton>
            <FilledButton
              onClick={() => handleSave()}
              width="48%"
              color="#580CE3"
            >
              add
            </FilledButton>
          </FlexWrap>
        </DialogContent>
      </Dialog>
    </>
  );
};
