import React, { useState } from "react";
import {
  HiddenFileInput,
  MediaNotUploaded,
  MediaUploadButton,
  ModalContentTitle,
  UpdateImageButton,
} from "../styled";
import EditIcon from "@material-ui/icons/Edit";
import { useEffect } from "react";
import { PreviewButton, UploadedVideoContainer } from "./styled";
import { useRef } from "react";

export function TipsAndDrillsMediaUpload({ uploadFn, onMetadata, media }) {
  const UploadedVideo = ({ src, name }) => {
    const videoRef = useRef();

    const onLoadedMetadata = () => {
      if (videoRef.current) {
        onMetadata(name, Math.floor(videoRef.current.duration));
      }
    };

    return (
      <div style={{ width: "100%", position: "relative" }}>
        <video
          ref={videoRef}
          onLoadedMetadata={onLoadedMetadata}
          style={{ background: "black" }}
          width="276"
          height="360"
        >
          <source src={src} type="video/webm" />
        </video>

        <UpdateImageButton>
          <label
            style={{ cursor: "pointer" }}
            htmlFor={`hidden-${name}-reupload`}
          >
            <EditIcon
              style={{
                fontSize: "20px",
                margin: "3px 0 0 2px",
                pointerEvents: "none",
                cursor: "pointer",
              }}
            />
          </label>
        </UpdateImageButton>
        <HiddenFileInput
          id={`hidden-${name}-reupload`}
          name={name}
          type="file"
          accept="video/*"
          onChange={uploadFn}
        />
      </div>
    );
  };

  const UploadedImage = ({ src, name }) => {
    return (
      <div style={{ width: "100%", position: "relative" }}>
        <img style={{ minWidth: "100%", maxWidth: "100%" }} src={src} />

        <UpdateImageButton>
          <label
            style={{ cursor: "pointer" }}
            htmlFor={`hidden-${name}-reupload`}
          >
            <EditIcon
              style={{
                fontSize: "20px",
                margin: "3px 0 0 2px",
                pointerEvents: "none",
                cursor: "pointer",
              }}
            />
          </label>
        </UpdateImageButton>
        <HiddenFileInput
          id={`hidden-${name}-reupload`}
          name={name}
          type="file"
          accept="image/*"
          onChange={uploadFn}
        />
      </div>
    );
  };

  return (
    <>
      <ModalContentTitle>Instructional Video</ModalContentTitle>
      {!media.instructionalVideo.src ? (
        <MediaNotUploaded height="320px">
          <MediaUploadButton htmlFor="hidden-instructionalVideo-upload">
            UPLOAD
          </MediaUploadButton>
          <HiddenFileInput
            id="hidden-instructionalVideo-upload"
            name="instructionalVideo"
            type="file"
            accept="video/*"
            onChange={uploadFn}
          />
        </MediaNotUploaded>
      ) : (
        <UploadedVideo
          name="instructionalVideo"
          src={media.instructionalVideo.src}
        />
      )}
      {media.instructionalVideo.src ? (
        <>
          <ModalContentTitle>Instructional Video Thumbnail</ModalContentTitle>
          {!media.instructionalVideoThumbnail.src ? (
            <MediaNotUploaded height="142px">
              <MediaUploadButton htmlFor="hidden-instructionalVideoThumbnail-upload">
                UPLOAD
              </MediaUploadButton>
              <HiddenFileInput
                id="hidden-instructionalVideoThumbnail-upload"
                name="instructionalVideoThumbnail"
                type="file"
                accept="image/*"
                onChange={uploadFn}
              />
            </MediaNotUploaded>
          ) : (
            <UploadedImage
              name="instructionalVideoThumbnail"
              src={media.instructionalVideoThumbnail.src}
            />
          )}
        </>
      ) : (
        ""
      )}

      <ModalContentTitle>Rep Loop Video</ModalContentTitle>
      {!media.repVideo.src ? (
        <MediaNotUploaded height="320px">
          <MediaUploadButton htmlFor="hidden-repVideo-upload">
            UPLOAD
          </MediaUploadButton>
          <HiddenFileInput
            id="hidden-repVideo-upload"
            name="repVideo"
            type="file"
            accept="video/*"
            onChange={uploadFn}
          />
        </MediaNotUploaded>
      ) : (
        <UploadedVideo name="repVideo" src={media.repVideo.src} />
      )}
    </>
  );
}
