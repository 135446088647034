import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import React, { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { FlexWrap, Pad } from "../../../../components";
import { getPublicTipsAndDrills, getTipsAndDrills } from "../../api_test";
import {
  FilledButton,
  OutlinedButton,
  SearchButton,
  SearchInput,
} from "../common/styled";
import { ModalTitle } from "../styled";
import { TipsAndDrillsModalChoice } from "./TipsAndDrillsModalChoice";
import SearchIcon from "@material-ui/icons/Search";
import { searchItems, searchPublishedItems } from "../../api_test/common";

export function PracticeGuideSummaryModal({ open, type, onClose, onSave }) {
  const [selected, setSelected] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [search, setSearch] = useState("");

  const handleSearch = useCallback(async () => {
    const data = await searchPublishedItems("tipsAndDrills", search);
    setTableData(data);
  }, [search]);

  useEffect(() => {
    const fetch = async () => {
      const data = await getPublicTipsAndDrills();
      setTableData(data);
    };
    fetch();
  }, []);

  const handleSelect = (id) => {
    if (selected.includes(id)) {
      setSelected((prev) => prev.filter((el) => el !== id));
    } else {
      setSelected((prev) => [...prev, id]);
    }
  };

  const handleClose = () => {
    onClose();
    setSelected([]);
  };

  const handleSave = () => {
    onSave(type, selected);
    onClose();
    setSelected([]);
  };

  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="false"
      open={open}
      onClose={handleClose}
    >
      <div style={{ width: "800px" }}>
        <DialogContent style={{ padding: "30px" }}>
          <ModalTitle>Select drill</ModalTitle>
          <FlexWrap width="fit-content">
            <SearchInput
              placeholder="Search"
              onChange={(e) => setSearch(e.target.value)}
              type="text"
            />
            <Pad width="10px" />
            <SearchButton onClick={() => handleSearch()}>
              <SearchIcon style={{ color: "white" }} />
            </SearchButton>
          </FlexWrap>
          {/* <TextField fullWidth variant="outlined" placeholder="Search" />
        <Pad height="10px" />
        <FlexWrap justify="start">
        <OutlinedButton color="#580CE3">All</OutlinedButton>
        <Pad width="10px" />
        <OutlinedButton color="#580CE3">Warm-up</OutlinedButton>
        <Pad width="10px" />
        <OutlinedButton color="#580CE3">Intro</OutlinedButton>
        <Pad width="10px" />
          <OutlinedButton color="#580CE3">Drill</OutlinedButton>
        </FlexWrap> */}
          <Pad height="10px" />
          <div
            style={{
              padding: "5px",
              overflowY: "scroll",
              maxHeight: "600px",
            }}
          >
            {tableData.map((el) => (
              <>
                <TipsAndDrillsModalChoice
                  data={el}
                  onClick={handleSelect}
                  active={selected.includes(el.id)}
                />
                <Pad height="10px" />
              </>
            ))}
          </div>
          <Pad height="10px" />
          <FlexWrap justify="space-between">
            <FilledButton onClick={handleClose} width="48%" color="#808080">
              cancel
            </FilledButton>
            <FilledButton onClick={handleSave} width="48%" color="#580CE3">
              add
            </FilledButton>
          </FlexWrap>
        </DialogContent>
      </div>
    </Dialog>
  );
}
