/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Chart } from 'react-chartjs-2';
import { ThemeProvider } from '@material-ui/styles';
import validate from 'validate.js';
import moment from 'moment';

import { chartjs } from './helpers';
import theme from './theme';
import validators from './common/validators';
import Routes from './Routes';
import firebase from './common/firebase';
import Analytics from './components/analytics';
import { getCookie, setCookie } from './helpers/cookie';

import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.css';

const browserHistory = createBrowserHistory();

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw,
});

validate.validators = {
  ...validate.validators,
  ...validators,
};

/*setInterval(() => {
  const start = getCookie('sportsbox-login-timestamp');
  const now = new Date();
  if (start) {
    if (-moment.duration(moment(parseInt(start)).diff(now)).asDays() > 1) {
      firebase.auth().signOut().then(() => {
        setCookie('sportsbox-login-timestamp', '');
        console.log("logged out" + firebase.auth().currentUser);
      }).catch((error) => {
        console.log(error);
      });
    }
  } else {
    setCookie('sportsbox-login-timestamp', now.getTime());
  }
}, 60000);*/

export default class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <Router history={browserHistory}>
          <Analytics />
          <Routes />
        </Router>
      </ThemeProvider>
    );
  }
}
