import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { AlbumPracticeGuideItem } from "./styled";
import { Pad } from "../../../../components";
import DeleteIcon from "@material-ui/icons/Delete";
import { format } from "date-fns";

export function FilledPracticeGuide({ items, onRemove, onReorder }) {
  const [itemsState, setItemsState] = useState(items);

  useEffect(() => {
    setItemsState(items);
  }, [items]);

  const onDragEnd = (result) => {
    const arr = Array.from(itemsState);
    const startIndex = result.source.index;
    const endIndex = result.destination.index;
    const [removed] = arr.splice(startIndex, 1);
    arr.splice(endIndex, 0, removed);
    setItemsState(arr);
    onReorder(arr.map((el) => el.id));
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={`album-practice-guide-list`}>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {itemsState.map((el, index) => {
              return (
                <Draggable key={el.id} draggableId={el.id} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <AlbumPracticeGuideItem>
                        <img src={el.coverImage} height="87px" width="132px" />
                        <div>{el.title}</div>
                        <div>{format(el.createdAt, "MM/dd/yyyy")}</div>
                        <DeleteIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => onRemove(el.id)}
                        />
                      </AlbumPracticeGuideItem>
                      <Pad height="10px" />
                    </div>
                  )}
                </Draggable>
              );
            })}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
