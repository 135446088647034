import React, { Component } from 'react';

import { withStyles } from '@material-ui/styles';
import { Container, CircularProgress} from '@material-ui/core';
import firebase, { axiosWithToken } from '../../common/firebase'
import { functionBaseUrl } from '../../common/firebase'
import NoSubscriptionDialog from '../../components/NoSubscriptionDialog';
import Search from './Search';
import { elasticIndex, generatePit, getMappings, pitLifespan, queryElastic, reportIndex } from '../../common/elastic';

const useStyles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  dialog: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  icon: {
    float: 'right',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});

class SearchController extends Component{
  constructor(props){
    const firebaseUser = firebase.auth().currentUser
    super(props);
    this.state = {
      uid : firebaseUser.uid,
      role : "",
      user: null,
      loading: true,
      popUpOpen: false,
      searchType: 'search',
      queryResults: {},
      cameraSetups: {},
      sessionEnvs: {},
      sessionTypes: {},
      users: {},
      sessions: {},
      videos: {},
      userActivities: {},
      userInfo: {},
      subData: {},
      subscriptionType: '',
      activeSub: true,
      isStaffEnterprise: false,
      isCertified: true,
      onboardingCompleted: true,
      dialogLoading: true,
      userData: {
        role: '',
        uid: '',
        pit: '',
        subscriptionType: '',
        subData: {}
      },
      mappings: {},
      noSubscription: false,
    }
    getMappings(reportIndex).then(mappings => {
      if (mappings)
        this.setState({reportMappings: mappings[Object.keys(mappings)[0]].mappings.properties})
    })
  }
  refreshPit = async (index) => {
    const pit = await generatePit(index || elasticIndex, pitLifespan, this.state.pit)
    if (pit) {
      if (index === reportIndex) {
        this.setState({reportPit: pit})
      } else {
        this.setState({pit: pit})}
      return true
    }
    return false
  }
  // Submit elastic query to firebase with pit. If the pit is expired 
  // also update the stored pit and pass the error down
  queryElasticwPit = async (config, pit) => {
    if (!pit && config.target === 'reportIssues') {
      pit = this.state.reportPit
    } else if (!pit) {
      pit = this.state.pit
    }
    config.pit = {id: pit, keep_alive: pitLifespan}
    return await queryElastic(config).then(res => {
      return res
    }).catch( (error) => {
      if (error.response && error.response.data.message.includes('pit_expired')) {
        if (!pit && config.isReport) {
          this.setState({reportPit: error.response.data.pit})
        } else if (!pit) {
          this.setState({pit: error.response.data.pit})
        }
        this.setState({pit: error.response.data.pit})
      }
      throw error
    })
  }

  async componentDidMount () {

    const mappings = await getMappings()
    if (mappings) {
      this.setState({mappings: mappings[Object.keys(mappings)[0]].mappings.properties})
    }
    if (!this.state.uid) {   
      this.setState({error : "Please log in"})
    }
    else {
      const user = await firebase.firestore().collection("users").doc(this.state.uid).get();
      if (!user) {
        console.log("Cannot find the user document")
        this.setState({ loading: false, error: "There was an error retrieving your info" });
      }
      let data = user.data()

      let userData = {
        role: data.role, 
        uid: this.state.uid, 
        enterpriseAccountId: data.enterpriseAccountId,
        subscriptionType: data.subscriptionType,
        students: data.students,
        coaches: data.coaches}
      this.setState({ userData: userData, role: data.role, subscriptionType: data.subscriptionType })
      if (!data.subscriptionType.includes('free')) {
        await axiosWithToken(functionBaseUrl+'/api/verifySub/' + this.state.uid, {
          method: 'post'
        }).then(response => {
          if (data.role !== 'admin' && response.data.success === false) {
            this.setState({ noSubscription: true });
          } else {
            userData.subData = response.data.data
            this.setState({
              userData: userData, loading: false
            })
          }
        })
        .catch(err => {
          console.log(err)
        });
      }
      if (data.enterpriseAccountId && data.enterpriseAccountId) {
        const entUser = await firebase.firestore().collection("enterpriseAccounts").doc(data.enterpriseAccountId).get();
        const entData = entUser.data();
        /*if (entData && 'autoRenewal' in entData && entData.autoRenewal === false) {
          this.setState({ isStaffEnterprise: true })
          await axiosWithToken(functionBaseUrl + '/api/teachableUser/' + this.state.uid, {
            method: 'GET',
          })
            .catch(err => {
              console.log(err)
            });
        }
        if (data.certification && (data.certification.includes('Sportsbox') || data.certification.includes('Sportsbox 3D Golf Level 1 Certification'))) {
          this.setState({ isCertified: true })
        } else {
          this.setState({ isCertified: false })
        }
        if ('onboardingCompleted' in entData) {
          this.setState({ onboardingCompleted: entData.onboardingCompleted })
        }*/
      }
      this.setState({ dialogLoading: false })

      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          this.setState({userData: userData, loading: false });
        }
      });
    }
  }
  
  subscriptionStatus = async () => {
    await axiosWithToken(functionBaseUrl+'/api/verifySub/' + this.state.uid, {
      method: 'post'
    }).then(response => {
      this.setState({
        subData: response.data.data, loading: false
      })
      if (this.state.role !== 'admin' && response.data.success === false) {
        //if (JSON.stringify(response.data.data) === '{}') {
          alert("You do not have an active subscription. Please choose a plan to access Sportsbox 3D Golf features")
          this.props.history.push('/plans')
        //}
      }
    })
    .catch(err => {
      console.log(err)
    });
  }

  handleChange = (prop) => (event, option) => {
    const value = event.target.value || event.target.checked || option || '';
    this.setState({[prop]:value});
  }
  handlePopUpOpen = () => {
    this.setState({popUpOpen: true});
  }
  handlePopUpClose = () => {
    this.setState({popUpOpen : false});
  }
  handleCloseFilter = () => {
    this.setState({similarities: {}});
  }
  render() {
    const classes = this.props.classes;
    const searchActive = this.state.similarities && Object.keys(this.state.similarities).length > 0;
    return !this.state.loading ? (
      <>
        <Search
          openPopUp={this.handlePopUpOpen}
          closePopUp={this.handlePopUpClose}
          handleChange={this.handleChange}
          values={this.state}
          searchActive={searchActive}
          handleCloseFilter={this.handleCloseFilter}
          queryElasticwPit={this.queryElasticwPit}
          classes={this.props.classes}
          refreshPit={this.refreshPit}
        />
        {this.state.noSubscription && (<NoSubscriptionDialog />)}
      </>
  ) : (<Container
    className={classes.paper}
    component="main">
      <CircularProgress style = {{display: (this.state.loading) ? "block" : "none"}}/>
    </Container>);
  }
}

export default withStyles(useStyles)(SearchController);