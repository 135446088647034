import styled from "styled-components";

export const StatusBadge = styled.div`
  background: ${({ status }) =>
    status === "private" || status === "archived" ? "#d9d9d9" : "#A12AEE"};
  color: ${({ status }) =>
    status === "private" || status === "archived" ? "#000" : "#fff"};
  text-transform: capitalize;
  border-radius: 20px;
  width: fit-content;
  padding: 5px 20px;
`;

export const PracticeGuideRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.25);
  margin-bottom: 12px;
  padding: 14px;
`;

export const TableRowItemWrapper = styled.div`
  min-width: ${({ width }) => width};
  max-width: ${({ width }) => width};
`;

export const ActionsContainer = styled.div`
  width: 100px;
  display: flex;
  justify-content: start;
  align-items: center;
`;

export const PracticeGuideDetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
`;

export const PracticeGuideFormColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 65%;
  padding: 10px;
`;

export const PracticeGuideMediaColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
`;

export const EmptySelector = styled.div`
  width: 100%;
  padding: 20px;
  border-radius: 5px;
  border: 1px dashed #a12aee;
  display: flex;
  align-items: center;
  color: #a12aee;
  cursor: pointer;
`;

export const NewItemButton = styled.button`
  margin-left: auto;
  padding: 12px 16px;
  border-radius: 40px;
  background-color: #a12aee;
  cursor: pointer;
  text-transform: uppercase;
  border: none;
  color: #fff;
`;
