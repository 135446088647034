import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import { Divider, Drawer } from "@material-ui/core";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import ReceiptIcon from "@material-ui/icons/Receipt";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import HelpIcon from "@material-ui/icons/Help";
import BackupIcon from "@material-ui/icons/Backup";
import TimelineIcon from "@material-ui/icons/Timeline";
import SearchIcon from "@material-ui/icons/Search";
//import BlurLinearIcon from '@material-ui/icons/BlurLinear';
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
//import ReportIcon from '@material-ui/icons/Report';
import PeopleIcon from "@material-ui/icons/People";
import NotificationsIcon from "@material-ui/icons/Notifications";
import SchoolIcon from "@material-ui/icons/School";
import BarChartIcon from "@material-ui/icons/BarChart";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import BusinessIcon from "@material-ui/icons/Business";
import AssignmentIcon from "@material-ui/icons/Assignment";
import TocIcon from "@material-ui/icons/Toc";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import Storefront from "@material-ui/icons/Storefront";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import { Profile, SidebarNav } from "./components";
import firebase, {
  axiosWithToken,
  functionBaseUrl,
} from "../../../../common/firebase";
import Swal from "sweetalert2";
import DevicesIcon from "@material-ui/icons/Devices";

const useStyles = (theme) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up("lg")]: {
      marginTop: 64,
      height: "calc(100% - 64px)",
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
});

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userRole: "",
      subData: {},
      subscriptionType: "",
      isStaffEnterprise: false,
    };
  }

  async componentDidMount() {
    await firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const userSnap = await firebase
          .firestore()
          .collection("users")
          .doc(user.uid)
          .get();
        if (!userSnap.exists) {
          console.log("No matching documents for UID: " + user.uid);
          this.setState({
            error: "The signed in user does not exist",
          });
        } else {
          const userDoc = userSnap.data();
          this.setState({
            userRole: userDoc.role,
            subscriptionType: userDoc.subscriptionType,
          });
          if (
            userDoc.subscriptionType.includes("enterprise_master") &&
            userDoc.enterpriseAccountId
          ) {
            await axiosWithToken(
              functionBaseUrl + "/api/verifyStaffEnt/" + user.uid,
              {
                method: "POST",
              }
            )
              .then(async (res) => {
                this.setState({
                  isStaffEnterprise: res.data.isStaffEnterprise,
                });
              })
              .catch((err) => {
                console.log(err);
              });
          }

          if (!userDoc.subscriptionType.includes("free")) {
            await axiosWithToken(
              functionBaseUrl + "/api/verifySub/" + user.uid,
              {
                method: "post",
              }
            )
              .then((response) => {
                this.setState({
                  subData: response.data.data,
                });
              })
              .catch((err) => {
                console.log(err);
              });
          }
        }
      }
    });
  }

  render() {
    const { open, variant, onClose, className, classes, ...rest } = this.props;

    const pages = [
      {
        title: "Account",
        href: "/account",
        icon: <AccountBoxIcon />,
        handleClick: () => {
          onClose();
        },
      },
      {
        title: "Credits",
        href: "/credits",
        icon: <CardGiftcardIcon />,
        handleClick: () => {
          onClose();
        },
      },
      {
        title: "Invoices",
        href: "/invoices/detail/last",
        icon: <ReceiptIcon />,
        handleClick: () => {
          onClose();
        },
      },
      /*{
        title: "Upload",
        href: "/video-upload",
        icon: <BackupIcon />,
        handleClick: () => {
          onClose();
        },
      },
      {
        title: "Sessions",
        href: "/session-details",
        icon: <TimelineIcon />,
        handleClick: () => {
          onClose();
        },
      },*/
      {
        title: "Remote Session",
        href: "/remote-session",
        icon: <DevicesIcon />,
        handleClick: () => {
          onClose();
        },
      },
      {
        title: "Instructors",
        href: "/enterprise/instructors",
        icon: <BusinessIcon />,
        handleClick: () => {
          onClose();
        },
      },
      {
        title: "Students",
        href: "/students",
        icon: <PeopleIcon />,
        handleClick: () => {
          onClose();
        },
      },
      {
        title: "Invites",
        href: "/invites",
        icon: <NotificationsIcon />,
        handleClick: () => {
          onClose();
        },
      },
      {
        title: "Coupons",
        href: "/coupons",
        icon: <LocalOfferIcon />,
        handleClick: () => {
          onClose();
        },
      },
      {
        title: "Learning",
        href: "/learning",
        icon: <SchoolIcon />,
        handleClick: () => {
          onClose();
        },
      },
      {
        title: "Search",
        href: "/search",
        icon: <SearchIcon />,
        handleClick: () => {
          onClose();
        },
      },
      {
        title: "Role Requests",
        href: "/role-review",
        icon: <AssignmentIndIcon />,
        handleClick: () => {
          onClose();
        },
      },
      {
        title: "Summary",
        href: "/summary",
        icon: <BarChartIcon />,
        handleClick: () => {
          onClose();
        },
      },
      {
        title: "Usage",
        href: "/usage",
        icon: <ImportExportIcon />,
        handleClick: () => {
          onClose();
        },
      },
      {
        title: "Reports",
        href: "/reports",
        icon: <AssignmentIcon />,
        handleClick: () => {
          onClose();
        },
      },
      {
        title: "Weekly User Reports",
        href: "/weekly-reports",
        icon: <AssignmentIcon />,
        handleClick: () => {
          onClose();
        },
      },
      {
        title: "Content Management",
        href: "/content-management",
        icon: <TocIcon />,
        handleClick: () => {
          onClose();
        },
      },
      {
        title: "Practice Guides",
        href: "/practice-guides",
        icon: <TocIcon />,
        handleClick: () => {
          onClose();
        },
      },
      {
        title: "QA-Results",
        href: "/questionnaires",
        icon: <QuestionAnswerIcon />,
        handleClick: () => {
          onClose();
        },
      },
      {
        title: "Enterprise",
        href: "/enterprise",
        icon: <BusinessIcon />,
        handleClick: () => {
          onClose();
        },
      },
      {
        title: "Bootcamp",
        href: "/bootcamp",
        icon: <Storefront />,
        handleClick: () => {
          onClose();
        },
      },
      {
        title: "Unity",
        href: "/unity",
        icon: <PlayCircleFilledIcon />,
        handleClick: () => {
          onClose();
        },
      },
      {
        title: "Logout",
        href: "/welcome",
        icon: <ExitToAppIcon />,
        handleClick: async (event) => {
          /*await Swal.fire({
                    title: '<p style="font-size:70%;">Are you sure you want to log out?</p>',
                    //html: '<p>Are you sure you want to reject this invite?</p>',
                    customClass : { 
                      container : 'my-swal' 
                    },
                    icon: 'warning',
                    confirmButtonText: 'Yes',
                    showCancelButton: true,
                    cancelButtonColor: 'red',
                    allowOutsideClick: false,
                  })
                    .then(async (result) => {
                      if (result.isConfirmed) {
                        await firebase
                            .auth()
                            .signOut()
                            .then(() => {
                                this.setState({ route: "/welcome" });
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                      } else {
                        event.preventDefault();
                      }
                    })
                    .catch(err => {
                      console.log(err)
                      event.preventDefault();
                    });*/
          if (window.confirm("Are you sure you want to log out?")) {
            firebase
              .auth()
              .signOut()
              .catch((error) => {
                console.log(error);
              });
          } else {
            event.preventDefault();
          }
        },
      },
      {
        title: "Help",
        href: "/help",
        icon: <HelpIcon />,
        handleClick: () => {
          onClose();
        },
      },
    ];

    let sidebarPages = () => {
      if (this.state.userRole !== "admin") {
        if (this.state.subscriptionType.includes("free")) {
          return pages.filter(
            (item) =>
              item.title !== "Role Requests" &&
              item.title !== "Reported Issues" &&
              item.title !== "Students" &&
              item.title !== "Summary" &&
              item.title !== "Coupons" &&
              item.title !== "Bootcamp" &&
              item.title !== "Enterprise" &&
              item.title !== "Instructors" &&
              item.title !== "Remote Session" &&
              item.title !== "Students" &&
              item.title !== "Reports" &&
              item.title !== "Weekly User Reports" &&
              item.title !== "Invoices" &&
              item.title !== "Credits" &&
              item.title !== "Usage" &&
              item.title !== "Content Management" &&
              item.title !== "QA-Results" &&
              item.title !== "Practice Guides"
          );
        }
        if (this.state.subscriptionType.includes("enterprise_coach")) {
          return pages.filter(
            (item) =>
              item.title !== "Role Requests" &&
              item.title !== "Reported Issues" &&
              item.title !== "Summary" &&
              item.title !== "Coupons" &&
              item.title !== "Enterprise" &&
              item.title !== "Bootcamp" &&
              item.title !== "Instructors" &&
              item.title !== "Invites" &&
              item.title !== "Reports" &&
              item.title !== "Weekly User Reports" &&
              item.title !== "Invoices" &&
              item.title !== "Usage" &&
              item.title !== "Content Management" &&
              item.title !== "QA-Results" &&
              item.title !== "Practice Guides"
          );
        }
        if (this.state.subscriptionType.includes("enterprise_master")) {
          if (this.state.isStaffEnterprise) {
            return pages.filter(
              (item) =>
                item.title !== "Role Requests" &&
                item.title !== "Reported Issues" &&
                item.title !== "Summary" &&
                item.title !== "Coupons" &&
                item.title !== "Bootcamp" &&
                item.title !== "Enterprise" &&
                item.title !== "Invites" &&
                item.title !== "Reports" &&
                item.title !== "Weekly User Reports" &&
                item.title !== "Usage" &&
                item.title !== "Content Management" &&
                item.title !== "QA-Results" &&
                item.title !== "Practice Guides"
            );
          } else {
            return pages.filter(
              (item) =>
                item.title !== "Role Requests" &&
                item.title !== "Reported Issues" &&
                item.title !== "Summary" &&
                item.title !== "Coupons" &&
                item.title !== "Bootcamp" &&
                item.title !== "Enterprise" &&
                item.title !== "Invites" &&
                item.title !== "Reports" &&
                item.title !== "Invoices" &&
                item.title !== "Usage" &&
                item.title !== "Content Management" &&
                item.title !== "QA-Results" &&
                item.title !== "Practice Guides"
            );
          }
        }
        if (this.state.subscriptionType.includes("student_lite")) {
          return pages.filter(
            (item) =>
              item.title !== "Role Requests" &&
              item.title !== "Reported Issues" &&
              item.title !== "Summary" &&
              item.title !== "Coupons" &&
              item.title !== "Bootcamp" &&
              item.title !== "Enterprise" &&
              item.title !== "Instructors" &&
              item.title !== "Students" &&
              item.title !== "Reports" &&
              item.title !== "Weekly User Reports" &&
              item.title !== "Remote Session" &&
              item.title !== "Invoices" &&
              item.title !== "Credits" &&
              item.title !== "Usage" &&
              item.title !== "Content Management" &&
              item.title !== "QA-Results" &&
              item.title !== "Practice Guides"
          );
        }
        if (this.state.subscriptionType.includes("pro_lite")) {
          return pages.filter(
            (item) =>
              item.title !== "Role Requests" &&
              item.title !== "Reported Issues" &&
              item.title !== "Summary" &&
              item.title !== "Coupons" &&
              item.title !== "Bootcamp" &&
              item.title !== "Enterprise" &&
              item.title !== "Instructors" &&
              item.title !== "Reports" &&
              item.title !== "Weekly User Reports" &&
              item.title !== "Invoices" &&
              item.title !== "Usage" &&
              item.title !== "Content Management" &&
              item.title !== "QA-Results" &&
              item.title !== "Practice Guides"
          );
        }
        return pages.filter(
          (item) =>
            item.title !== "Role Requests" &&
            item.title !== "Reported Issues" &&
            item.title !== "Summary" &&
            item.title !== "Coupons" &&
            item.title !== "Bootcamp" &&
            item.title !== "Enterprise" &&
            item.title !== "Instructors" &&
            item.title !== "Reports" &&
            item.title !== "Weekly User Reports" &&
            item.title !== "Invoices" &&
            item.title !== "Usage" &&
            item.title !== "Content Management" &&
            item.title !== "QA-Results" &&
            item.title !== "Practice Guides"
        );
      } else {
        return pages;
      }
    };

    return (
      <Drawer
        anchor="left"
        classes={{ paper: classes.drawer }}
        onClose={onClose}
        open={open}
        variant={variant}
        id="sidebarPage"
        onClick={Swal.close()}
      >
        <div {...rest} className={clsx(classes.root, className)}>
          <Profile />
          <Divider className={classes.divider} />
          <SidebarNav className={classes.nav} pages={sidebarPages()} />
        </div>
      </Drawer>
    );
  }
}

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

export default withStyles(useStyles)(Sidebar);
