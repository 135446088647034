import { Dialog, DialogContent } from "@material-ui/core";
import React, { useState } from "react";
import { useEffect } from "react";
import { FlexWrap, Pad } from "../../../../components";
import {
  checkForDeletePracticeGuide,
  checkForDeleteTipsAndDrills,
} from "../../api_test";
import { ModalTitle } from "../styled";
import { FilledButton } from "./styled";

export function DeleteConfirm({ open, onClose, initId, initType, onConfirm }) {
  const [stage, setStage] = useState("loading");
  useEffect(() => {
    if (open === true) {
      (async () => {
        let check = null;
        switch (initType) {
          case "drill":
            check = await checkForDeleteTipsAndDrills(initId);
            break;

          case "guide":
            check = await checkForDeletePracticeGuide(initId);
            break;

          case "album":
            check = [];
            break;
        }
        if (check && check.length) {
          setStage("failure");
        } else {
          setStage("success");
        }
      })();
    }
  }, [open, initId, initType]);

  const handleClose = () => {
    setStage("loading");
    onClose();
  };

  const handleConfirm = () => {
    onClose();
    setStage("loading");
    onConfirm(initId);
  };

  const getTitle = () => {
    switch (stage) {
      case "loading":
        return "Checking if this item is used somewhere...";

      case "success":
        return "You can delete this item.";

      case "failure":
        return "You can't delete this item.";
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent style={{ padding: "30px" }}>
        <ModalTitle>{getTitle()}</ModalTitle>
        <Pad height="20px" />
        <FlexWrap justify="space-between">
          <FilledButton onClick={handleClose} width="48%" color="#808080">
            cancel
          </FilledButton>
          <FilledButton
            disabled={stage !== "success"}
            onClick={handleConfirm}
            width="48%"
            color="#580CE3"
          >
            delete
          </FilledButton>
        </FlexWrap>
      </DialogContent>
    </Dialog>
  );
}
