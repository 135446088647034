import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import admin, { axiosWithToken, functionBaseUrl } from "../../common/firebase";

export function CustomTokenAuth(props) {
    const { search } = useLocation();

    const { history } = props;

    const checkIfUserExists = (creds) => {
        axiosWithToken(functionBaseUrl + "/api/users/" + creds.user.uid, {
            method: "get",
        })
            .then((response) => {
                if (window.location.href.includes('mobileClient')) {
                    history.push("/dashboard?mobileClient");
                } else {
                    history.push("/dashboard");
                }
            })
            .catch((error) => {
                Swal.fire({
                    title: '<p style="font-size:70%;">User does not exist for this account, please sign up.</p>',
                    icon: "warning",
                    confirmButtonText: "Ok",
                    allowOutsideClick: false,
                }).then(() => {
                    if (window.location.href.includes('mobileClient')) {
                        history.push("/sign-up?mobileClient");
                    } else {
                        history.push("/sign-up");
                    }
                });
            });
    };

    useEffect(() => {
        const query = new URLSearchParams(search);
        if (query.get("token")) {
            const token = query.get("token");
            admin
                .auth()
                .signInWithCustomToken(token)
                .then((creds) => {
                    checkIfUserExists(creds);
                    localStorage.setItem("uid", creds.user.uid);
                })
                .catch((error) => {
                    Swal.fire({
                        title: '<p style="font-size:70%;">User does not exist for this account, please sign up.</p>',
                        icon: "warning",
                        confirmButtonText: "Ok",
                        allowOutsideClick: false,
                    }).then(() => {
                        if (window.location.href.includes('mobileClient')) {
                            history.push("/sign-up?mobileClient");
                        } else {
                            history.push("/sign-up");
                        }
                    });
                });
        } else {
            Swal.fire({
                title: '<p style="font-size:70%;">User does not exist for this account, please sign up.</p>',
                icon: "warning",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
            }).then(() => {
                if (window.location.href.includes('mobileClient')) {
                    history.push("/sign-up?mobileClient");
                } else {
                    history.push("/sign-up");
                }
            });
        }
    }, []);

    return <div></div>;
}
