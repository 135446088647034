import React, { useRef, useState } from "react";
import {
  DeleteImageButton,
  HiddenFileInput,
  MediaNotUploaded,
  MediaUploadButton,
  ModalContentTitle,
  UpdateImageButton,
} from "./styled";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { UploadModal } from "./common/UploadModal";
import { useEffect } from "react";

export function MediaUpload({ uploadFn, onMetadata, media, deleteFn }) {
  const UploadedImage = ({ src }) => {
    return (
      <div style={{ width: "100%", position: "relative" }}>
        <img style={{ maxWidth: "100%" }} src={src} />

        <UpdateImageButton>
          <label style={{ cursor: "pointer" }} htmlFor="hidden-image-reupload">
            <EditIcon
              style={{
                fontSize: "20px",
                margin: "3px 0 0 2px",
                pointerEvents: "none",
                cursor: "pointer",
              }}
            />
          </label>
        </UpdateImageButton>
        <DeleteImageButton>
          <label
            style={{ cursor: "pointer" }}
            onClick={() => deleteFn("coverImage")}
          >
            <DeleteIcon
              style={{
                fontSize: "20px",
                margin: "4px 2px 0 2px",
                pointerEvents: "none",
                cursor: "pointer",
              }}
            />
          </label>
        </DeleteImageButton>
        <HiddenFileInput
          id="hidden-image-reupload"
          name="coverImage"
          type="file"
          accept="image/*"
          onChange={uploadFn}
        />
      </div>
    );
  };

  const UploadedVideo = ({ src, name }) => {
    const videoRef = useRef();

    const onLoadedMetadata = () => {
      if (videoRef.current) {
        onMetadata(name, Math.floor(videoRef.current.duration));
      }
    };
    return (
      <div style={{ width: "100%", position: "relative" }}>
        <video
          ref={videoRef}
          onLoadedMetadata={onLoadedMetadata}
          style={{ background: "black" }}
          width="276"
          height="360"
        >
          <source src={src} type="video/webm" />
        </video>

        <UpdateImageButton>
          <label style={{ cursor: "pointer" }} htmlFor="hidden-video-reupload">
            <EditIcon
              style={{
                fontSize: "20px",
                margin: "3px 0 0 2px",
                pointerEvents: "none",
                cursor: "pointer",
              }}
            />
          </label>
        </UpdateImageButton>
        <DeleteImageButton>
          <label style={{ cursor: "pointer" }} onClick={() => deleteFn(name)}>
            <DeleteIcon
              style={{
                fontSize: "20px",
                margin: "4px 2px 0 2px",
                pointerEvents: "none",
                cursor: "pointer",
              }}
            />
          </label>
        </DeleteImageButton>
        <HiddenFileInput
          id="hidden-video-reupload"
          accept="video/*"
          name={name}
          type="file"
          onChange={uploadFn}
        />
      </div>
    );
  };

  const MemoizedVideo = React.memo(UploadedVideo);

  return (
    <>
      <ModalContentTitle>Cover Image</ModalContentTitle>
      {!media.coverImage.src ? (
        <MediaNotUploaded height="142px">
          <MediaUploadButton htmlFor="hidden-image-upload">
            UPLOAD
          </MediaUploadButton>
          <HiddenFileInput
            id="hidden-image-upload"
            name="coverImage"
            type="file"
            onChange={uploadFn}
            accept="image/*"
          />
        </MediaNotUploaded>
      ) : (
        <UploadedImage src={media.coverImage.src} />
      )}
      <ModalContentTitle>Introduction Video</ModalContentTitle>
      {!media.introVideo.src ? (
        <MediaNotUploaded height="320px">
          <MediaUploadButton htmlFor="hidden-video-upload">
            UPLOAD
          </MediaUploadButton>
          <HiddenFileInput
            id="hidden-video-upload"
            name="introVideo"
            type="file"
            onChange={uploadFn}
            accept="video/*"
          />
        </MediaNotUploaded>
      ) : (
        <MemoizedVideo name="introVideo" src={media.introVideo.src} />
      )}
    </>
  );
}
