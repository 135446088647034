import React from "react";

export const CustomRadio = ({ active }) => {
  return (
    <>
      <div
        style={{
          border: "2px solid #580CE3",
          borderRadius: "50%",
          padding: "2px",
          width: "20px",
          height: "20px",
        }}
      >
        <div
          style={{
            background: active ? "#580CE3" : "rgba(0, 0, 0, 0)",
            borderRadius: "50%",
            width: "12px",
            height: "12px",
          }}
        />
      </div>
    </>
  );
};
