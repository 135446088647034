import { Dialog, DialogContent } from "@material-ui/core";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { FlexWrap, Pad } from "../../../../components";
import { CustomRadio } from "./CustomRadio";
import { FilledButton } from "./styled";
import { ModalTitle } from "../styled";
import {
  EqupmentItemContent,
  WatchlistItemContainer,
  WatchlistItemSubContainer,
} from "../practiceGuide/styled";

export function ConfirmSaveModal({ open, onClose, onSave, validation }) {
  const [selected, setSelected] = useState(null);

  const handleClose = () => {
    setSelected(null);
    onClose();
  };

  const handleSave = () => {
    onClose();
    onSave(selected);
    setSelected(null);
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
      <DialogContent style={{ padding: "30px" }}>
        <ModalTitle>Save</ModalTitle>
        <Pad margin="20px 0" width="100%">
          {validation().published ? (
            <>
              <WatchlistItemContainer onClick={() => setSelected("published")}>
                <CustomRadio active={selected === "published"} />
                <WatchlistItemSubContainer>
                  <div style={{ fontWeight: "bold" }}>Publish into app</div>
                  <div>This will publish this file into app</div>
                </WatchlistItemSubContainer>
              </WatchlistItemContainer>
              <Pad height="10px" />
            </>
          ) : (
            ""
          )}
          {validation().private ? (
            <>
              <WatchlistItemContainer onClick={() => setSelected("private")}>
                <CustomRadio active={selected === "private"} />
                <WatchlistItemSubContainer>
                  <div style={{ fontWeight: "bold" }}>Private</div>
                  <div>Keep the file as private only</div>
                </WatchlistItemSubContainer>
              </WatchlistItemContainer>
              <Pad height="10px" />
            </>
          ) : (
            ""
          )}
          {!validation().private && !validation().published ? (
            <div>
              You haven't filled enough fields to save this practice guide
            </div>
          ) : (
            ""
          )}
        </Pad>
        <FlexWrap justify="space-between">
          <FilledButton onClick={handleClose} width="48%" color="#808080">
            cancel
          </FilledButton>
          <FilledButton
            onClick={() => handleSave()}
            disabled={
              (!validation().private && !validation().published) ||
              selected === null
            }
            width="48%"
            color="#580CE3"
          >
            confirm
          </FilledButton>
        </FlexWrap>
      </DialogContent>
    </Dialog>
  );
}
