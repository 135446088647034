import admin from "../../../common/firebase";
import { format } from "date-fns";

export const dateMapper = (el) => ({
  ...el,
  createdAt: format(new Date(el.createdAt), "MM/dd/yyyy"),
});

export const fbMapper = (snap) => {
  const output = [];
  snap.forEach((doc) => {
    output.push({ ...doc.data(), id: doc.id });
  });
  return output;
};

export const firestorageExistCheck = (filePath) => {
  return admin
    .storage()
    .refFromURL(filePath)
    .getDownloadURL()
    .then(() => true)
    .catch((err) => {
      switch (err.code) {
        case "storage/object-not-found":
          return false;
      }
    });
};
