import React from "react";
import { Dialog, DialogContent, Button } from "@material-ui/core";

export function DeletePrompt({ open, func, onClose }) {
  const onYes = () => {
    func();
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <div>Are you sure?</div>
        <Button onClick={onClose}>No</Button>
        <Button onClick={onYes}>Yes</Button>
      </DialogContent>
    </Dialog>
  );
}
