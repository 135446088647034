import React, { useState, useEffect } from 'react';

import { Button, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, Grid, 
  TextField, Select, MenuItem, FormControl, InputLabel, InputAdornment, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Swal from 'sweetalert2';

import firebase, { functionBaseUrl, axiosWithToken } from '../../common/firebase';

function UpgradeStudentComponent({ open, student, close, coachName, coachId }) {
  const [subPlan, setSubPlan] = useState('');
  const [subPeriod, setSubPeriod] = useState('');
  const [autoRenewal, setAutoRenewal] = useState(false);

  const handleSubPlanChange = (event) => {
    setSubPlan(event.target.value);
  }

  const handleSubPeriodChange = (event) => {
    setSubPeriod(event.target.value);
  }

  const handleAutoRenewalChange = (event) => {
    setAutoRenewal(event.target.value);
  }

  const handleUpgrade = async () => {
    try {
      await axiosWithToken(`${functionBaseUrl}/api/v2/upgradeStudent`, {
        method: 'post',
        data: {
          id: student.id,
          coachId: coachId,
          coachName: coachName,
          subPlan: subPlan,
          subPeriod: subPeriod,
          autoRenewal: autoRenewal
        }
      })
        .then((response) => {
          if (response.status === 200) {
            Swal.fire({
              title: '<p style="font-size:70%;">Upgraded successfully</p>',
              icon: 'success',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              customClass : { 
                container : 'sweet-alert'
              },
              timer: 2000
            })
              .then(() => {
                window.location.reload();
              })
            close();
          } else {
            Swal.fire({
              title: '<p style="font-size:70%;">' + response.data.message + '</p>',
              icon: 'error',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              customClass : { 
                container : 'sweet-alert'
              },
            })
          }
        })
    } catch (e) {
      Swal.fire('<p style="font-size:70%;">Error occured</p>');
      close();
    }
  }

  return (<>
    <Dialog
      fullWidth
      open={open}
      onClose={close}
      aria-labelledby='form-dialog-title'>
      <DialogTitle id={'form-dialog-title'}>
        <div>
          Upgrade Student Account
          <CloseIcon style={{ float: 'right', marginTop: '-10px', marginRight: '-15px' }} onClick={close} />
        </div>
      </DialogTitle>
      <DialogContent>
        <Grid container component="main" maxWidth="xs">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                disabled
                label='Name'
                name='name'
                margin='dense'
                value={student?.name}
                variant='outlined' />
            </Grid>
            <Grid item sm={6}>
              <TextField
                fullWidth
                disabled
                label='Email'
                name='email'
                margin='dense'
                value={student?.user?.email}
                variant='outlined' />
            </Grid>
            <Grid item sm={6}>
              <FormControl              
                variant="outlined"
                fullWidth
                margin="dense"
                >
                  <InputLabel id="subPlan-select">Account Type*</InputLabel>
                  <Select
                    id="subPlan"
                    label="Account Type*"
                    labelId="subPlan-select"
                    name="subPlan"
                    value={subPlan}
                    onChange={handleSubPlanChange}
                  >
                    <MenuItem value={'development_sportsbox_student_lite_monthly'}>3D Practice Monthly</MenuItem>
                    <MenuItem value={'development_sportsbox_student_lite_annually'}>3D Practice Annually</MenuItem>
                  </Select>
                </FormControl>
            </Grid>
            <Grid item sm={6}>
              <TextField
                fullWidth
                label="Subscription Period*"
                name='subPeriod'
                margin='dense'
                onChange={handleSubPeriodChange}
                value={subPeriod}
                InputProps={{
                  endAdornment: <InputAdornment position="end">{subPlan.includes('annually') ? 'years' : 'months'}</InputAdornment>,
                  inputProps: { min: 0 }
                }}
                type="number"
                variant='outlined' />
                {subPeriod !== '' && subPeriod <= 0 && <Typography style={{ color: "red" }}>Subscription Period has to be greater than 0</Typography>}
            </Grid>
            <Grid item sm={6}>
              <FormControl              
                variant="outlined"
                fullWidth
                margin="dense"
                >
                  <InputLabel id="autoRenewal-select">Auto Renewal*</InputLabel>
                  <Select
                    id="autoRenewal"
                    label="Auto Renewal*"
                    labelId="autoRenewal-select"
                    name="autoRenewal"
                    value={autoRenewal}
                    onChange={handleAutoRenewalChange}
                  >
                    <MenuItem value={true}>On</MenuItem>
                    <MenuItem value={false}>Off</MenuItem>
                  </Select>
                </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          Cancel
        </Button>
        <Button onClick={handleUpgrade} color="secondary" disabled={subPlan === '' || (subPlan.length > 0 && subPeriod === 0) || (subPlan.length > 0 && subPeriod <= 0)}>
          Upgrade
        </Button>
      </DialogActions>
    </Dialog>
  </>);
}

export const UpgradeStudent = UpgradeStudentComponent;
