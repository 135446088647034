import React from "react";
import { Pad } from "../../../../../components";
import {
  WatchlistItemContainer,
  WatchlistItemSubContainer,
  WatchlistTrackerContainer,
} from "../../practiceGuide/styled";
import { CustomRadio } from "../CustomRadio";

export function PopularWatchlists({ data, selected, onSelect }) {
  return (
    <>
      {data.map((el) => (
        <>
          <WatchlistItemContainer onClick={() => onSelect(el.WATCHLIST_ID)}>
            <CustomRadio active={selected.includes(el.WATCHLIST_ID)} />
            <WatchlistItemSubContainer>
              <div style={{ fontWeight: "bold" }}>{el.WATCHLIST_NAME}</div>
              <div>{el.WATCHLIST_DESCRIPTION}</div>
              {selected.includes(el.WATCHLIST_ID)
                ? el.indicatorData.map((trc) => (
                    <>
                      <Pad height="10px" />
                      <WatchlistTrackerContainer>
                        <div>icon</div>
                        <Pad width="10px" />
                        <div>
                          <div style={{ fontSize: "16px" }}>{trc.Name}</div>
                          <div style={{ fontSize: "12px" }}>
                            Range {trc.minGoal} to {trc.maxGoal}
                          </div>
                        </div>
                      </WatchlistTrackerContainer>
                    </>
                  ))
                : ""}
            </WatchlistItemSubContainer>
          </WatchlistItemContainer>
          <Pad height="10px" />
        </>
      ))}
    </>
  );
}
