import React, { useState, useEffect } from 'react';

import { Button, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, Grid, TextField, Select, MenuItem } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Swal from 'sweetalert2';

import firebase, { functionBaseUrl, axiosWithToken } from '../../common/firebase';

function EditStudentEnterpriseComponent({ open, student, close }) {
  const [instructors, setInstructors] = useState([]);
  const [instructorsCurrent, setInstructorsCurrent] = useState([]);
  const [selectedCoach, setSelectedCoach] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const loadData = async () => {
    const firebaseUser = firebase.auth().currentUser;
    const uid = firebaseUser.uid;
    const currUser = await axiosWithToken(`${functionBaseUrl}/api/users/${uid}`, {
      method: 'get',
    });
    const response = await axiosWithToken(`${functionBaseUrl}/api/usersEnterprise/instructors/${currUser?.data.enterpriseAccountId}`, {
      method: 'get',
    });
    setInstructors(response.data);
    setIsLoading(false);
  }

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (student?.user?.coaches?.length && instructors?.length) {
      setInstructorsCurrent(instructors.filter(item => student.user.coaches.includes(item.uid)));
    }
  }, [student, instructors]);

  const handleCoachChange = (event) => {
    setSelectedCoach(event.target.value);
  }

  const addCoach = () => {
    if (selectedCoach !== '' && instructorsCurrent.filter(item => item.uid === selectedCoach).length === 0) {
      const newInstructor = instructors.filter(item => item.uid === selectedCoach);
      setInstructorsCurrent([...newInstructor, ...instructorsCurrent]);
    }
  }

  const removeCoach = (uid) => {
    const newInstructors = instructorsCurrent.filter(item => item.uid !== uid);
    setInstructorsCurrent(newInstructors);
  }

  const handleUpdate = async () => {
    try {
      const firebaseUser = firebase.auth().currentUser;
      const uid = firebaseUser.uid;
      await axiosWithToken(`${functionBaseUrl}/api/usersEnterprise/students/instructors`, {
        method: 'post',
        data: {
          instructors: instructorsCurrent,
          student: student.user.id,
        }
      });
      Swal.fire({
        title: '<p style="font-size:70%;">Saved successfully</p>',
        icon: 'success',
        confirmButtonText: 'Ok',
        allowOutsideClick: false,
        timer: 2000
      });
      close();
    } catch (e) {
      Swal.fire('<p style="font-size:70%;">Error occured</p>');
    }
  }

  return (<>
    <Dialog
      fullWidth
      open={open}
      onClose={close}
      aria-labelledby='form-dialog-title'>
      <DialogTitle id={'form-dialog-title'}>
        <div>
          Edit students coaches
          <CloseIcon style={{ float: 'right', marginTop: '-10px', marginRight: '-15px' }} onClick={close} />
        </div>
      </DialogTitle>
      <DialogContent>
        <Grid container component="main" maxWidth="xs">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                disabled
                label='Name'
                name='name'
                margin='dense'
                value={student?.name}
                variant='outlined' />
            </Grid>
            <Grid item sm={6}>
              <TextField
                fullWidth
                disabled
                label='Email'
                name='email'
                margin='dense'
                value={student?.user?.email}
                variant='outlined' />
            </Grid>
            {isLoading && (<>
              <Grid style={{ textAlign: "center", padding: 200 }}>
                <CircularProgress />
              </Grid>
            </>)}
            {!isLoading && (<>
              <Grid item xs={12}>
                <ul style={{ paddingLeft: '35px' }}>
                  {instructorsCurrent.map(item =>
                    <li>
                      <span>{item.firstName} {item.lastName} ({item.email})</span>
                      <CloseIcon style={{ paddingTop: '10px' }} onClick={() => removeCoach(item.uid)} />
                    </li>
                  )}
                </ul>
              </Grid>
              <Grid item xs={12}>
                <div>Add coach</div>
                <Select
                  label="Coach"
                  value={selectedCoach}
                  onChange={handleCoachChange}
                >
                  {instructors.map(item =>
                    <MenuItem value={item.uid}>{item.firstName} {item.lastName} ({item.email})</MenuItem>
                  )}
                </Select>
                <Button disabled={selectedCoach === ''} onClick={addCoach} color="primary">
                  Add
                </Button>
              </Grid>
            </>)}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          Cancel
        </Button>
        <Button onClick={handleUpdate} color="secondary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  </>);
}

export const EditStudentEnterprise = EditStudentEnterpriseComponent;