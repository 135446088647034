import React from 'react';

import { Container, Grid } from '@material-ui/core';

import { BootcampAccountList } from './BootcampAccountList';


function BootcampComponent() {
  return (<>
    <Container
      component="main"
      style={{ paddingTop: '20px' }}
    >
      <Grid container spacing={2}>
        <BootcampAccountList />
      </Grid>
    </Container>
  </>);
}

export const Bootcamp = BootcampComponent;