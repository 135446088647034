import React, {Component} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import {functionBaseUrl, axiosWithToken} from '../../common/firebase'
import firebase from '../../common/firebase'
import {withStyles} from '@material-ui/styles';
import {Grid, Button, Container, Avatar, TextField, Link,
  Typography, CssBaseline, CircularProgress, FormControl, 
  InputLabel, Select, MenuItem} from '@material-ui/core';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import {AccountNotFound} from '../account/components';
import * as admin from 'firebase/app';
import 'firebase/firestore';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    marginTop: theme.spacing(4),
  },
  buttonLink: {  // This is a link component surrounding a button component
    width: '100%'
  },
  linkedButton: {  // This is a button component surrounded by a link
    margin: theme.spacing(1, 0),
  }
});

class CreateLabelingJob extends Component {
  constructor(props) {
    super(props)
    const firebaseUser = firebase.auth().currentUser
    this.state = {
      uid : firebaseUser.uid,
      error : "",
      loading : true,
      success : false,
      jobID : "",
      requestID : "",
      name : "",
      dateCreated : "",
      desiredDate : "",
      completionDate : "",
      descriptionOfFrames : "",
      numberOfFrames : "",
      descriptionOfPoints : "",
      numberOfPoints : "",
      labelerID : "",
      status : "",
      labelers : [],
      requestIDs : []
    }
  }

  componentDidMount() {
    if (!this.state.uid) {   
      this.setState({error : "Please log in to submit a labeling request", loading : false})  
    } else {
      this.setState({
        loading : false
      })
    }
    this.getLabelers()
    this.getRequestIDs()
  }

  handleChange = (prop) => (event) => {
    this.setState({[prop]: event.target.value});
  }

  handleLabelerChange = event => {
    this.setState({      
      [event.target.name]: event.target.value
    });
  }

  handleRequestIDChange = event => {
    this.setState({      
      [event.target.name]: event.target.value
    });
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.setState({loading: true});
    //Create labeler job firestore entry
    let output = {
      jobID : this.state.jobID,
      requestID : this.state.requestID,
      name : this.state.name,
      dateCreated : this.state.dateCreated,
      desiredDate : this.state.desiredDate,
      completionDate : this.state.completionDate,
      descriptionOfFrames : this.state.descriptionOfFrames,
      numberOfFrames : this.state.numberOfFrames,
      descriptionOfPoints : this.state.descriptionOfPoints,
      numberOfPoints : this.state.numberOfPoints,
      labelerID : this.state.labelerID,
      status : this.state.status
    }
    axiosWithToken(functionBaseUrl +'/api/labelingJobs', {
      method: 'POST',
      data: output
    })
      .then(res => {
        console.log("Successfully created a new labeling job!")
        this.setState({
          success: true,
          loading: false
        })
      })
      .catch(err => {
        this.setState({loading: false});
        console.log("An error occured: " + err);
      });
  }

  getLabelers = () => {
    let tempLabelers = []
    tempLabelers.push({name: 'Unassigned', id: '0'});
    admin.firestore().collection('users').where('role', '==', 'labeler-admin').get().then(snapshot => {
      snapshot.forEach(function(doc) {
        let res = doc.data()
        tempLabelers.push({name: res.firstName + ' ' + res.lastName, id: doc.id});
      });
      this.setState({ labelers: tempLabelers });
    })
    .catch(err => {
      console.log('Error getting documents', err);
    });
  }

  getRequestIDs = () => {
    let tempRequestIDs = []
    tempRequestIDs.push({name: 'Unassigned', id: '0'});
    admin.firestore().collection('labelingRequests').get().then(snapshot => {
      snapshot.forEach(function(doc) {
        let res = doc.data()
        tempRequestIDs.push({name: res.requestID, id: doc.id});
      });
      this.setState({ requestIDs: tempRequestIDs });
    })
    .catch(err => {
      console.log('Error getting documents', err);
    });
  }

  render() {
    const classes = this.props.classes;
    return (
      <Container
        component="main"
        style={{width:'80%', maxWidth: '500px'}}
        className={classes.paper}
      >
        <CssBaseline />
        <Grid
          style = {{display: (this.state.error) ? "none" : (this.state.loading) ? "none" : (this.state.success) ? "none" : "flex"}}
        >
          <div className={this.props.classes.paper}>
            <Avatar className={this.props.classes.avatar}>
              <AssignmentIndIcon />
            </Avatar>
            <Typography
              component="h1"
              variant="h4"
            >
              CREATE A NEW LABELING JOB
            </Typography>
            <form
              className={this.props.classes.form}
              onSubmit={this.handleSubmit}
            >
              <div className={this.props.classes.root}>
                <Typography
                  variant="h6"
                  align="left"
                >
                  LABELING JOB INFORMATION
                </Typography>
                <TextField
                  autoFocus
                  fullWidth
                  label="Job ID"
                  margin="normal"
                  name="jobID"
                  onChange={this.handleChange("jobID")}
                  required
                  value={this.state.jobID}
                  variant="outlined"
                />
                <TextField
                  autoComplete="requestID"
                  select
                  fullWidth
                  label="Request ID"
                  margin="normal"
                  name="requestID"
                  onChange={this.handleRequestIDChange}
                  required
                  value={this.state.requestID}
                  variant="outlined"
                >
                  {this.state.requestIDs.map((requestID) => (
                    <MenuItem key={requestID.name} value={requestID.name}>
                      {requestID.name}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  autoFocus
                  fullWidth
                  label="Name"
                  margin="normal"
                  name="name"
                  onChange={this.handleChange("name")}
                  required
                  value={this.state.name}
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  id="dateCreated"
                  margin="normal"
                  label="Request Date"
                  type="date"
                  onChange={this.handleChange("dateCreated")}
                  required
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  fullWidth
                  id="desiredDate"
                  margin="normal"
                  label="Desired Date"
                  type="date"
                  onChange={this.handleChange("desiredDate")}
                  required
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  fullWidth
                  id="completionDate"
                  margin="normal"
                  label="Completion Date"
                  type="date"
                  onChange={this.handleChange("completionDate")}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className={this.props.classes.root}>
                <Typography
                  variant="h6"
                  align="left"
                >
                  INFORMATION
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  label="Description Of Frames"
                  margin="normal"
                  name="descriptionOfFrames"
                  onChange={this.handleChange("descriptionOfFrames")}
                  required
                  value={this.state.descriptionOfFrames}
                  variant="outlined"
                />
              </div>
                <TextField
                  fullWidth
                  label="Number of Frames"
                  margin="normal"
                  type='number'
                  name="numberOfFrames"
                  onChange={this.handleChange("numberOfFrames")}
                  required
                  value={this.state.numberOfFrames}
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  multiline
                  label="Description Of Points"
                  margin="normal"
                  name="descriptionOfPoints"
                  onChange={this.handleChange("descriptionOfPoints")}
                  required
                  value={this.state.descriptionOfPoints}
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  label="Number Of Points"
                  margin="normal"
                  type='number'
                  name="numberOfPoints"
                  onChange={this.handleChange("numberOfPoints")}
                  required
                  value={this.state.numberOfPoints}
                  variant="outlined"
                />
              <div className={this.props.classes.root}>
                <Typography
                  variant="h6"
                  align="left"
                >
                  ASSIGNMENT
                </Typography>
                <TextField
                  autoComplete="labelerID"
                  select
                  fullWidth
                  label="Labeler ID"
                  margin="normal"
                  name="labelerID"
                  onChange={this.handleLabelerChange}
                  required
                  value={this.state.labelerID}
                  variant="outlined"
                >
                  {this.state.labelers.map((labeler) => (
                    <MenuItem key={labeler.name} value={labeler.name}>
                      {labeler.name}
                    </MenuItem>
                  ))}
                </TextField>
                
                <FormControl              
                  variant="outlined"
                  fullWidth
                  margin="normal"
                >
                  <InputLabel id="status-select">Status*</InputLabel>
                  <Select
                    id="status"
                    label="Status"
                    labelId="status-select"
                    name="status"
                    value={this.state.status}
                    onChange={this.handleChange('status')}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={'created'}>Created</MenuItem>
                    <MenuItem value={'inProgress'}>In Progress</MenuItem>
                    <MenuItem value={'completed'}>Completed</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <Button
                className={this.props.classes.submit}
                color="primary"
                fullWidth
                type="submit"
                variant="contained"
              >
                CREATE LABELING JOB
              </Button>
              <Grid container>
                <Grid
                  item
                  xs
                >
                  <Link
                    component={RouterLink}
                    to="/labeling"
                    variant="body2"
                  >
                    {'Go back to Labeling'}
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
        </Grid>

        {/* Loading screen*/}
        <Grid
          style = {{margin : "auto", padding : 100, display: (this.state.loading) ? "block" : "none"}}
        >
          <CircularProgress />
        </Grid>

        {/* Success screen*/}
        <Grid
          style = {{margin : "auto", paddingTop : 100, display: (this.state.success) ? "block" : "none"}}
        >
          <div className={this.props.classes.paper}>
            <Avatar className={this.props.classes.avatar}>
              <CheckCircleIcon />
            </Avatar>
            <Typography component="h1" variant="h2">
              Success!
            </Typography>

            <Typography component="h5" align = "center">
              Successfully created a new labeling job.
            </Typography>
            <Link className={this.props.classes.buttonLink} component={RouterLink} to="/create-labelingJob">
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={this.props.classes.linkedButton}
                onClick={() => {
                  this.setState({ success: false })
                }}
              >
                Create Another labeling job
              </Button>
            </Link>
            <Link className={this.props.classes.buttonLink} component={RouterLink} to="/labeling">
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={this.props.classes.linkedButton}
              >
                Back To Labeling
              </Button>
            </Link>
          </div>
        </Grid>
        {/* Error screen*/}
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
          style = {{display: (this.state.error && !this.state.loading) ? "block" : "none"}}
        >
          <AccountNotFound            
            error = {{error : this.state.error}}
          />
        </Grid>
      </Container>
    );
  }
};

CreateLabelingJob.propTypes = {
  classes:PropTypes.object,
  history: PropTypes.object,
};

export default withStyles(useStyles)(CreateLabelingJob);
