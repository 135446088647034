import React from 'react';
import { withStyles } from '@material-ui/styles';
import './plan.css';
import {
    Grid, Button, Typography,
    TextField, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, FormLabel,
    FormControlLabel, Radio, RadioGroup,
    Table, TableBody, TableRow, TableCell, TableContainer, createMuiTheme, MuiThemeProvider, styled, TableHead, Snackbar
} from '@material-ui/core';
import { Alert, ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import CheckIcon from '@material-ui/icons/Check';
import { Close } from '@material-ui/icons';
import { isMobile } from 'react-device-detect';
import Swal from 'sweetalert2';
import { functionBaseUrl } from '../../../common/firebase';
import axios from 'axios';


window.localStorage.setItem('freeTrial', 'false')
const theme = createMuiTheme({
    tableCell: {
        display: 'flex',
        alignItems: 'center',
    },
    typography: {
        fontSize: 16,
        h1: {
            fontSize: 30,
        },
        h2: {
            fontSize: 28,
        },
        h3: {
            fontSize: 26,
        },
        h4: {
            fontSize: 24,
        },
        h5: {
            fontSize: 22
        }
    }
});

const useStyles = theme => ({
    paper: {
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(2),
        background: '#000C66',
        width: '100%'
    },
    newFeature: {
        background: '#000000',
        borderLeft: '10px solid #3b46ff',
        padding: theme.spacing(2),
    },
    tableHeader: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        fontWeight: 'bold',
    },
    link: {
        color: '#fff',
        textDecoration: 'underline'
    },
    link2: {
        color: '#7f39fb',
    },
    titleText: {
        color: 'white',
        fontSize: '2.5rem',
        width: '200px',
        marginBottom: theme.spacing(2),
        textDecoration: 'underline',
        textDecorationColor: '#3b46ff',
        textUnderlineOffset: '5px',
        fontFamily: 'Anton'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    root: {
        marginTop: theme.spacing(1),
    },
    buttonLink: {  // This is a link component surrounding a button component
        width: '100%'
    },
    linkedButton: {  // This is a button component surrounded by a link
        margin: theme.spacing(2),
    },
    title: {
        textAlign: 'center',
        fontFamily: 'arial, sans-serif'
    }
});


const TableCellCenter = styled(TableCell)({
    textAlign: 'center',
    borderLeft: '1px solid #e0e0e0',
})

const TableCellName = styled(TableCell)({
    width: isMobile ? 200 : 300
})

const Check = styled(CheckIcon)({
    color: '#007be8'
})

export const planPricing = {
    free: {
        monthly: 0,
        annually: 0,
        planName: 'sportsbox_free_student',
        getProdId: (interval) => 'development_sportsbox_free_student_' + interval,
        label: "Free",
    },
    practice: {
        monthly: 15.99,
        annually: 110,
        planName: 'sportsbox_student_lite',
        getProdId: (interval) => 'development_sportsbox_student_lite_' + interval,
        label: "3D Practice"
    },
    lite: {
        monthly: 65,
        annually: 650,
        planName: 'sportsbox_pro_lite',
        getProdId: (interval) => 'development_sportsbox_pro_lite_' + interval,
        label: "Pro Lite"
    },
    premium: {
        monthly: 189,
        annually: 1600,
        planName: 'sportsbox_pro_premium',
        getProdId: (interval) => 'development_sportsbox_pro_premium_' + interval,
        label: "Premium"
    },
    enterprise: {
        monthly: 0,
        annually: 3500,
        perCoach: 98,
        planName: 'sportsbox_enterprise_master',
        getProdId: (interval) => 'development_sportsbox_enterprise_master',
        label: "Enterprise"
    }
}

/*const practiceQuestions = [
    {
        question: 'What type of filming angle is required to capture a swing for analysis in Sportsbox?',
        options: [
            'Down-the-Line (Camera pointed at target)',
            '45° angle (Camera pointed at golfer from a 45-degree angle to the right of the golfer if facing forward)',
            'Face-On (Golfer facing the camera)',
            'Behind (Camera behind the golfer pointing at the backside of the golfer)'
        ],
        answer: 2
    },
    {
        question: 'When importing a swing video into the app, it must be in slow motion',
        options: [
            'True',
            'False'
        ],
        answer: 0
    },
    {
        question: 'What are possible reasons you may experience problems with Auto Swing Detection and Voice Guidance?',
        options: [
            'Taking practice swings/waggles in between shots you want recorded',
            'Lighting is too dark and you didn’t change the brightness setting to appropriate level',
            'You didn’t give enough pause/time in between swings (rapid fire one shot after another)',
            'You didn’t wait for the voice guidance to finish saying “All set. 3, 2, 1. GO” before taking the first swing',
            'Went out of camera frame in between swings or took more than 10 seconds between shots (was not prepared with balls you wanted to hit before starting session)',
            'All of the above'
        ],
        answer: 5
    },
    {
        question: 'Where do you find your Watchlist?',
        options: [
            '2D Screen',
            '3D Screen',
            'All of the above'
        ],
        answer: 2
    }
]*/


const PlansView = (props) => {
    const [tab, setTab] = React.useState(null);
    const [snackbar, setSnackbar] = React.useState(null)
    //const [openQuiz, setOpenQuiz] = React.useState(false);
    const [answers, setAnswers] = React.useState([-1, -1, -1, -1])

    if (tab === null && !props.loading) {
        if (props.subscriptionType && (props.subscriptionType?.includes('free') || props.subscriptionType === '' || ["development_sportsbox_student_lite_monthly", "development_sportsbox_student_lite_annually"].includes(props.subscriptionType))) {
            setTab('student')
        } else {
            setTab('coach')
        }
        if (window.location.href.includes('student')) {
            setTab('student');
        }
    }
    const delZero = (num) => {
        return num === parseInt(num) ? parseInt(num) : num.toFixed(2)
    }
    const calculatePrice = (key, interval) => {
        let price = planPricing[key][interval]
        const plan = planPricing[key]
        price = Math.max(price, 0)
        return delZero(price)
    }
    const getPlan = (key) => {
        const plan = planPricing[key]
        plan.monthly = calculatePrice(key, 'monthly')
        plan.annually = calculatePrice(key, 'annually')
        plan.key = key
        return plan
    }
    const generateLabel = (plan, interval, getStr) => {
        const price = calculatePrice(plan, interval)
        if (parseFloat(price) === parseFloat(planPricing[plan][interval])) {
            return getStr(planPricing[plan][interval])
        } else {
            return (<><strike style={{color: 'red'}}>{getStr(planPricing[plan][interval])}</strike><br/> {getStr(price)}</>)
        }
    }
    const planData = {
        free: {
            title: 'Free',
            buttonTitle: 'Get Started',
            month: 'Free',
            year: 'Free',
            numStudents: 'None',
            coachLicense: 'None',
            devices: '1',
            sessions: '1 sample session',
            cloud: <Close />,
            trackers: 'Limited',
            pracWatch: 'Only 1 popular Watchlist'
        },
        practice: {
            title: '3D Practice',
            buttonTitle:  'Get Started',
            month: generateLabel('practice', 'monthly', (price) => `$${price}/month`),
            year: <p>${parseInt(calculatePrice('practice', 'annually')/12)}/month <br /> {generateLabel('practice', 'annually', (price) => `($${price} billed annually)`)}</p>,
            //year: <p><strike>$110/billed annually</strike><br /><b>50% off Early Access - ${calculatePrice('practice', 'annually')} billed annually</b></p>,
            numStudents: 'None',
            coachLicense: 'None',
            devices: '1',
            sessions: '7/week',
            cloud: '5GB',
            trackers: 'Limited',
            pracWatch: 'Max of 3 watchlists at any time'
        },
        lite: {
            title: 'Pro Lite',
            buttonTitle: 'Get Started',
            buttonTitle2:  'Start 14-day trial',
            month: generateLabel('lite', 'monthly', (price) => `$${price}/month`),
            year: <p>${parseInt(calculatePrice('lite', 'annually')/12)}/month <br /> {generateLabel('lite', 'annually', (price) => `($${price} billed annually)`)}</p>,
            //month: <p><strike>$65/month</strike><br /><b style={{ fontSize: "125%", color: "#0d47a1" }}>20% off - ${calculatePrice('lite', 'monthly')}/month</b><br /><p style={{ color: "#0d47a1" }}>For the first 3 months</p></p>,
            //year: <p><strike>$54/month <br /> ($650 billed annually)</strike> <br /> <b style={{ fontSize: "125%", color: "#0d47a1" }}>20% off - ${parseInt(calculatePrice('lite', 'annually')/12)}/month <br /> {generateLabel('lite', 'annually', (price) => `($${price} billed annually)`)}</b></p>,
            numStudents: '15 students',
            coachLicense: '1',
            devices: '1/user',
            sessions: 'Sessions capped by cloud storage',
            cloud: '50GB',
            trackers: 'All',
        },
        premium: {
            title: '3D Pro',
            buttonTitle: 'Get Started',
            buttonTitle2:  'Start 14-day trial',
            month: generateLabel('premium', 'monthly', (price) => `$${price}/month`),
            year: <p>${parseInt(calculatePrice('premium', 'annually')/12)}/month <br />{generateLabel('premium', 'annually', (price) => `($${price} billed annually)`)}</p>,
            //month: <p><strike>$189/month</strike><br /><b style={{ fontSize: "125%", color: "#0d47a1" }}>20% off - ${calculatePrice('premium', 'monthly')}/month</b><br /><p style={{ color: "#0d47a1" }}>For the first 3 months</p></p>,
            //year: <p><strike>$157/month <br /> ($1890 billed annually)</strike> <br /> <b style={{ fontSize: "125%", color: "#0d47a1" }}>40% off - ${parseInt(calculatePrice('premium', 'annually')/12)}/month <br /> {generateLabel('premium', 'annually', (price) => `($${price} billed annually)`)}</b></p>,
            numStudents: '150 students',
            coachLicense: '1',
            devices: '2/user',
            sessions: 'Sessions capped by cloud storage',
            cloud: '200GB(~20K swings)',
            trackers: 'All',
        },
        enterprise: {
            title: <p>Enterprise <br /> (Up to 3 accounts)</p>,
            buttonTitle: 'Contact Sales',
            month: '',
            year: <>${planPricing.enterprise.perCoach}/month/coach <br />(${planPricing.enterprise.annually} billed annually)</>,
            numStudents: 'Unlimited students',
            coachLicense: 'Up to 3',
            devices: '2/user',
            sessions: 'Sessions capped by cloud storage',
            cloud: '1TB(~100K swings)',
            trackers: 'All',
        }
    }

    const getPlanData = (onlyKeys = false) => {
        let filter = []
        let filterSub = []
        if (props.useTabs) {
            if (tab === 'coach') {
                filter = ['lite', 'premium', 'enterprise']
            } else {
                filter = ['free', 'practice']
            }
        }
        if (props.subscriptionType?.includes('free') || props.subscriptionType === '') {
            filterSub = ["free", "practice", "lite", "premium", "enterprise"]
        }
        else if (["development_sportsbox_student_lite_monthly", "development_sportsbox_student_lite_annually"].includes(props.subscriptionType)) {
            filterSub = ["free", "practice"]
        } else {
            filterSub = ["free", "lite", "premium", "enterprise"]
        }
        if (onlyKeys) {
            return Object.keys(planData).filter((plan) => !props.useTabs || filter.includes(plan)).filter((plan) => !props.checkSub || filterSub.includes(plan))
        }
        return Object.entries(planData).filter(([key, value]) => !props.useTabs || filter.includes(key)).filter(([key, value]) => !props.checkSub || filterSub.includes(key))
    }
    const getAction = (planName) => {
        return props.actions[planName] ? props.actions[planName] : props.actions.default
    }

    const handleAnswer = (index) => (e, answer) => {
        const newAnswers = [...answers]
        newAnswers[index] = parseInt(answer)
        setAnswers(newAnswers)
    }

    /*const checkAnswers = () => {
        const correctAnswers = answers.map((answer, index) => answer === practiceQuestions[index].answer)
        if (correctAnswers.every((answer) => answer)) {
            getAction('practice')(getPlan('practice'))
        } else {
            const incorrectAnswers = correctAnswers.map((answer, index) => answer ? null : index + 1).filter((val) => val)
            const incorrectAnswersString = incorrectAnswers.map((answer, index) => `${incorrectAnswers.length === index + 1 && incorrectAnswers.length > 1 ? 'and ' : ''}${answer}`).join(', ')
            Swal.fire({
                title: `<p style="font-size:70%;">Sorry, your answer${incorrectAnswers.length > 1 ? 's' : ''} for: ` + incorrectAnswersString + (incorrectAnswers.length > 1 ? ' are' : ' is') + ' not correct. Please try again!</p>',
                icon: 'error',
                showConfirmButton: true,
                showCancelButton: false,
                confirmButtonText: "Ok",
                customClass: {
                    container: 'my-swal'
                },
            });
        }
    }*/



    const { classes } = props;
    const keys = getPlanData(true)
    const showCoach = keys.filter((key) => ['lite', 'premium', 'enterprise'].includes(key)).length > 0
    const showStudent = keys.filter((key) => ['free', 'practice'].includes(key)).length > 0


    const margin = isMobile ? 0 : 20
    return (
        <MuiThemeProvider theme={theme}>
            <div className={classes.paper}>
                <Typography variant='h1' className={classes.titleText}>PRICING</Typography>
                <Typography variant='subtitle1' style={{ color: 'white' }}>For coaches: <a href='https://meetings.hubspot.com/edwin-fuh/product-demo' target='_blank' rel='noopener noreferrer' className={classes.link}> Book a 15-minute appointment</a> to get a free 14-day trial.</Typography>
                <Typography variant='subtitle1' style={{ color: 'white' }}>For teams of two or more coaches, please contact us for enterprise offers <a href='https://sportsbox-21571110.hubspotpagebuilder.com/sportsbox-3d-golf-enterprise-form' target='_blank' rel='noopener noreferrer' className={classes.link}>HERE</a>.</Typography>
                <div className={classes.newFeature} style={{ marginTop: 15 }}>
                    <Typography variant='h3' style={{ color: 'white', marginBottom: 10, fontFamily: 'Anton' }}>ALL NEW: 3D PRACTICE</Typography>
                    <Typography variant='subtitle1' style={{ color: 'white' }}>If you don't have a 3DGolf Coach, find one <a href='https://www.sportsbox.ai/directory' target='_blank' rel='noopener noreferrer' className={classes.link2}>here</a> and ask them about 3D Practice</Typography>
                    <Typography variant='subtitle1' style={{ color: 'white' }}>If your Coach doesn't teach with Sportsbox, nominate them <a href='https://share.hsforms.com/1o3VZmMKSQyKZHSD_6uL5bgcucdi' target='_blank' rel='noopener noreferrer' className={classes.link2}>here</a>.</Typography>
                    <br />
                    <Typography variant='subtitle1' style={{color: 'white'}}><u>FALL PROMO: UP TO 40% OFF + FREE SPORTSBOX 3DGOLF LEVEL 1 CERTIFICATION</u></Typography>
                </div>
            </div>
            <Grid container style={{ marginTop: 20, display: 'flex', justifyContent: props.useTabs ? 'space-between' : 'flex-end' }}>
                <Grid item xs={3} />
                {props.useTabs && (<>

                    <Grid item xs={6} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                        <Typography variant='h1' style={{ fontFamily: 'Anton', color: 'black' }}>{tab === 'coach' ? "COACH" : "STUDENT"} PRICING</Typography>
                        <ToggleButtonGroup
                            value={tab}
                            color='primary'
                            exclusive
                            onChange={(event, value) => setTab(value ? value : tab)}>
                            <ToggleButton size='small' value='coach'>Coach Plans</ToggleButton>
                            <ToggleButton size='small' value='student'>Student Plans</ToggleButton>
                        </ToggleButtonGroup>
                    </Grid></>
                )}
                <Grid item xs={3} style={{ paddingRight: 20 }}>
                </Grid>
            </Grid>

            <div style={{ paddingLeft: margin, paddingRight: margin }}>
                <TableContainer style={{ marginBottom: 200, marginTop: 20, border: '1px solid #e0e0e0', borderRadius: 10 }}>
                    <Table >
                        <TableHead>
                            <TableRow>
                                <TableCellName>
                                    {!props.disableBillingOptions && (<>
                                        <Typography variant='h4'>Billed</Typography>
                                        <RadioGroup
                                            value={props.billing}
                                            onChange={props.handleChange('billing')}>
                                            <FormControlLabel value='monthly' control={<Radio />} label="Monthly" />
                                            <FormControlLabel value='annually' control={<Radio />} label="Annually (Save 17%)" />
                                        </RadioGroup></>)}
                                </TableCellName>
                                {getPlanData().map(([key, value]) => (
                                    <TableCellCenter key={key}>
                                        <Typography variant='h4'><b>{value.title}</b></Typography>
                                        <div className="start-or-purchase">
                                            <Button className={classes.linkedButton}
                                                variant="contained"
                                                color="primary"
                                                onClick={() => getAction(key)(getPlan(key))}
                                            >
                                                {value.buttonTitle}
                                            </Button>
                                            <br />
                                            {(key === 'lite' || key === 'premium') && (
                                                <Button className={classes.linkedButton}
                                                    variant="outlined"
                                                    color="primary"
                                                    onClick={() => {
                                                        getAction(key)(getPlan(key));
                                                        window.localStorage.setItem('freeTrial', 'true');
                                                    }}
                                                >
                                                    {value.buttonTitle2}
                                                </Button>
                                            )}
                                        </div>
                                    </TableCellCenter>
                                ))}

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCellName><Typography variant='h5'>Billed Monthly</Typography></TableCellName>
                                {getPlanData().map(([key, value]) => (
                                    <TableCellCenter key={key}>{value.month}</TableCellCenter>
                                ))}
                            </TableRow>
                            <TableRow>
                                <TableCellName><Typography variant='h5'>Billed Annually</Typography></TableCellName>
                                {getPlanData().map(([key, value]) => (
                                    <TableCellCenter key={key}>{value.year}</TableCellCenter>
                                ))}
                            </TableRow>
                            <TableRow>
                                <TableCellName><Typography variant='h4' className={classes.tableHeader}>General</Typography></TableCellName>
                                {getPlanData(true).map((key) => (<TableCell key={key} />))}
                            </TableRow>
                            <TableRow>
                                <TableCellName><Typography variant='h5'># of student accounts</Typography></TableCellName>
                                {getPlanData().map(([key, value]) => (
                                    <TableCellCenter key={key}>{value.numStudents}</TableCellCenter>
                                ))}
                            </TableRow>
                            <TableRow>
                                <TableCellName><Typography variant='h5'># of coach licenses</Typography></TableCellName>
                                {getPlanData().map(([key, value]) => (
                                    <TableCellCenter key={key}>{value.coachLicense}</TableCellCenter>
                                ))}
                            </TableRow>
                            <TableRow>
                                <TableCellName><Typography variant='h5'># of devices</Typography></TableCellName>
                                {getPlanData().map(([key, value]) => (
                                    <TableCellCenter key={key}>{value.devices}</TableCellCenter>
                                ))}
                            </TableRow>
                            <TableRow>
                                <TableCellName><Typography variant='h5'>Sessions</Typography></TableCellName>
                                {getPlanData().map(([key, value]) => (
                                    <TableCellCenter key={key}>{value.sessions}</TableCellCenter>
                                ))}
                            </TableRow>
                            <TableRow>
                                <TableCellName><Typography variant='h5'>Auto-upload sessions</Typography></TableCellName>
                                {getPlanData(true).map((key) => (
                                    <TableCellCenter key={key}>{key !== 'free' ? <Check /> : <Close />}</TableCellCenter>
                                ))}
                            </TableRow>
                            <TableRow>
                                <TableCellName><Typography variant='h5'>Cloud storage</Typography></TableCellName>
                                {getPlanData().map(([key, value]) => (
                                    <TableCellCenter key={key}>{value.cloud}</TableCellCenter>
                                ))}
                            </TableRow>
                            <TableRow>
                                <TableCellName><Typography variant='h5'>Access to 3DGolf education and learning portal</Typography></TableCellName>
                                {getPlanData(true).map((key) => (
                                    <TableCellCenter key={key}><Check /></TableCellCenter>
                                ))}
                            </TableRow>
                            <TableRow>
                                <TableCellName><Typography variant='h5'>Create and manage sessions on 3DGolf Website</Typography></TableCellName>
                                {getPlanData(true).map((key) => (
                                    <TableCellCenter key={key}>{key !== 'free' ? <Check /> : <Close />}</TableCellCenter>
                                ))}
                            </TableRow>




                            <TableRow>
                                <TableCellName><Typography variant='h4' className={classes.tableHeader}>Analysis Tools</Typography></TableCellName>
                                {getPlanData(true).map((key) => (<TableCell key={key} />))}
                            </TableRow>
                            <TableRow>
                                <TableCellName><Typography variant='h5'>Trackers</Typography></TableCellName>
                                {getPlanData().map(([key, value]) => (
                                    <TableCellCenter key={key}>{value.trackers}</TableCellCenter>
                                ))}
                            </TableRow>
                            <TableRow>
                                <TableCellName><Typography variant='h5'>Record video for 3D Analysis</Typography></TableCellName>
                                {getPlanData(true).map((key) => (
                                    <TableCellCenter key={key}>{key !== 'free' ? <Check /> : <Close />}</TableCellCenter>
                                ))}
                            </TableRow>
                            <TableRow>
                                <TableCellName><Typography variant='h5'>Import video for 3D Analysis</Typography></TableCellName>
                                {getPlanData(true).map((key) => (
                                    <TableCellCenter key={key}>{key !== 'free' ? <Check /> : <Close />}</TableCellCenter>
                                ))}
                            </TableRow>
                            <TableRow>
                                <TableCellName><Typography variant='h5'>Auto swing detection and voice guidance</Typography></TableCellName>
                                {getPlanData(true).map((key) => (
                                    <TableCellCenter key={key}>{key !== 'free' ? <Check /> : <Close />}</TableCellCenter>
                                ))}
                            </TableRow>



                            <TableRow>
                                <TableCellName><Typography variant='h4' className={classes.tableHeader}>Practice Tools</Typography></TableCellName>
                                {getPlanData(true).map((key) => (<TableCell key={key} />))}
                            </TableRow>
                            <TableRow>
                                <TableCellName><Typography variant='h5'>Inbox to communicate with coaches/students</Typography></TableCellName>
                                {getPlanData(true).map((key) => (
                                    <TableCellCenter key={key}><Check /></TableCellCenter>
                                ))}
                            </TableRow>
                            <TableRow>
                                <TableCellName><Typography variant='h5'>Create reference swing</Typography></TableCellName>
                                {getPlanData(true).map((key) => (
                                    <TableCellCenter key={key}>{['free', 'practice'].includes(key) ? <Close /> : <Check />}</TableCellCenter>
                                ))}
                            </TableRow>
                            <TableRow>
                                <TableCellName><Typography variant='h5'>Compare 2 swings</Typography></TableCellName>
                                {getPlanData(true).map((key) => (
                                    <TableCellCenter key={key}>{['free', 'practice'].includes(key) ? <Close /> : <Check />}</TableCellCenter>
                                ))}
                            </TableRow>
                            <TableRow>
                                <TableCellName><Typography variant='h5'>Screen recording with voiceover</Typography></TableCellName>
                                {getPlanData(true).map((key) => (
                                    <TableCellCenter key={key}>{['free', 'practice'].includes(key) ? <Close /> : <Check />}</TableCellCenter>
                                ))}
                            </TableRow>
                            <TableRow>
                                <TableCellName><Typography variant='h5'>Create and assign custom watchlists</Typography></TableCellName>
                                {getPlanData(true).map((key) => (
                                    <TableCellCenter key={key}>{['free', 'practice'].includes(key) ? <Close /> : <Check />}</TableCellCenter>
                                ))}
                            </TableRow>



                            <TableRow style={{ display: showStudent ? props.useTabs ? 'none' : '' : 'none' }}>
                                <TableCellName><Typography variant='h4' className={classes.tableHeader}>Student Practice Tools</Typography></TableCellName>
                                {getPlanData(true).map((key) => (<TableCell key={key} />))}
                            </TableRow>
                            <TableRow style={{ display: showStudent ? '' : 'none' }}>
                                <TableCellName><Typography variant='h5'>Access to popular watchlists</Typography></TableCellName>
                                {getPlanData(true).map((key) => (
                                    <TableCellCenter key={key}>{['free', 'practice'].includes(key) ? <Check /> : null}</TableCellCenter>
                                ))}
                            </TableRow>
                            <TableRow style={{ display: showStudent ? '' : 'none' }}>
                                <TableCellName><Typography variant='h5'>Practice with watchlists</Typography></TableCellName>
                                {getPlanData().map(([key, value]) => (
                                    <TableCellCenter key={key}>{['free', 'practice'].includes(key) ? value.pracWatch : null}</TableCellCenter>
                                ))}
                            </TableRow>




                            <TableRow style={{ display: showCoach ? props.useTabs ? 'none' : '' : 'none' }}>
                                <TableCellName><Typography variant='h4' className={classes.tableHeader}>Coach Tools</Typography></TableCellName>
                                {getPlanData(true).map((key) => (<TableCell key={key} />))}
                            </TableRow>
                            <TableRow style={{ display: showCoach ? '' : 'none' }}>
                                <TableCellName><Typography variant='h5'>Assign popular watchlists</Typography></TableCellName>
                                {getPlanData(true).map((key) => (
                                    <TableCellCenter key={key}>{['free', 'practice'].includes(key) ? null : <Check />}</TableCellCenter>
                                ))}
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <Snackbar
                open={Boolean(snackbar)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={3000}
                onClose={() => setSnackbar(null)}
            >
                <Alert severity={snackbar?.type}>{snackbar?.message}</Alert>
            </Snackbar>
            {/*<Dialog
                open={openQuiz}
                fullScreen={isMobile}>
                <DialogTitle>
                    PLEASE FIRST WATCH THIS 3-MINUTE QUICK START VIDEO
                </DialogTitle>
                <DialogContent>
                    <form>
                        <Grid container spacing={2}>
                            <Grid
                                item
                                xs={12}
                            >
                                <video title='practiceVid' width="100%" height="400" src="/images/3DPracticePlan.mp4" scrolling="no" controlsList="nodownload" controls="controls" allowFullScreen preload="metadata" autoPlay playsInline type="video/mp4" />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                            >
                                <b>Please answer these questions:</b>
                            </Grid>
                            {practiceQuestions.map((q, index) => (
                                <Grid item xs={6} key={index}>
                                    <FormControl>
                                        <FormLabel><b style={{ color: 'black', fontSize: '16px' }}>{index + 1}. {q.question}</b></FormLabel>
                                        <RadioGroup value={answers[index]} onChange={handleAnswer(index)}>
                                            {q.options.map((option, optionIndex) => (<>
                                                <FormControlLabel key={optionIndex} value={optionIndex} control={<Radio color='primary' />} label={<p style={{ fontSize: '14px' }}>{option}</p>} />
                                                <hr />
                                            </>))}
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            ))}
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenQuiz(false)} color="primary">
                        Go Back
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => { checkAnswers() }}
                        disabled={answers.filter(a => a === -1).length > 0}
                    >
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>*/}
        </MuiThemeProvider>)
}


export default withStyles(useStyles)(PlansView);
