import React from "react";
import {
  EmptySelector,
  PracticeGuideDetailsContainer,
  PracticeGuideFormColumn,
  PracticeGuideMediaColumn,
  StatusBadge,
} from "../styled";
import {
  FormControl,
  InputLabel,
  TextField,
  Select,
  Dialog,
  DialogContent,
  MenuItem,
} from "@material-ui/core";
import { Pad } from "../../../components";
import { MediaUpload } from "./MediaUpload";
import {
  ModalContentFooter,
  ModalContentHeader,
  ModalContentTitle,
} from "./styled";
import CloseIcon from "@material-ui/icons/Close";
import { EquipmentModal } from "./practiceGuide/EquipmentModal";
import { useState } from "react";
import { OutlinedButton } from "./common/styled";
import { PracticeGuideSummaryModal } from "./practiceGuide/PracticeGuideSummaryModal";
import { useEffect } from "react";
import { getPracticeGuideById, getTipsAndDrills } from "../api_test";
import { FilledSummary } from "./practiceGuide/FilledSummary";
import { WatchlistModal } from "./practiceGuide/WatchlistModal";
import { AssessmentModal } from "./practiceGuide/AssessmentModal";
import { FilledAssessment } from "./practiceGuide/FilledAssessment";
import { ExitDetailsModal } from "./ExitDetailsModal";
import { ConfirmSaveModal } from "./common/ConfirmSaveModal";
import { WatchlistSelectedTrackersItem } from "./practiceGuide/styled";
import { FilledWatchlistTrackers } from "./practiceGuide/FilledWatchlistTrackers";
import { Tags } from "./common/Tags";
import { hardcodedData } from "../config";
import { getWatchlistCsvData } from "../api_test/common";
import uuid from "react-uuid";
import { PracticeGuideWatchlist } from "./common/watchlist/PracticeGuideWatchlist";

export function PracticeGuideDetailsModal({
  open,
  onClose,
  initId,
  onUpdate,
  onSave,
}) {
  const [form, setForm] = useState({
    title: "",
    description: "",
    skill: "",
    goal: "",
    environment: "",
    fault: "",
    warmup: [],
    drill: [],
    benchmarkAssessment: null,
    finalAssessment: null,
    watchlist: [],
    tags: [],
    targetPractice: "None",
  });
  const [media, setMedia] = useState({
    coverImage: { src: "", file: null },
    introVideo: { src: "", file: null, duration: 0 },
  });
  const [modal, setModal] = useState({
    summary: { open: false, type: null },
    watchlist: { open: false, init: [] },
    assessment: { open: false, type: null },
    exit: { open: false },
    confirm: { open: false },
  });
  const [initTitle, setInitTitle] = useState(null);
  const [tipsAndDrills, setTipsAndDrills] = useState([]);
  const [watchlistData, setWatchlistData] = useState({
    popularWatchlistsData: [],
    trackersData: [],
    rangesData: [],
    positionsData: [],
  });

  useEffect(() => {
    (async () => {
      const data = await getTipsAndDrills();
      setTipsAndDrills(data);
      const wl = await getWatchlistCsvData();
      setWatchlistData(wl);
    })();
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const data = await getTipsAndDrills();
      setTipsAndDrills(data);
    };
    fetch();
  }, [form.warmup, form.drill]);

  useEffect(() => {
    if (
      initId &&
      watchlistData.popularWatchlistsData.length !== 0 &&
      watchlistData.trackersData.length !== 0 &&
      watchlistData.positionsData.length !== 0
    ) {
      const fetch = async () => {
        const {
          title,
          description,
          skillLevel,
          category,
          summary,
          goal,
          environment,
          coverImage,
          introVideo,
          watchlist,
          tags,
        } = await getPracticeGuideById(initId);

        const {
          warmup,
          drill,
          initialAssessment,
          finalAssessment,
          targetPractice,
        } = summary;

        setInitTitle(title);

        const watchlistMapper = () => {
          return watchlist.map((el) => {
            const trackers = el.trackers.map((trc) => {
              let trackerName = "";
              if (el.watchlistId === "CUSTOM") {
                const { DisplayName } = watchlistData.trackersData.find(
                  (trcData) => trcData.TrackerID === trc.trackerId
                );
                const { title } = watchlistData.positionsData.find(
                  (posData) => posData.id === trc.position
                );
                trackerName = `${DisplayName} at ${title}`;
              } else {
                const { Name } = watchlistData.popularWatchlistsData
                  .find((wlData) => wlData.WATCHLIST_ID == el.watchlistId)
                  .indicatorData.find(
                    (indData) => indData.ID.toUpperCase() === trc.trackerId
                  );
                trackerName = Name;
              }
              return { ...trc, trackerName };
            });
            return { ...el, trackers, uuid: uuid() };
          });
        };

        setForm({
          title,
          description,
          skill: skillLevel,
          goal,
          environment,
          fault: category,
          warmup: warmup ? warmup.videoIds : [],
          drill: drill ? drill.videoIds : [],
          benchmarkAssessment: initialAssessment
            ? {
                ...initialAssessment,
                title: initialAssessment.golfClub,
                reps: initialAssessment.numberOfShots,
              }
            : null,
          finalAssessment: finalAssessment
            ? {
                ...finalAssessment,
                title: finalAssessment.golfClub,
                reps: finalAssessment.numberOfShots,
              }
            : null,
          watchlist: watchlistMapper(),
          tags,
          targetPractice: targetPractice
            ? targetPractice.title
              ? targetPractice.title
              : ""
            : "",
        });
        setMedia({
          coverImage: { src: coverImage, file: null, duration: 0 },
          introVideo: { src: introVideo.videoPath, file: null, duration: 0 },
        });
      };
      fetch();
    }
  }, [initId, watchlistData]);

  const handleInputs = (e) => {
    e.persist();
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSelectors = (name, values) => {
    setForm((prev) => ({ ...prev, [name]: [...prev[name], ...values] }));
  };

  const handleSelectorsOverride = (name, values) => {
    setForm((prev) => ({ ...prev, [name]: values }));
  };

  const handleAssessmentAdd = (name, values) => {
    setForm((prev) => ({ ...prev, [name]: values }));
  };

  const handleUpload = (e) => {
    e.persist();
    setMedia((prev) => ({
      ...prev,
      [e.target.name]: {
        file: e.target.files[0],
        src: URL.createObjectURL(e.target.files[0]),
        duration: 0,
      },
    }));
  };

  const handleDeleteMedia = (name) => {
    setMedia((prev) => ({
      ...prev,
      [name]: {
        file: null,
        src: "",
        duration: 0,
      },
    }));
  };

  const handleMetadata = (name, duration) => {
    if (!media[name].duration) {
      setMedia((prev) => ({
        ...prev,
        [name]: {
          ...prev[name],
          duration,
        },
      }));
    }
  };

  const handleSave = () => {
    setModal((prev) => ({ ...prev, confirm: { open: true } }));
  };

  const handleConfirmSave = (status) => {
    handleClose();
    if (initId) onUpdate(initId, { ...form, ...media, status });
    else onSave({ ...form, ...media, status });
  };

  const filterTipsAndDrills = (idArr) => {
    // const filtered = tipsAndDrills.filter((el) => idArr.includes(el.id));
    const filt = idArr.map((el) => tipsAndDrills.find((fnd) => fnd.id === el));
    return filt;
  };

  const handleSummaryItemRemove = (id, type) => {
    setForm((prev) => ({
      ...prev,
      [type]: prev[type].filter((el) => el !== id),
    }));
  };

  const handleSummaryReorder = (newArr, type) => {
    setForm((prev) => {
      return {
        ...prev,
        [type]: newArr,
      };
    });
  };

  const handleAssessmentItemRemove = (id) => {
    setForm((prev) => ({
      ...prev,
      benchmarkAssessment: null,
      finalAssessment: null,
    }));
  };

  const handleWatchlistItemRemove = (id) => {
    setForm((prev) => ({
      ...prev,
      watchlist: prev.watchlist.filter((el) => el.uuid !== id),
    }));
  };

  const handleTagRemove = (id) => {
    setForm((prev) => ({ ...prev, tags: prev.tags.filter((el) => el !== id) }));
  };

  const handleClose = () => {
    onClose();
    setForm({
      title: "",
      description: "",
      skill: "",
      goal: "",
      environment: "",
      fault: "",
      warmup: [],
      drill: [],
      watchlist: [],
      benchmarkAssessment: null,
      finalAssessment: null,
      tags: [],
      targetPractice: null,
    });
    setModal({
      summary: { open: false, type: null },
      watchlist: { open: false, init: [] },
      assessment: { open: false, type: null },
      exit: { open: false },
      confirm: { open: false },
    });
    setMedia({
      coverImage: { src: "", file: null },
      introVideo: { src: "", file: null },
    });
  };

  const checkValidSave = () => {
    const saveOptions = {
      private: false,
      published: false,
    };
    if (form.title !== "" && form.description !== "") {
      saveOptions.private = true;
    }
    if (
      form.title !== "" &&
      form.description !== "" &&
      form.skill !== "" &&
      form.goal !== "" &&
      form.environment !== "" &&
      form.fault !== "" &&
      form.drill.length !== 0 &&
      form.targetPractice !== null &&
      form.tags.length !== 0 &&
      media.coverImage.src !== ""
    ) {
      saveOptions.published = true;
    }
    return saveOptions;
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => setModal((prev) => ({ ...prev, exit: { open: true } }))}
    >
      <DialogContent style={{ background: "#f3f3f3", padding: "0" }}>
        <ModalContentHeader>
          <div>{initTitle ? initTitle : "Guide Title"}</div>
          <CloseIcon
            style={{ cursor: "pointer" }}
            onClick={() =>
              setModal((prev) => ({
                ...prev,
                exit: { open: true },
              }))
            }
          />
        </ModalContentHeader>
        <PracticeGuideDetailsContainer>
          <PracticeGuideFormColumn>
            <ModalContentTitle>Guide Detail</ModalContentTitle>
            <TextField
              value={form.title}
              onChange={handleInputs}
              variant="outlined"
              name="title"
              label="Guide Title"
            />
            <Pad height="10px" />
            <TextField
              value={form.description}
              onChange={handleInputs}
              variant="outlined"
              name="description"
              label="Description"
              multiline
              rows={4}
            />
            <Pad height="10px" />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <FormControl style={{ width: "30%" }} variant="outlined">
                <InputLabel id="skill-label">Skill Level</InputLabel>
                <Select
                  lableId="skill-label"
                  value={form.skill}
                  onChange={handleInputs}
                  name="skill"
                  label="Skill Level"
                >
                  {hardcodedData.skill.map((el) => (
                    <MenuItem key={el} value={el}>
                      {el}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl style={{ width: "30%" }} variant="outlined">
                <InputLabel id="goal-label">Goal</InputLabel>
                <Select
                  lableId="goal-label"
                  value={form.goal}
                  onChange={handleInputs}
                  name="goal"
                  label="Goal"
                >
                  {hardcodedData.goal.map((el) => (
                    <MenuItem key={el} value={el}>
                      {el}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl style={{ width: "30%" }} variant="outlined">
                <InputLabel id="environment-label">Environment</InputLabel>
                <Select
                  lableId="environment-label"
                  value={form.environment}
                  onChange={handleInputs}
                  name="environment"
                  label="Environment"
                >
                  {hardcodedData.environment.map((el) => (
                    <MenuItem key={el} value={el}>
                      {el}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <Pad height="10px" />
            <FormControl variant="outlined">
              <InputLabel id="fault-label">Category</InputLabel>
              <Select
                lableId="fault-label"
                value={form.fault}
                onChange={handleInputs}
                name="fault"
                label="Category"
              >
                {hardcodedData.category.map((el) => (
                  <MenuItem key={el} value={el}>
                    {el}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <PracticeGuideWatchlist
              form={form.watchlist}
              handleSelectors={handleSelectors}
              handleRemove={handleWatchlistItemRemove}
            />
            <Pad height="10px" />

            <ModalContentTitle>Summary</ModalContentTitle>

            {form.warmup.length === 0 ? (
              <EmptySelector
                onClick={() =>
                  setModal((prev) => ({
                    ...prev,
                    summary: { open: true, type: "warmup" },
                  }))
                }
              >
                Warm-up
              </EmptySelector>
            ) : (
              <FilledSummary
                title="warm-up"
                type="warmup"
                items={filterTipsAndDrills(form.warmup)}
                onRemove={handleSummaryItemRemove}
                onReorder={handleSummaryReorder}
                onAdd={() =>
                  setModal((prev) => ({
                    ...prev,
                    summary: { open: true, type: "warmup" },
                  }))
                }
              />
            )}
            <Pad height="10px" />
            {!form.benchmarkAssessment ? (
              <EmptySelector
                onClick={() =>
                  setModal((prev) => ({
                    ...prev,
                    assessment: {
                      open: true,
                      type: "benchmarkAssessment",
                    },
                  }))
                }
              >
                Benchmark Assesment
              </EmptySelector>
            ) : (
              <FilledAssessment
                item={form.benchmarkAssessment}
                title="Benchamark Assessment"
                onRemove={handleAssessmentItemRemove}
                onAdd={() =>
                  setModal((prev) => ({
                    ...prev,
                    assessment: {
                      open: true,
                      type: "finalAssessment",
                    },
                  }))
                }
              />
            )}
            <Pad height="10px" />
            {form.drill.length === 0 ? (
              <EmptySelector
                onClick={() =>
                  setModal((prev) => ({
                    ...prev,
                    summary: { open: true, type: "drill" },
                  }))
                }
              >
                Drill
              </EmptySelector>
            ) : (
              <FilledSummary
                title="drill"
                type="drill"
                items={filterTipsAndDrills(form.drill)}
                onRemove={handleSummaryItemRemove}
                onReorder={handleSummaryReorder}
                onAdd={() =>
                  setModal((prev) => ({
                    ...prev,
                    summary: { open: true, type: "drill" },
                  }))
                }
              />
            )}
            <Pad height="10px" />
            {!form.finalAssessment ? (
              <EmptySelector
                onClick={() =>
                  setModal((prev) => ({
                    ...prev,
                    assessment: {
                      open: true,
                      type: "finalAssessment",
                    },
                  }))
                }
              >
                Final Assessment
              </EmptySelector>
            ) : (
              <FilledAssessment
                item={form.finalAssessment}
                title="Final Assessment"
                onRemove={handleAssessmentItemRemove}
                onAdd={() =>
                  setModal((prev) => ({
                    ...prev,
                    assessment: {
                      open: true,
                      type: "finalAssessment",
                    },
                  }))
                }
              />
            )}
            <Pad height="10px" />
            {/* <EmptySelector>Target Practice</EmptySelector> */}
            <FormControl variant="outlined">
              <InputLabel id="target-practice-label">
                Target Practice
              </InputLabel>
              <Select
                lableId="target-practice-label"
                value={form.targetPractice}
                onChange={handleInputs}
                name="targetPractice"
                label="Target Practice"
              >
                <MenuItem value="None">None</MenuItem>
                <MenuItem value="10 Shots">10 Shots</MenuItem>
              </Select>
            </FormControl>
          </PracticeGuideFormColumn>
          <PracticeGuideMediaColumn>
            <MediaUpload
              onMetadata={handleMetadata}
              uploadFn={handleUpload}
              deleteFn={handleDeleteMedia}
              media={media}
            />
            <ModalContentTitle>Tags</ModalContentTitle>
            <Tags form={form.tags} onSave={handleSelectorsOverride} />
          </PracticeGuideMediaColumn>
        </PracticeGuideDetailsContainer>
        <ModalContentFooter>
          <StatusBadge status="draft">draft</StatusBadge>
          <OutlinedButton onClick={handleSave} color="#580CE3">
            save
          </OutlinedButton>
        </ModalContentFooter>
        <ExitDetailsModal
          open={modal.exit.open}
          onClose={() =>
            setModal((prev) => ({ ...prev, exit: { open: false } }))
          }
          onConfirm={handleClose}
        />
        <AssessmentModal
          open={modal.assessment.open}
          type={modal.assessment.type}
          onClose={() => setModal((prev) => ({ ...prev, assessment: false }))}
          onSave={handleAssessmentAdd}
        />
        <PracticeGuideSummaryModal
          open={modal.summary.open}
          type={modal.summary.type}
          onClose={() => setModal((prev) => ({ ...prev, summary: false }))}
          onSave={handleSelectors}
        />

        <ConfirmSaveModal
          open={modal.confirm.open}
          onClose={() =>
            setModal((prev) => ({
              ...prev,
              confirm: { open: false },
            }))
          }
          onSave={handleConfirmSave}
          validation={checkValidSave}
        />
      </DialogContent>
    </Dialog>
  );
}
