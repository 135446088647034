import { Dialog, DialogContent } from "@material-ui/core";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { FlexWrap, Pad } from "../../../../components";
import { FilledButton } from "../common/styled";
import { ModalTitle } from "../styled";
import {
  EqupmentItemContent,
  WatchlistItemContainer,
  WatchlistItemSubContainer,
} from "../practiceGuide/styled";
import { getPracticeGuides } from "../../api_test";
import { PracticeGuideModalChoice } from "./PracticeGuideModalChoice";

export function AlbumPracticeGuideModal({ open, init, onClose, onSave }) {
  const [selected, setSelected] = useState([]);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const data = await getPracticeGuides();
      setTableData(data);
    };
    fetch();
  }, [open]);

  useEffect(() => {
    if (init.length !== 0) setSelected(init);
  }, [init]);

  const handleSave = () => {
    handleClose();
    onSave("practiceGuides", selected);
  };

  const handleSelect = (id) => {
    if (selected.includes(id))
      setSelected((prev) => prev.filter((el) => el !== id));
    else setSelected((prev) => [...prev, id]);
  };

  const handleClose = () => {
    onClose();
    setSelected([]);
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
      <DialogContent style={{ padding: "30px" }}>
        <ModalTitle>Practice Guides</ModalTitle>
        <Pad margin="20px 0" width="100%">
          {tableData.map((el) => (
            <div key={el.id}>
              <PracticeGuideModalChoice
                data={el}
                onClick={handleSelect}
                active={selected.includes(el.id)}
              />
              <Pad height="10px" />
            </div>
          ))}
        </Pad>
        <FlexWrap justify="space-between">
          <FilledButton onClick={handleClose} width="48%" color="#808080">
            cancel
          </FilledButton>
          <FilledButton
            onClick={() => handleSave()}
            width="48%"
            color="#580CE3"
          >
            confirm
          </FilledButton>
        </FlexWrap>
      </DialogContent>
    </Dialog>
  );
}
