import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import { Topbar, Footer } from './components';


const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 64,
    height: '100%',
    minHeight: '100vh',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
    },
  },
  content: {
    height: '100%',
    paddingTop: 80,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
    },
  },
}));

const Sale = (props) => {
  const [plansType, setPlansType] = useState('coaches');
  const [startWithPlan, setStartWithPlan] = useState('');
  const classes = useStyles();

  const handlePlanType = (value) => {
    setPlansType(value);
  }

  const getStarted = (value) => {
    setStartWithPlan(value);
  }

  const children = React.cloneElement(props.children, {
    handlePlanType: handlePlanType,
    getStarted: startWithPlan,
  });

  return (
    <div className={classes.root}>
      <Topbar />
      <main className={classes.content}>{children}</main>
      <Footer plansType={plansType} getStarted={getStarted} />
    </div>
  );
};

Sale.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Sale;