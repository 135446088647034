import React, { useEffect, useState } from "react";
import { Checkbox, Button, Typography, Divider } from "@material-ui/core";
import { FlexWrap, Pad } from "../../../../components";

export function PracticeGuideModalSummaryChoice({
    list,
    submit,
    cancel,
    type,
}) {
    const [selected, setSelected] = useState([]);
    const titles = {
        warmup: "Add Warm Up",
        drills: "Add Drills",
        targetPractice: "Add Target Practice",
    };

    const handleSelect = (e) => {
        const newVal = () => {
            const check = selected.findIndex((el) => el.uid === e.target.id);
            if (check !== -1) {
                return selected.filter(
                    (filterEl) => filterEl.uid !== e.target.id
                );
            } else {
                return [
                    ...selected,
                    list.find((findEl) => findEl.uid === e.target.id),
                ];
            }
        };
        setSelected(newVal());
    };

    const handleSelected = (id) => {
        return selected.findIndex((el) => el.uid === id) === -1 ? false : true;
    };

    return (
        <Pad width="450px" height="fit-content">
            <FlexWrap direction="column" align="start">
                <div>{titles[type]}</div>
                {list.map((el) => (
                    <FlexWrap
                        style={{ marginTop: "7px", width: "100%" }}
                        justify="space-between"
                        align="center"
                    >
                        <FlexWrap width="fit-content">
                            <img
                                style={{
                                    margin: "0 20px",
                                    borderRadius: "4px",
                                }}
                                width={50}
                                height={50}
                                src={el.video.thumbnail}
                            />
                            <Typography variant="p">{el.name}</Typography>
                        </FlexWrap>
                        <Checkbox
                            id={el.uid}
                            color="primary"
                            checked={handleSelected(el.uid)}
                            onChange={handleSelect}
                        />
                    </FlexWrap>
                ))}
            </FlexWrap>
            <Pad height="10px" />
            <Button
                style={{ marginRight: "10px" }}
                variant="contained"
                color="primary"
                onClick={() => submit(type, selected)}
            >
                submit
            </Button>

            <Button
                variant="contained"
                color="primary"
                onClick={() => cancel()}
            >
                cancel
            </Button>
        </Pad>
    );
}
