import admin from "../../../common/firebase";
import { fbMapper } from "../../../helpers/fbMapper";
import { firestorageExistCheck } from "./helper";

export const getTutorials = async () => {
    const tutorials = fbMapper(
        await admin.firestore().collection("mobileAppSettings").get()
    )[0].tutorials;
    return tutorials;
};

export const addTutorial = async (data) => {
    const { title, description, tutorialType, id, video } = data;

    const parsed = {
        title,
        description,
        tutorialType,
        id,
    };

    if (video.file) {
        var storageRef = admin.storage().ref();

        const vidExtension = video.file.name.split(".").pop();

        var vidFileRef = storageRef.child(
            `tutorials/videos/${Date.now()}.${vidExtension}`
        );

        const vidUploadTaskSnapshot = await vidFileRef.put(video.file);
        const vidDownloadURL = await vidUploadTaskSnapshot.ref.getDownloadURL();

        parsed.videoPath = vidDownloadURL;
        parsed.videoDuration = `${Math.floor(video.length)}`;
    }

    if (video.thumbnail) {
        var storageRef = admin.storage().ref();

        const thumbExtension = video.thumbnail.name.split(".").pop();
        var thumbFileRef = storageRef.child(
            `tutorials/thumbnails/${Date.now()}.${thumbExtension}`
        );
        const thumbUploadTaskSnapshot = await thumbFileRef.put(video.thumbnail);
        const thumbDownloadURL =
            await thumbUploadTaskSnapshot.ref.getDownloadURL();

        parsed.thumbVideoPath = thumbDownloadURL;
    }

    const tutData = fbMapper(
        await admin.firestore().collection("mobileAppSettings").get()
    )[0];

    await admin
        .firestore()
        .collection("mobileAppSettings")
        .doc(tutData.uid)
        .update({ tutorials: [...tutData.tutorials, parsed] });

    const tutorials = fbMapper(
        await admin.firestore().collection("mobileAppSettings").get()
    )[0].tutorials;

    return tutorials;
};

export const editTutorial = async (id, data) => {
    const { title, description, tutorialType, video, newVideo } = data;

    const tutData = fbMapper(
        await admin.firestore().collection("mobileAppSettings").get()
    )[0];

    const prev = tutData.tutorials.find((el) => el.id === id);

    const parsed = {
        title,
        description,
        tutorialType,
    };

    if (newVideo.file) {
        if (await firestorageExistCheck(prev.videoPath)) {
            await admin.storage().refFromURL(prev.videoPath).delete();
        }

        var storageRef = admin.storage().ref();

        const vidExtension = newVideo.file.name.split(".").pop();

        var vidFileRef = storageRef.child(
            `tutorials/video/${Date.now()}.${vidExtension}`
        );

        const vidUploadTaskSnapshot = await vidFileRef.put(newVideo.file);
        const vidDownloadURL = await vidUploadTaskSnapshot.ref.getDownloadURL();

        parsed.videoPath = vidDownloadURL;
        parsed.videoDuration = `${Math.floor(newVideo.length)}`;
    }

    if (newVideo.thumbnail) {
        if (await firestorageExistCheck(prev.thumbVideoPath)) {
            await admin.storage().refFromURL(prev.thumbVideoPath).delete();
        }

        var storageRef = admin.storage().ref();

        const thumbExtension = newVideo.thumbnail.name.split(".").pop();
        var thumbFileRef = storageRef.child(
            `tutorials/thumbnail/${Date.now()}.${thumbExtension}`
        );
        const thumbUploadTaskSnapshot = await thumbFileRef.put(
            newVideo.thumbnail
        );
        const thumbDownloadURL =
            await thumbUploadTaskSnapshot.ref.getDownloadURL();

        parsed.thumbVideoPath = thumbDownloadURL;
    }

    const result = {
        tutorials: tutData.tutorials.map((el) => {
            if (el.id === id) {
                return { ...el, ...parsed };
            } else return el;
        }),
    };

    await admin
        .firestore()
        .collection("mobileAppSettings")
        .doc(tutData.uid)
        .update(result);

    const tutorials = fbMapper(
        await admin.firestore().collection("mobileAppSettings").get()
    )[0].tutorials;

    return tutorials;
};

export const deleteTutorial = async (id) => {
    const tutData = fbMapper(
        await admin.firestore().collection("mobileAppSettings").get()
    )[0];

    const prev = tutData.tutorials.find((el) => el.id === id);

    if (await firestorageExistCheck(prev.videoPath)) {
        await admin.storage().refFromURL(prev.videoPath).delete();
    }

    if (await firestorageExistCheck(prev.thumbVideoPath)) {
        await admin.storage().refFromURL(prev.thumbVideoPath).delete();
    }

    const parsed = {
        tutorials: tutData.tutorials.filter((el) => el.id !== id),
    };

    await admin
        .firestore()
        .collection("mobileAppSettings")
        .doc(tutData.uid)
        .update(parsed);

    const tutorials = fbMapper(
        await admin.firestore().collection("mobileAppSettings").get()
    )[0].tutorials;

    return tutorials;
};
