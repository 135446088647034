import React, { useState } from 'react';

import { Button, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, Grid, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Swal from 'sweetalert2';

import firebase from '../../../../common/firebase';


function TagDialogComponent({ open, videos, close }) {
    const [tag, setTag] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (event) => {
        setTag(event.target.value);
    }

    const handleUpdate = async () => {
        try {
            setIsLoading(true);
            for (const video in videos) {
                await firebase.firestore().collection("videos").doc(video).update({
                    ssl2DTag: tag,
                })
            }
            setTag('');
            setIsLoading(false);
            Swal.fire({
                title: '<p style="font-size:70%;">Saved successfully</p>',
                icon: 'success',
                confirmButtonText: 'Ok',
                allowOutsideClick: false,
                timer: 2000
            });
            close();
        } catch (e) {
            setIsLoading(false);
            Swal.fire('<p style="font-size:70%;">Error occured</p>');
        }
    }

    return (<>
        <Dialog
            fullWidth
            open={open}
            onClose={close}
            aria-labelledby='form-dialog-title'>
            <DialogTitle id={'form-dialog-title'}>
                <div>
                    Add Tag
                    <CloseIcon style={{ float: 'right', marginTop: '-10px', marginRight: '-15px' }} onClick={close} />
                </div>
            </DialogTitle>
            <DialogContent>
                <Grid container component="main">
                    <Grid container spacing={2}>
                        <Grid
                            item
                            xs={12}
                        >
                            {!isLoading && (
                                <FormControl
                                    variant="outlined"
                                    fullWidth
                                >
                                    <InputLabel id="subPlan-select">Tag*</InputLabel>
                                    <Select
                                        id="tag"
                                        label="Tag"
                                        labelId="tag-select"
                                        name="tag"
                                        defaultValue={tag}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={''}>None</MenuItem>
                                        <MenuItem value={'2DSSL_Requested'}>2DSSL Requested</MenuItem>
                                        <MenuItem value={'2DSSL_Requested_DTL'}>2DSSL Requested DTL</MenuItem>
                                        <MenuItem value={'2DSSL_Invalid'}>2DSSL Invalid</MenuItem>
                                        <MenuItem value={'2DSSL_Labeled'}>2DSSL Labeled</MenuItem>
                                        <MenuItem value={'2DSSL_Trained'}>2DSSL Trained</MenuItem>
                                        <MenuItem value={'2DSSL_Improve'}>2DSSL Improve</MenuItem>
                                        <MenuItem value={'2DSSL_Improve_DTL'}>2DSSL Improve DTL</MenuItem>
                                        <MenuItem value={'2DSSL_Good'}>2DSSL Good</MenuItem>
                                        <MenuItem value={'2DSSL_Testing'}>2DSSL Testing</MenuItem>
                                    </Select>
                                </FormControl>
                            )}
                            {isLoading && (
                                <CircularProgress />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={close} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleUpdate} color="secondary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    </>);
}

export const TagDialog = TagDialogComponent;
