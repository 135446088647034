import React, {Component} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import firebase from '../../common/firebase'
import {withStyles} from '@material-ui/styles';
import {Grid, Button, Container, Avatar, TextField, Link,
  Typography, CssBaseline, CircularProgress, FormControl, 
  InputLabel, Select, MenuItem} from '@material-ui/core';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import {AccountNotFound} from '../account/components';
import * as admin from 'firebase/app';
import 'firebase/firestore';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    marginTop: theme.spacing(4),
  },
  buttonLink: {  // This is a link component surrounding a button component
    width: '100%'
  },
  linkedButton: {  // This is a button component surrounded by a link
    margin: theme.spacing(1, 0),
  }
});

class EditLabelingRequest extends Component {
  constructor(props) {
    super(props)
    const firebaseUser = firebase.auth().currentUser
    this.state = {
      uid : firebaseUser.uid,
      error : "",
      loading : true,
      success : false,
      requestID : "",
      name : "",
      purpose : "",
      videosType : "",
      dateCreated : "",
      numberOfFrames : "",
      instructions : "",
      numberOfPoints : "",
      descriptionOfVideos : "",
      locationOfVideos : "",
      descriptionOfFrames : "",
      requesterName : "",
      assignee : "",
      desiredDate : "",
      completionDate : "",
      status : "",
      labelers : []
    }
  }

  componentDidMount() {
    if (!this.state.uid) {   
      this.setState({error : "Please log in to edit a labeling request", loading : false})  
    } else {
      this.setState({
        loading : false
      })
    }
    this.getLabelers()
    this.loadData()
  }

  loadData = () => {
    let labReqId = localStorage.getItem('editLabReq');
    admin.firestore().collection('labelingRequests').doc(labReqId).get().then((querySnapshot) => {
      let out = querySnapshot.data();
      this.setState({
        requestID : out.requestID,
        name : out.name,
        purpose : out.purpose,
        videosType : out.videosType,
        dateCreated : out.dateCreated,
        numberOfFrames : out.numberOfFrames,
        instructions : out.instructions,
        numberOfPoints : out.numberOfPoints,
        descriptionOfVideos : out.descriptionOfVideos,
        locationOfVideos : out.locationOfVideos,
        descriptionOfFrames : out.descriptionOfFrames,
        requesterName : out.requesterName,
        assignee : out.assignee,
        desiredDate : out.desiredDate,
        completionDate : out.completionDate,
        status : out.status
      })
    })
    .catch(err => {
      console.log(err)
      this.setState({loading: false, error : "There was an error retrieving your info"});
    });
  }

  handleChange = (prop) => (event) => {
    this.setState({[prop]: event.target.value});
  }

  handleAssigneeChange = event => {
    this.setState({      
      [event.target.name]: event.target.value
    });
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.setState({loading: false});
    //Update labeling request firestore entry
    let labReqId = localStorage.getItem('editLabReq');
    window.localStorage.clear();
    const labReq = admin.firestore().collection('labelingRequests').doc(labReqId);
    labReq.update({'requestID': this.state.requestID});
    labReq.update({'name': this.state.name});
    labReq.update({'purpose': this.state.purpose});
    labReq.update({'videosType': this.state.videosType});
    labReq.update({'dateCreated': this.state.dateCreated});
    labReq.update({'numberOfFrames': this.state.numberOfFrames});
    labReq.update({'instructions': this.state.instructions});
    labReq.update({'numberOfPoints': this.state.numberOfPoints});
    labReq.update({'descriptionOfVideos': this.state.descriptionOfVideos});
    labReq.update({'locationOfVideos': this.state.locationOfVideos});
    labReq.update({'descriptionOfFrames': this.state.descriptionOfFrames});
    labReq.update({'requesterName': this.state.requesterName});
    labReq.update({'assignee': this.state.assignee});
    labReq.update({'desiredDate': this.state.desiredDate});
    labReq.update({'completionDate': this.state.completionDate});
    labReq.update({'status': this.state.status});
    this.setState({success: true})
    console.log("Successfully updated the labeling request!")
  }

  getLabelers = () => {
    let tempLabelers = []
    tempLabelers.push({name: 'Unassigned', id: '0'});
    admin.firestore().collection('users').where('role', '==', 'labeler-admin').get().then(snapshot => {
      snapshot.forEach(function(doc) {
        let res = doc.data()
        tempLabelers.push({name: res.firstName + ' ' + res.lastName, id: doc.id});
      });
      this.setState({ labelers: tempLabelers });
    })
    .catch(err => {
      console.log('Error getting documents', err);
    });
  }

  render() {
    const classes = this.props.classes;
    return (
      <Container
        component="main"
        style={{width:'80%', maxWidth: '500px'}}
        className={classes.paper}
      >
        <CssBaseline />
        <Grid
          style = {{display: (this.state.error) ? "none" : (this.state.loading) ? "none" : (this.state.success) ? "none" : "flex"}}
        >
          <div className={this.props.classes.paper}>
            <Avatar className={this.props.classes.avatar}>
              <AssignmentIndIcon />
            </Avatar>
            <Typography
              component="h1"
              variant="h4"
            >
              EDIT LABELING REQUEST
            </Typography>
            <form
              className={this.props.classes.form}
              onSubmit={this.handleSubmit}
            >
              <div className={this.props.classes.root}>
                <Typography
                  variant="h6"
                  align="left"
                >
                  LABELING REQUEST INFORMATION
                </Typography>
                <TextField
                  autoFocus
                  fullWidth
                  label="Request ID"
                  margin="normal"
                  name="requestID"
                  onChange={this.handleChange("requestID")}
                  required
                  value={this.state.requestID}
                  variant="outlined"
                />
                <TextField
                  autoFocus
                  fullWidth
                  label="Name"
                  margin="normal"
                  name="name"
                  onChange={this.handleChange("name")}
                  required
                  value={this.state.name}
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  multiline
                  label="Purpose"
                  margin="normal"
                  name="purpose"
                  onChange={this.handleChange("purpose")}
                  required
                  value={this.state.purpose}
                  variant="outlined"
                />
                <FormControl              
                  variant="outlined"
                  fullWidth
                  margin="normal"
                >
                  <InputLabel id="videosType-select">Video Type*</InputLabel>
                  <Select
                    id="videosType"
                    label="Video Type"
                    labelId="videosType-select"
                    name="videosType"
                    value={this.state.videosType}
                    onChange={this.handleChange('videosType')}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={'youtube'}>YouTube</MenuItem>
                    <MenuItem value={'own'}>Own</MenuItem>
                    <MenuItem value={'golfCenter'}>Golf Center</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  id="dateCreated"
                  margin="normal"
                  label="Request Date"
                  type="date"
                  onChange={this.handleChange("dateCreated")}
                  required
                  value={this.state.dateCreated}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className={this.props.classes.root}>
                <Typography
                  variant="h6"
                  align="left"
                >
                  INFORMATION
                </Typography>
                <TextField
                  fullWidth
                  label="Number of Frames"
                  margin="normal"
                  type='number'
                  name="numberOfFrames"
                  onChange={this.handleChange("numberOfFrames")}
                  required
                  value={this.state.numberOfFrames}
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  multiline
                  label="Instructions"
                  margin="normal"
                  name="instructions"
                  onChange={this.handleChange("instructions")}
                  required
                  value={this.state.instructions}
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  label="Number Of Points"
                  margin="normal"
                  type='number'
                  name="numberOfPoints"
                  onChange={this.handleChange("numberOfPoints")}
                  required
                  value={this.state.numberOfPoints}
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  multiline
                  label="Description Of Videos"
                  margin="normal"
                  name="descriptionOfVideos"
                  onChange={this.handleChange("descriptionOfVideos")}
                  required
                  value={this.state.descriptionOfVideos}
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  multiline
                  label="Location Of Videos"
                  margin="normal"
                  name="locationOfVideos"
                  onChange={this.handleChange("locationOfVideos")}
                  required
                  value={this.state.locationOfVideos}
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  multiline
                  label="Description Of Frames"
                  margin="normal"
                  name="descriptionOfFrames"
                  onChange={this.handleChange("descriptionOfFrames")}
                  required
                  value={this.state.descriptionOfFrames}
                  variant="outlined"
                />
              </div>
              <div className={this.props.classes.root}>
                <Typography
                  variant="h6"
                  align="left"
                >
                  ASSIGNMENT
                </Typography>
                <TextField
                  fullWidth
                  label="Name of the Requester"
                  margin="normal"
                  name="requesterName"
                  onChange={this.handleChange("requesterName")}
                  required
                  value={this.state.requesterName}
                  variant="outlined"
                />
                <TextField
                  autoComplete="assignee"
                  select
                  fullWidth
                  label="Name of the Assignee"
                  margin="normal"
                  name="assignee"
                  onChange={this.handleAssigneeChange}
                  required
                  value={this.state.assignee}
                  variant="outlined"
                >
                  {this.state.labelers.map((labeler) => (
                    <MenuItem key={labeler.name} value={labeler.name}>
                      {labeler.name}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  fullWidth
                  id="desiredDate"
                  margin="normal"
                  label="Desired Date"
                  type="date"
                  onChange={this.handleChange("desiredDate")}
                  required
                  value={this.state.desiredDate}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  fullWidth
                  id="completionDate"
                  margin="normal"
                  label="Completion Date"
                  type="date"
                  onChange={this.handleChange("completionDate")}
                  value={this.state.completionDate}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <FormControl              
                  variant="outlined"
                  fullWidth
                  margin="normal"
                >
                  <InputLabel id="status-select">Status*</InputLabel>
                  <Select
                    id="status"
                    label="Status"
                    labelId="status-select"
                    name="status"
                    value={this.state.status}
                    onChange={this.handleChange('status')}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={'created'}>Created</MenuItem>
                    <MenuItem value={'inProgress'}>In Progress</MenuItem>
                    <MenuItem value={'completed'}>Completed</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <Button
                className={this.props.classes.submit}
                color="primary"
                fullWidth
                type="submit"
                variant="contained"
              >
                SAVE LABELING REQUEST
              </Button>
              <Grid container>
                <Grid
                  item
                  xs
                >
                  <Link
                    component={RouterLink}
                    to="/labeling"
                    variant="body2"
                  >
                    {'Go back to Labeling'}
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
        </Grid>

        {/* Loading screen*/}
        <Grid
          style = {{margin : "auto", padding : 100, display: (this.state.loading) ? "block" : "none"}}
        >
          <CircularProgress />
        </Grid>

        {/* Success screen*/}
        <Grid
          style = {{margin : "auto", paddingTop : 100, display: (this.state.success) ? "block" : "none"}}
        >
          <div className={this.props.classes.paper}>
            <Avatar className={this.props.classes.avatar}>
              <CheckCircleIcon />
            </Avatar>
            <Typography component="h1" variant="h2">
              Success!
            </Typography>

            <Typography component="h5" align = "center">
              Successfully edited labeling request.
            </Typography>
            <Link className={this.props.classes.buttonLink} component={RouterLink} to="/labeling">
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={this.props.classes.linkedButton}
                onClick={() => {
                  this.setState({ success: false })
                }}
              >
                Edit Another labeling request
              </Button>
            </Link>
            <Link className={this.props.classes.buttonLink} component={RouterLink} to="/labeling">
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={this.props.classes.linkedButton}
              >
                Back To Labeling
              </Button>
            </Link>
          </div>
        </Grid>
        {/* Error screen*/}
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
          style = {{display: (this.state.error && !this.state.loading) ? "block" : "none"}}
        >
          <AccountNotFound            
            error = {{error : this.state.error}}
          />
        </Grid>
      </Container>
    );
  }
};

EditLabelingRequest.propTypes = {
  classes:PropTypes.object,
  history: PropTypes.object,
};

export default withStyles(useStyles)(EditLabelingRequest);
