import React, { useState } from "react";
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  withStyles,
  Button,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { FlexWrap, Pad } from "../../../components";
import { useEffect } from "react";

export function RemoteControlSessionCompleteModal({ open, onClose, onCreate }) {
  return (
    <Dialog onClose={onClose} open={open}>
      <DialogContent>
        <Pad padding="20px 50px">
          <FlexWrap direction="column" justify="center">
            <CheckCircleIcon style={{ fontSize: 200, color: "#2AE30C" }} />
            <Typography variant="h3" component="h3">
              SESSION ENDED
            </Typography>
            <Pad height="10px" />
            <Button
              variant="outlined"
              onClick={onClose}
              style={{ width: "300px" }}
            >
              RECORD AGAIN
            </Button>
          </FlexWrap>
        </Pad>
      </DialogContent>
    </Dialog>
  );
}
