import React from "react";
import { useEffect, useState, useRef, useCallback } from "react";
import { getWeeklyReport } from "./api";
import { DateRangePicker } from "react-date-range";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { Button, CircularProgress, Tooltip } from "@material-ui/core";
import { CSVLink } from "react-csv";

export const WeeklyUserReportPage = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(2023, 0, 0, 0, 0, 0, 0),
    endDate: new Date(),
    key: "range1",
  });
  const [showSelectionRange, setShowSelectionRange] = useState(false);
  const [csvData, setCsvData] = useState([]);

  const excelRef = useRef();

  useEffect(() => {
    (async () => {
      const { data, currentData } = await getWeeklyReport({
        from: selectionRange.startDate.valueOf(),
        to: selectionRange.endDate.valueOf(),
      });
      setData([currentData, ...data]);
      setIsLoading(false);
    })();
  }, [selectionRange]);

  useEffect(() => {
    if (csvData.length > 0) {
      excelRef.current.link.click();
    }
  }, [csvData]);

  const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
  });

  const classes = useStyles();

  const csvFetchFunc = useCallback(
    async (e) => {
      setCsvData(data);
    },
    [data]
  );

  const csvExportHeaders = [
    {
      label: "Week",
      key: "weekRange",
    },
    {
      label: "Active Coach Accounts",
      key: "activeCoaches",
    },
    {
      label: "Coach Account Growth/Loss",
      key: "activeCoachesDiff",
    },
    {
      label: "Active Student Accounts",
      key: "activeStudents",
    },
    {
      label: "Student Account Growth/Loss",
      key: "activeStudentsDiff",
    },
    {
      label: "Student Accounts Added by Coach",
      key: "addedStudents",
    },
    {
      label: "Growth/Loss WoW",
      key: "addedStudentsDiff",
    },
    {
      label: "Total Free Accounts",
      key: "freeAccounts",
    },
    {
      label: "WoW Growth",
      key: "freeAccountsDiff",
    },
  ];

  return (
    <div style={{ width: "100%", height: "100vh", padding: "40px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <Tooltip title="Select Time">
          <div
            style={{
              width: "40px",
              height: "40px",
              backgroundColor: "#2196f3",
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              cursor: "pointer",
            }}
            onClick={() => setShowSelectionRange((prev) => !prev)}
          >
            <AccessTimeIcon />
          </div>
        </Tooltip>
        <Button variant="contained" color="primary" onClick={csvFetchFunc}>
          Generate Csv Data
        </Button>
        <CSVLink
          ref={excelRef}
          filename={`output.csv`}
          data={csvData}
          headers={csvExportHeaders}
          asyncOnClick={true}
          style={{ display: "none" }}
        >
          Export Table Data
        </CSVLink>
      </div>
      <div
        style={{
          display: showSelectionRange ? "block" : "none",
          position: "absolute",
          zIndex: 10,
          border: "#e0e0e0 1px solid",
        }}
      >
        <DateRangePicker
          ranges={[selectionRange]}
          onChange={(ranges) => setSelectionRange(ranges.range1)}
        />
      </div>
      {isLoading ? (
        <div
          style={{
            width: "100vw",
            height: "90vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Week</TableCell>
                <TableCell>Active Coach Accounts</TableCell>
                <TableCell>Coach Account Growth/Loss</TableCell>
                <TableCell>Active Student Accounts</TableCell>
                <TableCell>Student Account Growth/Loss</TableCell>
                <TableCell>Student Accounts Added by Coach</TableCell>
                <TableCell>Growth/Loss WoW</TableCell>
                <TableCell>Total Free Accounts</TableCell>
                <TableCell>WoW Growth</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow key={row.weekRange}>
                  <TableCell component="th" scope="row">
                    {row.weekRange}
                  </TableCell>
                  <TableCell>{row.activeCoaches}</TableCell>
                  <TableCell>{row.activeCoachesDiff}</TableCell>
                  <TableCell>{row.activeStudents}</TableCell>
                  <TableCell>{row.activeStudentsDiff}</TableCell>
                  <TableCell>{row.addedStudents}</TableCell>
                  <TableCell>{row.addedStudentsDiff}</TableCell>
                  <TableCell>{row.freeAccounts}</TableCell>
                  <TableCell>{row.freeAccountsDiff}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};
