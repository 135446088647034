import React, { forwardRef, useState, useEffect } from 'react';

import {
  Box, Card, CardContent, CircularProgress, Container, Grid, Typography,
  IconButton
} from '@material-ui/core';
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
  Receipt,
  ArrowBack,
} from "@material-ui/icons";
import MaterialTable from "material-table";
import { Link as RouterLink, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import moment from 'moment';
import { CsvBuilder } from 'filefy';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import firebase, { functionBaseUrl, axiosWithToken } from '../../../common/firebase';
//import { ElavonPayment } from '../../../components/elavonPayment';
import { StripePayment } from '../../../components/stripePayment';

const tableIcons = {
  ExportCSV: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  ExportPDF: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => (
    <ArrowDownward {...props} ref={ref} />
  )),
  ThirdStateCheck: forwardRef((props, ref) => (
    <Remove {...props} ref={ref} />
  )),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  Receipt: forwardRef((props, ref) => <Receipt {...props} ref={ref} />),
};

const tableHeader = [
  { field: "userEmail", title: "Email Address" },
  { field: "userName", title: "Name" },
  {
    field: "startDate", title: "Start Date",
    render: (rowData) => {
      return moment(String(rowData.startDate), "x").format("MM/DD/YYYY");
    }
  },
  {
    field: "coachDetails", title: "Plan",
    customSort: (a, b) => {
      let aScore = 0;
      let bScore = 0;
      if (a.coachDetails.productId?.includes("student_lite_monthly")) {
        aScore = 1;
      }
      if (a.coachDetails.productId?.includes("student_lite_annually")) {
        aScore = 2;
      }
      if (b.coachDetails.productId?.includes("student_lite_monthly")) {
        bScore = 1;
      }
      if (b.coachDetails.productId?.includes("student_lite_annually")) {
        bScore = 2;
      }

      return aScore - bScore;
    },
    render: (rowData) => {
      if (rowData.coachDetails && rowData.coachDetails.productId.includes("student_lite_monthly")) {
        return "3D Practice Monthly"
      }
      if (rowData.coachDetails && rowData.coachDetails.productId.includes("student_lite_annually")) {
        return "3D Practice Annually"
      }
      return ""
    }
  },
  {
    field: "amount", title: "Price",
    render: (rowData) => {
      return "$" + rowData?.amount
    }
  },
  {
    field: "period", title: "Period",
    customSort: (a, b) => a?.coachDetails?.period - b?.coachDetails?.period,
    render: (rowData) => {
      return rowData?.coachDetails?.period
    }
  },
  {
    field: "revenue", title: "Revenue Share",
    render: (rowData) => {
      return "$" + rowData.revenue.toFixed(2) + " (" + rowData.discount + "%)"
    }
  },
  {
    field: "cost",
    title: "Balance",
    customSort: (a, b) => a.amount * ((100 - parseInt(a.discount)) / 100) * a.coachDetails.period - b.amount * ((100 - parseInt(b.discount)) / 100) * b.coachDetails.period,
    render: (rowData) => {
      return "$" + (rowData.amount * ((100 - parseInt(rowData.discount)) / 100) * rowData.coachDetails.period).toFixed(2);
    },
  },
];

function InvoiceDetailComponent({ history }) {
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState(0);
  const [totalPaid, setTotalPaid] = useState(0);
  const [amount, setAmount] = useState(0);
  const [period, setPeriod] = useState(new Date());
  const [datePaid, setDatePaid] = useState(new Date());
  const [status, setStatus] = useState('');
  const [id, setId] = useState('');
  const { invoiceId } = useParams();

  const loadData = async () => {
    try {
      setIsLoading(true);
      const firebaseUser = firebase.auth().currentUser;
      let resp;
      if (invoiceId === 'last' || invoiceId == '') {
        resp = await axiosWithToken(`${functionBaseUrl}/api/invoiceLast`, {
          method: 'POST',
          data: { userId: firebaseUser.uid }
        });
        setId(resp.data.id);
      } else {
        setId(invoiceId);
        resp = await axiosWithToken(`${functionBaseUrl}/api/invoice`, {
          method: 'POST',
          data: { id: invoiceId }
        });
      }
      setPeriod(resp.data.period);
      setDatePaid(resp.data.datePaid);
      setTotalPaid(resp.data.totalPaid);
      setStatus(resp.data.status);

      const response = await axiosWithToken(`${functionBaseUrl}/api/unpaidStudentsSub`, {
        method: 'POST',
        data: { userId: firebaseUser.uid }
      });
      let total = 0;
      let amount = 0;
      let discount = 10;
      for (let index = 0; index < response.data.data.length; index++) {
        if ((response.data.total + index) < 29) {
          discount = 10;
        } else if ((response.data.total + index) < 49) {
          discount = 20;
        } else {
          discount = 30;
        }
        total += response.data.data[index].amount * response.data.data[index].coachDetails.period;
        amount += response.data.data[index].amount * (100 - discount) / 100 * response.data.data[index].coachDetails.period;
        response.data.data[index].discount = discount;
        response.data.data[index].revenue = (response.data.data[index]?.amount * parseInt(discount) * response.data.data[index].coachDetails.period) / 100;
      }
      setUsers(response.data.data);
      setTotal(total.toFixed(2));
      setAmount(amount.toFixed(2));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      Swal.fire({
        title: '<p style="font-size:70%;">Error loading data</p>',
        icon: 'error',
        confirmButtonText: 'Ok',
        allowOutsideClick: true,
      });
    }
  }

  useEffect(() => {
    loadData();
  }, [])

  async function handleResult() {
    for (const user of users) {
      let coachDetails = user.coachDetails;
      coachDetails.status = 'Paid';
      await firebase.firestore().collection('subscriptions').doc(user.id).update({ coachDetails: coachDetails });
    }
    const firebaseUser = firebase.auth().currentUser;
    if (id) {
      await firebase.firestore().collection('invoices').doc(id).update({
        status: 'Paid',
        totalPaid: amount,
        datePaid: moment().unix() * 1000,
        userPaid: firebaseUser.uid,
      });
    } else {
      await firebase.firestore().collection('invoices').add({
        coachId: firebaseUser.uid,
        period: moment().unix() * 1000,
        datePaid: moment().unix() * 1000,
        status: 'Paid',
        subscriptions: users.map(sub => sub.id),
        totalPaid: amount,
        userPaid: firebaseUser.uid,
      });
    }
    await Swal.fire({
      title: `<p style="font-size:70%;">Thank you for your payment!</p>`,
      showConfirmButton: false,
      icon: 'success',
      customClass: {
        container: 'my-swal'
      },
      timer: 2000,
      showCloseButton: true,
      allowOutsideClick: true
    }).then(async () => {
      await history.push('/students');
    })
  }

  return (<>
    {isLoading && (
      <Container maxWidth="xs" style={{ textAlign: 'center', paddingTop: '50px' }}>
        <CircularProgress />
      </Container>
    )}
    {!isLoading && (
      <Container
        component="main"
        style={{ paddingTop: '50px' }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            style={{ paddingBottom: 20 }}
          >
            <RouterLink to="/invoices">
              <IconButton
                edge="start"
              >
                <ArrowBack />&nbsp;All Paid Invoices
              </IconButton>
            </RouterLink>
          </Grid>

          <Grid item xs={12}>
            <h2 style={{ textAlign: 'center' }}>{moment(period).format("MMMM YYYY")} Invoice</h2>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <Grid container justify="center">
              <Grid
                item
                sm={6}
                xs={12}
              >
                <Box border={1} borderColor="primary.main" style={{ marginBottom: "5%" }}>
                  <Card style={{ minWidth: "50%" }}>
                    <CardContent>
                      <Grid container justify="space-between">
                        <Typography
                          inline
                          component="h6"
                          variant="h5"
                          justify="center"
                        >
                          <b>Total Amount:</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </Typography>
                        <Typography
                          inline
                          component="h6"
                          variant="h5"
                          justify="center"
                          align="right"
                        >
                          ${total}
                        </Typography>
                      </Grid>
                      <Grid container justify="space-between">
                        <Typography
                          inline
                          component="h6"
                          variant="h5"
                          justify="center"
                        >
                          <b>Revenue Share:</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </Typography>
                        <Typography
                          inline
                          component="h6"
                          variant="h5"
                          justify="center"
                          align="right"
                        >
                          -${(total - amount).toFixed(2)}
                        </Typography>
                      </Grid>
                    </CardContent>
                  </Card>
                  <Card>
                    <CardContent fullWidth>
                      <Typography style={{ textAlign: 'center', marginTop: 20, marginBottom: 20 }}>
                        {status != 'Paid' && (
                          <div>
                            <span style={{ fontSize: '28px' }}>Current Balance: <b>${amount}</b></span><br />
                          </div>
                        )}
                        {status == 'Paid' && (
                          <div>
                            <span style={{ fontSize: '28px' }}>Paid: <b>${totalPaid}</b></span><br />
                            <span style={{ fontSize: '18px' }}><b></b></span><br/>
                            <span style={{ fontSize: '18px' }}>Date Paid: {moment(datePaid).format("DD MMMM YYYY")} </span>
                        </div>
                        )}
                        {/*total - amount > 0 && (
                          <div>
                            <span style={{ fontSize: '18px' }}>Revenue Share Discount: {(total - amount).toFixed(2)}% off</span><br/>
                            <span style={{ fontSize: '18px' }}>Total Amount: ${total}</span>
                          </div>
                        )*/}
                      </Typography>
                      {amount > 0 && status != 'Paid' && (
                        <StripePayment amount={amount} handleResult={handleResult} useConfirmation={true} />
                      )}
                    </CardContent>
                  </Card>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <h4>Details:</h4>
            <MaterialTable
              style={{ marginTop: "10px", marginBottom: "10px" }}
              icons={tableIcons}
              columns={tableHeader}
              title=""
              data={users}
              actions={[
                {
                  position: "toolbarOnSelect",
                  icon: 'ExportCSV',
                  tooltip: 'Export selected as CSV',
                  onClick: (e, rowData) => {
                    const columnTitles = tableHeader.map(columnDef => columnDef.title);
                    const csvData = rowData.map(data =>
                      tableHeader.map(columnDef => {
                        if (columnDef.render) {
                          return columnDef.render(data);
                        }
                        return data[columnDef.field];
                      }),
                    );
                    const builder = new CsvBuilder('data.csv')
                      .setColumns(columnTitles)
                      .addRows(csvData)
                      .exportFile();
                  }
                },
                {
                  position: "toolbarOnSelect",
                  icon: 'ExportPDF',
                  tooltip: 'Export selected as PDF',
                  onClick: (e, rowData) => {
                    const doc = new jsPDF();
                    const columnTitles = tableHeader.map(columnDef => columnDef.title);
                    const pdfData = rowData.map(data =>
                      tableHeader.map(columnDef => {
                        if (columnDef.render) {
                          return columnDef.render(data);
                        }
                        return data[columnDef.field];
                      }),
                    );
                    doc.autoTable({
                      head: [columnTitles],
                      body: pdfData,
                    });
                    doc.save(`data.pdf`);
                  }
                }
              ]}
              options={{
                selection: true,
                sorting: true,
                search: true,
                paging: true,
                exportAllData: true,
                exportButton: true,
                pageSizeOptions: [5, 10, 20, 50, 100],
                exportCsv: (columns, data) => {
                  const columnTitles = columns.map(columnDef => columnDef.title);
                  const csvData = data.map(rowData =>
                    columns.map(columnDef => {
                      const col = tableHeader.find(c => c.field == columnDef.field);
                      if (col.render) {
                        return col.render(rowData);
                      }
                      return rowData[columnDef.field];
                    }),
                  );
                  const builder = new CsvBuilder('data.csv')
                    .setColumns(columnTitles)
                    .addRows(csvData)
                    .exportFile();
                  return builder;
                },
                exportPdf: (columns, data) => {
                  const doc = new jsPDF();
                  const columnTitles = columns.map(columnDef => columnDef.title);
                  const pdfData = data.map(rowData =>
                    columns.map(columnDef => {
                      const col = tableHeader.find(c => c.field == columnDef.field);
                      if (col.render) {
                        return col.render(rowData);
                      }
                      return rowData[columnDef.field];
                    }),
                  );
                  doc.autoTable({
                    head: [columnTitles],
                    body: pdfData,
                  });
                  doc.save(`data.pdf`);
                },
                rowStyle: (x) => {
                  if (x.tableData.id % 2) {
                    return { backgroundColor: "#f2f2f2" };
                  }
                },
              }}
            />
          </Grid>
        </Grid>
      </Container>
    )}
  </>);
}

export const InvoiceDetail = InvoiceDetailComponent;
