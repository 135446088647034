import React from "react";

export default function Pad({
  children,
  width = "fit-content",
  height = "fit-content",
  margin = 0,
  padding = 0,
}) {
  return <div style={{ margin, padding, width, height }}>{children}</div>;
}
