import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {AppBar, Toolbar} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none',
  },
}));

const Topbar = (props) => {
  const {className, ...rest} = props;

  const classes = useStyles();

  const dashboard = () => {
    if (window.location.href.includes('?newAccount')) {
      return false
    }
    if (window.location.href.includes('/sign-up') || window.location.href.includes('/sign-in') || window.location.href.includes('/welcome')) {
      window.location.href = "https://www.sportsbox.ai/";
    } else {
      if (window.location.href.includes('/localhost')) {
        window.location.href = "http://localhost:3000/dashboard";
      } else {
        window.location.href = (process.env.REACT_APP_ENVIRONMENT === "DEVELOPMENT") ? "https://sportsbox-baseball.web.app/dashboard" : "https://sportsbox-product-baseball.web.app/dashboard";
      }
    }
  }

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      style={{ background: '#000C66' }} 
      position="fixed"
    >
      <Toolbar>
        <RouterLink onClick={() => dashboard()}>
          <img
            alt="Logo"
            src="/images/logos/sportsbox_icon.png"
            width={50}
          />
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
};

export default Topbar;
