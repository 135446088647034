import React, { Component } from 'react';
import { Grid, TextField, MenuItem, Button, Dialog, DialogContent, DialogTitle, DialogActions, Typography} from '@material-ui/core';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from '@material-ui/lab';

const requiredFields = ['combination', 'zoom', 'fps', 'resolution', 'orientation']

class CameraMeta extends Component {
  constructor(props){
    super(props);
    if (this.props.autofill) {
      this.state = this.props.autofill
    } else {
      this.state = this.getInitialState();
    }
    if (this.props.cameraSetup.setupCombination === 1) {
      this.state.combination = 0
      this.state.brand = this.props.cameraSetup.combinations[0].brand
      this.state.model = this.props.cameraSetup.combinations[0].model
    }
  }
  getInitialState = () => {
    return {
      combination: '',
      brand: '',
      model: '',
      zoom: '',
      fps: '',
      resolution: '',
      angle: '',
      distance: '',
      orientation: '',
      multiCam: false,
      cameraNumber: '',
      iso: '',
      height: '',
      errors: {}
    }
  }
  handleClear = () => {
    const confirmVal = window.confirm('Do you want to clear your query?')
    if (confirmVal) {
      this.setState(this.getInitialState())
    }
  }
  handleChange = (prop) => (event) => {
    this.setState({[prop]: event.target.value})
  }
  handleCombinationChange = (event) => {
    console.log(this.props.cameraSetup.combinations[event.target.value])
    this.setState({...this.state, combination: event.target.value, brand: this.props.cameraSetup.combinations[event.target.value].brand, model: this.props.cameraSetup.combinations[event.target.value].model})
  }
  handleSubmit = () => {
    let errors = {}
    requiredFields.forEach((field) => {
      if (this.state[field] === '') {
        errors[field] = field.charAt(0).toUpperCase() + field.slice(1) + ' is required'
      }
    })
    if (this.props.cameraSetup.setupCombination !== 1 && this.state.cameraNumber === '') {
      errors.cameraNumber = 'Camera Number is required'
    }
    if (Object.keys(errors).length === 0) {
      const output = this.state
      output.errors = null
      if (this.props.cameraSetup.setupCombination > 1) {
        output.multiCam = true
      }
      this.setState(this.getInitialState())
      this.props.handleSubmit(this.state)
    }
    this.setState({errors: errors})
  }
  render(){
    const { cameraSetup } = this.props
    return (
      <Dialog
        fullWidth
        open={this.props.open}
        onClose={this.props.closePopUp}
        aria-labelledby='form-dialog-title'>
        <DialogTitle id={'form-dialog-title'}>
          <div>
            Select Camera Settings
            <CloseIcon style={{float: 'right', marginTop: '-10px', marginRight: '-15px'}} onClick={this.props.closePopUp}/>
          </div>
        </DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid
                item
                sm={12}>
                <Typography
                  variant="h5"
                  color='primary'
                >Camera Name</Typography>
              </Grid>
              <Grid item sm={6}>
                <TextField
                  fullWidth
                  select
                  margin="dense"
                  name="cameraCombo"
                  onChange={this.handleCombinationChange}
                  required
                  value={this.state.combination}
                  variant="outlined"
                >
                  <MenuItem value=''>
                    <em>None</em>
                  </MenuItem>
                  {cameraSetup.combinations.map((item, index) => (
                    <MenuItem key={index} value={index}>
                      {item.brand + ' ' + item.model}
                    </MenuItem>
                  ))}
                </TextField>
                {this.state.errors.combination ? <Alert severity="warning">{this.state.errors.combination}</Alert> : null}
              </Grid>
              <Grid item sm={6} style={{display: cameraSetup.setupCombination > 1 ? 'block' : 'none'}}> 
                <TextField
                  select
                  fullWidth
                  label='Multicam Number'
                  name='cameraNumber'
                  onChange={this.handleChange('cameraNumber')}
                  value={this.state.cameraNumber}
                  margin='dense'
                  variant='outlined'>
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {Array.from(Array(parseInt(cameraSetup.setupCombination)).keys()).map((num) => 
                    <MenuItem key={num+1} value={num+1}>{num+1}</MenuItem>
                  )}
                </TextField>
                {this.state.errors.cameraNumber ? <Alert severity="warning">{this.state.errors.cameraNumber}</Alert> : null}
              </Grid>
              <Grid
                item
                sm={12}>
                <Typography
                  variant="h5"
                  color='textSecondary'
                  style={{marginTop: '8px'}}>
                  Camera Properties
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <TextField
                  fullWidth
                  label='Zoom (e.g. 2)'
                  name='zoom'
                  margin='dense'
                  type='number'
                  onChange={this.handleChange('zoom')}
                  value={this.state.zoom}
                  variant='outlined'/>
                {this.state.errors.zoom ? <Alert severity="warning">{this.state.errors.zoom}</Alert> : null}
              </Grid>
              <Grid item sm={6}>
                <TextField
                  fullWidth
                  label='Resolution (e.g. 1080p)'
                  name='resolution'
                  margin='dense'
                  onChange={this.handleChange('resolution')}
                  value={this.state.resolution}
                  variant='outlined'/>
                {this.state.errors.resolution ? <Alert severity="warning">{this.state.errors.resolution}</Alert> : null}
              </Grid>
              <Grid item sm={6}>
                <TextField
                  fullWidth
                  label='FPS (e.g. 60)'
                  name='fps'
                  margin='dense'
                  type='number'
                  onChange={this.handleChange('fps')}
                  value={this.state.fps}
                  variant='outlined'/>
                {this.state.errors.fps ? <Alert severity="warning">{this.state.errors.fps}</Alert> : null}
              </Grid>
              <Grid item sm={6}>
                <TextField
                  fullWidth
                  label='Height From Ground'
                  name='height'
                  margin='dense'
                  type='number'
                  onChange={this.handleChange('height')}
                  value={this.state.height}
                  variant='outlined'/>
              </Grid>
              <Grid item sm={6}>
                <TextField
                  fullWidth
                  label='Distance From Toeline (e.g. 6)'
                  name='distance'
                  margin='dense'
                  type='number'
                  onChange={this.handleChange('distance')}
                  value={this.state.distance}
                  variant='outlined'/>
              </Grid>
              <Grid item sm={6}>
                <TextField
                  select
                  fullWidth
                  label='Orientation'
                  name='orientation'
                  onChange={this.handleChange('orientation')}
                  value={this.state.orientation}
                  margin='dense'
                  variant='outlined'>
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={'portrait'}>Portrait</MenuItem>
                  <MenuItem value={'landscape'}>Landscape</MenuItem>
                </TextField>
                {this.state.errors.orientation ? <Alert severity="warning">{this.state.errors.orientation}</Alert> : null}
              </Grid>
              <Grid item sm={6}>
                <TextField
                  fullWidth
                  label='Angle'
                  name='angle'
                  margin='dense'
                  type='number'
                  onChange={this.handleChange('angle')}
                  value={this.state.angle}
                  variant='outlined'/>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClear} color="primary">
              Clear
            </Button>
            <Button onClick={this.handleSubmit} color="secondary">
              Submit
            </Button>
          </DialogActions>
      </Dialog>
    )
  }
}
CameraMeta.propTypes = {
  open: PropTypes.bool,
  closePopUp: PropTypes.func,
  submitQuery: PropTypes.func,
  values: PropTypes.object
}
export default CameraMeta