import admin, {
  axiosWithToken,
  functionBaseUrl,
} from "../../../common/firebase";
import { fbMapper } from "./helper";

const albumsDb = admin.firestore().collection("practiceGuideAlbums");
const guidesDb = admin.firestore().collection("practiceGuides");
const tipsDb = admin.firestore().collection("tipsAndDrillsVideos");

export const searchItems = async (dbName, input) => {
  let db = guidesDb;
  switch (dbName) {
    case "practiceGuides":
      db = guidesDb;
      break;
    case "tipsAndDrills":
      db = tipsDb;
      break;
    case "albums":
      db = albumsDb;
      break;
  }

  const data = fbMapper(await db.get());
  if (input.length === 0) {
    return data;
  }
  const found = data.reduce((acc, itr) => {
    const regex = new RegExp(`${input}`, "i");
    if (regex.test(itr.title) || regex.test(itr.author)) {
      return [...acc, itr];
    } else {
      return acc;
    }
  }, []);
  return found;
};

export const searchPublishedItems = async (dbName, input) => {
  let db = guidesDb;
  switch (dbName) {
    case "practiceGuide":
      db = guidesDb;
      break;
    case "tipsAndDrills":
      db = tipsDb;
      break;
    case "album":
      db = albumsDb;
      break;
  }

  const data = fbMapper(await db.where("status", "==", "published").get());
  if (input.length === 0) {
    return data;
  }
  const found = data.reduce((acc, itr) => {
    const regex = new RegExp(`${input}`, "i");
    if (regex.test(itr.title) || regex.test(itr.author)) {
      return [...acc, itr];
    } else {
      return acc;
    }
  }, []);
  return found;
};

export const copyItem = async (dbName, id) => {
  let db = guidesDb;
  switch (dbName) {
    case "practiceGuides":
      db = guidesDb;
      break;
    case "tipsAndDrills":
      db = tipsDb;
      break;
    case "albums":
      db = albumsDb;
      break;
  }
  const item = (await db.doc(id).get()).data();
  console.log(item.title);
  await db.add({ ...item, title: `${item.title} (1)` });
  const itemsList = fbMapper(await db.get());
  return itemsList;
};

export const archiveItem = async (dbName, id) => {
  let db = guidesDb;
  switch (dbName) {
    case "practiceGuides":
      db = guidesDb;
      break;
    case "tipsAndDrills":
      db = tipsDb;
      break;
    case "albums":
      db = albumsDb;
      break;
  }
  await db.doc(id).update({ status: "archived" });
};

export const getWatchlistCsvData = async () => {
  const { data } = await axiosWithToken(functionBaseUrl + "/api/csv", {
    method: "post",
  });
  return data;
};

export const parseBytes = (bytes) => {
  return +(bytes / Math.pow(1024, 2)).toFixed(2);
};

export const pgScripts = async () => {
  const pgs = fbMapper(await guidesDb.get());
  const userPgs = admin.firestore().collection("userPracticeGuides");
};

export const albumsFileSizeScript = async () => {
  const data = fbMapper(await albumsDb.get());
  for await (const itr of data) {
    console.log(itr.id);
    const { coverImage, introVideo } = itr;
    const coverImageFileSize = await getFileSizeByUrl(coverImage);
    const fileSize = await getFileSizeByUrl(introVideo.filePath);
    await albumsDb
      .doc(itr.id)
      .update({ coverImageFileSize, introVideo: { ...introVideo, fileSize } });
  }
  console.log("end");
};

export const guidesFileSizeScript = async () => {
  const data = fbMapper(await guidesDb.get());
  for await (const itr of data) {
    console.log(itr.id);
    const { coverImage, introVideo } = itr;
    const coverImageFileSize = await getFileSizeByUrl(coverImage);
    const fileSize = await getFileSizeByUrl(introVideo.filePath);
    await guidesDb
      .doc(itr.id)
      .update({ coverImageFileSize, introVideo: { ...introVideo, fileSize } });
  }
  console.log("end");
};

export async function getFileSizeByUrl(url) {
  const fetched = await fetch(url);
  const bytes = (await fetched.blob()).size;
  return +(bytes / Math.pow(1024, 2)).toFixed(2);
}
