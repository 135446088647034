import admin, { functionBaseUrl, axiosWithToken } from "../../common/firebase";
import { fbMapper } from "../../helpers/fbMapper";

const mapper = (snap) => {
  const output = [];
  snap.forEach((doc) => {
    output.push({ ...doc.data(), uid: doc.id });
  });
  return output;
};

const chunker = (arr) => {
  const chunks = [];
  for (let i = 0; i < arr.length; i += 10) {
    chunks.push(arr.slice(i, i + 10));
  }
  return chunks;
};

export const getCoachData = async () => {
  const userId = admin.auth().currentUser.uid;
  const coach = (
    await admin.firestore().collection("users").doc(userId).get()
  ).data();

  const students = mapper(
    await admin
      .firestore()
      .collection("users")
      .where("coaches", "array-contains", userId)
      .orderBy("userCreated", "desc")
      .get()
  );

  const invites = fbMapper(
    await admin
      .firestore()
      .collection("invites")
      .where("senderId", "==", userId)
      .where("status", "==", "pending")
      .where("expiredIn", ">", Date.now())
      .get()
  ).map((el) => el.invitee);

  return {
    ...coach,
    uid: userId,
    students: students.filter((el) => invites.includes(el.email)),
  };
};

export const checkCreatedRemoteSession = async () => {
  const coachId = admin.auth().currentUser.uid;
  const check = fbMapper(
    await admin
      .firestore()
      .collection("remoteControlSession")
      .where("coachId", "==", coachId)
      .where("status", "==", "active")
      .get()
  )[0];
  return check ? check : null;
};

export const getRemoteControlSessionById = async (id) => {
  const res = await axiosWithToken(
    `${functionBaseUrl}/api/v1/remote-control-session/${id}`,
    {
      method: "get",
    }
  );

  return res.data.data;
};

export const sendRemoteControlSessionCommand = async (id, data) => {
  const coachId = admin.auth().currentUser.uid;
  const res = await axiosWithToken(
    `${functionBaseUrl}/api/v1/remote-control-session/remote-device-command/${id}`,
    {
      method: "patch",
      data: { ...data, senderId: coachId, receiverId: coachId },
    }
  );
};

export const sendRemoteControlSessionCommandToAllDevices = async (id, data) => {
  const coachId = admin.auth().currentUser.uid;
  const res = await axiosWithToken(
    `${functionBaseUrl}/api/v1/remote-control-session/all-remote-device-command/${id}`,
    {
      method: "patch",
      data: { ...data, senderId: coachId, receiverId: coachId },
    }
  );
};

export const createRemoteControlSession = async (data) => {
  const coachId = admin.auth().currentUser.uid;
  const created = await axiosWithToken(
    `${functionBaseUrl}/api/v1/remote-control-session`,
    {
      method: "post",
      data: { ...data, coachId },
    }
  );

  return created.data;
};

export const updateRemoteControlSession = async (id, data) => {
  await axiosWithToken(
    `${functionBaseUrl}/api/v1/remote-control-session/${id}`,
    {
      method: "patch",
      data,
    }
  );
};

export const resetRemoteControlSession = async (data) => {
  await axiosWithToken(
    `${functionBaseUrl}/api/v1/remote-control-session/status/${data}`,
    {
      method: "patch",
      data: { status: "completed" },
    }
  );
};

export const deleteRemoteControlSessionVideo = async (id, videoPath) => {
  await axiosWithToken(
    `${functionBaseUrl}/api/v1/remote-control-session/pull-video/${id}`,
    {
      method: "patch",
      data: { videoPath },
    }
  );

  const res = await axiosWithToken(
    `${functionBaseUrl}/api/v1/remote-control-session/${id}`,
    {
      method: "get",
    }
  );

  return res.data;
};

export const createSession = async (data) => {
  const { sessionName } = data;

  const remoteControlSession = await checkCreatedRemoteSession();

  const body = {
    sessionType: "LIVE",
    sessionEnv: "Indoor",
    sessionPurpose: "Studio-Bay",
    sessionName,
    sessionDate: `${Date.now()}`,
    videosOrig: "",
    parentUserId: remoteControlSession.coachId,
    sessionUserId: remoteControlSession.studentId,
    cameraSetupId: "-",
    location: "",
    windSpeed: "",
    temperature: "",
    weatherDesc: "",
    humidity: "",
    activityId: ["golf"],
    videoIds: [],
    tags: [],
  };

  const session = await axiosWithToken(`${functionBaseUrl}/api/sessions`, {
    method: "post",
    data: body,
  });

  const userData = (
    await admin
      .firestore()
      .collection("users")
      .doc(remoteControlSession.studentId)
      .get()
  ).data();

  const { dob, userName, hipSize, height, dominantHand, gender } = userData;

  const [m, d, y] = dob.split("/");

  const age = `${new Date().getFullYear() - Number(y)}`;

  const urlParser = (url) => {
    console.log(url);

    const parsed = url.split("/");

    const fin = parsed[parsed.length - 1].split("%");

    const [filename, rest] = fin[fin.length - 1].split(".");

    const ext = rest.split("?")[0];

    return { filename, ext };
  };

  await Promise.all(
    remoteControlSession.recordedVideos.map(async (el) => {
      const { filename, ext } = urlParser(el.videoPath);

      const videoBody = {
        videoPath: el.videoPath,
        parentSessionId: session.data.id,
        parentUserId: remoteControlSession.coachId,
        videoIsSplit: false,
        videoSize: "",
        videoOrigName: filename,
        videoCreated: Date.now(),
        videoLength: "",
        videoType: ext,
        actionIds: [],
        metaData: {
          brand: "iPhone",
          distortion: "",
          fps: el.metadata?.fps || 0,
          frameCount: el.metadata?.frameCount || 0,
          intrinsic: [],
          lenght: 0,
          measurement: {},
          model: "",
          name: filename,
          resolution: el.metadata?.resolution || "",
          cameraAngle: el.metadata?.cameraAngle || "",
        },
        userData: {
          age,
          comment: "",
          fullName: userName,
          gender,
          golfClub: remoteControlSession.golfClub,
          hand: dominantHand,
          handicap: "",
          height: height,
          hipMeasurement: hipSize,
        },
        favorite: false,
        videoSource: "Internal",
      };
      await axiosWithToken(`${functionBaseUrl}/api/videos`, {
        method: "post",
        data: videoBody,
      });
    })
  );

  await axiosWithToken(
    `${functionBaseUrl}/api/v1/remote-control-session/status/${remoteControlSession.id}`,
    {
      method: "patch",
      data: { status: "completed" },
    }
  );
};
