import React, { useState, useEffect } from 'react';

import { Container, Card, CardHeader, CardContent, Divider, Grid, Typography } from '@material-ui/core';
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

import { functionBaseUrl, axiosWithToken } from '../../common/firebase';

function WeekUsageComponent() {
  const [weekData, setWeekData] = useState(null);

  const getWeekData = async () => {
    const data = await axiosWithToken(functionBaseUrl + '/api/usage', { method: 'GET' });
    setWeekData({
      openApp: 0,
      openAppUp: false,
      sessionsRecorded: Math.round(data.data[1]?.sessionsRecorded * 100, 2) / 100,
      sessionsRecordedUp: data.data[1]?.sessionsRecorded > data.data[2]?.sessionsRecorded,
      sessionsImported: Math.round(data.data[1]?.sessionsImported * 100, 2) / 100,
      sessionsImportedUp: data.data[1]?.sessionsImported > data.data[2]?.sessionsImported,
      sessionsCoach: Math.round(data.data[1]?.sessionsCoach * 100, 2) / 100,
      sessionsCoachUp: data.data[1]?.sessionsCoach > data.data[2]?.sessionsCoach,
      studentsCoach: Math.round(data.data[1]?.studentsCoach * 100, 2) / 100,
      studentsCoachUp: data.data[1]?.studentsCoach > data.data[2]?.studentsCoach,
      newSubscriptions: Math.round(data.data[1]?.newSubscriptions * 100, 2) / 100,
      newSubscriptionsUp: data.data[1]?.newSubscriptions > data.data[2]?.newSubscriptions,
      issuesUser: Math.round(data.data[1]?.issuesUser * 100, 2) / 100,
      issuesUserUp: data.data[1]?.issuesUser > data.data[2]?.issuesUser,
      missUser: Math.round(data.data[1]?.missUser * 100, 2) / 100,
      missUserUp: data.data[1]?.missUser > data.data[2]?.missUser,
      swingsSession: Math.round(data.data[1]?.swingsSession * 100, 2) / 100,
      swingsSessionUp: data.data[1]?.swingsSession > data.data[2]?.swingsSession,
      missSession: Math.round(data.data[1]?.missSession * 100, 2) / 100,
      missSessionUp: data.data[1]?.missSession > data.data[2]?.missSession,
      swingSessionPercent: Math.round(data.data[1]?.swingSessionPercent * 100, 2) / 100,
      swingSessionPercentUp: data.data[1]?.swingSessionPercent > data.data[2]?.swingSessionPercent,
    });
  }

  useEffect(() => {
    getWeekData();
  }, []);

  return (<>
    <Container
      component="main"
      style={{ padding: '20px 0' }}
    >
      <Grid
        container
        spacing={2}
      >
        <Grid item xs={12}>
          <Typography variant="h2">Week usage</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Card style={{ textAlign: "center" }}>
            <CardHeader
              title="Open App"
              titleTypographyProps={{
                variant: "h5",
                color: "primary",
              }}
            />
            <Divider />
            <CardContent>
              {weekData && (
                <h2>{weekData.openApp} {weekData.openAppUp ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</h2>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Card style={{ textAlign: "center" }}>
            <CardHeader
              title="Record Session"
              titleTypographyProps={{
                variant: "h5",
                color: "primary",
              }}
            />
            <Divider />
            <CardContent>
              {weekData && (
                <h2>{weekData.sessionsRecorded} {weekData.sessionsRecordedUp ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</h2>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Card style={{ textAlign: "center" }}>
            <CardHeader
              title="Import Session"
              titleTypographyProps={{
                variant: "h5",
                color: "primary",
              }}
            />
            <Divider />
            <CardContent>
              {weekData && (
                <h2>{weekData.sessionsImported} {weekData.sessionsImportedUp ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</h2>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Card style={{ textAlign: "center" }}>
            <CardHeader
              title="Issues/user"
              titleTypographyProps={{
                variant: "h5",
                color: "primary",
              }}
            />
            <Divider />
            <CardContent>
              {weekData && (
                <h2>{weekData.issuesUser} {weekData.issuesUserUp ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</h2>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Card style={{ textAlign: "center" }}>
            <CardHeader
              title="Miss/user"
              titleTypographyProps={{
                variant: "h5",
                color: "primary",
              }}
            />
            <Divider />
            <CardContent>
              {weekData && (
                <h2>{weekData.missUser} {weekData.missUserUp ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</h2>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Card style={{ textAlign: "center" }}>
            <CardHeader
              title="% of swing/session"
              titleTypographyProps={{
                variant: "h5",
                color: "primary",
              }}
            />
            <Divider />
            <CardContent>
              {weekData && (
                <h2>{weekData.swingSessionPercent} {weekData.swingSessionPercentUp ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</h2>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Card style={{ textAlign: "center" }}>
            <CardHeader
              title="% of miss/session"
              titleTypographyProps={{
                variant: "h5",
                color: "primary",
              }}
            />
            <Divider />
            <CardContent>
              {weekData && (
                <h2>{weekData.missSession} {weekData.missSessionUp ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</h2>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Card style={{ textAlign: "center" }}>
            <CardHeader
              title="Sessions/coach"
              titleTypographyProps={{
                variant: "h5",
                color: "primary",
              }}
            />
            <Divider />
            <CardContent>
              {weekData && (
                <h2>{weekData.sessionsCoach} {weekData.sessionsCoachUp ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</h2>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Card style={{ textAlign: "center" }}>
            <CardHeader
              title="Swings/session"
              titleTypographyProps={{
                variant: "h5",
                color: "primary",
              }}
            />
            <Divider />
            <CardContent>
              {weekData && (
                <h2>{weekData.swingsSession} {weekData.swingsSessionUp ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</h2>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Card style={{ textAlign: "center" }}>
            <CardHeader
              title="Students/coach"
              titleTypographyProps={{
                variant: "h5",
                color: "primary",
              }}
            />
            <Divider />
            <CardContent>
              {weekData && (
                <h2>{weekData.studentsCoach} {weekData.studentsCoachUp ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</h2>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Card style={{ textAlign: "center" }}>
            <CardHeader
              title="New Subscriptions"
              titleTypographyProps={{
                variant: "h5",
                color: "primary",
              }}
            />
            <Divider />
            <CardContent>
              {weekData && (
                <h2>{weekData.newSubscriptions} {weekData.newSubscriptionsUp ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</h2>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  </>);
}

export const WeekUsage = WeekUsageComponent;
