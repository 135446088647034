import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Select,
  MenuItem,
  Chip,
  Typography,
  Checkbox,
} from "@material-ui/core";
import { FlexWrap, Pad } from "../../../../components";
import { fromRenderProps } from "recompose";
import { useEffect } from "react";
import { getTipsAndDrills } from "../../api";
import { PracticeGuideModalSummaryChoice } from "./PracticeGuideModalSummaryChoice";
import { PracticeGuideModalSummary } from "./PracticeGuideModalSummary";
import { AccessLevelSelect } from "../AccessLevelSelect";
import { PlatformSelect } from "../PlatformSelect";

export function PracticeGuideModal({
  onCreate,
  onEdit,
  open,
  onClose,
  init = null,
  type,
}) {
  const [form, setForm] = useState({
    name: "",
    description: "",
    file: null,
    newFile: null,
    platform: "Android",
    accessLevel: "Internal",
    tags: new Set(),
    summary: {
      warmup: [],
      drills: [],
      targetPractice: [],
    },
  });

  const [summary, setSummary] = useState({
    open: false,
    type: "",
    list: null,
  });

  const [thumbnail, setThumbnail] = useState(null);

  useEffect(() => {
    if (!summary.list) {
      (async () => {
        const list = await getTipsAndDrills();
        setSummary((prev) => ({ ...prev, list }));
      })();
    }
    if (init) {
      const {
        name,
        descriptions,
        platform,
        accessLevel,
        isPremium,
        tags,
        summary: initSummary,
        bannerImageUrl,
      } = init;

      const tagsSet = new Set(tags);

      const parsed = {
        name,
        accessLevel,
        isPremium,
        description: descriptions,
        file: null,
        platform,
        tags: tagsSet,
      };

      setThumbnail(bannerImageUrl);
      setForm((prev) => ({ ...prev, ...parsed }));

      if (summary.list) {
        const parsedSummary = () => {
          const parse = (arr) => {
            return arr.map((mapEl) => {
              const found = summary.list.find((findEl) => {
                return findEl.uid === mapEl.drillVideo;
              });

              return found;
            });
          };

          return {
            warmup: parse(initSummary.warmup),
            drills: parse(initSummary.drills),
            targetPractice: parse(initSummary.targetPractice),
          };
        };
        setForm((prev) => ({ ...prev, summary: parsedSummary() }));
      }
    }
  }, [init, summary.list, open]);

  const tags = [
    { id: 1, value: "test1", label: "Test 1" },
    { id: 2, value: "test2", label: "Test 2" },
  ];

  const handleInput = (e) => {
    e.persist();
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleFile = (e) => {
    const file = e.target.files[0];

    setThumbnail(URL.createObjectURL(file));
    type === "create"
      ? setForm((prev) => ({ ...prev, file }))
      : setForm((prev) => ({ ...prev, newFile: file }));
  };

  const clearState = () => {
    setForm({
      name: "",
      description: "",
      file: null,
      platform: "Android",
      accessLevel: "Internal",
      isPremium: false,
      tags: new Set(),
      summary: {
        warmup: [],
        drills: [],
        targetPractice: [],
      },
    });
    setThumbnail(null);
  };

  const handleSubmit = () => {
    const summaryMapper = (arr) => {
      return arr.map((el) => ({
        drillVideo: el.uid,
        drillPracticeCount: 0,
        drillPracticeTime: 0,
      }));
    };
    const submitData = {
      ...form,
      tags: [...form.tags],
      summary: {
        warmup: summaryMapper(form.summary.warmup),
        drills: summaryMapper(form.summary.drills),
        targetPractice: summaryMapper(form.summary.targetPractice),
      },
    };
    type === "create" ? onCreate(submitData) : onEdit(init.uid, submitData);
    clearState();
  };

  const handleClose = () => {
    onClose();
    clearState();
  };

  const handleTags = (id) => {
    const newSet = new Set(form.tags);
    if (newSet.has(id)) newSet.delete(id);
    else newSet.add(id);
    setForm((prev) => ({ ...prev, tags: newSet }));
  };

  const handleSummaryAdd = (type) => {
    setSummary((prev) => ({ ...prev, open: true, type }));
  };

  const handleSummaryAddSubmit = (type, data) => {
    setSummary((prev) => ({ ...prev, open: false }));
    setForm((prev) => ({
      ...prev,
      summary: { ...prev.summary, [type]: data },
    }));
  };

  const handleCheckbox = (e) => {
    e.persist();
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.checked }));
  };

  const handleSelect = (value, field) => {
    setForm((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const Thumbnail = () => {
    return thumbnail ? (
      <img width={100} height={100} src={thumbnail} alt="test" />
    ) : (
      <FlexWrap
        style={{
          background: "#C9C9C9",
          width: "100px",
          height: "100px",
          textAlign: "center",
        }}
      >
        Image Preview
      </FlexWrap>
    );
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>
        <Typography variant="h3" component="h3">
          {type === "create" ? "New Practice Guide" : "Edit Practice Guide"}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {summary.open ? (
          <PracticeGuideModalSummaryChoice
            type={summary.type}
            list={summary.list}
            submit={handleSummaryAddSubmit}
            cancel={() => setSummary((prev) => ({ ...prev, open: false }))}
          />
        ) : (
          <>
            <Pad width="450px" height="fit-content">
              <label
                style={{
                  marginRight: "10px",
                  cursor: "pointer",
                }}
                htmlFor="fileInput"
              >
                <Thumbnail />
              </label>
              <FlexWrap justify="space-between" align="baseline">
                <TextField
                  value={form.name}
                  onChange={handleInput}
                  name="name"
                  label="Name"
                />
                <TextField
                  value={form.description}
                  onChange={handleInput}
                  name="description"
                  label="Description"
                />
              </FlexWrap>
              <Pad height="10px" />
              <FlexWrap justify="space-between" align="baseline">
                {/* <Select
                                    labelId="search"
                                    name="search"
                                    onChange={(e) =>
                                        setForm((prev) => ({
                                            ...prev,
                                            platform: e.target.value,
                                        }))
                                    }
                                    value={form.platform}
                                >
                                    <MenuItem value="ndroid">
                                        {platformSelectLabels.android}
                                    </MenuItem>
                                    <MenuItem value="iphone">
                                        {platformSelectLabels.iphone}
                                    </MenuItem>
                                    <MenuItem value="cloud">
                                        {platformSelectLabels.cloud}
                                    </MenuItem>
                                </Select> */}
                <PlatformSelect value={form.platform} onChange={handleSelect} />
                <AccessLevelSelect
                  value={form.accessLevel}
                  onChange={handleSelect}
                />
                <div></div>
                <div></div>
              </FlexWrap>
              <Pad height="10px" />

              <FlexWrap justify="start" align="center">
                <Checkbox
                  id="premium-checkbox"
                  name="isPremium"
                  color="primary"
                  checked={form.isPremium}
                  onChange={handleCheckbox}
                />
                <label htmlFor="premium-checkbox">Premium</label>
              </FlexWrap>
              <PracticeGuideModalSummary
                data={form.summary}
                onAdd={handleSummaryAdd}
              />
              <Pad height="10px" />
              <div>
                {tags.map((el) => (
                  <Chip
                    style={{ marginRight: "5px" }}
                    color="primary"
                    variant={form.tags.has(el.id) ? "default" : "outlined"}
                    key={el.id}
                    label={el.label}
                    onClick={() => handleTags(el.id)}
                  />
                ))}
              </div>
            </Pad>

            <Pad height="10px" />

            <Button
              type="submit"
              onClick={() => handleSubmit()}
              variant="contained"
              color="primary"
            >
              Submit
            </Button>

            <input
              onChange={handleFile}
              id="fileInput"
              style={{ display: "none" }}
              type="file"
            />
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
