import admin, {
  axiosWithToken,
  functionBaseUrl,
} from "../../../common/firebase";
import { fbMapper } from "./helper";
import { parseBytes } from "./common";
import { dateMapper, firestorageExistCheck } from "./helper";

const db = admin.firestore().collection("practiceGuideAlbums");

export const getAlbums = async () => {
  const albums = fbMapper(await db.get());
  return albums;
};

export const getAlbumById = async (id) => {
  const album = await db.doc(id).get();
  return album.data();
};

export const getAlbumsServer = async ({
  limit,
  offset,
  search,
  sort,
  direction,
  status,
}) => {
  const { data } = await axiosWithToken(functionBaseUrl + "/api/web/albums", {
    method: "post",
    data: { limit, offset, search, sort: "order", direction: "asc", status },
  });

  return data;
};

export const createAlbum = async (data) => {
  const {
    title,
    summary,
    practiceGuides,
    skillLevel,
    coverImage,
    introVideo,
    status,
  } = data;

  const parsed = {
    title,
    description: summary,
    practiceGuideIds: practiceGuides,
    skillLevel,
    createdAt: Date.now(),
    updatedAt: Date.now(),
    status,
    goal: "",
    category: "",
    accessLevel: "",
    platform: "Cloud",
  };

  const created = await db.add(parsed);

  const storageRef = admin.storage().ref();

  if (coverImage.file) {
    const thumbnailExtension = coverImage.file.name.split(".").pop();
    const thumbnailFileRef = storageRef.child(
      `practice_guide_albums/${created.id}/thumbnail.${thumbnailExtension}`
    );
    const thumbnailUploadTaskSnapshot = await thumbnailFileRef.put(
      coverImage.file
    );
    const thumbnailDownloadURL =
      await thumbnailUploadTaskSnapshot.ref.getDownloadURL();

    await db.doc(created.id).update({
      coverImage: thumbnailDownloadURL,
      coverImageFileSize: parseBytes(coverImage.file.size),
    });
  } else {
    await db.doc(created.id).update({
      coverImage: "",
      coverImageFileSize: 0,
    });
  }

  if (introVideo.file) {
    const introVideoExtension = introVideo.file.name.split(".").pop();
    const introVideoFileRef = storageRef.child(
      `practice_guide_albums/${created.id}/introVideo.${introVideoExtension}`
    );
    const introVideoUploadTaskSnapshot = await introVideoFileRef.put(
      introVideo.file
    );
    const introVideoDownloadURL =
      await introVideoUploadTaskSnapshot.ref.getDownloadURL();

    await db.doc(created.id).update({
      introVideo: {
        videoPath: introVideoDownloadURL,
        duration: introVideo.duration,
        title: "Title",
        thumbnail: "",
        fileSize: parseBytes(introVideo.file.size),
      },
    });
  } else {
    await db.doc(created.id).update({
      introVideo: {
        videoPath: "",
        duration: 0,
        title: "",
        thumbnail: "",
        fileSize: 0,
      },
    });
  }
};

export const updateAlbum = async (id, data) => {
  const {
    title,
    summary,
    skillLevel,
    practiceGuides,
    introVideo,
    coverImage,
    status,
  } = data;

  const parsed = {
    title,
    skillLevel,
    description: summary,
    practiceGuideIds: practiceGuides,
    status,
  };

  if (coverImage.file) {
    const storageRef = admin.storage().ref();

    const thumbnailExtension = coverImage.file.name.split(".").pop();
    const thumbnailFileRef = storageRef.child(
      `practice_guide_albums/${id}/thumbnail.${thumbnailExtension}`
    );
    const thumbnailUploadTaskSnapshot = await thumbnailFileRef.put(
      coverImage.file
    );
    const thumbnailDownloadURL =
      await thumbnailUploadTaskSnapshot.ref.getDownloadURL();

    parsed.coverImage = thumbnailDownloadURL;
    parsed.coverImageFileSize = parseBytes(coverImage.file.size);
  }

  if (introVideo.file) {
    const storageRef = admin.storage().ref();

    const introVideoExtension = introVideo.file.name.split(".").pop();
    const introVideoFileRef = storageRef.child(
      `practice_guide_albums/${id}/introVideo.${introVideoExtension}`
    );

    const introVideoUploadTaskSnapshot = await introVideoFileRef.put(
      introVideo.file
    );
    const introVideoDownloadURL =
      await introVideoUploadTaskSnapshot.ref.getDownloadURL();

    parsed.introVideo = {
      videoPath: introVideoDownloadURL,
      duration: introVideo.duration,
      title: "Title",
      thumbnail: "",
      fileSize: parseBytes(introVideo.file.size),
    };
  }

  await db.doc(id).update(parsed);
};

export const deleteAlbum = async (id) => {
  await db.doc(id).delete();
};

export const copyAlbum = async (id) => {
  const album = (await db.doc(id).get()).data();
  await db.add({ ...album, title: `${album.title} (1)` });
};

export const reorderAlbums = async (idArr) => {
  await Promise.all(
    idArr.map(async (el, index) => {
      return await db.doc(el).update({ order: index });
    })
  );
};
