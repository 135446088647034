import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/styles';
import {Avatar, Typography, CircularProgress} from '@material-ui/core';
import firebase, { axiosWithToken } from '../../../../../../common/firebase'
import { functionBaseUrl } from '../../../../../../common/firebase'

const useStyles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
  },
  avatar: {
    width: 60,
    height: 60,
  },
  name: {
    marginTop: 10
  },
});

class Profile extends React.Component{
  constructor(props) {
    super(props)
    const firebaseUser = firebase.auth().currentUser

    this.state = {
      name: '',
      avatar: '',
      role: '',
      email: '',
      uid: firebaseUser ? firebaseUser.uid : "",
      loading: true
    };
  }

  componentDidMount () {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        axiosWithToken(functionBaseUrl+'/api/users/'+ user.uid, {
          method: 'get',
        })
        .then(response => {
          this.setState({
            name: response.data.firstName + ' ' + response.data.lastName,
            email: response.data.email,
            avatar: response.data.picture,
            role: response.data.role,
            loading: false
          });
        })
        .catch(err => {
          this.setState({loading: false});
          console.log(err);
        });
      } else {
        this.setState({loading: false});
        console.log('User is not logged in');
      }
    });
  }

  titleCase = (str) => {
    if(str) {
      str = str.replaceAll('-', ' ');
      var splitStr = str.toLowerCase().split(' ');
      for (var i = 0; i < splitStr.length; i++) {
          splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
      }
      return splitStr.join(' '); 
    } else {
      return ""
    }
  }

  render() {
    const { classes } = this.props        
    return (
      <div className={classes.root}>
        <Avatar 
        component={RouterLink}
        to="/account"
        className={classes.avatar}
        src={this.state.avatar}
        style={{display : this.state.loading ? "none" : "flex"}}
        />
        <Typography          
          variant="h4"
          className={classes.name}
        >
          {this.state.name}
        </Typography>
        <Typography variant="body2">{this.state.email}</Typography>
        <CircularProgress style={{display : this.state.loading ? "block" : "none"}} />
      </div>
    );
  }
};

Profile.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(useStyles)(Profile);