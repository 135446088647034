import admin, { functionBaseUrl, axiosWithToken } from "../../common/firebase";

export const getQuestionnairesStatistics = async (data) => {
  const res = await axiosWithToken(
    `${functionBaseUrl}/api/v1/questionnaires/statistics`,
    {
      method: "post",
      data,
    }
  );

  return res.data;
};

export const getQuestionsList = async () => {
  const res = await axiosWithToken(
    `${functionBaseUrl}/api/v1/questionnaires/questions-list`,
    {
      method: "get",
    }
  );

  return res.data;
};

export const getQuestionsCsv = async (data) => {
  const res = await axiosWithToken(
    `${functionBaseUrl}/api/v1/questionnaires/csv-data`,
    {
      method: "post",
      data,
    }
  );

  return res.data;
};
