import React, { forwardRef } from "react";

import {
    AddBox,
    ArrowDownward,
    Check,
    ChevronLeft,
    ChevronRight,
    Clear,
    DeleteOutline,
    Edit,
    FilterList,
    FirstPage,
    LastPage,
    Remove,
    SaveAlt,
    Search,
    ViewColumn,
    Receipt,
} from "@material-ui/icons";
import MaterialTable from "material-table";
import Swal from "sweetalert2";
import moment from "moment";

import { functionBaseUrl, axiosWithToken } from "../../../common/firebase";

const tableHeader = [
    { field: "email", title: "Email Address" },
    {
        field: "name",
        title: "Name",
        render: (rowData) => {
            return `${rowData.firstName} ${rowData.lastName}`;
        },
    },
    //{ field: 'gender', title: 'Gender' },
    { field: "studentsCount", title: "Number of students" },
    {
        field: "userCreated",
        title: "Created",
        render: (rowData) => {
            return moment(parseInt(rowData.userCreated)).format("MM/DD/YYYY");
        },
    },
];

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
        <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
        <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    Receipt: forwardRef((props, ref) => <Receipt {...props} ref={ref} />),
};

function EnterpriseInstructorsListComponent({ accounts, handleRefresh }) {
    const handleDelete = async (event, data) => {
        Swal.fire({
            title: `<p style="font-size:70%;">Are you sure you want to delete ${data.firstName} ${data.lastName} (${data.email})?</p>`,
            icon: "warning",
            confirmButtonText: "Yes",
            showCancelButton: true,
            cancelButtonText: "No",
            cancelButtonColor: "red",
            allowOutsideClick: false,
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await axiosWithToken(
                        `${functionBaseUrl}/api/usersEnterprise/instructors/remove`,
                        {
                            method: "post",
                            data: {
                                id: data.uid,
                            },
                        }
                    );

                    handleRefresh();

                    Swal.fire({
                        title: '<p style="font-size:70%;">Deleted successfully</p>',
                        icon: "success",
                        confirmButtonText: "Ok",
                        allowOutsideClick: false,
                        timer: 2000,
                    });
                } catch (e) {
                    Swal.fire('<p style="font-size:70%;">Error occured</p>');
                }
            }
        });
    };

    return (
        <>
            <MaterialTable
                style={{ marginTop: "10px", marginBottom: "10px" }}
                icons={tableIcons}
                columns={tableHeader}
                title=""
                data={accounts}
                options={{
                    selection: false,
                    sorting: true,
                    search: true,
                    paging: true,
                    rowStyle: (x) => {
                        if (x.tableData.id % 2) {
                            return { backgroundColor: "#f2f2f2" };
                        }
                    },
                }}
                actions={[
                    (rowData) => ({
                        tooltip: "Delete",
                        icon: tableIcons.Delete,
                        disabled:
                            rowData.subscriptionType.includes(
                                "enterprise_master"
                            ),
                        onClick: handleDelete,
                    }),
                ]}
            />
        </>
    );
}

export const EnterpriseInstructorsList = EnterpriseInstructorsListComponent;
