import React, { useEffect, useState } from "react";
import { Button, withStyles } from "@material-ui/core";
import admin from "../../../common/firebase";
import BackwardIcon from "@material-ui/icons/ArrowBackIos";
import ForwardIcon from "@material-ui/icons/ArrowForwardIos";
import { FlexWrap, Pad } from "../../../components";

export function RemoteControlSessionPreview({
  init,
  data,
  deleteFn,
  createFn,
  resetFn,
}) {
  const [preview, setPreview] = useState([]);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [confirmReset, setConfirmReset] = useState(false);

  useEffect(() => {
    if (data) {
      setPreview(data);
      setCurrentVideoIndex(preview.length - 1);
    }
  }, [data]);

  const CustomButton = withStyles({
    root: {
      border: "2px solid",
      borderColor: "#580CE3",
      color: "#580CE3",
      width: "100%",
      "&:hover": {
        backgroundColor: "#580CE3",
        borderColor: "#580CE3",
        color: "#fff",
      },
      "&:active": {
        backgroundColor: "#580CE3",
        borderColor: "#580CE3",
        color: "#fff",
      },
    },
  })(Button);

  const ResetButton = withStyles({
    root: {
      border: "2px solid",
      borderColor: "#e30c0c",
      color: "#e30c0c",
      width: "100%",
      "&:hover": {
        backgroundColor: "#e30c0c",
        borderColor: "#e30c0c",
        color: "#fff",
      },
      "&:active": {
        backgroundColor: "#e30c0c",
        borderColor: "#e30c0c",
        color: "#fff",
      },
    },
  })(Button);

  const ConfirmButton = withStyles({
    root: {
      border: "2px solid",
      borderColor: "#666666",
      color: "#666666",
      width: "100%",
      "&:hover": {
        backgroundColor: "#666666",
        borderColor: "#666666",
        color: "#fff",
      },
      "&:active": {
        backgroundColor: "#666666",
        borderColor: "#666666",
        color: "#fff",
      },
    },
  })(Button);

  useEffect(() => {
    const coachId = admin.auth().currentUser.uid;
    const unsubscribe = admin
      .firestore()
      .collection("remoteControlSession")
      .where("coachId", "==", coachId)
      .where("status", "==", "active")
      .onSnapshot((data) => {
        const newData = data.docs.map((el) => el.data())[0];
        if (newData) {
          setPreview(newData.recordedVideos);
          setCurrentVideoIndex(newData.recordedVideos.length - 1);
        }
      });

    return () => {
      unsubscribe();
    };
  }, []);

  const handleIndexChange = (type) => {
    switch (type) {
      case "forward":
        if (preview.length - 1 !== currentVideoIndex)
          setCurrentVideoIndex((prev) => prev + 1);
        break;

      case "backward":
        if (currentVideoIndex !== 0) setCurrentVideoIndex((prev) => prev - 1);
        break;
    }
  };

  const activeIconCheck = (type) => {
    switch (type) {
      case "forward":
        return preview.length - 1 !== currentVideoIndex;
      case "backward":
        return currentVideoIndex !== 0;
    }
  };

  const handleDelete = () => {
    deleteFn(preview[currentVideoIndex]);
    setCurrentVideoIndex(0);
  };

  return (
    <>
      {preview.length !== 0 ? (
        <>
          <div style={{ fontWeight: "bold", fontSize: "20px" }}>
            Review recording
          </div>
          <Pad height="20px" />
          <FlexWrap>
            <video
              key={preview.length ? preview[currentVideoIndex]?.videoPath : ""}
              width="300"
              height="500"
              controls
              autoPlay
              muted
            >
              <source
                src={
                  preview.length ? preview[currentVideoIndex]?.videoPath : ""
                }
                type="video/mp4"
              />
            </video>
          </FlexWrap>
          <Pad height="20px" />
          <FlexWrap>
            <Pad width="50%">
              <CustomButton onClick={createFn}>CREATE SESSION</CustomButton>
              <Pad height="20px" />
              <CustomButton onClick={handleDelete}>DELETE VIDEO</CustomButton>
              <Pad height="20px" />
              {confirmReset ? (
                <FlexWrap direction="row" justify="space-between">
                  <ConfirmButton
                    style={{ width: "47%" }}
                    onClick={() => setConfirmReset(false)}
                  >
                    CANCEL
                  </ConfirmButton>
                  <ResetButton
                    style={{ width: "47%" }}
                    onClick={() => {
                      resetFn();
                      setConfirmReset(false);
                    }}
                  >
                    RESET
                  </ResetButton>
                </FlexWrap>
              ) : (
                <CustomButton onClick={() => setConfirmReset(true)}>
                  RESET REMOTE SESSION
                </CustomButton>
              )}
            </Pad>
          </FlexWrap>
          <Pad height="20px" />
          <FlexWrap justify="center" align="center">
            <BackwardIcon
              style={{
                cursor: activeIconCheck("backward") ? "pointer" : "",
                color: activeIconCheck("backward") ? "black" : "gray",
              }}
              onClick={() => handleIndexChange("backward")}
            />
            <div style={{ margin: "0 15px 0 10px" }}>
              {currentVideoIndex + 1}/{preview.length}
            </div>
            <ForwardIcon
              style={{
                cursor: activeIconCheck("forward") ? "pointer" : "",
                color: activeIconCheck("forward") ? "black" : "gray",
              }}
              onClick={() => handleIndexChange("forward")}
            />
          </FlexWrap>
        </>
      ) : (
        <FlexWrap justify="center" direction="column">
          <FlexWrap width="100%" justify="start">
            <div style={{ fontWeight: "bold", fontSize: "20px" }}>
              Set up instructions
            </div>
          </FlexWrap>
          <img
            src="/images/RemoteControl.png"
            id="pairing"
            width="500px"
            height="auto"
            style={{
              backgroundColor: "#F4F6F8",
            }}
          />
          <div
            style={{
              background: "#E4E4E4",
              padding: "16px",
              width: "100%",
            }}
          >
            <div>
              Step 1: Set up your device and select "Create remote session" on
              the app
            </div>
            <div>Step 2: Select the device, student and initial settings</div>
            <div>Step 3: Press record button</div>
            <div>
              Step 4: To change recording settings press settings button
            </div>
          </div>
          <Pad height="20px" />
          <Pad width="50%">
            {init ? (
              confirmReset ? (
                <FlexWrap direction="row" justify="space-between">
                  <ConfirmButton
                    style={{ width: "47%" }}
                    onClick={() => setConfirmReset(false)}
                  >
                    CANCEL
                  </ConfirmButton>
                  <ResetButton
                    style={{ width: "47%" }}
                    onClick={() => {
                      resetFn();
                      setConfirmReset(false);
                    }}
                  >
                    RESET
                  </ResetButton>
                </FlexWrap>
              ) : (
                <CustomButton onClick={() => setConfirmReset(true)}>
                  RESET REMOTE SESSION
                </CustomButton>
              )
            ) : (
              ""
            )}
          </Pad>
        </FlexWrap>
      )}
    </>
  );
}
