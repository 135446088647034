import React from "react";
import { Chip } from "@material-ui/core";

export function Tags({ selected, onSelect }) {
  const tags = [
    { id: 1, value: "test1", label: "Test 1" },
    { id: 2, value: "test2", label: "Test 2" },
  ];

  return (
    <div>
      {tags.map((el) => (
        <Chip
          style={{ marginRight: "5px" }}
          color="primary"
          variant={selected.has(el.label) ? "default" : "outlined"}
          key={el.id}
          label={el.label}
          onClick={() => onSelect(el.label)}
        />
      ))}
    </div>
  );
}
