import React from 'react';
import firebase from '../../../../common/firebase';
import {
    Button,
    CircularProgress,
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
    DialogTitle
  } from '@material-ui/core';
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const CropImage = (props) => {
    const [popUp, setPopUp] = React.useState({
        loading: false,
        crop: {
            unit: "px",
            x: 0,
            y: 0,
            width: 250,
            height: 250
        }
    });

    const makeClientCrop = async (crop) => {
        if (popUp.imageRef && crop) {
            const croppedImageBlob = await getCroppedImg(
                popUp.imageRef,
                crop,
                "newFile.jpeg"
            );
            setPopUp({ ...popUp, croppedImageBlob: croppedImageBlob });
        }
    }
    const getCroppedImg = (image, crop, fileName) => {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
        );

        return new Promise((resolve, reject) => {
        canvas.toBlob(blob => {
            if (!blob) {
            console.error("Canvas is empty");
            return;
            }
            blob.name = fileName;
            resolve(blob);
        }, "image/jpeg");
        });
    }
    const onCropChange = (crop) => {
        const {imageRef} = popUp;
        crop.width = Math.min(crop.width, imageRef.width);
        crop.height = crop.width;
        crop.height = Math.min(crop.height, imageRef.height);
        crop.width = crop.height;
        setPopUp({
        ...popUp,
        crop: crop
        })
    }
    const handleFileUpload = () => {
        setPopUp({...popUp, loading: true})
        if (popUp.croppedImageBlob) {
            firebase.storage().ref('/profile-pictures/user-profile-picture-' + props.user.uid).put(popUp.croppedImageBlob)
            .then(() => {  
                firebase.storage().ref('/profile-pictures').child('user-profile-picture-' + props.user.uid).getDownloadURL()
                .then((url) => {
                    props.uploadImages(url);
                })
            })
            .catch((error) => {
                setPopUp({...popUp, error: error.message})
                console.log(error)
            });
        }
    }
    const {file, Alert} = props;
    return (
    <div>
        {file ? (
        <Dialog 
            disableBackdropClick
            disableEscapeKeyDown
            open={file ? true : false}
            onClose={props.handlePopUpClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Crop Profile Photo</DialogTitle>
            <DialogContent>
            {/* Loading Screen */}
            <div
                style = {{display: (popUp.loading) ? "flex" : "none",  justifyContent:'center', alignItems:'center'}}
            >
                <CircularProgress />
            </div>

            {/* Main Screen */}
            <div 
                style = {{display: (popUp.loading) ? "none" : "block"}}
            >
                <DialogContentText>
                    Please crop your image before submitting
                </DialogContentText>
                <ReactCrop
                src={file}
                crop={popUp.crop}
                ruleOfThirds
                onImageLoaded={(image) => {popUp.imageRef = image}}
                onComplete={(crop) => {makeClientCrop(crop)}}
                onChange={onCropChange}
                />
            </div>
            {popUp.error ? <Alert severity="error">Error: {popUp.error}</Alert> : ""}
            </DialogContent>
            <DialogActions>
            <div 
                style = {{display: (popUp.loading) ? "none" : "block"}}
            >
                <Button onClick={handleFileUpload} color="primary">
                    Submit
                </Button>
                <Button onClick={props.handlePopUpClose} color="primary">
                Cancel
                </Button>
            </div>
            </DialogActions>
        </Dialog>) : null}
    </div>)
            
};

export default CropImage