import React from 'react';
import { withStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  InputAdornment,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Typography,
  Dialog,
  DialogContent,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import MuiPhoneNumber from "material-ui-phone-number";
import { functionBaseUrl, axiosWithToken } from '../../../../common/firebase';
import firebase from '../../../../common/firebase';
import 'firebase/firestore';
import Swal from 'sweetalert2';
import moment from 'moment';
import axios from 'axios';
import { StripeUpdatePayment } from '../../../../components/stripeUpdatePayment';

const useStyles = () => ({
  root: {},
  number: { borderColor: "#ffffff", }
});

class AccountDetails extends React.Component {
  constructor(props) {
    super(props)
    const currUser = firebase.auth().currentUser;

    this.state = {
      firstName: "",
      email: currUser.email,
      dominantHand: "",
      lastName: "",
      userName: "",
      dob: "",
      height: "",
      hipSize: "",
      weight: "",
      disability: "",
      gender: "",
      uid: currUser.uid,
      roles: [],
      role: "",
      subscriptionType: "",
      address: "",
      //addressId : this.props.user.addressId,
      popUpOpen: false,
      requestedRole: "",
      roleRequest: false,
      popUpLoading: false,
      street_number: "",
      route: "",
      locality: "",
      administrative_area_level_1: "",
      country: "",
      postal_code: "",
      paymentResponse: {},
      subscriptionId: "",
      subStatus: "Not Active",
      subData: {},
      plan: "",
      sessionToken: "",
      transactionDetails: {},
      openUpdateCard: false,
      last4: "",
      brand: "",
    }
  }

  componentDidMount() {
    if (this.state.uid) {
      this.setState({
        firstName: this.props.user.firstName || "",
        userName: this.props.user.userName || "",
        email: this.props.user.email || "",
        dominantHand: this.props.user.dominantHand || "",
        lastName: this.props.user.lastName || "",
        dob: this.props.user.dob || "",
        height: this.props.user.height || "",
        hipSize: this.props.user.hipSize || "",
        weight: this.props.user.weight || "",
        disability: this.props.user.disability || "",
        gender: this.props.user.gender || "",
        uid: this.props.user.uid || "",
        role: this.props.user.role || "",
        subscriptionType: this.props.user.subscriptionType || "",
        street_number: this.props.user.street_number || "",
        route: this.props.user.route || "",
        locality: this.props.user.locality || "",
        administrative_area_level_1: this.props.user.administrative_area_level_1 || "",
        country: this.props.user.country || "",
        postal_code: this.props.user.postal_code || "",
        contactNumber: this.props.user.contactNumber || "",
        //addressId: this.props.user.addressId || "",
      })

      if (!this.props.user.gender || !this.props.user.dominantHand || !this.props.user.height || !this.props.user.hipSize) {
        /*Swal.fire({
          title: '<p style="font-size:70%;">Complete Your Baseball Profile</p>',
          icon: 'warning',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          timer: 3000,
        });*/
      }
    }
    if (!this.props.user.subscriptionType.includes('free')) {
      this.subscriptionStatus()
    }

    const query = new URLSearchParams(window.location.search);

    if (query.get("session_id")) {
      this.getSession(query.get("session_id"));
    }
  }

  getSession = async (sessionId) => {
    try {
      const response = await axios(`${functionBaseUrl}/api/stripeSession/${sessionId}`);
      const setupIntent = response.data.setup_intent;
      const firebaseUser = firebase.auth().currentUser;
      await axios(`${functionBaseUrl}/api/stripeUpdatePaymentMethod`, {
        method: 'POST',
        data: { email: firebaseUser.email, setupIntent },
      });
      Swal.fire({
        title: '<p style="font-size:70%;">Card details successfully updated</p>',
        icon: 'success',
        confirmButtonText: 'Ok',
        allowOusideClick: false
      });
    } catch (error) {
      console.error(error);
      Swal.fire({
        title: '<p style="font-size:70%;">Error updating card details</p>',
        icon: 'warning',
        confirmButtonText: 'Ok',
        allowOutsideClick: false,
        timer: 3000,
      });
    }
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleContactChange = (event) => {
    this.setState({ contactNumber: event });
  }

  titleCase = (str) => {
    if (str) {
      str = str.replaceAll('-', ' ');
      var splitStr = str.toLowerCase().split(' ');
      for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      return splitStr.join(' ');
    } else {
      return ""
    }
  }

  getPlanDescription = (productId) => {
    if (productId) {
      productId = productId.replaceAll('_', ' ');
      var splitStr = productId.toLowerCase().split(' ');
      for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      return splitStr.join(' ');
    } else {
      return ""
    }
  }

  subscriptionStatus = async () => {
    try {
      const response = await axiosWithToken(functionBaseUrl + '/api/verifySub/' + this.state.uid, {
        method: 'post'
      });

      this.setState({
        transactionId: 'transactionId' in response.data.data !== undefined ? response.data.data.transactionId : "",
        subscriptionId: 'id' in response.data.data !== undefined ? response.data.data.id : "",
        subscriptionType: 'productId' in response.data.data ? response.data.data.productId : "",
        subStatus: response.data.success,
        subData: JSON.stringify(response.data.data) !== undefined ? response.data.data : {}
      })

      if (response?.data?.data?.platform == 'Stripe') {
        const firebaseUser = firebase.auth().currentUser;
        const responseMethods = await axios(functionBaseUrl + '/api/stripePaymentMethods', {
          method: 'POST',
          data: { email: firebaseUser.email },
        });
        if (responseMethods.data.paymentMethods.data.length > 0) {
          this.setState({ brand: responseMethods.data.paymentMethods.data[0].card.brand.toUpperCase(), last4: responseMethods.data.paymentMethods.data[0].card.last4 });
        }
      }
    } catch (err) {
      console.log(err)
    };
  }

  checkSubStatus = () => {
    if (this.state.subscriptionType.includes('free')) {
      return "Active"
    }
    if (this.state.subStatus === true) {
      return "Active"
    } else {
      return "Not Active"
    }
  }

  checkAutoRenewal = () => {
    if (this.state.subscriptionType.includes('free')) {
      return ""
    }
    if (JSON.stringify(this.state.subData) !== undefined && JSON.stringify(this.state.subData) !== '{}') {
      if (this.state.subData.autoRenewal) {
        return "On"
      }
    }
    return "Off"
  }

  checkSubPlatform = () => {
    let platform = ""
    if (JSON.stringify(this.state.subData) !== undefined && JSON.stringify(this.state.subData) !== '{}') {
      if (this.state.subData.platform) {
        platform = this.state.subData.platform
      }
    }

    return platform
  }

  getSubEndDate = () => {
    let date = ""
    if (JSON.stringify(this.state.subData) !== undefined && JSON.stringify(this.state.subData) !== '{}') {
      if (this.state.subData.endDate) {
        date = moment(String(this.state.subData.endDate), "x").format("MM/DD/YYYY");
      }
    }
    return date
  }

  cancelStripeSubscription = () => {
    Swal.fire({
      title: '<p style="font-size:70%;">Are you sure you want to cancel your subscription?</p>',
      icon: 'warning',
      confirmButtonText: 'Yes',
      showCancelButton: true,
      cancelButtonText: 'No',
      cancelButtonColor: 'red',
      allowOutsideClick: false
    }).then((result) => {
      if (result.isConfirmed) {
        axiosWithToken(functionBaseUrl + '/api/cancelStripeSub/' + this.state.uid, {
          method: 'POST',
        })
          .then(() => {
            Swal.fire({
              title: '<p style="font-size:70%;">Subscription successfully canceled</p>',
              icon: 'success',
              confirmButtonText: 'Ok',
              allowOutsideClick: false
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload()
              }
            })
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        return false
      }
    })
  }

  pauseStripeSubscription = () => {
    Swal.fire({
      title: '<p style="font-size:70%;">Are you sure you want to cancel your subscription?</p>',
      icon: 'warning',
      confirmButtonText: 'Yes',
      showCancelButton: true,
      cancelButtonText: 'No',
      cancelButtonColor: 'red',
      allowOutsideClick: false
    }).then((result) => {
      if (result.isConfirmed) {
        axiosWithToken(functionBaseUrl + '/api/pauseStripeSub/' + this.state.uid, {
          method: 'POST',
        })
          .then(() => {
            Swal.fire({
              title: '<p style="font-size:70%;">Subscription successfully canceled</p>',
              icon: 'success',
              confirmButtonText: 'Ok',
              allowOutsideClick: false
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload()
              }
            })
          })
          .catch(err => {
            console.log(err);
            Swal.fire({
              title: '<p style="font-size:70%;">Error canceling subscription</p>',
              icon: 'error',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              timer: 3000,
            });
          });
      } else {
        return false
      }
    })
  }

  resumeStripeSubscription = () => {
    Swal.fire({
      title: '<p style="font-size:70%;">Are you sure you want to resume your subscription?</p>',
      icon: 'warning',
      confirmButtonText: 'Yes',
      showCancelButton: true,
      cancelButtonText: 'No',
      cancelButtonColor: 'red',
      allowOutsideClick: false
    }).then((result) => {
      if (result.isConfirmed) {
        axiosWithToken(functionBaseUrl + '/api/resumeStripeSub/' + this.state.uid, {
          method: 'POST',
        })
          .then(() => {
            Swal.fire({
              title: '<p style="font-size:70%;">Subscription successfully resumed</p>',
              icon: 'success',
              confirmButtonText: 'Ok',
              allowOutsideClick: false
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload()
              }
            })
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        return false
      }
    })
  }

  cancelButton = () => {
    Swal.fire({
      title: '<p style="font-size:70%;">Are you sure you want to cancel your subscription?</p>',
      icon: 'warning',
      confirmButtonText: 'Yes',
      showCancelButton: true,
      cancelButtonText: 'No',
      cancelButtonColor: 'red',
      allowOutsideClick: false
    }).then((result) => {
      if (result.isConfirmed) {
        axiosWithToken(functionBaseUrl + '/api/cancelElavonTxn/' + this.state.uid, {
          method: 'POST',
        })
          .then(() => {
            Swal.fire({
              title: '<p style="font-size:70%;">Subscription successfully canceled</p>',
              icon: 'success',
              confirmButtonText: 'Ok',
              allowOutsideClick: false
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload()
              }
            })
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        return false
      }
    })
  }

  resumeButton = () => {
    Swal.fire({
      title: '<p style="font-size:70%;">Are you sure you want to resume your subscription?</p>',
      icon: 'warning',
      confirmButtonText: 'Yes',
      showCancelButton: true,
      cancelButtonText: 'No',
      cancelButtonColor: 'red',
      allowOutsideClick: false
    }).then((result) => {
      if (result.isConfirmed) {
        axiosWithToken(functionBaseUrl + '/api/activateElavonTxn/' + this.state.uid, {
          method: 'POST',
        })
          .then(() => {
            Swal.fire({
              title: '<p style="font-size:70%;">Subscription successfully resumed</p>',
              icon: 'success',
              confirmButtonText: 'Ok',
              allowOutsideClick: false
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload()
              }
            })
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        return false
      }
    })
  }

  checkEndDate = (sub) => {
    let today = ""
    if (new Date(Number(sub.startDate) + 86400000 * 7) > Date.now()) {
      today = String((Number(sub.startDate) + 86400000 * 7))
    } else {
      today = String(sub.endDate)
    }
    return today
  }

  getEndDate = (sub) => {
    let date = ''
    if (sub.productId.includes('free')) {
      date = ''
    } else if (sub.productId.includes('monthly')) {
      date = String((Number(sub.endDate) + 86400000 * 31))
    } else {
      date = String((Number(sub.endDate) + 86400000 * 365))
    }
    return date
  }

  changeDateFormat = (startDate, endDate) => {
    let today
    if (new Date(Number(startDate) + 86400000 * 7) > Date.now()) {
      today = new Date(Number(startDate) + 86400000 * 7)
    } else {
      today = new Date(Number(endDate))
    }
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    let yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    today = mm + '/' + dd + '/' + yyyy;
    return today
  }

  currentPlan = () => {
    if (this.state.subscriptionType.includes('student_lite')) {
      if (this.state.subscriptionType.includes('monthly')) {
        return '3D Practice Monthly';
      } else {
        return '3D Practice Annually';
      }
    } else if (this.state.subscriptionType.includes('pro_lite')) {
      if (this.state.subscriptionType.includes('monthly')) {
        return 'Pro Lite Monthly';
      } else {
        return 'Pro Lite Annually';
      }
    } else if (this.state.subscriptionType.includes('pro_premium')) {
      if (this.state.subscriptionType.includes('monthly')) {
        return '3D Pro Monthly';
      } else {
        return '3D Pro Annually';
      }
    } else if (this.state.subscriptionType.includes('enterprise_master')) {
      if (this.props.isStaffEnterprise) {
        return 'Enterprise Staff'
      } else {
        return 'Enterprise Master';
      }
    } else if (this.state.subscriptionType.includes('enterprise_coach')) {
      return 'Enterprise Coach';
    } else {
      return "Free";
    }
  }

  handleSubmit = (event) => {
    event.preventDefault()
    if (!this.state.firstName.trim()) {
      Swal.fire('<p style="font-size:70%;">Please enter valid first name</p>');
      return;
    }
    if (!this.state.lastName.trim()) {
      Swal.fire('<p style="font-size:70%;">Please enter valid last name</p>');
      return;
    }
    axiosWithToken(functionBaseUrl + '/api/users/' + this.state.uid, {
      method: 'patch',
      data: {
        userInfo: {
          firstName: this.state.firstName,
          userName: this.state.userName,
          email: this.state.email,
          lastName: this.state.lastName,
          height: this.state.height,
          hipSize: this.state.hipSize,
          //weight: this.state.weight,
          gender: this.state.gender,
          //dob: this.state.dob,
          dominantHand: this.state.dominantHand,
          //disability: this.state.disability,
          //role: this.state.role,
          contactNumber: this.state.contactNumber
        }
      }
    })
      .then(() => {
        Swal.fire({
          title: '<p style="font-size:70%;">Your changes have been saved!</p>',
          icon: 'success',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          timer: 2000
        }).then(() => {
          window.location.reload()
        })
      })
      .catch(err => {
        alert('There was an error updating your info')
        console.log(err);
      });
  }

  render() {
    const { classes } = this.props
    return (
      <div>
        <Card style={{ maxWidth: 400 }}>
          <form
            autoComplete="off"
            //noValidate
            onSubmit={this.handleSubmit}
          >
            <CardHeader
              subheader="This information can be edited"
              title="Profile"
              titleTypographyProps={{ variant: "h5", color: "primary" }}
            />
            <Divider />
            <CardContent>
              <Grid
                container
                spacing={1}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="First name"
                    margin="dense"
                    name="firstName"
                    id="firstName"
                    onChange={this.handleChange}
                    required
                    variant="outlined"
                    value={this.state.firstName}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Last name"
                    margin="dense"
                    name="lastName"
                    onChange={this.handleChange}
                    required
                    variant="outlined"
                    value={this.state.lastName}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="DOB (MM/DD/YYYY)"
                    name="dob"
                    disabled
                    margin="dense"
                    onChange={this.handleChange}
                    required
                    //type="date"                
                    variant="outlined"
                    value={this.state.dob}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <MuiPhoneNumber
                    fullWidth
                    margin="dense"
                    label="Phone Number"
                    name="contactNumber"
                    defaultCountry={'us'}
                    onChange={this.handleContactChange}
                    value={this.state.contactNumber}
                    variant="outlined"
                    className={classes.number}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <FormControl
                    variant="outlined"
                    fullWidth
                    style={{
                      border: this.state.gender ? 'none' : '1px solid red',
                    }}
                    margin="dense"
                  >
                    <InputLabel id="gender-select">Gender</InputLabel>
                    <Select
                      id="gender"
                      label="Gender"
                      labelId="gender-select"
                      name="gender"
                      value={this.state.gender.toUpperCase()}
                      onChange={this.handleChange}
                    >
                      <MenuItem value={'MALE'}>Male</MenuItem>
                      <MenuItem value={'FEMALE'}>Female</MenuItem>
                      <MenuItem value={'PREFER NOT TO ANSWER'}>Prefer not to answer</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <FormControl
                    fullWidth
                    variant="outlined"
                    style={{
                      border: this.state.dominantHand ? 'none' : '1px solid red',
                    }}
                    margin="dense"
                  >
                    <InputLabel id="dominantHand-select">Dominant Hand</InputLabel>
                    <Select
                      id="dominantHand"
                      label="Dominant Hand"
                      labelId="dominantHand-select"
                      name='dominantHand'
                      value={this.state.dominantHand}
                      onChange={this.handleChange}
                    >
                      <MenuItem value={'Right'}>Right</MenuItem>
                      <MenuItem value={'Left'}>Left</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    label={"Height (e.g. 5'5\")"}
                    margin="dense"
                    name="height"
                    style={{
                      border: this.state.height ? 'none' : '1px solid red',
                    }}
                    onChange={this.handleChange}
                    variant="outlined"
                    value={this.state.height}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    label={"Hip Width (Inches)"}
                    margin="dense"
                    name="hipSize"
                    style={{
                      border: this.state.hipSize ? 'none' : '1px solid red',
                    }}
                    onChange={this.handleChange}
                    variant="outlined"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">in</InputAdornment>,
                    }}
                    value={this.state.hipSize}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Button
                color="primary"
                variant="outlined"
                type="submit"
              //onClick={this.handleSave}
              >
                Save details
              </Button>
            </CardActions>
          </form>

          <CardContent>
            <Divider />
            <CardHeader
              title="Subscription"
              titleTypographyProps={{ variant: "h5", color: "primary" }}
            />
            <Typography
              color="textSecondary"
              variant="body1"
              marginTop="theme.spacing(1)"
            >
              <b>Status:</b> {" " + this.checkSubStatus()}
            </Typography>
            <Typography
              color="textSecondary"
              variant="body1"
              marginTop="theme.spacing(1)"
            >
              <span style={{ marginRight: '10px' }}><b>Current Plan:</b> {" " + this.currentPlan()}</span>
              <RouterLink to="/plans">
                <Button
                  variant="contained"
                  color="primary"
                >
                  Update Plan
                </Button>
              </RouterLink>
              {/*!this.state.subscriptionType.includes('free') && this.state.subData.platform === 'Stripe' && (
                <>
                  <div>
                    <Button
                      style={{ marginTop: '10px' }}
                      variant="contained"
                      color="primary"
                      onClick={() => { this.setState({ openUpdateCard: true }) }}
                    >
                      Update Card
                    </Button>
                  </div>
                  <Dialog
                    fullWidth
                    open={this.state.openUpdateCard}
                  >
                    <DialogContent>
                      <StripeUpdatePayment subscriptionId={this.state.transactionId} />
                    </DialogContent>
                  </Dialog>
                </>
              )*/}
            </Typography>
            {!this.state.subscriptionType.includes('free') && (
              <div>
                <Typography
                  color="textSecondary"
                  variant="body1"
                  marginTop="theme.spacing(1)"
                //marginBottom="theme.spacing(1)"
                >
                  <b>Auto Renewal:</b> {" " + this.checkAutoRenewal()}
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="body1"
                  marginTop="theme.spacing(1)"
                >
                  <b>Subscription Platform:</b> {" " + this.checkSubPlatform()}
                  {this.state.brand && this.state.last4 && (
                    <> ({this.state.brand} •••• {this.state.last4})</>
                  )}
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="body1"
                  marginTop="theme.spacing(1)"
                  marginBottom="theme.spacing(1)"
                >
                  <b>Subscription End Date:</b> {" " + this.getSubEndDate()}
                </Typography>
                {(this.state.subData.platform === 'Apple' || this.state.subData.platform === 'Android') ?
                  <Typography
                    color="textSecondary"
                    variant="body1"
                    marginTop="theme.spacing(1)"
                  >
                    <p>To cancel your subscription, please go to the platform that you originally subscribed from</p>
                  </Typography> : <div></div>
                }
              </div>
            )}
            {(!this.state.subscriptionType.includes('enterprise') && this.state.subData.autoRenewal && this.state.subData.platform === 'Elavon') && (
              <CardActions>
                <Button variant="contained" color="secondary" onClick={() => this.cancelButton()}>Cancel Subscription</Button>
              </CardActions>
            )}
            {(!this.state.subscriptionType.includes('enterprise') && JSON.stringify(this.state.subData) !== undefined && JSON.stringify(this.state.subData) !== '{}' && this.state.subData.autoRenewal === false && this.state.subData.platform === 'Elavon') && (
              <CardActions>
                <Button variant="contained" color="secondary" onClick={() => this.resumeButton()}>Resume Subscription</Button>
              </CardActions>
            )}
            <CardActions>
              {this.state.subData.autoRenewal && this.state.subData.platform === 'Elavon' && (
                <div>
                  <RouterLink to="/account/payment/update">
                    <Button fullWidth color="primary" variant="outlined">Update payment method</Button>
                  </RouterLink>
                </div>
              )}
              {this.state.subData.autoRenewal && this.state.subData.platform === 'Stripe' && (<>
                <Button color="primary" variant="outlined" onClick={() => this.pauseStripeSubscription()}>Cancel Subscription</Button>
              </>)}
              {!this.state.subscriptionType.includes('enterprise') && !this.state.subData.autoRenewal && this.state.subData.platform === 'Stripe' && this.state.subData.endDate > Date.now() && (<>
                <Button color="primary" variant="outlined" onClick={() => this.resumeStripeSubscription()}>Resume Subscription</Button>
              </>)}
            </CardActions>
          </CardContent>
        </Card>
      </div >
    );
  };
}

export default withStyles(useStyles)(AccountDetails);
