import React, { forwardRef, useState, useEffect } from 'react';

import { Container, Grid, Typography } from '@material-ui/core';
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
  Receipt,
} from "@material-ui/icons";
import MaterialTable from "material-table";

import { functionBaseUrl, axiosWithToken } from '../../common/firebase';

const tableHeader = [
  { field: "email", title: "Email Address" },
  {
    field: "name",
    title: "Name",
    render: (rowData) => {
      return `${rowData.firstName} ${rowData.lastName}`;
    },
  },
  {
    field: "change",
    title: "Usage change",
    render: (rowData) => {
      return `${(rowData.thisWeekActivity - rowData.prevWeekActivity) / (rowData.prevWeekActivity ? rowData.prevWeekActivity : 1) * 100}%`;
    },
  },
];

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => (
    <ArrowDownward {...props} ref={ref} />
  )),
  ThirdStateCheck: forwardRef((props, ref) => (
    <Remove {...props} ref={ref} />
  )),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  Receipt: forwardRef((props, ref) => <Receipt {...props} ref={ref} />),
};

function UsageChangeComponent() {
  const [users, setUsers] = useState([]);

  const getUserData = async () => {
    const data = await axiosWithToken(functionBaseUrl + '/api/usageChange', { method: 'GET' });
    setUsers(data.data);
  }

  useEffect(() => {
    getUserData();
  }, []);

  return (<>
    <Container
      component="main"
      style={{ padding: '20px 0' }}
    >
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
        >
          <Typography variant="h2">Usage/behavior Change</Typography>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <MaterialTable
            style={{ marginTop: "10px", marginBottom: "10px" }}
            icons={tableIcons}
            columns={tableHeader}
            title=""
            data={users}
            options={{
              selection: false,
              sorting: true,
              search: true,
              paging: true,
              rowStyle: (x) => {
                if (x.tableData.id % 2) {
                  return { backgroundColor: "#f2f2f2" };
                }
              },
            }}
          />
        </Grid>
      </Grid>
    </Container>
  </>);
}

export const UsageChange = UsageChangeComponent;
