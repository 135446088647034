import React from "react";
import {
  EmptySelector,
  PracticeGuideDetailsContainer,
  PracticeGuideFormColumn,
  PracticeGuideMediaColumn,
  StatusBadge,
} from "../styled";
import {
  FormControl,
  InputLabel,
  TextField,
  Select,
  Dialog,
  DialogContent,
  MenuItem,
} from "@material-ui/core";
import { FlexWrap, Pad } from "../../../components";
import { MediaUpload } from "./MediaUpload";
import {
  HiddenFileInput,
  ModalContentFooter,
  ModalContentHeader,
  ModalContentTitle,
} from "./styled";
import CloseIcon from "@material-ui/icons/Close";
import { EquipmentModal } from "./practiceGuide/EquipmentModal";
import { useState } from "react";
import { OutlinedButton } from "./common/styled";
import { ExitDetailsModal } from "./ExitDetailsModal";
import { TipsAndDrillsMediaUpload } from "./tipsAndDrills/TipsAndDrillsMediaUpload";
import { useEffect } from "react";
import { getTipsAndDrillsById } from "../api_test/tipsAndDrillsApi";
import { FilledEquipment } from "./tipsAndDrills/FilledEquipment";
import {
  EmptyAudioSelector,
  FilledAudioSelector,
  PreviewButton,
} from "./tipsAndDrills/styled";
import DeleteIcon from "@material-ui/icons/Delete";
import { KeyPoints } from "./tipsAndDrills/KeyPoints";
import { v4 } from "uuid";
import { ConfirmSaveModal } from "./common/ConfirmSaveModal";

export function TipsAndDrillsDetailsModal({
  open,
  onClose,
  onSave,
  onUpdate,
  initId,
}) {
  const [form, setForm] = useState({
    title: "",
    author: "",
    drillRepeatCountMin: "",
    drillRepeatCountMax: "",
    repText: "",
    keyPoints: [],
    equipment: [],
  });
  const [media, setMedia] = useState({
    instructionalVideo: { src: "", file: null, duration: 0 },
    instructionalVideoThumbnail: { src: "", file: null, duration: 0 },
    repVideo: { src: "", file: null, duration: 0 },
    repVideoThumbnail: { src: "", file: null, duration: 0 },
    repAudio: { src: "", file: null, duration: 0 },
  });
  const [modal, setModal] = useState({
    equipment: { open: false, init: [] },
    confirm: { open: false },
    exit: { open: false },
  });
  const [initTitle, setInitTitle] = useState(null);

  // useEffect(() => {
  //   console.log(media);
  // }, [media]);

  useEffect(() => {
    if (initId) {
      const fetch = async () => {
        const {
          author,
          equipment,
          drillInstruction,
          definition,
          drillRepeatCountMin,
          drillRepeatCountMax,
          title,
          status,
          audioTrack,
          loopVideo,
          instructionalVideo,
        } = await getTipsAndDrillsById(initId);

        setInitTitle(title);
        setForm({
          author,
          equipment,
          keyPoints: drillInstruction.map((el) => ({ value: el, id: v4() })),
          repText: definition,
          drillRepeatCountMin,
          drillRepeatCountMax,
          title,
        });
        setMedia({
          repAudio: {
            src: audioTrack.audioPath,
            file: null,
            duration: audioTrack.duration,
          },
          repVideo: {
            src: loopVideo.videoPath,
            file: null,
            duration: loopVideo.duration,
          },
          repVideoThumbnail: {
            src: loopVideo.thumbnail,
            file: null,
            duration: 0,
          },
          instructionalVideo: {
            src: instructionalVideo.videoPath,
            file: null,
            duration: instructionalVideo.duration,
          },
          instructionalVideoThumbnail: {
            src: instructionalVideo.thumbnail,
            file: null,
            duration: 0,
          },
        });
      };
      fetch();
    }
  }, [initId]);

  const handleInputs = (e) => {
    e.persist();
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSelectors = (name, values) => {
    setForm((prev) => ({ ...prev, [name]: values }));
  };

  const handleKeyPointsChange = (e, id) => {
    e.persist();
    setForm((prev) => ({
      ...prev,
      keyPoints: prev.keyPoints.map((el) => {
        if (el.id === id) {
          return { value: e.target.value, id };
        } else {
          return el;
        }
      }),
    }));
  };
  const handleKeyPointsRemove = (id) =>
    setForm((prev) => ({
      ...prev,
      keyPoints: prev.keyPoints.filter((el) => el.id !== id),
    }));

  const handleKeyPointsAdd = () =>
    setForm((prev) => ({
      ...prev,
      keyPoints: [...prev.keyPoints, { value: "", id: v4() }],
    }));

  const handleUpload = (e) => {
    e.persist();
    setMedia((prev) => ({
      ...prev,
      [e.target.name]: {
        file: e.target.files[0],
        src: URL.createObjectURL(e.target.files[0]),
        duration: 0,
      },
    }));
  };

  const handleMetadata = (name, duration) => {
    if (!media[name].duration) {
      setMedia((prev) => ({
        ...prev,
        [name]: {
          ...prev[name],
          duration,
        },
      }));
    }
  };

  const handleConfirmSave = (status) => {
    if (initId) onUpdate(initId, { ...form, ...media, status });
    else onSave({ ...form, ...media, status });
    handleClose();
  };

  const handleSave = () => {
    setModal((prev) => ({ ...prev, confirm: { open: true } }));
  };

  const handleEquipmentRemove = (id) => {
    setForm((prev) => ({
      ...prev,
      equipment: prev.equipment.filter((el) => el !== id),
    }));
  };

  const handleEquipmentAdd = () => {
    setModal((prev) => ({
      ...prev,
      equipment: { open: true, init: form.equipment },
    }));
  };

  const handleClose = () => {
    onClose();
    setForm({
      title: "",
      author: "",
      drillRepeatCountMax: "",
      drillRepeatCountMin: "",
      repText: "",
      keyPoints: [{ value: "", id: v4() }],
      equipment: [],
    });
    setModal({
      equipment: { open: false, init: [] },
      exit: { open: false },
      confirm: { open: false },
    });
    setMedia({
      instructionalVideo: { src: "", file: null, duration: 0 },
      instructionalVideoThumbnail: { src: "", file: null, duration: 0 },
      repVideo: { src: "", file: null, duration: 0 },
      repAudio: { src: "", file: null, duration: 0 },
    });
    setInitTitle(null);
  };

  const checkValidSave = () => {
    const saveOptions = {
      private: false,
      published: false,
    };

    if (form.title !== "" && form.author !== "") {
      saveOptions.private = true;
    }

    if (
      form.title !== "" &&
      form.author !== "" &&
      form.drillRepeatCountMax !== "" &&
      form.drillRepeatCountMin !== "" &&
      form.repText !== "" &&
      form.keyPoints.length !== 0 &&
      form.equipment.length !== 0 &&
      media.instructionalVideo.src !== "" &&
      media.repVideo.src !== "" &&
      media.repAudio.src !== ""
    ) {
      saveOptions.published = true;
    }

    return saveOptions;
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() =>
        setModal((prev) => ({
          ...prev,
          exit: { open: true },
        }))
      }
    >
      <DialogContent style={{ background: "#f3f3f3", padding: "0" }}>
        <ModalContentHeader>
          <div>{initTitle ? initTitle : "Tips And Drills Video Title"}</div>
          <CloseIcon
            style={{ cursor: "pointer" }}
            onClick={() =>
              setModal((prev) => ({
                ...prev,
                exit: { open: true },
              }))
            }
          />
        </ModalContentHeader>
        <PracticeGuideDetailsContainer>
          <PracticeGuideFormColumn>
            <ModalContentTitle>Video Detail</ModalContentTitle>
            <TextField
              style={{ background: "#FFF" }}
              value={form.title}
              onChange={handleInputs}
              variant="outlined"
              name="title"
              label="Video Title"
            />
            <Pad height="20px" />
            <TextField
              style={{ background: "#FFF" }}
              value={form.author}
              onChange={handleInputs}
              variant="outlined"
              name="author"
              label="Author"
            />
            <Pad height="20px" />
            <FlexWrap width="100%" justify="space-between">
              <TextField
                type="number"
                InputProps={{ inputProps: { min: 0, max: 20 } }}
                style={{ background: "#FFF", width: "45%" }}
                value={form.drillRepeatCountMin}
                onChange={handleInputs}
                variant="outlined"
                name="drillRepeatCountMin"
                label="Minimum Drill Reps"
              />
              <TextField
                type="number"
                InputProps={{ inputProps: { min: 0, max: 20 } }}
                style={{ background: "#FFF", width: "45%" }}
                value={form.drillRepeatCountMax}
                onChange={handleInputs}
                variant="outlined"
                name="drillRepeatCountMax"
                label="Maximum Drill Reps"
              />
            </FlexWrap>
            <Pad height="20px" />
            <ModalContentTitle>Equipment needed</ModalContentTitle>
            {form.equipment.length === 0 ? (
              <EmptySelector
                onClick={() =>
                  setModal((prev) => ({
                    ...prev,
                    equipment: { open: true, init: [] },
                  }))
                }
              >
                Add Equipment
              </EmptySelector>
            ) : (
              <FilledEquipment
                onRemove={handleEquipmentRemove}
                onAdd={handleEquipmentAdd}
                items={form.equipment}
              />
            )}
            <Pad height="20px" />
            {!media.repAudio.src ? (
              <EmptyAudioSelector htmlFor="hidden-repAudio-upload">
                Upload Audio File
              </EmptyAudioSelector>
            ) : (
              <FilledAudioSelector>
                <audio
                  onLoadedMetadata={(e) =>
                    handleMetadata("repAudio", Math.floor(e.target.duration))
                  }
                  controls
                  src={media.repAudio.src}
                ></audio>
                <DeleteIcon
                  onClick={() => {
                    setMedia((prev) => ({
                      ...prev,
                      repAudio: {
                        src: "",
                        file: null,
                        duration: 0,
                      },
                    }));
                  }}
                />
              </FilledAudioSelector>
            )}
            <HiddenFileInput
              id="hidden-repAudio-upload"
              onChange={handleUpload}
              onClick={(e) => (e.target.value = null)}
              name="repAudio"
              accept="audio/*"
              type="file"
            />
            <Pad height="20px" />
            <TextField
              style={{ background: "#FFF" }}
              value={form.repText}
              onChange={handleInputs}
              variant="outlined"
              name="repText"
              label="Textual rep definition"
              multiline
              rows={4}
            />
            <Pad height="20px" />
            <ModalContentTitle>Textual Key Points</ModalContentTitle>
            <KeyPoints
              form={form.keyPoints}
              onChange={handleKeyPointsChange}
              onAdd={handleKeyPointsAdd}
              onRemove={handleKeyPointsRemove}
            />
          </PracticeGuideFormColumn>
          <PracticeGuideMediaColumn>
            <TipsAndDrillsMediaUpload
              onMetadata={handleMetadata}
              uploadFn={handleUpload}
              media={media}
            />
          </PracticeGuideMediaColumn>
        </PracticeGuideDetailsContainer>
        <ModalContentFooter>
          <StatusBadge status="draft">draft</StatusBadge>
          <OutlinedButton onClick={handleSave} color="#580CE3">
            save
          </OutlinedButton>
        </ModalContentFooter>
        <ExitDetailsModal
          open={modal.exit.open}
          onClose={() =>
            setModal((prev) => ({ ...prev, exit: { open: false } }))
          }
          onConfirm={handleClose}
        />
        <EquipmentModal
          open={modal.equipment.open}
          init={modal.equipment.init}
          onClose={() =>
            setModal((prev) => ({
              ...prev,
              equipment: { open: false, init: [] },
            }))
          }
          onSave={handleSelectors}
        />
        <ConfirmSaveModal
          open={modal.confirm.open}
          onClose={() =>
            setModal((prev) => ({
              ...prev,
              confirm: { open: false },
            }))
          }
          onSave={handleConfirmSave}
          validation={checkValidSave}
        />
      </DialogContent>
    </Dialog>
  );
}
