import React, { useState } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Grid, Button, Container, Avatar, TextField, Link, Typography,
  FormControlLabel, CssBaseline, Checkbox, InputAdornment, IconButton
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Google as GoogleIcon } from '../../icons';
import MuiAlert from '@material-ui/lab/Alert';
import { getCookie, setCookie } from '../../helpers/cookie';
import firebase from '../../common/firebase';
import { functionBaseUrl, loginWithGoogle, loginWithFacebook, loginWithApple, axiosWithToken } from '../../common/firebase'
import axios from 'axios';
import Swal from 'sweetalert2';
import AppleIcon from '@material-ui/icons/Apple';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  otherSignIn: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const SignIn = (props) => {
  const { history } = props;

  const classes = useStyles();

  const [values, setValues] = useState({
    email: getCookie('sportsbox-email'),
    password: '',
    error: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

 

  const handleSignIn = async (event) => {
    Swal.fire({
      title: 'Processing...',
      html: '<div><img width="10%" src="images/loading.gif" alt="Loading" /></div>',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      customClass : {
        container : 'my-swal' 
      },
    })
    event.preventDefault();
    const data = new FormData(event.target);
    if (data.get('remember') === 'remember') {
      setCookie('sportsbox-email', data.get('email'));
    } else {
      setCookie('sportsbox-email', '');
    }
    await axios(functionBaseUrl + '/api/userInAuth', {
      method: 'POST',
      data: {
        email: data.get('email').toLowerCase().replace(/ /g, '')
      }
    })
      .then(async response => {
        if (response.status !== 200) {
          await Swal.fire({
            title: '<p style="font-size:70%;">User does not exist for this account, would you like to sign up?</p>',
            icon: 'warning',
            confirmButtonText: 'Yes',
            showCancelButton: true,
            cancelButtonText: 'No',
            cancelButtonColor: 'red',
            allowOutsideClick: false,
            customClass : { 
              container : 'my-swal' 
            },
          })
            .then((result) => {
              if (result.isConfirmed) {
                history.push('/sign-up');
                Swal.close();
              } else {
                Swal.close();
                return
              }
            })
          return;
        }
        await firebase.auth().signInWithEmailAndPassword(data.get('email').toLowerCase().replace(/ /g, ''), data.get('password'))
        .then((creds) => {
          checkIfUserExists(creds);
          localStorage.setItem('uid', creds.user.uid);
          Swal.close();
        })
        .catch((error) => {
          console.log(error);
          setValues({
            ...values,
            error: error.message,
          });
        });
      })
      .catch(async err => {
        console.log(err)
        await Swal.fire({
          title: '<p style="font-size:70%;">User does not exist for this account, would you like to sign up?</p>',
          icon: 'warning',
          confirmButtonText: 'Yes',
          showCancelButton: true,
          cancelButtonText: 'No',
          cancelButtonColor: 'red',
          allowOutsideClick: false,
          customClass : { 
            container : 'my-swal' 
          },
        })
          .then((result) => {
            if (result.isConfirmed) {
              history.push('/sign-up');
              Swal.close();
            } else {
              Swal.close();
              return
            }
          })
        return;
      });
    Swal.close();
  };

  const handleGoogleSignIn = () => {
    loginWithGoogle()
      .then((creds) => {
        checkIfUserExists(creds);
      })
      .catch((error) => {
        console.log(error);
        setValues({
          ...values,
          error: error.message,
        });
      });
  }
  const handleFacebookSignIn = () => {
    loginWithFacebook()
      .then((creds) => {
        checkIfUserExists(creds);
      })
      .catch((error) => {
        console.log(error);
        setValues({
          ...values,
          error: error.message,
        });
      });
  }
  const handleAppleSignIn = () => {
    loginWithApple()
      .then((creds) => {
        checkIfUserExists(creds);
      })
      .catch((error) => {
        console.log(error);
        setValues({
          ...values,
          error: error.message,
        });
      });
  }
  const checkIfUserExists = async (creds) => {
    await axiosWithToken(functionBaseUrl + '/api/users/' + creds.user.uid, {
      method: 'get',
    }).then(response => {
      if (window.location.href.includes('redirectTo=')) {
        const redirectTo = window.location.href.split("redirectTo=")[1].substring(0);
        history.push(redirectTo);
      } else {
        history.push('/dashboard');
      }
    }).catch(async error => {
      await Swal.fire({
        title: '<p style="font-size:70%;">User does not exist for this account, would you like to sign up?</p>',
        icon: 'warning',
        confirmButtonText: 'Yes',
        showCancelButton: true,
        cancelButtonText: 'No',
        cancelButtonColor: 'red',
        allowOutsideClick: false,
        customClass : { 
          container : 'my-swal' 
        },
      })
        .then((result) => {
          if (result.isConfirmed) {
            history.push('/sign-up');
            Swal.close();
          } else {
            Swal.close();
            return
          }
        })
      return;
    })
  }
  const checkSignUp = () => {
    if (localStorage.getItem('login') === 'google') {
      handleGoogleSignIn()
    }
    if (localStorage.getItem('login') === 'facebook') {
      handleFacebookSignIn()
    }
  }
  return (
    <Container
      component="main"
      maxWidth="xs"
      className={classes.paper}
    >
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography
          component="h1"
          variant="h5"
        >
          Sign in
        </Typography>
        <Button
          className={classes.submit}
          color="secondary"
          fullWidth
          variant="contained"
          onClick={() => window.location.href = "/sign-up"}
        >
          First time here? Create an account
        </Button>
        <form
          className={classes.form}
          onSubmit={handleSignIn}
        >
          <TextField
            autoComplete="email"
            autoFocus
            fullWidth
            label="Email Address"
            margin="normal"
            name="email"
            onChange={handleChange}
            required
            value={values.email}
            variant="outlined"
          />
          <TextField
            autoComplete="current-password"
            fullWidth
            id="password"
            label="Password"
            margin="normal"
            name="password"
            onChange={handleChange}
            required
            type={showPassword ? "text" : "password"}
            value={values.password}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <Grid container>
            <Grid item>
              <Link
                component={RouterLink}
                to="/forgot-password"
                variant="body2"
              >
                {'Forgot password?'}
              </Link>
            </Grid>
          </Grid>
          <FormControlLabel
            control={
              <Checkbox
                name="remember"
                color="primary"
                value="remember"
              />
            }
            label="Remember me"
          />
          {values.error ? <Alert severity="error">Error: {values.error}</Alert> : ""}
          <Button
            //className={classes.submit}
            color="primary"
            fullWidth
            type="submit"
            variant="contained"
          >
            Sign In
          </Button>
          <div className={classes.otherSignIn}>
            <Grid>
              <Typography
                style={{ padding: 20 }}
                align={'center'}>
                Or
              </Typography>
            </Grid>
            <Grid container >
              <Grid
                item
                xs={12}
              >
                <Button
                  size="large"
                  variant="contained"
                  onClick={handleAppleSignIn}
                  fullWidth
                >
                  <AppleIcon className={classes.socialIcon} />
                    Apple
                </Button>
              </Grid>
              <Grid item xs={12}>
                <br />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Button
                  size="large"
                  variant="contained"
                  onClick={handleGoogleSignIn}
                  fullWidth
                >
                  <GoogleIcon className={classes.socialIcon} />
                  Google
                </Button>
              </Grid>
            </Grid>
          </div>
        </form>
      </div>
    </Container>
  );
};

SignIn.propTypes = {
  history: PropTypes.object,
};

export default withRouter(SignIn);
