import React, { Component } from 'react';
import { Grid, Link, Button, IconButton, Container, Card, CardContent, Typography } from '@material-ui/core';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import InputMask from 'react-input-mask';
import firebase, { axiosWithToken, functionBaseUrl } from '../../../common/firebase'
import Swal from 'sweetalert2';
import { StripeSubscription } from '../../../components/stripeSubscription';
import moment from 'moment';

class PlanPay extends Component {
    constructor(props){
        super(props)
        this.state = {
            paymentInterval: 'annually',
            stage: 'interval',
            firstName: props.userInfo?.firstName || '',
            lastName: props.userInfo?.lastName || '',
            cardNumber: '',
            expirationDate: '',
            securityCode: '',
            streetName: '',
            zip: ''
        }
    }

    getTableData = () => {
        const data = []
        const { plan } = this.props
        data.push(['Plan', plan.label])
        data.push(['Amount', `$${plan[this.state.paymentInterval]}`])
        data.push(['Billed', this.state.paymentInterval.toUpperCase()])
        data.push(['Payment Date', new Date().toLocaleDateString('en-us')])
        return data
    }

    createSubscription = async (data) => {
        let endDate = new Date()
        const plan = this.props.plan.getProdId(this.state.paymentInterval)
        if (plan.includes('enterprise') || this.state.paymentInterval === 'annually') {
            endDate.setYear(endDate.getFullYear()+1)
        } else {
            endDate.setMonth(endDate.getMonth()+1)
        }
        await axiosWithToken(functionBaseUrl + '/api/subscription', {
            method: 'POST',
            data: {
                userId: this.props.userInfo.uid,
                plan: plan,
                planDescription: '',
                planType: this.state.paymentInterval,
                transactionId: data.id, // From Stripe
                payload: '',
                packageId: '',
                orderId: '',
                amount: data.amount / 100, // From Stripe
                startDate: String(Date.now()),
                endDate: String(endDate),
                lastUpdated: String(Date.now()),
                autoRenewal: true,
                platform: 'Stripe',
                productId: this.props.plan.getProdId(this.state.paymentInterval),
            }
        })
        .then(async () => {
            this.props.handleDone()
        })
        .catch(err => {
            console.log(err.response?.data || err);
        })
    }

    render() {
        const perMonth = (this.props.plan[this.state.paymentInterval]/(this.state.paymentInterval === 'monthly' ? 1 : 12)).toFixed(2)
        return (
            <Container maxWidth='xs' style={{display: 'flex', alignContent: 'center', flexDirection: 'column'}}>
                { this.state.stage === 'interval' && (
                    <Card style={{ width: "100%" }}>
                        <CardContent>
                            <Grid
                                item
                                xs={12}
                                style={{ paddingBottom: 20, textAlign: 'center' }}
                                >
                                <Typography variant='h4' style={{marginBottom: 10}}><b>{this.props.plan.label.toUpperCase()}</b></Typography>
                                <ToggleButtonGroup
                                    value={this.state.paymentInterval}
                                    exclusive
                                    onChange={(e, value) => value ? this.setState({ paymentInterval: value }) : this.setState({ paymentInterval: this.state.paymentInterval })}
                                >
                                    <ToggleButton value="monthly" aria-label="Monthly">
                                    Monthly
                                    </ToggleButton>
                                    <ToggleButton value="annually" aria-label="Annually">
                                    Annually (Save 17%)
                                    </ToggleButton>
                                </ToggleButtonGroup>
                                {this.state.paymentInterval !== null && (
                                    <div>
                                        <Typography style={{marginTop: 30}}>
                                        <span style={{ fontSize: '34px' }}>${perMonth}</span> / MONTH
                                        </Typography>
                                        <div style={{display: this.state.paymentInterval === 'annually' ? 'flex' : 'none', 
                                                    justifyContent: (this.props.plan.monthly * 12 - this.props.plan.annually) > 0 ? 'space-between' : 'center', 
                                                    marginLeft: 55, 
                                                    marginRight: 55, 
                                                    marginTop: 20,
                                                    fontSize: '14px'}}>
                                            <p>${this.props.plan.annually.toFixed(2)} BILLED ANNUALLY</p>
                                            <b style={{display: (this.props.plan.monthly * 12 - this.props.plan.annually) > 0 ? '' : 'none'}}>Save ${(this.props.plan.monthly * 12 - this.props.plan.annually).toFixed(2)}</b>
                                        </div>
                                    </div>
                                )}
                            </Grid>
                        <Button
                            color="primary"
                            fullWidth
                            variant="contained"
                            disabled={this.state.paymentInterval === null || this.state.paymentInterval === undefined}
                            onClick={() => this.setState({ stage: 'card' })}
                            >
                            Continue to Payment
                        </Button>
                    </CardContent>
                    </Card>
                )}
                { this.state.stage === 'card' && (
                    <Grid container>
                        <Grid item xs={12}>
                            <IconButton onClick={() => this.setState({ stage: 'interval' })}>
                                <ArrowBackIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs={12}>
                            {window.localStorage.getItem('freeTrial') === 'true' && (this.props.plan.getProdId(this.state.paymentInterval).includes('pro_lite') || this.props.plan.getProdId(this.state.paymentInterval).includes('premium')) && (
                                <b>You will not be charged until your free trial ends on {`${moment().add(2, 'weeks').format('MM/DD/YYYY')}`}.</b>
                            )}
                            <StripeSubscription 
                                email={this.props.userInfo.email} 
                                productId={this.props.plan.getProdId(this.state.paymentInterval)} 
                                handleResult={this.createSubscription}
                                freeTrial={window.localStorage.getItem('freeTrial') === 'true' && (this.props.plan.getProdId(this.state.paymentInterval).includes('pro_lite') || this.props.plan.getProdId(this.state.paymentInterval).includes('premium')) ? 14 : 0}
                                fullName={`${this.state.firstName} ${this.state.lastName}`}
                            />
                        </Grid>
                    </Grid>
                )}
                {/* {this.state.stage === 'card' && (
                    <form onSubmit={this.handlePay}>
                        <Grid container spacing={1}>
                            
                            <Grid item xs={12} style={{display: 'flex', paddingBottom: 20}}>
                                <IconButton
                                    edge="start"
                                    onClick={()=>this.setState({ stage: 'interval' })}
                                    >
                                    <ArrowBackIcon />
                                </IconButton>
                            </Grid>
                            <Grid item xs={12} style={{ marginBottom: 20 }}>
                                <Box border={1} borderColor="primary.main">
                                    <table style={{width: '100%', padding: 20}}>
                                        <tbody>
                                        {this.getTableData().map((row, index) => (
                                                <tr key={index}>
                                                    <td style={{textAlign: 'left', width: 110}}>
                                                        <b>{row[0]}</b>
                                                    </td>
                                                    <td style={{textAlign: 'left'}}>:</td>
                                                    <td style={{textAlign: 'right'}}>
                                                        {row[1]}
                                                    </td>
                                                </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </Box>
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                >
                                <TextField
                                    fullWidth
                                    required
                                    label="First Name"
                                    value={(this.state.firstName || '')}
                                    onChange={(e) => this.setState({ firstName: e.target.value })}
                                    variant="outlined"
                                    inputProps={{ maxLength: 30 }}
                                />
                            </Grid>

                            <Grid
                                item
                                xs={6}
                                >
                                <TextField
                                    fullWidth
                                    required
                                    label="Last Name"
                                    value={this.state.lastName || ''}
                                    onChange={(e) => this.setState({ lastName: e.target.value })}
                                    variant="outlined"
                                    inputProps={{ maxLength: 30 }}
                                />
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                >
                                <InputMask
                                    mask="9999 9999 9999 9999"
                                    label='Card Number'
                                    type="text"
                                    value={this.state.cardNumber || ''}
                                    onChange={(e) => this.setState({ cardNumber: e.target.value })}
                                >
                                    {()=><TextField variant="outlined" required fullWidth label='Card Number' />}
                                </InputMask>
                            </Grid>

                            <Grid
                                item
                                xs={6}
                                >
                                <InputMask
                                    mask="99/99"
                                    fullWidth
                                    value={this.state.expirationDate || ''}
                                    onChange={(e) => this.setState({ expirationDate: e.target.value })}
                                    type="text"
                                    inputProps={{ maxLength: 5 }}
                                >
                                    {() => <TextField fullWidth required variant="outlined" label="Expiration Date" />}
                                </InputMask>
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                >
                                <TextField
                                    fullWidth
                                    required
                                    label="Security Code (CVV)"
                                    value={this.state.securityCode}
                                    onChange={(e) => this.setState({ securityCode: e.target.value })}
                                    variant="outlined"
                                    inputProps={{ maxLength: 3 }}
                                />
                            </Grid>

                            <Grid
                                item
                                xs={6}
                                >
                                <TextField
                                    fullWidth
                                    required
                                    label="Street Name"
                                    value={this.state.streetName}
                                    onChange={(e) => this.setState({ streetName: e.target.value })}
                                    variant="outlined"
                                />
                                </Grid>
                            <Grid
                                item
                                xs={6}
                                >
                                <TextField
                                    fullWidth
                                    required
                                    label="Zip Code"
                                    value={this.state.zip}
                                    onChange={(e) => this.setState({ zip: e.target.value })}
                                    variant="outlined"
                            />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    className={this.props.classes.submit}
                                    color="primary"
                                    fullWidth
                                    required
                                    type="submit"
                                    variant="contained"
                                    >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </form>)
                } */}
                <Link
                component={RouterLink}
                to="/sign-in"
                variant="body2"
                style={{textAlign: 'center'}}
                >
                {'Already have an account? Sign in'}
                </Link>
            </Container>)
        }
}

export default withRouter(PlanPay);
