import React, { useState } from 'react';

import { Button, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, Grid, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Swal from 'sweetalert2';

import firebase from '../../../../common/firebase';


function SubscriptionLabelComponent({ open, subscriptions, close }) {
    const [tag, setTag] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (event) => {
        setTag(event.target.value);
    }

    const handleUpdate = async () => {
        try {
            setIsLoading(true);
            for (const sub in subscriptions) {
                await firebase.firestore().collection("subscriptions").doc(sub).update({
                    subLabel: tag,
                })
            }
            setTag('');
            setIsLoading(false);
            Swal.fire({
                title: '<p style="font-size:70%;">Saved successfully</p>',
                icon: 'success',
                confirmButtonText: 'Ok',
                allowOutsideClick: false,
                timer: 2000
            });
            close();
        } catch (e) {
            setIsLoading(false);
            Swal.fire('<p style="font-size:70%;">Error occured</p>');
        }
    }

    return (<>
        <Dialog
            fullWidth
            open={open}
            onClose={close}
            aria-labelledby='form-dialog-title'>
            <DialogTitle id={'form-dialog-title'}>
                <div>
                    Add Label
                    <CloseIcon style={{ float: 'right', marginTop: '-10px', marginRight: '-15px' }} onClick={close} />
                </div>
            </DialogTitle>
            <DialogContent>
                <Grid container component="main">
                    <Grid container spacing={2}>
                        <Grid
                            item
                            xs={12}
                        >
                            {!isLoading && (
                                <FormControl
                                    variant="outlined"
                                    fullWidth
                                >
                                    <InputLabel id="subPlan-select">Label*</InputLabel>
                                    <Select
                                        id="tag"
                                        label="Tag"
                                        labelId="tag-select"
                                        name="tag"
                                        defaultValue={tag}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={''}>None</MenuItem>
                                        <MenuItem value={'test'}>Test</MenuItem>
                                        <MenuItem value={'demo'}>Demo</MenuItem>
                                        <MenuItem value={'partner'}>Partner</MenuItem>
                                        <MenuItem value={'revenue_generating'}>Revenue Generating</MenuItem>
                                        <MenuItem value={'staff'}>Staff</MenuItem>
                                        <MenuItem value={'free_trial'}>Free Trial</MenuItem>
                                        <MenuItem value={'bootcamp'}>Bootcamp</MenuItem>
                                        <MenuItem value={'invitational'}>Invitational Page</MenuItem>
                                        <MenuItem value={'offer'}>Offer Page</MenuItem>
                                    </Select>
                                </FormControl>
                            )}
                            {isLoading && (
                                <CircularProgress />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={close} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleUpdate} color="secondary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    </>);
}

export const SubscriptionLabel = SubscriptionLabelComponent;
