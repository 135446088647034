import React from "react";
import Select from "react-select";

export function CustomSelect({ label, width, ...props }) {
  const studentsSelectStyles = {
    container: (styles) => ({ ...styles, width: "100%" }),
  };
  return (
    <div style={{ width: width || "100%", marginTop: "10px" }}>
      <div style={{ marginLeft: "10px", marginBottom: "2px" }}>{label}</div>
      <Select
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: "#580CE3",
            primary75: "#B591F9",
            primary50: "#C2A3FA",
            primary25: "#F3EDFE",
          },
        })}
        styles={studentsSelectStyles}
        {...props}
      />
    </div>
  );
}

export function MenuItemWithLabel({ label, children, ...props }) {
  return (
    <div style={{ width: "100%", marginTop: "10px" }}>
      <div
        style={{ marginLeft: "10px", marginBottom: "10px", fontWeight: "bold" }}
      >
        {label}
      </div>
      {children}
    </div>
  );
}
