import React, { forwardRef, useEffect, useState } from 'react';

import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
  Receipt,
} from '@material-ui/icons';
import { Button, Grid, CircularProgress, Container } from '@material-ui/core';
import MaterialTable from 'material-table';
import Swal from 'sweetalert2';
import moment from 'moment';

import { functionBaseUrl, axiosWithToken } from '../../common/firebase';
import { AddBootcampAccount } from './AddBootcampAccount';
import { ImportBootcampAccounts } from './ImportBootcampAccounts';

const tableHeader = [
  { field: 'userEmail', title: 'Email Address' },
  { field: 'userName', title: 'Name' },
  { field: 'productId', title: 'Plan', render: rowData => {
    return getPlan(rowData.productId)
  }},
  { field: 'amount', title: 'Amount' },
  { field: 'transactionId', title: 'Payment Completed', render: rowData => {
    return rowData.transactionId !== '' ? 'Yes' : 'No'
  }},
  { field: 'autoRenewal', title: 'Auto Renewal' },
  { field: 'startDate', title: 'Start Date', defaultSort: 'desc', render: rowData => {
    return moment(String(rowData.startDate), "x").format("MM/DD/YYYY")
  }},
  { field: 'endDate', title: 'End Date', render: rowData => {
    return moment(String(rowData.endDate), "x").format("MM/DD/YYYY")
  }},
  { field: 'salesRep', title: 'Sales Rep' },
];

function getPlan(productId) {
  let plan = '';
  if (productId?.includes('student_lite')) {
    plan = '3D Practice';
  } else if (productId?.includes('pro_lite')) {
    plan = 'Pro Lite';
  } else if (productId?.includes('premium')) {
    plan = '3D Pro';
  } else if (productId?.includes('enterprise_master')) {
    plan = 'Enterprise Master';
  } else if (productId?.includes('enterprise_coach')) {
    plan = 'Enterprise Coach';
  } else {
    plan = 'Free';
  }

  if (productId?.includes('monthly')) {
    plan += ' Monthly';
  }
  if (productId?.includes('annually')) {
    plan += ' Annually';
  }

  return plan;
}

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  Receipt: forwardRef((props, ref) => <Receipt {...props} ref={ref} />),
};

function BootcampAccountListComponent() {
  const [accounts, setAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddPopupOpened, setIsAddPopupOpened] = useState(false);
  const [isImportPopupOpened, setIsImportPopupOpened] = useState(false);

  useEffect(() => {
    loadData();
  }, [])

  const loadData = async () => {
    try {
      setIsLoading(true);
      const response = await axiosWithToken(`${functionBaseUrl}/api/usersBootcamp`, {
        method: 'get',
      });
      setAccounts(response.data.userInfo);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      Swal.fire('<p style="font-size:70%;">Error occured</p>');
    }
  }

  const openCreateAccount = () => {
    setIsAddPopupOpened(true);
  }

  const closeCreateAccount = () => {
    setIsAddPopupOpened(false);
    loadData();
  }

  const openImportAccount = () => {
    setIsImportPopupOpened(true);
  }

  const closeImportAccount = () => {
    setIsImportPopupOpened(false);
    loadData();
  }

  return (<>
    <Grid item xs={12} style={{ textAlign: 'right', paddingTop: '2rem' }}>
      <Button
        variant="contained"
        color="primary"
        onClick={openCreateAccount}
      >
        CREATE NEW ACCOUNT
      </Button>
      <Button
        variant="contained"
        color="secondary"
        onClick={openImportAccount}
        style={{ marginLeft: '2rem' }}
      >
        IMPORT ACCOUNTS
      </Button>
    </Grid>
    <Grid item xs={12}>
      <h3>Bootcamp Accounts</h3>
      {!isLoading ? (
        <MaterialTable
          style={{ marginTop: '10px', marginBottom: '10px' }}
          icons={tableIcons}
          columns={tableHeader}
          title=""
          data={accounts}
          options={{
            selection: false,
            sorting: true,
            search: true,
            paging: true,
            exportAllData: true,
            /*exportButton: true,
            rowStyle: x => {
              console.log(x)
              if (x.tableData.id % 2 === 0) {
                return { backgroundColor: '#f2f2f2' }
              }
            }*/
          }}
        />
      ) :
        <Container style={{ textAlign: 'center', paddingTop: '50px' }}>
          <CircularProgress />
        </Container>
      }
      <AddBootcampAccount
        open={isAddPopupOpened}
        close={closeCreateAccount}
      />
      <ImportBootcampAccounts
        open={isImportPopupOpened}
        close={closeImportAccount}
      />
    </Grid>
  </>);
}

export const BootcampAccountList = BootcampAccountListComponent;