import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid, TextField, InputAdornment, FormControlLabel, Checkbox } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Swal from 'sweetalert2';
import { functionBaseUrl, axiosWithToken } from '../../common/firebase';

function AddEnterpriseAccountComponent({ open, close }) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [dob, setDOB] = useState('');
  const [coachesNumber, setCoachesNumber] = useState('');
  const [annualPricing, setAnnualPricing] = useState('');
  const [autoRenewal, setAutorenewal] = useState(false);
  const [facilityName, setFacilityName] = useState('');
  const [facilityAddress, setFacilityAddress] = useState('');
  const [salesRep, setSalesRep] = useState('');

  const handleFirstName = (e) => {
    setFirstName(e.target.value);
  }
  const handleLastName = (e) => {
    setLastName(e.target.value);
  }
  const handleEmail = (e) => {
    setEmail(e.target.value);
  }
  const handleDOB = (e) => {
    setDOB(e.target.value);
  }
  const handleCoachesNumber = (e) => {
    setCoachesNumber(e.target.value);
  }
  const handleAnnualPricing = (e) => {
    setAnnualPricing(e.target.value);
  }
  const handleAutoRenewal = (e) => {
    setAutorenewal(e.target.checked);
    if (e.target.checked) {
      setCoachesNumber(1)
    }
  }
  const handleFacilityName = (e) => {
    setFacilityName(e.target.value);
  }
  const handleFacilityAddress = (e) => {
    setFacilityAddress(e.target.value);
  }
  const handleSalesRep = (e) => {
    setSalesRep(e.target.value);
  }
  const clearState = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setDOB('');
    setCoachesNumber('');
    setAnnualPricing('');
    setAutorenewal(true);
    setFacilityName('');
    setFacilityAddress('');
    setSalesRep('');
  }
  
  const dobFormat = (dob) => {
    let [year, month, day] = dob.split('-');
    return (month + "/" + day + "/" + year)
  }

  const validEmail = (email) => {
    const regex = /\S+@\S+\.\S+/;
    return regex.test(email);
  }

  const handleCreate = async () => {
    console.log(coachesNumber)
    if (!firstName || !lastName || !email || !dob || !coachesNumber || !annualPricing) {
      close();
      Swal.fire('<p style="font-size:70%;">Please fill in all required fields</p>');
      return;
    }
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const date = new Date(new Date().setFullYear(new Date().getFullYear() - 13)).toLocaleDateString('en-ZA', options).replaceAll('/', '-');
    if (Date.parse(dob) > Date.parse(date)) {
      close();
      Swal.fire({
        title: '<p style="font-size:70%;">Sorry, this user is not eligible for a Sportsbox.ai account at this time.</p>',
        confirmButtonText: 'Ok',
        icon: 'warning',
        allowOutsideClick: false,
        customClass : { 
          container : 'my-swal' 
        },
      })
        .then(() => {
          window.location.reload();
        })
      return false;
    }
    if (!validEmail(email)) {
      close();
      Swal.fire('<p style="font-size:70%;">Please enter a valid Email</p>');
      return;
    }
    if (coachesNumber === '' || coachesNumber < 1) {
      close();
      Swal.fire('<p style="font-size:70%;">Coaches Number has to greater than 0</p>');
      return;
    }
    
    await axiosWithToken(`${functionBaseUrl}/api/usersEnterprise`, {
      method: 'post',
      data: {
        firstName: firstName,
        lastName: lastName,
        email: email.toLowerCase().replace(/ /g, ''),
        dob: dobFormat(dob),
        coachesCount: autoRenewal ? 1 : coachesNumber,
        autoRenewal: !autoRenewal,
        annualPrice: annualPricing,
        facilityName: facilityName,
        facilityAddress: facilityAddress,
        salesRep: salesRep
      }
    })
    .then((response) => {
      close();
      if (response.status === 200) {
        clearState();
        Swal.fire({
          title: '<p style="font-size:70%;">Account successfully created for ' + email + ' and the password is ' + response.data.password + '</p>',
          icon: 'success',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          customClass : { 
            container : 'my-swal' 
          },
        });
      } else {
        if (response.data.message.includes('upgraded')) {
          clearState();
        }
        Swal.fire({
          title: '<p style="font-size:70%;">' + response.data.message + '</p>',
          icon: 'warning',
          confirmButtonText: 'Ok',
          allowOutsideClick: true,
          customClass : { 
            container : 'my-swal' 
          },
        })
      }
      return false;
    })
    .catch(() => {
      console.log("Error creating enterprise account");
    })
  }

  return (<>
    <Dialog
      fullWidth
      open={open}
      onClose={close}
      aria-labelledby='form-dialog-title'>
      <DialogTitle id={'form-dialog-title'}>
        <div>
          New enterprise account
          <CloseIcon style={{ float: 'right', marginTop: '-10px', marginRight: '-15px' }} onClick={close} />
        </div>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            sm={6}
            xs={12}
          >
            <TextField
              autoComplete="firstname"
              autoFocus
              fullWidth
              required
              label="First Name"
              onChange={handleFirstName}
              value={firstName}
              variant="outlined"
              inputProps={{ maxLength: 254 }}
            />
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
          >
            <TextField
              autoComplete="lastName"
              fullWidth
              required
              label="Last Name"
              onChange={handleLastName}
              value={lastName}
              variant="outlined"
              inputProps={{ maxLength: 254 }}
            />
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
          >
            <TextField
              autoComplete="email"
              fullWidth
              required
              label="Email Address"
              onChange={handleEmail}
              value={email}
              variant="outlined"
              inputProps={{ maxLength: 254 }}
            />
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
          >
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              required
              fullWidth
              variant="outlined"
              id="date"
              label="Date of Birth"
              onChange={handleDOB}
              type="date"
              value={dob}
            />
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
          >
            <TextField
              fullWidth
              required
              label="Number of Coaches"
              onChange={handleCoachesNumber}
              value={autoRenewal ? 1 : coachesNumber}
              variant="outlined"
              type="Number"
              InputProps={{
                inputProps: { min: 0 }
              }}
            />
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
          >
            <TextField
              autoComplete="salesRep"
              fullWidth
              label="Sales Representative Email"
              onChange={handleSalesRep}
              value={salesRep}
              variant="outlined"
              inputProps={{ maxLength: 254 }}
            />
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
          >
            <TextField
              fullWidth
              required
              label="Annual Pricing"
              onChange={handleAnnualPricing}
              value={annualPricing}
              type="Number"
              variant="outlined"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                inputProps: { min: 0 }
              }}
            />
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
          >
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={autoRenewal}
                  onChange={handleAutoRenewal}
                />
              }
              label="Staff Enterprise"
            />
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
          >
            <TextField
              autoComplete="facilityName"
              fullWidth
              required
              label="Facility Name"
              onChange={handleFacilityName}
              value={facilityName}
              variant="outlined"
              inputProps={{ maxLength: 254 }}
            />
          </Grid>
          <Grid
            item
            //sm={6}
            xs={12}
          >
            <TextField
              autoComplete="facilityAddress"
              fullWidth
              required
              label="Facility Address"
              onChange={handleFacilityAddress}
              value={facilityAddress}
              variant="outlined"
              inputProps={{ maxLength: 254 }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreate}
        >
          CREATE
        </Button>
      </DialogActions>
    </Dialog>
  </>);
}

export const AddEnterpriseAccount = AddEnterpriseAccountComponent;
