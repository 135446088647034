import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import { Typography, Divider, Grid, Dialog, DialogTitle, TableContainer, TableRow, TableCell, TableBody, Table } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight:710,
    minWidth:400
  },
}));

const DisplayLabReqItems = (props) => {
  const classes = useStyles();
  
  const fieldNames = {
    labelingRequests: [['requestID', 'Request ID'], ['name', 'Name'], ['purpose', 'Purpose'], ['videosType', 'Videos Type'], 
    ['createdDate', 'Date Created'], ['numberOfFrames', 'Number of Frames'], ['instructions', 'Instructions'], 
    ['numberOfPoints', 'Number of Points'], ['descriptionOfVideos', 'Description of Videos'], ['locationOfVideos', 'Location of Videos'],
    ['descriptionOfFrames', 'Description of Frames'], ['requester', 'Requester Name'], 
    ['assignee', 'Assignee'], ['desiredDate', 'Desired Date'], ['completionDate', 'Completion Date'], ['status', 'Status']]
  }

  const createFieldTable = (fields, values) => {
    return (
      <TableContainer>
        <Table size='small'>
          <TableBody>
            {fields.map((field, index) => (
            <TableRow key={index}>
              <TableCell style={{width: '35%', verticalAlign: 'top'}}>{field[1]}</TableCell>
              <TableCell style={{width: '65%'}}>{(values ? values[field[0]] : props.rowData[field[0]]) || ''}</TableCell>
            </TableRow>))}
          </TableBody>
        </Table>
      </TableContainer>)
  }

  let content = null
  if(props.open) {
    if (props.type === 'labelingRequests') {
      content = (<div style={{display: 'flex'}}>
        <Grid container style={{width: '80%', minWidth: '400px'}}>
          <Grid item xs={12}>
            <Typography
              variant='h6'>
              {props.rowData.requestID}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              color='textSecondary'
              variant='subtitle2'>
              {props.rowData.createdDate}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider style={{width: '60%'}}/>
          </Grid>
          <Grid item xs={12}>
            {createFieldTable(fieldNames.labelingRequests)}
          </Grid>
        </Grid>
      </div>)
    } 
  }
  return props.open ? (
    <Dialog
      fullWidth
      open={props.open}
      onClose={props.onClose}
      aria-labelledby='form-dialog-title'>
      <DialogTitle>
      <CloseIcon style={{float: 'right', marginTop: '-10px', marginRight: '-15px'}} onClick={props.onClose}/>
        {content}
      </DialogTitle>
    </Dialog>
  ) : null
};

DisplayLabReqItems.propTypes = {
  item: PropTypes.object
};

export default DisplayLabReqItems;
