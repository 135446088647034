import React from "react";

import { Grid, Button } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";

import { getPlanPrice } from "../../common/plans";

function PlansSelectionComponent({ plansType, period, getStarted, subData }) {
  return (
    <>
      <Grid container>
        {plansType == "coaches" && (
          <>
            <Grid item xs={12} md={2} lg={3} />
            {/*<Grid item xs={12} md={4} lg={3}>
              <div className="plan-card plan-card-boot">
                <div className="plan-card-caption">
                  Boot Camp
                  <span>Best Deal</span>
                </div>
                <div className="plan-card-price">
                  <span>$400</span> Full access
                </div>
                <div className="plan-card-description">
                  Become an expert in coaching with 3D.
                </div>
                <div className="plan-card-button-unified-container">
                  <Button
                    onClick={() =>
                      getStarted(
                        "development_sportsbox_pro_premium_bootcamp",
                        false
                      )
                    }
                    className="plan-button"
                  >
                    Buy Now
                  </Button>
                </div>
                <div className="plan-card-features-caption">FEATURES</div>
                <ul className="plan-card-features">
                  <li>
                    <CheckIcon className="plan-card-feature-mark" /> 5 weekly
                    interactive education sessions
                  </li>
                  <li>
                    <CheckIcon className="plan-card-feature-mark" /> Sportsbox
                    3DGolf Level 1 certification ($200 value)
                  </li>
                  <li>
                    <CheckIcon className="plan-card-feature-mark" /> 2 months
                    3D Pro subscription ($160 value)
                  </li>
                  <li>
                    <CheckIcon className="plan-card-feature-mark" /> 6 PDR
                    credits
                  </li>
                  <li>
                    <CheckIcon className="plan-card-feature-mark" />
                    <div>
                      Discounted Annual Subscription:
                      <br />
                      <span>-30% off 3D Pro</span> <s>$800</s> - $560
                    </div>
                  </li>
                </ul>
              </div>
            </Grid>*/}
            {/*<Grid item xs={12} md={3}>
              <div className="plan-card">
                <div className="plan-card-caption">Pro Lite</div>
                <div className="plan-card-price">
                  <span>${getPlanPrice(`lite${period}`)}</span> /{" "}
                  {period == "Monthly" ? "mo" : "yr"}
                </div>
                <div className="plan-card-description">Get started with 3D</div>
                <div className="plan-card-button-unified-container">
                  {subData && !subData?.freeTrialUsed && (
                    <Button
                      onClick={() =>
                        getStarted("development_sportsbox_pro_lite", true)
                      }
                      className="plan-button"
                    >
                      Get 14-Day Trial
                    </Button>
                  )}
                  <Button
                    onClick={() =>
                      getStarted("development_sportsbox_pro_lite", false)
                    }
                    className="plan-button-outlined"
                    style={{
                      marginTop:
                        subData && !subData?.freeTrialUsed ? "-10px" : "0px",
                    }}
                  >
                    Subscribe Now
                  </Button>
                </div>
                <div className="plan-card-features-caption">FEATURES</div>
                <div>Starting with...</div>
                <ul className="plan-card-features">
                  <li>
                    <CheckIcon className="plan-card-feature-mark" /> Up to 15
                    students
                  </li>
                  <li>
                    <CheckIcon className="plan-card-feature-mark" /> Advanced
                    analytics
                  </li>
                  <li>
                    <CheckIcon className="plan-card-feature-mark" /> All but
                    Kinematic Sequence
                  </li>
                  <li>
                    <CheckIcon className="plan-card-feature-mark" /> 50GB cloud
                    storage (5,000 swings)
                  </li>
                  <li>
                    <CheckIcon className="plan-card-feature-mark" /> 1 device
                    per user
                  </li>
                </ul>
              </div>
            </Grid>*/}
            <Grid item xs={12} md={4} lg={3}>
              <div className="plan-card">
                <div className="plan-card-caption">
                  3D Pro
                  <span>For Professionals</span>
                </div>
                <div className="plan-card-price">
                  <span>${getPlanPrice(`premium${period}`)}</span> /{" "}
                  {period == "Monthly" ? "mo" : "yr"}
                </div>
                <div className="plan-card-description">
                  Level up your lessons with 3D
                </div>
                <div className="plan-card-button-unified-container">
                  {!subData?.freeTrialUsed && (
                    <Button
                      onClick={() =>
                        getStarted("development_sportsbox_pro_premium", true)
                      }
                      className="plan-button"
                      style={{
                        marginTop: "5px"
                      }}
                    >
                      Try Free for 2 Weeks
                    </Button>
                  )}
                  <Button
                    onClick={() =>
                      getStarted("development_sportsbox_pro_premium", false)
                    }
                    className="plan-button-outlined"
                    style={{
                      marginTop:
                        !subData?.freeTrialUsed ? "-10px" : "10px",
                    }}
                  >
                    Subscribe Now
                  </Button>
                </div>
                <div className="plan-card-features-caption" style={{ marginTop: subData?.freeTrialUsed ? "-80px" : "0px" }}>FEATURES</div>
                <div>Get complete access to</div>
                <ul className="plan-card-features">
                  <li>
                    <CheckIcon className="plan-card-feature-mark" /> Unlimited
                    students
                  </li>
                  <li>
                    <CheckIcon className="plan-card-feature-mark" /> Unlimited
                    swings
                  </li>
                  <li>
                    <CheckIcon className="plan-card-feature-mark" /> All 2D and
                    3D analysis features
                  </li>
                  <li>
                    <CheckIcon className="plan-card-feature-mark" /> All
                    measurements
                  </li>
                  <li>
                    <CheckIcon className="plan-card-feature-mark" /> Sportsbox
                    Drill Library (50+ Drills)
                  </li>
                  <li>
                    <CheckIcon className="plan-card-feature-mark" /> 2 devices
                    per user
                  </li>
                </ul>
              </div>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <div className="plan-card">
                <div className="plan-card-caption">
                  Enterprise
                  <span>For Teams</span>
                </div>
                <div className="plan-card-price">
                  <span>Let’s chat</span>
                </div>
                <div>Help your team of coaches level up with 3D</div>
                <div className="plan-card-button-unified-container">
                  <Button
                    href="mailto:sales@sportsbox.ai"
                    className="plan-button-outlined"
                  >
                    Contact sales
                  </Button>
                </div>
                <br />
                <div className="plan-card-features-caption" style={{ marginTop: subData?.freeTrialUsed ? "-80px" : "0px" }}>FEATURES</div>
                <div>Everything in  <strong>3D Pro</strong> plus...</div>
                <ul className="plan-card-features">
                  <li>
                    <CheckIcon className="plan-card-feature-mark" /> Team
                    management
                  </li>
                  <li>
                    <CheckIcon className="plan-card-feature-mark" /> Personal
                    account management & onboarding
                  </li>
                  <li>
                    <CheckIcon className="plan-card-feature-mark" /> 1TB cloud
                    storage per user (100,000 swings)
                  </li>
                </ul>
              </div>
            </Grid>
          </>
        )}
        {plansType == "players" && (
          <>
            <Grid item xs={12} md={2} lg={3} />
            <Grid item xs={12} md={4} lg={3}>
              <div className="plan-card">
                <div className="plan-card-caption">Free Plan</div>
                <div className="plan-card-price">
                  <span>Free</span>
                </div>
                <div>
                  Preview the power of 3D with our limited free plan
                </div>
                <div>
                  <Button
                    onClick={() =>
                      getStarted("development_sportsbox_free_student", false)
                    }
                    className="plan-button"
                  >
                    Get started
                  </Button>
                </div>
                {console.log(!subData?.freeTrialUsed)}
                {!subData?.freeTrialUsed && (<div><br /><br /></div>)}
                <div className="plan-card-features-caption">FEATURES</div>
                <div>Starting with...</div>
                <ul className="plan-card-features">
                  <li>
                    <CheckIcon className="plan-card-feature-mark" /> 5 swings per month
                  </li>
                  <li>
                    <CheckIcon className="plan-card-feature-mark" /> 1 goal recommended by Sportsbox
                  </li>
                  <li>
                    <CheckIcon className="plan-card-feature-mark" /> Limited measurements related to your goal
                  </li>
                  <li>
                    <CheckIcon className="plan-card-feature-mark" /> Review and analyze swings
                  </li>
                </ul>
              </div>
            </Grid>
            {/*<Grid item xs={12} md={4} lg={3}>
              <div className="plan-card">
                <div className="plan-card-caption">
                  3D Practice
                  <span>Popular</span>
                </div>
                <div className="plan-card-price">
                  <span>${getPlanPrice(`studentLite${period}`)}</span> /{" "}
                  {period == "Monthly" ? "mo" : "yr"}
                </div>
                <div>
                  Practice like the pros with instant 3D feedback on your swing
                </div>
                <div className="plan-card-button-unified-container">
                  {!subData?.freeTrialUsed && (
                    <Button
                      onClick={() =>
                        getStarted("development_sportsbox_student_lite", true)
                      }
                      className="plan-button"
                    >
                      Try Free for 2 Weeks
                    </Button>
                  )}
                  {subData?.freeTrialUsed && (<br />)}
                  <Button
                    onClick={() =>
                      getStarted("development_sportsbox_student_lite", false)
                    }
                    className="plan-button-outlined"
                    style={{
                      marginTop:
                        !subData?.freeTrialUsed ? "-10px" : "10px",
                    }}
                  >
                    Subscribe Now
                  </Button>
                </div>
                <div className="plan-card-features-caption" style={{ marginTop: subData?.freeTrialUsed ? "-40px" : "0px" }}>FEATURES</div>
                <div>
                  Everything in <strong>Free</strong> plus....
                </div>
                <ul className="plan-card-features">
                  <li>
                    <CheckIcon className="plan-card-feature-mark" /> Unlimited swings
                  </li>
                  <li>
                    <CheckIcon className="plan-card-feature-mark" /> Unlimited goals and customizable goals
                  </li>
                  <li>
                    <CheckIcon className="plan-card-feature-mark" /> Basic Tracker and Indicator measurements
                  </li>
                  <li>
                    <CheckIcon className="plan-card-feature-mark" /> Auto-swing
                    detection
                  </li>
                  <li>
                    <CheckIcon className="plan-card-feature-mark" /> 2D and 3D comparison
                  </li>
                  <li>
                    <CheckIcon className="plan-card-feature-mark" /> 50+ practice drills with assessments
                  </li>
                </ul>
              </div>
            </Grid>*/}
            <Grid item xs={12} md={2} lg={3} />
          </>
        )}
      </Grid>
    </>
  );
}

export default PlansSelectionComponent;
