import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";
import { FlexWrap, Pad } from "../../../../components";
import { Thumbnail } from "../Thumbnail";
import { v4 } from "uuid";

export function TutorialModal({
  onCreate,
  onEdit,
  open,
  onClose,
  type,
  init = null,
}) {
  const [form, setForm] = useState({
    title: "",
    description: "",
    tutorialType: "",
    video: { length: 0, thumbnail: null, url: null, file: null },
    newVideo: { length: 0, thumbnail: null, url: null, file: null },
  });
  const [video, setVideo] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);

  useEffect(() => {
    if (type === "edit" && init) {
      const { title, description, tutorialType, videoPath, thumbVideoPath } =
        init;
      setForm((prev) => ({
        ...prev,
        title,
        description,
        tutorialType,
      }));
      setVideo(videoPath);
      setThumbnail(thumbVideoPath);
    }
  }, [init, type]);

  const handleInput = (e) => {
    e.persist();
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleFile = (e) => {
    const file = e.target.files[0];

    setVideo(URL.createObjectURL(file));

    type === "create"
      ? setForm((prev) => ({ ...prev, video: { ...prev.video, file } }))
      : setForm((prev) => ({ ...prev, newVideo: { ...prev.newVideo, file } }));
  };

  const handleMetadata = (e) => {
    const length = e.target.duration;
    type === "create"
      ? setForm((prev) => ({
          ...prev,
          video: { ...prev.video, length },
        }))
      : setForm((prev) => ({
          ...prev,
          newVideo: { ...prev.newVideo, length },
        }));
  };

  const handleThumbnail = (e) => {
    const file = e.target.files[0];
    setThumbnail(URL.createObjectURL(file));
    type === "create"
      ? setForm((prev) => ({
          ...prev,
          video: { ...prev.video, thumbnail: file },
        }))
      : setForm((prev) => ({
          ...prev,
          newVideo: { ...prev.newVideo, thumbnail: file },
        }));
  };

  const clearForm = () => {
    setForm({
      title: "",
      description: "",
      type: "",
      video: { length: 0, thumbnail: null, url: null, file: null },
      newVideo: { length: 0, thumbnail: null, url: null, file: null },
    });
  };

  const handleClose = () => {
    if (type === "create") clearForm();
    onClose();
  };

  const handleSubmit = () => {
    type === "create"
      ? onCreate({ ...form, id: v4() })
      : onEdit(init.id, { ...form });
    clearForm();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Tutorials</DialogTitle>
      <DialogContent>
        <Pad width="350px" height="fit-content">
          <Thumbnail
            display={thumbnail}
            handleFile={handleThumbnail}
            text="Upload Video Thumbnail"
          />
          <FlexWrap justify="space-between" align="baseline">
            <TextField
              style={{ width: "150px" }}
              value={form.title}
              onChange={handleInput}
              name="title"
              label="Title"
            />
            <TextField
              style={{ width: "150px" }}
              value={form.tutorialType}
              onChange={handleInput}
              name="tutorialType"
              label="Type"
            />
            <TextField
              style={{ marginTop: "10px", width: "150px" }}
              value={form.description}
              onChange={handleInput}
              multiline
              rows={4}
              name="description"
              label="Description"
            />
          </FlexWrap>
          <Pad height="10px" />
          <video
            style={{ display: "none" }}
            src={video}
            onLoadedMetadata={handleMetadata}
          />
          <Pad height="10px" />
          {video ? (
            <a href={video} target="_blank">
              Video Preview
            </a>
          ) : (
            ""
          )}
          <Pad height="10px" />
          <FlexWrap justify="space-between">
            <label htmlFor="tutorialVideoUpload">
              <Button variant="contained" color="primary" component="span">
                Upload Video
              </Button>
            </label>
            <input
              id="tutorialVideoUpload"
              style={{ display: "none" }}
              onChange={handleFile}
              type="file"
            />
            <Button
              type="submit"
              onClick={() => handleSubmit()}
              variant="contained"
              color="primary"
            >
              Submit
            </Button>
          </FlexWrap>
        </Pad>
      </DialogContent>
    </Dialog>
  );
}
