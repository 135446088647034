import styled from "styled-components";

export const DragAndDropUpload = styled.div`
  border: 1px #808080 dashed;
  border-radius: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  width: 100%;
  height: 400px;
  margin-bottom: 20px;
`;

export const FilledButton = styled.button`
  background-color: ${({ color }) => color};
  border: ${({ color }) => color} 1px solid;
  border-radius: 4px;
  padding: 12px 16px;
  color: #fff;
  text-transform: uppercase;
  outline: none;
  cursor: pointer;
  ${({ width }) => `width: ${width};`}
  &:hover:enabled {
    background-color: #fff;
    color: ${({ color }) => color};
  }
  &:focus:enabled {
    background-color: #fff;
    color: ${({ color }) => color};
  }
  &:disabled {
    cursor: auto;
    opacity: 0.5;
  }
`;

export const OutlinedButton = styled.button`
  background-color: rgba(0, 0, 0, 0);
  border: ${({ color }) => color} 1px solid;
  border-radius: 4px;
  padding: 12px 16px;
  color: ${({ color }) => color};
  text-transform: uppercase;
  outline: none;
  cursor: pointer;
  ${({ width }) => `width: ${width};`}
  &:hover:enabled {
    background-color: ${({ color }) => color};
    color: #fff;
  }
  &:focus:enabled {
    background-color: ${({ color }) => color};
    color: #fff;
  }
  &:disabled {
    cursor: auto;
    opacity: 0.5;
  }
`;

export const PracticeGuideHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const PracticeGuideHeaderItem = styled.div`
  font-size: 24px;
  font-weight: 700;
  padding: 5px 10px;
  cursor: pointer;
  border-bottom: ${({ active }) => (active ? "3px solid #A12AEE" : "none")};
`;

export const PracticeGuideHeaderItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const WatchlistTrackerFormContainer = styled.div`
  background: #e4e4e4;
  border-radius: 8px;
  justify-content: space-between;
  display: flex;
  width: 100%;
  margin: 10px 0;
  padding: 20px;
`;

export const WatchlistFormGoalColumn = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const WatchlistFormGoalSubContainer = styled.div`
  display: flex;
`;

export const WatchlistFormGoalItem = styled.div`
  width: 100px;
  height: 44px;
  padding: 12px 16px;
  background: #ffffff;
  border: 1px solid #afafaf;
  border-radius: 4px;
`;

export const TagsModalItem = styled.div`
  padding: 10px;
  border: 1px solid #c9c9c9;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
`;

export const TagContainer = styled.div`
  width: fit-content;
  border: 1px solid ${({ button }) => (button ? "#580ce3" : "#580ce3")};
  color: ${({ button }) => (button ? "#fff" : "#580ce3")};
  background: ${({ button }) => (button ? "#580ce3" : "#fff")};
  border-radius: 25px;
  padding: 5px 15px;
  margin-bottom: 5px;
  ${({ button }) =>
    button
      ? `user-select: none;
  cursor: pointer;`
      : ""}
`;

export const SearchButton = styled.div`
  background: #5a5a5a;
  border-radius: 4px;
  padding: 10px;
  padding-bottom: 7px;
  cursor: pointer;
`;

export const SearchInput = styled.input`
  background: #d9d9d9;
  border-radius: 8px;
  border: none;
  height: 45px;
  padding: 10px;
  &:focus {
    border: none;
    outline: none;
  }
`;
