import React from "react";
import {
  FilledSummaryContainer,
  FilledSummaryItem,
  FilledSummaryItemReps,
  FilledSummaryItemsContainer,
  FilledSummaryItemsSubContainer,
  FilledSummaryTitleContainer,
} from "./styled";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { useEffect } from "react";

export function FilledAssessment({ item, type, title, onRemove, onAdd }) {
  return (
    <FilledSummaryContainer>
      <FilledSummaryTitleContainer>
        <div>{title}</div>
        <AddIcon
          onClick={onAdd}
          style={{ fontSize: "24px", color: "purple" }}
        />
      </FilledSummaryTitleContainer>
      <FilledSummaryItemsContainer>
        <FilledSummaryItem key={item.id}>
          <div>{item.title}</div>
          <FilledSummaryItemsSubContainer>
            <FilledSummaryItemReps>x{item.reps}</FilledSummaryItemReps>
            <DeleteIcon
              onClick={() => onRemove(item, type)}
              style={{ fontSize: "24px" }}
            />
          </FilledSummaryItemsSubContainer>
        </FilledSummaryItem>
      </FilledSummaryItemsContainer>
    </FilledSummaryContainer>
  );
}
