import { Dialog, DialogContent } from "@material-ui/core";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { FlexWrap, Pad } from "../../../../components";
import { hardcodedData } from "../../config";
import { CustomRadio } from "../common/CustomRadio";
import { FilledButton } from "../common/styled";
import { ModalTitle } from "../styled";
import { EqupmentItemContent } from "./styled";

export function AssessmentModal({ open, init, type, onClose, onSave }) {
  const [selected, setSelected] = useState(null);
  useEffect(() => {
    if (init) setSelected(init);
  }, [init]);

  const handleSelect = (item) => {
    setSelected(item);
  };

  const handleClose = () => {
    onClose();
    setSelected(null);
  };

  const handleSave = () => {
    onClose();
    onSave("benchmarkAssessment", { title: selected, reps: 3 });
    onSave("finalAssessment", { title: selected, reps: 3 });
    setSelected(null);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent style={{ padding: "30px" }}>
        <ModalTitle>Assessment Setup</ModalTitle>
        <div>Select club for assessment</div>
        <Pad margin="20px 0" width="100%">
          {hardcodedData.assessment.map((el) => (
            <>
              <EqupmentItemContent onClick={() => handleSelect(el)}>
                <div>{el}</div>
                <CustomRadio active={selected ? selected === el : false} />
              </EqupmentItemContent>
              <Pad height="10px" />
            </>
          ))}
        </Pad>
        <FlexWrap justify="space-between">
          <FilledButton onClick={handleClose} width="48%" color="#808080">
            cancel
          </FilledButton>
          <FilledButton onClick={handleSave} width="48%" color="#580CE3">
            add
          </FilledButton>
        </FlexWrap>
      </DialogContent>
    </Dialog>
  );
}
