import React, { Component } from 'react';

import { CssBaseline, Box, Avatar, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Search as SearchIcon } from '@material-ui/icons';
import AdvancedSearch from './components/advance_search/AdvancedSearch';

class Search extends Component{
  render(){
    const classes = this.props.classes;
    return (<Box
        component="main">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
              <SearchIcon/>
          </Avatar>
          <Typography
              component="h1"
              variant="h4"
              style={{marginBottom: '10px'}}
          >
              SEARCH
          </Typography>
          {/* <Typography
            component="h1"
            variant="body1"
            style={{marginBottom: '10px'}}
          >Select What You Want To Search For </Typography>
          <div style={{width: '50%', minWidth: '400px', marginBottom: '10px'}}>
            <ToggleButtonGroup
            style={{width: '101%'}}
            exclusive
            value={this.props.values.searchType}
            onChange={this.props.handleChange('searchType')}
            >
              <ToggleButton
                style={{width: '33%'}}
                value='users'>
                User
              </ToggleButton>
              <ToggleButton
                style={{width: '33%'}}
                value='sessions'>
                Session
              </ToggleButton>
              <ToggleButton
                style={{width: '33%'}}
                value='videos'>
                Video
              </ToggleButton>
              {this.props.values.role === 'admin' && (
                <ToggleButton
                  style={{width: '33%'}}
                  value='analysis'>
                  Analysis
                </ToggleButton>
              )}
              <ToggleButton
                style={{width: '33%'}}
                value='search'>
                Adv. Search
              </ToggleButton>
              </ToggleButtonGroup>
          </div> */}
          {/* <div style={{minWidth: '80%', display: (this.props.values.searchType !== "search" ? 'block' : 'none')}}>
            <DisplayTables
              userData={this.props.values.userData}
              subData={this.props.values.subData}
              user={this.props.values.user}
              role={this.props.values.role}
              sessions={this.props.values.sessions}
              videos={this.props.values.videos}
              searchType={this.props.values.searchType}
              sessionTypes={this.props.values.sessionTypes} 
              sessionEnvs={this.props.values.sessionEnvs} 
              cameraSetups={this.props.values.cameraSetups}
              queryResults={this.props.values.queryResults}
              users={this.props.values.users}/>
          </div> */}
          <div id="adv-search-div" style={{width: '100%', display: (this.props.values.searchType === "search" ? 'block' : 'none')}}>
              <AdvancedSearch
                mappings={this.props.values.mappings}
                reportMappings={this.props.values.reportMappings}
                userData={this.props.values.userData}
                queryElasticwPit={this.props.queryElasticwPit}
                refreshPit={this.props.refreshPit}/>
          </div>
        </div>
      </Box>)
  }
}

Search.propTypes = {
  openPopUp: PropTypes.func,
  closePopUp: PropTypes.func,
  submitQuery: PropTypes.func,
  handleChange: PropTypes.func,
  values: PropTypes.object,
  classes: PropTypes.object
}
export default Search;