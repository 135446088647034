import React from "react";
import {
  EmptySelector,
  PracticeGuideDetailsContainer,
  PracticeGuideFormColumn,
  PracticeGuideMediaColumn,
  StatusBadge,
} from "../styled";
import {
  FormControl,
  InputLabel,
  TextField,
  Select,
  Dialog,
  DialogContent,
  MenuItem,
} from "@material-ui/core";
import { Pad } from "../../../components";
import { MediaUpload } from "./MediaUpload";
import {
  ModalContentFooter,
  ModalContentHeader,
  ModalContentTitle,
} from "./styled";
import CloseIcon from "@material-ui/icons/Close";
import { EquipmentModal } from "./practiceGuide/EquipmentModal";
import { useState } from "react";
import { OutlinedButton } from "./common/styled";
import { AlbumMediaUpload } from "./album/AlbumMediaUpload";
import {
  AlbumPracticeGuideItem,
  EmptyAlbumPracticeGuide,
} from "./album/styled";
import { AlbumPracticeGuideModal } from "./album/AlbumPracticeGuideModal";
import { useEffect } from "react";
import { getPracticeGuides } from "../api_test";
import { createAlbum, getAlbumById } from "../api_test/albumApi";
import { format } from "date-fns";
import DeleteIcon from "@material-ui/icons/Delete";
import { ExitDetailsModal } from "./ExitDetailsModal";
import { ConfirmSaveModal } from "./common/ConfirmSaveModal";
import { hardcodedData } from "../config";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FilledPracticeGuide } from "./album/FilledPracticeGuide";

export function AlbumDetailsModal({ open, onClose, initId, onSave, onUpdate }) {
  const [form, setForm] = useState({
    title: "",
    summary: "",
    skillLevel: "",
    practiceGuides: [],
  });
  const [media, setMedia] = useState({
    coverImage: { src: "", file: null, duration: 0 },
    introVideo: { src: "", file: null, duration: 0 },
  });
  const [modal, setModal] = useState({
    practiceGuide: { open: false, init: [] },
    confirm: { open: false },
    exit: { open: false },
  });
  const [initTitle, setInitTitle] = useState(null);
  const [practiceGuides, setPracticeGuides] = useState([]);

  useEffect(() => {
    if (initId) {
      const fetch = async () => {
        const {
          title,
          description,
          skillLevel,
          coverImage,
          introVideo,
          practiceGuideIds,
        } = await getAlbumById(initId);
        setInitTitle(title);
        setForm({
          title,
          skillLevel,
          summary: description,
          practiceGuides: practiceGuideIds,
        });
        setMedia({
          coverImage: { src: coverImage, file: null, duration: 0 },
          introVideo: { src: introVideo.videoPath, file: null, duration: 0 },
        });
      };
      fetch();
    }
  }, [initId]);

  useEffect(() => {
    const fetch = async () => {
      const data = await getPracticeGuides();
      setPracticeGuides(data);
    };
    fetch();
  }, []);

  const handleInputs = (e) => {
    e.persist();
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSelectors = (name, values) => {
    setForm((prev) => ({ ...prev, [name]: values }));
  };

  const filterPracticeGuides = (idArr) => {
    // const filtered = practiceGuides.filter((el) => idArr.includes(el.id));
    // return filtered;

    const filt = idArr.map((el) => practiceGuides.find((fnd) => fnd.id === el));
    return filt;
  };

  const handleUpload = (e) => {
    e.persist();
    setMedia((prev) => ({
      ...prev,
      [e.target.name]: {
        file: e.target.files[0],
        src: URL.createObjectURL(e.target.files[0]),
        duration: 0,
      },
    }));
  };

  const handleMetadata = (name, duration) => {
    if (!media[name].duration) {
      setMedia((prev) => ({
        ...prev,
        [name]: {
          ...prev[name],
          duration,
        },
      }));
    }
  };

  const handleSave = () => {
    setModal((prev) => ({ ...prev, confirm: { open: true } }));
  };

  const handleConfirmSave = (status) => {
    if (initId) onUpdate(initId, { ...form, ...media, status });
    else onSave({ ...form, ...media, status });
    handleClose();
  };

  const handleClose = () => {
    setForm({ title: "", summary: "", skillLevel: "", practiceGuides: [] });
    setMedia({
      coverImage: { src: "", file: null, duration: 0 },
      introVideo: { src: "", file: null, duration: 0 },
    });
    setModal({
      practiceGuide: { open: false, init: [] },
      exit: { open: false },
      confirm: { open: false },
    });
    onClose();
  };

  const checkValidSave = () => {
    const saveOptions = {
      private: false,
      published: false,
    };

    if (form.title !== "" && form.description !== "") {
      saveOptions.private = true;
    }

    if (
      form.title !== "" &&
      form.summary !== "" &&
      form.skillLevel !== "" &&
      form.practiceGuides.length !== 0 &&
      media.coverImage.src !== ""
    ) {
      saveOptions.published = true;
    }

    return saveOptions;
  };

  const handleRemovePracticeGuide = (id) =>
    setForm((prev) => ({
      ...prev,
      practiceGuides: prev.practiceGuides.filter((el) => {
        return el !== id;
      }),
    }));

  const handleReorderPracticeGuide = (newArr) => {
    setForm((prev) => {
      return {
        ...prev,
        practiceGuides: newArr,
      };
    });
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={() =>
          setModal((prev) => ({
            ...prev,
            exit: { open: true },
          }))
        }
      >
        <DialogContent style={{ background: "#f3f3f3", padding: "0" }}>
          <ModalContentHeader>
            <div>{initTitle ? initTitle : "Album Title"}</div>
            <CloseIcon
              onClick={() =>
                setModal((prev) => ({
                  ...prev,
                  exit: { open: true },
                }))
              }
              style={{ cursor: "pointer" }}
            />
          </ModalContentHeader>
          <PracticeGuideDetailsContainer>
            <PracticeGuideFormColumn>
              <ModalContentTitle>Album Detail</ModalContentTitle>
              <TextField
                value={form.title}
                onChange={handleInputs}
                variant="outlined"
                name="title"
                label="Album Title"
              />
              <Pad height="20px" />
              <TextField
                value={form.summary}
                onChange={handleInputs}
                variant="outlined"
                name="summary"
                label="Summary"
              />
              <Pad height="20px" />
              <FormControl variant="outlined">
                <InputLabel id="skill-label">Skill Level</InputLabel>
                <Select
                  lableId="skill-label"
                  value={form.skillLevel}
                  onChange={handleInputs}
                  name="skillLevel"
                  label="Skill Level"
                >
                  {hardcodedData.skill.map((el) => (
                    <MenuItem key={el} value={el}>
                      {el}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Pad height="20px" />
              <ModalContentTitle>
                Content{" "}
                {form.practiceGuides.length === 0
                  ? ""
                  : `(${form.practiceGuides.length})`}
              </ModalContentTitle>
              {form.practiceGuides.length === 0 ? (
                <EmptyAlbumPracticeGuide>
                  <div>Add up to 10 guides</div>
                  <Pad height="10px" />
                  <OutlinedButton
                    onClick={() =>
                      setModal((prev) => ({
                        ...prev,
                        practiceGuide: {
                          open: true,
                          init: [],
                        },
                      }))
                    }
                    width="calc(100% - 100px)"
                    color="#580CE3"
                  >
                    Add Guide
                  </OutlinedButton>
                </EmptyAlbumPracticeGuide>
              ) : (
                <>
                  {/* {filterPracticeGuides(form.practiceGuides).map((el) => {
                      return (
                        <div key={el.id}>
                          <AlbumPracticeGuideItem>
                            <img
                              src={el.coverImage}
                              height="87px"
                              width="132px"
                            />
                            <div>{el.title}</div>
                            <div>{format(el.createdAt, "MM/dd/yyyy")}</div>
                            <DeleteIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => handleRemovePracticeGuide(el.id)}
                            />
                          </AlbumPracticeGuideItem>
                          <Pad height="10px" />
                        </div>
                      );
                    })} */}
                  <FilledPracticeGuide
                    items={filterPracticeGuides(form.practiceGuides)}
                    onRemove={handleRemovePracticeGuide}
                    onReorder={handleReorderPracticeGuide}
                  />
                  <OutlinedButton
                    onClick={() =>
                      setModal((prev) => ({
                        ...prev,
                        practiceGuide: {
                          open: true,
                          init: form.practiceGuides,
                        },
                      }))
                    }
                    width="100%"
                    color="#580CE3"
                  >
                    Add Guide
                  </OutlinedButton>
                </>
              )}
            </PracticeGuideFormColumn>
            <PracticeGuideMediaColumn>
              <AlbumMediaUpload
                onMetadata={handleMetadata}
                uploadFn={handleUpload}
                media={media}
              />
            </PracticeGuideMediaColumn>
          </PracticeGuideDetailsContainer>
          <ModalContentFooter>
            <StatusBadge status="draft">draft</StatusBadge>
            <OutlinedButton onClick={handleSave} color="#580CE3">
              save
            </OutlinedButton>
          </ModalContentFooter>
        </DialogContent>
      </Dialog>
      <AlbumPracticeGuideModal
        open={modal.practiceGuide.open}
        init={modal.practiceGuide.init}
        onClose={() =>
          setModal((prev) => ({
            ...prev,
            practiceGuide: { open: false, init: [] },
          }))
        }
        onSave={handleSelectors}
      />
      <ExitDetailsModal
        open={modal.exit.open}
        onClose={() => setModal((prev) => ({ ...prev, exit: { open: false } }))}
        onConfirm={handleClose}
      />
      <ConfirmSaveModal
        open={modal.confirm.open}
        onClose={() =>
          setModal((prev) => ({
            ...prev,
            confirm: { open: false },
          }))
        }
        onSave={handleConfirmSave}
        validation={checkValidSave}
      />
    </>
  );
}
