import React, { Component } from 'react';
import { createMuiTheme, MuiThemeProvider, Typography, MenuItem, Menu, Fade } from '@material-ui/core';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import * as admin from 'firebase/app';
import 'firebase/firestore';
import { forwardRef } from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {AddBox, ArrowDownward, Check, ChevronLeft, ChevronRight, Clear, DeleteOutline, Edit, FilterList, FirstPage, LastPage, Remove, SaveAlt, Search, ViewColumn} from '@material-ui/icons';
import palette from '../../../../theme/palette';
import DisplayLabReqItems from './DisplayLabReqItems';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const tableHeads = {labelingRequests: [
  {field: 'requestID', title: 'Request ID'},
  {field: 'name', title: 'Name'},
  {field: 'requester', title: 'Requester'},
  {field: 'assignee', title: 'Assignee'},
  {field: 'createdDate', title: 'Created Date'},
  {field: 'desiredDate', title: 'Desired Date'},
  {field: 'status', title: 'Status'}
]}

const theme = createMuiTheme({
  typography: {
    h6: {
      fontWeight: 500,
      fontSize: '20px',
      letterSpacing: '-0.05px',
      lineHeight: '20px',
    }
  },
  palette: {
    secondary: {
      main: palette.primary.main,
    },
  },
})

class DisplayLabReqTable extends Component {
  constructor (props) {
    super(props);
    this.state = {
      popUpData: {
        open: false
      },
      selectedRows: {
        labelingRequests: []
      },
      editMenu: {
        anchor: null,
        type: ''
      },
      deleteMenu: {
        anchor: null,
        type: ''
      }
    }
  }

  getTableHeader = (name) => {
    return tableHeads[name];
  }

  openPopUp = (type, rowData) => {
    this.setState({popUpData: {open: true, type: type, rowData: rowData}})
  }

  closePopUp = () => {
    this.setState({popUpData: {open: false}})
  }

  handleEdit = (type) => (event, data) => {
    this.setState({editMenu: {anchor: event.currentTarget, type: type}, selectedRows: {...this.state.selectedRows, [type]: data}})
  }

  handleDelete = (type) => (event, data) => {
    this.setState({deleteMenu: {anchor: event.currentTarget, type: type}, selectedRows: {...this.state.selectedRows, [type]: data}})
  }

  editLabReq = () => {
    const ids = this.state.selectedRows.labelingRequests.map((item) => item.id)
    localStorage.setItem('editLabReq', ids[0])
  }

  deleteLabReq = () => {
    const ids = this.state.selectedRows.labelingRequests.map((item) => item.id)
    let count = 0;
    for(let i = 0; i < ids.length; ++i) {
      admin.firestore().collection('labelingRequests').doc(ids[i]).get().then(function(querySnapshot) {
        let res = querySnapshot.data();
        let reqID = res.requestID;
        admin.firestore().collection('labelingJobs').where('requestID', '==', reqID).get().then(snapshot => {
          snapshot.forEach(function(doc) {
            admin.firestore().collection('labelingJobs').doc(doc.id).delete();
          });
        })
        .catch(err => {
          console.log('Error getting documents', err);
        });
        admin.firestore().collection('labelingRequests').doc(ids[i]).delete();
        ++count;
        if(count === ids.length) {
          alert(ids.length + " labeling request(s) were deleted, page will refresh now");
          setTimeout(function() {
            window.location.reload()
          }, 1000);
        }
      })
      .catch(err => {
          console.log("Error deleting documents: ", err);
      });
    }
  }

  render(){
    let labReqData = [];
    if (this.props.labelingRequests) {
      const keys = Object.keys(this.props.labelingRequests)
      labReqData = keys.map((key) => {
        if(this.props.labelingRequests[key]) {
          const labelingRequest = this.props.labelingRequests[key];
          const out = {
            id: labelingRequest.id,
            requestID: labelingRequest.requestID,
            name: labelingRequest.name,
            requester: labelingRequest.requesterName,
            assignee: labelingRequest.assignee,
            createdDate: labelingRequest.dateCreated,
            desiredDate: labelingRequest.desiredDate,
            status: (labelingRequest.status.charAt(0).toUpperCase() + labelingRequest.status.substring(1, labelingRequest.status.length)).replace(/([A-Z])/g, ' $1').trim(),
            purpose: labelingRequest.purpose,
            videosType: (labelingRequest.videosType.charAt(0).toUpperCase() + labelingRequest.videosType.substring(1, labelingRequest.videosType.length)).replace(/([A-Z])/g, ' $1').trim(),
            numberOfFrames: labelingRequest.numberOfFrames,
            instructions: labelingRequest.instructions,
            numberOfPoints: labelingRequest.numberOfPoints,
            descriptionOfVideos: labelingRequest.descriptionOfVideos,
            locationOfVideos: labelingRequest.locationOfVideos,
            descriptionOfFrames: labelingRequest.descriptionOfFrames,
            locationOfFrames: labelingRequest.locationOfFrames,
            completionDate: labelingRequest.completionDate,
          }
          return out;
        }
        return null;
      })
    }
    const tableElements = [];
    if(labReqData.length > 0) {
      tableElements.push(
        <MaterialTable
          style={{marginBottom: '10px'}}
          icons={tableIcons}
          key='labReqTable'
          title='Labeling Requests'
          columns={this.getTableHeader('labelingRequests')}
          data={labReqData.map(row => this.state.selectedRows.labelingRequests.find(selected => selected.id === row.id) ? {...row, tableData: { checked: true}} : row)}
          options={{
            selection: true
          }}
          actions={[{
            tooltip: 'Edit selected labeling request',
            icon: tableIcons.Edit,
            onClick: this.handleEdit('labelingRequests')
          }, {
            tooltip: 'Delete selected labeling request(s)',
            icon: tableIcons.Delete,
            onClick: this.handleDelete('labelingRequests')
          }]}
          onRowClick={(event, rowData) => this.openPopUp('labelingRequests', rowData)}
        />)
    }
    if (tableElements.length < 1) {
      return (<Typography align='center' style={{marginTop: '20px'}}>There are no items to display</Typography>)
    }
    return (
    <MuiThemeProvider theme={theme}>
      {tableElements}
      <DisplayLabReqItems
        open={this.state.popUpData.open}
        type={this.state.popUpData.type}
        rowData={this.state.popUpData.rowData}
        onClose={this.closePopUp}
      />
      <Menu
        id="menu1"
        anchorEl={this.state.editMenu.anchor}
        keepMounted
        open={Boolean(this.state.editMenu.anchor)} 
        onClose={() => {this.setState({editMenu: {anchor: null, type: ''}})}}
        TransitionComponent={Fade}
      >
        <MenuItem component={RouterLink} to="/edit-labelingRequest" onClick={this.editLabReq}>Edit</MenuItem>
      </Menu>
      <Menu
        id="menu2"
        anchorEl={this.state.deleteMenu.anchor}
        keepMounted
        open={Boolean(this.state.deleteMenu.anchor)}
        onClose={() => {this.setState({deleteMenu: {anchor: null, type: ''}})}}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={this.deleteLabReq}>Delete</MenuItem>
      </Menu>
    </MuiThemeProvider>)
  }
}
DisplayLabReqTable.propTypes = {
  labelingRequests: PropTypes.object,
  searchOrder: PropTypes.string,
  compress: PropTypes.bool
}
export default DisplayLabReqTable