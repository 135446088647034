import { format } from "date-fns";
import React from "react";
import { FlexWrap } from "../../../../components";
import { CustomRadio } from "../common/CustomRadio";
import { SummaryItemContainer } from "./styled";

export function TipsAndDrillsModalChoice({ data, active, onClick }) {
  return (
    <SummaryItemContainer onClick={() => onClick(data.id)}>
      <video style={{ background: "black" }} height="87px" width="132px">
        <source src={data.loopVideo.videoPath} type="video/webm" />
      </video>
      <div style={{ minWidth: "150px", maxWidth: "150px" }}>{data.title}</div>
      <div style={{ minWidth: "150px", maxWidth: "150px" }}>{data.author}</div>
      <div style={{ minWidth: "150px", maxWidth: "150px" }}>
        {format(new Date(data.createdAt), "MM/dd/yyyy")}
      </div>
      <CustomRadio active={active} />
    </SummaryItemContainer>
  );
}
