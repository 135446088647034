import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, FormControl, InputLabel, Select, MenuItem, InputAdornment, FormControlLabel, Checkbox, Link } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { functionBaseUrl, axiosWithToken } from '../../common/firebase';
import Swal from 'sweetalert2';

class AddStudentAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      coachEmail: '',
      dob: '',
      height: '',
      gender: '',
      dominantHand: '',
      hipWidth: '',
      subPlan: '',
      trialPeriod: '',
      agree: false
    }
  }

  handleChange = (prop) => (event) => {
    this.setState({ [prop]: event.target.value })
  }

  handleAgree = () => {
    this.setState({ agree: !this.state.agree })
  }

  getInitialState = () => {
    return {
      firstName: '',
      lastName: '',
      email: '',
      coachEmail: '',
      dob: '',
      height: '',
      gender: '',
      dominantHand: '',
      hipWidth: '',
      subPlan: '',
      trialPeriod: '',
      agree: false
    }
  }

  handleClear = () => {
    const confirmVal = window.confirm('Do you want to clear your query?')
    if (confirmVal) {
      this.setState(this.getInitialState())
    }
  }

  validEmail = (email) => {
    const regex = /\S+@\S+\.\S+/
    return regex.test(email)
  }

  dobFormat = (dob) => {
    let [year, month, day] = dob.split('-');
    return (month + "/" + day + "/" + year)
  }

  handleSubmit = () => {
    const output = {
      email: this.state.email.toLowerCase().replace(/ /g, ''),
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      dob: this.dobFormat(this.state.dob),
      gender: this.state.gender,
      height: this.state.height,
      coachEmail: this.state.coachEmail.toLowerCase().replace(/ /g, ''),
      dominantHand: this.state.dominantHand,
      hipWidth: this.state.hipWidth,
      subPlan: 'development_sportsbox_free_student',
      trialPeriod: this.state.trialPeriod
    }
    if (output.firstName.length === 0) {
      this.props.onClose();
      Swal.fire({
        title: '<p style="font-size:70%;">First name field cannot be blank</p>',
        icon: 'warning',
        confirmButtonText: 'Ok',
        allowOutsideClick: true,
        timer: 5000
      })
      return false;
    }
    if (output.lastName.length === 0) {
      this.props.onClose();
      Swal.fire({
        title: '<p style="font-size:70%;">Last Name field cannot be blank</p>',
        icon: 'warning',
        confirmButtonText: 'Ok',
        allowOutsideClick: true,
        timer: 5000
      })
      return false;
    }
    if (output.dob.length === 0) {
      this.props.onClose();
      Swal.fire({
        title: '<p style="font-size:70%;">Date of birth field cannot be blank</p>',
        icon: 'warning',
        confirmButtonText: 'Ok',
        allowOutsideClick: true,
        timer: 5000
      })
      return false;
    }
    if (output.dob.length > 0) {
      const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
      const date = new Date(new Date().setFullYear(new Date().getFullYear() - 13)).toLocaleDateString('en-ZA', options).replaceAll('/', '-');
      if (Date.parse(this.state.dob) > Date.parse(date)) {
        this.props.onClose();
        Swal.fire({
          title: '<p style="font-size:70%;">Sorry, this user is not eligible for a Sportsbox.ai account at this time.</p>',
          icon: 'warning',
          confirmButtonText: 'Ok',
          allowOutsideClick: false
        })
        this.setState(this.getInitialState());
        return false;
      }
    }
    if (output.email.length === 0) {
      this.props.onClose();
      Swal.fire({
        title: '<p style="font-size:70%;">Email field cannot be blank</p>',
        icon: 'warning',
        confirmButtonText: 'Ok',
        allowOutsideClick: true,
        timer: 5000
      })
      return false;
    }
    if (output.coachEmail.length === 0) {
      this.props.onClose();
      Swal.fire({
        title: '<p style="font-size:70%;">Email field cannot be blank</p>',
        icon: 'warning',
        confirmButtonText: 'Ok',
        allowOutsideClick: true,
        timer: 5000
      })
      return false;
    }
    if (output.email.length > 0) {
      if (!this.validEmail(output.email)) {
        this.props.onClose();
        Swal.fire({
          title: '<p style="font-size:70%;">Please enter a valid email</p>',
          icon: 'warning',
          confirmButtonText: 'Ok',
          allowOutsideClick: true,
          timer: 5000
        })
        return false;
      }
    }
    if (output.coachEmail.length > 0) {
      if (!this.validEmail(output.coachEmail)) {
        this.props.onClose();
        Swal.fire({
          title: '<p style="font-size:70%;">Please enter a valid coach email</p>',
          icon: 'warning',
          confirmButtonText: 'Ok',
          allowOutsideClick: true,
          timer: 5000
        })
        return false;
      }
    }
    axiosWithToken(functionBaseUrl + '/api/invUserAdmin', {
      method: 'post',
      data: output
    }).then(response => {
      if (response.status === 201) {
        Swal.fire({
          title: '<p style="font-size:70%;">' + response.data.message + '</p>',
          icon: 'warning',
          confirmButtonText: 'Ok',
          allowOutsideClick: true
        })
      } else {
        this.setState({ students: response.data, loading: false });
        Swal.fire({
          title: '<p style="font-size:70%;">' + response.data.message + '</p>',
          icon: 'success',
          confirmButtonText: 'Ok',
          allowOutsideClick: true,
        }).then(() => {
          this.setState(this.getInitialState())
        })
      }
    })
      .catch(err => {
        console.log(err)
      });
    this.props.onClose()
  }

  render() {
    return (
      <Dialog
        fullWidth
        open={this.props.open}
        onClose={this.props.onClose}
        aria-labelledby='form-dialog-title'>
        <DialogTitle id={'form-dialog-title'}>
          <div>
            Enter User Details
            <CloseIcon style={{ float: 'right', marginTop: '-10px', marginRight: '-15px' }} onClick={this.props.onClose} />
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container component="main" maxWidth="xs">
            <Grid
              item
              xs={12}
            >
              <Typography
                variant="h5"
                color='primary'
              >
                User Details
              </Typography>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  required
                  label='First Name'
                  name='firstName'
                  margin='dense'
                  onChange={this.handleChange('firstName')}
                  value={this.state.firstName}
                  variant='outlined' />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  required
                  label='Last Name'
                  name='lastName'
                  margin='dense'
                  onChange={this.handleChange('lastName')}
                  value={this.state.lastName}
                  variant='outlined' />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  required
                  label='Email'
                  name='email'
                  margin='dense'
                  onChange={this.handleChange('email')}
                  value={this.state.email}
                  variant='outlined' />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  required
                  label='Coach Email'
                  name='caochEmail'
                  margin='dense'
                  onChange={this.handleChange('coachEmail')}
                  value={this.state.coachEmail}
                  variant='outlined' />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                  fullWidth
                  variant="outlined"
                  id="date"
                  margin="dense"
                  label="Date of Birth"
                  onChange={this.handleChange('dob')}
                  type="date"
                  value={this.state.dob}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Height (e.g. 5'10)"
                  name='height'
                  margin='dense'
                  onChange={this.handleChange('height')}
                  value={this.state.height}
                  variant='outlined' />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl              
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  >
                    <InputLabel id="gender-select">Gender</InputLabel>
                    <Select
                      id="gender"
                      label="Gender"
                      labelId="gender-select"
                      name="gender"
                      value={this.state.gender.toUpperCase()}
                      onChange={this.handleChange('gender')}
                    >
                      <MenuItem value={'MALE'}>Male</MenuItem>
                      <MenuItem value={'FEMALE'}>Female</MenuItem>
                      <MenuItem value={'PREFER NOT TO ANSWER'}>Prefer not to answer</MenuItem>
                    </Select>
                  </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl              
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  >
                    <InputLabel id="domHand-select">Dominant Hand</InputLabel>
                    <Select
                      id="domHand"
                      label="Dominant Hand"
                      labelId="domHand-select"
                      name="domHand"
                      value={this.state.dominantHand.toUpperCase()}
                      onChange={this.handleChange('dominantHand')}
                    >
                      <MenuItem value={'RIGHT'}>Right</MenuItem>
                      <MenuItem value={'LEFT'}>Left</MenuItem>
                    </Select>
                  </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField    
                  fullWidth         
                  label={"Hip Width (e.g. 7.5)"}
                  margin="dense"
                  name="hipSize"
                  onChange={this.handleChange('hipWidth')}
                  variant="outlined"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">in</InputAdornment>,
                  }}
                  value={this.state.hipWidth}
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      value={this.state.agree}
                      onClick={this.handleAgree}
                      checked={this.state.agree ? 'checked' : null}
                    />
                  }
                  label={
                    <div>
                      <span>I have read and agree to Sportsbox's </span>
                      <Link onClick={(e) => {
                        e.preventDefault();
                        window.open(window.location.href.replace('invites', 'terms-of-service'))
                      }}>Terms of Service
                      </Link>
                      <span> and </span>
                      <Link onClick={(e) => {
                        e.preventDefault();
                        window.open(window.location.href.replace('invites', 'privacy'))
                      }}>Privacy Policy
                      </Link>
                    </div>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClear} color="primary">
            Clear
          </Button>
          <Button onClick={this.handleSubmit} color="secondary" disabled={this.state.agree === false}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

AddStudentAdmin.propTypes = {
  item: PropTypes.object,
  open: PropTypes.bool,
  closePopUp: PropTypes.func,
  submitQuery: PropTypes.func,
  values: PropTypes.object
};

export default AddStudentAdmin;