import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Container, Avatar, Typography, CircularProgress, withStyles, DialogContent, Dialog, DialogTitle} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import CloseIcon from "@material-ui/icons/Close";
//import ActivitiesController from './activities/ActivitiesController';
//import AddressController from './address/AddressController';
import { axiosWithToken, functionBaseUrl } from '../../common/firebase';
import firebase from '../../common/firebase';
import axios from 'axios';
import Swal from 'sweetalert2';
import PlansView, { planPricing } from './plansView/PlansView';
import UserInfo from './user_info/UserInfo';
import PlanPay from './payment/PlanPay';

const useStyles = theme => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  }
});

const dobFormat = (dob) => {
  let [year, month, day] = dob.split('-');
  return (month + "/" + day + "/" + year)
}

const heightFormat = (height) => {
  if (!height || height === `_'__"`) {
    return '';
  }
  try {
    let [feet, inches] = height.replace(`"`, '').replace('_', '0').split(`'`);
    return `${parseInt(feet)}'${parseInt(inches)}"`
  } catch (e) {
    return '';
  }
}

const avatarActive = ['info', 'pay']

class SignUpController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      stage: 'plans',
      selectedPlan: '',
    }
  }
  handleUserInfoSubmit = async (userInfo, password) => {
    this.setState({loading: true, password})
    let creds
    if (!userInfo.uid) {
      creds = await firebase.auth().createUserWithEmailAndPassword(userInfo.email.toLowerCase().replace(/ /g, ''), password)
    }
    this.createUserEntry(userInfo, creds)
  }
  createUserEntry = async (userInfo, creds) => {
    if (!userInfo.uid) {
      userInfo.uid = creds?.user?.uid
    }
    const data = {
      uid: userInfo.uid,
      firstName: userInfo.firstName?.replace(/ /g, '') || '',
      lastName: userInfo.lastName?.replace(/ /g, '') || '',
      userName: userInfo.firstName?.replace(/ /g, '') + ' ' + userInfo.lastName?.replace(/ /g, ''),
      email: userInfo.email?.replace(/ /g, '') || '',
      averageScore: 0,
      goals: [],
      dob: dobFormat(userInfo.dob),
      height: heightFormat(userInfo.height),
      hipSize: '7.2',
      gender: userInfo.gender || '',
      dominantHand: userInfo.dominantHand || '',
      picture: '',
      role: 'Sportsbox-Instructor',
      weeklyPractices: 0,
      coachAddress: { city: "", state: "", country: "" },
      coachFacility: [{ name: "", address: "", students: 0, lessons: 0, environment: [] }],
      certification: [],
      userConsent: userInfo.userConsent
    }
    await axios(functionBaseUrl + '/api/userMobile', {
      method: 'POST',
      data: data
    })
    .then(async () => {
      if (this.state.selectedPlan?.planName.includes('free')) {
        if (creds) {
          creds.user.sendEmailVerification();
          await axiosWithToken(functionBaseUrl + '/api/users/' + userInfo.uid, {
            method: 'PATCH',
            data: {
              userInfo: {
                subscriptionType: 'development_sportsbox_free_student',
                role: 'Sportsbox-User'
              }
            }
          });
        }
        this.props.history.push('/dashboard');
      } else {
        this.setState({userInfo, loading:false, stage: 'pay'});
      }
    })
    .catch(err => {
      this.setState({loading:false});
      Swal.fire({
        title: '<p style="font-size:70%;">An unexpected error occured and your account could not be created.</p>',
        confirmButtonText: 'Ok',
        allowOutsideClick: false
      })
      .then(() => {
        this.props.history.push('/welcome');
      })
      console.log(err);
    });
  }

  handlePaymentComplete = () => {
    axiosWithToken(functionBaseUrl + '/api/userEmailVerf', {
      method: 'PATCH',
      data: {
        id: this.state.userInfo.uid,
        status: true
      }
    })
    .then(async () => {
      localStorage.setItem('showPopUp', true);
      window.location.href = "/dashboard"
    })
    .catch(err => {
      console.log(err);
    })
  }
  render() {
    const {classes} = this.props
    return (
      this.state.loading ? (
        <Container maxWidth="xl" style={{ textAlign: 'center', padding: 200 }}>
          <CircularProgress />
        </Container>
      ) : (
      <div>
        {avatarActive.includes(this.state.stage) && (<div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', padding: 30}}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography
            variant="h5"
          >
            Create Account
          </Typography>
        </div>)}
        {this.state.stage === 'plans' && <PlansView
            handleChange={(field) => (e) => this.setState({ [field]: e.target.value })}
            actions={{
              default: (plan) => this.setState({ stage: 'info', selectedPlan: plan }),
              enterprise: () => window.open('https://sportsbox-21571110.hubspotpagebuilder.com/sportsbox-3d-golf-enterprise-form', '_blank'),
            }}
            error={this.state.error}
            loading={this.state.loading}
            success={this.state.success}
            useTabs
            disableBillingOptions
          />}
        {this.state.stage === 'info' && (
          <UserInfo
            setStage={(stage) => this.setState({ stage })}
            handleSubmit={this.handleUserInfoSubmit}
            selectedPlan={this.state.selectedPlan?.key}
          />)}
        {this.state.stage === 'pay' && (
          <PlanPay
            classes={classes}
            plan={this.state.selectedPlan}
            userInfo={this.state.userInfo}
            setLoading={(loading) => this.setState({ loading })}
            handleDone={this.handlePaymentComplete}
          />
        )}
      </div>)
    )
  }
}

SignUpController.propTypes = {
  history: PropTypes.object,
};

export default withRouter(withStyles(useStyles)(SignUpController));
