import palette from "../../theme/palette";
import { createMuiTheme } from "@material-ui/core";

export const useStyles = (theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    marginTop: theme.spacing(4),
  },
  buttonLink: {
    // This is a link component surrounding a button component
    width: "100%",
  },
  linkedButton: {
    // This is a button component surrounded by a link
    margin: theme.spacing(1, 0),
  },
});

export const theme = createMuiTheme({
  typography: {
    h6: {
      fontWeight: 500,
      fontSize: "20px",
      letterSpacing: "-0.05px",
      lineHeight: "20px",
    },
  },
  palette: {
    secondary: {
      main: palette.primary.main,
    },
  },
});

export const tutorialsTableColumns = [
  { field: "title", title: "Title" },
  { field: "tutorialType", title: "Type" },
];

export const tableOptions = {
  // toolbar: false,
  selection: false,
  sorting: false,
  search: false,
  paging: false,
  draggable: false,
  rowStyle: (x) => {
    if (x.tableData.id % 2) {
      return { backgroundColor: "#f2f2f2" };
    }
  },
};

export const practiceGuideTableColumns = [
  { field: "name", title: "Title" },
  { field: "creator.name", title: "Creator" },
  { field: "createdAt", title: "Date" },
  { field: "accessLevel", title: "Access Level" },
];
