import styled from "styled-components";

//main-screen

export const TableSorter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
`;

export const TableSorterItem = styled.div`
  display: flex;
  align-items: center;
  color: gray;
`;

export const TableSorterItemWrapper = styled.div`
  width: ${({ width }) => width};
  cursor: ${({ sortable }) => (sortable ? "pointer" : "auto")};
  display: flex;
  justify-content: start;
  align-items: center;
`;

//modal

export const ModalContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 20px;
  border-bottom: gray 1px solid;
`;

export const ModalContentFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px;
  background: #fff;
  border-top: gray 1px solid;
`;

export const ModalContentTitle = styled.div`
  font-weight: 700;
  margin: 10px 0;
`;

export const ModalTitle = styled.div`
  font-weight: 700;
  font-size: 30px;
`;

export const MediaNotUploaded = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(160, 42, 238, 0.2);
  border: #a02aee 1px dashed;
  height: ${({ height = "100px" }) => height};
`;

export const MediaUploadButton = styled.label`
  border: #a02aee 1px solid;
  color: #a02aee;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
  letter-spacing: 0.115em;
  text-transform: uppercase;
`;

export const UpdateImageButton = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  /* z-index: 100; */
`;

export const DeleteImageButton = styled.div`
  position: absolute;
  bottom: 10px;
  right: 50px;
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  /* z-index: 100; */
`;

export const HiddenFileInput = styled.input`
  display: none;
`;
