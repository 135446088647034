import { Dialog, DialogContent } from "@material-ui/core";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { FlexWrap, Pad } from "../../../../components";
import { hardcodedData } from "../../config";
import { FilledButton } from "../common/styled";
import { ModalTitle } from "../styled";
import { EqupmentItemContent } from "./styled";

export function EquipmentModal({ open, init, onClose, onSave }) {
  const [selected, setSelected] = useState([]);
  useEffect(() => {
    if (init.length !== 0) {
      setSelected(init);
    }
  }, [init]);
  const EquipmentRadio = ({ active }) => {
    return (
      <>
        <div
          style={{
            border: "2px solid #580CE3",
            borderRadius: "50%",
            padding: "2px",
            width: "20px",
            height: "20px",
          }}
        >
          <div
            style={{
              background: active ? "#580CE3" : "#FFF",
              borderRadius: "50%",
              width: "12px",
              height: "12px",
            }}
          />
        </div>
      </>
    );
  };

  const handleSelect = (id) => {
    if (selected.includes(id)) {
      setSelected((prev) => prev.filter((el) => el !== id));
    } else {
      setSelected((prev) => [...prev, id]);
    }
  };

  const test = [
    { name: "Driver", id: "driver" },
    { name: "6 Iron", id: "sixIron" },
    { name: "Ball", id: "ball" },
    { name: "Towel", id: "towel" },
    { name: "Guidence Stick", id: "guidenceStick" },
  ];

  const handleSave = () => {
    onClose();
    onSave("equipment", selected);
    setSelected([]);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent style={{ padding: "30px" }}>
        <ModalTitle>Equipment needed</ModalTitle>
        <div>Select from the list below or add custom ones</div>
        <Pad margin="20px 0" width="100%">
          {hardcodedData.equipment.map((el) => (
            <div key={el}>
              <EqupmentItemContent onClick={() => handleSelect(el)}>
                <div>{el}</div>
                <EquipmentRadio active={selected.includes(el)} />
              </EqupmentItemContent>
              <Pad height="10px" />
            </div>
          ))}
        </Pad>
        <FlexWrap justify="space-between">
          <FilledButton onClick={onClose} width="48%" color="#808080">
            cancel
          </FilledButton>
          <FilledButton
            onClick={() => handleSave()}
            width="48%"
            color="#580CE3"
          >
            add
          </FilledButton>
        </FlexWrap>
      </DialogContent>
    </Dialog>
  );
}
