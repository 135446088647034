import styled from "styled-components";

export const ModalTitle = styled.div`
  font-weight: 700;
  font-size: 30px;
`;

export const TagsModalItem = styled.div`
  padding: 10px;
  border: 1px solid #c9c9c9;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
`;

export const FilledButton = styled.button`
  background-color: ${({ color }) => color};
  border: ${({ color }) => color} 1px solid;
  border-radius: 4px;
  padding: 12px 16px;
  color: #fff;
  text-transform: uppercase;
  outline: none;
  cursor: pointer;
  ${({ width }) => `width: ${width};`}
  &:hover:enabled {
    background-color: #fff;
    color: ${({ color }) => color};
  }
  &:focus:enabled {
    background-color: #fff;
    color: ${({ color }) => color};
  }
  &:disabled {
    cursor: auto;
    opacity: 0.5;
  }
`;
