import React, { useState } from 'react';

import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid, TextField, FormControlLabel, Checkbox, Link } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Swal from 'sweetalert2';

import firebase, { functionBaseUrl, axiosWithToken } from '../../../common/firebase';

function AddEnterpriseInstructorComponent({ open, close }) {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dob, setDOB] = useState('');
  const [agree, setAgree] = useState(false);
  const [isUserExists, setIsUserExists] = useState(false);

  const handleEmail = async (e) => {
    setEmail(e.target.value);
    setIsUserExists(false);
    const userSnap = await firebase.firestore().collection('users').where('email', '==', e.target.value).get();
    userSnap.forEach(snap => {
      const user = snap.data();
      setFirstName(user.firstName);
      setLastName(user.lastName);
      let [month, day, year] = user.dob.split('/');
      setDOB(`${year}-${month}-${day}`);
      setIsUserExists(true);
    });
  }
  const handleFirstName = (e) => {
    setFirstName(e.target.value);
  }
  const handleLastName = (e) => {
    setLastName(e.target.value);
  }
  const handleDOB = (e) => {
    setDOB(e.target.value.substring(0, 10));
  }
  const handleAgree = (e) => {
    setAgree(!agree);
  }
  const dobFormat = (dob) => {
    let [year, month, day] = dob.split('-');
    return (month + "/" + day + "/" + year)
  }
  const handleCreate = async () => {
    if (!isUserExists) {
      if (!email || !firstName || !lastName || !dob) {
        close();
        Swal.fire('<p style="font-size:70%;">Please fill in all required fields</p>');
        return;
      }
      if (dob.length > 10) {
        close();
        Swal.fire('<p style="font-size:70%;">Incorrect date of birth</p>');
        return;
      }
    }
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const date = new Date(new Date().setFullYear(new Date().getFullYear() - 13)).toLocaleDateString('en-ZA', options).replaceAll('/', '-');
    if (Date.parse(dob) > Date.parse(date)) {
      close();
      Swal.fire({
        title: '<p style="font-size:70%;">Sorry, this user is not eligible for a Sportsbox.ai account at this time.</p>',
        confirmButtonText: 'Ok',
        icon: 'warning',
        allowOutsideClick: false
      })
        .then(() => {
          window.location.reload();
        })
      return false;
    }
    try {
      const firebaseUser = firebase.auth().currentUser;
      const uid = firebaseUser.uid;
      const user = await firebase.firestore().collection('users').doc(uid).get();
      const userData = user.data();
      await axiosWithToken(`${functionBaseUrl}/api/usersEnterprise/instructors`, {
        method: 'post',
        data: {
          email: email.toLowerCase().replace(/ /g, ''),
          firstName: firstName,
          lastName: lastName,
          enterpriseAccountId: userData.enterpriseAccountId,
          dob: dobFormat(dob),
          uid,
        }
      })
        .then((response) => {
          close();
          if (response.status === 201) {
            Swal.fire({
              title: '<p style="font-size:70%;">' + response.data.message + '</p>',
              icon: 'warning',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              customClass: {
                container: 'my-swal'
              },
            })
          } else {
            Swal.fire({
              title: '<p style="font-size:70%;">Account successfully created for ' + email + ' and the password is ' + response.data.password + '</p>',
              icon: 'success',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              customClass: {
                container: 'my-swal'
              },
            })
          }
        })
    } catch (e) {
      close();
      Swal.fire({
        title: '<p style="font-size:70%;">Error creating account, please try again</p>',
        icon: 'error',
        confirmButtonText: 'Ok',
        allowOutsideClick: false,
        customClass: {
          container: 'my-swal'
        },
      })
    }
  }

  return (<>
    <Dialog
      fullWidth
      open={open}
      onClose={close}
      aria-labelledby='form-dialog-title'>
      <DialogTitle id={'form-dialog-title'}>
        <div>
          Add instructor
          <CloseIcon style={{ float: 'right', marginTop: '-10px', marginRight: '-15px' }} onClick={close} />
        </div>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <TextField
              autoComplete="email"
              autoFocus
              fullWidth
              required
              label="Email Address"
              onChange={handleEmail}
              value={email}
              variant="outlined"
              inputProps={{ maxLength: 254 }}
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <TextField
              fullWidth
              required
              label="First Name"
              onChange={handleFirstName}
              value={firstName}
              variant="outlined"
              disabled={isUserExists}
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <TextField
              fullWidth
              required
              label="Last Name"
              onChange={handleLastName}
              value={lastName}
              variant="outlined"
              disabled={isUserExists}
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              required
              fullWidth
              variant="outlined"
              id="date"
              label="Date of Birth"
              onChange={handleDOB}
              type="date"
              value={dob}
              disabled={isUserExists}
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  value={agree}
                  onClick={handleAgree}
                  checked={agree ? 'checked' : null}
                />
              }
              label={
                <div>
                  <span>I have read and agree to Sportsbox's </span>
                  <Link onClick={(e) => {
                    e.preventDefault();
                    window.open(window.location.href.replace('enterprise/instructors', 'terms-of-service'))
                  }}>Terms of Service
                  </Link>
                  <span> and </span>
                  <Link onClick={(e) => {
                    e.preventDefault();
                    window.open(window.location.href.replace('enterprise/instructors', 'privacy'))
                  }}>Privacy Policy
                  </Link>
                </div>
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreate}
          disabled={agree === false}
        >
          CREATE
        </Button>
      </DialogActions>
    </Dialog>
  </>);
}

export const AddEnterpriseInstructor = AddEnterpriseInstructorComponent;