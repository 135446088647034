import React, {Component} from 'react';
import firebase from '../../common/firebase'
import PropTypes from 'prop-types';
import {Grid, Container, Avatar, Typography, CssBaseline, Tabs, Modal, Dialog,/*Stack,*/
Tab, Box, FormControl, InputLabel, Select, MenuItem, CardHeader, Button} from '@material-ui/core';
import {functionBaseUrl, axiosWithToken} from '../../common/firebase';
import StudyIcon from '@material-ui/icons/School';
import {AccountNotFound} from '../account/components';
import {withStyles} from '@material-ui/styles';
import Swal from 'sweetalert2';
import NoSubscriptionDialog from '../../components/NoSubscriptionDialog';
//import './learning.css';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const useStyles = theme => ({
  paper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    marginTop: theme.spacing(4),
  },
  buttonLink: {  // This is a link component surrounding a button component
    width: '60px',
    maxWidth: '20%'
  },
  linkedButton: {  // This is a button component surrounded by a link
    margin: theme.spacing(1, 0),
  },
  centeredText: {
    textAlign: 'center',
  }
});
class Learning extends Component {
  constructor(props) {
    const firebaseUser = firebase.auth().currentUser;
    super(props)
    this.state = {
      uid : firebaseUser ? firebaseUser.uid : "",
      error : "",
      loading : true,
      success : false,
      role: '',
      subData: {},
      value: 0,
      tracker: '',
      position: '',
      open: false,
      noSubscription: false,
      activeSub: true,
      isStaffEnterprise: false,
      isCertified: true,
      onboardingCompleted: true,
      dialogLoading: true,
      subscriptionType: ''
    }
  }

  componentDidMount() {
    const user = firebase.auth().currentUser;
    if (!user) {   
      this.setState({error : "Please log in to access Learning page"});
      this.setState({loading : false});
    }
    else {
      axiosWithToken(functionBaseUrl+'/api/users/' + this.state.uid, {
        method: 'get',
      })
      .then(async response => {
        this.setState({ role: response.data.role, subscriptionType: response.data.subscriptionType });
        if (!response.data.subscriptionType.includes('free')) {
          this.subscriptionStatus()
        }

        if (response.data.subscriptionType.includes('enterprise') && response.data.enterpriseAccountId) {
          const entUser = await firebase.firestore().collection("enterpriseAccounts").doc(response.data.enterpriseAccountId).get();
          const entData = entUser.data();
          /*if (entData && 'autoRenewal' in entData && entData.autoRenewal === false) {
            this.setState({ isStaffEnterprise: true })
            await axiosWithToken(functionBaseUrl + '/api/teachableUser/' + this.state.uid, {
              method: 'GET',
            })
              .catch(err => {
                console.log(err)
              });
          }
          if (response.data.certification && (response.data.certification.includes('Sportsbox') || response.data.certification.includes('Sportsbox 3D Baseball Level 1 Certification'))) {
            this.setState({ isCertified: true })
          } else {
            this.setState({ isCertified: false })
          }
          if ('onboardingCompleted' in entData) {
            this.setState({ onboardingCompleted: entData.onboardingCompleted })
          }*/
        }
        this.setState({ dialogLoading: false })
      })
      .catch(err => {
        console.log(err)
        this.setState({loading: false, error : "There was an error retrieving your info"});
      });
    }
  }

  subscriptionStatus = () => {
    axiosWithToken(functionBaseUrl+'/api/verifySub/' + this.state.uid, {
      method: 'post'
    }).then(response => {
      this.setState({
        subData: response.data.data
      })
      if (this.state.role !== 'admin' && 'success' in response.data) {
        this.setState({ activeSub: response.data.success });
      }
    })
    .catch(err => {
      console.log(err)
    });
  }

  handleChange = (prop) => (event) => {
    this.setState({[prop]: event.target.value});
  }

  handleMenuChange = (event) => {
    this.setState({      
      [event.target.name]: event.target.value
    });    
  }

  handleOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  trackerButton = () => {
    let imageSrc = ""
    if(this.state.tracker === 'turn') {
      if(this.state.position === 'address') {
        imageSrc = "/images/TrackerRanges/Tracker Ranges_102021_vFinal-01.jpg"
      }
      if(this.state.position === 'bch') {
        imageSrc = "/images/TrackerRanges/Tracker Ranges_102021_vFinal-02.jpg"
      }
      if(this.state.position === 'top') {
        imageSrc = "/images/TrackerRanges/Tracker Ranges_102021_vFinal-03.jpg"
      }
      if(this.state.position === 'dch') {
        imageSrc = "/images/TrackerRanges/Tracker Ranges_102021_vFinal-04.jpg"
      }
      if(this.state.position === 'impact') {
        imageSrc = "/images/TrackerRanges/Tracker Ranges_102021_vFinal-05.jpg"
      }
      if(this.state.position === 'ftch') {
        imageSrc = "/images/TrackerRanges/Tracker Ranges_102021_vFinal-06.jpg"
      }
      if(this.state.position === 'finish') {
        imageSrc = "/images/TrackerRanges/Tracker Ranges_102021_vFinal-07.jpg"
      }
    }

    if(this.state.tracker === 'sideBend') {
      if(this.state.position === 'address') {
        imageSrc = "/images/TrackerRanges/Tracker Ranges_102021_vFinal-08.jpg"
      }
      if(this.state.position === 'bch') {
        imageSrc = "/images/TrackerRanges/Tracker Ranges_102021_vFinal-09.jpg"
      }
      if(this.state.position === 'top') {
        imageSrc = "/images/TrackerRanges/Tracker Ranges_102021_vFinal-10.jpg"
      }
      if(this.state.position === 'dch') {
        imageSrc = "/images/TrackerRanges/Tracker Ranges_102021_vFinal-11.jpg"
      }
      if(this.state.position === 'impact') {
        imageSrc = "/images/TrackerRanges/Tracker Ranges_102021_vFinal-12.jpg"
      }
      if(this.state.position === 'ftch') {
        imageSrc = "/images/TrackerRanges/Tracker Ranges_102021_vFinal-13.jpg"
      }
      if(this.state.position === 'finish') {
        imageSrc = "/images/TrackerRanges/Tracker Ranges_102021_vFinal-14.jpg"
      }
    }

    if(this.state.tracker === 'bend') {
      if(this.state.position === 'address') {
        imageSrc = "/images/TrackerRanges/Tracker Ranges_102021_vFinal-15.jpg"
      }
      if(this.state.position === 'bch') {
        imageSrc = "/images/TrackerRanges/Tracker Ranges_102021_vFinal-16.jpg"
      }
      if(this.state.position === 'top') {
        imageSrc = "/images/TrackerRanges/Tracker Ranges_102021_vFinal-17.jpg"
      }
      if(this.state.position === 'dch') {
        imageSrc = "/images/TrackerRanges/Tracker Ranges_102021_vFinal-18.jpg"
      }
      if(this.state.position === 'impact') {
        imageSrc = "/images/TrackerRanges/Tracker Ranges_102021_vFinal-19.jpg"
      }
      if(this.state.position === 'ftch') {
        imageSrc = "/images/TrackerRanges/Tracker Ranges_102021_vFinal-20.jpg"
      }
      if(this.state.position === 'finish') {
        imageSrc = "/images/TrackerRanges/Tracker Ranges_102021_vFinal-21.jpg"
      }
    }

    if(this.state.tracker === 'flex') {
      if(this.state.position === 'address') {
        imageSrc = "/images/TrackerRanges/Tracker Ranges_102021_vFinal-22.jpg"
      }
      if(this.state.position === 'bch') {
        imageSrc = "/images/TrackerRanges/Tracker Ranges_102021_vFinal-23.jpg"
      }
      if(this.state.position === 'top') {
        imageSrc = "/images/TrackerRanges/Tracker Ranges_102021_vFinal-24.jpg"
      }
      if(this.state.position === 'dch') {
        imageSrc = "/images/TrackerRanges/Tracker Ranges_102021_vFinal-25.jpg"
      }
      if(this.state.position === 'impact') {
        imageSrc = "/images/TrackerRanges/Tracker Ranges_102021_vFinal-26.jpg"
      }
      if(this.state.position === 'ftch') {
        imageSrc = "/images/TrackerRanges/Tracker Ranges_102021_vFinal-27.jpg"
      }
      if(this.state.position === 'finish') {
        imageSrc = "/images/TrackerRanges/Tracker Ranges_102021_vFinal-28.jpg"
      }
    }

    if(this.state.tracker === 'lift') {
      if(this.state.position === 'bch') {
        imageSrc = "/images/TrackerRanges/Tracker Ranges_102021_vFinal-29.jpg"
      }
      if(this.state.position === 'top') {
        imageSrc = "/images/TrackerRanges/Tracker Ranges_102021_vFinal-30.jpg"
      }
      if(this.state.position === 'dch') {
        imageSrc = "/images/TrackerRanges/Tracker Ranges_102021_vFinal-31.jpg"
      }
      if(this.state.position === 'impact') {
        imageSrc = "/images/TrackerRanges/Tracker Ranges_102021_vFinal-32.jpg"
      }
      if(this.state.position === 'ftch') {
        imageSrc = "/images/TrackerRanges/Tracker Ranges_102021_vFinal-33.jpg"
      }
      if(this.state.position === 'finish') {
        imageSrc = "/images/TrackerRanges/Tracker Ranges_102021_vFinal-34.jpg"
      }
    }

    if(this.state.tracker === 'sway') {
      if(this.state.position === 'bch') {
        imageSrc = "/images/TrackerRanges/Tracker Ranges_102021_vFinal-35.jpg"
      }
      if(this.state.position === 'top') {
        imageSrc = "/images/TrackerRanges/Tracker Ranges_102021_vFinal-36.jpg"
      }
      if(this.state.position === 'dch') {
        imageSrc = "/images/TrackerRanges/Tracker Ranges_102021_vFinal-37.jpg"
      }
      if(this.state.position === 'impact') {
        imageSrc = "/images/TrackerRanges/Tracker Ranges_102021_vFinal-38.jpg"
      }
      if(this.state.position === 'ftch') {
        imageSrc = "/images/TrackerRanges/Tracker Ranges_102021_vFinal-39.jpg"
      }
      if(this.state.position === 'finish') {
        imageSrc = "/images/TrackerRanges/Tracker Ranges_102021_vFinal-40.jpg"
      }
    }

    if(this.state.value === 0 && this.state.tracker !== undefined && this.state.position !== undefined && this.state.tracker.length > 0 && this.state.position.length > 0) {
      if(this.state.tracker === 'sway' && this.state.position === 'address') {
        Swal.fire('<p style="font-size:70%;">Sway data is not available for address position.</p>')
        return false
      }
      if(this.state.tracker === 'lift' && this.state.position === 'address') {
        Swal.fire('<p style="font-size:70%;">Lift data is not available for address position.</p>')
        return false
      }
      return(
        <div className={this.props.classes.paper}>
          <img src={imageSrc} alt="tracker" width={"100%"}/>
        </div>
      )
    }
  }

  handleVideo = (tracker) => {
    if (window.matchMedia('(max-width: 700px)').matches) {
      Swal.fire({
        showConfirmButton: false,
        html: '<iframe width="100%" height="200" src="/images/' + tracker + '.mp4" scrolling="no" controlsList="nodownload" controls="controls" allowfullscreen preload="metadata" autoplay playsInline type="video/mp4" />',
        position: 'center',
        width: '100%',
        showCloseButton: true
      })
    } else {
      Swal.fire({
        showConfirmButton: false,
        html: '<iframe width="100%" height="500" src="/images/' + tracker + '.mp4" scrolling="no" controlsList="nodownload" controls="controls" allowfullscreen preload="metadata" autoplay playsInline type="video/mp4" />',
        position: 'center',
        width: '60%',
        showCloseButton: true
      })
    }
  }

  handleTrackerDef = (tracker) => {
    if (window.matchMedia('(max-width: 700px)').matches) {
      Swal.fire({
        showConfirmButton: false,
        html: '<div className={this.props.classes.paper}><img width="100%" height="200" src="/images/TrackerDefinitions/Tracker_Definitions_' + tracker + '.jpg" alt="tracker"/></div>',
        position: 'center',
        width: '100%',
        showCloseButton: true
      })
    } else {
      Swal.fire({
        showConfirmButton: false,
        html: '<div className={this.props.classes.paper}><img width="100%" height="500" src="/images/TrackerDefinitions/Tracker_Definitions_' + tracker + '.jpg" alt="tracker"/></div>',
        position: 'center',
        width: '60%',
        showCloseButton: true
      })
    }
  }

  handleWebinar = (tracker) => {
    if (window.matchMedia('(max-width: 700px)').matches) {
      Swal.fire({
        showConfirmButton: false,
        html: tracker === 'comingSoon' ? '<p>Coming soon - April 19, 2022</>' : '<iframe width="100%" height="200" src="' + tracker + '" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
        position: 'center',
        width: '100%',
        showCloseButton: true
      })
    } else {
      Swal.fire({
        showConfirmButton: false,
        html: tracker === 'comingSoon' ? '<p>Coming soon - April 19, 2022</>' : '<iframe width="100%" height="500" src="' + tracker + '" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
        position: 'center',
        width: '60%',
        showCloseButton: true
      })
    }
  }

  render() {
    return (
      <Container
        component="main"
        className={this.props.classes.paper}
      >
        <CssBaseline />
        <div className={this.props.classes.paper}>
          <Avatar className={this.props.classes.avatar}>
              <StudyIcon/>
          </Avatar>
          <Typography
              component="h1"
              variant="h4"
              style={{marginBottom: '10px'}}
          >
              LEARNING
          </Typography>
          <Box style={{marginBottom: '2%'}} sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <Tabs value={this.state.value} onChange={this.handleChange('value')} centered /*variant="fullWidth"*/ textColor="secondary" indicatorColor="secondary">
              <Tab label="TRACKER RANGES" onClick={() => this.setState({ value: 0 })}/>
              <Tab label="BIOMECHANICS OF GOLF" onClick={() => this.setState({ value: 1 })}/>
            </Tabs>
          </Box>
          {(this.state.value === 0 ?
            <div>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12} spacing={2}>
                <Typography
                  component="h6"
                  variant="h5"
                  justify="center"
                  align="center"
                >
                  Below we provide the average, minimum, and maximum range of many of the key Sportsbox 3D<br />
                  Baseball trackers at 7 key swing positions, from male and female professional golfers.<br /> 
                  Please select the tracker type and the swing position to see the tracker range data:
                </Typography>
              </Grid>

              <Grid item md={12} xs={12} spacing={2}>
                <FormControl       
                variant="outlined"
                fullWidth
                style={{maxWidth: "50%"}}
                align="center"
                margin="dense"
                >
                  <InputLabel id="gender-select">Tracker</InputLabel>
                  <Select
                    id="tracker"
                    label="Tracker"
                    labelId="tracker-select"
                    name="tracker"
                    value={this.state.tracker}
                    onChange={this.handleMenuChange}
                  >
                    <MenuItem value={undefined}>
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={'turn'}>Turn</MenuItem>
                    <MenuItem value={'sideBend'}>Side Bend</MenuItem>
                    <MenuItem value={'bend'}>Bend</MenuItem>
                    <MenuItem value={'flex'}>Flex</MenuItem>
                    <MenuItem value={'lift'}>Lift</MenuItem>
                    <MenuItem value={'sway'}>Sway</MenuItem>
                  </Select>
                </FormControl>

                <FormControl              
                variant="outlined"
                fullWidth
                style={{maxWidth: "50%"}}
                align="center"
                margin="dense"
                >
                  <InputLabel id="position-select">Position</InputLabel>
                  <Select
                    id="position"
                    label="Position"
                    labelId="position-select"
                    name="position"
                    value={this.state.position}
                    onChange={this.handleMenuChange}
                  >
                    <MenuItem value={undefined}>
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={'address'}>Address</MenuItem>
                    <MenuItem value={'bch'}>Backswing Club Horizontal</MenuItem>
                    <MenuItem value={'top'}>Top</MenuItem>
                    <MenuItem value={'dch'}>Downswing Club Horizontal</MenuItem>
                    <MenuItem value={'impact'}>Impact</MenuItem>
                    <MenuItem value={'ftch'}>Follow-Through Club Horizontal</MenuItem>
                    <MenuItem value={'finish'}>Finish</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            </div>
            :
            <div>
              <Grid
              container
              >
                <CardHeader
                  subheader="Angular Movements"
                  title="LEVEL 1: UNDERSTANDING TRACKER DEFINITIONS"
                  titleTypographyProps={{variant:"h4", color:"primary"}}
                />
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Button fullWidth variant="contained" color="primary" onClick={() => this.handleTrackerDef('turn')}>Turn</Button>
                </Grid>
                <Grid item xs={6}>
                  <Button fullWidth variant="contained" color="primary" onClick={() => this.handleTrackerDef('bend')}>Bend</Button>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Button fullWidth variant="contained" color="primary" onClick={() => this.handleTrackerDef('sideBend')}>Side Bend</Button>
                </Grid>
                {/*<Grid item xs={6}>
                  <Button fullWidth variant="contained" color="primary">Flex</Button>
          </Grid>*/}
              </Grid>
              <Grid
              container
              >
                <CardHeader
                  subheader="Linear Movements"
                  titleTypographyProps={{variant:"h4", color:"primary"}}
                />
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Button fullWidth variant="contained" color="primary" onClick={() => this.handleTrackerDef('sway')}>Sway</Button>
                </Grid>
                <Grid item xs={6}>
                  <Button fullWidth variant="contained" color="primary" onClick={() => this.handleTrackerDef('lift')}>Lift</Button>
                </Grid>
              </Grid>

              <Grid
              container
              >
                <CardHeader
                  subheader="Quick Hits"
                  title="LEVEL 2: TRACKER TRUTHS"
                  titleTypographyProps={{variant:"h4", color:"primary"}}
                />
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Button fullWidth variant="contained" color="primary" onClick={() => this.handleVideo('chestSideBend')}>Chest Side Bend</Button>
                </Grid>
                <Grid item xs={6}>
                  <Button fullWidth variant="contained" color="primary" onClick={() => this.handleVideo('chestSway')}>Chest Sway</Button>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
              <Grid item xs={6}>
                  <Button fullWidth variant="contained" color="primary" onClick={() => this.handleVideo('pelvisLift')}>Pelvis Lift</Button>
                </Grid>
                <Grid item xs={6}>
                  <Button fullWidth variant="contained" color="primary" onClick={() => this.handleVideo('pelvisSideBend')}>Pelvis Side Bend</Button>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
              <Grid item xs={6}>
                  <Button fullWidth variant="contained" color="primary" onClick={() => this.handleVideo('pelvisSway')}>Pelvis Sway</Button>
                </Grid>
                <Grid item xs={6}>
                  <Button fullWidth variant="contained" color="primary" onClick={() => this.handleVideo('pelvisTurn')}>Pelvis Turn</Button>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
              <Grid item xs={6}>
                  <Button fullWidth variant="contained" color="primary" onClick={() => this.handleVideo('shoulderTurn')}>Shoulder Turn</Button>
                </Grid>
                <Grid item xs={6}>
                  <Button fullWidth variant="contained" color="primary" onClick={() => this.handleVideo('xFactor')}>X-Factor</Button>
                </Grid>
              </Grid>
              <Grid
              container
              >
                <CardHeader
                  subheader="Webinars"
                  titleTypographyProps={{variant:"h4", color:"primary"}}
                />
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Button fullWidth variant="contained" color="primary" onClick={() => this.handleWebinar('//www.youtube.com/embed/R-M8nRwnBaI?start=276')}>Webinar #1</Button>
                </Grid>
                <Grid item xs={6}>
                  <Button fullWidth variant="contained" color="primary" onClick={() => this.handleWebinar('//www.youtube.com/embed/xD8qOfjDPls?start=2726')}>Webinar #2</Button>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Button fullWidth variant="contained" color="primary" onClick={() => this.handleWebinar('//www.youtube.com/embed/-UbtLZo2Wos?start=2964')}>Webinar #3</Button>
                </Grid>
                <Grid item xs={6}>
                <Button fullWidth variant="contained" color="primary" onClick={() => this.handleWebinar('//www.youtube.com/embed/dr0ZqpNMgOk?start=5')}>Webinar #4</Button>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Button fullWidth variant="contained" color="primary" onClick={() => this.handleWebinar('//www.youtube.com/embed/0OBgYtt0kfQ?start=2549')}>Webinar #5</Button>
                </Grid>
              </Grid>
            </div>)}
        </div>
        {this.trackerButton()}
        {/* Error screen*/}
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
          style = {{display: (this.state.error && !this.state.loading) ? "block" : "none"}}
        >
          <AccountNotFound            
            error = {{error : this.state.error}}
          />
        </Grid>
        <NoSubscriptionDialog
          activeSub={this.state.activeSub}
          isStaffEnterprise={this.state.isStaffEnterprise}
          isCertified={this.state.isCertified}
          onboardingCompleted={this.state.onboardingCompleted}
          role={this.state.role}
          loading={this.state.dialogLoading}
          subscriptionType={this.state.subscriptionType}
        />
      </Container>
    );
  }
};

Learning.propTypes = {
  classes:PropTypes.object,
  history: PropTypes.object,
};

export default withStyles(useStyles)(Learning);