import React from 'react';
import {
  Typography,
  Box,
  Button,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  Snackbar,
} from '@material-ui/core';
import {
  Timeline,
  TimelineDot,
  TimelineConnector,
  TimelineSeparator,
  TimelineContent,
  TimelineItem
} from '@material-ui/lab';
import MuiAlert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/styles';
import CloseIcon from "@material-ui/icons/Close";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import GolfCourseIcon from '@material-ui/icons/GolfCourse';
import DescriptionIcon from '@material-ui/icons/Description';
import { Link } from 'react-router-dom';
import firebase, { axiosWithToken, functionBaseUrl } from '../../common/firebase';
import NoSubscriptionDialog from '../../components/NoSubscriptionDialog';
import './Dashboard.css';
import NewAccountCheck from './NewAccountCheck';
import moment from 'moment';
import { getPlan } from '../../common/plans';
import BootcampCheck from './BootcampCheck';
import Integration from './Integration';

const useStyles = () => ({
  paper: {
    marginTop: 800,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  page: {
    flexDirection: 'column',
    alignItems: 'center',
  },
});


class Dashboard extends React.Component {
  constructor(props) {
    const firebaseUser = firebase.auth().currentUser
    super(props)

    this.state = {
      uid: firebaseUser.uid,
      email: "",
      firstName: "",
      lastName: "",
      role: "",
      subStatus: "",
      subData: [],
      subscriptionType: "",
      popUpOpen: false,
      invitesReceived: [],
      activeSub: true,
      isStaffEnterprise: false,
      isCertified: true,
      onboardingCompleted: true,
      loading: true,
      userData: {},
      openPlanUpgrade: false,
      openSubscriptionEnds: false,
      openSubscriptionEnded: false,
      openInvoiceDue: false,
      bootcampUser: false,
      bootcampPopUpOpen: false,
      integrationPopUpOpen: false,
    }
  }

  async componentDidMount() {
    if (!this.state.uid) {
      this.setState({ error: "Please log in" })
    }
    else {
      const user = await firebase.firestore().collection('users').doc(this.state.uid).get();
      let data = user.data();
      this.setState({ userData: data, role: data.role, firstName: data.firstName, lastName: data.lastName, dob: data.dob, subscriptionType: data.subscriptionType, email: data.email })

      if (data.subscriptionType.includes('student')) {
        let tempInvites = [];
        const invites = await firebase.firestore().collection('invites').where('invitee', '==', data.email).where('status', '==', 'pending').where('expiredIn', '>=', Date.now()).get();
        invites.forEach(async function (doc) {
          const sender = await firebase.firestore().collection('users').doc(doc.data().senderId).get();
          tempInvites.push({ sender: sender.data(), ...doc.data(), id: doc.id })
        });
        this.setState({ invitesReceived: tempInvites });

        if (window.location.href.toLowerCase().includes('partner=golfgenius')) {
          this.openIntegrationPopUp();
        }
      }

      let tempBuffer = [];
      const bufferSnap = await firebase.firestore().collection('incompleteUsersBuffer').where('userId', '==', this.state.uid).get();
        bufferSnap.forEach(async function (doc) {
          tempBuffer.push({ ...doc.data(), id: doc.id })
        });
        if (tempBuffer.length > 0) {
          if ('bootcampUser' in tempBuffer[0]) {
            this.setState({ bootcampUser: true })
            this.openBootcampPopUp()
          } else {
            this.openPopUp()
          }
        }

      if (!data.subscriptionType.includes('free')) {
        await axiosWithToken(functionBaseUrl + '/api/verifySub/' + this.state.uid, {
          method: 'post',
        }).then(response => {
          this.setState({ subStatus: response.data.success, subData: response.data.data })
          if (this.state.role !== 'admin' || 'success' in response.data) {
            this.setState({ activeSub: response.data.success });
            if (window.location.href.toLowerCase().includes('partner=golfgenius')) {
              this.openIntegrationPopUp();
            }
          }
          if (!response.data.success && response.data.data?.plan) {
            this.setState({ openSubscriptionEnded: true });
          }
          if (!response.data.success && response.data.data?.plan && moment(response.data.data.endDate).diff(moment(), 'days') < 7) {
            this.setState({ openSubscriptionEnds: true });
          }
          if (tempBuffer.length === 0 && response.data.success && (response.data.data?.plan?.includes('lite') || response.data.data?.plan?.includes('free'))) {
            this.setState({ openPlanUpgrade: true });
          }
        })
      }

      if (data.subscriptionType.includes('enterprise') && data.enterpriseAccountId) {
        await axiosWithToken(functionBaseUrl + '/api/verifyStaffEnt/' + this.state.uid, {
          method: 'POST',
        })
          .then(async (res) => {
            this.setState({ isStaffEnterprise: res.data.isStaffEnterprise, isCertified: res.data.isCertified, onboardingCompleted: res.data.onboardingCompleted, loading: false })
          })
          .catch(err => {
            console.log(err)
          });

        axiosWithToken(`${functionBaseUrl}/api/invoiceLast`, {
          method: 'post',
          data: { userId: this.state.uid },
        }).then(response => {
          if (response.data.status === 'Unpaid' && this.state.activeSub) {
            this.setState({ openInvoiceDue: true, invoiceDue: response.data.period });
          }
        });
      }
      this.setState({ loading: false })
    }
  }

  openPopUp = () => {
    this.setState({ popUpOpen: true });
  }

  closePopUp = () => {
    this.setState({ popUpOpen: false });
  }

  openBootcampPopUp = () => {
    this.setState({ bootcampPopUpOpen: true });
  }

  closeBootcampPopUp = () => {
    this.setState({ bootcampPopUpOpen: false });
    window.location.reload();
  }

  openIntegrationPopUp = () => {
    this.setState({ integrationPopUpOpen: true });
  }

  closeIntegrationPopUp = () => {
    this.setState({ integrationPopUpOpen: false });
    //window.location.reload();
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.page}>
        <div className={classes.paper} style={{ marginTop: 80, marginBottom: 20 }}>
          <img alt="Logo" src="/images/logos/sportsbox_icon.png" width={50}/>
        </div>
        <div className={classes.paper} style={{ marginTop: 20, marginBottom: 30 }}>
          <Typography component="h1" variant="h1" >
            <Box fontFamily="Anton" className="dashboard-title">
              {`Welcome to the SPORTSBOX 3D Baseball App!`}
            </Box>
          </Typography>
        </div>
        <div style={{ minWidth: "50%", paddingBottom: "25%" }}>
          <Timeline align="alternate">
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="primary">
                  <GolfCourseIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} className="dashboard-timeline-item">
                  <Typography variant="h5" component="h1" fontFamily="League Spartan">
                    Record
                  </Typography>
                  <Typography fontFamily="Lato">baseball session swings</Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="inherit">
                  <Link to='/session-details'>
                    <DescriptionIcon color="secondary" />
                  </Link>
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} className="dashboard-timeline-item" style={{ marginLeft: "auto" }}>
                  <Typography variant="h5" component="h1" fontFamily="League Spartan">
                    Create
                  </Typography>
                  <Typography fontFamily="Lato">your online session</Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="inherit">
                  <Link to='/video-upload'>
                    <CloudUploadIcon color="secondary" />
                  </Link>
                </TimelineDot>
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} className="dashboard-timeline-item">
                  <Typography variant="h5" component="h1" fontFamily="League Spartan">
                    Upload
                  </Typography>
                  <Typography fontFamily="Lato">your video for use</Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        </div>
        {'userConsent' in this.state.userData && (
          <NewAccountCheck
            open={this.state.popUpOpen}
            onClose={this.closePopUp}
            invitesReceived={this.state.invitesReceived}
            subscriptionType={this.state.subscriptionType}
            name={this.state.userName}
            firstName={this.state.firstName}
            lastName={this.state.lastName}
            email={this.state.email}
            uid={this.state.uid}
            subData={this.state.subData}
            userData={this.state.userData}
          />
        )}
        {this.state.bootcampUser && <BootcampCheck
          open={this.state.bootcampPopUpOpen}
          onClose={this.closeBootcampPopUp}
          subscriptionType={this.state.subscriptionType}
          name={this.state.userName}
          firstName={this.state.firstName}
          lastName={this.state.lastName}
          email={this.state.email}
          uid={this.state.uid}
          subData={this.state.subData}
          userData={this.state.userData}
        />}
        {this.state.integrationPopUpOpen && <Integration 
          open={this.state.integrationPopUpOpen}
          onClose={this.closeIntegrationPopUp}
          subscriptionType={this.state.subscriptionType}
          uid={this.state.uid}
        />}
        {!this.state.popUpOpen && !this.state.bootcampPopUpOpen && !this.state.open && <NoSubscriptionDialog
          activeSub={this.state.activeSub}
          isStaffEnterprise={this.state.isStaffEnterprise}
          isCertified={this.state.isCertified}
          onboardingCompleted={this.state.onboardingCompleted}
          role={this.state.role}
          loading={this.state.loading}
          subscriptionType={this.state.subscriptionType}
        />}
        <Snackbar open={this.state.openPlanUpgrade} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} style={{ top: "10%" }}>
          <MuiAlert severity="info" elevation={6} variant="filled">
            Current plan: {getPlan(this.state.subData?.plan)}&nbsp;&nbsp;&nbsp;
            <Button variant="contained" color="secondary" size="small" onClick={() => this.props.history.push('/plans')}>
              UPGRADE
            </Button>
          </MuiAlert>
        </Snackbar>
        {this.state.isStaffEnterprise && this.state.isCertified && this.state.onboardingCompleted && (<Snackbar open={this.state.openInvoiceDue} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} style={{ top: "10%" }}>
          <MuiAlert severity="warning" elevation={6} variant="filled">
            Invoice Due: {moment(String(this.state.invoiceDue), "x").format('MM/DD/YYYY')}&nbsp;&nbsp;&nbsp;
            <Button variant="contained" color="secondary" size="small" onClick={() => this.props.history.push('/invoices/detail/last')}>
              PAY INVOICE
            </Button>
          </MuiAlert>
        </Snackbar>)}
        {this.state.subData?.endDate > 0 && (
          <div>
            <Snackbar open={this.state.openSubscriptionEnds} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} style={{ top: "10%" }}>
              <MuiAlert severity="warning" elevation={6} variant="filled">
                Subscription Ends: {moment(String(this.state.subData?.endDate), "x").format('MM/DD/YYYY')}&nbsp;&nbsp;&nbsp;
                <Button variant="contained" color="secondary" size="small" onClick={() => this.props.history.push('/plans')}>
                  RENEW
                </Button>
              </MuiAlert>
            </Snackbar>
            <Snackbar open={this.state.openSubscriptionEnded} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} style={{ top: "10%" }}>
              <MuiAlert severity="error" elevation={6} variant="filled">
                Subscription Ended: {moment(String(this.state.subData?.endDate), "x").format('MM/DD/YYYY')}&nbsp;&nbsp;&nbsp;
                <Button variant="contained" color="secondary" size="small" onClick={() => this.props.history.push('/plans')}>
                  RENEW
                </Button>
              </MuiAlert>
            </Snackbar>
          </div>
        )}
      </div>
    );
  }

}

export default withStyles(useStyles)(Dashboard);
