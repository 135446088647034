import React from "react";
import palette from "../../theme/palette";
import { createMuiTheme } from "@material-ui/core";
import { StatusBadge } from "./styled";

export const useStyles = (theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    marginTop: theme.spacing(4),
  },
  buttonLink: {
    // This is a link component surrounding a button component
    width: "100%",
  },
  linkedButton: {
    // This is a button component surrounded by a link
    margin: theme.spacing(1, 0),
  },
});

export const theme = createMuiTheme({
  typography: {
    h6: {
      fontWeight: 500,
      fontSize: "20px",
      letterSpacing: "-0.05px",
      lineHeight: "20px",
    },
  },
  palette: {
    secondary: {
      main: palette.primary.main,
    },
  },
});

export const tutorialsTableColumns = [
  { field: "title", title: "Title" },
  { field: "tutorialType", title: "Type" },
];

export const tableOptions = {
  // toolbar: false,
  selection: false,
  sorting: false,
  search: false,
  paging: false,
  draggable: false,

  rowStyle: (x) => ({
    boxShadow: `0px 1px 4px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.25)`,
    // borderBottom: "10px solid white",
    border: "1px solid black",
  }),
};

export const watchlistIdMapper = (id) => {
  const data = {
    PLVSWYIMP: "Pelvis Sway at Impact",
    CHSTSWYIMP: "Chest Sway at Impact",
    PLVTRNTOP: "Pelvis Turn at Top",
    CHSTTRNTOP: "Chest Turn at Top",
    PLVTRNADR: "Pelvis Turn at Address",
    CHSTTRNADR: "Chest Turn at Address",
    PLVSWYTOP: "Pelvis Sway at Top",
    CHSTSWYTOP: "Chest Sway at Top",
    PLVTRNIMP: "Pelvis Turn at Impact",
    CHSTTRNIMP: "Chest Turn at Impact",
    CHSTBNDTOP: "Chest Bend at Top",
    CHSTLFTTOP: "Chest Lift at Top",
    CHSTSBNDIMP: "Chest Side Bend at Impact",
    CHSTBNDADR: "Chest Bend at Address",
  };
  return data[id];
};

export const hardcodedData = {
  watchlist: [
    {
      name: "Hanging Back",
      description:
        "Not getting over onto lead leg at impact. May cause a slice.",
      trackers: [
        {
          position: "IMP",
          trackerId: "PLVSWYIMP",
          rangeMin: 2.8,
          rangeMax: 5.8,
          trackerUnit: "inches",
        },
        {
          position: "IMP",
          trackerId: "CHSTSWYIMP",
          rangeMin: -2.2,
          rangeMax: 2.0,
          trackerUnit: "inches",
        },
      ],
      createdAt: "",
      updatedAt: "",
      watchlistId: "HANGING_BACK",
    },
    {
      name: "Low Turn at Top",
      description:
        "Chest and pelvis are not turned enough at top of backswing. May cause loss of speed.",
      trackers: [
        {
          position: "TOP",
          trackerId: "PLVTRNTOP",
          rangeMin: -52.5,
          rangeMax: -36.1,
          trackerUnit: "degrees",
        },
        {
          position: "TOP",
          trackerId: "CHSTTRNTOP",
          rangeMin: -102.2,
          rangeMax: -82.6,
          trackerUnit: "degrees",
        },
      ],
      createdAt: "",
      updatedAt: "",
      watchlistId: "LOW_TURN_AT_TOP",
    },
    {
      name: "Aligment at Address",
      description:
        "Chest and pelvis are too open at address. Shot direction may be compromized.",
      trackers: [
        {
          position: "ADR",
          trackerId: "PLVTRNTOP",
          rangeMin: -3.7,
          rangeMax: 7.7,
          trackerUnit: "degrees",
        },
        {
          position: "ADR",
          trackerId: "CHSTTRNADR",
          rangeMin: 5.8,
          rangeMax: 16.6,
          trackerUnit: "degrees",
        },
      ],
      createdAt: "",
      updatedAt: "",
      watchlistId: "ALIGNMENT_AT_ADR",
    },
    {
      name: "Excessive Sway at Top",
      description:
        "Chest and pelvis are swaying too much away from the target at the top of backswing. May cause inconsistent ball contact.",
      trackers: [
        {
          position: "TOP",
          trackerId: "PLVSWYTOP",
          rangeMin: -0.8,
          rangeMax: 1.4,
          trackerUnit: "inches",
        },
        {
          position: "TOP",
          trackerId: "CHSTSWYTOP",
          rangeMin: -1.5,
          rangeMax: 1.1,
          trackerUnit: "inches",
        },
      ],
      createdAt: "",
      updatedAt: "",
      watchlistId: "EX_SWAY_AT_TOP",
    },
    {
      name: "Excessive Slide in Downswing",
      description:
        "Chest and pelvis are sliding toward the target too much in the downswing. This may cause inconsistent ball contact.",
      trackers: [
        {
          position: "IMP",
          trackerId: "PLVSWYIMP",
          rangeMin: 2.8,
          rangeMax: 5.8,
          trackerUnit: "inches",
        },
        {
          position: "IMP",
          trackerId: "CHSTSWYIMP",
          rangeMin: -2.2,
          rangeMax: 2.0,
          trackerUnit: "inches",
        },
      ],
      createdAt: "",
      updatedAt: "",
      watchlistId: "EX_DSWNG_SWY",
    },
    {
      name: "Poor Impact Set Position",
      description:
        "Chest and pelvis are not open enough at impact. Typically this means you are swinging with your arms too much and not using your body enough.",
      trackers: [
        {
          position: "IMP",
          trackerId: "PLVTRNIMP",
          rangeMin: 31.9,
          rangeMax: 50.9,
          trackerUnit: "degrees",
        },
        {
          position: "IMP",
          trackerId: "CHSTTRNIMP",
          rangeMin: 19.1,
          rangeMax: 38.3,
          trackerUnit: "degrees",
        },
      ],
      createdAt: "",
      updatedAt: "",
      watchlistId: "POOR_IMP_SET",
    },
    {
      name: "Reverse Pivot",
      description:
        "Chest and pelvis are not open enough at impact. Typically this means you are swinging with your arms too much and not using your body enough.",
      trackers: [
        {
          position: "TOP",
          trackerId: "PLVSWYTOP",
          rangeMin: -0.8,
          rangeMax: 1.4,
          trackerUnit: "inches",
        },
        {
          position: "TOP",
          trackerId: "CHSTSWYTOP",
          rangeMin: -1.5,
          rangeMax: 1.1,
          trackerUnit: "inches",
        },
      ],
      createdAt: "",
      updatedAt: "",
      watchlistId: "REVERSE_PIVOT",
    },
    {
      name: "Reverse Spine Angle at Top",
      description:
        "Your upper body is leaning backwards a the top of backswing.  This can lead to inconsistent swings and possible back sorness.",
      trackers: [
        {
          position: "TOP",
          trackerId: "CHSTBNDTOP",
          rangeMin: -8.5,
          rangeMax: 8.3,
          trackerUnit: "degrees",
        },
      ],
      createdAt: "",
      updatedAt: "",
      watchlistId: "REV_SPINE",
    },
    {
      name: "Standing Up",
      description:
        "You are standing up in your backswing.  Try and keep your posture and rotate around your spine.",
      trackers: [
        {
          position: "TOP",
          trackerId: "CHSTLFTTOP",
          rangeMin: -1.7,
          rangeMax: 0.1,
          trackerUnit: "inches",
        },
      ],
      createdAt: "",
      updatedAt: "",
      watchlistId: "STANDING_UP",
    },
    {
      name: "Excessive Forward Bend at Address",
      description:
        "You are bending too far forward at address, please stand up a little.  This will free up your swing to rotate well.",
      trackers: [
        {
          position: "ADR",
          trackerId: "CHSTBNDADR",
          rangeMin: 25.3,
          rangeMax: 39.5,
          trackerUnit: "degrees",
        },
      ],
      createdAt: "",
      updatedAt: "",
      watchlistId: "EX_FWD_BND",
    },
    {
      name: "Excessive Side bend at Imp",
      description:
        "You have excessive side bend of your chest at impact. This may result in back soreness.",
      trackers: [
        {
          position: "IMP",
          trackerId: "CHSTSBNDIMP",
          rangeMin: 25.3,
          rangeMax: 37.3,
          trackerUnit: "degrees",
        },
      ],
      createdAt: "",
      updatedAt: "",
      watchlistId: "EX_CHST_SBND_IMP",
    },
  ],
  skill: ["Beginner", "Intermediate", "Advanced"],
  environment: [
    "Range",
    "Indoor Facility",
    "Course",
    "Gym",
    "At home",
    "Anywhere",
  ],
  tags: [
    "featured",
    "slice",
    "hook",
    "speed",
    "turn",
    "sway",
    "setup",
    "contact",
    "pivot",
    "sequence",
    "mobility",
    "strength",
    "swing",
  ],
  goal: [
    "Breaking 100",
    "Breaking 90",
    "Breaking 80",
    "Better contact",
    "More speed",
    "Better path",
    "Club face control",
    "More strength",
    "More mobility",
    "Ball flight control",
    "Peak performance",
  ],
  category: [
    "Warm-up",
    "Getting Started",
    "Shaping shots",
    "Long Drive/Speed",
    "Swing Fix",
    "Wedges",
    "Putting",
    "Fitness",
    "Games/Tests",
  ],
  equipment: [
    "Glove",
    "Golf Balls",
    "Practice Balls",
    "Alignment Stick",
    "2 Alignment Sticks",
    "Chair",
    "Wall",
    "Golf Bag",
    "Medicine ball",
    "Just You!",
    "Towel",
    "Headcover",
    "Two Headcovers",
    "Putter",
    "Wedge",
    "Short Iron",
    "Mid Iron",
    "Long Iron",
    "Fairway Wood",
    "Hybrid",
    "Driver",
    "Whip Trainer",
    "OW Whip Trainer",
    "OW Light Speed",
    "OW Turn Trainer",
    "M3 Jetstick",
    "M3 Speedbomber",
    "M3 Velociraptor",
    "TS Smart Ball",
    "TS Plane Mate",
    "TS Flex Click",
    "LS LagShot 7-iron",
    "LS LagShot Driver",
    "DU Down Under Board",
  ],
  assessment: [
    "Putter",
    "Wedge",
    "Short Iron",
    "Mid Iron",
    "Long Iron",
    "Fairway Wood",
    "Hybrid",
    "Driver",
  ],
};
