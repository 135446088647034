import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Link } from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4),
    },
}));

const Footer = (props) => {
    const { className, ...rest } = props;

    const classes = useStyles();

    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}
        >
            <Grid
                container
            >
                <Grid
                    item
                    sm={6}
                    xs={12}
                >
                    <Typography variant="body1" >
                        &copy; {' '}
                        <Link
                            component="a"
                            href="https://www.sportsbox.ai/"
                            target="_blank"
                        >
                            SPORTSBOX AI
                        </Link>
                        , {moment().year()} (
                        <Link
                            component="a"
                            href="/privacy"
                            target="_blank"
                        >
                            Privacy Policy
                        </Link>
                        {' '} & {' '}
                        <Link
                            component="a"
                            href="/terms-of-service"
                            target="_blank"
                        >
                            Terms of Service
                        </Link>
                        )
                    </Typography>
                </Grid>
                {/*<Grid
                    item
                    sm={6}
                    xs={12}
                >
                    <Typography align="right">
                        v. {process.env.REACT_APP_VERSION}
                    </Typography>
                </Grid>*/}
            </Grid>
        </div>
    );
};

Footer.propTypes = {
    className: PropTypes.string,
};

export default Footer;
