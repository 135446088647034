import admin from "../../../common/firebase";
import { format } from "date-fns";

export const dateMapper = (el) => ({
  ...el,
  createdAt: format(el.createdAt, "MM/dd/yyyy"),
});

export const firestorageExistCheck = (filePath) => {
  return admin
    .storage()
    .refFromURL(filePath)
    .getDownloadURL()
    .then(() => true)
    .catch((err) => {
      switch (err.code) {
        case "storage/object-not-found":
          return false;
      }
    });
};
