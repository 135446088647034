import React, { useEffect, useState } from "react";

import {
    Avatar,
    Button,
    Card,
    CardHeader,
    CardContent,
    Container,
    Divider,
    Grid,
    Typography,
} from "@material-ui/core";
import PeopleIcon from "@material-ui/icons/People";

import firebase, {
    functionBaseUrl,
    axiosWithToken,
} from "../../../common/firebase";
import { AddEnterpriseInstructor } from "./AddEnterpriseInstructor";
import { EnterpriseInstructorsList } from "./EnterpriseInstructorsList";
import Swal from "sweetalert2";

function EnterpriseInstructorsComponent() {
    const [isLoading, setIsLoading] = useState(true);
    const [isAddPopupOpened, setIsAddPopupOpened] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [currentUser, setCurrentUser] = useState([]);

    const openAddPopup = () => {
        if (accounts?.length >= currentUser?.coachesCount) {
            Swal.fire({
                title: '<p style="font-size:70%;">You have reached the maximum limit of Instructor accounts that can be added for your subscription</p>',
                icon: "warning",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                timer: 5000,
            });
            return false;
        }
        setIsAddPopupOpened(true);
    };

    const closeAddPopup = () => {
        setIsAddPopupOpened(false);
        loadData();
    };

    const loadData = async () => {
        setIsLoading(true);
        const { uid } = firebase.auth().currentUser;
        // const uid = firebaseUser.uid;
        const user = await axiosWithToken(
            `${functionBaseUrl}/api/usersEnterprise/${uid}`,
            {
                method: "get",
            }
        );
        setCurrentUser(user.data?.userInfo);
        const currUser = await axiosWithToken(
            `${functionBaseUrl}/api/users/${uid}`,
            {
                method: "get",
            }
        );
        const response = await axiosWithToken(
            `${functionBaseUrl}/api/usersEnterprise/instructors/${currUser?.data.enterpriseAccountId}`,
            {
                method: "get",
            }
        );
        setAccounts(response.data);
        setIsLoading(false);
    };

    useEffect(() => {
        loadData();
    }, []);

    return (
        <>
            <Container component="main" style={{ paddingTop: "20px" }}>
                <Grid container spacing={2}>
                    <Grid
                        item
                        xs={12}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            textAlign: "center",
                        }}
                    >
                        <Avatar style={{ marginBottom: "10px" }}>
                            <PeopleIcon />
                        </Avatar>
                        <Typography
                            component="h1"
                            variant="h4"
                            style={{ marginBottom: "10px" }}
                        >
                            INSTRUCTORS{" "}
                            {!isLoading && (
                                <>
                                    ({accounts?.length || 0}/
                                    {currentUser?.coachesCount})
                                </>
                            )}
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={openAddPopup}
                        >
                            ADD INSTRUCTOR
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <EnterpriseInstructorsList
                            accounts={accounts}
                            handleRefresh={loadData}
                        />
                        <AddEnterpriseInstructor
                            open={isAddPopupOpened}
                            close={closeAddPopup}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Card style={{ textAlign: "center" }}>
                            <CardHeader
                                title="Active Instructors"
                                titleTypographyProps={{
                                    variant: "h5",
                                    color: "primary",
                                }}
                            />
                            <Divider />
                            <CardContent>
                                <h2>{currentUser?.activeCoaches}</h2>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Card style={{ textAlign: "center" }}>
                            <CardHeader
                                title="Sessions Last 24 hours"
                                titleTypographyProps={{
                                    variant: "h5",
                                    color: "primary",
                                }}
                            />
                            <Divider />
                            <CardContent>
                                <h2>{currentUser?.sessionsLast24Hours}</h2>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Card style={{ textAlign: "center" }}>
                            <CardHeader
                                title="Sessions Last 7 days"
                                titleTypographyProps={{
                                    variant: "h5",
                                    color: "primary",
                                }}
                            />
                            <Divider />
                            <CardContent>
                                <h2>{currentUser?.sessionsLast7Days}</h2>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Card style={{ textAlign: "center" }}>
                            <CardHeader
                                title="Sessions Last Month"
                                titleTypographyProps={{
                                    variant: "h5",
                                    color: "primary",
                                }}
                            />
                            <Divider />
                            <CardContent>
                                <h2>{currentUser?.sessionsLastMonth}</h2>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Card style={{ textAlign: "center" }}>
                            <CardHeader
                                title="Swings captured/imported"
                                titleTypographyProps={{
                                    variant: "h5",
                                    color: "primary",
                                }}
                            />
                            <Divider />
                            <CardContent>
                                <h2>{currentUser?.swings}</h2>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Card style={{ textAlign: "center" }}>
                            <CardHeader
                                title="Active Students"
                                titleTypographyProps={{
                                    variant: "h5",
                                    color: "primary",
                                }}
                            />
                            <Divider />
                            <CardContent>
                                <h2>{currentUser?.activeStudents}</h2>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export const EnterpriseInstructors = EnterpriseInstructorsComponent;