import React, { useState, useEffect } from 'react';

import { Container, Grid, Switch, Button } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import axios from 'axios';
import Swal from 'sweetalert2';

import firebase, { axiosWithToken, functionBaseUrl } from '../../common/firebase';
import PlansSelectionComponent from './plansSelection';
import PlansCompareComponent from './plansCompare';
import PlansSignupComponent from './plansSignup';
import PlansSummaryComponent from './plansSummary';
import PlansPaymentComponent from './plansPayment';
import PlansSuccessComponent from './plansSuccess';
import PlansTestimonialsCoachesComponent from './plansTestimonialsCoaches';
import PlansTestimonialsPlayersComponent from './plansTestimonialsPlayers';
import './plans.css';


function Plans2023Component({ handlePlanType, getStarted }) {
  const [plansType, setPlansType] = useState('players');
  const [currentStep, setCurrentStep] = useState(1);
  const [plansPeriodY, setPlansPeriodY] = useState(true);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [uid, setUid] = useState('');
  const [bootcampUser, setBootcampUser] = useState(false);
  const [subData, setSubData] = useState({});
  const [freeTrial, setFreeTrial] = useState(false);

  useEffect(() => {
    if (window.location.href.includes('/plans')) {
      firebase.auth().onAuthStateChanged(async function (user) {
        if (user != null) {
          setUid(user.uid);
          setEmail(user.email);

          await axiosWithToken(functionBaseUrl + "/api/subscriptionByUserId/" + user.uid, {
            method: "GET",
          })
            .then(response => {
              if (response.data.data) {
                setSubData(response.data.data);
              }
            })
            .catch(err => {
              console.log(err)
            });
        }
      });
    }
  }, []);

  useEffect(() => {
    if (getStarted === 'premium') {
      handleGetStarted('development_sportsbox_pro_premium', true);
    }
  }, [getStarted]);

  useEffect(() => {
    if (currentStep > 1) {
      handlePlanType('');
    }
  }, [currentStep]);

  const handleGetStarted = async (plan, trial) => {
    let response = "";
    setFreeTrial(trial);

    if (window.location.href.includes('/plans')) {
      if (subData && (subData?.endDate > String(Date.now()) || subData.autoRenewal)) {
        if (subData.bootcampUser && plan.includes('_bootcamp')) {
          response = await Swal.fire({
            title: '<p style="font-size:70%;">Boot Camp</p>',
            html: '<p>It looks like you have already purchased Boot Camp, please select a different plan.</p>',
            icon: 'warning',
            confirmButtonText: 'Ok',
            showCancelButton: false,
            customClass: {
              container: 'my-swal'
            },
          })
            .then(() => {
              return null;
            })
            .catch(err => {
              console.log(err)
            });
        } else {
          response = await Swal.fire({
            title: '<p style="font-size:70%;">Active Subscription</p>',
            html: '<p>It looks like you have an active subscription, if you wish to proceed, your current subscription will be forfeited. Would you like to continue?</p>' +
              '<p style="font-size:80%;"><br/><br/><b>APPLE or GOOGLE SUBSCRIBERS:</b> Please <a target="_blank" rel="noopener noreferrer" href="https://help.sportsbox.ai/how-do-i-cancel-my-sportsbox-3dgolf-subscription">cancel your current Apple or Google subscription</a> immediately or you will continue to be charged. It is your responsibility to cancel your current plan.</p>',
            icon: 'warning',
            showConfirmButton: true,
            confirmButtonText: 'Yes',
            showCancelButton: true,
            cancelButtonColor: 'red',
            customClass: {
              container: 'my-swal'
            },
          })
            .then(async (result) => {
              if (result.isConfirmed) {
                await axios(functionBaseUrl + "/api/cancelSubHelper", {
                  method: "POST",
                  data: { email: email }
                })
                  .catch(err => {
                    console.log(err)
                  });
              } else {
                return null;
              }
            })
            .catch(err => {
              console.log(err)
            });
        }
      }
    }

    if (response === null) {
      return;
    }

    if (plan.includes('_bootcamp')) {
      setBootcampUser(true);
      plan = plan.replace('_bootcamp', '_monthly');
      setSelectedPlan(plan);
    } else {
      setSelectedPlan(`${plan}_${plansPeriodY ? 'annually' : 'monthly'}`);
    }

    if (window.location.href.includes('sign-up')) {
      setCurrentStep(2);
    } else {
      setCurrentStep(3);
    }
  }

  const handleFinishSignUp = (uid, email, name) => {
    setEmail(email);
    setUid(uid);
    setFullName(name);
    setCurrentStep(3);
  }

  return (<>

    {currentStep == 1 && (<>
      {/*<Container
        maxWidth={false}
        style={{ padding: 0 }}
        className="plans"
      >
        <Grid container>
          <Grid
            item
            xs={12}
            className={`plans-banner ${plansType == 'players' ? 'players' : ''}`}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                md={6}
              >
                {plansType == 'coaches' && (<>
                  <div className="plans-banner-caption">
                    Best Place to Get Started With Sportsbox
                  </div>
                  <div className="plans-banner-subcaption">
                    In just 5 weeks, our interactive course will help you unlock your potential as a 3DGolf instructor, teaching you everything from selecting the correct 3D tracker in your lessons to maximizing the power of Sportsbox's features, all while boosting your revenue. Enroll in the program and receive a 2-month 3D Pro subscription (a $378 value) as well as an exclusive discount on an Annual subscription.
                  </div>
                  <div className="plans-banner-buttons">
                    <Button style={{ marginRight: "2%" }} target="_blank" rel="noopener noreferrer" href="https://sportsbox-ai.teachable.com/p/sportsboxbootcamp" className="plan-button">Boot Camp Overview</Button>
                    <Button target="_blank" rel="noopener noreferrer" href="https://meetings.hubspot.com/edwin-fuh/product-demo" className="plan-button" style={{ background: "white", color: "black" }}>Book a Demo</Button>
                  </div>
                </>)}
                {plansType == 'players' && (<>
                  <div className="plans-banner-caption">
                    Introducing the Ultimate Practice Experience
                  </div>
                  <div className="plans-banner-subcaption">
                    Elevate your game with our 3D Practice app subscription, providing you access to over 50 comprehensive Practice Guides. Each guide features an easy-to-follow series of content designed for effective practice sessions. Track and quantify your progress after every practice, ensuring continuous improvement with your swing goals. Subscribe now and transform your practice experience!
                  </div>
                </>)}
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                className="plans-banner-testimonials"
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>*/}
      <Container
        component="main"
        maxWidth="xl"
      >
        <Grid container className="plans">
          {/*<Grid
            item
            xs={12}
            className="plans-selector-wrapper"
          >
            <ToggleButtonGroup
              value={plansType}
              exclusive
              onChange={(e, value) => { setPlansType(value ? value : plansType); handlePlanType(value ? value : plansType); }}
              className="plans-selector-group"
            >
              <ToggleButton value="coaches" aria-label="Coaches" className="plans-selector">
                Pros
              </ToggleButton>
              <ToggleButton value="players" aria-label="Players" className="plans-selector">
                Players
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid
            item
            xs={12}
            className="plans-period-wrapper"
          >
            <span>Monthly</span>
            <Switch
              className="plans-period-switch"
              checked={plansPeriodY}
              onChange={() => setPlansPeriodY(!plansPeriodY)}>
            </Switch>
            <span>Annually</span>
          </Grid>*/}
          <Grid
            item
            xs={12}
          >
            {//(window.location.href.includes('sign-up') || subData !== {}) &&
              <PlansSelectionComponent
                plansType={plansType}
                period={plansPeriodY ? 'Annually' : 'Monthly'}
                getStarted={handleGetStarted}
                subData={subData}
              />}
          </Grid>
          {/*<Grid
            item
            xs={12}
          >
            {//(window.location.href.includes('sign-up') || subData !== {}) && 
              <PlansCompareComponent
                plansType={plansType}
                getStarted={handleGetStarted}
                subData={subData}
              />}
          </Grid>
          <Grid
            item
            xs={12}
            className="plans-testimonials"
          >
            {plansType == 'coaches' && (<>
              <PlansTestimonialsCoachesComponent />
            </>)}
            {plansType == 'players' && (<>
              <PlansTestimonialsPlayersComponent />
            </>)}
          </Grid>*/}
        </Grid>
      </Container>
    </>)}
    {currentStep >= 2 && (
      <Container
        component="main"
        maxWidth={false}
        style={{ padding: 0, paddingTop: '10px' }}
      >
        <Grid container className="plans">
          <Grid
            item
            xs={12}
            md={6}
            className="plans-checkout"
          >
            {currentStep < 5 && (
              <div className="plans-checkout-progress mobile">
                {window.location.href.includes('sign-up') && <span className={currentStep >= 2 ? 'plans-checkout-progress-done' : ''}></span>}
                <span className={currentStep >= 3 ? 'plans-checkout-progress-done' : ''}></span>
                {!selectedPlan.includes('free') && <span className={currentStep >= 4 ? 'plans-checkout-progress-done' : ''}></span>}
              </div>
            )}
            {currentStep == 2 && (<PlansSignupComponent plan={selectedPlan} finishSignUp={handleFinishSignUp} back={() => setCurrentStep(1)} />)}
            {currentStep == 3 && (<PlansSummaryComponent plan={selectedPlan} subData={subData} bootcampUser={bootcampUser} freeTrial={freeTrial} pay={() => selectedPlan.includes('free') ? setCurrentStep(5) : setCurrentStep(4)} back={() => window.location.href.includes('sign-up') ? setCurrentStep(2) : setCurrentStep(1)} />)}
            {currentStep == 4 && (<PlansPaymentComponent plan={selectedPlan} subData={subData} bootcampUser={bootcampUser} freeTrial={freeTrial} email={email} uid={uid} fullName={fullName} finish={() => setCurrentStep(5)} back={() => setCurrentStep(3)} />)}
            {currentStep == 5 && (<PlansSuccessComponent />)}
            {currentStep < 5 && (
              <div className="plans-checkout-progress">
                {window.location.href.includes('sign-up') && <span className={currentStep >= 2 ? 'plans-checkout-progress-done' : ''}></span>}
                <span className={currentStep >= 3 ? 'plans-checkout-progress-done' : ''}></span>
                {!selectedPlan.includes('free') && <span className={currentStep >= 4 ? 'plans-checkout-progress-done' : ''}></span>}
              </div>
            )}
          </Grid>
          <Grid
            item
            xs={0}
            md={6}
            className="plans-checkout-cta"
          >
            <div className="plans-checkout-cta-caption">
              Elevate Your Baseball Coaching with Sportsbox 3DGolf App
            </div>
            <div className="plans-checkout-cta-description">
              Discover the future of baseball coaching with our innovative Sportsbox 3DGolf app. Experience markerless 3D motion capture, visualize baseball swings from six different angles, obtain precise 3D biomechanical measurements, and track progress using quantifiable data. Empower your students with personalized, trackable goals tailored to their unique needs.
            </div>
            <div className="plans-checkout-cta-coaches">
              <img src="/images/avatars/Avatar.png" />
              <img src="/images/avatars/Avatar-1.png" />
              <img src="/images/avatars/Avatar-2.png" />
              <img src="/images/avatars/Avatar-3.png" />
              <img src="/images/avatars/Avatar-4.png" />
              <img src="/images/avatars/Avatar-5.png" />
              <img src="/images/avatars/Avatar-6.png" />
              <img src="/images/avatars/Avatar-7.png" />
              Join thousands of coaches in 32 countries
            </div>
          </Grid>
        </Grid>
      </Container>
    )}
  </>);
}

export default Plans2023Component;