import React from "react";
import { FlexWrap } from "../../../components";

export function Thumbnail({ display, handleFile, text = "Upload Image" }) {
  return (
    <>
      <label
        style={{
          height: "fit-content",
          cursor: "pointer",
        }}
        htmlFor="fileInput"
      >
        {display ? (
          <img width={100} height={100} src={display} alt="test" />
        ) : (
          <FlexWrap
            style={{
              padding: "10px",
              background: "#C9C9C9",
              width: "100px",
              height: "100px",
              textAlign: "center",
            }}
          >
            {text}
          </FlexWrap>
        )}
      </label>
      <input
        onChange={handleFile}
        id="fileInput"
        style={{ display: "none" }}
        type="file"
      />
    </>
  );
}
