import admin from "../../../common/firebase";
import { fbMapper } from "../../../helpers/fbMapper";
import { dateMapper, firestorageExistCheck } from "./helper";

const db = admin.firestore().collection("tipsAndDrillsVideos");

export const getTipsAndDrills = async () => {
  const vids = fbMapper(await db.get()).map(dateMapper);

  return vids;
};

export const createTipsAndDrillsVideo = async (data) => {
  const { name, tags, platform, video, accessLevel, isPremium } = data;

  const userId = admin.auth().currentUser.uid;

  const userData = (
    await admin.firestore().collection("users").doc(userId).get()
  ).data();

  var storageRef = admin.storage().ref();

  const vidExtension = video.file.name.split(".").pop();
  const thumbExtension = video.thumbnail.name.split(".").pop();

  var vidFileRef = storageRef.child(
    `tips_and_drills/videos/${Date.now()}.${vidExtension}`
  );
  var thumbFileRef = storageRef.child(
    `tips_and_drills/thumbnails/${Date.now()}.${thumbExtension}`
  );

  const vidUploadTaskSnapshot = await vidFileRef.put(video.file);
  const vidDownloadURL = await vidUploadTaskSnapshot.ref.getDownloadURL();

  const thumbUploadTaskSnapshot = await thumbFileRef.put(video.thumbnail);
  const thumbDownloadURL = await thumbUploadTaskSnapshot.ref.getDownloadURL();

  const parsed = {
    createdAt: Date.now(),
    isPremium,
    accessLevel,
    name,
    tags,
    platform,
    creator: {
      name: `${userData.firstName} ${userData.lastName}`,
      userId,
    },
    video: {
      thumbnail: thumbDownloadURL,
      length: Math.floor(video.length),
      url: vidDownloadURL,
    },
  };

  await db.add(parsed);

  const vids = fbMapper(await db.get()).map(dateMapper);

  return vids;
};

export const updateTipsAndDrillsVideo = async (id, data) => {
  const { name, tags, platform, video, newVideo, isPremium, accessLevel } =
    data;

  const prev = (await db.doc(id).get()).data();

  const parsed = {
    ...prev,
    isPremium,
    accessLevel,
    name,
    tags,
    platform,
  };

  if (newVideo.file) {
    if (await firestorageExistCheck(prev.video.url)) {
      admin.storage().refFromURL(prev.video.url).delete();
    }
    const storageRef = admin.storage().ref();
    const vidExtension = newVideo.file.name.split(".").pop();
    const vidFileRef = storageRef.child(
      `tips_and_drills/videos/${Date.now()}.${vidExtension}`
    );
    const vidUploadTaskSnapshot = await vidFileRef.put(newVideo.file);
    const vidDownloadURL = await vidUploadTaskSnapshot.ref.getDownloadURL();
    parsed.video = {
      ...parsed.video,
      length: Math.floor(newVideo.length),
      url: vidDownloadURL,
    };
  }

  if (newVideo.thumbnail) {
    if (await firestorageExistCheck(prev.video.thumbnail)) {
      admin.storage().refFromURL(prev.video.thumbnail).delete();
    }
    const storageRef = admin.storage().ref();
    const thumbExtension = newVideo.thumbnail.name.split(".").pop();
    const thumbFileRef = storageRef.child(
      `tips_and_drills/thumbnails/${Date.now()}.${thumbExtension}`
    );
    const thumbUploadTaskSnapshot = await thumbFileRef.put(newVideo.thumbnail);
    const thumbDownloadURL = await thumbUploadTaskSnapshot.ref.getDownloadURL();
    parsed.video = {
      ...parsed.video,
      thumbnail: thumbDownloadURL,
    };
  }

  await db.doc(id).set(parsed);

  const vids = fbMapper(await db.get()).map(dateMapper);

  return vids;
};

export const deleteTipsAndDrillsVideo = async (id) => {
  const prev = (await db.doc(id).get()).data();

  if (await firestorageExistCheck(prev.video.thumbnail)) {
    admin.storage().refFromURL(prev.video.thumbnail).delete();
  }

  if (await firestorageExistCheck(prev.video.url)) {
    admin.storage().refFromURL(prev.video.url).delete();
  }

  await db.doc(id).delete();

  const pg = fbMapper(
    await admin.firestore().collection("practiceGuides").get()
  );

  const pgsWithDrill = pg.map((el) => {
    const drills = el.summary.drills.filter((drl) => drl.drillVideo !== id);
    const targetPractice = el.summary.targetPractice.filter(
      (tp) => tp.drillVideo !== id
    );
    const warmup = el.summary.warmup.filter((wrm) => wrm.drillVideo !== id);
    return { ...el, summary: { drills, targetPractice, warmup } };
  });

  await Promise.all(
    pgsWithDrill.map(async (el) => {
      const { uid, summary } = el;
      return await admin
        .firestore()
        .collection("practiceGuides")
        .doc(uid)
        .update({ summary });
    })
  );

  const tipsAndDrills = fbMapper(await db.get()).map(dateMapper);
  const practiceGuides = fbMapper(
    await admin.firestore().collection("practiceGuides").get()
  ).map(dateMapper);

  return { tipsAndDrills, practiceGuides };
};

export const deleteTesting = async () => {
  const id = "MYhODjGb3CWukrX5b2vB";
};
