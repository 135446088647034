import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid, TextField, 
  FormControl, Select, MenuItem, InputLabel } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Swal from 'sweetalert2';
import { functionBaseUrl, axiosWithToken } from '../../common/firebase';
import { set } from 'date-fns';

function AddCreditComponent({ open, close }) {
  const [amount, setAmount] = useState(10);
  const [creditCount, setCreditCount] = useState(1);
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('complete');
  const [type, setType] = useState('in');

  const handleAmount = (e) => {
    setAmount(e.target.value);
    if (e.target.value === 0) {
      setStatus('complete');
    }
  }
  const handleCreditCount = (e) => {
    setCreditCount(e.target.value);
  }
  const handleEmail = (e) => {
    setEmail(e.target.value);
  }
  const handleType = (e) => {
    setType(e.target.value);
    if (e.target.value === 'out') {
      setStatus('complete');
    }
  }
  const clearState = () => {
    setAmount(10);
    setCreditCount(1);
    setEmail('');
    setStatus('complete');
    setType('in');
  }

  const validEmail = (email) => {
    const regex = /\S+@\S+\.\S+/;
    return regex.test(email);
  }

  const handleCreate = async () => {
    if (!amount || !creditCount || !email || !status || !type) {
      close();
      Swal.fire('<p style="font-size:70%;">Please fill in all required fields</p>');
      return;
    }
    if (amount < 0) {
      close();
      Swal.fire('<p style="font-size:70%;">Amount has to greater than 0</p>');
      return;
    }
    if (!validEmail(email)) {
      close();
      Swal.fire('<p style="font-size:70%;">Please enter a valid Email</p>');
      return;
    }

    const userResponse = await axiosWithToken(`${functionBaseUrl}/api/searchUserByEmail`, {
      method: 'POST',
      data: { email: email.toLowerCase() }
    });
    if (userResponse?.data?.length === 0) {
      close();
      Swal.fire('<p style="font-size:70%;">No user found with the email: <b>' + email + '</b></p>');
      return;
    }
    
    await axiosWithToken(`${functionBaseUrl}/api/v1/credits`, {
      method: 'POST',
      data: {
        amount: amount,
        creditCount: creditCount,
        status: status,
        userId: userResponse?.data[0]?.id,
        coachId: userResponse?.data[0]?.id,
        transactionType: type
      }
    })
    .then((response) => {
      close();
      if (response.status === 200) {
        clearState();
        Swal.fire({
          icon: 'success',
          showConfirmButton: false,
          allowOutsideClick: false,
          timer: 2000,
          customClass : { 
            container : 'my-swal' 
          },
        });
      } else {
        Swal.fire({
          title: '<p style="font-size:70%;">' + response.data.message + '</p>',
          icon: 'warning',
          confirmButtonText: 'Ok',
          allowOutsideClick: true,
          customClass : { 
            container : 'my-swal' 
          },
        })
      }
      return false;
    })
    .catch(() => {
      Swal.fire({
        title: '<p style="font-size:70%;">There was an error, please try again</p>',
        icon: 'warning',
        confirmButtonText: 'Ok',
        allowOutsideClick: true,
        customClass : { 
          container : 'my-swal' 
        },
      })
    })
  }

  return (<>
    <Dialog
      fullWidth
      open={open}
      onClose={close}
      aria-labelledby='form-dialog-title'>
      <DialogTitle id={'form-dialog-title'}>
        <div>
          <b>Add Credits</b>
          <CloseIcon style={{ float: 'right', marginTop: '-10px', marginRight: '-15px' }} onClick={close} />
        </div>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            sm={6}
            xs={12}
          >
            <TextField
              autoFocus
              fullWidth
              required
              label="Amount"
              onChange={handleAmount}
              value={amount}
              variant="outlined"
              type='number'
              inputProps={{ min: 0 }}
            />
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
          >
            <TextField
              fullWidth
              required
              label="# of Credits"
              onChange={handleCreditCount}
              value={creditCount}
              variant="outlined"
              type='number'
              inputProps={{ min: 1 }}
            />
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
          >
            <TextField
              autoComplete="email"
              fullWidth
              required
              label="Email Address"
              onChange={handleEmail}
              value={email}
              variant="outlined"
              inputProps={{ maxLength: 254 }}
            />
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
          >
            <FormControl required label="Status" variant="outlined" fullWidth>
              <InputLabel>Status</InputLabel>
              <Select label="Status" value={status} disabled={amount === 0 || type === 'out'} onChange={(e) => setStatus(e.target.value)} style={{ width: '100%' }}>
                <MenuItem value={'incomplete'}>Incomplete</MenuItem>
                <MenuItem value={'complete'}>Complete</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
          >
            <FormControl required label="Type" variant="outlined" fullWidth>
              <InputLabel>Type</InputLabel>
              <Select label="Status" value={type} onChange={handleType} style={{ width: '100%' }}>
                <MenuItem value={'in'}>Add</MenuItem>
                <MenuItem value={'out'}>Remove</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreate}
        >
          ADD
        </Button>
      </DialogActions>
    </Dialog>
  </>);
}

export const AddCredit = AddCreditComponent;
