import React from "react";
import {
  PracticeGuideHeaderContainer,
  PracticeGuideHeaderItem,
  PracticeGuideHeaderItemContainer,
} from "./styled";

export function WatchlistModalSwitcher({ selected, onSelect }) {
  return (
    <PracticeGuideHeaderContainer>
      <PracticeGuideHeaderItemContainer>
        <PracticeGuideHeaderItem
          onClick={() => onSelect("popular")}
          active={selected === "popular"}
        >
          Popular
        </PracticeGuideHeaderItem>
        <PracticeGuideHeaderItem
          onClick={() => onSelect("create")}
          active={selected === "create"}
        >
          Create New
        </PracticeGuideHeaderItem>
      </PracticeGuideHeaderItemContainer>
    </PracticeGuideHeaderContainer>
  );
}
