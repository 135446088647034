import React, { Component } from 'react';
import { createMuiTheme, MuiThemeProvider, Typography, MenuItem, Menu, Fade, Avatar, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import 'firebase/firestore';
import firebase, { axiosWithToken, functionBaseUrl } from '../../../../common/firebase'
import { forwardRef } from 'react';
import {AddBox, ArrowDownward, Check, ChevronLeft, ChevronRight, Clear, DeleteOutline, Edit, FilterList, FirstPage, LastPage, Remove, SaveAlt, Search, ViewColumn} from '@material-ui/icons';
import palette from '../../../../theme/palette';
import Swal from 'sweetalert2';
import { EditStudentEnterprise } from '../../EditStudentEnterprise';
import { DisplayStudentsSessionsTable } from './DisplayStudentsSessionsTable';
import { UpgradeStudent } from '../../UpgradeStudent';
import moment from 'moment';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const theme = createMuiTheme({
  typography: {
    h6: {
      fontWeight: 500,
      fontSize: '20px',
      letterSpacing: '-0.05px',
      lineHeight: '20px',
    }
  },
  palette: {
    secondary: {
      main: palette.primary.main,
    },
  },
})

const tableHeads = {
  students: [
    {
      field: 'picture', title: '', width: '70px', render: rowData => (
        <Avatar
          style={{ width: 35, height: 35 }}
          src={rowData.picture} />
      )
    },
    { field: 'name', title: 'Name' },
    { field: 'email', title: 'Email' },
    { field: 'plan', title: 'Plan' },
    {
      field: 'coaches', title: 'Instructors', width: '70px', render: rowData => 
        rowData.coachesData.map(coach => <>{coach.firstName} {coach.lastName}<br/></>)
    },
    {
      field: 'sessionIds', title: 'Sessions',
      cellStyle: {
        width: '20px',
        maxWidth: '20%'
      },
      headerStyle: {
        width: '20px',
        maxWidth: '20%'
      }
    },
    { field: 'userCreated', title: 'Created', defaultSort: 'desc' },
    { field: 'status', title: 'Status' },
  ]
}

class DisplayStudentsTable extends Component {
  constructor (props) {
    const firebaseUser = firebase.auth().currentUser
    super(props);
    this.state = {
      selectedRows: {
        students: []
      },
      exportMenu: {
        anchor: null,
        type: ''
      },
      deleteMenuSess: {
        anchor: null,
        type: ''
      },
      uid : firebaseUser ? firebaseUser.uid : "",
      studentsEnterprise: [
        /*{
          field: 'actionSessions', title: '', width: '70px', render: rowData => (
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.openPopUpSessions(rowData)}
            >
              Sessions
            </Button>
          )
        },*/
        {
          field: 'actionUpgrade', title: '', width: '70px', render: rowData => (
            rowData?.plan?.toLowerCase().includes('free') && (
              <Button
                variant="contained"
                disabled={!rowData.plan.toLowerCase().includes('free')}
                //style={{ backgroundColor: '#060a30', color: 'white' }}
                color="default"
                onClick={() => this.openUpgradePopUp(rowData)}
              >
                Upgrade
              </Button>
            )
          )
        },
        {
          field: 'actionEdit', title: '', width: '70px', render: rowData => (
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.openPopUpEditStudent(rowData)}
            >
              Edit
            </Button>
          )
        },
        ...tableHeads.students,
      ],
      staffEnterprise: [
        /*{
          field: 'actionSessions', title: '', width: '70px', render: rowData => (
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.openPopUpSessions(rowData)}
            >
              Sessions
            </Button>
          )
        },*/
        {
          field: 'actionUpgrade', title: '', width: '70px', render: rowData => (
            rowData?.plan?.toLowerCase().includes('free') && (
              <Button
                variant="contained"
                disabled={!rowData.plan.toLowerCase().includes('free')}
                //style={{ backgroundColor: '#060a30', color: 'white' }}
                color="default"
                onClick={() => this.openUpgradePopUp(rowData)}
              >
                Upgrade
              </Button>
            )
          )
        },
        ...tableHeads.students,
      ],
      nyStudents: [
        {
          field: 'actionSessions', title: '', width: '70px', render: rowData => (
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.openPopUpSessions(rowData)}
            >
              Sessions
            </Button>
          )
        },
        ...tableHeads.students,
        { field: 'workflow', title: 'Workflow' },
        { field: 'shotTrajectory', title: 'Shot Trajectory' },
        { field: 'typicalMiss', title: 'Typical Miss' },
      ],
      studentData: {},
      openPopUpEdit: false,
      isOpenPopUpSessions: false,
      openPopUpUpgrade: false,
    }
  }

  getTableHeader = (name) => {
    return [
      /*{
        field: 'actionSessions', title: '', width: '70px', render: rowData => (
          <Button
            variant="contained"
            color="primary"
            onClick={() => this.openPopUpSessions(rowData)}
          >
            Sessions
          </Button>
        )
      },*/
      {
        field: 'actionUpgrade', title: '', width: '70px', render: rowData => (
          rowData?.plan?.toLowerCase().includes('free') && (
            <Button
              variant="contained"
              disabled={!rowData.plan.toLowerCase().includes('free')}
              //style={{ backgroundColor: '#060a30', color: 'white' }}
              color="default"
              onClick={() => this.openUpgradePopUp(rowData)}
            >
              Upgrade
            </Button>
          )
        )
      },
      ...tableHeads[name]
    ];
  }

  openPopUpEditStudent = (data) => {
    this.setState({ studentData: data, openPopUpEdit: true });
  }

  closePopUpEditStudent = () => {
    this.setState({ openPopUpEdit: false });
  }

  openUpgradePopUp = (data) => {
    this.setState({ studentData: data, openPopUpUpgrade: true });
  }

  closePopUpUpgrade = () => {
    this.setState({ openPopUpUpgrade: false });
  }

  openPopUpSessions = (data) => {
    this.setState({ studentData: data, isOpenPopUpSessions: true });
  }

  closePopUpSessions = () => {
    this.setState({ isOpenPopUpSessions: false });
  }

  handleExportPush = (type) => (event, data) => {
    this.setState({exportMenu: {anchor: event.currentTarget, type: type}, selectedRows: {...this.state.selectedRows, [type]: data}})
  }

  handleDeleteSess = (type) => (event, data) => {
    this.setState({deleteMenuSess: {anchor: event.currentTarget, type: type}, selectedRows: {...this.state.selectedRows, [type]: data}})
  }

  removeStudents = async () => {
    const ids = this.state.selectedRows.students.map((item) => item.id)
    for (const id of ids) {
      await axiosWithToken(functionBaseUrl + '/api/removeStudent', {
        method: 'delete',
        data: {
          studentId: id,
          coachId: this.state.selectedRows.students[0].coachesData[0]?.id || this.state.uid
        }
      })
        .catch(err => {
          console.log(err)
          Swal.fire({
            title: '<p style="font-size:70%;">There was an error, please try again!</p>',
            icon: 'error',
            allowOutsideClick: true
          })
        });
    }
    Swal.fire({
      title: '<p style="font-size:70%;">Student(s) successfully removed</p>',
      icon: 'success',
      showConfirmButton: false,
      allowOutsideClick: true,
      timer: 2000
    }).then(() => {
      window.location.reload()
    })
  }

  sessionDate = (date) => {
    return moment(String(date), "x").format('MM/DD/YYYY');
  }

  getPlan = (plan) => {
    if (plan.includes('free')) {
      return 'Free'
    } else if (plan.includes('student_lite')) {
      return '3D Practice'
    } else if (plan.includes('pro_lite')) {
      return 'Pro Lite'
    } else if (plan.includes('premium')) {
      return '3D Pro'
    } else if (plan.includes('enterprise_master')) {
      return 'Enterprise Master'
    } else if (plan.includes('enterprise_coach')) {
      return 'Enterprise Coach'
    }
  }

  titleCase = (str) => {
    if (str) {
      str = str.replaceAll('-', ' ');
      var splitStr = str.toLowerCase().split(' ');
      for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
      }
      return splitStr.join(' '); 
    } else {
      return ""
    }
  }

  render() {
    let usersData = [];
    if (this.props.students) {
      const keys = Object.keys(this.props.students)
      usersData = keys.map((uid) => {
        const user = this.props.students[uid];       
        if (user) {
          const userCoaches = [];
          user.coaches && user.coaches.forEach(coach => {
            if (this.state.uid == coach || this.props.role == 'admin' || this.props.subscriptionType?.includes('enterprise_master')) {
              const coachData = this.props.coaches?.find(c => c.id === coach);
              if (coachData) {
                userCoaches.push(coachData);
              }
            }
          });
          const out = {
            id: user.id,
            picture: user.picture,
            name: user.firstName + ' ' + user.lastName,
            email: user.email,
            gender: user.gender ? this.titleCase(user.gender) : '',
            height: user.height,
            plan: user.subscriptionType ? this.getPlan(user.subscriptionType) : 'Free',
            sessionIds: user.sessionIds?.length,
            userCreated: this.sessionDate(user.userCreated),
            user: user,
            coachesData: userCoaches,
            status: user?.invite?.status,
            workflow: user?.workflow,
            typicalMiss: user?.typicalMiss?.toString().replaceAll(',', ', '),
            shotTrajectory: user?.shotTrajectory
          }
          return out;
        }
        return null;
      })
    }
    const tableElements = [];
    if (usersData.length > 0) {
      tableElements.push(
        <MaterialTable
          style={{ marginBottom: '10px' }}
          icons={tableIcons}
          key='sessionTable'
          title='Students'
          columns={(this.state.uid === '3wLXoGfzTpaQ6XTSkpnbfejfhYt1') ? this.state.nyStudents : ((this.props.isStaffEnterprise || this.props.role === 'admin') ? (((this.props.isCertified && this.props.onboardingCompleted) || this.props.role === 'admin') ? this.state.staffEnterprise : this.state.staffEntNotComplete) : (this.props.subscriptionType.includes('enterprise_master') ? this.state.studentsEnterprise : this.getTableHeader('students')))}
          data={usersData.map(row => this.state.selectedRows.students.find(selected => selected.id === row.id) ? { ...row, tableData: { checked: true } } : row)}
          options={{
            selection: true,
            sorting: true,
            pageSizeOptions: [5, 10, 20, 50, 100],
            exportButton: {
              csv: true,
              pdf: true,
            },
            exportAllData: true,
          }}
          actions={[
            {
              tooltip: 'Download selected students',
              icon: tableIcons.Export,
              onClick: this.handleExportPush('students')
            }, 
            {
              tooltip: 'Remove selected students',
              icon: tableIcons.Delete,
              onClick: this.handleDeleteSess('students')
            }
          ]} />)
    }
    if (tableElements.length < 1) {
      return (<Typography align='center' style={{ marginTop: '20px' }}>There are no students to display</Typography>)
    }
    return (
      <MuiThemeProvider theme={theme}>
        {tableElements}
        <EditStudentEnterprise
          open={this.state.openPopUpEdit}
          student={this.state.studentData}
          close={this.closePopUpEditStudent}
        />
        <UpgradeStudent
          open={this.state.openPopUpUpgrade}
          student={this.state.studentData}
          close={this.closePopUpUpgrade}
          coachName={this.props.coachName}
          coachId={this.props.coachId}
        />
        <DisplayStudentsSessionsTable
          open={this.state.isOpenPopUpSessions}
          student={this.state.studentData}
          close={this.closePopUpSessions}
        />
        <Menu
          id="menu"
          anchorEl={this.state.exportMenu.anchor}
          keepMounted
          open={Boolean(this.state.exportMenu.anchor)}
          onClose={() => { this.setState({ exportMenu: { anchor: null, type: '' } }) }}
          TransitionComponent={Fade}
        >
          {/*<MenuItem onClick={this.exportAsCsv}>Export As CSV</MenuItem>*/}
          <MenuItem onClick={this.downloadVideos}>Download Session Videos</MenuItem>
        </Menu>
        <Menu
          id="menu2"
          anchorEl={this.state.deleteMenuSess.anchor}
          keepMounted
          open={Boolean(this.state.deleteMenuSess.anchor)}
          onClose={() => { this.setState({ deleteMenuSess: { anchor: null, type: '' } }) }}
          TransitionComponent={Fade}
        >
          <MenuItem onClick={this.removeStudents}>Remove</MenuItem>
        </Menu>
      </MuiThemeProvider>)
  }
}
DisplayStudentsTable.propTypes = {
  students: PropTypes.array,
  searchOrder: PropTypes.string,
  compress: PropTypes.bool
}
export default DisplayStudentsTable