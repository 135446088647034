import { format } from "date-fns";
import React from "react";
import { FlexWrap } from "../../../components";
import {
  ActionsContainer,
  PracticeGuideRowContainer,
  TableRowItemWrapper,
  StatusBadge,
} from "../styled";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import LinkIcon from "@material-ui/icons/Link";

export function PracticeGuideRow({ data, onEdit, onDelete, onCopy }) {
  const {
    coverImage,
    coverImageFileSize,
    introVideo,
    title,
    status,
    createdAt,
    id,
  } = data;
  const iconStyle = { cursor: "pointer", fontSize: "25px" };
  const totalSize = () => {
    const cvrImg = coverImageFileSize ? coverImageFileSize : 0;
    const intrVid = introVideo && introVideo.fileSize ? introVideo.fileSize : 0;
    return cvrImg + intrVid;
  };

  return (
    <PracticeGuideRowContainer>
      <TableRowItemWrapper width="132px">
        {coverImage ? (
          <img src={coverImage} alt="" height="87" width="132" />
        ) : (
          <img
            src="https://sanin-japan.com/assets/camaleon_cms/image-not-found-4a963b95bf081c3ea02923dceaeb3f8085e1a654fc54840aac61a57a60903fef.png"
            alt=""
            height="87"
            width="132"
          />
        )}
      </TableRowItemWrapper>
      <TableRowItemWrapper width="200px">
        <div>{title}</div>
      </TableRowItemWrapper>
      <TableRowItemWrapper width="150px">
        <div>{totalSize()}mb</div>
      </TableRowItemWrapper>
      <TableRowItemWrapper width="100px">
        <div>{format(new Date(createdAt), "MM/dd/yyyy")}</div>
      </TableRowItemWrapper>
      <TableRowItemWrapper width="150px">
        <StatusBadge status={status}>{status}</StatusBadge>
      </TableRowItemWrapper>
      <TableRowItemWrapper width="100px">
        <ActionsContainer>
          <EditIcon onClick={onEdit} style={iconStyle} />
          <DeleteIcon onClick={onDelete} style={iconStyle} />
          <FileCopyIcon onClick={onCopy} style={iconStyle} />
          <LinkIcon
            onClick={() => {
              navigator.clipboard.writeText(
                `https://3dgolf.sportsbox.ai/practice-guide/${id}`
              );
            }}
            style={iconStyle}
          />
        </ActionsContainer>
      </TableRowItemWrapper>
    </PracticeGuideRowContainer>
  );
}
