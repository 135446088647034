import React, { useState } from 'react';

import { Container, Avatar, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import ImportExportIcon from "@material-ui/icons/ImportExport";

import { ActiveUsers } from './ActiveUsers';
import { WeekUsage } from './WeekUsage';
import { UsageChange } from './UsageChange';


const useStyles = theme => ({
  paper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
});


function UsageComponent({ classes }) {

  return (<>
    <Container className={classes.paper} component="main">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <ImportExportIcon />
        </Avatar>
        <Typography
          component="h1"
          variant="h4"
          style={{ marginBottom: '10px' }}
        >
          USAGE
        </Typography>
      </div>
      <WeekUsage />
      <ActiveUsers />
      <UsageChange />
    </Container>
  </>);
}

export const Usage = withStyles(useStyles)(UsageComponent);