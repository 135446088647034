import React, { forwardRef, useState, useEffect } from 'react';

import { Button, CircularProgress, Container, Grid } from '@material-ui/core';
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
  Receipt,
} from "@material-ui/icons";
import MaterialTable from "material-table";
import Swal from 'sweetalert2';
import moment from 'moment';

import firebase, { functionBaseUrl, axiosWithToken } from '../../common/firebase';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => (
    <ArrowDownward {...props} ref={ref} />
  )),
  ThirdStateCheck: forwardRef((props, ref) => (
    <Remove {...props} ref={ref} />
  )),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  Receipt: forwardRef((props, ref) => <Receipt {...props} ref={ref} />),
};

function InvoicesComponent({ history }) {
  const [isLoading, setIsLoading] = useState(true);
  const [invoices, setInvoices] = useState([]);

  const tableHeader = [
    {
      field: "period", title: "Period", render: rowData => (
        <>{moment(rowData.period).format("MMMM YYYY")}</>
      )
    },
    { field: "status", title: "Status" },
    {
      field: "id", title: "", render: rowData => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => history.push(`/invoices/detail/${rowData.id}`)}
        >
          Details
        </Button>
      )
    },
  ];

  const loadData = async () => {
    try {
      setIsLoading(true);
      const firebaseUser = firebase.auth().currentUser;
      const response = await axiosWithToken(`${functionBaseUrl}/api/invoices`, {
        method: 'POST',
        data: { userId: firebaseUser.uid }
      });
      setInvoices(response.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      Swal.fire({
        title: '<p style="font-size:70%;">Error loading data</p>',
        icon: 'error',
        confirmButtonText: 'Ok',
        allowOutsideClick: true,
      });
    }
  }

  useEffect(() => {
    loadData();
  }, [])

  return (<>
    {isLoading && (
      <Container maxWidth="xs" style={{ textAlign: 'center', paddingTop: '50px' }}>
        <CircularProgress />
      </Container>
    )}
    {!isLoading && (
      <Container
        component="main"
        style={{ paddingTop: '50px' }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h2 style={{ textAlign: 'center' }}>Invoices</h2>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <MaterialTable
              style={{ marginTop: "10px", marginBottom: "10px" }}
              icons={tableIcons}
              columns={tableHeader}
              title=""
              data={invoices}
              options={{
                selection: false,
                sorting: true,
                search: true,
                paging: true,
                pageSizeOptions: [5, 10, 20, 50, 100],
                rowStyle: (x) => {
                  if (x.tableData.id % 2) {
                    return { backgroundColor: "#f2f2f2" };
                  }
                },
              }}
            />
          </Grid>
        </Grid>
      </Container>
    )}
  </>);
}

export const Invoices = InvoicesComponent;
