import styled from "styled-components";

export const EmptyAlbumPracticeGuide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 350px;
  border: 1px dashed #808080;
  border-radius: 4px;
`;

export const AlbumPracticeGuideItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  padding: 10px;
`;
