import React from "react";
import { format } from "date-fns";
import { CustomRadio } from "../common/CustomRadio";
import { SummaryItemContainer } from "../practiceGuide/styled";

export function PracticeGuideModalChoice({ data, onClick, active }) {
  return (
    <SummaryItemContainer onClick={() => onClick(data.id)}>
      <img height="87px" width="132px" src={data.coverImage} />
      <div>{data.title}</div>
      <div>{format(data.createdAt, "MM/dd/yyyy")}</div>
      <CustomRadio active={active} />
    </SummaryItemContainer>
  );
}
