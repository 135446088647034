import React, { useEffect, useState, useCallback } from "react";
import {
  CircularProgress,
  Snackbar,
  Typography,
  withStyles,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useStyles, theme } from "./config";
import { RemoteControlSessionPreview } from "./components/RemoteControlSessionPreview";
import { RemoteControlSessionMenu } from "./components/RemoteControlSessionMenu";
import {
  checkCreatedRemoteSession,
  createRemoteControlSession,
  createSession,
  deleteRemoteControlSessionVideo,
  getCoachData,
  getRemoteControlSessionById,
  sendRemoteControlSessionCommand,
  resetRemoteControlSession,
  updateRemoteControlSession,
  sendRemoteControlSessionCommandToAllDevices,
} from "./api";
import { FlexWrap, Pad } from "../../components";
import { CreateSessionModal } from "./components/CreateSessionModal";
import { RemoteControlSessionCompleteModal } from "./components/RemoteControlSessionCompleteModal";
import admin from "../../common/firebase";
import { ChangeStudentPrompt } from "./components/ChangeStudentPrompt";
import { RemoteControlSessionDeviceModal } from "./components/RemoteControlSessionDeviceSelectModal";
import { useNetwork } from "../../helpers/useNetwork";

const RemoteControlSession = (props) => {
  const [coachData, setCoachData] = useState(null);
  const [preview, setPreview] = useState([]);
  const [init, setInit] = useState(null);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [deviceModal, setDeviceModal] = useState(false);
  const [completeModal, setCompleteModal] = useState(false);
  const [toast, setToast] = useState({ open: false, text: "" });
  const [menuData, setMenuData] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState("");
  const [form, setForm] = useState({
    student: "none",
    golfClub: "7i",
  });
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [sendSettingsUpdateCommand, setSendSettingsUpdateCommand] =
    useState(false);

  const isOnline = useNetwork(window.navigator.onLine);

  useEffect(() => {
    if (!isOnline) setToast({ open: true, text: "You are offline" });
  }, [isOnline]);

  useEffect(() => {
    (async () => {
      if (!coachData) {
        loadingWrapper(setLoading, async () => {
          const foundCoachData = await getCoachData();
          setCoachData(foundCoachData);

          const persistedForm = localStorage.getItem("remoteControlForm");
          if (persistedForm) {
            const parsedPersistedForm = JSON.parse(persistedForm);
            setForm(parsedPersistedForm);
          } else {
            if (foundCoachData.students.length === 1) {
              setForm({
                student: foundCoachData.students[0].uid,
                golfClub: "7i",
              });
            }
          }
          if (
            foundCoachData?.devices &&
            foundCoachData?.devices?.length === 1
          ) {
            handleDevicesSelectConfirm([
              {
                ...foundCoachData?.devices[0],
                form: {
                  lighting: "",
                  brightness: 100,
                  zoom: 1,
                  isFrontCameraEnabled: false,
                  angle: "FaceOn",
                },
                cameraCapabilities: {
                  brightnessMin: 0,
                  brightnessMax: 0,
                  zoomRangeMin: 0,
                  zoomRangeMax: 0,
                },
              },
            ]);
          }

          const initData = await checkCreatedRemoteSession();
          if (initData) {
            const { remoteDevices, studentId, golfClub } = initData;
            setSelectedDevices(
              remoteDevices.map((el) => {
                const form =
                  el.logs.length !== 0
                    ? el.logs[el.logs.length - 1].body
                    : {
                        lighting: "",
                        brightness: 10,
                        zoom: 1,
                        brightness: 10,
                        zoom: 1,
                        isFrontCameraEnabled: false,
                        angle: "FaceOn",
                        angle: "FaceOn",
                      };
                const foundDevice = foundCoachData.devices.find(
                  (coachDevice) => coachDevice.deviceId === el.deviceId
                );
                return {
                  ...foundDevice,
                  form,
                  cameraCapabilities: el.cameraCapabilities,
                };
              })
            );
            setSelectedDevice(remoteDevices[0].deviceId);
            setForm({ student: studentId, golfClub });
            setInit(initData);
            setPreview(initData.recordedVideos);
          }
        });
      }
    })();
  }, []);

  useEffect(() => {
    if (init && init.id) {
      const unsubscribe = admin
        .firestore()
        .collection("remoteControlSession")
        .doc(init.id)
        .onSnapshot((data) => {
          const newData = data.data();

          if (newData) {
            setSelectedDevices((prev) =>
              prev.map((el) => {
                const found = newData.remoteDevices.find(
                  (fnd) => fnd.deviceId === el.deviceId
                );
                if (found) {
                  return {
                    ...el,
                    cameraCapabilities: found.cameraCapabilities
                      ? found.cameraCapabilities
                      : {
                          brightnessMin: 0,
                          brightnessMax: 0,
                          zoomRangeMin: 0,
                          zoomRangeMax: 0,
                        },
                  };
                } else {
                  return el;
                }
              })
            );
          }
          if (newData && newData.status === "completed") {
            setCompleteModal(true);
            setInit(null);
            setPreview([]);
          }
        });
      return () => unsubscribe();
    }
  }, [init]);

  useEffect(() => {
    let debounceTimer;
    const handleSettingsChangeAsync = async () => {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(async () => {
        await Promise.all(
          selectedDevices.map(async (el) => {
            const { brightness, zoom, angle, isFrontCameraEnabled } = el.form;

            if (
              init?.id &&
              (brightness || brightness === 0) &&
              isFrontCameraEnabled !== undefined &&
              zoom &&
              angle &&
              sendSettingsUpdateCommand
            ) {
              await sendRemoteControlSessionCommand(init.id, {
                deviceId: el.deviceId,
                command: "settingsUpdate",
                body: {
                  brightness,
                  zoom,
                  isFrontCameraEnabled,
                  angle,
                },
              });
            }
            return;
          })
        );
        setSendSettingsUpdateCommand(false);
      }, 1000);
    };
    handleSettingsChangeAsync();
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [init, selectedDevice, selectedDevices, sendSettingsUpdateCommand]);

  useEffect(() => {
    if (init?.id && form.golfClub) {
      (async () => {
        await updateRemoteControlSession(init.id, { golfClub: form.golfClub });
      })();
    }
  }, [form.golfClub, init]);

  useEffect(() => {
    // console.log("hahahaha", selectedDevices[0]);
  }, [selectedDevices]);

  const handleSelectChange = (e, name, id) => {
    console.log(e, name, id);
    setSelectedDevices((prev) =>
      prev.map((el) => {
        if (el.deviceId === id) {
          if (name === "isFrontCameraEnabled" && e.value === true) {
            return {
              ...el,
              form: { ...el.form, isFrontCameraEnabled: true, zoom: 1 },
            };
          } else {
            return {
              ...el,
              form: { ...el.form, [name]: e.value },
            };
          }
        } else {
          return el;
        }
      })
    );
    setSendSettingsUpdateCommand(true);
  };

  const handleSessionEndModalClose = () => {
    window.location.reload(false);
  };

  const handleStudentChange = (e) => {
    setForm((prev) => ({ ...prev, student: e.value }));
  };

  const handleClubChange = (e) => {
    setForm((prev) => ({ ...prev, golfClub: e.value }));
  };

  const handleDevicesSelectConfirm = useCallback(
    async (data) => {
      const settingsPersistance = JSON.parse(
        localStorage.getItem("remoteControlSettings")
      );

      const persistedDevices = data.map((el) => {
        const found = settingsPersistance?.find(
          (pers) => el.deviceId === pers.deviceId
        );

        if (found) {
          return { ...el, form: found.form };
        } else {
          return el;
        }
      });

      setSelectedDevices(persistedDevices);
      setSelectedDevice(data[0].deviceId);
      if (init?.id) {
        await updateRemoteControlSession(init.id, {
          remoteDevices: data.map((el) => ({
            deviceId: el.deviceId,
            recordingPosition: "",
            isOnline: false,
            logs: [],
            cameraCapabilities: {
              brightnessMin: 0,
              brightnessMax: 0,
              zoomRangeMin: 0,
              zoomRangeMax: 0,
            },
          })),
        });
      } else {
        const { student, golfClub } = form;

        const createData = {
          studentId: student,
          golfClub,
          remoteDevices: data.map((el) => ({
            deviceId: el.deviceId,
            recordingPosition: "",
            isOnline: false,
            logs: [],
            cameraCapabilities: {
              brightnessMin: 0,
              brightnessMax: 0,
              zoomRangeMin: 0,
              zoomRangeMax: 0,
            },
          })),
        };
        const created = await createRemoteControlSession(createData);
        const createdData = await getRemoteControlSessionById(created.id);
        setInit(createdData);
      }
    },
    [form, init]
  );

  const handleRemoteControlSessionStart = useCallback(
    async (data) => {
      await Promise.all(
        selectedDevices.map(async (el) => {
          const { brightness, zoom, isFrontCameraEnabled, angle } = el.form;

          await sendRemoteControlSessionCommand(init.id, {
            deviceId: el.deviceId,
            command: "settingsUpdate",
            body: {
              brightness,
              zoom,
              isFrontCameraEnabled,
              angle,
            },
          });

          await sendRemoteControlSessionCommand(init.id, {
            deviceId: el.deviceId,
            command: "startRecording",
            body: {
              brightness,
              zoom,
              isFrontCameraEnabled,
              angle,
            },
          });
        })
      );

      // const persisted = JSON.parse(
      //   localStorage.getItem("remoteControlSettings")
      // );

      // const toPersist = persisted.map((persistedItem) => {
      //   const found = selectedDevices.find(
      //     (el) => el.deviceId === persistedItem.deviceId
      //   );

      //   if (found) {
      //     return { deviceId: found.deviceId, form: found.form };
      //   } else {
      //     return persistedItem;
      //   }
      // });

      // const newDevices = selectedDevices.filter((el) => {
      //   const found = toPersist.find((pers) => pers.deviceId === el.deviceId);

      //   return !found;
      // });

      localStorage.setItem("remoteControlForm", JSON.stringify(form));
      localStorage.setItem(
        "remoteControlSettings",
        JSON.stringify(
          selectedDevices.map((el) => ({
            deviceId: el.deviceId,
            form: el.form,
          }))
        )
      );
    },
    [init, form, selectedDevices, selectedDevice]
  );

  const handleRemoteControlSessionStop = useCallback(
    async (data) => {
      const foundDevice = selectedDevices.find(
        (el) => el.deviceId === selectedDevice
      );
      const { brightness, zoom, isFrontCameraEnabled, angle } =
        foundDevice.form;
      await sendRemoteControlSessionCommandToAllDevices(init.id, {
        command: "stopRecording",
        body: {
          brightness,
          zoom,
          isFrontCameraEnabled,
          angle,
        },
      });
    },
    [init, selectedDevices, selectedDevice]
  );

  const handleRemoteControlSessionSettings = useCallback(
    async (data) => {
      const { deviceId, command, golfClub } = data;
      await sendRemoteControlSessionCommand(init.id, {
        deviceId,
        command,
        body: { golfClub },
      });
    },
    [init]
  );

  const handleVideoDelete = useCallback(
    async (videoPath) => {
      const upd = await deleteRemoteControlSessionVideo(init.id, videoPath);
      setPreview(upd.recordedVideos);
    },
    [init]
  );

  const handleSessionCreate = useCallback(async (data) => {
    setPreview([]);
    await createSession(data);
    setModal(false);
    setCompleteModal(true);
  });

  const handleSessionReset = useCallback(async () => {
    await sendRemoteControlSessionCommandToAllDevices(init.id, {
      command: "endSession",
      body: {
        angle: "",
        autoSwing: "",
        zoom: "",
        brightness: "",
      },
    });
    await resetRemoteControlSession(init.id);
    // setSelectedDevices([]);
    // setForm({ golfClub: "7i", student: "none" });
    // setInit(null);
    // setPreview([]);
    window.location.reload(false);
  }, [init, selectedDevices, selectedDevice]);

  const handleDevicesRefresh = useCallback(async () => {
    const coachData = await getCoachData();
    setCoachData((prev) => ({ ...prev, devices: coachData.devices }));
    setToast({ open: true, text: "Devices Refreshed" });
  });

  const handleStudentsRefresh = useCallback(async () => {
    const coachData = await getCoachData();
    setCoachData((prev) => ({ ...prev, students: coachData.students }));
    setToast({ open: true, text: "Students Refreshed" });
  });

  const loadingWrapper = async (setLoadingFunc, execFunc) => {
    setLoadingFunc(true);
    await execFunc();
    setLoadingFunc(false);
  };

  return (
    <>
      <div>
        {loading ? (
          <div
            style={{
              height: "100vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : coachData.devices && coachData.devices.length !== 0 ? (
          <>
            <div
              style={{
                display: "flex",
                padding: "50px",
                width: "100%",
                height: "calc(100vh + 200px)",
              }}
            >
              <Pad width="50%">
                <FlexWrap align="start" direction="column">
                  <RemoteControlSessionMenu
                    init={init}
                    startFn={handleRemoteControlSessionStart}
                    stopFn={handleRemoteControlSessionStop}
                    settingsFn={handleRemoteControlSessionSettings}
                    devicesRefreshFn={handleDevicesRefresh}
                    studentsRefreshFn={handleStudentsRefresh}
                    devices={selectedDevices}
                    selectedDevice={selectedDevice}
                    students={[...coachData.students, coachData]}
                    onDeviceModalOpen={() => setDeviceModal(true)}
                    form={form}
                    onSettingsChange={handleSelectChange}
                    onStudentChange={handleStudentChange}
                    onClubChange={handleClubChange}
                    onMenuChange={setMenuData}
                    onDeviceChange={(e) => setSelectedDevice(e.value)}
                  />
                </FlexWrap>
              </Pad>
              <div
                style={{
                  height: "100%",
                  outline: "1px black solid",
                  margin: "0 10px",
                }}
              />

              <Pad width="50%">
                <FlexWrap align="start" direction="column">
                  <RemoteControlSessionPreview
                    data={preview}
                    init={init}
                    deleteFn={handleVideoDelete}
                    resetFn={handleSessionReset}
                    createFn={() => setModal(true)}
                  />
                </FlexWrap>
              </Pad>
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "50px",
                width: "100%",
                height: "100vh",
              }}
            >
              <div style={{ fontWeight: "bold", fontSize: "20px" }}>
                You don't have any devices
              </div>
            </div>
          </>
        )}
      </div>
      {/* <button onClick={() => setCompleteModal(true)}>test</button> */}

      <CreateSessionModal
        coachRef={coachData}
        sessionRef={init}
        open={modal}
        onClose={() => setModal(false)}
        onCreate={handleSessionCreate}
      />
      <RemoteControlSessionCompleteModal
        open={completeModal}
        onClose={handleSessionEndModalClose}
      />
      <RemoteControlSessionDeviceModal
        onConfirm={handleDevicesSelectConfirm}
        devices={coachData?.devices}
        sessionSelectedDevices={selectedDevices}
        open={deviceModal}
        onClose={() => setDeviceModal(false)}
      />
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={toast.open}
        autoHideDuration={6000}
        onClose={() => setToast({ open: false, text: "" })}
        message={toast.text}
      />
    </>
  );
};

RemoteControlSession.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
};

export default withStyles(useStyles)(RemoteControlSession);
