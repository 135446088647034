import React, { useState, useEffect } from 'react';
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Button } from '@material-ui/core';
import Swal from 'sweetalert2';
//import { StripeAddressForm } from '../stripeAddressForm';

function StripePaymentFormComponent({ clientSecret, handleResult, useConfirmation, amount }) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
            setMessage("");
            break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (useConfirmation) {
      const resultConfirmation = await Swal.fire({
        title: `<p style="font-size:70%;">Are you sure you want to pay $${amount}?</p>`,
        icon: 'warning',
        confirmButtonText: 'Yes',
        showCancelButton: true,
        cancelButtonText: 'No',
        cancelButtonColor: 'red',
        allowOutsideClick: false
      });
      if (!resultConfirmation.isConfirmed) {
        return;
      }
    }

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);
    setMessage('');

    const {error: submitError} = await elements.submit();
    if (submitError) {
      setMessage(submitError.message);
      setIsLoading(false);
      return;
    }

    const paymentResult = await stripe.confirmPayment({
      elements,
      clientSecret,
      redirect: "if_required",
    });

    if (paymentResult?.error) {
      if (paymentResult.error.type === "card_error" || paymentResult.error.type === "validation_error") {
        setMessage(paymentResult.error.message);
      } else {
        setMessage("An unexpected error occurred.");
      }
      setIsLoading(false);
      return
    }

    if (paymentResult?.paymentIntent.status === 'succeeded') {
      handleResult({
        amount: paymentResult.paymentIntent.amount,
        id: paymentResult.paymentIntent.id,
      });
    }

    setIsLoading(false);
  };

  return (<>
    <form id="payment-form" onSubmit={handleSubmit}>
      {/*<StripeAddressForm defaultValues={{ name: "" }} />
      <div style={{ marginTop: 20 }}></div>*/}
      <PaymentElement options={{ layout: { type: 'accordion' } }} />
      <Button
        disabled={isLoading || !stripe || !elements}
        id="submit"
        color="primary"
        fullWidth
        type="submit"
        variant="contained"
        style={{ marginTop: 20 }}
      >
        <span id="button-text">
          {isLoading && !message ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
      </Button>
      {message && <div id="payment-message" style={{ textAlign: 'center', paddingTop: '10px' }}>{message}</div>}
    </form>
  </>);
}

export const StripePaymentForm = StripePaymentFormComponent;