import React from 'react';
import PropTypes from 'prop-types';
import firebase from '../../../../common/firebase';
import { Grid, Dialog, DialogTitle, Button, FormControl, InputLabel, 
    MenuItem, Select, Container, IconButton, Tooltip, TextField } from '@material-ui/core';
import { Close, PlayCircleFilled, PauseCircleFilled, Fullscreen, 
    Redo, FullscreenExit } from '@material-ui/icons';
import { Unity, useUnityContext } from "react-unity-webgl";

const UnityPopUp = (props) => {
    const { unityProvider, sendMessage, isLoaded, unload } =
        useUnityContext({
            loaderUrl: "/Web/Build/Web.loader.js",
            dataUrl: "/Web/Build/Web.data",
            frameworkUrl: "/Web/Build/Web.framework.js",
            codeUrl: "/Web/Build/Web.wasm",
            streamingAssetsUrl: "/Web/StreamingAssets"
        });

    const [data, setData] = React.useState('');
    const [pov, setPOV] = React.useState('FACE_ON');
    const [speed, setSpeed] = React.useState('60');
    const [refModel, setRefModel] = React.useState(true);
    const [toggle, setToggle] = React.useState(true);
    const [fullScreen, setFullScreen] = React.useState(false);
    const [check, setCheck] = React.useState(true);
    const [currentFrame, setCurrentFrame] = React.useState('');
    const [success, setSuccess] = React.useState(props?.chunks?.length > 0 ? true : false);

    if (isLoaded && check) {
        setTimeout(() => {
            startUnity();
        }, 5000);
    }

    function sendJSONChunksToUnity() {
        sendMessage('AvatarController', 'SetExpectedTotalLength', String(props.chunksLength));
        if (props.chunksRef.length === 0) {
            sendMessage('AvatarController', 'SetExpectedTotalLengthRef', String(props.chunksLength));
        }
        for (let i = 0; i < props.chunks.length; i++) {
            sendMessage('AvatarController', 'ReceiveJSONChunk', props.chunks[i]);
            if (props.chunksRef.length === 0) {
                sendMessage('AvatarController', 'ReceiveJSONChunkRef', props.chunks[i]);
            }
        }
        if (props.chunksRef.length > 0) {
            sendMessage('AvatarController', 'SetExpectedTotalLengthRef', props.chunksRefLength);
            for (let i = 0; i < props.chunks.length; i++) {
                sendMessage('AvatarController', 'ReceiveJSONChunkRef', props.chunksRef[i]);
            }
        }
        setCheck(false);
    }

    async function handleClickBack() {
        await unload();
        // Ready to navigate to another page.
    }

    const onClose = async() => {
        setCheck(true)
        //await unload();
        props.onClose();
    }

    const handlePOV = (e) => {
        setPOV(e.target.value);
        sendMessage("AvatarController", "SetAvatarPosition", e.target.value);
    }

    const startUnity = () => {
        if (check) {
            if (data) {
                sendMessage("AvatarController", "RenderUserAvatarFromBlob", data);
                sendMessage("AvatarController", "RenderReferenceAvatarFromBlob", data);
                setCheck(false);
            } else {
                sendJSONChunksToUnity();
            }
        }
        
        sendMessage("AvatarController", "PlayAnimation");
        setToggle(false);
    }

    const play = () => {
        if (check) {
            if (data) {
                sendMessage("AvatarController", "RenderUserAvatarFromBlob", data);
                sendMessage("AvatarController", "RenderReferenceAvatarFromBlob", data);
                setCheck(false);
            } else {
                sendJSONChunksToUnity();
            }
        }
        
        sendMessage("AvatarController", "PlayAnimation");
        setToggle(false);
    }

    const pause = () => {
        sendMessage("AvatarController", "PauseAnimation", "");
        setToggle(true);
    }

    const restart = () => {
        sendMessage("AvatarController", "RestartAnimation");
        play();
    }

    const onFullScreen = () => {
        setFullScreen(!fullScreen);
    };

    const addReferenceModel = () => {
        sendMessage("AvatarController", "AddReferenceAvatar");
        sendMessage("AvatarController", "HideGolfClub");
        setRefModel(false);
    }

    const removeReferenceModel = () => {
        sendMessage("AvatarController", "RemoveReferenceAvatar");
        setRefModel(true);
    }

    const handleSpeed = (e) => {
        setSpeed(e.target.value)
        sendMessage("AvatarController", "SetFramePerSecond", e.target.value);
    }

    const getCurrentFrame = () => {
        //setCurrentFrame(e.target.value)
        console.log(sendMessage("AvatarController", "currentFrame"));
    }

    const handleFrameChange = (e) => {
        setCurrentFrame(e.target.value);
        const inputValue = parseInt(e.target.value, 10);

        if (!isNaN(inputValue) && inputValue >= 0 && inputValue < props?.totalFrames) {
            sendMessage("AvatarController", "SetCurrentFrame", String(inputValue));
            sendMessage("AvatarController", "SetCurrentFrameRef", String(inputValue));
        } else {
            alert("Invalid input value or out of range [0 - " + (props?.totalFrames - 1) + "]");
        }
    }
    
    let content = null
    if (props.open) {
        if (check && !props.chunks) {
            var storageRef = firebase.storage().ref().child("/swing_data/" + props.data._id);
            storageRef.listAll().then(function(res) {
                if (res.items.length === 0) {
                    alert("No analysis data found for this video")
                    props.onClose()
                    setCheck(true)
                }
                res.items.forEach(function(itemRef) {
                    itemRef.getDownloadURL().then((res) => {
                        if (res.includes('_ai.json')) {
                            setData(res);
                            setSuccess(true);
                        }
                    }).catch(function(error) {
                        console.log(error)
                        props.onClose();
                    })
                });
            }).catch(function(error) {
                console.log(error)
            });
        }
        content = (
        <div>
            {toggle === true && <Tooltip title="Play"><Button onClick={play}><PlayCircleFilled /></Button></Tooltip> }
            {toggle === false && <Tooltip title="Pause"><Button onClick={pause}><PauseCircleFilled /></Button></Tooltip> }
            <Tooltip title="Restart"><Button onClick={restart}><Redo /></Button></Tooltip>
            <Tooltip title="Full Screen"><Button onClick={onFullScreen}>{fullScreen === false && <Fullscreen />} {fullScreen === true && <FullscreenExit />}</Button></Tooltip>
            {refModel === true && <Button onClick={addReferenceModel}>Add Reference Model</Button> }
            {refModel === false && <Button onClick={removeReferenceModel}>Remove Reference Model</Button> }
            <Container
                component="main"
                style={{ padding: '20px 0' }}
            >
            <Grid
                container
                spacing={2}
            >
                <Grid
                item
                xs={4}
                >
                <FormControl
                    fullWidth
                    variant="outlined"
                >
                    <InputLabel id="pov-select">POV</InputLabel>
                    <Select
                    label="POV"
                    labelId="pov-select"
                    onChange={handlePOV}
                    value={pov}
                    >
                    <MenuItem value={'FACE_ON'}>Face On</MenuItem>
                    <MenuItem value={'FROM_TARGET'}>From Target</MenuItem>
                    <MenuItem value={'DTL'}>DTL</MenuItem>
                    <MenuItem value={'ABOVE'}>Above</MenuItem>
                    <MenuItem value={'BEHIND'}>Behind</MenuItem>
                    <MenuItem value={'BELOW'}>Below</MenuItem>
                    </Select>
                </FormControl>
                </Grid>

                <Grid
                item
                xs={4}
                >
                <FormControl
                    fullWidth
                    variant="outlined"
                >
                    <InputLabel id="speed-select">Speed</InputLabel>
                    <Select
                    label="Speed"
                    labelId="speed-select"
                    onChange={handleSpeed}
                    value={speed}
                    >
                    <MenuItem value={'15'}>0.25x</MenuItem>
                    <MenuItem value={'30'}>0.5x</MenuItem>
                    <MenuItem value={'60'}>1x</MenuItem>
                    <MenuItem value={'120'}>2x</MenuItem>
                    <MenuItem value={'180'}>3x</MenuItem>
                    </Select>
                </FormControl>
                </Grid>

                <Grid item sm={4}>
                    <TextField 
                        fullWidth
                        label={"Enter Frame # (Total: " + props?.totalFrames + ")"} 
                        variant="outlined" 
                        onChange={handleFrameChange}
                        value={currentFrame}                        
                        type="Number"
                        InputProps={{
                            inputProps: { min: 0, max: (props?.totalFrames - 1) },
                        }}
                    />
                </Grid>
            </Grid>
            </Container>
            {success && (
                <div>
                    <Unity 
                        unityProvider={unityProvider} 
                        style={{ width: "100%", height: "100%" }}
                    />
                    {/*<button onClick={handleClickBack}>Stop</button>*/}
                    {/*<iframe src="/Web/index.html" title="Unity WebGL" width="100%" height="500px" />*/}
                </div>
            )}
        </div>
        )
    }
    return props.open ? (
        <div style={{display: 'flex'}}> 
            <Dialog
                fullWidth
                fullScreen={fullScreen}
                maxWidth={"md"}
                style={{ width: '100%' }}
                open={props.open}
                onClose={onClose}
                aria-labelledby='form-dialog-title'
            >
                <DialogTitle>
                    <IconButton onClick={onClose} style={{float: 'right', marginTop: '-10px', marginRight: '-15px'}}>
                        <Close/>
                    </IconButton>
                    {content}
                </DialogTitle>
            </Dialog>
        </div>
    ) : null
};

UnityPopUp.propTypes = {
    item: PropTypes.object
};

export default UnityPopUp;
