import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import firebase from '../../common/firebase'
import { withStyles } from '@material-ui/styles';
import {
  Grid, Button, Container, Avatar, Link, Typography,
  CssBaseline, CircularProgress, Card, CardHeader,
  Divider, CardContent
} from '@material-ui/core';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import { AccountNotFound } from '../account/components';
import DisplayStudentsTable from './components/display/DisplayStudentsTable';
import { functionBaseUrl, axiosWithToken } from '../../common/firebase';
import PeopleIcon from '@material-ui/icons/People';
import AddStudent from './AddStudent';
import ImportContacts from '@material-ui/icons/ImportContacts';
import { ImportStudentsEnterprise } from './ImportStudentsEnterprise';
import Swal from 'sweetalert2';
import CreateStudent from './CreateStudent';
import NoSubscriptionDialog from '../../components/NoSubscriptionDialog';
import moment from 'moment';

const useStyles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  tables: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  inviteButtons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    marginTop: theme.spacing(4),
  },
  buttonLink: {  // This is a link component surrounding a button component
    width: '100%',
    textAlign: 'center'
  },
  linkedButton: {  // This is a button component surrounded by a link
    margin: theme.spacing(1, 0),
  },
  centeredText: {
    textAlign: 'center',
  }
});

const firestore = firebase.firestore()

class Students extends Component {
  constructor(props) {
    const firebaseUser = firebase.auth().currentUser
    super(props)
    this.state = {
      uid: firebaseUser ? firebaseUser.uid : '',
      userName: '',
      email: '',
      error: '',
      loading: true,
      success: false,
      sessionNames: [],
      sessionIds: [],
      students: [],
      cameraSetups: [],
      popUpOpen: false,
      createPopUpOpen: false,
      role: '',
      roleCheck: '',
      coachInviteCount: 0,
      studentInviteCount: 0,
      subData: {},
      subscriptionType: '',
      importPopUpOpen: false,
      coaches: [],
      invitesSent: [],
      activeSub: true,
      isStaffEnterprise: false,
      isCertified: true,
      onboardingCompleted: true,
      dialogLoading: true,
      plan: 'free',
      creditCount: 0,
    }
  }

  async componentDidMount() {
    this.loadData();
  }

  loadData() {
    if (!this.state.uid) {
      this.setState({ error: "Please log in to view your students" })
      this.setState({ loading: false })
    }
    else {
      firebase.firestore().collection("users").doc(this.state.uid).get()
        .then(async userDoc => {
          if (!userDoc.exists && this.state.uid !== "") {
            console.log('No matching documents for UID: ' + this.state.uid);
            this.setState({ error: "The signed in user does not exist" });
          }

          this.setState({ 
            role: userDoc.data().role, 
            email: userDoc.data().email, 
            userName: userDoc.data().firstName + ' ' + userDoc.data().lastName, 
            subscriptionType: userDoc.data().subscriptionType,
            creditCount: userDoc.data()?.creditCount,
          })
          if (userDoc.data() && userDoc.data().subscriptionType.includes('enterprise_master') && userDoc.data().enterpriseAccountId) {
            await axiosWithToken(functionBaseUrl + '/api/verifyStaffEnt/' + this.state.uid, {
              method: 'POST',
            })
              .then(async (res) => {
                this.setState({ isStaffEnterprise: res.data.isStaffEnterprise, isCertified: res.data.isCertified, onboardingCompleted: res.data.onboardingCompleted, dialogLoading: false })
              })
              .catch(err => {
                console.log(err)
              });
          }

          if (!userDoc.data().subscriptionType.includes('free')) {
            this.subscriptionStatus()
          }

          let tempCoaches = [{ ...userDoc.data(), id: userDoc.id }];
          if (userDoc.data()?.subscriptionType?.includes('enterprise_master')) {
            await axiosWithToken(functionBaseUrl + '/api/usersEnterprise/instructors/' + userDoc.data()?.enterpriseAccountId, {
              method: 'GET',
            })
              .then(response => {
                if (response.data && response.data.length > 0) {
                  tempCoaches.push(...response.data);
                }
              })
              .catch(err => {
                console.log(err)
              });
          }

          let tempStudents = [];
          await axiosWithToken(functionBaseUrl + '/api/v3/studentsByCoach/' + this.state.uid, {
            method: 'GET',
          })
            .then(response => {
              tempStudents = response.data;
            })
            .catch(err => {
              console.log(err)
            });

          let freeCount = 0;
          let paidCount = 0;
          for (const student of tempStudents) {
            if (student?.subscriptionType?.includes('free') || student?.subscriptionType === '') {
              freeCount++;
            } else {
              paidCount++;
            }
          }

          this.setState({ 
            coaches: tempCoaches, 
            students: tempStudents, 
            freeStudents: freeCount, 
            paidStudents: paidCount
          });

          await axiosWithToken(functionBaseUrl + '/api/invitesBySender/' + this.state.uid, {
            method: 'GET',
          })
            .then(response => {
              this.setState({
                loading: false,
                invitesSent: response.data
              })
            })
            .catch(err => {
              console.log(err)
            })

          this.setState({ loading: false });
        })
    }
  }

  subscriptionStatus = async() => {
    await axiosWithToken(functionBaseUrl + '/api/verifySub/' + this.state.uid, {
      method: 'post'
    }).then(response => {
      this.setState({
        subData: response.data.data
      })
      if (this.state.role !== 'admin' && 'success' in response.data) {
        this.setState({ activeSub: response.data.success })
      }
    })
      .catch(err => {
        console.log(err)
      });
  }

  closePopUp = () => {
    this.setState({ popUpOpen: false })
  }

  importPopUpClose = () => {
    this.setState({ importPopUpOpen: false });
  }

  openPopUpAccount = () => {
    if (this.state.role === 'admin') {
      this.setState({ createPopUpOpen: true })
      return false;
    }
    if (this.state.subscriptionType.includes('enterprise')) {
      /*if (this.state.plan !== 'free') {
        if (this.state.isStaffEnterprise) {
          if (!this.state.isCertified) {
            Swal.fire({
              title: '<p style="font-size:70%;">Please complete Sportsbox Certification to access this feature</p>',
              icon: 'warning',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              customClass : { 
                container : 'sweet-alert' 
              },
            })
            return false
          }
          if (!this.state.onboardingCompleted) {
            Swal.fire({
              title: '<p style="font-size:70%;">Please complete Onboarding session to access this feature</p>',
              icon: 'warning',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              customClass : { 
                container : 'sweet-alert' 
              },
            })
            return false
          }
        }
      }*/
      this.setState({ createPopUpOpen: true })
      return false;
    }
    if (this.state.subscriptionType.includes('student')) {
      Swal.fire({
        title: '<p style="font-size:70%;">You cannot add students with your current plan!</p>',
        icon: 'warning',
        confirmButtonText: 'Ok',
        allowOutsideClick: true,
      });
      return false;
    }
    if (this.state.subscriptionType.includes('student') === false && this.state.studentInviteCount <= this.studentInviteLimit() ||
      this.studentInviteLimit() == 'unlimited' || this.studentInviteLimit() == 'Unlimited') {
      this.setState({ createPopUpOpen: true })
    } else {
      Swal.fire({
        title: '<p style="font-size:70%;">You have exceeded your Student invite limit!</p>',
        icon: 'warning',
        confirmButtonText: 'Ok',
        allowOutsideClick: true,
      });
      return false;
    }
  }

  closeCreatePopUp = () => {
    this.setState({ createPopUpOpen: false })
    this.loadData();
  }

  studentInviteLimit = () => {
    let productId = this.state.subscriptionType
    if (!productId) {
      return ''
    }
    if (productId.includes('student') || productId === '') {
      return 0
    } else if (productId.includes('pro_lite') || productId.includes('golf_lite')) {
      return 15
    } else if (productId.includes('plus')) {
      return 150
    } else if (productId.includes('premium')) {
      return 'unlimited'
    } else if (productId.includes('enterprise')) {
      return 'Unlimited'
    } else {
      return 15
    }
  }

  openPopUpStudent = () => {
    this.checkStudentInviteCount()
    if (this.state.role === 'admin' || this.state.subscriptionType.includes('enterprise') || this.state.subscriptionType.includes('premium')) {
      this.setState({ popUpOpen: true, roleCheck: 'Sportsbox-User' })
      return false;
    }
    if (this.state.subscriptionType === '') {
      Swal.fire({
        title: '<p style="font-size:70%;">You cannot add students with your current plan!</p>',
        icon: 'warning',
        confirmButtonText: 'Ok',
        allowOutsideClick: true,
      });
      return false;
    }
    if (this.state.subscriptionType.includes('student')) {
      Swal.fire({
        title: '<p style="font-size:70%;">You cannot add students with your current plan!</p>',
        icon: 'warning',
        confirmButtonText: 'Ok',
        allowOutsideClick: true,
      });
      return false;
    }
    if (this.state.subscriptionType.includes('enterprise')) {
      this.setState({ popUpOpen: true, roleCheck: 'Sportsbox-User' })
    } else if (Object.keys(this.state.subData).length > 0 && this.state.studentInviteCount <= this.studentInviteLimit()) {
      this.setState({ popUpOpen: true, roleCheck: 'Sportsbox-User' })
    } else {
      Swal.fire({
        title: '<p style="font-size:70%;">You have exceeded your Student invite limit!</p>',
        icon: 'warning',
        confirmButtonText: 'Ok',
        allowOutsideClick: true,
      });
      return false;
    }
  }

  openPopUpCoach = () => {
    this.checkCoachInviteCount()
    if (this.state.role === 'admin') {
      this.setState({ popUpOpen: true, roleCheck: 'Sportsbox-Instructor' })
      return false;
    }
    this.setState({ popUpOpen: true, roleCheck: 'Sportsbox-Instructor' })
    /*if(this.state.role === 'Sportsbox-Instructor' && this.state.coachInviteCount <= 5) {
      this.setState({popUpOpen: true, roleCheck: 'Sportsbox-Instructor'})
    } else {
      alert("You have exceeded your Instructor invite limit")
    }*/
  }

  openPopUpImport = () => {
    this.setState({ importPopUpOpen: true });
  }

  checkStudentInviteCount = () => {
    let tempCount = []
    firebase.firestore().collection('invites').where('role', '==', 'Sportsbox-User').where('senderId', '==', this.state.uid).where('status', '!=', 'rejected').get().then(snapshot => {
      snapshot.forEach(function (doc) {
        tempCount.push({ ...doc.data(), id: doc.id });;
      });
      this.setState({ studentInviteCount: tempCount.length });
    })
      .catch(err => {
        console.log(err)
      });
  }

  requesterRole = (invite) => {
    let role = ""
    if (invite && invite.role) {
      role = invite.role
    }
    return role
  }

  inviteValidity = (invite) => {
    let date = ""
    if (invite && invite.expiredIn) {
      date = this.dateDisplay(invite.expiredIn)
    }
    return date
  }

  dateDisplay = (date) => {
    return moment(String(date), "x").format('MM/DD/YYYY');
  }

  rejectRequest = (requestId) => (event) => {
    Swal.fire({
      title: '<p style="font-size:70%;">Reject Invite</p>',
      html: '<p>Are you sure you want to reject this invite?</p>',
      icon: 'warning',
      confirmButtonText: 'Yes',
      showCancelButton: true,
      cancelButtonColor: 'red',
    })
      .then((result) => {
        if (result.isConfirmed) {
          axiosWithToken(functionBaseUrl + '/api/rejectInvite/' + requestId, {
            method: 'post',
          })
            .then(response => {
              let invitesSent = [...this.state.invitesSent];
              var sentIndex = this.state.invitesSent.map(invite => invite.id).indexOf(requestId);
              invitesSent.splice(sentIndex, 1);
              this.setState({ invitesSent: invitesSent });

              window.location.reload();
            })
        }
      })
      .catch(err => {
        console.log(err)
      });
  }

  render() {
    const classes = this.props.classes;
    return (<div>
      <Container
        className={classes.tables}
        component="main">
        <CssBaseline />
        <Grid
          style={{ display: (this.state.error) ? "none" : (this.state.loading) ? "none" : (this.state.success) ? "none" : "flex" }}
        >
          <div className={classes.tables}>
            <Avatar className={this.props.classes.avatar}>
              <PeopleIcon />
            </Avatar>
            <Typography
              component="h1"
              variant="h4"
              style={{ marginBottom: '10px' }}
            >
              STUDENTS ({this.state.students.length + ' / ' + this.studentInviteLimit()})
            </Typography>
            {this.state.isStaffEnterprise && (
              <Card style={{ minWidth: "50%" }}>
                <CardContent>
                  <Grid container justify="space-between">
                    <Typography
                      inline
                      component="h6"
                      variant="h5"
                      justify="center"
                    >
                      <b>Total Credits:</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Typography>
                    <Typography
                      inline
                      component="h6"
                      variant="h5"
                      justify="center"
                      align="right"
                    >
                      {this.state.creditCount}
                    </Typography>
                  </Grid>
                  <br />
                  <hr />
                  <br />
                  <Grid container justify="space-between">
                    <Typography
                      inline
                      component="h6"
                      variant="h5"
                      justify="center"
                    >
                      <b>Free Plan Students:</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Typography>
                    <Typography
                      inline
                      component="h6"
                      variant="h5"
                      justify="center"
                      align="right"
                    >
                      {this.state.freeStudents}
                    </Typography>
                  </Grid>
                  <Grid container justify="space-between">
                    <Typography
                      inline
                      component="h6"
                      variant="h5"
                      justify="center"
                    >
                      <b>Paid Plan Students:</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Typography>
                    <Typography
                      inline
                      component="h6"
                      variant="h5"
                      justify="center"
                      align="right"
                    >
                      {this.state.paidStudents}
                    </Typography>
                  </Grid>
                </CardContent>
              </Card>
            )}
            <br />
            {(!this.state.subscriptionType.includes('student') || this.state.role === 'admin') && (
              <Button
                variant="contained"
                color="primary"
                //className={this.props.classes.linkedButton}
                onClick={() => { this.openPopUpAccount(); this.setState({ plan: 'free' }) }}
              >
                CREATE FREE STUDENT ACCOUNT
              </Button>
            )}
            <br />
            {(!this.state.subscriptionType.includes('student')) && (
              <Button
                variant="contained"
                color="primary"
                //className={this.props.classes.linkedButton}
                //disabled={this.state.role !== 'admin' && (!this.state.isCertified || !this.state.onboardingCompleted)}
                onClick={() => { this.openPopUpAccount(); this.setState({ plan: 'student_lite' }) }}
              >
                CREATE PAID STUDENT ACCOUNT
              </Button>
            )}
            <br />
            {(this.state.subscriptionType.includes('enterprise') || this.state.role === 'admin') && (
              <Button
                variant="contained"
                color="secondary"
                onClick={this.openPopUpImport}
              >
                <ImportContacts />&nbsp;Import Student Accounts
              </Button>
            )}
            <AddStudent
              open={this.state.popUpOpen}
              onClose={this.closePopUp}
              name={this.state.userName}
              uid={this.state.uid}
              roleCheck={this.state.roleCheck}
              role={this.state.role}
              subscriptionType={this.state.subscriptionType}
              coaches={this.state.coaches}
            />
            {this.state.importPopUpOpen && (
              <ImportStudentsEnterprise
                open={this.state.importPopUpOpen}
                close={this.importPopUpClose}
                uid={this.state.uid}
                name={this.state.userName}
              />
            )}
            <CreateStudent
              open={this.state.createPopUpOpen}
              onClose={this.closeCreatePopUp}
              name={this.state.userName}
              email={this.state.email}
              uid={this.state.uid}
              subscriptionType={this.state.subscriptionType}
              plan={this.state.plan}
              students={[...new Map(this.state.students.map(item => [item['id'], item])).values()]}
              instructors={this.state.subscriptionType.includes('enterprise_master') ? this.state.coaches : null}
            />
          </div>
        </Grid>
      </Container>
      <Container
        className={classes.tables}
        component="main">
        <div className={classes.tables}>
          <div style={{ width: '80%' }}>
            {/*(this.state.isStaffEnterprise || this.state.role === 'admin') && (
              <Button
                variant="contained"
                color="primary"
                className={this.props.classes.linkedButton}
                disabled={this.state.role !== 'admin' && (!this.state.isCertified || !this.state.onboardingCompleted)}
                onClick={() => { window.location.href = window.location.href.replace('students', 'invoices/detail/last') }}
              >
                INVOICE
              </Button>
            )*/}
            <DisplayStudentsTable
              role={this.state.role}
              subscriptionType={this.state.subscriptionType}
              isStaffEnterprise={this.state.isStaffEnterprise}
              isCertified={this.state.isCertified}
              onboardingCompleted={this.state.onboardingCompleted}
              students={this.state.students}
              invites={this.state.invitesSent}
              coaches={this.state.coaches}
              coachName={this.state.userName}
              coachId={this.state.uid}
            />
          </div>
        </div>
        {this.state.subscriptionType.includes('enterprise') && (
          <div className={this.props.classes.root}>
            <Typography
              component="h1"
              variant="h4"
              style={{ marginBottom: '10px', textAlign: 'center' }}
            >
              Invites Sent
            </Typography>
            {(this.state.invitesSent.length === 0) ? "There are no invites" :
              this.state.invitesSent.map((invite) => (
                <Card style={{ maxWidth: "100%" }} key={invite.id}>
                  <CardHeader
                    title={invite.inviteeName + ' (' + invite.invitee + ')'}
                    titleTypographyProps={{ variant: "h5", color: "primary" }}
                  />
                  <Typography
                    component="h6"
                    variant="h6"
                    justify="center"
                    style={{ marginLeft: '15px', marginRight: '15px', marginTop: '-10px' }}
                  >
                    <i>{"has been invited for the role of " + this.requesterRole(invite)}</i>
                  </Typography>
                  <Typography
                    component="h6"
                    variant="h6"
                    justify="center"
                    style={{ marginLeft: '15px', marginRight: '15px', marginBottom: '10px' }}
                  >
                    {invite.status === 'pending' && invite.expiredIn > Date.now() && (
                      <>
                        <b>Valid Till: </b>{" " + this.inviteValidity(invite)}
                      </>
                    )}
                    {invite.status === 'pending' && invite.expiredIn <= Date.now() && (
                      <>
                        <b>Expired</b>
                      </>
                    )}
                    {invite.status !== 'pending' && (
                      <b>{invite.status.charAt(0).toUpperCase() + invite.status.slice(1)}</b>
                    )}
                  </Typography>
                  <Divider />
                  {invite.status === 'pending' && invite.expiredIn > Date.now() && (
                    <CardContent>
                      <Button
                        style={{ margin: '0 auto', display: "flex" }}
                        variant="contained"
                        color="secondary"
                        onClick={this.rejectRequest(invite.id)}
                      >
                        Cancel
                      </Button>
                    </CardContent>
                  )}
                </Card>
              ))}
          </div>
        )}
      </Container>

      <Container
        className={classes.tables}
        component="main">
        {/* Loading screen*/}
        <Grid
          style={{ margin: "auto", padding: 100, display: (this.state.loading) ? "block" : "none" }}
        >
          <CircularProgress />
        </Grid>

        {/* Error screen*/}
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
          style={{ display: (this.state.error && !this.state.loading) ? "block" : "none" }}
        >
          <AccountNotFound
            error={{ error: this.state.error }}
          />
        </Grid>
        <NoSubscriptionDialog
          activeSub={this.state.activeSub}
          isStaffEnterprise={this.state.isStaffEnterprise}
          isCertified={this.state.isCertified}
          onboardingCompleted={this.state.onboardingCompleted}
          role={this.state.role}
          loading={this.state.dialogLoading}
          subscriptionType={this.state.subscriptionType}
        />
      </Container>
    </div>
    );
  }
};

Students.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
};

export default withStyles(useStyles)(Students);
