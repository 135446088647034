import React, { useEffect } from 'react';
import { loadStripe } from "@stripe/stripe-js";

import Swal from 'sweetalert2';
import firebase, { functionBaseUrl } from '../../common/firebase';
import axios from 'axios';

const stripePromise = loadStripe("pk_test_51JS3jaHK8QnjuqRNSlSVNOX9mD3Be02vIQZb2S5HHozM145GjpeVvuSHadBPcQSnJfHpZvPBnPmgHeFwXqjk7M5100Ez2ZTngL");

function StripeUpdatePaymentComponent({ subscriptionId }) {

  const init = async () => {
    const firebaseUser = firebase.auth().currentUser;
    try {
      const response = await axios(functionBaseUrl + '/api/stripeUpdateCard', {
        method: 'POST',
        data: { email: firebaseUser.email, subscriptionId },
      });
      const stripe = await stripePromise;
      await stripe.redirectToCheckout({
        sessionId: response.data.id,
      });
    } catch (error) {
      Swal.fire({
        title: '<p style="font-size:70%;">Error occurred</p>',
        icon: 'warning',
        confirmButtonText: 'Ok',
        allowOutsideClick: true
      })
      return;
    }
  }

  useEffect(() => {
    init();
  }, []);

  return (<>

  </>);
}

export const StripeUpdatePayment = StripeUpdatePaymentComponent;
