import React, { useState, useEffect } from 'react';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Swal from 'sweetalert2';
import firebase, { functionBaseUrl } from '../../common/firebase';
import { StripePaymentForm } from './stripePaymentForm';
import axios from 'axios';
import { FormControlLabel, Checkbox, Button } from '@material-ui/core';
import moment from 'moment';

const stripePromise = loadStripe("pk_test_51JS3jaHK8QnjuqRNSlSVNOX9mD3Be02vIQZb2S5HHozM145GjpeVvuSHadBPcQSnJfHpZvPBnPmgHeFwXqjk7M5100Ez2ZTngL");

function StripePaymentComponent({ amount, email, handleResult, buttonText, useConfirmation }) {
  const [clientSecret, setClientSecret] = useState("");
  const [useSavedCard, setUseSavedCard] = useState(null);
  const [card, setCard] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");

  const init = async () => {
    setClientSecret("");
    const firebaseUser = firebase.auth().currentUser;
    try {
      if (useSavedCard || useSavedCard === null) {
        const responseMethods = await axios(functionBaseUrl + '/api/stripePaymentMethods', {
          method: 'POST',
          data: { email: firebaseUser.email },
        });

        if (responseMethods?.data?.paymentMethods?.data?.length > 0) {
          let cardData = responseMethods?.data?.paymentMethods?.data[0];
          const cardExpiry = moment(cardData?.card?.exp_month + '/' + cardData?.card?.exp_year, 'MM/YYYY').valueOf();
          if (cardExpiry > moment().valueOf()) {
            setCard(cardData?.card?.brand?.toUpperCase() + ' •••• ' + cardData?.card?.last4);
            setUseSavedCard(true);
            setPaymentMethod(cardData?.id);
          } else {
            setUseSavedCard(false);
          }
        }
      }
    } catch (error) {
      setUseSavedCard(false);
    }
    if (useSavedCard === null || useSavedCard === false) {
      try {
        const response = await axios(functionBaseUrl + '/api/stripeCreateIntent', {
          method: 'POST',
          data: { amount, email: email ? email : firebaseUser?.email },
        });
        setClientSecret(response.data.clientSecret);
      } catch (error) {
        Swal.fire({
          title: '<p style="font-size:70%;">Error occurred</p>',
          icon: 'warning',
          confirmButtonText: 'Ok',
          allowOutsideClick: true
        })
        return;
      }
    }
  }

  const pay = async () => {
    const firebaseUser = firebase.auth().currentUser;
    try {
      const response = await axios(functionBaseUrl + '/api/stripeCreateIntent', {
        method: 'POST',
        data: { amount, email: email ? email : firebaseUser?.email, paymentMethod },
      });
      setClientSecret(response.data.clientSecret);
      if (useSavedCard) {
        if (response.data.paymentIntent.status === 'succeeded') {
          handleResult();
        } else {
          Swal.fire({
            title: '<p style="font-size:70%;">Payment failed, please try again.</p>',
            icon: 'warning',
            confirmButtonText: 'Ok',
            allowOutsideClick: true
          })
        }
      }
    } catch (error) {
      Swal.fire({
        title: '<p style="font-size:70%;">Error occurred</p>',
        icon: 'warning',
        confirmButtonText: 'Ok',
        allowOutsideClick: true
      })
      return;
    }
  }

  useEffect(() => {
    init();
  }, [amount]);

  return (<>
    {card.length > 0 && (<div><FormControlLabel
      control={
        <Checkbox
          color="primary"
          onClick={() => setUseSavedCard(!useSavedCard)}
          checked={useSavedCard ? true : false}
          value={useSavedCard ? true : false}
        />
      }
      label={<div>Use saved card: <b>{card}</b></div>}
    /><br />
    {useSavedCard && (<Button
      disabled={useSavedCard === null || useSavedCard === false}
      color="primary"
      fullWidth
      onClick={pay}
      variant="contained"
      style={{ marginTop: 20 }}
    >
      <span id="button-text">
        Pay now
      </span>
    </Button>)}<br /></div>)}
    {card.length > 0 && !useSavedCard && ( <div><b>or enter card details</b><br /><br /><br /></div> )}
    {clientSecret && !useSavedCard && (
      <Elements
        options={{
          clientSecret,
          appearance: {
            theme: 'stripe',
          },
        }}
        stripe={stripePromise}
      >
        <StripePaymentForm 
          clientSecret={clientSecret} 
          handleResult={handleResult}
          buttonText={buttonText}
          useConfirmation={useConfirmation}
          amount={amount} 
        />
      </Elements>
    )}
  </>);
}

export const StripePayment = StripePaymentComponent;