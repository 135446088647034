import { TextField } from "@material-ui/core";
import React, { useState } from "react";
import { v4 } from "uuid";
import { FlexWrap, Pad } from "../../../../components";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import { AddKeyPoint } from "./styled";
import { useEffect } from "react";

export function KeyPoints({ form, onChange, onAdd, onRemove }) {
  const find = (id) => form.find((el) => el.id === id);

  return (
    <>
      {form.map((el) => (
        <FlexWrap
          style={{ marginBottom: "10px" }}
          key={el.id}
          justify="space-between"
        >
          <TextField
            style={{ background: "#FFF", width: "94%" }}
            value={find(el.id).value}
            onChange={(e) => onChange(e, el.id)}
            variant="outlined"
            name="keyPointsText"
            label="Key Point"
          />
          <RemoveCircleOutlineIcon
            style={{ cursor: "pointer" }}
            onClick={() => onRemove(el.id)}
          />
        </FlexWrap>
      ))}
      <Pad height="10px" />
      <AddKeyPoint onClick={onAdd}>Add Key Point</AddKeyPoint>
    </>
  );
}
