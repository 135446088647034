import React, { useState, useEffect } from "react";
import {
  Container,
  FormControl,
  Grid,
  InputLabel,
  IconButton,
  Button,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  CircularProgress,
} from "@material-ui/core";
import { DateRange } from "@material-ui/icons";
import { Bar } from "react-chartjs-2";
import { DateRangePicker } from "react-date-range";
import moment from "moment";
import { functionBaseUrl } from "../../common/firebase";
import axios from "axios";
import {
  parse,
  format,
  startOfDay,
  endOfDay,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  eachDayOfInterval,
  eachWeekOfInterval,
  eachMonthOfInterval,
  eachYearOfInterval,
  getISOWeek,
  getISOWeekYear,
  getYear,
  addWeeks,
} from "date-fns";
import { listOfSubscriptions } from "../../common/envConfig";

function sum(arr, n) {
  var total = 0;
  if (n <= arr.length) {
    for (var i = 0; i < n; i++) {
      total += arr[i];
    }
  }
  return total;
}

function zeroPad(num, places) {
  var zero = places - num.toString().length + 1;
  return Array(+(zero > 0 && zero)).join("0") + num;
}

function SummaryChartsComponent({
  users,
  sessions,
  videos,
  subscriptions,
  recurringSubscriptions,
  canceledSubscriptions,
  staffAccounts,
  deviceLimitExceptionList,
}) {
  const [statType, setStatType] = useState("recurringSubscriptions");
  const [statPeriod, setStatPeriod] = useState("byDays");
  const [data, setData] = useState({});
  const [options, setOptions] = useState({});
  const [openRangeDialog, setOpenRangeDialog] = useState(false);
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(Date.now() - 6 * 24 * 60 * 60 * 1000).setHours(0, 0, 0),
    endDate: new Date().setHours(23, 59, 59),
    key: "range1",
  });
  const [dates, setDates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [issues, setIssues] = useState([]);
  const [issuesFON, setIssuesFON] = useState([]);
  const [issuesDTL, setIssuesDTL] = useState([]);
  const [analysis, setAnalysis] = useState([]);
  const [analysisFON, setAnalysisFON] = useState([]);
  const [analysisDTL, setAnalysisDTL] = useState([]);
  const [tempUsers, setUsers] = useState([]);
  const [tempCoaches, setCoaches] = useState([]);
  const [tempStudents, setStudents] = useState([]);
  const [tempSessions, setSessions] = useState([]);
  const [tempVideos, setVideos] = useState([]);
  const [videosFON, setVideosFON] = useState([]);
  const [videosDTL, setVideosDTL] = useState([]);
  const [avgCS, setavgCS] = useState([]);
  const [avgCSP, setavgCSP] = useState([]);
  const [issuesPercentage, setIssuesPercentage] = useState([]);
  const [issuesFONPercentage, setIssuesFONPercentage] = useState([]);
  const [issuesDTLPercentage, setIssuesDTLPercentage] = useState([]);
  const [analysisPercentage, setAnalysisPercentage] = useState([]);
  const [analysisFONPercentage, setAnalysisFONPercentage] = useState([]);
  const [analysisDTLPercentage, setAnalysisDTLPercentage] = useState([]);
  //const [tempSubscriptions, setSubscriptions] = useState([]);

  const handleStatType = (e) => {
    setStatType(e.target.value);
    getDataWithRange(e.target.value, {}, statPeriod);
  };
  const handleStatPeriod = (e) => {
    setStatPeriod(e.target.value);
    getDataWithRange(statType, {}, e.target.value);
  };

  const getDataWithRange = async (collection, range, period) => {
    setLoading(true);

    const must_not_terms = ["*sb3d*", "*3dsb*", "*sportsbox*", "*demo*"];
    const must_not = must_not_terms.map((term) => {
      return { wildcard: { email: term } };
    });
    if (deviceLimitExceptionList && deviceLimitExceptionList.length > 0)
      must_not.push({ terms: { "email.keyword": deviceLimitExceptionList } });
    let dates = [];
    if (Object.keys(range).length > 0) {
      dates = getAllDates(
        String(range.startDate.valueOf()),
        String(range.endDate.valueOf()),
        period.toLowerCase().substring(2)
      );
    } else {
      dates = getAllDates(
        String(selectionRange.startDate.valueOf()),
        String(selectionRange.endDate.valueOf()),
        period.toLowerCase().substring(2)
      );
    }
    setDates(dates);

    const analysisParentQuery = {
      has_parent: {
        parent_type: "videos",
        query: {
          has_parent: {
            parent_type: "sessions",
            query: {
              has_parent: {
                parent_type: "users",
                query: { bool: { must_not: must_not } },
              },
            },
          },
        },
      },
    };

    if (collection === "analysis" || collection === "issues") {
      setIssues([]);
      setIssuesFON([]);
      setIssuesDTL([]);
      setAnalysis([]);
      setAnalysisFON([]);
      setAnalysisDTL([]);

      let analysisData = [];
      let analysisDataFON = [];
      let analysisDataDTL = [];
      let issuesData = [];
      let issuesDataFON = [];
      let issuesDataDTL = [];

      for (const date of dates) {
        let dateRange = getDateRange(date);
        /*let analysisSnap = await axios(functionBaseUrl + '/api/elastic/collection/size/filter', {
          method: 'POST',
          data: {
            collection: "analysis",
            query: {
              "bool": {
                "must": [{ "match": { "docType": "analysis" } }],
                "filter": [{ "range": { "createdDate": { "gte": String(dateRange.start), "lte": String(dateRange.end) } } },
                  analysisParentQuery]
              }
            }
          }
        });
        analysisData.push(analysisSnap?.data?.count)*/

        let analysisSnapFON = await axios(
          functionBaseUrl + "/api/elastic/collection/size/filter",
          {
            method: "POST",
            data: {
              collection: "analysis",
              query: {
                bool: {
                  must: [
                    {
                      match: {
                        docType: "analysis",
                      },
                    },
                    {
                      bool: {
                        should: [
                          {
                            bool: {
                              must_not: {
                                exists: { field: "videoMetadata.cameraAngle" },
                              },
                            },
                          },
                          {
                            wildcard: {
                              "videoMetadata.cameraAngle.keyword": "*FaceOn*",
                            },
                          },
                          {
                            wildcard: {
                              "videoMetadata.cameraAngle.keyword": "*FON*",
                            },
                          },
                          {
                            wildcard: {
                              "videoMetadata.cameraAngle.keyword": "*",
                            },
                          },
                        ],
                        minimum_should_match: 1,
                      },
                    },
                  ],
                  filter: [
                    {
                      range: {
                        createdDate: {
                          gte: String(dateRange.start),
                          lte: String(dateRange.end),
                        },
                      },
                    },
                    analysisParentQuery,
                  ],
                },
              },
            },
          }
        );
        analysisDataFON.push(analysisSnapFON?.data?.count);

        let analysisSnapDTL = await axios(
          functionBaseUrl + "/api/elastic/collection/size/filter",
          {
            method: "POST",
            data: {
              collection: "analysis",
              query: {
                bool: {
                  must: [
                    {
                      match: {
                        docType: "analysis",
                      },
                    },
                    {
                      bool: {
                        should: [
                          {
                            wildcard: {
                              "videoMetadata.cameraAngle.keyword":
                                "*DownTheLine*",
                            },
                          },
                          {
                            wildcard: {
                              "videoMetadata.cameraAngle.keyword": "*DTL*",
                            },
                          },
                        ],
                        minimum_should_match: 1,
                      },
                    },
                  ],
                  filter: [
                    {
                      range: {
                        createdDate: {
                          gte: String(dateRange.start),
                          lte: String(dateRange.end),
                        },
                      },
                    },
                    analysisParentQuery,
                  ],
                },
              },
            },
          }
        );
        analysisDataDTL.push(analysisSnapDTL?.data?.count);
        analysisData.push(
          analysisSnapFON?.data?.count + analysisSnapDTL?.data?.count
        );

        /*let issuesSnap = await axios(functionBaseUrl + '/api/elastic/collection/size/filter', {
          method: 'POST',
          data: {
            collection: "reportIssues",
            query: {
              "bool": {
                "filter": [{ "range": { "createdDate": { "gte": String(dateRange.start), "lte": String(dateRange.end) } } }]
              }
            }
          }
        });
        issuesData.push(issuesSnap?.data?.count)*/

        let issuesSnapFON = await axios(
          functionBaseUrl + "/api/elastic/collection/size/filter",
          {
            method: "POST",
            data: {
              collection: "reportIssues",
              query: {
                bool: {
                  must: [
                    {
                      bool: {
                        should: [
                          {
                            bool: {
                              must_not: { exists: { field: "cameraAngle" } },
                            },
                          },
                          { wildcard: { "cameraAngle.keyword": "*FaceOn*" } },
                          { wildcard: { "cameraAngle.keyword": "*FON*" } },
                          { wildcard: { "cameraAngle.keyword": "*" } },
                        ],
                        minimum_should_match: 1,
                      },
                    },
                  ],
                  filter: [
                    {
                      range: {
                        createdDate: {
                          gte: String(dateRange.start),
                          lte: String(dateRange.end),
                        },
                      },
                    },
                  ],
                },
              },
            },
          }
        );
        issuesDataFON.push(issuesSnapFON?.data?.count);

        let issuesSnapDTL = await axios(
          functionBaseUrl + "/api/elastic/collection/size/filter",
          {
            method: "POST",
            data: {
              collection: "reportIssues",
              query: {
                bool: {
                  must: [
                    {
                      bool: {
                        should: [
                          {
                            wildcard: {
                              "cameraAngle.keyword": "*DownTheLine*",
                            },
                          },
                          { wildcard: { "cameraAngle.keyword": "*DTL*" } },
                        ],
                        minimum_should_match: 2,
                      },
                    },
                  ],
                  filter: [
                    {
                      range: {
                        createdDate: {
                          gte: String(dateRange.start),
                          lte: String(dateRange.end),
                        },
                      },
                    },
                  ],
                },
              },
            },
          }
        );
        issuesData.push(
          issuesSnapFON?.data?.count + issuesSnapDTL?.data?.count
        );
        issuesDataDTL.push(issuesSnapDTL?.data?.count);
      }

      setIssues(issuesData);
      setIssuesFON(issuesDataFON);
      setIssuesDTL(issuesDataDTL);
      setAnalysis(analysisData);
      setAnalysisFON(analysisDataFON);
      setAnalysisDTL(analysisDataDTL);
    }

    if (
      collection === "analysisByPercentage" ||
      collection === "issuesByPercentage"
    ) {
      setIssuesPercentage([]);
      setIssuesFONPercentage([]);
      setIssuesDTLPercentage([]);
      setAnalysisPercentage([]);
      setAnalysisFONPercentage([]);
      setAnalysisDTLPercentage([]);

      let analysisData = [];
      let analysisDataFON = [];
      let analysisDataDTL = [];
      let issuesData = [];
      let issuesDataFON = [];
      let issuesDataDTL = [];
      for (const date of dates) {
        let dateRange = getDateRange(date);
        /*let analysisSnap = await axios(functionBaseUrl + '/api/elastic/collection/size/filter', {
          method: 'POST',
          data: {
            collection: "analysis",
            query: {
              "bool": {
                "must": [{ "match": { "docType": "analysis" } }],
                "filter": [{ "range": { "createdDate": { "gte": String(dateRange.start), "lte": String(dateRange.end) } } },
                  analysisParentQuery]
              }
            }
          }
        });
        analysisData.push(analysisSnap?.data?.count)*/

        let analysisSnapFON = await axios(
          functionBaseUrl + "/api/elastic/collection/size/filter",
          {
            method: "POST",
            data: {
              collection: "analysis",
              query: {
                bool: {
                  must: [
                    {
                      match: {
                        docType: "analysis",
                      },
                    },
                    {
                      bool: {
                        should: [
                          {
                            bool: {
                              must_not: {
                                exists: { field: "videoMetadata.cameraAngle" },
                              },
                            },
                          },
                          {
                            wildcard: {
                              "videoMetadata.cameraAngle.keyword": "*FaceOn*",
                            },
                          },
                          {
                            wildcard: {
                              "videoMetadata.cameraAngle.keyword": "*FON*",
                            },
                          },
                          {
                            wildcard: {
                              "videoMetadata.cameraAngle.keyword": "*",
                            },
                          },
                        ],
                        minimum_should_match: 1,
                      },
                    },
                  ],
                  filter: [
                    {
                      range: {
                        createdDate: {
                          gte: String(dateRange.start),
                          lte: String(dateRange.end),
                        },
                      },
                    },
                    analysisParentQuery,
                  ],
                },
              },
            },
          }
        );

        let analysisSnapDTL = await axios(
          functionBaseUrl + "/api/elastic/collection/size/filter",
          {
            method: "POST",
            data: {
              collection: "analysis",
              query: {
                bool: {
                  must: [
                    {
                      match: {
                        docType: "analysis",
                      },
                    },
                    {
                      bool: {
                        should: [
                          {
                            wildcard: {
                              "videoMetadata.cameraAngle.keyword":
                                "*DownTheLine*",
                            },
                          },
                          {
                            wildcard: {
                              "videoMetadata.cameraAngle.keyword": "*DTL*",
                            },
                          },
                        ],
                        minimum_should_match: 1,
                      },
                    },
                  ],
                  filter: [
                    {
                      range: {
                        createdDate: {
                          gte: String(dateRange.start),
                          lte: String(dateRange.end),
                        },
                      },
                    },
                    analysisParentQuery,
                  ],
                },
              },
            },
          }
        );
        let analysisTotal =
          analysisSnapFON?.data?.count + analysisSnapDTL?.data?.count;
        analysisData.push(analysisTotal);
        analysisDataFON.push(
          (Math.round((analysisSnapFON?.data?.count / analysisTotal) * 100) /
            100) *
            100
        );
        analysisDataDTL.push(
          (Math.round((analysisSnapDTL?.data?.count / analysisTotal) * 100) /
            100) *
            100
        );

        /*let issuesSnap = await axios(functionBaseUrl + '/api/elastic/collection/size/filter', {
          method: 'POST',
          data: {
            collection: "reportIssues",
            query: {
              "bool": {
                "filter": [{ "range": { "createdDate": { "gte": String(dateRange.start), "lte": String(dateRange.end) } } }]
              }
            }
          }
        });
        issuesData.push(issuesSnap?.data?.count)*/

        let issuesSnapFON = await axios(
          functionBaseUrl + "/api/elastic/collection/size/filter",
          {
            method: "POST",
            data: {
              collection: "reportIssues",
              query: {
                bool: {
                  must: [
                    {
                      bool: {
                        should: [
                          {
                            bool: {
                              must_not: { exists: { field: "cameraAngle" } },
                            },
                          },
                          { wildcard: { "cameraAngle.keyword": "*FaceOn*" } },
                          { wildcard: { "cameraAngle.keyword": "*FON*" } },
                          { wildcard: { "cameraAngle.keyword": "*" } },
                        ],
                        minimum_should_match: 1,
                      },
                    },
                  ],
                  filter: [
                    {
                      range: {
                        createdDate: {
                          gte: String(dateRange.start),
                          lte: String(dateRange.end),
                        },
                      },
                    },
                  ],
                },
              },
            },
          }
        );

        let issuesSnapDTL = await axios(
          functionBaseUrl + "/api/elastic/collection/size/filter",
          {
            method: "POST",
            data: {
              collection: "reportIssues",
              query: {
                bool: {
                  must: [
                    {
                      bool: {
                        should: [
                          {
                            wildcard: {
                              "cameraAngle.keyword": "*DownTheLine*",
                            },
                          },
                          { wildcard: { "cameraAngle.keyword": "*DTL*" } },
                        ],
                        minimum_should_match: 1,
                      },
                    },
                  ],
                  filter: [
                    {
                      range: {
                        createdDate: {
                          gte: String(dateRange.start),
                          lte: String(dateRange.end),
                        },
                      },
                    },
                  ],
                },
              },
            },
          }
        );
        let issuesTotal =
          issuesSnapFON?.data?.count + issuesSnapDTL?.data?.count;
        issuesData.push(issuesTotal);
        issuesDataFON.push(
          (Math.round((issuesSnapFON?.data?.count / issuesTotal) * 100) / 100) *
            100
        );
        issuesDataDTL.push(
          (Math.round((issuesSnapDTL?.data?.count / issuesTotal) * 100) / 100) *
            100
        );
      }

      setIssuesPercentage(issuesData);
      setIssuesFONPercentage(issuesDataFON);
      setIssuesDTLPercentage(issuesDataDTL);
      setAnalysisPercentage(analysisData);
      setAnalysisFONPercentage(analysisDataFON);
      setAnalysisDTLPercentage(analysisDataDTL);
    }

    if (collection === "users") {
      setUsers([]);
      setCoaches([]);
      setStudents([]);

      let usersData = [];
      let coachesData = [];
      let studentsData = [];
      for (const date of dates) {
        let dateRange = getDateRange(date);

        let snap = await axios(
          functionBaseUrl + "/api/elastic/collection/size/filter",
          {
            method: "POST",
            data: {
              collection: "users",
              query: {
                bool: {
                  must: [{ match: { docType: "users" } }],
                  filter: [
                    {
                      range: {
                        userCreated: {
                          gte: String(dateRange.start),
                          lte: String(dateRange.end),
                        },
                      },
                    },
                  ],
                  must_not: must_not,
                },
              },
            },
          }
        );
        usersData.push(snap?.data?.count);

        let snapCoaches = await axios(
          functionBaseUrl + "/api/elastic/collection/size/filter",
          {
            method: "POST",
            data: {
              collection: "users",
              query: {
                bool: {
                  must: [
                    { match: { docType: "users" } },
                    {
                      terms: {
                        subscriptionType: [
                          listOfSubscriptions.LITE_MONTHLY,
                          listOfSubscriptions.LITE_ANNUALLY,
                          listOfSubscriptions.PREMIUM_MONTHLY,
                          listOfSubscriptions.PREMIUM_ANNUALLY,
                          listOfSubscriptions.ENTERPRISE_MASTER,
                          listOfSubscriptions.ENTERPRISE_COACH,
                        ],
                      },
                    },
                  ],
                  filter: [
                    {
                      range: {
                        userCreated: {
                          gte: String(dateRange.start),
                          lte: String(dateRange.end),
                        },
                      },
                    },
                  ],
                  must_not: must_not,
                },
              },
            },
          }
        );
        coachesData.push(snapCoaches?.data?.count);

        let snapStudents = await axios(
          functionBaseUrl + "/api/elastic/collection/size/filter",
          {
            method: "POST",
            data: {
              collection: "users",
              query: {
                bool: {
                  must: [
                    { match: { docType: "users" } },
                    {
                      terms: {
                        subscriptionType: [
                          "",
                          listOfSubscriptions.STUDENT_FREE,
                          listOfSubscriptions.STUDENT_LITE_MONTHLY,
                          listOfSubscriptions.STUDENT_LITE_ANNUALLY,
                        ],
                      },
                    },
                  ],
                  filter: [
                    {
                      range: {
                        userCreated: {
                          gte: String(dateRange.start),
                          lte: String(dateRange.end),
                        },
                      },
                    },
                  ],
                  must_not: must_not,
                },
              },
            },
          }
        );
        studentsData.push(snapStudents?.data?.count);
      }

      setUsers(usersData);
      setCoaches(coachesData);
      setStudents(studentsData);
    }

    if (collection === "sessions") {
      setSessions([]);
      let sessionsData = [];
      for (const date of dates) {
        let dateRange = getDateRange(date);
        let sessionsSnap = await axios(
          functionBaseUrl + "/api/elastic/collection/size/filter",
          {
            method: "POST",
            data: {
              collection: "sessions",
              query: {
                bool: {
                  must: [{ match: { docType: "sessions" } }],
                  filter: [
                    {
                      range: {
                        sessionDate: {
                          gte: String(dateRange.start),
                          lte: String(dateRange.end),
                        },
                      },
                    },
                    {
                      has_parent: {
                        parent_type: "users",
                        query: { bool: { must_not: must_not } },
                      },
                    },
                  ],
                },
              },
            },
          }
        );
        sessionsData.push(sessionsSnap?.data?.count);
      }

      setSessions(sessionsData);
    }

    if (collection === "videos") {
      setVideos([]);
      setVideosFON([]);
      setVideosDTL([]);

      let videosData = [];
      let videosDataFON = [];
      let videosDataDTL = [];
      for (const date of dates) {
        let dateRange = getDateRange(date);
        let videosSnap = await axios(
          functionBaseUrl + "/api/elastic/collection/size/filter",
          {
            method: "POST",
            data: {
              collection: "videos",
              query: {
                bool: {
                  must: [{ match: { docType: "videos" } }],
                  filter: [
                    {
                      range: {
                        videoCreated: {
                          gte: String(dateRange.start),
                          lte: String(dateRange.end),
                        },
                      },
                    },
                    {
                      has_parent: {
                        parent_type: "sessions",
                        query: {
                          has_parent: {
                            parent_type: "users",
                            query: { bool: { must_not: must_not } },
                          },
                        },
                      },
                    },
                  ],
                },
              },
            },
          }
        );
        videosData.push(videosSnap?.data?.count);

        let videosSnapFON = await axios(
          functionBaseUrl + "/api/elastic/collection/size/filter",
          {
            method: "POST",
            data: {
              collection: "videos",
              query: {
                bool: {
                  must: [
                    {
                      match: {
                        docType: "videos",
                      },
                    },
                    {
                      bool: {
                        should: [
                          {
                            bool: {
                              must_not: {
                                exists: { field: "metaData.cameraAngle" },
                              },
                            },
                          },
                          { match: { "metaData.cameraAngle.keyword": "" } },
                          { match: { "metaData.cameraAngle.keyword": "FaceOn" } },
                          { match: { "metaData.cameraAngle.keyword": "FaceOn (FO)" } },
                          { match: { "metaData.cameraAngle.keyword": "FON" } },
                        ],
                        minimum_should_match: 1,
                      },
                    },
                  ],
                  filter: [
                    {
                      range: {
                        videoCreated: {
                          gte: String(dateRange.start),
                          lte: String(dateRange.end),
                        },
                      },
                    },
                    {
                      has_parent: {
                        parent_type: "sessions",
                        query: {
                          has_parent: {
                            parent_type: "users",
                            query: { bool: { must_not: must_not } },
                          },
                        },
                      },
                    },
                  ],
                },
              },
            },
          }
        );
        videosDataFON.push(videosSnapFON?.data?.count);

        let videosSnapDTL = await axios(
          functionBaseUrl + "/api/elastic/collection/size/filter",
          {
            method: "POST",
            data: {
              collection: "videos",
              query: {
                bool: {
                  must: [
                    {
                      match: {
                        docType: "videos",
                      },
                    },
                    {
                      bool: {
                        should: [
                          /*{
                            bool: {
                              must: {
                                exists: { field: "metaData.cameraAngle" },
                              },
                            },
                          },*/
                          { match: { "metaData.cameraAngle.keyword": "DownTheLine" } },
                          { match: { "metaData.cameraAngle.keyword": "DTL" } },
                        ],
                        minimum_should_match: 1,
                      },
                    },
                  ],
                  filter: [
                    {
                      range: {
                        videoCreated: {
                          gte: String(dateRange.start),
                          lte: String(dateRange.end),
                        },
                      },
                    },
                    {
                      has_parent: {
                        parent_type: "sessions",
                        query: {
                          has_parent: {
                            parent_type: "users",
                            query: { bool: { must_not: must_not } },
                          },
                        },
                      },
                    },
                  ],
                },
              },
            },
          }
        );
        videosDataDTL.push(videosSnapDTL?.data?.count);
      }

      setVideos(videosData);
      setVideosFON(videosDataFON);
      setVideosDTL(videosDataDTL);
    }
    if (collection === "confidenceScore") {
      setavgCS([]);

      let avgCSData = [];
      let avgCSData1 = [];
      let avgCSData2 = [];
      let avgCSData3 = [];
      let avgCSData4 = [];
      let avgCSData5 = [];
      for (const date of dates) {
        let dateRange = getDateRange(date);
        let snap1 = await axios(
          functionBaseUrl + "/api/elastic/collection/size/filter",
          {
            method: "POST",
            data: {
              collection: "analysis",
              query: {
                bool: {
                  must: [{ match: { docType: "analysis" } }],
                  filter: [
                    {
                      range: {
                        createdDate: {
                          gte: String(dateRange.start),
                          lte: String(dateRange.end),
                        },
                      },
                    },
                    {
                      range: {
                        swingConfidenceScore: { gte: "0.89", lte: "1" },
                      },
                    },
                    analysisParentQuery,
                  ],
                },
              },
            },
          }
        );
        avgCSData1.push(snap1?.data?.count);

        let snap2 = await axios(
          functionBaseUrl + "/api/elastic/collection/size/filter",
          {
            method: "POST",
            data: {
              collection: "analysis",
              query: {
                bool: {
                  must: [{ match: { docType: "analysis" } }],
                  filter: [
                    {
                      range: {
                        createdDate: {
                          gte: String(dateRange.start),
                          lte: String(dateRange.end),
                        },
                      },
                    },
                    {
                      range: {
                        swingConfidenceScore: { gte: "0.8", lt: "0.89" },
                      },
                    },
                    analysisParentQuery,
                  ],
                },
              },
            },
          }
        );
        avgCSData2.push(snap2?.data?.count);

        let snap3 = await axios(
          functionBaseUrl + "/api/elastic/collection/size/filter",
          {
            method: "POST",
            data: {
              collection: "analysis",
              query: {
                bool: {
                  must: [{ match: { docType: "analysis" } }],
                  filter: [
                    {
                      range: {
                        createdDate: {
                          gte: String(dateRange.start),
                          lte: String(dateRange.end),
                        },
                      },
                    },
                    {
                      range: {
                        swingConfidenceScore: { gte: "0.7", lt: "0.8" },
                      },
                    },
                    analysisParentQuery,
                  ],
                },
              },
            },
          }
        );
        avgCSData3.push(snap3?.data?.count);

        let snap4 = await axios(
          functionBaseUrl + "/api/elastic/collection/size/filter",
          {
            method: "POST",
            data: {
              collection: "analysis",
              query: {
                bool: {
                  must: [{ match: { docType: "analysis" } }],
                  filter: [
                    {
                      range: {
                        createdDate: {
                          gte: String(dateRange.start),
                          lte: String(dateRange.end),
                        },
                      },
                    },
                    {
                      range: {
                        swingConfidenceScore: { gte: "0.6", lt: "0.7" },
                      },
                    },
                    analysisParentQuery,
                  ],
                },
              },
            },
          }
        );
        avgCSData4.push(snap4?.data?.count);

        let snap5 = await axios(
          functionBaseUrl + "/api/elastic/collection/size/filter",
          {
            method: "POST",
            data: {
              collection: "analysis",
              query: {
                bool: {
                  must: [{ match: { docType: "analysis" } }],
                  filter: [
                    {
                      range: {
                        createdDate: {
                          gte: String(dateRange.start),
                          lte: String(dateRange.end),
                        },
                      },
                    },
                    {
                      range: { swingConfidenceScore: { gte: "0", lt: "0.6" } },
                    },
                    analysisParentQuery,
                  ],
                },
              },
            },
          }
        );
        avgCSData5.push(snap5?.data?.count);
      }
      avgCSData.push(avgCSData1);
      avgCSData.push(avgCSData2);
      avgCSData.push(avgCSData3);
      avgCSData.push(avgCSData4);
      avgCSData.push(avgCSData5);

      setavgCS(avgCSData);
    }

    if (collection === "confidenceScoreByPercentage") {
      setavgCSP([]);

      let avgCSPDataTotal = [];
      let avgCSPData = [];
      let avgCSPData1 = [];
      let avgCSPData2 = [];
      let avgCSPData3 = [];
      let avgCSPData4 = [];
      let avgCSPData5 = [];
      for (const date of dates) {
        let dateRange = getDateRange(date);
        let snap = await axios(
          functionBaseUrl + "/api/elastic/collection/size/filter",
          {
            method: "POST",
            data: {
              collection: "analysis",
              query: {
                bool: {
                  must: [{ match: { docType: "analysis" } }],
                  filter: [
                    {
                      range: {
                        createdDate: {
                          gte: String(dateRange.start),
                          lte: String(dateRange.end),
                        },
                      },
                    },
                    analysisParentQuery,
                  ],
                },
              },
            },
          }
        );
        avgCSPDataTotal.push(snap?.data?.count);

        let snap1 = await axios(
          functionBaseUrl + "/api/elastic/collection/size/filter",
          {
            method: "POST",
            data: {
              collection: "analysis",
              query: {
                bool: {
                  must: [{ match: { docType: "analysis" } }],
                  filter: [
                    {
                      range: {
                        createdDate: {
                          gte: String(dateRange.start),
                          lte: String(dateRange.end),
                        },
                      },
                    },
                    {
                      range: {
                        swingConfidenceScore: { gte: "0.89", lte: "1" },
                      },
                    },
                    analysisParentQuery,
                  ],
                },
              },
            },
          }
        );
        avgCSPData1.push(
          Math.round((snap1?.data?.count / snap?.data?.count) * 100) / 100
        );

        let snap2 = await axios(
          functionBaseUrl + "/api/elastic/collection/size/filter",
          {
            method: "POST",
            data: {
              collection: "analysis",
              query: {
                bool: {
                  must: [{ match: { docType: "analysis" } }],
                  filter: [
                    {
                      range: {
                        createdDate: {
                          gte: String(dateRange.start),
                          lte: String(dateRange.end),
                        },
                      },
                    },
                    {
                      range: {
                        swingConfidenceScore: { gte: "0.8", lt: "0.89" },
                      },
                    },
                    analysisParentQuery,
                  ],
                },
              },
            },
          }
        );
        avgCSPData2.push(
          Math.round((snap2?.data?.count / snap?.data?.count) * 100) / 100
        );

        let snap3 = await axios(
          functionBaseUrl + "/api/elastic/collection/size/filter",
          {
            method: "POST",
            data: {
              collection: "analysis",
              query: {
                bool: {
                  must: [{ match: { docType: "analysis" } }],
                  filter: [
                    {
                      range: {
                        createdDate: {
                          gte: String(dateRange.start),
                          lte: String(dateRange.end),
                        },
                      },
                    },
                    {
                      range: {
                        swingConfidenceScore: { gte: "0.7", lt: "0.8" },
                      },
                    },
                    analysisParentQuery,
                  ],
                },
              },
            },
          }
        );
        avgCSPData3.push(
          Math.round((snap3?.data?.count / snap?.data?.count) * 100) / 100
        );

        let snap4 = await axios(
          functionBaseUrl + "/api/elastic/collection/size/filter",
          {
            method: "POST",
            data: {
              collection: "analysis",
              query: {
                bool: {
                  must: [{ match: { docType: "analysis" } }],
                  filter: [
                    {
                      range: {
                        createdDate: {
                          gte: String(dateRange.start),
                          lte: String(dateRange.end),
                        },
                      },
                    },
                    {
                      range: {
                        swingConfidenceScore: { gte: "0.6", lt: "0.7" },
                      },
                    },
                    analysisParentQuery,
                  ],
                },
              },
            },
          }
        );
        avgCSPData4.push(
          Math.round((snap4?.data?.count / snap?.data?.count) * 100) / 100
        );

        let snap5 = await axios(
          functionBaseUrl + "/api/elastic/collection/size/filter",
          {
            method: "POST",
            data: {
              collection: "analysis",
              query: {
                bool: {
                  must: [{ match: { docType: "analysis" } }],
                  filter: [
                    {
                      range: {
                        createdDate: {
                          gte: String(dateRange.start),
                          lte: String(dateRange.end),
                        },
                      },
                    },
                    {
                      range: { swingConfidenceScore: { gte: "0", lt: "0.6" } },
                    },
                    analysisParentQuery,
                  ],
                },
              },
            },
          }
        );
        avgCSPData5.push(
          Math.round((snap5?.data?.count / snap?.data?.count) * 100) / 100
        );
      }
      avgCSPData.push(avgCSPData1);
      avgCSPData.push(avgCSPData2);
      avgCSPData.push(avgCSPData3);
      avgCSPData.push(avgCSPData4);
      avgCSPData.push(avgCSPData5);

      setavgCSP(avgCSPData);
    }
  };

  const getDateRange = (dateString) => {
    let startDate, endDate;

    if (/^\d{2}-\d{2}-\d{4}$/.test(dateString)) {
      // days format: "MM-DD-YYYY"
      startDate = startOfDay(parse(dateString, "MM-dd-yyyy", new Date()));
      endDate = endOfDay(parse(dateString, "MM-dd-yyyy", new Date()));
    } else if (/^Week\d{1,2}-\d{4}$/.test(dateString)) {
      // weeks format: "Week##-YYYY"
      const [weekString, year] = dateString.split("-");
      const weekNumber = parseInt(weekString.slice(4), 10);
      const isoWeekStart = startOfWeek(
        parse(`${year}-01-01`, "yyyy-MM-dd", new Date()),
        { weekStartsOn: 1 }
      );
      startDate = addWeeks(isoWeekStart, weekNumber);
      endDate = endOfWeek(startDate, { weekStartsOn: 1 });
      endDate = endOfDay(endDate);
    } else if (/^\d{2}-\d{4}$/.test(dateString)) {
      // months format: "MM-YYYY"
      const [month, year] = dateString.split("-");
      startDate = startOfMonth(
        parse(`${year}-${month}-01`, "yyyy-MM-dd", new Date())
      );
      endDate = endOfMonth(startDate);
    } else if (/^\d{4}$/.test(dateString)) {
      // years format: "YYYY"
      const year = dateString;
      startDate = startOfYear(parse(`${year}-01-01`, "yyyy-MM-dd", new Date()));
      endDate = endOfYear(startDate);
    } else {
      throw new Error("Invalid date format");
    }

    return {
      start: startDate.getTime(),
      end: endDate.getTime(),
      timezone: "PST",
    };
  };

  const getAllDates = (startDate, endDate, period) => {
    const dates = [];
    let intervals;

    const start = new Date(startDate * 1);
    const end = new Date(endDate * 1);

    switch (period) {
      case "days":
        intervals = eachDayOfInterval({ start, end });
        dates.push(...intervals.map((date) => format(date, "MM-dd-yyyy")));
        break;
      case "weeks":
        intervals = eachWeekOfInterval({ start, end });
        dates.push(
          ...intervals.map(
            (date) =>
              `Week${getISOWeek(date) <= 9 ? "0" : ""}${getISOWeek(
                date
              )}-${getISOWeekYear(date)}`
          )
        );
        break;
      case "months":
        intervals = eachMonthOfInterval({ start, end });
        dates.push(...intervals.map((date) => format(date, "MM-yyyy")));
        break;
      case "years":
        intervals = eachYearOfInterval({ start, end });
        dates.push(...intervals.map((date) => String(getYear(date))));
        break;
      default:
        return null;
    }

    return dates;
  };

  useEffect(() => {
    if (
      statType === "users" &&
      tempUsers.length === dates.length &&
      tempCoaches.length === dates.length &&
      tempStudents.length === dates.length
    ) {
      let overall = [];

      for (var i = 0; i < tempUsers.length; i++) {
        let tot = sum(tempUsers, i + 1);
        overall[i] = tot;
      }

      setOptions({
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
          },
          title: {
            display: true,
            text: "Users",
            fontSize: "20",
          },
        },
      });

      setData({
        labels: dates,
        datasets: [
          {
            label: "Accumulative",
            backgroundColor: "rgb(155, 99, 132, 0.5)",
            borderColor: "rgb(155, 99, 132)",
            data: overall,
            hidden: true,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Total",
            backgroundColor: "rgb(255, 99, 132, 0.5)",
            borderColor: "rgb(255, 99, 132)",
            data: tempUsers,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Coaches",
            backgroundColor: "rgb(70, 185, 89, 0.5)",
            borderColor: "rgb(70, 185, 89)",
            data: tempCoaches,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Students",
            backgroundColor: "rgb(41, 121, 255, 0.5)",
            borderColor: "rgb(41, 121, 255)",
            data: tempStudents,
            //fill: false,
            //lineTension: 0
          },
        ],
      });
      setLoading(false);
    }

    if (statType === "sessions" && tempSessions.length === dates.length) {
      let overall = [];

      for (var i = 0; i < tempSessions.length; i++) {
        let tot = sum(tempSessions, i + 1);
        overall[i] = tot;
      }

      setOptions({
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
          },
          title: {
            display: true,
            text: "Sessions",
            fontSize: "20",
          },
        },
      });

      setData({
        labels: dates,
        datasets: [
          {
            label: "Accumulative",
            backgroundColor: "rgb(155, 99, 132, 0.5)",
            borderColor: "rgb(155, 99, 132)",
            data: overall,
            hidden: true,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Total",
            backgroundColor: "rgb(255, 99, 132, 0.5)",
            borderColor: "rgb(255, 99, 132)",
            data: tempSessions,
            //fill: false,
            //lineTension: 0
          },
        ],
      });
      setLoading(false);
    }

    if (
      statType === "videos" &&
      tempVideos.length === dates.length &&
      videosFON.length === dates.length &&
      videosDTL.length === dates.length
    ) {
      let overall = [];

      for (var i = 0; i < tempVideos.length; i++) {
        let tot = sum(tempVideos, i + 1);
        overall[i] = tot;
      }

      setOptions({
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
          },
          title: {
            display: true,
            text: "Videos",
            fontSize: "20",
          },
        },
      });

      setData({
        labels: dates,
        datasets: [
          {
            label: "Accumulative",
            backgroundColor: "rgb(155, 99, 132, 0.5)",
            borderColor: "rgb(155, 99, 132)",
            data: overall,
            hidden: true,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Total",
            backgroundColor: "rgb(255, 99, 132, 0.5)",
            borderColor: "rgb(255, 99, 132)",
            data: tempVideos,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "FON Total",
            backgroundColor: "rgb(255, 128, 0, 0.5)",
            borderColor: "rgb(255, 128, 0)",
            data: videosFON,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "DTL Total",
            backgroundColor: "rgb(255, 255, 0, 0.5)",
            borderColor: "rgb(255, 255, 0)",
            data: videosDTL,
            //fill: false,
            //lineTension: 0
          },
        ],
      });
      setLoading(false);
    }

    if (
      statType === "issues" &&
      issues.length === dates.length &&
      analysis.length === dates.length
    ) {
      let issuesVSanalysis = [];

      for (var i = 0; i < issues.length; i++) {
        issuesVSanalysis[i] = (
          (issues[i] / (analysis[i] + issues[i])) *
          100
        ).toFixed(0);
      }

      setOptions({
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
          },
          title: {
            display: true,
            text: "Swing And A Miss",
            fontSize: "20",
          },
        },
      });

      setData({
        labels: dates,
        datasets: [
          {
            label: "S&M Total",
            backgroundColor: "rgb(255, 99, 132, 0.5)",
            borderColor: "rgb(255, 99, 132)",
            data: issues,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Analysis Total",
            backgroundColor: "rgb(70, 185, 89, 0.5)",
            borderColor: "rgb(70, 185, 89)",
            data: analysis,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "S&M FON Total",
            backgroundColor: "rgb(255, 128, 0, 0.5)",
            borderColor: "rgb(255, 128, 0)",
            data: issuesFON,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "S&M DTL Total",
            backgroundColor: "rgb(255, 255, 0, 0.5)",
            borderColor: "rgb(255, 255, 0)",
            data: issuesDTL,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Analysis FON Total",
            backgroundColor: "rgb(0, 255, 255, 0.5)",
            borderColor: "rgb(0, 255, 255)",
            data: analysisFON,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Analysis DTL Total",
            backgroundColor: "rgb(255, 0, 255, 0.5)",
            borderColor: "rgb(255, 0, 255)",
            data: analysisDTL,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "S&M vs Analysis (%)",
            backgroundColor: "rgb(52, 170, 235, 0.5)",
            borderColor: "rgb(52, 160, 235)",
            data: issuesVSanalysis,
            //fill: false,
            //lineTension: 0
          },
        ],
      });
      setLoading(false);
    }

    if (
      statType === "analysis" &&
      analysis.length === dates.length &&
      issues.length === dates.length
    ) {
      let analysisVSissues = [];

      for (var i = 0; i < analysis.length; i++) {
        analysisVSissues[i] = (
          (analysis[i] / (analysis[i] + issues[i])) *
          100
        ).toFixed(0);
      }

      setOptions({
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
          },
          title: {
            display: true,
            text: "Analysis",
            fontSize: "20",
          },
        },
      });

      setData({
        labels: dates,
        datasets: [
          {
            label: "Analysis Total",
            backgroundColor: "rgb(255, 99, 132, 0.5)",
            borderColor: "rgb(255, 99, 132)",
            data: analysis,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "S&M Total",
            backgroundColor: "rgb(70, 185, 89, 0.5)",
            borderColor: "rgb(70, 185, 89)",
            data: issues,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Analysis FON Total",
            backgroundColor: "rgb(255, 128, 0, 0.5)",
            borderColor: "rgb(255, 128, 0)",
            data: analysisFON,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Analysis DTL Total",
            backgroundColor: "rgb(0, 255, 255, 0.5)",
            borderColor: "rgb(0, 255, 255)",
            data: analysisDTL,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "S&M FON Total",
            backgroundColor: "rgb(255, 255, 0, 0.5)",
            borderColor: "rgb(255, 255, 0)",
            data: issuesFON,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "S&M DTL Total",
            backgroundColor: "rgb(255, 0, 255, 0.5)",
            borderColor: "rgb(255, 0, 255)",
            data: issuesDTL,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Analysis vs S&M (%)",
            backgroundColor: "rgb(52, 170, 235, 0.5)",
            borderColor: "rgb(52, 160, 235)",
            data: analysisVSissues,
            //fill: false,
            //lineTension: 0
          },
        ],
      });
      setLoading(false);
    }

    if (
      statType === "issuesByPercentage" &&
      issuesPercentage.length === dates.length &&
      analysisPercentage.length === dates.length
    ) {
      /*let issuesVSanalysis = [];

      for (var i = 0; i < issues.length; i++) {
        issuesVSanalysis[i] = ((issues[i] / (analysis[i] + issues[i])) * 100).toFixed(0);
      }*/

      setOptions({
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
          },
          title: {
            display: true,
            text: "Swing And A Miss by %",
            fontSize: "20",
          },
        },
      });

      setData({
        labels: dates,
        datasets: [
          /*{
            label: 'S&M Total',
            backgroundColor: 'rgb(255, 99, 132, 0.5)',
            borderColor: 'rgb(255, 99, 132)',
            data: issues,
            //fill: false,
            //lineTension: 0
          },
          {
            label: 'Analysis Total',
            backgroundColor: 'rgb(70, 185, 89, 0.5)',
            borderColor: 'rgb(70, 185, 89)',
            data: analysis,
            //fill: false,
            //lineTension: 0
          },*/
          {
            label: "S&M FON Total (%)",
            backgroundColor: "rgb(255, 128, 0, 0.5)",
            borderColor: "rgb(255, 128, 0)",
            data: issuesFONPercentage,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "S&M DTL Total (%)",
            backgroundColor: "rgb(255, 255, 0, 0.5)",
            borderColor: "rgb(255, 255, 0)",
            data: issuesDTLPercentage,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Analysis FON Total (%)",
            backgroundColor: "rgb(0, 255, 255, 0.5)",
            borderColor: "rgb(0, 255, 255)",
            data: analysisFONPercentage,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Analysis DTL Total (%)",
            backgroundColor: "rgb(255, 0, 255, 0.5)",
            borderColor: "rgb(255, 0, 255)",
            data: analysisDTLPercentage,
            //fill: false,
            //lineTension: 0
          },
          /*{
            label: 'S&M vs Analysis (%)',
            backgroundColor: 'rgb(52, 170, 235, 0.5)',
            borderColor: 'rgb(52, 160, 235)',
            data: issuesVSanalysis,
            //fill: false,
            //lineTension: 0
          },*/
        ],
      });
      setLoading(false);
    }

    if (
      statType === "analysisByPercentage" &&
      analysisPercentage.length === dates.length &&
      issuesPercentage.length === dates.length
    ) {
      setOptions({
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
          },
          title: {
            display: true,
            text: "Analysis by %",
            fontSize: "20",
          },
        },
      });

      setData({
        labels: dates,
        datasets: [
          /*{
            label: 'Analysis Total (%)',
            backgroundColor: 'rgb(255, 99, 132, 0.5)',
            borderColor: 'rgb(255, 99, 132)',
            data: analysisPercentage,
            //fill: false,
            //lineTension: 0
          },
          {
            label: 'S&M Total (%)',
            backgroundColor: 'rgb(70, 185, 89, 0.5)',
            borderColor: 'rgb(70, 185, 89)',
            data: issuesPercentage,
            //fill: false,
            //lineTension: 0
          },*/
          {
            label: "Analysis FON Total (%)",
            backgroundColor: "rgb(255, 128, 0, 0.5)",
            borderColor: "rgb(255, 128, 0)",
            data: analysisFONPercentage,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Analysis DTL Total (%)",
            backgroundColor: "rgb(0, 255, 255, 0.5)",
            borderColor: "rgb(0, 255, 255)",
            data: analysisDTLPercentage,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "S&M FON Total (%)",
            backgroundColor: "rgb(255, 255, 0, 0.5)",
            borderColor: "rgb(255, 255, 0)",
            data: issuesFONPercentage,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "S&M DTL Total (%)",
            backgroundColor: "rgb(255, 0, 255, 0.5)",
            borderColor: "rgb(255, 0, 255)",
            data: issuesDTLPercentage,
            //fill: false,
            //lineTension: 0
          },
          /*{
            label: 'Analysis vs S&M (%)',
            backgroundColor: 'rgb(52, 170, 235, 0.5)',
            borderColor: 'rgb(52, 160, 235)',
            data: analysisVSissues,
            //fill: false,
            //lineTension: 0
          },*/
        ],
      });
      setLoading(false);
    }

    if (
      statType === "confidenceScore" &&
      avgCS.length > 0 &&
      avgCS[0].length === dates.length
    ) {
      setOptions({
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
          },
          title: {
            display: true,
            text: "Confidence Score",
            fontSize: "20",
          },
        },
      });

      setData({
        labels: dates,
        datasets: [
          {
            label: "89 - 100",
            backgroundColor: "rgb(255, 99, 132, 0.5)",
            borderColor: "rgb(255, 99, 132)",
            data: avgCS[0],
            //fill: false,
            //lineTension: 0
          },
          {
            label: "80 - 89",
            backgroundColor: "rgb(70, 185, 89, 0.5)",
            borderColor: "rgb(70, 185, 89)",
            data: avgCS[1],
            //fill: false,
            //lineTension: 0
          },
          {
            label: "70-79",
            backgroundColor: "rgb(52, 170, 235, 0.5)",
            borderColor: "rgb(52, 160, 235)",
            data: avgCS[2],
            //fill: false,
            //lineTension: 0
          },
          {
            label: "60 - 69",
            backgroundColor: "rgb(75, 192, 192, 0.5)",
            borderColor: "rgb(75, 192, 192)",
            data: avgCS[3],
            //fill: false,
            //lineTension: 0
          },
          {
            label: "0 - 59",
            backgroundColor: "rgb(255, 240, 0, 0.5)",
            borderColor: "rgb(255, 240, 0)",
            data: avgCS[4],
            //fill: false,
            //lineTension: 0
          },
        ],
      });
      setLoading(false);
    }

    if (
      statType === "confidenceScoreByPercentage" &&
      avgCSP.length > 0 &&
      avgCSP[0].length === dates.length
    ) {
      setOptions({
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
          },
          title: {
            display: true,
            text: "Confidence Score",
            fontSize: "20",
          },
        },
      });

      setData({
        labels: dates,
        datasets: [
          {
            label: "89 - 100",
            backgroundColor: "rgb(255, 99, 132, 0.5)",
            borderColor: "rgb(255, 99, 132)",
            data: avgCSP[0],
            //fill: false,
            //lineTension: 0
          },
          {
            label: "80 - 89",
            backgroundColor: "rgb(70, 185, 89, 0.5)",
            borderColor: "rgb(70, 185, 89)",
            data: avgCSP[1],
            //fill: false,
            //lineTension: 0
          },
          {
            label: "70-79",
            backgroundColor: "rgb(52, 170, 235, 0.5)",
            borderColor: "rgb(52, 160, 235)",
            data: avgCSP[2],
            //fill: false,
            //lineTension: 0
          },
          {
            label: "60 - 69",
            backgroundColor: "rgb(75, 192, 192, 0.5)",
            borderColor: "rgb(75, 192, 192)",
            data: avgCSP[3],
            //fill: false,
            //lineTension: 0
          },
          {
            label: "0 - 59",
            backgroundColor: "rgb(255, 240, 0, 0.5)",
            borderColor: "rgb(255, 240, 0)",
            data: avgCSP[4],
            //fill: false,
            //lineTension: 0
          },
        ],
      });
      setLoading(false);
    }

    if (statType === "subscriptions" && subscriptions.length > 0) {
      let overall = [];
      let total = [];
      let elavon = [];
      let stripe = [];
      let apple = [];
      let android = [];
      let canceled = [];

      subscriptions.forEach((sub) => {
        if (sub.startDate) {
          const created = moment(sub.startDate * 1);
          if (
            created >= moment(selectionRange.startDate) &&
            created <= moment(selectionRange.endDate).endOf("day")
          ) {
            let key = created.format("YYYY-MM-DD");
            if (statPeriod === "byWeeks") {
              key = `${created.format("YYYY")}-${zeroPad(created.week(), 2)}`;
            }
            if (statPeriod === "byMonths") {
              key = created.format("YYYY-MM");
            }
            if (statPeriod === "byYears") {
              key = created.format("YYYY");
            }

            const totalIndex = total.findIndex((item) => item.key === key);
            if (totalIndex < 0) {
              total.push({ key: key, count: 1 });
              overall.push({ key: key, count: 1 });
            } else {
              total[totalIndex].count++;
              overall[totalIndex].count++;
            }
            const elavonIndex = elavon.findIndex((item) => item.key === key);
            if (elavonIndex < 0) {
              elavon.push({
                key: key,
                count: sub.platform === "Elavon" ? 1 : 0,
              });
            } else {
              if (sub.platform === "Elavon") {
                elavon[elavonIndex].count++;
              }
            }
            const stripeIndex = stripe.findIndex((item) => item.key === key);
            if (stripeIndex < 0) {
              stripe.push({
                key: key,
                count: sub.platform === "Stripe" ? 1 : 0,
              });
            } else {
              if (sub.platform === "Stripe") {
                stripe[stripeIndex].count++;
              }
            }
            const appleIndex = apple.findIndex((item) => item.key === key);
            if (appleIndex < 0) {
              apple.push({ key: key, count: sub.platform === "Apple" ? 1 : 0 });
            } else {
              if (sub.platform === "Apple") {
                apple[appleIndex].count++;
              }
            }
            const androidIndex = android.findIndex((item) => item.key === key);
            if (androidIndex < 0) {
              android.push({
                key: key,
                count: sub.platform === "Android" ? 1 : 0,
              });
            } else {
              if (sub.platform === "Android") {
                android[androidIndex].count++;
              }
            }
            if (
              !sub.productId.includes("enterprise_master") ||
              (sub.productId.includes("enterprise_master") &&
                ![0, 100, 200].includes(parseInt(sub.amount)))
            ) {
              const canceledIndex = canceled.findIndex(
                (item) => item.key === key
              );
              if (canceledIndex < 0) {
                canceled.push({
                  key: key,
                  count: !sub.autoRenewal && sub.transactionId !== "" ? 1 : 0,
                });
              } else {
                if (!sub.autoRenewal && sub.transactionId !== "") {
                  canceled[canceledIndex].count++;
                }
              }
            }
          }
        }
      });

      overall.sort((a, b) => a.key.localeCompare(b.key));
      total.sort((a, b) => a.key.localeCompare(b.key));
      elavon.sort((a, b) => a.key.localeCompare(b.key));
      stripe.sort((a, b) => a.key.localeCompare(b.key));
      apple.sort((a, b) => a.key.localeCompare(b.key));
      android.sort((a, b) => a.key.localeCompare(b.key));
      canceled.sort((a, b) => a.key.localeCompare(b.key));

      if (overall.length > 0) {
        for (var i = 0; i < overall.length; i++) {
          let tot = sum(total, i + 1);
          overall[i].count = tot;
        }
      }

      setOptions({
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
          },
          title: {
            display: true,
            text: "All Subscriptions",
            fontSize: "20",
          },
        },
      });

      setData({
        labels: total.map((item) => item.key),
        datasets: [
          {
            label: "Accumulative",
            backgroundColor: "rgb(155, 99, 132, 0.5)",
            borderColor: "rgb(155, 99, 132)",
            data: overall.map((item) => item.count),
            hidden: true,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Total",
            backgroundColor: "rgb(255, 99, 132, 0.5)",
            borderColor: "rgb(255, 99, 132)",
            data: total.map((item) => item.count),
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Elavon",
            backgroundColor: "rgb(70, 185, 89, 0.5)",
            borderColor: "rgb(70, 185, 89)",
            data: elavon.map((item) => item.count),
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Stripe",
            backgroundColor: "rgb(255, 153, 51, 0.5)",
            borderColor: "rgb(255, 153, 51)",
            data: stripe.map((item) => item.count),
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Apple",
            backgroundColor: "rgb(41, 121, 255, 0.5)",
            borderColor: "rgb(41, 121, 255)",
            data: apple.map((item) => item.count),
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Android",
            backgroundColor: "rgb(75, 192, 192, 0.5)",
            borderColor: "rgb(75, 192, 192)",
            data: android.map((item) => item.count),
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Canceled",
            backgroundColor: "rgb(255, 240, 0, 0.5)",
            borderColor: "rgb(255, 240, 0)",
            data: canceled.map((item) => item.count),
            //fill: false,
            //lineTension: 0
          },
        ],
      });
      setLoading(false);
    }

    if (statType === "recurringSubscriptions" && subscriptions.length > 0) {
      let overall = [];
      let total = [];
      let elavon = [];
      let stripe = [];
      let apple = [];
      let android = [];
      let staff = [];

      subscriptions.forEach((sub) => {
        if (
          sub.startDate &&
          (sub.autoRenewal ||
            (sub.productId.includes("enterprise_master") && !sub.autoRenewal))
        ) {
          const created = moment(sub.startDate * 1);
          if (
            created >= moment(selectionRange.startDate) &&
            created <= moment(selectionRange.endDate).endOf("day")
          ) {
            let key = created.format("YYYY-MM-DD");
            if (statPeriod === "byWeeks") {
              key = `${created.format("YYYY")}-${zeroPad(created.week(), 2)}`;
            }
            if (statPeriod === "byMonths") {
              key = created.format("YYYY-MM");
            }
            if (statPeriod === "byYears") {
              key = created.format("YYYY");
            }

            if (
              sub.autoRenewal ||
              parseInt(sub.amount) === 0 ||
              (sub.transactionId !== "" &&
                (parseInt(sub.amount) === 100 || parseInt(sub.amount) === 200))
            ) {
              const totalIndex = total.findIndex((item) => item.key === key);
              if (totalIndex < 0) {
                total.push({ key: key, count: 1 });
                overall.push({ key: key, count: 1 });
              } else {
                total[totalIndex].count++;
                overall[totalIndex].count++;
              }
            }
            if (sub.autoRenewal) {
              const elavonIndex = elavon.findIndex((item) => item.key === key);
              if (elavonIndex < 0) {
                elavon.push({
                  key: key,
                  count: sub.platform === "Elavon" ? 1 : 0,
                });
              } else {
                if (sub.platform === "Elavon") {
                  elavon[elavonIndex].count++;
                }
              }
              const stripeIndex = stripe.findIndex((item) => item.key === key);
              if (stripeIndex < 0) {
                stripe.push({
                  key: key,
                  count: sub.platform === "Stripe" ? 1 : 0,
                });
              } else {
                if (sub.platform === "Stripe") {
                  stripe[stripeIndex].count++;
                }
              }
            }
            const appleIndex = apple.findIndex((item) => item.key === key);
            if (appleIndex < 0) {
              apple.push({ key: key, count: sub.platform === "Apple" ? 1 : 0 });
            } else {
              if (sub.platform === "Apple") {
                apple[appleIndex].count++;
              }
            }
            const androidIndex = android.findIndex((item) => item.key === key);
            if (androidIndex < 0) {
              android.push({
                key: key,
                count: sub.platform === "Android" ? 1 : 0,
              });
            } else {
              if (sub.platform === "Android") {
                android[androidIndex].count++;
              }
            }
            const staffIndex = staff.findIndex((item) => item.key === key);
            if (staffIndex < 0) {
              staff.push({
                key: key,
                count:
                  sub.productId.includes("enterprise_master") &&
                  !sub.autoRenewal &&
                  (parseInt(sub.amount) === 0 ||
                    (sub.transactionId !== "" &&
                      (parseInt(sub.amount) === 100 ||
                        parseInt(sub.amount) === 200)))
                    ? 1
                    : 0,
              });
            } else {
              if (
                sub.productId.includes("enterprise_master") &&
                !sub.autoRenewal &&
                (parseInt(sub.amount) === 0 ||
                  (sub.transactionId !== "" &&
                    (parseInt(sub.amount) === 100 ||
                      parseInt(sub.amount) === 200)))
              ) {
                staff[staffIndex].count++;
              }
            }
          }
        }
      });

      overall.sort((a, b) => a.key.localeCompare(b.key));
      total.sort((a, b) => a.key.localeCompare(b.key));
      elavon.sort((a, b) => a.key.localeCompare(b.key));
      stripe.sort((a, b) => a.key.localeCompare(b.key));
      apple.sort((a, b) => a.key.localeCompare(b.key));
      android.sort((a, b) => a.key.localeCompare(b.key));
      staff.sort((a, b) => a.key.localeCompare(b.key));

      if (overall.length > 0) {
        for (var i = 0; i < overall.length; i++) {
          let tot = sum(total, i + 1);
          overall[i].count = tot;
        }
      }

      setOptions({
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
          },
          title: {
            display: true,
            text: "Recurring Subscriptions",
            fontSize: "20",
          },
        },
      });

      setData({
        labels: total.map((item) => item.key),
        datasets: [
          {
            label: "Accumulative",
            backgroundColor: "rgb(155, 99, 132, 0.5)",
            borderColor: "rgb(155, 99, 132)",
            data: overall.map((item) => item.count),
            hidden: true,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Total",
            backgroundColor: "rgb(255, 99, 132, 0.5)",
            borderColor: "rgb(255, 99, 132)",
            data: total.map((item) => item.count),
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Elavon",
            backgroundColor: "rgb(70, 185, 89, 0.5)",
            borderColor: "rgb(70, 185, 89)",
            data: elavon.map((item) => item.count),
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Stripe",
            backgroundColor: "rgb(255, 153, 51, 0.5)",
            borderColor: "rgb(255, 153, 51)",
            data: stripe.map((item) => item.count),
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Apple",
            backgroundColor: "rgb(41, 121, 255, 0.5)",
            borderColor: "rgb(41, 121, 255)",
            data: apple.map((item) => item.count),
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Android",
            backgroundColor: "rgb(75, 192, 192, 0.5)",
            borderColor: "rgb(75, 192, 192)",
            data: android.map((item) => item.count),
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Staff",
            backgroundColor: "rgb(175, 192, 192, 0.5)",
            borderColor: "rgb(175, 192, 192)",
            data: staff.map((item) => item.count),
            //fill: false,
            //lineTension: 0
          },
        ],
      });
      setLoading(false);
    }

    if (
      statType === "canceledSubscriptions" &&
      canceledSubscriptions.length > 0
    ) {
      let overall = [];
      let total = [];
      let elavon = [];
      let stripe = [];
      let apple = [];
      let android = [];

      canceledSubscriptions.forEach((sub) => {
        if (sub.startDate && !sub.autoRenewal && sub.transactionId !== "") {
          const created = moment(sub.startDate * 1);
          if (
            created >= moment(selectionRange.startDate) &&
            created <= moment(selectionRange.endDate).endOf("day")
          ) {
            let key = created.format("YYYY-MM-DD");
            if (statPeriod === "byWeeks") {
              key = `${created.format("YYYY")}-${zeroPad(created.week(), 2)}`;
            }
            if (statPeriod === "byMonths") {
              key = created.format("YYYY-MM");
            }
            if (statPeriod === "byYears") {
              key = created.format("YYYY");
            }

            if (
              !sub.productId.includes("enterprise_master") ||
              (sub.productId.includes("enterprise_master") &&
                ![0, 100, 200].includes(parseInt(sub.amount)))
            ) {
              const totalIndex = total.findIndex((item) => item.key === key);
              if (totalIndex < 0) {
                total.push({ key: key, count: 1 });
                overall.push({ key: key, count: 1 });
              } else {
                total[totalIndex].count++;
                overall[totalIndex].count++;
              }
              const elavonIndex = elavon.findIndex((item) => item.key === key);
              if (elavonIndex < 0) {
                elavon.push({
                  key: key,
                  count: sub.platform === "Elavon" ? 1 : 0,
                });
              } else {
                if (sub.platform === "Elavon") {
                  elavon[elavonIndex].count++;
                }
              }
              const stripeIndex = stripe.findIndex((item) => item.key === key);
              if (stripeIndex < 0) {
                stripe.push({
                  key: key,
                  count: sub.platform === "Stripe" ? 1 : 0,
                });
              } else {
                if (sub.platform === "Stripe") {
                  stripe[stripeIndex].count++;
                }
              }
              const appleIndex = apple.findIndex((item) => item.key === key);
              if (appleIndex < 0) {
                apple.push({
                  key: key,
                  count: sub.platform === "Apple" ? 1 : 0,
                });
              } else {
                if (sub.platform === "Apple") {
                  apple[appleIndex].count++;
                }
              }
              const androidIndex = android.findIndex(
                (item) => item.key === key
              );
              if (androidIndex < 0) {
                android.push({
                  key: key,
                  count: sub.platform === "Android" ? 1 : 0,
                });
              } else {
                if (sub.platform === "Android") {
                  android[androidIndex].count++;
                }
              }
            }
          }
        }
      });

      overall.sort((a, b) => a.key.localeCompare(b.key));
      total.sort((a, b) => a.key.localeCompare(b.key));
      elavon.sort((a, b) => a.key.localeCompare(b.key));
      stripe.sort((a, b) => a.key.localeCompare(b.key));
      apple.sort((a, b) => a.key.localeCompare(b.key));
      android.sort((a, b) => a.key.localeCompare(b.key));

      if (overall.length > 0) {
        for (var i = 0; i < overall.length; i++) {
          let tot = sum(total, i + 1);
          overall[i].count = tot;
        }
      }

      setOptions({
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
          },
          title: {
            display: true,
            text: "Canceled Subscriptions",
            fontSize: "20",
          },
        },
      });

      setData({
        labels: total.map((item) => item.key),
        datasets: [
          {
            label: "Accumulative",
            backgroundColor: "rgb(155, 99, 132, 0.5)",
            borderColor: "rgb(155, 99, 132)",
            data: overall.map((item) => item.count),
            hidden: true,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Total",
            backgroundColor: "rgb(255, 99, 132, 0.5)",
            borderColor: "rgb(255, 99, 132)",
            data: total.map((item) => item.count),
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Elavon",
            backgroundColor: "rgb(70, 185, 89, 0.5)",
            borderColor: "rgb(70, 185, 89)",
            data: elavon.map((item) => item.count),
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Stripe",
            backgroundColor: "rgb(255, 153, 51, 0.5)",
            borderColor: "rgb(255, 153, 51)",
            data: stripe.map((item) => item.count),
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Apple",
            backgroundColor: "rgb(41, 121, 255, 0.5)",
            borderColor: "rgb(41, 121, 255)",
            data: apple.map((item) => item.count),
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Android",
            backgroundColor: "rgb(75, 192, 192, 0.5)",
            borderColor: "rgb(75, 192, 192)",
            data: android.map((item) => item.count),
            //fill: false,
            //lineTension: 0
          },
        ],
      });
      setLoading(false);
    }
  }, [
    statType,
    statPeriod,
    selectionRange,
    users,
    sessions,
    videos,
    subscriptions,
    recurringSubscriptions,
    canceledSubscriptions,
    issues,
    analysis,
    staffAccounts,
    tempUsers,
    tempCoaches,
    tempStudents,
    tempSessions,
    tempVideos,
    avgCS,
    avgCSP,
    videosFON,
    videosDTL,
    issuesFON,
    issuesDTL,
    analysisFON,
    analysisDTL,
    issuesPercentage,
    issuesDTLPercentage,
    issuesFONPercentage,
    analysisPercentage,
    analysisDTLPercentage,
    analysisFONPercentage,
  ]);

  return (
    <>
      <Container component="main" style={{ padding: "20px 0" }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="stat-type-select">Statistics</InputLabel>
              <Select
                label="Statistics"
                labelId="stat-type-select"
                onChange={handleStatType}
                value={statType}
              >
                <MenuItem value={"users"}>Users</MenuItem>
                <MenuItem value={"sessions"}>Sessions</MenuItem>
                <MenuItem value={"videos"}>Videos</MenuItem>
                <MenuItem value={"subscriptions"}>All Subscriptions</MenuItem>
                <MenuItem value={"recurringSubscriptions"}>
                  Recurring Subscriptions
                </MenuItem>
                <MenuItem value={"canceledSubscriptions"}>
                  Canceled Subscriptions
                </MenuItem>
                <MenuItem value={"issues"}>Swing And A Miss</MenuItem>
                <MenuItem value={"issuesByPercentage"}>
                  Swing And A Miss by %
                </MenuItem>
                <MenuItem value={"analysis"}>Analysis</MenuItem>
                <MenuItem value={"analysisByPercentage"}>
                  Analysis by %
                </MenuItem>
                <MenuItem value={"confidenceScore"}>Confidence Score</MenuItem>
                <MenuItem value={"confidenceScoreByPercentage"}>
                  Confidence Score by %
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="stat-period-select">Period</InputLabel>
              <Select
                label="Period"
                labelId="stat-period-select"
                onChange={handleStatPeriod}
                value={statPeriod}
              >
                <MenuItem value={"byDays"}>By Days</MenuItem>
                <MenuItem value={"byWeeks"}>By Weeks</MenuItem>
                <MenuItem value={"byMonths"}>By Months</MenuItem>
                <MenuItem value={"byYears"}>By Years</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <div style={{ textAlign: "right" }}>
              From {moment(selectionRange.startDate).format("MM/DD/YYYY")} to{" "}
              {moment(selectionRange.endDate).format("MM/DD/YYYY")}
              <IconButton onClick={() => setOpenRangeDialog(true)}>
                <DateRange />
              </IconButton>
            </div>
            <Dialog fullWidth open={openRangeDialog}>
              <DialogContent>
                <DateRangePicker
                  ranges={[selectionRange]}
                  onChange={(ranges) => {
                    setSelectionRange(ranges.range1);
                    getDataWithRange(statType, ranges.range1, statPeriod);
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setOpenRangeDialog(false)}
                  color="primary"
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
          <Grid item xs={12}>
            {!loading ? (
              <Bar
                options={{
                  title: {
                    display: true,
                    text:
                      statType
                        .replace(/([A-Z])/g, " $1")
                        .charAt(0)
                        .toUpperCase() +
                      statType.replace(/([A-Z])/g, " $1").slice(1),
                    fontSize: "20",
                  },
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          beginAtZero: true,
                        },
                      },
                    ],
                  },
                }}
                data={data}
              />
            ) : (
              <Grid
                style={{
                  margin: "auto",
                  padding: 100,
                  display: loading ? "block" : "none",
                }}
              >
                <CircularProgress />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export const SummaryCharts = SummaryChartsComponent;
