import { format } from "date-fns";
import React from "react";
import { FlexWrap } from "../../../../components";
import {
  ActionsContainer,
  PracticeGuideRowContainer,
  TableRowItemWrapper,
  StatusBadge,
} from "../../styled";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import MoreVertIcon from "@material-ui/icons/MoreVert";

export function TipsAndDrillsRow({ data, onDelete, onEdit, onCopy }) {
  const { loopVideo, title, status, createdAt, type, author } = data;
  const iconStyle = { cursor: "pointer", fontSize: "25px" };
  return (
    <PracticeGuideRowContainer>
      <TableRowItemWrapper width="132px">
        {loopVideo && loopVideo.videoPath ? (
          <video style={{ background: "black" }} width="132" height="87">
            <source src={loopVideo.videoPath} type="video/webm" />
          </video>
        ) : (
          <img
            src="https://sanin-japan.com/assets/camaleon_cms/image-not-found-4a963b95bf081c3ea02923dceaeb3f8085e1a654fc54840aac61a57a60903fef.png"
            alt=""
            height="87"
            width="132"
          />
        )}
      </TableRowItemWrapper>
      <TableRowItemWrapper width="200px">
        <div>{title}</div>
      </TableRowItemWrapper>
      <TableRowItemWrapper width="200px">
        <div>Tips And Drills Video</div>
      </TableRowItemWrapper>
      <TableRowItemWrapper width="100px">
        <div>{author}</div>
      </TableRowItemWrapper>
      <TableRowItemWrapper width="100px">
        <div>{format(createdAt, "MM/dd/yyyy")}</div>
      </TableRowItemWrapper>
      <TableRowItemWrapper width="100px">
        <StatusBadge status={status}>{status}</StatusBadge>
      </TableRowItemWrapper>
      <TableRowItemWrapper width="100px">
        <ActionsContainer>
          <EditIcon onClick={onEdit} style={iconStyle} />
          <DeleteIcon onClick={onDelete} style={iconStyle} />
          <FileCopyIcon onClick={onCopy} style={iconStyle} />
        </ActionsContainer>
      </TableRowItemWrapper>
    </PracticeGuideRowContainer>
  );
}
