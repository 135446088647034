import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
// import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Typography,
  Grid,
  Avatar,
  Paper,
  CardHeader
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { blue } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
    root: {},
    details: {
      display: 'flex'
    },
    avatar: {
      marginLeft: 'auto',
      height: 110,
      width: 100,
      flexShrink: 0,
      flexGrow: 0
    },
    progress: {
      marginTop: theme.spacing(2)
    },
    uploadButton: {
      marginRight: theme.spacing(2)
    },
    blue: {
      color: '#fff',
      backgroundColor: blue[500]
    },
    paper: {
      padding: theme.spacing(2),
      border: theme.spacing(2),
    },
    header: {
      backgroundColor: blue[50],
    }
  }));

  const AccountNotFound = props => {
    const { className, ...rest } = props;
    const classes = useStyles();
    return (
      <Grid
      container
      className={classes.paper}
      >
        <Paper
        elevation={2}
        className={clsx(classes.root, className)}
        {...rest}
        >
          <CardHeader className={classes.header}/>
        <Grid
        className={classes.paper}>
            <Grid
            container
            justify="center"
            >
              <Avatar
              className={classes.blue}>
                <LockOutlinedIcon />
              </Avatar>
            </Grid>
            <Grid
            container
            justify="center"
            alignContent="center"
            >
              <Typography
              variant = "h3"
              className={classes.paper}
              >
                  {rest.error.error}
              </Typography>
            </Grid>
          </Grid>
      </Paper>
      </Grid>
    )
}

AccountNotFound.propTypes = {
    className: PropTypes.string
  };

export default AccountNotFound