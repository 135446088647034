export const heightValues = [
    "4'6\"",
    "4'7\"",
    "4'8\"",
    "4'9\"",
    "4'10\"",
    "4'11\"",
    "5'0\"",
    "5'1\"",
    "5'2\"",
    "5'3\"",
    "5'4\"",
    "5'5\"",
    "5'6\"",
    "5'7\"",
    "5'8\"",
    "5'9\"",
    "5'10\"",
    "5'11\"",
    "6'0\"",
    "6'1\"",
    "6'2\"",
    "6'3\"",
    "6'4\"",
    "6'5\"",
    "6'6\"",
    "6'7\"",
    "6'8\"",
    "6'9\"",
    "6'10\"",
    "6'11\"",
    "7'0\"",
];

export const maleHeightToHipWidth = {
    "4'6\"": [5.3, 5.4, 5.5, 5.6, 5.7, 5.8, 5.9, 6, 6.1, 6.2],
    "4'7\"": [5.4, 5.5, 5.6, 5.7, 5.8, 5.9, 6, 6.1, 6.2, 6.3],
    "4'8\"": [5.5, 5.6, 5.7, 5.8, 5.9, 6, 6.1, 6.2, 6.3, 6.4],
    "4'9\"": [5.6, 5.7, 5.8, 5.9, 6, 6.1, 6.2, 6.3, 6.4, 6.5],
    "4'10\"": [5.7, 5.8, 5.9, 6, 6.1, 6.2, 6.3, 6.4, 6.5, 6.6],
    "4'11\"": [5.8, 5.9, 6, 6.1, 6.2, 6.3, 6.4, 6.5, 6.6, 6.7],
    "5'0\"": [5.9, 6, 6.1, 6.2, 6.3, 6.4, 6.5, 6.6, 6.7, 6.8],
    "5'1\"": [6, 6.1, 6.2, 6.3, 6.4, 6.5, 6.6, 6.7, 6.8, 6.9],
    "5'2\"": [6.1, 6.2, 6.3, 6.4, 6.5, 6.6, 6.7, 6.8, 6.9, 7],
    "5'3\"": [6.2, 6.3, 6.4, 6.5, 6.6, 6.7, 6.8, 6.9, 7, 7.1],
    "5'4\"": [6.3, 6.4, 6.5, 6.6, 6.7, 6.8, 6.9, 7, 7.1, 7.2],
    "5'5\"": [6.4, 6.5, 6.6, 6.7, 6.8, 6.9, 7, 7.1, 7.2, 7.3],
    "5'6\"": [6.5, 6.6, 6.7, 6.8, 6.9, 7, 7.1, 7.2, 7.3, 7.4],
    "5'7\"": [6.6, 6.7, 6.8, 6.9, 7, 7.1, 7.2, 7.3, 7.4, 7.5],
    "5'8\"": [6.7, 6.8, 6.9, 7, 7.1, 7.2, 7.3, 7.4, 7.5, 7.6],
    "5'9\"": [6.8, 6.9, 7, 7.1, 7.2, 7.3, 7.4, 7.5, 7.6, 7.7],
    "5'10\"": [6.9, 7, 7.1, 7.2, 7.3, 7.4, 7.5, 7.6, 7.7, 7.8],
    "5'11\"": [7, 7.1, 7.2, 7.3, 7.4, 7.5, 7.6, 7.7, 7.8, 7.9],
    "6'0\"": [7.1, 7.2, 7.3, 7.4, 7.5, 7.6, 7.7, 7.8, 7.9, 8],
    "6'1\"": [7.2, 7.3, 7.4, 7.5, 7.6, 7.7, 7.8, 7.9, 8, 8.1],
    "6'2\"": [7.3, 7.4, 7.5, 7.6, 7.7, 7.8, 7.9, 8, 8.1, 8.2],
    "6'3\"": [7.4, 7.5, 7.6, 7.7, 7.8, 7.9, 8, 8.1, 8.2, 8.3],
    "6'4\"": [7.5, 7.6, 7.7, 7.8, 7.9, 8, 8.1, 8.2, 8.3, 8.4],
    "6'5\"": [7.6, 7.7, 7.8, 7.9, 8, 8.1, 8.2, 8.3, 8.4, 8.5],
    "6'6\"": [7.7, 7.8, 7.9, 8, 8.1, 8.2, 8.3, 8.4, 8.5, 8.6],
    "6'7\"": [7.8, 7.9, 8, 8.1, 8.2, 8.3, 8.4, 8.5, 8.6, 8.7],
    "6'8\"": [7.9, 8, 8.1, 8.2, 8.3, 8.4, 8.5, 8.6, 8.7, 8.8],
    "6'9\"": [8, 8.1, 8.2, 8.3, 8.4, 8.5, 8.6, 8.7, 8.8, 8.9],
    "6'10\"": [8.1, 8.2, 8.3, 8.4, 8.5, 8.6, 8.7, 8.8, 8.9, 9],
    "6'11\"": [8.2, 8.3, 8.4, 8.5, 8.6, 8.7, 8.8, 8.9, 9, 9.1],
    "7'0\"": [8.3, 8.4, 8.5, 8.6, 8.7, 8.8, 8.9, 9, 9.1, 9.2],
};

export const maleHeightToDefaultHipWidth = {
    "4'6\"": 5.8,
    "4'7\"": 5.9,
    "4'8\"": 6,
    "4'9\"": 6.1,
    "4'10\"": 6.2,
    "4'11\"": 6.3,
    "5'0\"": 6.4,
    "5'1\"": 6.5,
    "5'2\"": 6.6,
    "5'3\"": 6.7,
    "5'4\"": 6.8,
    "5'5\"": 6.9,
    "5'6\"": 7,
    "5'7\"": 7.1,
    "5'8\"": 7.2,
    "5'9\"": 7.3,
    "5'10\"": 7.4,
    "5'11\"": 7.5,
    "6'0\"": 7.6,
    "6'1\"": 7.7,
    "6'2\"": 7.8,
    "6'3\"": 7.9,
    "6'4\"": 8,
    "6'5\"": 8.1,
    "6'6\"": 8.2,
    "6'7\"": 8.3,
    "6'8\"": 8.4,
    "6'9\"": 8.5,
    "6'10\"": 8.6,
    "6'11\"": 8.7,
    "7'0\"": 8.8,
};

export const femaleHeightToHipWidth = {
    "4'6\"": [5.6, 5.7, 5.8, 5.9, 6, 6.1, 6.2, 6.3, 6.4, 6.5, 6.6],
    "4'7\"": [5.7, 5.8, 5.9, 6, 6.1, 6.2, 6.3, 6.4, 6.5, 6.6, 6.7],
    "4'8\"": [5.8, 5.9, 6, 6.1, 6.2, 6.3, 6.4, 6.5, 6.6, 6.7, 6.8],
    "4'9\"": [5.9, 6, 6.1, 6.2, 6.3, 6.4, 6.5, 6.6, 6.7, 6.8, 6.9],
    "4'10\"": [6, 6.1, 6.2, 6.3, 6.4, 6.5, 6.6, 6.7, 6.8, 6.9, 7],
    "4'11\"": [6.1, 6.2, 6.3, 6.4, 6.5, 6.6, 6.7, 6.8, 6.9, 7, 7.1],
    "5'0\"": [6.2, 6.3, 6.4, 6.5, 6.6, 6.7, 6.8, 6.9, 7, 7.1, 7.2],
    "5'1\"": [6.3, 6.4, 6.5, 6.6, 6.7, 6.8, 6.9, 7, 7.1, 7.2, 7.3],
    "5'2\"": [6.4, 6.5, 6.6, 6.7, 6.8, 6.9, 7, 7.1, 7.2, 7.3, 7.4],
    "5'3\"": [6.5, 6.6, 6.7, 6.8, 6.9, 7, 7.1, 7.2, 7.3, 7.4, 7.5],
    "5'4\"": [6.6, 6.7, 6.8, 6.9, 7, 7.1, 7.2, 7.3, 7.4, 7.5, 7.6],
    "5'5\"": [6.7, 6.8, 6.9, 7, 7.1, 7.2, 7.3, 7.4, 7.5, 7.6, 7.7],
    "5'6\"": [6.8, 6.9, 7, 7.1, 7.2, 7.3, 7.4, 7.5, 7.6, 7.7, 7.8],
    "5'7\"": [6.9, 7, 7.1, 7.2, 7.3, 7.4, 7.5, 7.6, 7.7, 7.8, 7.9],
    "5'8\"": [7, 7.1, 7.2, 7.3, 7.4, 7.5, 7.6, 7.7, 7.8, 7.9, 8],
    "5'9\"": [7.1, 7.2, 7.3, 7.4, 7.5, 7.6, 7.7, 7.8, 7.9, 8, 8.1],
    "5'10\"": [7.2, 7.3, 7.4, 7.5, 7.6, 7.7, 7.8, 7.9, 8, 8.1, 8.2],
    "5'11\"": [7.3, 7.4, 7.5, 7.6, 7.7, 7.8, 7.9, 8, 8.1, 8.2, 8.3],
    "6'0\"": [7.4, 7.5, 7.6, 7.7, 7.8, 7.9, 8, 8.1, 8.2, 8.3, 8.4],
    "6'1\"": [7.5, 7.6, 7.7, 7.8, 7.9, 8, 8.1, 8.2, 8.3, 8.4, 8.5],
    "6'2\"": [7.6, 7.7, 7.8, 7.9, 8, 8.1, 8.2, 8.3, 8.4, 8.5, 8.6],
    "6'3\"": [7.7, 7.8, 7.9, 8, 8.1, 8.2, 8.3, 8.4, 8.5, 8.6, 8.7],
    "6'4\"": [7.8, 7.9, 8, 8.1, 8.2, 8.3, 8.4, 8.5, 8.6, 8.7, 8.8],
    "6'5\"": [7.9, 8, 8.1, 8.2, 8.3, 8.4, 8.5, 8.6, 8.7, 8.8, 8.9],
    "6'6\"": [8, 8.1, 8.2, 8.3, 8.4, 8.5, 8.6, 8.7, 8.8, 8.9, 9],
    "6'7\"": [8.1, 8.2, 8.3, 8.4, 8.5, 8.6, 8.7, 8.8, 8.9, 9, 9.1],
    "6'8\"": [8.2, 8.3, 8.4, 8.5, 8.6, 8.7, 8.8, 8.9, 9, 9.1, 9.2],
    "6'9\"": [8.3, 8.4, 8.5, 8.6, 8.7, 8.8, 8.9, 9, 9.1, 9.2, 9.3],
    "6'10\"": [8.4, 8.5, 8.6, 8.7, 8.8, 8.9, 9, 9.1, 9.2, 9.3, 9.4],
    "6'11\"": [8.5, 8.6, 8.7, 8.8, 8.9, 9, 9.1, 9.2, 9.3, 9.4, 9.5],
    "7'0\"": [8.6, 8.7, 8.8, 8.9, 9, 9.1, 9.2, 9.3, 9.4, 9.5, 9.6],
};

export const femaleHeightToDefaultHipWidth = {
    "4'6\"": 6.1,
    "4'7\"": 6.2,
    "4'8\"": 6.3,
    "4'9\"": 6.4,
    "4'10\"": 6.5,
    "4'11\"": 6.6,
    "5'0\"": 6.7,
    "5'1\"": 6.8,
    "5'2\"": 6.9,
    "5'3\"": 7,
    "5'4\"": 7.1,
    "5'5\"": 7.2,
    "5'6\"": 7.3,
    "5'7\"": 7.4,
    "5'8\"": 7.5,
    "5'9\"": 7.6,
    "5'10\"": 7.7,
    "5'11\"": 7.8,
    "6'0\"": 7.9,
    "6'1\"": 8,
    "6'2\"": 8.1,
    "6'3\"": 8.2,
    "6'4\"": 8.3,
    "6'5\"": 8.4,
    "6'6\"": 8.5,
    "6'7\"": 8.6,
    "6'8\"": 8.7,
    "6'9\"": 8.8,
    "6'10\"": 8.9,
    "6'11\"": 9,
    "7'0\"": 9.1,
};

export const defaultHeightToHipWidth = {
    "4'6\"": [
        5.3, 5.4, 5.5, 5.6, 5.7, 5.8, 5.9, 6, 6.1, 6.2, 6.3, 6.4, 6.5, 6.6,
    ],
    "4'7\"": [
        5.4, 5.5, 5.6, 5.7, 5.8, 5.9, 6, 6.1, 6.2, 6.3, 6.4, 6.5, 6.6, 6.7,
    ],
    "4'8\"": [
        5.5, 5.6, 5.7, 5.8, 5.9, 6, 6.1, 6.2, 6.3, 6.4, 6.5, 6.6, 6.7, 6.8,
    ],
    "4'9\"": [
        5.6, 5.7, 5.8, 5.9, 6, 6.1, 6.2, 6.3, 6.4, 6.5, 6.6, 6.7, 6.8, 6.9,
    ],
    "4'10\"": [
        5.7, 5.8, 5.9, 6, 6.1, 6.2, 6.3, 6.4, 6.5, 6.6, 6.7, 6.8, 6.9, 7,
    ],
    "4'11\"": [
        5.8, 5.9, 6, 6.1, 6.2, 6.3, 6.4, 6.5, 6.6, 6.7, 6.8, 6.9, 7, 7.1,
    ],
    "5'0\"": [5.9, 6, 6.1, 6.2, 6.3, 6.4, 6.5, 6.6, 6.7, 6.8, 6.9, 7, 7.1, 7.2],
    "5'1\"": [6, 6.1, 6.2, 6.3, 6.4, 6.5, 6.6, 6.7, 6.8, 6.9, 7, 7.1, 7.2, 7.3],
    "5'2\"": [
        6.1, 6.2, 6.3, 6.4, 6.5, 6.6, 6.7, 6.8, 6.9, 7, 7.1, 7.2, 7.3, 7.4,
    ],
    "5'3\"": [
        6.2, 6.3, 6.4, 6.5, 6.6, 6.7, 6.8, 6.9, 7, 7.1, 7.2, 7.3, 7.4, 7.5,
    ],
    "5'4\"": [
        6.3, 6.4, 6.5, 6.6, 6.7, 6.8, 6.9, 7, 7.1, 7.2, 7.3, 7.4, 7.5, 7.6,
    ],
    "5'5\"": [
        6.4, 6.5, 6.6, 6.7, 6.8, 6.9, 7, 7.1, 7.2, 7.3, 7.4, 7.5, 7.6, 7.7,
    ],
    "5'6\"": [
        6.5, 6.6, 6.7, 6.8, 6.9, 7, 7.1, 7.2, 7.3, 7.4, 7.5, 7.6, 7.7, 7.8,
    ],
    "5'7\"": [
        6.6, 6.7, 6.8, 6.9, 7, 7.1, 7.2, 7.3, 7.4, 7.5, 7.6, 7.7, 7.8, 7.9,
    ],
    "5'8\"": [6.7, 6.8, 6.9, 7, 7.1, 7.2, 7.3, 7.4, 7.5, 7.6, 7.7, 7.8, 7.9, 8],
    "5'9\"": [6.8, 6.9, 7, 7.1, 7.2, 7.3, 7.4, 7.5, 7.6, 7.7, 7.8, 7.9, 8, 8.1],
    "5'10\"": [
        6.9, 7, 7.1, 7.2, 7.3, 7.4, 7.5, 7.6, 7.7, 7.8, 7.9, 8, 8.1, 8.2,
    ],
    "5'11\"": [
        7, 7.1, 7.2, 7.3, 7.4, 7.5, 7.6, 7.7, 7.8, 7.9, 8, 8.1, 8.2, 8.3,
    ],
    "6'0\"": [
        7.1, 7.2, 7.3, 7.4, 7.5, 7.6, 7.7, 7.8, 7.9, 8, 8.1, 8.2, 8.3, 8.4,
    ],
    "6'1\"": [
        7.2, 7.3, 7.4, 7.5, 7.6, 7.7, 7.8, 7.9, 8, 8.1, 8.2, 8.3, 8.4, 8.5,
    ],
    "6'2\"": [
        7.3, 7.4, 7.5, 7.6, 7.7, 7.8, 7.9, 8, 8.1, 8.2, 8.3, 8.4, 8.5, 8.6,
    ],
    "6'3\"": [
        7.4, 7.5, 7.6, 7.7, 7.8, 7.9, 8, 8.1, 8.2, 8.3, 8.4, 8.5, 8.6, 8.7,
    ],
    "6'4\"": [
        7.5, 7.6, 7.7, 7.8, 7.9, 8, 8.1, 8.2, 8.3, 8.4, 8.5, 8.6, 8.7, 8.8,
    ],
    "6'5\"": [
        7.6, 7.7, 7.8, 7.9, 8, 8.1, 8.2, 8.3, 8.4, 8.5, 8.6, 8.7, 8.8, 8.9,
    ],
    "6'6\"": [7.7, 7.8, 7.9, 8, 8.1, 8.2, 8.3, 8.4, 8.5, 8.6, 8.7, 8.8, 8.9, 9],
    "6'7\"": [7.8, 7.9, 8, 8.1, 8.2, 8.3, 8.4, 8.5, 8.6, 8.7, 8.8, 8.9, 9, 9.1],
    "6'8\"": [7.9, 8, 8.1, 8.2, 8.3, 8.4, 8.5, 8.6, 8.7, 8.8, 8.9, 9, 9.1, 9.2],
    "6'9\"": [8, 8.1, 8.2, 8.3, 8.4, 8.5, 8.6, 8.7, 8.8, 8.9, 9, 9.1, 9.2, 9.3],
    "6'10\"": [
        8.1, 8.2, 8.3, 8.4, 8.5, 8.6, 8.7, 8.8, 8.9, 9, 9.1, 9.2, 9.3, 9.4,
    ],
    "6'11\"": [
        8.2, 8.3, 8.4, 8.5, 8.6, 8.7, 8.8, 8.9, 9, 9.1, 9.2, 9.3, 9.4, 9.5,
    ],
    "7'0\"": [
        8.3, 8.4, 8.5, 8.6, 8.7, 8.8, 8.9, 9, 9.1, 9.2, 9.3, 9.4, 9.5, 9.6,
    ],
};

export const defaultHeightToDefaultHipWidth = {
    "4'6\"": 6,
    "4'7\"": 6.1,
    "4'8\"": 6.2,
    "4'9\"": 6.3,
    "4'10\"": 6.4,
    "4'11\"": 6.5,
    "5'0\"": 6.6,
    "5'1\"": 6.7,
    "5'2\"": 6.8,
    "5'3\"": 6.9,
    "5'4\"": 7,
    "5'5\"": 7.1,
    "5'6\"": 7.2,
    "5'7\"": 7.3,
    "5'8\"": 7.4,
    "5'9\"": 7.5,
    "5'10\"": 7.6,
    "5'11\"": 7.7,
    "6'0\"": 7.8,
    "6'1\"": 7.9,
    "6'2\"": 8,
    "6'3\"": 8.1,
    "6'4\"": 8.2,
    "6'5\"": 8.3,
    "6'6\"": 8.4,
    "6'7\"": 8.5,
    "6'8\"": 8.6,
    "6'9\"": 8.7,
    "6'10\"": 8.8,
    "6'11\"": 8.9,
    "7'0\"": 9,
};
