import React, { useEffect } from "react";
import AddIcon from "@material-ui/icons/Add";
import { FlexWrap, Pad } from "../../../../components";
import { Divider, Typography } from "@material-ui/core";

export function PracticeGuideModalSummary({ data, onAdd }) {
    const titles = {
        warmup: "Warm Up",
        drills: "Drills",
        targetPractice: "Target Practice",
    };

    const elWrap = (type, lastEl) => (
        <>
            <div
                style={{
                    width: "100%",
                    padding: "10px",
                }}
            >
                <FlexWrap justify="space-between">
                    <div>{titles[type]}</div>
                    <AddIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => onAdd(type)}
                    />
                </FlexWrap>
                {data[type].map((el) => (
                    <FlexWrap
                        style={{ marginTop: "7px" }}
                        justify="space-between"
                        align="center"
                    >
                        <FlexWrap width="fit-content">
                            <img
                                style={{
                                    margin: "0 20px",
                                    borderRadius: "4px",
                                }}
                                width={50}
                                height={50}
                                src={el?.video?.thumbnail}
                            />
                            <div>{el.name}</div>
                        </FlexWrap>
                        <div
                            style={{
                                padding: "4px 6px",
                                background: "#C9C9C9",
                                borderRadius: "4px",
                            }}
                        >
                            {el.video.length}s
                        </div>
                    </FlexWrap>
                ))}
            </div>
            {!lastEl ? (
                <Divider
                    style={{
                        height: "0.5px",
                        background: "#C9C9C9",
                        width: "100%",
                    }}
                />
            ) : (
                <></>
            )}
        </>
    );

    return (
        <Pad margin="10px 0" width="450px" height="fit-content">
            <FlexWrap
                direction="column"
                justify="space-between"
                align="baseline"
            >
                <Typography variant="h4" component="h4">
                    Summary
                </Typography>
                <Pad margin="0 0 10px 0" />
                {Object.keys(data).map((el, i) =>
                    elWrap(el, i === Object.keys(data).length - 1)
                )}
            </FlexWrap>
        </Pad>
    );
}
