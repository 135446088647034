import React from "react";
import styles from "./styles.module.css";

const TermsOfService = () => (
  <>
    <div className={styles.container}>
      <div className={`${styles.bold} ${styles.centered}`}>
        Sportsbox Terms of Service
      </div>
      <div className={styles.spacer}></div>
      <div className={styles.italic}>Last Updated: 5/18/2024</div>
      <div className={styles.spacer}></div>
      <div>
        Welcome and thank you for your interest in
        <span className={styles.boldUnderline}> Sportsbox.ai Inc. </span>
        ("<span className={styles.boldUnderline}>Sportsbox</span>" "
        <span className={styles.boldUnderline}>we</span>" or "
        <span className={styles.boldUnderline}>us</span>") and our website at
        sportsbox.ai along with our related websites, hosted applications,
        mobile applications, and other services provided by us (collectively,
        the "<span className={styles.boldUnderline}>Service</span>"). These
        Terms of Service are a legally binding contract between you and
        Sportsbox regarding your use of the Service.
      </div>
      <div className={styles.spacer}></div>
      <div className={styles.bold}>
        PLEASE READ THE FOLLOWING TERMS CAREFULLY:
      </div>
      <div className={styles.firstIndent}>
        <span className={styles.bold}>
          BY CLICKING “I ACCEPT” OR BY DOWNLOADING, INSTALLING, OR OTHERWISE
          ACCESSING OR USING THE SERVICE
        </span>
        , YOU AGREE THAT YOU HAVE READ AND UNDERSTOOD, AND AS A CONDITION TO
        YOUR USE OF THE SERVICE, YOU AGREE TO BE BOUND BY THE FOLLOWING TERMS
        AND CONDITIONS INCLUDING SPORTSBOX’S PRIVACY POLICY [
        <a href="https://3dbaseball.sportsbox.ai/privacy">
          https://3dbaseball.sportsbox.ai/privacy
        </a>
        ] (TOGETHER, THESE “<span className={styles.boldUnderline}>TERMS</span>”
        ). If you are not eligible or do not agree to the Terms, then you do not
        have our permission to USE THE SERVICE. YOUR USE OF THE SERVICE AND
        SPORTSBOX’S PROVISION OF THE SERVICE TO YOU CONSTITUTES AN AGREEMENT BY
        SPORTSBOX AND BY YOU TO BE BOUND BY THESE TERMS.
      </div>
      <div className={styles.spacer}></div>
      <div className={styles.firstIndent}>
        <span className={styles.bold}>ARBITRATION NOTICE. </span>
        Except for certain kinds of disputes described in Section 17, you agree
        that disputes arising under these Terms will be resolved by binding
        individual arbitration and BY ACCEPTING THESE TERMS YOU AND SPORTSBOX
        ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN ANY
        CLASS ACTION OR REPRESENTATIVE PROCEEDING. YOU AGREE TO GIVE UP YOUR
        RIGHT TO GO TO COURT to assert or defend your rights under this contract
        (except for matters that may be taken to small claims court). Your
        rights will be determined by a NEUTRAL ARBITRATOR and NOT a judge or
        jury. (See Section 17.)
      </div>
      <div className={styles.spacer}></div>
      <div>
        <span className={styles.bold}>1. Service Overview. </span>
        We provide a platform that helps sports or fitness coaches (“Coaches”)
        and athletes (“Players”) train and improve their performance. The
        Service makes available tools that are designed to generate
        3-dimensional motion analysis and provide corrective feedback. The
        features and content available to you may depend on the type of Account
        and level of Subscription Service you have (see Sections 3 and 4 for
        more detail).
      </div>
      <div className={styles.spacer}></div>
      <div className={styles.bold}>2. Eligibility</div>
      <div className={styles.firstIndent}>
        <span className={styles.bold}>2.1 Users. </span>
        If you are a user accessing or using the Service, you represent and
        warrant to us that:
      </div>
      <div className={styles.secondIndent}>
        <div>(a) You are at least 18 years old; or</div>
        <div>
          (b) If you are under 18 years old, your parent or legal guardian has
          reviewed and discussed these Terms with you, agreed to these Terms on
          your behalf, set up your Account for you, and supervises your use of
          the Service.
        </div>
      </div>
      <div className={styles.firstIndent}>
        <span className={styles.bold}>2.2 Parents or Legal Guardians. </span>
        If you are a parent or legal guardian of a child under the age of 18 who
        would like to access and use the Service, you, on your own behalf and
        your child's behalf, agree to these Terms and to supervise your child's
        use of the Service and acknowledge that your child's use of the Service
        will be subject to these Terms.
      </div>
      <div className={styles.firstIndent}>
        <span className={styles.bold}>2.3 Eligibility in General. </span>
        Additionally, by agreeing to these Terms, you represent and warrant to
        us that: (a) you have not previously been suspended or removed from the
        Service; and (b) your registration and your use of the Service is in
        compliance with any and all applicable laws and regulations. If you are
        an entity, organization, or company, the individual accepting these
        Terms on your behalf represents and warrants that they have authority to
        bind you to these Terms and you agree to be bound by these Terms.
      </div>
      <div className={styles.spacer}></div>
      <div>
        <span className={styles.bold}>3. Accounts and Registration. </span>
        To access most features of the Service, you must register for an
        account.
      </div>
      <div className={styles.firstIndent}>
        <span className={styles.bold}>3.1 Coaches. </span>
        Subject to these Terms, Coaches can set up a coach account on the
        Service (“Coach Account”). To set up a Coach Account, you may be
        required to provide us with certain information, including your name,
        email address, phone number, date of birth, place of
        employment/professional affiliation, and information about your
        Player(s) (defined below). If you provide any information to us about
        any third party (such as a Player or the parent or legal guardian of a
        Player), you represent and warrant that you have the authorization or
        permission to provide that information and to authorize us to use that
        information for the purposes that information is provided for. You may
        be able to invite Players to the Service or otherwise link Player
        Accounts with your Coach Account and those Players and their Player
        Accounts will become associated with your Coach Account (each Player
        associated with your Coach Account “your Player”). You may share
        content, analysis, and other information with your Players under your
        Coach Account. If your Coach Account is suspended or terminated for any
        reason, your Players may cease to have access to those materials during
        the suspension or after the termination.
      </div>
      <div className={styles.firstIndent}>
        <span className={styles.bold}>3.2 Players. </span>A Player must have a
        “Player Account” (each Coach Account or Player Account an “Account”) to
        use the Service. To register for a Player Account, you may be required
        to provide us with certain information, including your name, email
        address, phone number, date of birth, body measurements, baseball and
        softball behavior and preferences, and skill level. If your Player
        Account is associated with a Coach Account, then you will have access to
        materials your Coach shares with you. If your Player Account becomes
        disassociated with the Coach Account for any reason (including if the
        Coach Account is suspended or terminated) and these Terms have not
        otherwise been terminated, then you will no longer have access to
        materials shared by your Coach. If you are a Player under the age of 18,
        your parent or legal guardian must set up your Player Account and agree
        to these Terms on your behalf.
      </div>
      <div className={styles.firstIndent}>
        <span className={styles.bold}>3.3 Accounts in General. </span>You agree
        that the information you provide to us is accurate and that you will
        keep it accurate and up to date at all times. You are solely responsible
        for maintaining the confidentiality of your Account and password and you
        accept responsibility for all activities that occur under your Account.
        If you believe that your Account is no longer secure, then you must
        immediately notify us at{" "}
        <a href="mailto:contact@spotsbox.ai">contact@spotsbox.ai</a>.
      </div>
      <div className={styles.spacer}></div>
      <div>
        <span className={styles.bold}>4. General Payment Terms. </span>
        Certain features of the Service may require you to pay fees. Before you
        pay any fees, you will have an opportunity to review and accept the fees
        that you will be charged. All fees are in U.S. Dollars and are
        non-refundable unless otherwise specifically provided for herein.
      </div>

      <div className={styles.spacer}></div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>4.1 Price. </span>Sportsbox reserves the
        right to determine pricing for the Service. Sportsbox will make
        reasonable efforts to keep pricing information published on the Service
        up to date. We encourage you to check our pricing page periodically for
        current pricing information. Sportsbox may change the fees for any
        feature of the Service, including additional fees or charges, if
        Sportsbox gives you advance notice of changes before they apply to you.
        Sportsbox, at its sole discretion, may make promotional offers with
        different features and different pricing to any of Sportsbox’s
        customers. These promotional offers, unless made to you, will not apply
        to your offer or these Terms.
      </div>

      <div className={styles.spacer}></div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>4.2 Authorization. </span>You authorize
        Sportsbox to charge all sums for the orders that you make and any level
        of Service you select as described in these Terms or published by
        Sportsbox, including all applicable taxes, to the payment method
        specified in your Account. If you pay any fees with a credit card, then
        Sportsbox may seek pre-authorization of your credit card account prior
        to your purchase to verify that the credit card is valid and has the
        necessary funds or credit available to cover your purchase.
      </div>

      <div className={styles.spacer}></div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>4.3 Subscription Service. </span>The
        Service may include certain subscription-based plans with automatically
        recurring payments for periodic charges (“Subscription Service”). The
        “Subscription Billing Date” is the date when you purchase your first
        subscription to the Service. The Subscription Service will begin on the
        Subscription Billing Date and continue for the subscription period that
        you select on your Account (such period the “Initial Subscription
        Period”) and will automatically renew for successive periods of the same
        duration as the Initial Subscription Period (the Initial Subscription
        Period and each such renewal period each a “Subscription Period”) unless
        you cancel the Subscription Service or we terminate it. If you activate
        a Subscription Service, then you authorize Sportsbox or its third-party
        payment processors to periodically charge on a going-forward basis and
        until cancellation of the Subscription Service all accrued sums on or
        before the payment due date to the payment method associated with your
        Account or that you otherwise provide to us. For information on the
        “Subscription Fee” please see our PRICING page [
        <a href="https://www.sportsbox.ai/pricing">
          https://www.sportsbox.ai/pricing
        </a>
        ]. Your Account will be charged automatically on the Subscription
        Billing Date and thereafter on the renewal date of your Subscription
        Service for all applicable fees and taxes for the next Subscription
        Period. You must cancel your Subscription Service 5 days before it
        renews in order to avoid billing of the next periodic Subscription Fee
        to your Account. You may cancel the Subscription Service by contacting
        us at <a href="mailto:contact@sportsbox.ai">contact@sportsbox.ai</a>.
        Your cancellation must be received 5 DAYS before the renewal date in
        order to avoid charge for the next subscription period.
      </div>

      <div className={styles.spacer}></div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>4.4 Delinquent Accounts. </span>The
        Sportsbox may suspend or terminate access to the Service, including
        fee-based portions of the Service, for any Account for which any amount
        is due but unpaid. In addition to the amount due for the Service, a
        delinquent Account will be charged with fees or charges that are
        incidental to any chargeback or collection of any unpaid amount,
        including collection fees. If your payment method is no longer valid at
        the time a renewal Subscription Fee is due, then we reserve the right to
        delete your Account and any information or User Content (defined below)
        associated with your Account without any liability to you (or your
        Players if you have a Coach Account).
      </div>

      <div className={styles.spacer}></div>

      <div>
        <span className={styles.bold}>5. Licenses. </span>
      </div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>5.1 Limited License. </span>Subject to
        your complete and ongoing compliance with these Terms, Sportsbox grants
        you, solely for your personal, non-commercial use, a limited,
        non-exclusive, non-transferable, non-sublicensable, revocable license
        to: (a) install and use one object code copy of any mobile application
        associated with the Service (whether installed by you or pre-installed
        on your mobile device by the device manufacturer or a wireless telephone
        provider) on a mobile device that you own or control; and (b) access and
        use the Service.
      </div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>5.2 License Restrictions. </span>Except
        and solely to the extent such a restriction is impermissible under
        applicable law, you may not: (a) reproduce, distribute, publicly
        display, publicly perform, or create derivative works of the Service;
        (b) make modifications to the Service; or (c) interfere with or
        circumvent any feature of the Service, including any security or access
        control mechanism. If you are prohibited under applicable law from using
        the Service, then you may not use it.
      </div>

      <div className={styles.spacer}></div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>5.3 Feedback. </span> We respect and
        appreciate the thoughts and comments from our users. If you choose to
        provide input and suggestions regarding existing functionalities,
        problems with, or proposed modifications or improvements to the Service
        (“Feedback”), then you hereby grant Sportsbox an unrestricted,
        perpetual, irrevocable, non-exclusive, fully-paid, royalty-free right
        and license to exploit the Feedback in any manner and for any purpose,
        including to improve the Service and create other products and services.
        We will have no obligation to provide you with attribution for any
        Feedback you provide to us.
      </div>
      <div className={styles.spacer}></div>

      <div>
        <span className={styles.bold}>6. Ownership; Proprietary Rights. </span>
        The Service is owned and operated by Sportsbox. The visual interfaces,
        graphics, design, compilation, information, data, computer code
        (including source code or object code), products, software, services,
        and all other elements of the Service provided by Sportsbox
        (“Materials”) are protected by intellectual property and other laws. All
        Materials included in the Service are the property of Sportsbox or its
        third-party licensors. Except as expressly authorized by Sportsbox, you
        may not make use of the Materials. There are no implied licenses in
        these Terms and Sportsbox reserves all rights to the Materials not
        granted expressly in these Terms.
      </div>

      <div className={styles.spacer}></div>

      <div>
        <span className={styles.bold}>7. Third-Party Terms </span>
        The Service is owned and operated by Sportsbox. The visual interfaces,
        graphics, design, compilation, information, data, computer code
        (including source code or object code), products, software, services,
        and all other elements of the Service provided by Sportsbox
        (“Materials”) are protected by intellectual property and other laws. All
        Materials included in the Service are the property of Sportsbox or its
        third-party licensors. Except as expressly authorized by Sportsbox, you
        may not make use of the Materials. There are no implied licenses in
        these Terms and Sportsbox reserves all rights to the Materials not
        granted expressly in these Terms.
      </div>

      <div className={styles.spacer}></div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>
          7.1 Third-Party Services and Linked Websites.{" "}
        </span>
        Sportsbox may provide tools through the Service that enable you to
        export information, including User Content (defined below), to
        third-party services. By using one of these tools, you hereby authorize
        Sportsbox to transfer that information to the applicable third-party
        service. Third-party services are not under Sportsbox’s control and, to
        the fullest extent permitted by law, Sportsbox is not responsible for
        any third-party service’s use of your exported information (including
        User Content). Please be sure to review the terms of use and privacy
        policy of any third-party services before you share any User Content or
        information with such third-party services. The Service may also contain
        links to third-party websites. Linked websites are not under Sportsbox’s
        control and Sportsbox is not responsible for their content.
      </div>

      <div className={styles.spacer}></div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>7.2 Third-Party Software. </span>
        The Service may include or incorporate third-party software components
        that are generally available free of charge under licenses granting
        recipients broad rights to copy, modify, and distribute those components
        (“Third-Party Components”). Although the Service is provided to you
        subject to these Terms, nothing in these Terms prevents, restricts, or
        is intended to prevent or restrict you from obtaining Third-Party
        Components under the applicable third-party licenses or to limit your
        use of Third-Party Components under those third-party licenses.
      </div>

      <div className={styles.spacer}></div>

      <div>
        <span className={styles.bold}>7. User Content </span>
      </div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>8.1 User Content Generally. </span>
        Certain features of the Service may permit users to record, submit,
        upload, publish, or otherwise transmit (“Post”) content to the Service,
        including messages, reviews, photos, video or audio (including sound or
        voice recordings and musical recordings embodied in the video or audio),
        images, folders, data, text, and any other works of authorship or other
        works (“User Content”). You retain any copyright and other proprietary
        rights that you may hold in the User Content that you Post to the
        Service, subject to the licenses granted in these Terms.
      </div>
      <div className={styles.spacer}></div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>
          8.2 Limited License Grant to Sportsbox.{" "}
        </span>
        By Posting User Content to or via the Service, you grant Sportsbox a
        worldwide, non-exclusive, irrevocable, royalty-free, fully paid right
        and license (with the right to sublicense through multiple tiers) to:
        (a) host, store, transfer, publicly display, publicly perform (including
        by means of a digital audio transmission), communicate to the public,
        reproduce, modify for the purpose of formatting for display, create
        derivative works as authorized in these Terms, and distribute your User
        Content, in whole or in part, in any media formats and through any media
        channels now known or hereafter developed; and (b) to the extent
        permitted by applicable law and if you are located in the United States,
        use your User Content to improve and enhance the Materials, the Service,
        and any models used by Sportsbox in developing or providing the Service.
        All of the rights you grant in these Terms are provided on a
        through-to-the-audience basis, meaning the owners or operators of
        external services will not have any separate liability to you or any
        other third party for User Content Posted or otherwise used on external
        services via the Service. You agree to pay all monies owing to any
        person or entity resulting from Posting your User Content and from
        Sportsbox’s exercise of the license set forth in this Section.
      </div>

      <div className={styles.spacer}></div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>
          8.3 Limited License Grant to Other Users.{" "}
        </span>
        By Posting User Content to or via the Service and making it available to
        other users of the Service, you grant those users a non-exclusive
        license to access and use that User Content as permitted by these Terms
        and the functionality of the Service.
      </div>

      <div className={styles.spacer}></div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>
          8.3 You Must Have Rights to the Content You Post; User Content
          Representations and Warranties.{" "}
        </span>
        You must not Post User Content if you are not the owner of or are not
        fully authorized to grant rights in all of the elements of that User
        Content. Sportsbox disclaims any and all liability in connection with
        User Content. You are solely responsible for your User Content and the
        consequences of providing User Content via the Service. By providing
        User Content via the Service, you affirm, represent, and warrant to us
        that:
      </div>
      <div className={styles.spacer}></div>

      <div className={styles.secondIndent}>
        (a) you are the creator and owner of the User Content or have the
        necessary licenses, rights, consents, and permissions to authorize
        Sportsbox and users of the Service to use and distribute your User
        Content as necessary to exercise the licenses granted by you in this
        Section in the manner contemplated by Sportsbox, the Service, and these
        Terms;
      </div>
      <div className={styles.spacer}></div>

      <div className={styles.secondIndent}>
        (b) your User Content and the Posting or other use of your User Content
        as contemplated by these Terms does not and will not: (i) infringe,
        violate, misappropriate, or otherwise breach any third-party right,
        including any copyright, trademark, patent, trade secret, moral right,
        privacy right, right of publicity, or any other intellectual property,
        contract, or proprietary right; (ii) slander, defame, libel, or invade
        the right of privacy, publicity, or other property rights of any other
        person; or (iii) cause Sportsbox to violate any law or regulation or
        require us to obtain any further licenses from, or pay any royalties,
        fees, compensation, or other amounts or provide any attribution to any
        third parties; and
      </div>
      <div className={styles.spacer}></div>

      <div className={styles.secondIndent}>
        (c) your User Content could not be deemed by a reasonable person to be
        objectionable, profane, indecent, pornographic, harassing, threatening,
        embarrassing, hateful, or otherwise inappropriate.
      </div>
      <div className={styles.spacer}></div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>8.4 User Content Disclaimer. </span>
        We are under no obligation to edit or control User Content that you or
        other users Post and will not be in any way responsible or liable for
        User Content. Sportsbox may, however, at any time and without prior
        notice, screen, remove, edit, or block any User Content that in our sole
        judgment violates these Terms, is alleged to violate the rights of third
        parties, or is otherwise objectionable. You understand that when using
        the Service, you will be exposed to User Content from a variety of
        sources and acknowledge that User Content may be inaccurate, offensive,
        indecent, or objectionable. You agree to waive, and do waive, any legal
        or equitable right or remedy you have or may have against Sportsbox with
        respect to User Content. If notified by a user or content owner that
        User Content allegedly does not conform to these Terms, we may
        investigate the allegation and determine in our sole discretion whether
        to remove the User Content, which we reserve the right to do at any time
        and without notice. For clarity, Sportsbox does not permit infringing
        activities on the Service.
      </div>
      <div className={styles.spacer}></div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>8.5 User Content Disclaimer. </span>
        We are under no obligation to edit or control User Content that you or
        other users Post and will not be in any way responsible or liable for
        User Content. Sportsbox may, however, at any time and without prior
        notice, screen, remove, edit, or block any User Content that in our sole
        judgment violates these Terms, is alleged to violate the rights of third
        parties, or is otherwise objectionable. You understand that when using
        the Service, you will be exposed to User Content from a variety of
        sources and acknowledge that User Content may be inaccurate, offensive,
        indecent, or objectionable. You agree to waive, and do waive, any legal
        or equitable right or remedy you have or may have against Sportsbox with
        respect to User Content. If notified by a user or content owner that
        User Content allegedly does not conform to these Terms, we may
        investigate the allegation and determine in our sole discretion whether
        to remove the User Content, which we reserve the right to do at any time
        and without notice. For clarity, Sportsbox does not permit infringing
        activities on the Service.
      </div>
      <div className={styles.spacer}></div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>8.6 User Content Disclaimer. </span>
        We may, in our sole discretion, impose or modify limits on the size of
        the files embodying your User Content you may store on the Service from
        time to time. You may not be able to Post any additional User Content
        once you’ve reached the limit associated with your Account. We are not
        obligated to, and will have no liability for, any inability to Post or
        loss of User Content due to such limits unless we agree otherwise in
        writing (such as if you purchase additional storage on the Service from
        us).
      </div>
      <div className={styles.spacer}></div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>8.7 Monitoring Content. </span>
        Sportsbox does not control and does not have any obligation to monitor:
        (a) User Content; (b) any content made available by third parties; or
        (c) the use of the Service by its users. You acknowledge and agree that
        Sportsbox reserves the right to, and may from time to time, monitor any
        and all information transmitted or received through the Service for
        operational and other purposes. If at any time Sportsbox chooses to
        monitor the content, then Sportsbox still assumes no responsibility or
        liability for content or any loss or damage incurred as a result of the
        use of content. During monitoring, information may be examined,
        recorded, copied, and used in accordance with our Privacy Policy
        (defined below). Sportsbox may block, filter, mute, remove, or disable
        access to any User Content uploaded to or transmitted through the
        Service without any liability to the user who Posted such User Content
        to the Service or to any other users of the Service.
      </div>

      <div className={styles.spacer}></div>

      <div>
        <span className={styles.bold}>9. Communications </span>
      </div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>9.1 Text Messaging & Phone Calls. </span>
        Sportsbox does not control and does not have any obligation to monitor:
        (a) User Content; (b) any content made available by third parties; or
        (c) the use of the Service by its users. You acknowledge and agree that
        Sportsbox reserves the right to, and may from time to time, monitor any
        and all information transmitted or received through the Service for
        operational and other purposes. If at any time Sportsbox chooses to
        monitor the content, then Sportsbox still assumes no responsibility or
        liability for content or any loss or damage incurred as a result of the
        use of content. During monitoring, information may be examined,
        recorded, copied, and used in accordance with our Privacy Policy
        (defined below). Sportsbox may block, filter, mute, remove, or disable
        access to any User Content uploaded to or transmitted through the
        Service without any liability to the user who Posted such User Content
        to the Service or to any other users of the Service.
      </div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>9.2 Text Messaging & Phone Calls. </span>
        You agree that Sportsbox and those acting on our behalf may call and
        send you text (SMS) messages at the phone number you provide us. These
        calls and messages may include operational calls and messages about your
        use of the Service, as well as marketing calls and messages. Calls and
        text messages may be made or sent using an automatic telephone dialing
        system. Standard data and message rates may apply whenever you send or
        receive such calls or messages, as specified by your carrier. IF YOU
        WISH TO OPT OUT OF MARKETING CALLS OR MESSAGES FROM US, YOU CAN EMAIL{" "}
        <a href="mailto:contact@sportsbox.ai">CONTACT@SPORTSBOX.AI</a> OR TEXT
        THE WORD “STOP” FROM THE MOBILE DEVICE RECEIVING THE MESSAGES. IF YOU
        WISH TO OPT OUT OF ALL CALLS AND TEXT MESSAGES FROM SPORTSBOX, YOU CAN
        EMAIL <a href="mailto:contact@sportsbox.ai">CONTACT@SPORTSBOX.AI</a> OR
        TEXT THE WORD “STOPALL” TO THE NUMBER FROM WHICH YOU ARE RECEIVING THE
        MESSAGES, HOWEVER, YOU ACKNOWLEDGE THAT OPTING OUT OF RECEIVING ALL
        MESSAGES MAY IMPACT YOUR USE OF THE SERVICE. You may continue to receive
        calls and text messages for a short period while we process your
        request, including a message confirming the receipt of your opt-out
        request. Your agreement to receive marketing calls and texts is not a
        condition of any purchase on or use of the Service.
      </div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>9.3 Push Notifications. </span>
        When you install our app on your mobile device, you agree to receive
        push notifications, which are messages an app sends you on your mobile
        device when you are not in the app. You can turn off notifications by
        visiting your mobile device’s “settings” page.
      </div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>9.4 Email. </span>
        We may send you emails concerning our products and services, as well as
        those of third parties. You may opt out of promotional emails by
        following the unsubscribe instructions in the promotional email itself.
      </div>

      <div className={styles.spacer}></div>

      <div>
        <span className={styles.bold}>10. Prohibited Conduct </span>BY USING THE
        SERVICE YOU AGREE NOT TO:
      </div>

      <div className={styles.firstIndent}>
        (a) use the Service for any illegal purpose or in violation of any
        local, state, national, or international law;
      </div>

      <div className={styles.firstIndent}>
        (b) use the Service for any purpose other than the purposes contemplated
        by these Terms;
      </div>

      <div className={styles.firstIndent}>
        (c) use the Service or any Data or Materials available through the
        Service for any benchmarking or comparative purposes or in connection
        with the development of any product or service that is competitive with
        or derivative of a product or service of Sportsbox;
      </div>

      <div className={styles.firstIndent}>
        (d) harass, threaten, demean, embarrass, bully, or otherwise harm any
        other user of the Service;
      </div>

      <div className={styles.firstIndent}>
        (e) violate, encourage others to violate, or provide instructions on how
        to violate any right of a third party, including by infringing or
        misappropriating any third-party intellectual property right;
      </div>

      <div className={styles.firstIndent}>
        (f) access, search, or otherwise use any portion of the Service through
        the use of any engine, software, tool, agent, device, or mechanism
        (including spiders, robots, crawlers, and data mining tools) other than
        the software or search agents provided by Sportsbox;
      </div>

      <div className={styles.firstIndent}>
        (g) interfere with security-related features of the Service, including
        by: (i) disabling or circumventing features that prevent or limit use,
        printing, or copying of any content; or (ii) reverse engineering or
        otherwise attempting to discover the source code of any portion of the
        Service except to the extent that the activity is expressly permitted by
        applicable law;
      </div>

      <div className={styles.firstIndent}>
        (h) interfere with the operation of the Service or any user’s enjoyment
        of the Service, including by: (i) uploading or otherwise disseminating
        any virus, adware, spyware, worm, or other malicious code; (ii) making
        any unsolicited offer or advertisement to another user of the Service;
        (iii) collecting personal information about another user or third party
        without consent; or (iv) interfering with or disrupting any network,
        equipment, or server connected to or used to provide the Service;
      </div>

      <div className={styles.firstIndent}>
        (i) perform any fraudulent activity including impersonating any person
        or entity, claiming a false affiliation or identity, accessing any other
        Account without permission, or falsifying your age or date of birth;
      </div>

      <div className={styles.firstIndent}>
        (j) sell or otherwise transfer the access granted under these Terms or
        any Materials (as defined in Section 6) or any right or ability to view,
        access, or use any Materials; or
      </div>

      <div className={styles.firstIndent}>
        (k) attempt to do any of the acts described in this Section 10 or assist
        or permit any person in engaging in any of the acts described in this
        Section 10.
      </div>

      <div className={styles.spacer}></div>

      <div>
        <span className={styles.bold}>
          11. Intellectual Property Rights Protection{" "}
        </span>
      </div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>
          11.1 Respect of Third Party Rights.{" "}
        </span>
        Sportsbox respects the intellectual property rights of others, takes the
        protection of intellectual property rights very seriously, and asks
        users of the Service to do the same. Infringing activity will not be
        tolerated on or through the Service.
      </div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>11.2 DMCA Notification. </span>
        We comply with the provisions of the Digital Millennium Copyright Act
        applicable to Internet service providers (17 U.S.C. § 512, as amended).
        If you have an intellectual property rights-related complaint about any
        material on the Service, you may contact our Designated Agent at the
        following address:
      </div>

      <div>
        Sportsbox.ai Inc. Attn: Legal Department (IP Notification) 1229 120th
        Ave NE, Suite A Bellevue, WA 98005 Phone: (425)559-2428 Email:
        copyright@sportsbox.ai
      </div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>
          11.3 Procedure for Reporting Claimed Infringement.{" "}
        </span>
        If you believe that any content made available on or through the Service
        has been used or exploited in a manner that infringes an intellectual
        property right you own or control, then please promptly send a written
        “Notification of Claimed Infringement” to the Designated Agent
        identified above containing the following information:
      </div>

      <div className={styles.firstIndent}>
        (a) an electronic or physical signature of the person authorized to act
        on behalf of the owner of the copyright or other right being infringed;
      </div>

      <div className={styles.firstIndent}>
        (b) a description of the copyrighted work or other intellectual property
        right that you claim has been infringed;
      </div>

      <div className={styles.firstIndent}>
        (c) a description of the material that you claim is infringing and where
        it is located on the Service;
      </div>

      <div className={styles.firstIndent}>
        (d) your address, telephone number, and email address;
      </div>

      <div className={styles.firstIndent}>
        (e) a statement by you that you have a good faith belief that the use of
        the materials on the Service of which you are complaining is not
        authorized by the copyright or other intellectual property right owner,
        its agent, or the law; and
      </div>

      <div className={styles.firstIndent}>
        (f)a statement by you that the above information in your notice is
        accurate and that under penalty of perjury, you are the copyright or
        other intellectual property right owner or authorized to act on the
        copyright or intellectual property owner’s behalf.
      </div>

      <div className={styles.firstIndent}>
        Your Notification of Claimed Infringement may be shared by Sportsbox
        with the user alleged to have infringed a right you own or control, as
        well as with the operators of publicly available databases that track
        notifications of claimed infringement, and you consent to Sportsbox
        making such disclosures. You should consult with your own lawyer or see
        17 U.S.C. § 512 to confirm your obligations to provide a valid notice of
        claimed infringement.
      </div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>11.4 Repeat Infringers. </span>
        Sportsbox’s policy is to: (a) remove or disable access to material that
        Sportsbox believes in good faith, upon notice from an intellectual
        property rights owner or authorized agent, is infringing the
        intellectual property rights of a third party by being made available
        through the Service; and (b) in appropriate circumstances, to terminate
        the accounts of and block access to the Service by any user who
        repeatedly or egregiously infringes other people’s copyright or other
        intellectual property rights. Sportsbox will terminate the Accounts of
        users that are determined by Sportsbox to be repeat infringers.
        Sportsbox reserves the right, however, to suspend or terminate any
        Account in our sole discretion.
      </div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>11.5 Counter Notification. </span>
        If you receive a notification from Sportsbox that material made
        available by you on or through the Service has been the subject of a
        Notification of Claimed Infringement, then you will have the right to
        provide Sportsbox with what is called a “Counter Notification.” To be
        effective, a Counter Notification must be in writing, provided to
        Sportsbox’s Designated Agent through one of the methods identified in
        Section 11.2 and include substantially the following information:
      </div>

      <div className={styles.firstIndent}>
        (a) your physical or electronic signature;
      </div>

      <div className={styles.firstIndent}>
        (b) identification of the material that has been removed or to which
        access has been disabled, and the location at which the material
        appeared before it was removed or access to it was disabled;
      </div>

      <div className={styles.firstIndent}>
        (c) a statement under penalty of perjury that you have a good faith
        belief that the material was removed or disabled as a result of mistake
        or misidentification of the material to be removed or disabled; and
      </div>

      <div className={styles.firstIndent}>
        (d) your name, address, and telephone number, and a statement that you
        consent to the jurisdiction of Federal District Court for the judicial
        district in which the address is located, or if you are residing outside
        of the United States, then for any judicial district in which Sportsbox
        may be found, and that you will accept service of process from the
        person who provided notification under Section 11.2 above or an agent of
        that person.
      </div>
      <div className={styles.firstIndent}>
        A party submitting a Counter Notification should consult a lawyer or see
        17 U.S.C. § 512 to confirm the party’s obligations to provide a valid
        counter notification under the Copyright Act.
      </div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>
          11.6 Reposting of Content Subject to a Counter Notification.{" "}
        </span>
        If you submit a Counter Notification to Sportsbox in response to a
        Notification of Claimed Infringement, then Sportsbox will promptly
        provide the person who provided the Notification of Claimed Infringement
        with a copy of your Counter Notification and inform that person that
        Sportsbox will replace the removed User Content or cease disabling
        access to it in 10 business days, and Sportsbox will replace the removed
        User Content and cease disabling access to it not less than 10 nor more
        than 14 business days following receipt of the Counter Notification,
        unless Sportsbox’s Designated Agent receives notice from the party that
        submitted the Notification of Claimed Infringement that such person has
        filed an action seeking a court order to restrain the user from engaging
        in infringing activity relating to the material on Sportsbox’s system or
        network.
      </div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>
          11.7 False Notifications of Claimed Infringement or Counter
          Notifications.{" "}
        </span>
        The Copyright Act provides at 17 U.S.C. § 512(f) that: “[a]ny person who
        knowingly materially misrepresents under [Section 512 of the Copyright
        Act (17 U.S.C. § 512)] (1) that material or activity is infringing, or
        (2) that material or activity was removed or disabled by mistake or
        misidentification, will be liable for any damages, including costs and
        attorneys’ fees, incurred by the alleged infringer, by any copyright
        owner or copyright owner’s authorized licensee, or by a service
        provider, who is injured by such misrepresentation, as the result of
        [Sportsbox] relying upon such misrepresentation in removing or disabling
        access to the material or activity claimed to be infringing, or in
        replacing the removed material or ceasing to disable access to it.”
        Sportsbox reserves the right to seek damages from any party that submits
        a Notification of Claimed Infringement or Counter Notification in
        violation of the law.
      </div>

      <div>
        <span className={styles.bold}>12. Modification of Terms </span>We may,
        from time to time, change these Terms. Please check these Terms
        periodically for changes. Revisions will be effective immediately except
        that, for existing users, material revisions will be effective 30 days
        after posting or notice to you of the revisions unless otherwise stated.
        If a change to these Terms materially modifies your rights or
        obligations, we may require that you accept the modified Terms in order
        to continue to use the Service. If you do not agree to the modified
        Terms, then you should discontinue your use of the Service. Except as
        expressly permitted in this Section 12, these Terms may be amended only
        by a written agreement signed by authorized representatives of the
        parties to these Terms.
      </div>

      <div>
        <span className={styles.bold}>
          13. Term, Termination, and Modification of the Service{" "}
        </span>
      </div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>13.1 Term </span>
        These Terms are effective beginning when you accept the Terms or first
        download, install, access, or use the Service, and ending when
        terminated as described in Section 13.2.
      </div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>13.2 Termination </span>
        If you violate any provision of these Terms, then your authorization to
        access the Service and these Terms automatically terminate. In addition,
        Sportsbox may, at its sole discretion, terminate these Terms or suspend
        or terminate your Account or access to the Service, at any time for any
        reason or no reason, with or without notice, and without any liability
        to you arising from such termination. If we terminate your Account or
        these Terms for convenience, we will refund you any prepaid but unused
        fees. You may terminate your Account and these Terms at any time by
        contacting us at contact@sportsbox.ai.
      </div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>13.3 Effect of Termination </span>
        Upon termination of these Terms: (a) your license rights will terminate
        and you must immediately cease all use of the Service; (b) you will no
        longer be authorized to access your Account or the Service; (c) you must
        pay Sportsbox any unpaid amount that was due prior to termination; and
        (d) all payment obligations accrued prior to termination and Sections
        5.3, 6, 8, 13.3, 14, 15, 16, 17, and 18 will survive. If your Account
        has been terminated for a breach of these Terms, then you are prohibited
        from creating a new Account on the Service using a different name, email
        address or other forms of account verification. You are solely
        responsible for retaining copies of your User Content since upon
        termination of your Account, you may lose access rights to your User
        Content.
      </div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>13.4 Modification of the Service </span>
        Sportsbox reserves the right to modify or discontinue all or any portion
        of the Service at any time (including by limiting or discontinuing
        certain features of the Service), temporarily or permanently, without
        notice to you. Sportsbox will have no liability for any change to the
        Service, including any paid-for functionalities of the Service, or any
        suspension or termination of your access to or use of the Service. You
        should retain copies of your User Content so that you have permanent
        copies in the event the Service is modified in such a way that you lose
        access to your User Content.
      </div>

      <div>
        <span className={styles.bold}>14. Indemnity </span>
      </div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>14.1 General </span>
        To the fullest extent permitted by law, you are responsible for your use
        of the Service, and you will defend and indemnify Sportsbox, its
        affiliates and their respective shareholders, directors, managers,
        members, officers, employees, consultants, and agents (together, the
        “Sportsbox Entities”) from and against every claim brought by a third
        party, and any related liability, damage, loss, and expense, including
        attorneys’ fees and costs, arising out of or connected with: (a) your
        unauthorized use of, or misuse of, the Service; (b) your violation of
        any portion of these Terms, any representation, warranty, or agreement
        referenced in these Terms, or any applicable law or regulation; (c) your
        violation of any third-party right, including any intellectual property
        right or publicity, confidentiality, other property, or privacy right;
        or (d) any dispute or issue between you and any third party. We reserve
        the right, at our own expense, to assume the exclusive defense and
        control of any matter otherwise subject to indemnification by you
        (without limiting your indemnification obligations with respect to that
        matter), and in that case, you agree to cooperate with our defense of
        those claims.
      </div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>14.2 Coaches </span>
        In addition to the above, if you are a Coach, you will defend and
        indemnify Sportsbox Entities from and against any third-party claim and
        any related liability, damage, loss, and expense, including attorneys’
        fees and costs, arising out of or connected with: (a) any failure by
        your Players who are under the age of 18 to have their parent or legal
        guardian set up their Player Accounts; or (b) the unauthorized use of,
        or misuse of, the Service by your Player (including any use of the
        Service by your Player who are not eligible under Section 2).
      </div>

      <div>
        <span className={styles.bold}>
          15. Disclaimers; No Warranties by Sportsbox{" "}
        </span>
      </div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>15.1 </span>
        THE SERVICE AND ALL MATERIALS AND CONTENT AVAILABLE THROUGH THE SERVICE
        ARE PROVIDED “AS IS” AND ON AN “AS AVAILABLE” BASIS. SPORTSBOX DISCLAIMS
        ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, RELATING TO THE
        SERVICE AND ALL MATERIALS AND CONTENT AVAILABLE THROUGH THE SERVICE,
        INCLUDING: (a) ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A
        PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, OR NON-INFRINGEMENT; AND (b)
        ANY WARRANTY ARISING OUT OF COURSE OF DEALING, USAGE, OR TRADE.
        SPORTSBOX DOES NOT WARRANT THAT THE SERVICE OR ANY PORTION OF THE
        SERVICE, OR ANY MATERIALS OR CONTENT OFFERED THROUGH THE SERVICE, WILL
        BE UNINTERRUPTED, SECURE, OR FREE OF ERRORS, VIRUSES, OR OTHER HARMFUL
        COMPONENTS, AND SPORTSBOX DOES NOT WARRANT THAT ANY OF THOSE ISSUES WILL
        BE CORRECTED.
      </div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>15.2 </span>
        NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM
        THE SERVICE OR SPORTSBOX ENTITIES OR ANY MATERIALS OR CONTENT AVAILABLE
        THROUGH THE SERVICE WILL CREATE ANY WARRANTY REGARDING ANY OF THE
        SPORTSBOX ENTITIES OR THE SERVICE THAT IS NOT EXPRESSLY STATED IN THESE
        TERMS. WE ARE NOT RESPONSIBLE FOR ANY DAMAGE THAT MAY RESULT FROM THE
        SERVICE AND YOUR DEALING WITH ANY OTHER USER. YOU UNDERSTAND AND AGREE
        THAT YOU USE ANY PORTION OF THE SERVICE AT YOUR OWN DISCRETION AND RISK,
        AND THAT WE ARE NOT RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY
        (INCLUDING YOUR COMPUTER SYSTEM OR MOBILE DEVICE USED IN CONNECTION WITH
        THE SERVICE) OR ANY LOSS OF DATA, INCLUDING USER CONTENT.
      </div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>15.3 </span>
        THE LIMITATIONS, EXCLUSIONS AND DISCLAIMERS IN THIS SECTION 15 APPLY TO
        THE FULLEST EXTENT PERMITTED BY LAW. Sportsbox does not disclaim any
        warranty or other right that Sportsbox is prohibited from disclaiming
        under applicable law.
      </div>

      <div>
        <span className={styles.bold}>16. Limitation of Liability </span>
      </div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>16.1 </span>
        TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL THE SPORTSBOX
        ENTITIES BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
        CONSEQUENTIAL OR PUNITIVE DAMAGES (INCLUDING DAMAGES FOR LOSS OF
        PROFITS, GOODWILL, OR ANY OTHER INTANGIBLE LOSS) ARISING OUT OF OR
        RELATING TO YOUR ACCESS TO OR USE OF, OR YOUR INABILITY TO ACCESS OR
        USE, THE SERVICE OR ANY MATERIALS OR CONTENT ON THE SERVICE, WHETHER
        BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), STATUTE, OR
        ANY OTHER LEGAL THEORY, AND WHETHER OR NOT ANY Sportsbox ENTITY HAS BEEN
        INFORMED OF THE POSSIBILITY OF DAMAGE.
      </div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>16.2 </span>
        EXCEPT AS PROVIDED IN SECTIONS 17.5 AND 17.6 AND TO THE FULLEST EXTENT
        PERMITTED BY LAW, THE AGGREGATE LIABILITY OF THE SPORTSBOX ENTITIES TO
        YOU FOR ALL CLAIMS ARISING OUT OF OR RELATING TO THE USE OF OR ANY
        INABILITY TO USE ANY PORTION OF THE SERVICE OR OTHERWISE UNDER THESE
        TERMS, WHETHER IN CONTRACT, TORT, OR OTHERWISE, IS LIMITED TO THE
        GREATER OF: (a) THE AMOUNT YOU HAVE PAID TO SPORTSBOX FOR ACCESS TO AND
        USE OF THE SERVICE IN THE 12 MONTHS PRIOR TO THE EVENT OR CIRCUMSTANCE
        GIVING RISE TO THE CLAIM; AND (b) US$100.
      </div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>16.3 </span>
        EACH PROVISION OF THESE TERMS THAT PROVIDES FOR A LIMITATION OF
        LIABILITY, DISCLAIMER OF WARRANTIES, OR EXCLUSION OF DAMAGES IS INTENDED
        TO AND DOES ALLOCATE THE RISKS BETWEEN THE PARTIES UNDER THESE TERMS.
        THIS ALLOCATION IS AN ESSENTIAL ELEMENT OF THE BASIS OF THE BARGAIN
        BETWEEN THE PARTIES. EACH OF THESE PROVISIONS IS SEVERABLE AND
        INDEPENDENT OF ALL OTHER PROVISIONS OF THESE TERMS. THE LIMITATIONS IN
        THIS SECTION 16 WILL APPLY EVEN IF ANY LIMITED REMEDY FAILS OF ITS
        ESSENTIAL PURPOSE.
      </div>

      <div>
        <span className={styles.bold}>
          17. Dispute Resolution and Arbitration{" "}
        </span>
      </div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>17.1 Generally </span>
        In the interest of resolving disputes between you and Sportsbox in the
        most expedient and cost effective manner, and except as described in
        Section 17.2 and 17.3, you and Sportsbox agree that every dispute
        arising in connection with these Terms, the Service, and communications
        from us will be resolved by binding arbitration. Arbitration is less
        formal than a lawsuit in court. Arbitration uses a neutral arbitrator
        instead of a judge or jury, may allow for more limited discovery than in
        court, and can be subject to very limited review by courts. Arbitrators
        can award the same damages and relief that a court can award. This
        agreement to arbitrate disputes includes all claims arising out of or
        relating to any aspect of these Terms, the Service, or any
        communications to or from us, whether based in contract, tort, statute,
        fraud, misrepresentation, or any other legal theory, and regardless of
        whether a claim arises during or after the termination of these Terms.
        YOU UNDERSTAND AND AGREE THAT, BY ENTERING INTO THESE TERMS, YOU AND
        SPORTSBOX ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO
        PARTICIPATE IN A CLASS ACTION.
      </div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>17.2 Exceptions </span>
        Despite the provisions of Section 17.1, nothing in these Terms will be
        deemed to waive, preclude, or otherwise limit the right of either party
        to: (a) bring an individual action in small claims court; (b) pursue an
        enforcement action through the applicable federal, state, or local
        agency if that action is available; (c) seek injunctive relief in a
        court of law in aid of arbitration; or (d) to file suit in a court of
        law to address an intellectual property infringement claim.
      </div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>17.3 Opt-Out </span>
        If you do not wish to resolve disputes by binding arbitration, you may
        opt out of the provisions of this Section 17 within 30 days after the
        date that you agree to these Terms by sending a letter to Sportsbox.ai,
        Inc, Attention: Legal Department – Arbitration Opt-Out, 1229 120th Ave
        NE, Suite A, Bellevue, WA 98005, United States, that specifies: your
        full legal name, the email address associated with your account on the
        Service, and a statement that you wish to opt out of arbitration
        (“Opt-Out Notice”). Once Sportsbox receives your Opt-Out Notice, this
        Section 17 will be void and any action arising out of these Terms will
        be resolved as set forth in Section 18.2. The remaining provisions of
        these Terms will not be affected by your Opt-Out Notice.
      </div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>17.4 Arbitrator </span>
        Any arbitration between you and Sportsbox will be settled under the
        Federal Arbitration Act and administered by the American Arbitration
        Association (“AAA”) under its Consumer Arbitration Rules (collectively,
        “AAA Rules”) as modified by these Terms. The AAA Rules and filing forms
        are available online at www.adr.org, by calling the AAA at
        +1-800-778-7879, or by contacting Sportsbox. The arbitrator has
        exclusive authority to resolve any dispute relating to the
        interpretation, applicability, or enforceability of this binding
        arbitration agreement.
      </div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>
          17.4 Notice of Arbitration; Process{" "}
        </span>
        A party who intends to seek arbitration must first send a written notice
        of the dispute to the other party by certified U.S. Mail or by Federal
        Express (signature required) or, only if that other party has not
        provided a current physical address, then by electronic mail (“Notice of
        Arbitration”). Sportsbox’s address for Notice is: Sportsbox.ai Inc.,
        1229 120th Ave NE, Suite A, Bellevue, WA 98005, United States. The
        Notice of Arbitration must: (a) describe the nature and basis of the
        claim or dispute; and (b) set forth the specific relief sought
        (“Demand”). The parties will make good faith efforts to resolve the
        claim directly, but if the parties do not reach an agreement to do so
        within 30 days after the Notice of Arbitration is received, you or
        Sportsbox may commence an arbitration proceeding. All arbitration
        proceedings between the parties will be confidential unless otherwise
        agreed by the parties in writing. During the arbitration, the amount of
        any settlement offer made by you or Sportsbox must not be disclosed to
        the arbitrator until after the arbitrator makes a final decision and
        award, if any. If the arbitrator awards you an amount higher than the
        last written settlement amount offered by Sportsbox in settlement of the
        dispute prior to the award, Sportsbox will pay to you the higher of: (i)
        the amount awarded by the arbitrator and (ii) US$10,000.
      </div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>17.5 Fees </span>
        If you commence arbitration in accordance with these Terms, Sportsbox
        will reimburse you for your payment of the filing fee, unless your claim
        is for more than US$10,000, in which case the payment of any fees will
        be decided by the AAA Rules. Any arbitration hearing will take place at
        a location to be agreed upon in the county and state of your residence,
        but if the claim is for US$10,000 or less, you may choose whether the
        arbitration will be conducted: (a) solely on the basis of documents
        submitted to the arbitrator; (b) through a non-appearance based
        telephone hearing; or (c) by an in-person hearing as established by the
        AAA Rules in the county (or parish) of your residence. If the arbitrator
        finds that either the substance of your claim or the relief sought in
        the Demand is frivolous or brought for an improper purpose (as measured
        by the standards set forth in Federal Rule of Civil Procedure 11(b)),
        then the payment of all fees will be governed by the AAA Rules. In that
        case, you agree to reimburse Sportsbox for all monies previously
        disbursed by it that are otherwise your obligation to pay under the AAA
        Rules. Regardless of the manner in which the arbitration is conducted,
        the arbitrator must issue a reasoned written decision sufficient to
        explain the essential findings and conclusions on which the decision and
        award, if any, are based. The arbitrator may make rulings and resolve
        disputes as to the payment and reimbursement of fees or expenses at any
        time during the proceeding and upon request from either party made
        within 14 days of the arbitrator’s ruling on the merits.
      </div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>17.6 No Class Actions </span>
        YOU AND SPORTSBOX AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER
        ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS
        MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. Further,
        unless both you and Sportsbox agree otherwise, the arbitrator may not
        consolidate more than one person’s claims, and may not otherwise preside
        over any form of a representative or class proceeding.
      </div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>
          17.6 Modifications to this Arbitration Provision{" "}
        </span>
        If Sportsbox makes any future change to this arbitration provision,
        other than a change to Sportsbox’s address for Notice of Arbitration,
        you may reject the change by sending us written notice within 30 days of
        the change to Sportsbox’s address for Notice of Arbitration, in which
        case your account with Sportsbox will be immediately terminated and this
        arbitration provision, as in effect immediately prior to the changes you
        rejected will survive.
      </div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>17.6 Enforceability </span>
        If Section 17.7 or the entirety of this Section 17 is found to be
        unenforceable, or if Sportsbox receives an Opt-Out Notice from you, then
        the entirety of this Section 17 will be null and void and, in that case,
        the exclusive jurisdiction and venue described in Section 18.2 will
        govern any action arising out of or related to these Terms.
      </div>

      <div>
        <span className={styles.bold}>18. Miscellaneous </span>
      </div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>18.1 General Terms. </span>
        These Terms, including the Privacy Policy and any other agreements
        expressly incorporated by reference into these Terms, are the entire and
        exclusive understanding and agreement between you and Sportsbox
        regarding your use of the Service. You may not assign or transfer these
        Terms or your rights under these Terms, in whole or in part, by
        operation of law or otherwise, without our prior written consent. We may
        assign these Terms and all rights granted under these Terms, including
        with respect to your User Content, at any time without notice or
        consent. The failure to require performance of any provision will not
        affect our right to require performance at any other time after that,
        nor will a waiver by us of any breach or default of these Terms, or any
        provision of these Terms, be a waiver of any subsequent breach or
        default or a waiver of the provision itself. Use of Section headers in
        these Terms is for convenience only and will not have any impact on the
        interpretation of any provision. Throughout these Terms the use of the
        word “including” means “including but not limited to.” If any part of
        these Terms is held to be invalid or unenforceable, then the
        unenforceable part will be given effect to the greatest extent possible,
        and the remaining parts will remain in full force and effect.
      </div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>18.2 Governing Law. </span>
        These Terms are governed by the laws of the State of Washington without
        regard to conflict of law principles. You and Sportsbox submit to the
        personal and exclusive jurisdiction of the state courts and federal
        courts located within King County, Washington for resolution of any
        lawsuit or court proceeding permitted under these Terms. We operate the
        Service from our offices in Washington, and we make no representation
        that Materials included in the Service are appropriate or available for
        use in other locations.
      </div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>18.3 Privacy Policy. </span>
        Please read the Sportsbox Privacy Policy
        [https://3dbaseball.sportsbox.ai/privacy] (the “Privacy Policy”)
        carefully for information relating to our collection, use, storage, and
        disclosure of your personal information. The Sportsbox Privacy Policy is
        incorporated by this reference into, and made a part of, these Terms.
      </div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>
          18.4 Consent to Electronic Communications.{" "}
        </span>
        By using the Service, you consent to receiving certain electronic
        communications from us as further described in our Privacy Policy.
        Please read our Privacy Policy to learn more about our electronic
        communications practices. You agree that any notices, agreements,
        disclosures, or other communications that we send to you electronically
        will satisfy any legal communication requirements, including that those
        communications be in writing.
      </div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>18.5 Contact Information. </span>
        The Service is offered by Sportsbox.ai Inc., located at 1229 120th Ave
        NE, Suite A, Bellevue, WA 98005, United States. You may contact us by
        sending correspondence to that address or by emailing us at
        contact@sportsbox.ai.
      </div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>
          18.5 Notice to California Residents.{" "}
        </span>
        If you are a California resident, then under California Civil Code
        Section 1789.3, you may contact the Complaint Assistance Unit of the
        Division of Consumer Services of the California Department of Consumer
        Affairs in writing at 1625 N. Market Blvd., Suite S-202, Sacramento,
        California 95834, or by telephone at +1-800-952-5210 in order to resolve
        a complaint regarding the Service or to receive further information
        regarding use of the Service.
      </div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>18.5 No Support. </span>
        We are under no obligation to provide support for the Service. In
        instances where we may offer support, the support will be subject to
        published policies.
      </div>

      <div className={styles.firstIndent}>
        <span className={styles.bold}>18.5 International Use. </span>
        The Service is intended for visitors located within the United States.
        We make no representation that the Service is appropriate or available
        for use outside of the United States. Access to the Service from
        countries or territories or by individuals where such access is illegal
        is prohibited.
      </div>

      <div>
        <span className={styles.bold}>19. Notice Regarding Apple </span> This
        Section 19 only applies to the extent you are using our mobile
        application on an iOS device. You acknowledge that these Terms are
        between you and Sportsbox only, not with Apple Inc. (“Apple”), and Apple
        is not responsible for the Service or the content of it. Apple has no
        obligation to furnish any maintenance and support services with respect
        to the Service. If the Service fails to conform to any applicable
        warranty, you may notify Apple, and Apple will refund any applicable
        purchase price for the mobile application to you. To the maximum extent
        permitted by applicable law, Apple has no other warranty obligation with
        respect to the Service. Apple is not responsible for addressing any
        claims by you or any third party relating to the Service or your
        possession and/or use of the Service, including: (1) product liability
        claims; (2) any claim that the Service fails to conform to any
        applicable legal or regulatory requirement; or (3) claims arising under
        consumer protection or similar legislation. Apple is not responsible for
        the investigation, defense, settlement, and discharge of any third-party
        claim that the Service and/or your possession and use of the Service
        infringe a third party’s intellectual property rights. You agree to
        comply with any applicable third-party terms when using the Service.
        Apple and Apple’s subsidiaries are third-party beneficiaries of these
        Terms, and upon your acceptance of these Terms, Apple will have the
        right (and will be deemed to have accepted the right) to enforce these
        Terms against you as a third-party beneficiary of these Terms. You
        hereby represent and warrant that: (a) you are not located in a country
        that is subject to a U.S. Government embargo or that has been designated
        by the U.S. Government as a “terrorist supporting” country; and (b) you
        are not listed on any U.S. Government list of prohibited or restricted
        parties.
      </div>
    </div>
  </>
);

export default TermsOfService;
