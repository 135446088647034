import React, { Component } from 'react';
import {Link as RouterLink} from 'react-router-dom';
import { createMuiTheme, MuiThemeProvider, Typography, MenuItem, Menu, Fade } from '@material-ui/core';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import * as admin from 'firebase/app';
import 'firebase/firestore';
import { forwardRef } from 'react';
import DisplayLabJobItems from './DisplayLabJobItems';
import {AddBox, ArrowDownward, Check, ChevronLeft, ChevronRight, Clear, DeleteOutline, Edit, FilterList, FirstPage, LastPage, Remove, SaveAlt, Search, ViewColumn} from '@material-ui/icons';
import palette from '../../../../theme/palette';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const tableHeads = {labelingJobs: [
  {field: 'jobID', title: 'Job ID'},
  {field: 'name', title: 'Name'},
  {field: 'requestID', title: 'Request ID'},
  {field: 'labelerID', title: 'Labeler ID'},
  {field: 'createdDate', title: 'Created Date'},
  {field: 'desiredDate', title: 'Desired Date'},
  {field: 'status', title: 'Status'},
]}

const theme = createMuiTheme({
  typography: {
    h6: {
      fontWeight: 500,
      fontSize: '20px',
      letterSpacing: '-0.05px',
      lineHeight: '20px',
    }
  },
  palette: {
    secondary: {
      main: palette.primary.main,
    },
  },
})

class DisplayLabJobTable extends Component {
  constructor (props) {
    super(props);
    this.state = {
      popUpData: {
        open: false
      },
      selectedRows: {
        labelingJobs: []
      },
      editMenu: {
        anchor: null,
        type: ''
      },
      deleteMenu: {
        anchor: null,
        type: ''
      }
    }
  }

  getTableHeader = (name) => {
    return tableHeads[name];
  }

  openPopUp = (type, rowData) => {
    this.setState({popUpData: {open: true, type: type, rowData: rowData}})
  }

  closePopUp = () => {
    this.setState({popUpData: {open: false}})
  }

  handleEdit = (type) => (event, data) => {
    this.setState({editMenu: {anchor: event.currentTarget, type: type}, selectedRows: {...this.state.selectedRows, [type]: data}})
  }

  handleDelete = (type) => (event, data) => {
    this.setState({deleteMenu: {anchor: event.currentTarget, type: type}, selectedRows: {...this.state.selectedRows, [type]: data}})
  }

  editLabJob = () => {
    const ids = this.state.selectedRows.labelingJobs.map((item) => item.id)
    localStorage.setItem('editLabJob', ids[0])
  }

  deleteLabJob = () => {
    const ids = this.state.selectedRows.labelingJobs.map((item) => item.id)
    let count = 0;
    for(let i = 0; i < ids.length; ++i) {
      admin.firestore().collection('labelingJobs').doc(ids[i]).delete();
      ++count;
      if(count === ids.length) {
        alert(ids.length + " labeling job(s) were deleted, page will refresh now");
        setTimeout(function() {
          window.location.reload()
        }, 1000);
      }
    }
  }

  render(){
    let labJobData = [];
    if (this.props.labelingJobs) {
      const keys = Object.keys(this.props.labelingJobs)
      labJobData = keys.map((key) => {
        if(this.props.labelingJobs[key]) {
          const labelingJob = this.props.labelingJobs[key];
          const out = {
            id: labelingJob.id,
            jobID: labelingJob.jobID,
            name: labelingJob.name,
            requestID: labelingJob.requestID,
            labelerID: labelingJob.labelerID,
            createdDate: labelingJob.dateCreated,
            desiredDate: labelingJob.desiredDate,
            status: (labelingJob.status.charAt(0).toUpperCase() + labelingJob.status.substring(1, labelingJob.status.length)).replace(/([A-Z])/g, ' $1').trim(),
            completionDate: labelingJob.completionDate,
            descriptionOfFrames: labelingJob.descriptionOfFrames,
            numberOfFrames: labelingJob.numberOfFrames,
            descriptionOfPoints: labelingJob.descriptionOfPoints,
            numberOfPoints: labelingJob.numberOfPoints,
          }
          return out;
        }
        return null;
      })
    }
    const tableElements = [];
    if(labJobData.length > 0) {
      tableElements.push(
        <MaterialTable
          style={{marginBottom: '10px'}}
          icons={tableIcons}
          key='labJobTable'
          title='Labeling Jobs'
          columns={this.getTableHeader('labelingJobs')}
          data={labJobData.map(row => this.state.selectedRows.labelingJobs.find(selected => selected.id === row.id) ? {...row, tableData: { checked: true}} : row)}
          options={{
            selection: true
          }}
          actions={[{
            tooltip: 'Edit selected labeling job',
            icon: tableIcons.Edit,
            onClick: this.handleEdit('labelingJobs')
          }, {
            tooltip: 'Delete selected labeling job(s)',
            icon: tableIcons.Delete,
            onClick: this.handleDelete('labelingJobs')
          }]}
          onRowClick={(event, rowData) => this.openPopUp('labelingJobs', rowData)}
        />)
    }
    if (tableElements.length < 1) {
      return (<Typography align='center' style={{marginTop: '20px'}}>There are no items to display</Typography>)
    }
    return (
    <MuiThemeProvider theme={theme}>
      {tableElements}
      <DisplayLabJobItems
        open={this.state.popUpData.open}
        type={this.state.popUpData.type}
        rowData={this.state.popUpData.rowData}
        onClose={this.closePopUp}
      />
      <Menu
        id="menu1"
        anchorEl={this.state.editMenu.anchor}
        keepMounted
        open={Boolean(this.state.editMenu.anchor)}
        onClose={() => {this.setState({editMenu: {anchor: null, type: ''}})}}
        TransitionComponent={Fade}
      >
        <MenuItem component={RouterLink} to="/edit-labelingJob" onClick={this.editLabJob}>Edit</MenuItem>
      </Menu>
      <Menu
        id="menu2"
        anchorEl={this.state.deleteMenu.anchor}
        keepMounted
        open={Boolean(this.state.deleteMenu.anchor)}
        onClose={() => {this.setState({deleteMenu: {anchor: null, type: ''}})}}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={this.deleteLabJob}>Delete</MenuItem>
      </Menu>
    </MuiThemeProvider>)
  }
}
DisplayLabJobTable.propTypes = {
  labelingJobs: PropTypes.object,
  searchOrder: PropTypes.string,
  compress: PropTypes.bool
}
export default DisplayLabJobTable