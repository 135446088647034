import React, { forwardRef, useState, useEffect } from 'react';

import {
  Box, Card, CardContent, CircularProgress, Container, Grid, Typography,
  IconButton, Select, MenuItem, FormControl
} from '@material-ui/core';
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
  Receipt,
  ArrowBack,
} from "@material-ui/icons";
import { Link as RouterLink } from 'react-router-dom';
import Swal from 'sweetalert2';
import 'jspdf-autotable';
import firebase, { functionBaseUrl, axiosWithToken } from '../../common/firebase';
import { StripePayment } from '../../components/stripePayment';

const tableIcons = {
  ExportCSV: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  ExportPDF: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => (
    <ArrowDownward {...props} ref={ref} />
  )),
  ThirdStateCheck: forwardRef((props, ref) => (
    <Remove {...props} ref={ref} />
  )),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  Receipt: forwardRef((props, ref) => <Receipt {...props} ref={ref} />),
};

function CreditsComponent({ history }) {
  const [id, setId] = useState('');
  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [amount, setAmount] = useState(0);
  const [credits, setCredits] = useState(0);
  const [custom, setCustom] = useState(false);
  const [unpaidCredit, setUnpaidCredit] = useState({});

  const loadData = async () => {
    try {
      setIsLoading(true);
      const firebaseUser = firebase.auth().currentUser;
      setId(firebaseUser.uid);
      let userResponse = await axiosWithToken(`${functionBaseUrl}/api/users/${firebaseUser.uid}`, {
        method: 'GET',
      });
      setUser(userResponse?.data);

      let response = await axiosWithToken(`${functionBaseUrl}/api/v1/unpaidCredits/coach/${firebaseUser.uid}`, {
        method: 'GET',
      });
      if (response?.data?.credits?.length > 0) {
        setAmount(response?.data?.credits[0]?.amount);
        setCredits(response?.data?.credits[0]?.creditCount);
        setUnpaidCredit(response?.data?.credits[0]);
        setCustom(true);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      Swal.fire({
        title: '<p style="font-size:70%;">Error loading data</p>',
        icon: 'error',
        confirmButtonText: 'Ok',
        allowOutsideClick: true,
      });
    }
  }

  useEffect(() => {
    loadData();
  }, [])

  async function handleResult() {
    if (custom) {
      await axiosWithToken(`${functionBaseUrl}/api/v1/credits`, {
        method: 'PATCH',
        data: { creditId: unpaidCredit?.id, info: { status: 'complete' } }
      })
        .then(async () => {
          await Swal.fire({
            title: `<p style="font-size:70%;">Thank you for your payment!</p>`,
            showConfirmButton: false,
            icon: 'success',
            customClass: {
              container: 'my-swal'
            },
            timer: 3000,
            allowOutsideClick: true
          }).then(async () => {
            await history.push('/credits/history');
          })
        })
        .catch(async (error) => {
          await Swal.fire({
            title: '<p style="font-size:70%;">Error adding credits</p>',
            icon: 'error',
            customClass: {
              container: 'my-swal'
            },
            confirmButtonText: 'Ok',
            allowOutsideClick: true,
          });
        });
    } else {
      let input = {
        amount: amount,
        creditCount: credits,
        status: 'complete',
        userId: id,
        coachId: id,
        transactionType: 'in'
      }

      await axiosWithToken(`${functionBaseUrl}/api/v1/credits`, {
        method: 'POST',
        data: input
      })
        .then(async () => {
          await Swal.fire({
            title: `<p style="font-size:70%;">Thank you for your payment!</p>`,
            showConfirmButton: false,
            icon: 'success',
            customClass: {
              container: 'my-swal'
            },
            timer: 3000,
            allowOutsideClick: true
          }).then(async () => {
            await history.push('/credits/history');
          })
        })
        .catch(async (error) => {
          await Swal.fire({
            title: '<p style="font-size:70%;">Error adding credits</p>',
            icon: 'error',
            customClass: {
              container: 'my-swal'
            },
            confirmButtonText: 'Ok',
            allowOutsideClick: true,
          });
        });
    }
  }

  function handlePackageChange(amount) {
    setAmount(0);
    if (amount === 249) {
      setCredits(30);
    } else if (amount === 499) {
      setCredits(80);
    } else if (amount === 749) {
      setCredits(120);
    } else {
      setCredits(0);
    }
    setAmount(amount);
  }

  return (<>
    {isLoading && (
      <Container maxWidth="xs" style={{ textAlign: 'center', paddingTop: '50px' }}>
        <CircularProgress />
      </Container>
    )}
    {!isLoading && (
      <Container
        component="main"
        style={{ paddingTop: '50px' }}
      >
        <Grid container spacing={2}>
        <Grid
            item
            xs={12}
            style={{ paddingBottom: 20 }}
          >
            <RouterLink to="/credits/history">
              <IconButton
                edge="start"
              >
                <ArrowBack />&nbsp;Credits History
              </IconButton>
            </RouterLink>
          </Grid>

          <Grid item xs={12}>
            <h2 style={{ textAlign: 'center' }}>Purchase Credits</h2>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <Grid container justify="center">
              <Grid
                item
                sm={6}
                xs={12}
              >
                <Box border={1} borderColor="primary.main" style={{ marginBottom: "5%" }}>
                  <Card style={{ minWidth: "50%" }}>
                    <CardContent>
                      <Grid item xs={12}>
                        <Typography style={{ textAlign: 'center' }}>
                          <div>
                            <span style={{ fontSize: '20px' }}>Total Credits: <b>{user?.creditCount} ({user?.creditCount > 1 ? "months" :  "month"})</b></span><br /><br />
                          </div>
                        </Typography>
                      </Grid>
                      {!custom && (<Grid container justify="space-between">
                        <FormControl variant="outlined" fullWidth>
                          <Select value={amount} onChange={(e) => handlePackageChange(e.target.value)} style={{ width: '100%' }}>
                            <MenuItem disabled value={0}>Select a Package</MenuItem>
                            <MenuItem value={249}>Standard (30 months of 3DP $500 value) - $249</MenuItem>
                            <MenuItem value={499}>Premium (80 months of 3DP $1280 value) - $499</MenuItem>
                            <MenuItem value={749}>Gold (120 months of 3DP $1920 value) - $749</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>)}
                    </CardContent>
                  </Card>
                  <Card>
                    <CardContent fullWidth>
                      <Typography style={{ textAlign: 'center', marginTop: 20, marginBottom: 20 }}>
                        <div>
                          <span style={{ fontSize: '28px' }}>Credits: <b>{credits} ({credits > 1 ? "months" :  "month"})</b></span><br /><br />
                          <span style={{ fontSize: '20px' }}>Amount: <b>${amount}</b></span><br />
                        </div>
                      </Typography>
                      {amount > 0 && (
                        <StripePayment amount={amount} handleResult={handleResult} useConfirmation={true} />
                      )}
                    </CardContent>
                  </Card>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    )}
  </>);
}

export const Credits = CreditsComponent;
