import React, { Component } from 'react';
import { Grid, TextField, Button, Dialog, DialogContent, DialogTitle, DialogActions, Typography, 
         FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from '@material-ui/lab';

const requiredFields = ['fullName', 'age', 'height']

class UserMeta extends Component {
  constructor(props){
    super(props);
    if (this.props.autofill) {
      this.state = this.props.autofill
    } else {
      this.state = this.getInitialState();
    }
  }
  getInitialState = () => {
    return {
      fullName: '',
      gender: '',
      age: '',
      height: '',
      golfClub: '',
      handicap: '',
      hipSize: '',
      notes: '',
      prefHand: '',
      clothColor: '',
      sleeves: '',
      pants: '',
      hat: '',
      clothFit: '',
      contrast: '',
      errors: {}
    }
  }
  handleClear = () => {
    const confirmVal = window.confirm('Do you want to clear your query?')
    if (confirmVal) {
      this.setState(this.getInitialState())
    }
  }
  handleChange = (prop) => (event) => {
    this.setState({[prop]: event.target.value})
  }
  handleSubmit = () => {
    let errors = {}
    requiredFields.forEach((field) => {
      if (this.state[field] === '') {
        errors[field] = field.charAt(0).toUpperCase() + field.slice(1) + ' is required'
      }
    })
    if (Object.keys(errors).length === 0) {
      const output = this.state
      output.errors = null
      this.props.handleSubmit(this.state)
    }
    this.setState({errors: errors})
  }
  render(){
    return (
      <Dialog
        fullWidth
        open={this.props.open}
        onClose={this.props.closePopUp}
        aria-labelledby='form-dialog-title'>
        <DialogTitle id={'form-dialog-title'}>
          <div>
            <Typography display={'inline'} variant="h5" color='primary'>Player Information</Typography>
            <CloseIcon display={'inline'} style={{float: 'right', marginTop: '-10px', marginRight: '-15px'}} onClick={this.props.closePopUp}/>
          </div>
        </DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item sm={6}>
                <TextField
                  fullWidth
                  label='Full Name*'
                  name='fullName'
                  margin='dense'
                  onChange={this.handleChange('fullName')}
                  value={this.state.fullname}
                  variant='outlined'/>
                {this.state.errors.fullName ? <Alert severity="warning">{this.state.errors.fullName}</Alert> : null}
              </Grid>
              <Grid item sm={6}>
                <FormControl              
                  variant="outlined"
                  fullWidth
                  margin="dense"
                >
                  <InputLabel id="gender-select">Gender</InputLabel>
                  <Select
                    id="gender"
                    label="Gender"
                    labelId="gender-select"
                    name="gender"
                    value={this.state.gender}
                    onChange={this.handleChange('gender')}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={'male'}>Male</MenuItem>
                    <MenuItem value={'female'}>Female</MenuItem>
                    <MenuItem value={'other'}>Other</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6}>
                <TextField
                  fullWidth
                  label='Age*'
                  name='age'
                  margin='dense'
                  type='number'
                  onChange={this.handleChange('age')}
                  value={this.state.age}
                  variant='outlined'/>
                {this.state.errors.age ? <Alert severity="warning">{this.state.errors.age}</Alert> : null}
              </Grid>
              <Grid item sm={6}>
                <TextField
                  fullWidth
                  label={"Height (e.g. 5'5\")*"}
                  name='height'
                  margin='dense'
                  onChange={this.handleChange('height')}
                  value={this.state.height}
                  variant='outlined'/>
                {this.state.errors.height ? <Alert severity="warning">{this.state.errors.height}</Alert> : null}
              </Grid>
              <Grid item sm={6}>
                <FormControl              
                  variant="outlined"
                  fullWidth
                  margin="dense"
                >
                  <InputLabel id="golfClub-select">Golf Club Used</InputLabel>
                  <Select
                    id="golfClub"
                    label="Golf Club Used"
                    labelId="golfClub-select"
                    name="golfClub"
                    value={this.state.golfClub}
                    onChange={this.handleChange('golfClub')}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={'driver'}>Driver</MenuItem>
                    <MenuItem value={'wood'}>Wood</MenuItem>
                    <MenuItem value={'hybrid'}>Hybrid</MenuItem>
                    <MenuItem value={'iron'}>Iron</MenuItem>
                    <MenuItem value={'wedge'}>Wedge</MenuItem>
                    <MenuItem value={'putter'}>Putter</MenuItem>
                    <MenuItem value={'other'}>Other</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6}>
                <FormControl              
                  variant="outlined"
                  fullWidth
                  margin="dense"
                >
                  <InputLabel id="hand-select">Preferred Hand</InputLabel>
                  <Select
                    id="prefHand"
                    label="PreferredHand"
                    labelId="hand-select"
                    name="prefHand"
                    value={this.state.prefHand}
                    onChange={this.handleChange('prefHand')}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={'right'}>Right</MenuItem>
                    <MenuItem value={'left'}>Left</MenuItem>
                    <MenuItem value={'both'}>Both</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6}>
                <TextField
                  fullWidth
                  label='Handicap'
                  name='handicap'
                  margin='dense'
                  type='number'
                  onChange={this.handleChange('handicap')}
                  value={this.state.handicap}
                  variant='outlined'/>
              </Grid>
              <Grid item sm={6}>
                <TextField
                  fullWidth
                  label='Hip Width (Inches)'
                  name='hipSize'
                  margin='dense'
                  onChange={this.handleChange('hipSize')}
                  value={this.state.hipSize}
                  variant='outlined'/>
              </Grid>
              <Grid item sm={6}>
                <TextField
                  fullWidth
                  multiline
                  label='Notes/Comments'
                  name='notes'
                  margin='dense'
                  onChange={this.handleChange('notes')}
                  value={this.state.notes}
                  variant='outlined'/>
              </Grid>
              <Grid
                item
                sm={12}>
                <Typography
                  variant="h5"
                  color='secondary'
                  style={{marginTop: '8px'}}>
                  Player Clothing
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <FormControl              
                  variant="outlined"
                  fullWidth
                  margin="dense"
                >
                  <InputLabel id="cloth-select">Clothes Color</InputLabel>
                  <Select
                    id="clothColor"
                    label="ClothColor"
                    labelId="cloth-select"
                    name="clothColor"
                    value={this.state.clothColor}
                    onChange={this.handleChange('clothColor')}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={'light'}>Light</MenuItem>
                    <MenuItem value={'dark'}>Dark</MenuItem>
                    <MenuItem value={'mixed'}>Mixed</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6}>
                <FormControl              
                  variant="outlined"
                  fullWidth
                  margin="dense"
                >
                  <InputLabel id="sleeve-select">Sleeves</InputLabel>
                  <Select
                    id="sleeves"
                    label="Sleeves"
                    labelId="sleeve-select"
                    name="sleeves"
                    value={this.state.sleeves}
                    onChange={this.handleChange('sleeves')}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={'long'}>Long</MenuItem>
                    <MenuItem value={'short'}>Short</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6}>
                <FormControl              
                  variant="outlined"
                  fullWidth
                  margin="dense"
                >
                  <InputLabel id="pant-select">Pants</InputLabel>
                  <Select
                    id="pants"
                    label="Pants"
                    labelId="pant-select"
                    name="pants"
                    value={this.state.pants}
                    onChange={this.handleChange('pants')}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={'long'}>Long</MenuItem>
                    <MenuItem value={'short'}>Short</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6}>
                <FormControl              
                  variant="outlined"
                  fullWidth
                  margin="dense"
                >
                  <InputLabel id="fit-select">Cloth Fit</InputLabel>
                  <Select
                    id="clothFit"
                    label="Fit"
                    labelId="fit-select"
                    name="clothFit"
                    value={this.state.clothFit}
                    onChange={this.handleChange('clothFit')}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={'loose'}>Loose</MenuItem>
                    <MenuItem value={'tight'}>Tight</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6}>
                <FormControl              
                  variant="outlined"
                  fullWidth
                  margin="dense"
                >
                  <InputLabel id="hat-select">Hat</InputLabel>
                  <Select
                    id="hat"
                    label="Hat"
                    labelId="hat-select"
                    name="hat"
                    value={this.state.hat}
                    onChange={this.handleChange('hat')}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={'yes'}>Yes</MenuItem>
                    <MenuItem value={'no'}>No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6}>
                <FormControl              
                  variant="outlined"
                  fullWidth
                  margin="dense"
                >
                  <InputLabel id="contrast-select">Contrast</InputLabel>
                  <Select
                    id="contrast"
                    label="Contrast"
                    labelId="contrast-select"
                    name="contrast"
                    value={this.state.contrast}
                    onChange={this.handleChange('contrast')}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={'high'}>High</MenuItem>
                    <MenuItem value={'medium'}>Medium</MenuItem>
                    <MenuItem value={'low'}>Low</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClear} color="primary">
              Clear
            </Button>
            <Button onClick={this.handleSubmit} color="secondary">
              Submit
            </Button>
          </DialogActions>
      </Dialog>
    )
  }
}
UserMeta.propTypes = {
  open: PropTypes.bool,
  closePopUp: PropTypes.func,
  submitQuery: PropTypes.func,
  values: PropTypes.object
}
export default UserMeta
