import axios from 'axios';

import { functionBaseUrl } from './firebase';


export const checkUserAcc = async (email) => {
    try {
        return await axios(functionBaseUrl + '/api/userInAuth', {
            method: 'POST',
            data: {
                email: email.toLowerCase().replace(/ /g, '')
            }
        })
            .then(res => {
                if (res.status === 200) {
                    return false;
                } else {
                    return true;
                }
            })
            .catch(err => {
                console.log(err)
            });
    } catch (err) {
        console.log(err)
    }
}

export const validatePassword = (password) => {
    const verify = /^(?=.*\d)(?=.*[.!@#$%^&*])(?=.*[a-z]).{8,}$/;
    return verify.test(password);
}

export const validateEmail = (email) => {
    const verify = /\S+@\S+\.\S+/;
    return verify.test(email);
}

export const dobFormat = (dob) => {
    let [year, month, day] = dob.split('-');
    return (month + "/" + day + "/" + year)
}
