import axios from "axios";
import Swal from "sweetalert2";
import * as admin from "firebase/app";
import "firebase/firestore";

export async function downloadFile(name, blob) {
    const fileDownloadUrl = URL.createObjectURL(blob)
    this.dofileDownload.click()
    URL.revokeObjectURL(fileDownloadUrl)
}

var saveData = (function () {
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    return function (data, fileName) {
        var url = window.URL.createObjectURL(data);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    };
}());

export async function analyzeVideo(data, user) {
    const time = Date.now();
    let timerInterval
    let host = "https://gpu.sportsbox.ai:30443";
    if (window.location.href.includes("localhost")) {
        host = "http://gpu.sportsbox.ai:30080";
    }

    Swal.fire({
        title: "Analyzing...",
        html: '<div><b></b></div>',
        didOpen: () => {
            Swal.showLoading()
            const b = Swal.getHtmlContainer().querySelector('b')
            timerInterval = setInterval(async () => {
                await axios(`${host}/web_inference/${time}`, {
                method: "GET",
                }).then(res => {
                    let text = res.data.stage
                    const percentage = parseInt((parseFloat(res.data.current_num_frame)/parseFloat(res.data.total_num_frame))*100)
                    if (!isNaN(percentage)) {
                        text += `: ${percentage}%`
                    }
                    b.textContent = text
                })
            }, 200)
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
    });
    await axios(`${host}/web_inference/`, {
        method: "POST",
        data: {
            videoUrl: data.videoPath,
            hipDistance: data.userData
                ? data.userData.hipMeasurement
                    ? parseInt(data.userData.hipMeasurement) <
                        20
                        ? parseInt(
                                data.userData.hipMeasurement *
                                    25.4
                            )
                        : parseInt(data.userData.hipMeasurement)
                    : 191
                : 191,
            fps: data.metaData
                ? data.metaData.fps
                    ? parseInt(data.metaData.fps)
                    : 120
                : 120,
            intrinsic: [
                [1371.8, 0, 539.4],
                [0, 1366.5, 973.2],
                [0, 0, 1],
            ],
            time: time
        },
    }).then(async (response) => {
        clearInterval(timerInterval)
        let totalFrames =
            response.data.json2d.frames.length;
        let totalScore = 0;
        for (const frame of response.data.json2d.frames) {
            totalScore += frame.score;
        }
        let averageScore = (
            (totalScore / totalFrames) *
            100
        ).toFixed(2);
        console.log("Average Score: " + averageScore);
        if (averageScore > 80) {
            // convert your object into a JSON-string
            response.data.jsonai.response =
                response.data.json3d.frames;
            var jsonString2d = JSON.stringify(
                response.data.json2d
            );
            var jsonString3d = JSON.stringify(
                response.data.json3d
            );
            var jsonStringAI = JSON.stringify(
                response.data.jsonai
            );
            // create a Blob from the JSON-string
            var blob2d = new Blob([jsonString2d], {
                type: "application/json",
            });
            var blob3d = new Blob([jsonString3d], {
                type: "application/json",
            });
            var blobAI = new Blob([jsonStringAI], {
                type: "application/json",
            });
            // create a reference to the storage
            var storageRef = admin.storage().ref();
            // Create a reference to the file you are about to create
            var folder = ""
            if (user === 'qa') {
                folder = "/accuracy_analysis/"
            } else {
                folder = "/swing_data/"
            }
            var fileRef2d = storageRef.child(
                folder +
                    data._id +
                    "/" +
                    data.videoOrigName.replace(
                        /[^a-zA-Z0-9 ]/g,
                        ""
                    ) +
                    "_" +
                    data._id +
                    "_2d.json"
            );
            var fileRef3d = storageRef.child(
                folder +
                    data._id +
                    "/" +
                    data.videoOrigName.replace(
                        /[^a-zA-Z0-9 ]/g,
                        ""
                    ) +
                    "_" +
                    data._id +
                    "_3d.json"
            );
            var fileRefAI = storageRef.child(
                folder +
                    data._id +
                    "/" +
                    data.videoOrigName.replace(
                        /[^a-zA-Z0-9 ]/g,
                        ""
                    ) +
                    "_" +
                    data._id +
                    "_ai.json"
            );
            // upload you blob into the storage
            await fileRefAI
                .put(blobAI)
                .then(function (snapshot) {
                    console.log("Uploaded AI JSON file!");
                });
            await fileRef2d
                .put(blob2d)
                .then(function (snapshot) {
                    console.log("Uploaded 2d JSON file!");
                });
            await fileRef3d
                .put(blob3d)
                .then(function (snapshot) {
                    console.log("Uploaded 3d JSON file!");
                });
            
            if (user !== 'qa') {
                await admin
                    .firestore()
                    .collection("videos")
                    .doc(data._id)
                    .update({
                        analyzed: true,
                        analysisPlatform: "Server",
                    });
            }
            if (user === 'qa') {
                saveData(blob2d, data.videoOrigName.replace(
                    /[^a-zA-Z0-9 ]/g,
                    ""
                ) + "_2d");
                saveData(blob3d, data.videoOrigName.replace(
                    /[^a-zA-Z0-9 ]/g,
                    ""
                ) + "_3d");
                saveData(blobAI, data.videoOrigName.replace(
                    /[^a-zA-Z0-9 ]/g,
                    ""
                ) + "_AI");
            }
            Swal.close();
            Swal.fire({
                title: '<p style="font-size:70%;">Successfully analyzed!</p>',
                icon: "success",
                //confirmButtonText: 'Ok',
                showConfirmButton: false,
                allowOutsideClick: false,
                timer: 3000,
            }).then(() => {
                console.log(data)
            });
            return {averageScore: averageScore, totalFrames: totalFrames, totalScore: totalScore}
        } else {
            Swal.fire({
                title:
                    '<p style="font-size:70%;">Swing and a miss error because confidence score was ' +
                    averageScore +
                    "%</p>",
                icon: "error",
                confirmButtonText: "Ok",
                allowOutsideClick: true,
            });
            return {error: "Swing and a miss error because confidence score was " + averageScore + "%"}
        }
    })
    .catch((err) => {
        clearInterval(timerInterval)
        console.log(err);
        Swal.fire({
            title: '<p style="font-size:70%;">No swing detected</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
        });
        return {error: "No swing detected"}
    });
};

export function reportAnalysisStatus(time, callback) {
    let host = "https://gpu.sportsbox.ai:30443";
    if (window.location.href.includes("localhost")) {
        host = "http://gpu.sportsbox.ai:30080";
    }
    return new Promise(async (resolve, reject) => {
        await new Promise(resolveInner => setTimeout(resolveInner, 1000));
        let resData = {}
        do {
            await axios(`${host}/web_inference/${time}`, {
                method: "GET",
            }).then(res => {
                resData = res.data || {}
                console.log(resData)
                resData.statusText = `${resData.stage}: ${parseInt((parseFloat(resData.current_num_frame)/parseFloat(resData.total_num_frame))*100)}% `
                callback(resData)
            })} while (resData.stage)
        resolve("done")
    })
}