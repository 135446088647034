import React from "react";
import { FilledEquipmentContainer, FilledEquipmentItem } from "./styled";
import CloseIcon from "@material-ui/icons/Close";
import { OutlinedButton } from "../common/styled";
import { Pad } from "../../../../components";

export function FilledEquipment({ items, onRemove, onAdd }) {
  return (
    <FilledEquipmentContainer>
      {items.map((el) => (
        <>
          <FilledEquipmentItem key={el}>
            <div>{el}</div>
            <CloseIcon onClick={() => onRemove(el)} fontSize="small" />
          </FilledEquipmentItem>
          <Pad width="10px" />
        </>
      ))}
      <OutlinedButton onClick={onAdd} color="#A12AEE">
        Add
      </OutlinedButton>
    </FilledEquipmentContainer>
  );
}
