import admin from "../../common/firebase";
import { fbMapper } from "../../helpers/fbMapper";
import { format } from "date-fns";

const dateMapper = (el) => ({
  ...el,
  createdAt: format(el.createdAt, "MM/dd/yyyy"),
});

export const getTipsAndDrills = async () => {
  const vids = fbMapper(
    await admin.firestore().collection("tipsAndDrillsVideos").get()
  ).map(dateMapper);

  return vids;
};

export const getPracticeGuides = async () => {
  const guides = fbMapper(
    await admin.firestore().collection("practiceGuides").get()
  ).map(dateMapper);

  return guides;
};

export const createPracticeGuide = async (data) => {
  console.log(data);
  const {
    name,
    description,
    platform,
    accessLevel,
    isPremium,
    tags,
    file,
    summary,
  } = data;

  const userId = admin.auth().currentUser.uid;

  const userData = (
    await admin.firestore().collection("users").doc(userId).get()
  ).data();

  var storageRef = admin.storage().ref();

  const extension = file.name.split(".").pop();

  var fileRef = storageRef.child(`practice_guides/${Date.now()}.${extension}`);

  const uploadTaskSnapshot = await fileRef.put(file);

  const filePath = await uploadTaskSnapshot.ref.getDownloadURL();

  const parsed = {
    name,
    descriptions: description,
    platform,
    tags,
    creator: {
      name: `${userData.firstName} ${userData.lastName}`,
      userId,
    },
    bannerImageUrl: filePath,
    accessLevel,
    isPremium,
    likes: 0,
    summary,
    createdAt: Date.now(),
  };
  await admin.firestore().collection("practiceGuides").add(parsed);

  const guides = fbMapper(
    await admin.firestore().collection("practiceGuides").get()
  ).map(dateMapper);

  return guides;
};

export const updatePracticeGuide = async (id, data) => {
  const {
    name,
    description,
    platform,
    accessLevel,
    isPremium,
    tags,
    newFile,
    summary,
  } = data;

  const prev = (
    await admin.firestore().collection("practiceGuides").doc(id).get()
  ).data();

  const parsed = {
    ...prev,
    accessLevel,
    isPremium,
    name,
    descriptions: description,
    platform,
    tags,
    summary,
  };

  if (newFile) {
    await admin.storage().refFromURL(prev.bannerImageUrl).delete();

    var storageRef = admin.storage().ref();

    const thumbExtension = newFile.name.split(".").pop();
    var thumbFileRef = storageRef.child(
      `practice_guide/${Date.now()}.${thumbExtension}`
    );
    const thumbUploadTaskSnapshot = await thumbFileRef.put(newFile);
    const thumbDownloadURL = await thumbUploadTaskSnapshot.ref.getDownloadURL();

    parsed.bannerImageUrl = thumbDownloadURL;
  }

  await admin.firestore().collection("practiceGuides").doc(id).set(parsed);

  const guides = fbMapper(
    await admin.firestore().collection("practiceGuides").get()
  ).map(dateMapper);

  return guides;
};

export const createTipsAndDrillsVideo = async (data) => {
  const { name, tags, platform, video, accessLevel, isPremium } = data;

  const userId = admin.auth().currentUser.uid;

  const userData = (
    await admin.firestore().collection("users").doc(userId).get()
  ).data();

  var storageRef = admin.storage().ref();

  const vidExtension = video.file.name.split(".").pop();
  const thumbExtension = video.thumbnail.name.split(".").pop();

  var vidFileRef = storageRef.child(
    `tips_and_drills/video/${Date.now()}.${vidExtension}`
  );
  var thumbFileRef = storageRef.child(
    `tips_and_drills/thumbnail/${Date.now()}.${thumbExtension}`
  );

  const vidUploadTaskSnapshot = await vidFileRef.put(video.file);
  const vidDownloadURL = await vidUploadTaskSnapshot.ref.getDownloadURL();

  const thumbUploadTaskSnapshot = await thumbFileRef.put(video.thumbnail);
  const thumbDownloadURL = await thumbUploadTaskSnapshot.ref.getDownloadURL();

  const parsed = {
    createdAt: Date.now(),
    isPremium,
    accessLevel,
    name,
    tags,
    platform,
    creator: {
      name: `${userData.firstName} ${userData.lastName}`,
      userId,
    },
    video: {
      thumbnail: thumbDownloadURL,
      length: Math.floor(video.length),
      url: vidDownloadURL,
    },
  };

  await admin.firestore().collection("tipsAndDrillsVideos").add(parsed);

  const vids = fbMapper(
    await admin.firestore().collection("tipsAndDrillsVideos").get()
  ).map(dateMapper);

  return vids;
};

export const updateTipsAndDrillsVideo = async (id, data) => {
  const { name, tags, platform, video, newVideo, isPremium, accessLevel } =
    data;

  const prev = (
    await admin.firestore().collection("tipsAndDrillsVideos").doc(id).get()
  ).data();

  const parsed = {
    ...prev,
    isPremium,
    accessLevel,
    name,
    tags,
    platform,
  };

  if (newVideo.file) {
    await admin.storage().refFromURL(prev.bannerImageUrl).delete();

    var storageRef = admin.storage().ref();

    const vidExtension = newVideo.file.name.split(".").pop();
    const thumbExtension = newVideo.thumbnail.name.split(".").pop();

    var vidFileRef = storageRef.child(
      `tips_and_drills/video/${Date.now()}.${vidExtension}`
    );
    var thumbFileRef = storageRef.child(
      `tips_and_drills/thumbnail/${Date.now()}.${thumbExtension}`
    );

    const vidUploadTaskSnapshot = await vidFileRef.put(newVideo.file);
    const vidDownloadURL = await vidUploadTaskSnapshot.ref.getDownloadURL();

    const thumbUploadTaskSnapshot = await thumbFileRef.put(newVideo.thumbnail);
    const thumbDownloadURL = await thumbUploadTaskSnapshot.ref.getDownloadURL();

    parsed.video = {
      thumbnail: thumbDownloadURL,
      length: Math.floor(newVideo.length),
      url: vidDownloadURL,
    };
  }

  await admin.firestore().collection("tipsAndDrillsVideos").doc(id).set(parsed);

  const vids = fbMapper(
    await admin.firestore().collection("tipsAndDrillsVideos").get()
  ).map(dateMapper);

  return vids;
};

export const deleteTipsAndDrillsVideo = async (id) => {
  await admin.firestore().collection("tipsAndDrillsVideos").doc(id).delete();

  const vids = fbMapper(
    await admin.firestore().collection("tipsAndDrillsVideos").get()
  ).map(dateMapper);

  return vids;
};

export const deletePracticeGuide = async (id) => {
  await admin.firestore().collection("practiceGuides").doc(id).delete();

  const guides = fbMapper(
    await admin.firestore().collection("practiceGuides").get()
  ).map(dateMapper);

  return guides;
};

export const getTutorials = async () => {
  const tutorials = (
    await admin
      .firestore()
      .collection("mobileAppSettings")
      .doc("4whziaqEAjuOc4nXwvBE")
      .get()
  ).data().tutorials;
  return tutorials;
};

export const addTutorial = async (data) => {
  const { title, description, tutorialType, id, video } = data;

  const parsed = {
    title,
    description,
    tutorialType,
    id,
  };

  if (video.file) {
    var storageRef = admin.storage().ref();

    const vidExtension = video.file.name.split(".").pop();

    var vidFileRef = storageRef.child(
      `tutorials/video/${Date.now()}.${vidExtension}`
    );

    const vidUploadTaskSnapshot = await vidFileRef.put(video.file);
    const vidDownloadURL = await vidUploadTaskSnapshot.ref.getDownloadURL();

    parsed.videoPath = vidDownloadURL;
    parsed.videoDuration = `${Math.floor(video.length)}`;
  }

  if (video.thumbnail) {
    var storageRef = admin.storage().ref();

    const thumbExtension = video.thumbnail.name.split(".").pop();
    var thumbFileRef = storageRef.child(
      `tutorials/thumbnail/${Date.now()}.${thumbExtension}`
    );
    const thumbUploadTaskSnapshot = await thumbFileRef.put(video.thumbnail);
    const thumbDownloadURL = await thumbUploadTaskSnapshot.ref.getDownloadURL();

    parsed.thumbVideoPath = thumbDownloadURL;
  }

  const tutData = fbMapper(
    await admin.firestore().collection("mobileAppSettings").get()
  )[0];

  await admin
    .firestore()
    .collection("mobileAppSettings")
    .doc(tutData.uid)
    .update({ tutorials: [...tutData.tutorials, parsed] });

  const tutorials = fbMapper(
    await admin.firestore().collection("mobileAppSettings").get()
  )[0].tutorials;

  return tutorials;
};

export const editTutorial = async (id, data) => {
  const { title, description, tutorialType, video, newVideo } = data;

  const tutData = fbMapper(
    await admin.firestore().collection("mobileAppSettings").get()
  )[0];

  const prev = tutData.tutorials.find((el) => el.id === id);

  const parsed = {
    title,
    description,
    tutorialType,
  };

  if (newVideo.file) {
    await admin.storage().refFromURL(prev.videoPath).delete();

    var storageRef = admin.storage().ref();

    const vidExtension = newVideo.file.name.split(".").pop();

    var vidFileRef = storageRef.child(
      `tutorials/video/${Date.now()}.${vidExtension}`
    );

    const vidUploadTaskSnapshot = await vidFileRef.put(newVideo.file);
    const vidDownloadURL = await vidUploadTaskSnapshot.ref.getDownloadURL();

    parsed.videoPath = vidDownloadURL;
    parsed.videoDuration = `${Math.floor(newVideo.length)}`;
  }

  if (newVideo.thumbnail) {
    await admin.storage().refFromURL(prev.thumbVideoPath).delete();

    var storageRef = admin.storage().ref();

    const thumbExtension = newVideo.thumbnail.name.split(".").pop();
    var thumbFileRef = storageRef.child(
      `tutorials/thumbnail/${Date.now()}.${thumbExtension}`
    );
    const thumbUploadTaskSnapshot = await thumbFileRef.put(newVideo.thumbnail);
    const thumbDownloadURL = await thumbUploadTaskSnapshot.ref.getDownloadURL();

    parsed.thumbVideoPath = thumbDownloadURL;
  }

  const result = {
    tutorials: tutData.tutorials.map((el) => {
      if (el.id === id) {
        return { ...el, ...parsed };
      } else return el;
    }),
  };

  await admin
    .firestore()
    .collection("mobileAppSettings")
    .doc(tutData.uid)
    .update(result);

  const tutorials = fbMapper(
    await admin.firestore().collection("mobileAppSettings").get()
  )[0].tutorials;

  return tutorials;
};

export const deleteTutorial = async (id) => {
  const tutData = fbMapper(
    await admin.firestore().collection("mobileAppSettings").get()
  )[0];

  const prev = tutData.tutorials.find((el) => el.id === id);

  await admin.storage().refFromURL(prev.videoPath).delete();
  await admin.storage().refFromURL(prev.thumbVideoPath).delete();

  const parsed = {
    tutorials: tutData.tutorials.filter((el) => el.id !== id),
  };

  await admin
    .firestore()
    .collection("mobileAppSettings")
    .doc(tutData.uid)
    .update(parsed);

  const tutorials = fbMapper(
    await admin.firestore().collection("mobileAppSettings").get()
  )[0].tutorials;

  return tutorials;
};

export const test = async () => {
  const users = fbMapper(await admin.firestore().collection("users").get());
  const data = users.reduce((acc, el) => {
    !(el.subscriptionType in acc)
      ? (acc[el.subscriptionType] = 1)
      : (acc[el.subscriptionType] += 1);
    return acc;
  }, {});

  console.log(data);
};
