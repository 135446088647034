import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button, Grid, Typography, Link } from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4),
        background: '#101828',
        color: '#FCFCFD',
    },
    copyright: {
        color: '#F9FAFB',
        [theme.breakpoints.down('sm')]: {
          textAlign: 'center',
        },
    },
    menu: {
        textAlign: 'right',
        [theme.breakpoints.down('sm')]: {
          textAlign: 'center',
        },
    },
}));

const Footer = (props) => {
    const { className, plansType, getStarted, ...rest } = props;

    const classes = useStyles();

    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}
        >
            <Grid
                container
                maxWidth="xl"
            >
                {/*plansType == 'coaches' && (
                    <Grid
                        item
                        xs={12}
                        style={{ textAlign: 'center' }}
                    >
                        <img
                            alt="Logo"
                            src="/images/logos/Logomark.svg"
                            style={{ marginTop: '32px' }}
                        />
                        <p
                            style={{ fontSize: '30px', fontWeight: 600, padding: '40px 0' }}
                        >
                            Start your 14-day free trial to 3D Pro
                        </p>
                        <div>
                            <Button target="_blank" rel="noopener noreferrer" href="https://meetings.hubspot.com/edwin-fuh/product-demo" className="plan-button" style={{ textTransform: 'none', marginRight: '20px', fontWeight: 600, padding: '12px 20px', background: "white", color: "black" }}>Book a Demo</Button>
                            <Button onClick={() => { getStarted('premium'); window.scrollTo(0, 0); }} className="plan-button"  style={{ textTransform: 'none', fontWeight: 600, padding: '12px 20px', background: "#5C0DEE", color: "white" }}>Get started</Button>
                        </div>
                    </Grid>
                )*/}
                <Grid
                    item
                    xs={12} style={{ borderTop: '1px solid #475467', paddingBottom: '32px', marginTop: '32px' }}
                />
                <Grid
                    item
                    sm={6}
                    xs={12}
                >
                    <Typography variant="body1" className={classes.copyright}>
                        &copy; {moment().year()} {' '}
                        <Link
                            style={{ color: '#F9FAFB' }}
                            component="a"
                            href="https://www.sportsbox.ai/"
                            target="_blank"
                        >
                            SPORTSBOX AI.
                        </Link>
                        {' '} All rights reserved.
                    </Typography>
                </Grid>
                <Grid
                    item
                    sm={6}
                    xs={12}
                >
                    <Typography variant="body1" className={classes.menu}>
                        <Link
                            style={{ color: '#F9FAFB' }}
                            component="a"
                            href="/privacy"
                            target="_blank"
                        >
                            Privacy Policy
                        </Link>
                        {' '} & {' '}
                        <Link
                            style={{ color: '#F9FAFB' }}
                            component="a"
                            href="/terms-of-service"
                            target="_blank"
                        >
                            Terms of Service
                        </Link>
                        )
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );
};

Footer.propTypes = {
    className: PropTypes.string,
};

export default Footer;
