import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import CheckBox from "@material-ui/core/Checkbox";
import React, { useEffect, useState } from "react";
import { FlexWrap, Pad } from "../../../components";
import { FilledButton, ModalTitle, TagsModalItem } from "./styled";

//

export function RemoteControlSessionDeviceModal({
  devices,
  sessionSelectedDevices,
  open,
  onClose,
  onConfirm,
}) {
  const [selectedDevices, setSelectedDevices] = useState([]);

  useEffect(() => {
    if (sessionSelectedDevices) {
      setSelectedDevices(sessionSelectedDevices.map((el) => el.deviceId));
    }
  }, [sessionSelectedDevices]);

  const handleSelect = (id) => {
    if (selectedDevices.includes(id)) {
      if (selectedDevices.length !== 1) {
        setSelectedDevices((prev) => prev.filter((el) => el !== id));
      }
    } else {
      setSelectedDevices((prev) => [...prev, id]);
    }
  };

  const CustomRadio = ({ active }) => {
    return (
      <>
        <div
          style={{
            border: "2px solid #580CE3",
            borderRadius: "50%",
            padding: "2px",
            width: "20px",
            height: "20px",
          }}
        >
          <div
            style={{
              background: active ? "#580CE3" : "rgba(0, 0, 0, 0)",
              borderRadius: "50%",
              width: "12px",
              height: "12px",
            }}
          />
        </div>
      </>
    );
  };

  const DeviceComponent = ({ deviceData, selected, setSelected }) => {
    const handleCheckbox = (e) => {
      switch (e.target.checked) {
        case true:
          setSelected((prev) => [...prev, e.target.id]);
          break;
        default:
          setSelected((prev) => prev.filter((el) => el !== e.target.id));
          break;
      }
    };

    return (
      <FlexWrap justify="space-between">
        <div>{deviceData.deviceName}</div>
        <CheckBox
          color="primary"
          onChange={handleCheckbox}
          id={deviceData.deviceId}
          checked={selected.includes(deviceData.deviceId)}
        />
      </FlexWrap>
    );
  };

  const handleConfirm = () => {
    const arr = [];
    devices.forEach((el) => {
      if (selectedDevices.includes(el.deviceId)) {
        arr.push({
          ...el,
          form: {
            lighting: "",
            brightness: 100,
            zoom: 1,
            isFrontCameraEnabled: false,
            angle: "FaceOn",
          },
          cameraCapabilities: {
            brightnessMin: 0,
            brightnessMax: 0,
            zoomRangeMin: 0,
            zoomRangeMax: 0,
          },
        });
      }
    });
    setSelectedDevices([]);
    onConfirm(arr);
    onClose();
  };

  return (
    // <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
    //   <DialogTitle>
    //     <Typography variant="h3">Select remote devices</Typography>
    //   </DialogTitle>
    //   <DialogContent>
    //     {devices
    //       ? devices?.map((el) => (
    //           <DeviceComponent
    //             key={el.deviceId}
    //             deviceData={el}
    //             selected={selectedDevices}
    //             setSelected={setSelectedDevices}
    //           />
    //         ))
    //       : ""}
    //     <Pad height="10px" />

    //     <FlexWrap justify="center">
    //       <Button
    //         variant="contained"
    //         color="primary"
    //         size="small"
    //         onClick={handleConfirm}
    //       >
    //         Save
    //       </Button>
    //     </FlexWrap>
    //     <Pad height="10px" />
    //   </DialogContent>
    // </Dialog>
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <DialogContent style={{ padding: "30px" }}>
        <ModalTitle>Select remote devices</ModalTitle>
        {devices
          ? devices?.map((el) => (
              <>
                <TagsModalItem onClick={() => handleSelect(el.deviceId)}>
                  <div>{el.deviceName}</div>
                  <CustomRadio active={selectedDevices.includes(el.deviceId)} />
                </TagsModalItem>
                <Pad height="10px" />
              </>
            ))
          : ""}
        <FlexWrap justify="space-between">
          <FilledButton onClick={onClose} width="48%" color="#808080">
            cancel
          </FilledButton>
          <FilledButton onClick={handleConfirm} width="48%" color="#580CE3">
            add
          </FilledButton>
        </FlexWrap>
      </DialogContent>
    </Dialog>
  );
}
