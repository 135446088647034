import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {functionBaseUrl, axiosWithToken} from '../../common/firebase'
import firebase from '../../common/firebase'
import {withStyles} from '@material-ui/styles';
import {Grid, Container, Button, Avatar, Typography, CssBaseline, CircularProgress, Card, CardHeader, CardContent, Divider} from '@material-ui/core';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import {AccountNotFound} from '../account/components';
import { red } from '@material-ui/core/colors';


const useStyles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    marginTop: theme.spacing(4),
  },
  buttonLink: {  // This is a link component surrounding a button component
    width: '100%'
  },
  linkedButton: {  // This is a button component surrounded by a link
    margin: theme.spacing(1, 0),
  }
});

const RejectButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
}))(Button);

class RoleReview extends Component {
  constructor(props) {
    const firebaseUser = firebase.auth().currentUser
    super(props)
    this.state = {
      uid : firebaseUser.uid,
      error : "",
      loading : true,
      pendingRoleRequests : []
    }
  }

  componentDidMount() {
    if (!this.state.uid) {   
      this.setState({error : "Please log in to submit a session"})
      this.setState({loading : false})  
    }
    else {
      axiosWithToken(functionBaseUrl+'/api/users/' + this.state.uid, {
        method: 'get',
      }).then(response => {
        var role = response.data.role;
        if(!["admin", "data-admin"].includes(role)) {
          this.setState({loading: false, error : "You are not authorized to access this page."});
        }
        axiosWithToken(functionBaseUrl+'/api/pendingRoleRequests/', {
          method: 'get',
        }).then(response => {
          this.setState({
            loading : false,
            pendingRoleRequests: response.data
          }) 
        })
        .catch(err => {
          this.setState({loading: false, error : "You are not authorized to access this page."});
        })   
      })
      .catch(err => {
        console.log(err)
        this.setState({loading: false, error : "There was an error retrieving your info"});
      });
    }    
  }

  approveRequest = (requestId) => (event) => {
    axiosWithToken(functionBaseUrl+'/api/approveRoleRequest/' + requestId, {
      method: 'patch',
    }).then(response => {
      console.log(response)
      let pendingRoleRequests = [...this.state.pendingRoleRequests];
      var requestIndex = this.state.pendingRoleRequests.map(roleRequest => roleRequest.id).indexOf(requestId);
      pendingRoleRequests.splice(requestIndex, 1);
      this.setState({pendingRoleRequests: pendingRoleRequests});
    })
    .catch(err => {
      console.log(err)
    });
  }

  rejectRequest = (requestId) => (event) => {
    axiosWithToken(functionBaseUrl+'/api/rejectRoleRequest/' + requestId, {
      method: 'patch',
    }).then(response => {
      console.log(response)
      let pendingRoleRequests = [...this.state.pendingRoleRequests];
      var requestIndex = this.state.pendingRoleRequests.map(roleRequest => roleRequest.id).indexOf(requestId);
      pendingRoleRequests.splice(requestIndex, 1);
      this.setState({pendingRoleRequests: pendingRoleRequests});
    })
    .catch(err => {
      console.log(err)
    });
  }

  requesterName = (userInfo) => {
    let name = ""
    if(userInfo && userInfo.firstName) {
      name = userInfo.firstName + " " + userInfo.lastName
    }
    return name
  }

  render() {
    return (
      <Container
        component="main"
        maxWidth="xs"
      >
      <CssBaseline />
      <Grid
        style = {{display: (this.state.error) ? "none" : (this.state.loading) ? "none" : (this.state.success) ? "none" : "flex"}}
      >
        <div className={this.props.classes.paper}>
          <Avatar className={this.props.classes.avatar}>
            <AssignmentIndIcon />
          </Avatar>
          <Typography
            component="h1"
            variant="h4"
          >
            REVIEW ROLE REQUESTS
          </Typography>
          <div className={this.props.classes.root}>
            {
              (this.state.pendingRoleRequests.length === 0) ? "There are no role requests left for review" :
              this.state.pendingRoleRequests.map((roleRequest) => (
                <Card style={{maxWidth: "100%"}} key={roleRequest.id}>
                  <CardHeader
                      subheader={"is requesting the " + roleRequest.requestInfo.requestedRole + " role" }
                      title={this.requesterName(roleRequest.userInfo)}
                      titleTypographyProps={{variant:"h5", color:"primary"}}
                    />
                    <Divider />
                    <CardContent>
                      <Button          
                        variant="contained"
                        color="primary"
                        onClick={this.approveRequest(roleRequest.id)}
                      >
                        Approve
                      </Button>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <RejectButton          
                        variant="contained"
                        color="primary"
                        onClick={this.rejectRequest(roleRequest.id)}
                      >
                        Reject
                      </RejectButton>
                    </CardContent>
                </Card>
              ))
            }
          </div>
        </div>
      </Grid>

      {/* Loading screen*/}
      <Grid
        style = {{margin : "auto", padding : 100, display: (this.state.loading) ? "block" : "none"}}
      >
        <CircularProgress />
      </Grid>
      {/* Error screen*/}
      <Grid
        item
        lg={12}
        md={12}
        xl={12}
        xs={12}
        style = {{display: (this.state.error && !this.state.loading) ? "block" : "none"}}
      >
        <AccountNotFound            
          error = {{error : this.state.error}}
        />
      </Grid>
      </Container>
    );
  }
};

RoleReview.propTypes = {
  classes:PropTypes.object,
  history: PropTypes.object,
};

export default withStyles(useStyles)(RoleReview);
