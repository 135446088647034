import { axiosWithToken, esIndex, functionBaseUrl, riIndex } from "./firebase"

export const pitLifespan = '15m';
export const elasticIndex = esIndex;
export const reportIndex = riIndex;

/**
 * Gets documeent by id with optional getParent parameter
 * @param {string} docId 
 * @param {Object} getParent 
 * @returns 
 */
export async function getDocument(docId, getParent = null) {
    return await axiosWithToken(functionBaseUrl+'/api/elastic/doc', {
        method: 'get',
        params: { id: docId, getParent: getParent}
    }).then(response => {
        return response.data
    }).catch(err => {
        return null
    })
}

/**
 * Sends query to firebase and returns hits, total, and sort values for search_after
 * @param {object} config
 * @param {string} config.index - index to be queried in. Ignored if pit is provided
 * @param {{id: string, keep_alive: string}} config.pit - pit to be queried with.
 * @param {string} config.bool - type of the document to be queried in.
 * @param {{filter: array, must: array, must_not: array, should: array}} config.bool - expanded bool object for query. 
 *                                                                                      Ignored if bool is provided
 * @param {integer} config.size - number of documents to be returned.
 * @param {integer} config.search_after - Which document to search afternoon
 * @param {sort} config.sort - sort parameters for elastic
 */
export async function queryElastic(config) {
    let query = {
        index: config.index,
        track_total_hits: true,
        query: {
            bool: config.bool ? config.bool : 
              {
                filter: config.filter,
                must: config.must,
                should: config.should,
                must_not: config.must_not,
                multi_match: config.multi_match
              }
        },
        size: config.size,
        aggs: config.aggs,
        sort: config.sort,
        search_after: config.search_after
    }
    if (config.query) {
      query.query = config.query
    }
    if (config.pit) {
      query.pit = {
        id: config.pit.id,
        keep_alive: config.pit.keep_alive || pitLifespan
      }
    } else {
      query["index"] = config.index
    }
    return axiosWithToken(functionBaseUrl+'/api/elastic/search', {
      method: 'get',
      params: {body: query, target: config.target}
    }).then(response => {
      let hits
      //Gets inner_hits if onlyInner is in config
      if (config.onlyInner) {
        hits = response.data.hits.hits.map(hit => {
          if (hit.inner_hits) {
            const inners = {[config.target]: { hits: [{...hit, inner_hits: null}], total: 1}}
            getAllInner(hit, inners)
            hit.inner_hits = inners
          }
          return {score: hit._score, id: hit._id, inner_hits: hit.inner_hits}
        })
      } else {
        hits = response.data.hits.hits
      }
      let result = {hits: hits, aggs: response.data.aggregations}
      //Retrieve last element and store sort values for pagination
      if (response.data.hits.hits.length > 0 && response.data.hits.total.value > 0) {
        const last = response.data.hits.hits[response.data.hits.hits.length - 1]
        result.total = response.data.hits.total.value
        if ("sort" in last) {
          result.sort = last.sort
        }
      }
      return result
    })
}

function getAllInner (curr, inner_hits) {
  if (curr.inner_hits) {
    Object.keys(curr.inner_hits).forEach(key => {
      curr.inner_hits[key].total = curr.inner_hits[key].hits.total.value
      curr.inner_hits[key].hits = curr.inner_hits[key].hits.hits
      inner_hits[key] = curr.inner_hits[key]
      curr.inner_hits[key].hits.forEach(hit => {
        getAllInner(hit, inner_hits)
      })
      delete curr.inner_hits[key]
    })
  }
}

/**
 * Generates and returns a pit for the given index and keep_alive
 * @param {string} index 
 * @param {string} keep_alive
 */
export async function generatePit(index=elasticIndex, keep_alive=pitLifespan, pit=null) {
  return axiosWithToken(functionBaseUrl+'/api/elastic/search', {
    method: 'post',
    params: {index: index, keep_alive: keep_alive, pit: pit}
  }).then(response => {
    let pit = response.data.id
    return pit
  })
  .catch(err => {
    console.log(err.response?.data)
    return 0
  });
}

export async function getMappings(index=elasticIndex) {
  return axiosWithToken(functionBaseUrl+'/api/elastic/mappings/'+index, {
    method: 'get',
  }).then(response => {
    return response.data
  }).catch(err => {
    console.log(err)
    return null
  })
}