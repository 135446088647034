import styled from "styled-components";

export const FilledEquipmentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const FilledEquipmentItem = styled.div`
  display: flex;
  align-items: center;
  background: #fff;
  padding: 10px;
  height: 40px;
  border: gray 1px solid;
  border-radius: 5px;
`;

export const UploadedVideoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(160, 42, 238, 0.2);
  width: 100%;
  height: 80px;
`;

export const PreviewButton = styled.a`
  border: #a02aee 1px solid;
  color: #a02aee;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
  letter-spacing: 0.115em;
  text-transform: uppercase;
`;

export const EmptyAudioSelector = styled.label`
  width: 100%;
  padding: 20px;
  border-radius: 5px;
  border: 1px dashed #a12aee;
  display: flex;
  align-items: center;
  color: #a12aee;
  cursor: pointer;
`;

export const FilledAudioSelector = styled.div`
  width: 100%;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  background: #fff;
  justify-content: space-between;
`;

export const AddKeyPoint = styled.label`
  width: 100%;
  padding: 20px;
  border-radius: 5px;
  border: 1px dashed #a12aee;
  display: flex;
  align-items: center;
  color: #a12aee;
  cursor: pointer;
`;
