import React from 'react';

import { Container, Grid } from '@material-ui/core';

import { EnterpriseAccountList } from './EnterpriseAccountList';


function EnterpriseComponent() {
  return (<>
    <Container
      component="main"
      style={{ paddingTop: '20px' }}
    >
      <Grid container spacing={2}>
        <EnterpriseAccountList />
      </Grid>
    </Container>
  </>);
}

export const Enterprise = EnterpriseComponent;