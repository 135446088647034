import React, { Component } from 'react';
import { createMuiTheme, MuiThemeProvider, Link, CssBaseline, Container } from '@material-ui/core';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';

import { forwardRef } from 'react';
 
import {AddBox, ArrowDownward, Check, ChevronLeft, ChevronRight, Clear, DeleteOutline, Edit, FilterList, FirstPage, LastPage, Remove, SaveAlt, Search, ViewColumn} from '@material-ui/icons';
import palette from '../../theme/palette';
import CameraMeta from './CameraMeta';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const getTableHeads = (setupCombination) => {
  const tableHeads = [
    {field: 'name', title: 'File Name'},
    {field: 'type', title: 'Type'},
    {field: 'zoom', title: 'Zoom'},
    {field: 'resolution', title: 'Resolution'},
    {field: 'fps', title: 'FPS'},
    {field: 'orientation', title: 'Orientation'},
    {field: 'angle', title: 'Angle'}]
  if(setupCombination > 1) {
    tableHeads.splice(1, 0, {field: 'cameraNumber', title: 'Camera #'})
  }
  return tableHeads
}

const theme = createMuiTheme({
  typography: {
    h6: {
      fontWeight: 500,
      fontSize: '20px',
      letterSpacing: '-0.05px',
      lineHeight: '20px',
    }
  },
  palette: {
    secondary: {
      main: palette.primary.main,
    },
  },
})
class VideoFileTable extends Component {
  constructor (props) {
    super(props);
    this.state = {
      selectedRows: [],
      popUpOpen: false
    }
  }

  deleteVideos = (event, rowData) => {
    this.props.deleteMultipleFiles(rowData)
    this.setState({selectedRows: []})
  }

  editVideos = (event, rowData) => {
    this.setState({selectedRows: rowData, popUpOpen: true})
  }

  handleMetaDataEdit = (metaData) => {
    const idsToModify = this.state.selectedRows.map((row) => row.tableData.id)
    const modifiedVideos = this.props.videos.map((videoData, index) => {
      let originalData = videoData
      if (idsToModify.includes(index)){
        originalData.metaData = metaData
      }
      return originalData
    })
    this.props.updateVideos(modifiedVideos)
    this.setState({popUpOpen: false})
  }

  getDataFromVideos = (videos) => {
    const tableData = videos.map((video) => {
      return {
        name: video.file.name,
        type: video.file.type,
        zoom: video.metaData.zoom,
        resolution: video.metaData.resolution,
        fps: video.metaData.fps,
        orientation: video.metaData.orientation,
        cameraNumber: video.metaData.cameraNumber,
        angle: video.metaData.angle
      }
    })
    return tableData
  }

  handlePopUpClose = () => {
    this.setState({pendingFile: null, popUpOpen : false});
  }

  render(){
    return (
    <Container
      component="main"
      style={{marginBottom: '0'}}
      className={this.props.classes.paper}
    >
      <CssBaseline/>
      <MuiThemeProvider theme={theme}>
        <MaterialTable
          style={{marginBottom: '10px', width: '100%'}}
          icons={tableIcons}
          key='videoTable'
          title='Video Queue'
          columns={getTableHeads(this.props.cameraSetup.setupCombination)}
          data={this.getDataFromVideos(this.props.videos).map((row) => this.state.selectedRows.find(selected => selected.name === row.name) ? {...row, tableData: { checked: true}} : row)}
          options={{
            selection: true
          }}
          actions={[
            {
              tooltip: 'Edit selected videos meta-data',
              icon: tableIcons.Edit,
              onClick: this.editVideos
            },
            {
            tooltip: 'Delete selected videos',
            icon: tableIcons.Delete,
            onClick: this.deleteVideos
          }]}/>
      </MuiThemeProvider>
      
      <Link component='button' variant="body2" style={{display:'flex', justifyContent: 'flex-end'}} onClick={this.props.handleToggleShowVideos}>Back To Upload</Link>
      <CameraMeta
        open={this.state.popUpOpen}
        closePopUp={this.handlePopUpClose}
        cameraSetup={this.props.cameraSetup}
        handleSubmit={this.handleMetaDataEdit}
      /> 
    </Container>)
  }
}
VideoFileTable.propTypes = {
  videos: PropTypes.array
}
export default VideoFileTable