import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import firebase, {
  functionBaseUrl,
  axiosWithToken,
} from "../../common/firebase";
import Swal from "sweetalert2";
import "./Student.css";
import {
  defaultHeightToDefaultHipWidth,
  defaultHeightToHipWidth,
  femaleHeightToDefaultHipWidth,
  femaleHeightToHipWidth,
  heightValues,
  maleHeightToDefaultHipWidth,
  maleHeightToHipWidth,
} from "./misc/hipWidthTable";

class CreateStudent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      height: "",
      gender: "",
      dominantHand: "",
      hipWidth: "",
      subPlan: "",
      trialPeriod: "",
      currentStep: "email",
      autoRenewal: false,
      coach: null,
      isLoading: false,
    };
  }

  handleChange = (prop) => (event) => {
    if (this.props.plan === "free") {
      this.setState({ subPlan: "development_sportsbox_free_student" });
    }
    this.setState({ [prop]: event.target.value });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.height !== prevState.height ||
      this.state.gender !== prevState.gender
    ) {
      let result = 6;
      if (this.state.height !== "" && this.state.gender !== "") {
        switch (this.state.gender) {
          case "MALE":
            result = maleHeightToDefaultHipWidth[this.state.height];
            break;
          case "FEMALE":
            result = femaleHeightToDefaultHipWidth[this.state.height];
            break;
          default:
            result = defaultHeightToDefaultHipWidth[this.state.height];
            break;
        }
        this.setState({ hipWidth: result });
      }
    }
  }

  getInitialState = () => {
    return {
      firstName: "",
      lastName: "",
      email: "",
      height: "",
      gender: "",
      dominantHand: "",
      hipWidth: "",
      subPlan: "",
      autoRenewal: false,
      trialPeriod: "",
      currentStep: "email",
      isLoading: false,
    };
  };

  submitButton = () => {
    if (this.state.currentStep === "email") {
      return "NEXT";
    } else {
      return "SUBMIT";
    }
  };

  handleClear = () => {
    const confirmVal = window.confirm("Do you want to clear your query?");
    if (confirmVal) {
      if (this.state.currentStep === "email") {
        this.setState(this.getInitialState());
      } else {
        this.setState({ 
          firstName: "",
          lastName: "",
          height: "",
          gender: "",
          dominantHand: "",
          hipWidth: "",
          subPlan: "",
          trialPeriod: "",
          autoRenewal: false,
        })
      }
    }
  };

  validEmail = (email) => {
    const regex = /\S+@\S+\.\S+/;
    return regex.test(email);
  };

  getDisabled = () => {
    if (this.state.currentStep === "email") {
      if (this.state.isLoading) {
        return true;
      }
      if (this.state.email === "" || (this.props.instructors?.length && !this.state.coach)) {
        return true;
      } else {
        if (this.validEmail(this.state.email)) {
          return false;
        } else {
          return true;
        }
      }
    }
    if (this.state.currentStep === "newStudent") {
      if (
        this.state.firstName === "" ||
        this.state.lastName === "" ||
        this.state.email === "" ||
        this.state.height === "" ||
        this.state.gender === "" ||
        this.state.dominantHand === "" ||
        this.state.hipWidth === "" ||
        (this.props.plan === "student_lite" && this.state.subPlan === "") ||
        (this.props.plan === "student_lite" &&
          (this.state.trialPeriod === "" || this.state.trialPeriod <= 0))
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  handleSubmit = async () => {
    this.setState({ isLoading: true });
    Swal.fire({
      title: 'Processing...',
      html: '<div><img width="10%" src="images/loading.gif" alt="Loading" /></div>',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      customClass : {
        container : 'my-swal' 
      },
    })
    if (this.state.currentStep === 'email') {
      const index = this.props.students.findIndex((item) => item.email === this.state.email.toLowerCase().replace(/ /g, ''));
      if (index > -1) {
        Swal.fire({
          title: '<p style="font-size:70%;">The user with email: ' + this.state.email + ' is already your student</p>',
          icon: 'warning',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showCancelButton: false,
          customClass : { 
            container : 'my-swal' 
          },
        }).then(() => {
          this.setState(this.getInitialState());
          this.props.onClose();
          return false;
        })
        return false;
      }
      const userSnap = await firebase.firestore().collection('users').where('email', '==', this.state.email.toLowerCase().replace(/ /g, '')).get();
      let user = [];
      let sub = [];
      userSnap.forEach(doc => {
        user.push({ ...doc.data(), id: doc.id });
      });
      if (userSnap.size > 0) {
        const subSnap = await firebase.firestore().collection('subscriptions').where('userId', '==', user[0].id).get();
        subSnap.forEach(doc => {
          sub.push({ ...doc.data(), id: doc.id });
        });
        if (sub.length > 0 && !sub[0].productId.includes('student') && (sub[0].endDate > String(Date.now()) || sub[0].autoRenewal)) {
          Swal.fire({
            title: '<p style="font-size:70%;">Coach accounts cannot be added as a student</p>',
            icon: 'warning',
            confirmButtonText: 'Ok',
            allowOutsideClick: true,
            customClass : { 
              container : 'my-swal' 
            },
            timer: 5000
          })
          this.setState(this.getInitialState());
          this.props.onClose();
          return false;
        }
        if (this.props.plan === 'free') {
          Swal.fire({
            title: '<p style="font-size:70%;">An account already exists associated with the email: ' + this.state.email + '. Would you like to add them as your student?</p>',
            icon: 'warning',
            confirmButtonText: 'Yes',
            allowOutsideClick: false,
            cancelButtonText: 'No',
            showCancelButton: true,
            customClass : { 
              container : 'my-swal' 
            },
          }).then(async (response) => {
            if (response.isConfirmed) {
              await axiosWithToken(functionBaseUrl + '/api/userCoach', {
                method: 'POST',
                data: {
                  userEmail: this.state.email.toLowerCase().replace(/ /g, ''),
                  coachEmail: this.state.coach ? this.props.instructors.find(c => c.id == this.state.coach).email : this.props.email
                }
              })
                .then(response => {
                  if (response.status === 201) {
                    Swal.fire({
                      title: '<p style="font-size:70%;">' + response.data.message + '</p>',
                      icon: 'warning',
                      confirmButtonText: 'Ok',
                      allowOutsideClick: false,
                      customClass : { 
                        container : 'my-swal' 
                      },
                    })
                  } else {
                    Swal.fire({
                      title: '<p style="font-size:70%;">' + response.data.message + '</p>',
                      icon: 'success',
                      confirmButtonText: 'Ok',
                      allowOutsideClick: false,
                      customClass : { 
                        container : 'my-swal' 
                      },
                      timer: 2000
                    })
                  }
                })
                .catch(err => {
                  console.log(err)
                })
              this.setState(this.getInitialState());
              this.props.onClose();
              return false;
            } else {
              this.setState(this.getInitialState());
              this.props.onClose();
              return false;
            }
          })
        } else {
          if (!user[0].subscriptionType.includes('free')) {
            if (!user[0].subscriptionType.includes('free') || sub[0].autoRenewal === true || sub[0].endDate > Date.now()) {
              Swal.fire({
                title: '<p style="font-size:70%;">User with email: ' + this.state.email + ' already have an active subscription. Would you like to add them as your student without adding subscription?</p>',
                icon: 'warning',
                confirmButtonText: 'Yes',
                allowOutsideClick: false,
                cancelButtonText: 'No',
                showCancelButton: true,
                customClass : { 
                  container : 'my-swal' 
                },
              }).then(async (response) => {
                if (response.isConfirmed) {
                  await axiosWithToken(functionBaseUrl + '/api/userCoach', {
                    method: 'POST',
                    data: {
                      userEmail: this.state.email.toLowerCase().replace(/ /g, ''),
                      coachEmail: this.state.coach ? this.props.instructors.find(c => c.id == this.state.coach).email : this.props.email
                    }
                  })
                    .then(response => {
                      if (response.status === 201) {
                        Swal.fire({
                          title: '<p style="font-size:70%;">' + response.data.message + '</p>',
                          icon: 'warning',
                          confirmButtonText: 'Ok',
                          allowOutsideClick: false,
                          customClass : { 
                            container : 'my-swal' 
                          },
                        })
                      } else {
                        Swal.fire({
                          title: '<p style="font-size:70%;">' + response.data.message + '</p>',
                          icon: 'success',
                          confirmButtonText: 'Ok',
                          allowOutsideClick: false,
                          customClass : { 
                            container : 'my-swal' 
                          },
                          timer: 2000
                        })
                      }
                    })
                    .catch(err => {
                      console.log(err)
                    })
                  this.setState(this.getInitialState());
                  this.props.onClose();
                  return false;
                } else {
                  this.setState(this.getInitialState());
                  this.props.onClose();
                  return false;
                }
              })
            }
          } else {
            Swal.fire({
              title: '<p style="font-size:70%;">An account already exists associated with the email: ' + this.state.email + '. Would you like to add them as your student?</p>',
              icon: 'warning',
              confirmButtonText: 'Yes',
              allowOutsideClick: false,
              cancelButtonText: 'No',
              showCancelButton: true,
              customClass : { 
                container : 'my-swal' 
              },
            }).then(async (response) => {
              if (response.isConfirmed) {
                this.setState({ currentStep: 'upgrade' });
                return false;
              } else {
                this.setState(this.getInitialState());
                this.props.onClose();
                return false;
              }
            })
          }
        }
      } else {
        Swal.fire({
          title: '<p style="font-size:70%;">No user found associated with email: ' + this.state.email + '. Would you like to create an account for them?</p>',
          icon: 'warning',
          confirmButtonText: 'Yes',
          allowOutsideClick: false,
          cancelButtonText: 'No',
          showCancelButton: true,
          customClass : { 
            container : 'my-swal' 
          },
        }).then((response) => {
          if (response.isConfirmed) {
            this.setState({ currentStep: 'newStudent' })
            return false;
          } else {
            this.setState(this.getInitialState());
            this.props.onClose();
            return false;
          }
        })
      }
    }
    if (this.state.currentStep === 'upgrade') {
      try {
        const userSnap = await firebase.firestore().collection('users').where('email', '==', this.state.email.toLowerCase().replace(/ /g, '')).get();
        let user = [];
        userSnap.forEach(doc => {
          user.push({ ...doc.data(), id: doc.id });
        });
        if (user.length > 0) {
          if (this.state.subPlan === '') {
            Swal.fire({
              title: '<p style="font-size:70%;">Account Type field cannot be blank</p>',
              icon: 'warning',
              confirmButtonText: 'Ok',
              allowOutsideClick: true,
              customClass : { 
                container : 'my-swal' 
              },
              timer: 5000
            })
            return false;
          }
          const output = {
            email: this.state.email.toLowerCase().replace(/ /g, ''),
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            dob: '',
            gender: this.state.gender,
            height: this.state.height,
            coachId: this.state.coach ? this.state.coach : this.props.uid,
            coachName: this.state.coach ? this.props.instructors.find(c => c.id == this.state.coach).firstName + ' ' + this.props.instructors.find(c => c.id == this.state.coach).lastName : this.props.name,
            dominantHand: this.state.dominantHand,
            hipWidth: this.state.hipWidth,
            subPlan: this.props.plan === 'free' ? 'development_sportsbox_free_student' : this.state.subPlan,
            subPeriod: Number(this.state.trialPeriod),
            autoRenewal: this.state.autoRenewal,
          }
          
          await axiosWithToken(functionBaseUrl + '/api/v3/createStudent', {
            method: 'post',
            data: output
          })
            .then(response => {
              if (response.status === 201) {
                Swal.fire({
                  title: '<p style="font-size:70%;">' + response.data.message + '</p>',
                  icon: 'warning',
                  confirmButtonText: 'Ok',
                  allowOutsideClick: true,
                  customClass : { 
                    container : 'my-swal' 
                  },
                }).then(() => {
                  this.props.onClose()
                  this.setState(this.getInitialState())
                  window.location.reload();
                })
              } else {
                Swal.fire({
                  title: '<p style="font-size:70%;">An account has been successfully created for ' + output.email + '.</p>',
                  icon: 'success',
                  confirmButtonText: 'Ok',
                  customClass : { 
                    container : 'my-swal' 
                  },
                  allowOutsideClick: true,
                  timer: 3000
                }).then(() => {
                  this.props.onClose()
                  this.setState(this.getInitialState())
                  window.location.reload();
                })
              }
            })
            .catch(err => {
              console.log(err)
            });
        }
      } catch (e) {
        Swal.fire('<p style="font-size:70%;">Error occured</p>');
        this.props.onClose();
      }
    }
    if (this.state.currentStep === 'newStudent') {
      const output = {
        email: this.state.email.toLowerCase().replace(/ /g, ''),
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        dob: '',
        gender: this.state.gender,
        height: this.state.height,
        coachId: this.state.coach ? this.state.coach : this.props.uid,
        coachName: this.state.coach ? this.props.instructors.find(c => c.id == this.state.coach).firstName + ' ' + this.props.instructors.find(c => c.id == this.state.coach).lastName : this.props.name,
        dominantHand: this.state.dominantHand,
        hipWidth: this.state.hipWidth,
        subPlan: this.props.plan === 'free' ? 'development_sportsbox_free_student' : this.state.subPlan,
        subPeriod: Number(this.state.trialPeriod),
        autoRenewal: this.state.autoRenewal,
      }
      if (output.firstName.length === 0) {
        Swal.fire({
          title: '<p style="font-size:70%;">First Name field cannot be blank</p>',
          icon: 'warning',
          confirmButtonText: 'Ok',
          allowOutsideClick: true,
          customClass : { 
            container : 'my-swal' 
          },
          timer: 5000
        })
        return false;
      }
      if (output.lastName.length === 0) {
        Swal.fire({
          title: '<p style="font-size:70%;">Last Name field cannot be blank</p>',
          icon: 'warning',
          confirmButtonText: 'Ok',
          allowOutsideClick: true,
          customClass : { 
            container : 'my-swal' 
          },
          timer: 5000
        })
        return false;
      }
      if (output.email.length === 0) {
        Swal.fire({
          title: '<p style="font-size:70%;">Email field cannot be blank</p>',
          icon: 'warning',
          confirmButtonText: 'Ok',
          allowOutsideClick: true,
          customClass : { 
            container : 'my-swal' 
          },
          timer: 5000
        })
        return false;
      }
      if (output.email.length > 0) {
        if (!this.validEmail(output.email)) {
          Swal.fire({
            title: '<p style="font-size:70%;">Please enter a valid email</p>',
            icon: 'warning',
            confirmButtonText: 'Ok',
            allowOutsideClick: true,
            customClass : { 
              container : 'my-swal' 
            },
            timer: 5000
          })
          return false;
        }
      }
      if (output.height === '') {
        Swal.fire({
          title: '<p style="font-size:70%;">Height field cannot be blank</p>',
          icon: 'warning',
          confirmButtonText: 'Ok',
          allowOutsideClick: true,
          customClass : { 
            container : 'my-swal' 
          },
          timer: 5000
        })
        return false;
      }
      if (output.gender === '') {
        Swal.fire({
          title: '<p style="font-size:70%;">Gender field cannot be blank</p>',
          icon: 'warning',
          confirmButtonText: 'Ok',
          allowOutsideClick: true,
          customClass : { 
            container : 'my-swal' 
          },
          timer: 5000
        })
        return false;
      }
      if (output.dominantHand === '') {
        Swal.fire({
          title: '<p style="font-size:70%;">Dominant Hand field cannot be blank</p>',
          icon: 'warning',
          confirmButtonText: 'Ok',
          allowOutsideClick: true,
          customClass : { 
            container : 'my-swal' 
          },
          timer: 5000
        })
        return false;
      }
      if (output.hipWidth === '') {
        Swal.fire({
          title: '<p style="font-size:70%;">Hip Width field cannot be blank</p>',
          icon: 'warning',
          confirmButtonText: 'Ok',
          allowOutsideClick: true,
          customClass : { 
            container : 'my-swal' 
          },
          timer: 5000
        })
        return false;
      }
      if (output.subPlan === '') {
        Swal.fire({
          title: '<p style="font-size:70%;">Account Type field cannot be blank</p>',
          icon: 'warning',
          confirmButtonText: 'Ok',
          allowOutsideClick: true,
          customClass : { 
            container : 'my-swal' 
          },
          timer: 5000
        })
        return false;
      }
      await axiosWithToken(functionBaseUrl + '/api/v3/createStudent', {
        method: 'post',
        data: output
      }).then(response => {
        if (response.status === 201) {
          Swal.fire({
            title: '<p style="font-size:70%;">' + response.data.message + '</p>',
            icon: 'warning',
            confirmButtonText: 'Ok',
            allowOutsideClick: true,
            customClass : { 
              container : 'my-swal' 
            },
          })
        } else {
          this.setState({ students: response.data, loading: false });
          Swal.fire({
            title: '<p style="font-size:70%;">An account has been successfully created for ' + output.email + '.</p>',
            icon: 'success',
            confirmButtonText: 'Ok',
            customClass : { 
              container : 'my-swal' 
            },
            allowOutsideClick: true,
            timer: 3000
          }).then(() => {
            this.props.onClose()
            this.setState(this.getInitialState())
            window.location.reload();
          })
        }
      })
        .catch(err => {
          console.log(err)
        });
      this.props.onClose()
    }
  }

  handleHeightInput(e, type) {
    this.setState({ [type]: e.target.value });
  }

  render() {
    return (
      <Dialog
        fullWidth
        open={this.props.open}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id={"form-dialog-title"}>
          <div>
            Enter User Details
            <CloseIcon
              style={{
                float: "right",
                marginTop: "-10px",
                marginRight: "-15px",
              }}
              onClick={() => {
                this.props.onClose();
                this.setState(this.getInitialState());
              }}
            />
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container component="main" maxWidth="xs">
            <Grid item sm={12}>
              <Typography variant="h5" color="primary">
                User Details
              </Typography>
            </Grid>
            <Grid container spacing={1}>
              {this.state.currentStep === "newStudent" && (
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    required
                    label="First Name"
                    name="firstName"
                    margin="dense"
                    onChange={this.handleChange("firstName")}
                    value={this.state.firstName}
                    variant="outlined"
                  />
                </Grid>
              )}
              {this.state.currentStep === "newStudent" && (
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    required
                    label="Last Name"
                    name="lastName"
                    margin="dense"
                    onChange={this.handleChange("lastName")}
                    value={this.state.lastName}
                    variant="outlined"
                  />
                </Grid>
              )}
              <Grid item sm={6}>
                <TextField
                  fullWidth
                  required
                  disabled={
                    this.state.currentStep === "newStudent" ||
                    this.state.currentStep === "upgrade"
                  }
                  label="Email"
                  name="email"
                  margin="dense"
                  onChange={this.handleChange("email")}
                  value={this.state.email.toLowerCase().replace(/ /g, "")}
                  variant="outlined"
                />
              </Grid>
              {this.props.instructors?.length && (
                <Grid item xs={6}>
                  <FormControl variant="outlined" fullWidth margin="dense">
                    <InputLabel id="height-label">Coach*</InputLabel>
                    <Select
                      id="coach"
                      label="Coach"
                      labelId="coach-label"
                      name="coach"
                      onChange={this.handleChange("coach")}
                      value={this.state.coach}
                      variant="outlined"
                    >
                       {this.props.instructors.sort((a,b) => (a.firstName > b.firstName) ? 1 : ((b.firstName > a.firstName) ? -1 : 0)).map(item =>
                        <MenuItem value={item.id}>{item.firstName} {item.lastName} ({item.email})</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {this.state.currentStep === "newStudent" && (
                <Grid item sm={6}>
                  <FormControl variant="outlined" fullWidth margin="dense">
                    <InputLabel id="height-label">Height*</InputLabel>
                    <Select
                      id="height"
                      label="Height"
                      labelId="height-label"
                      name="height"
                      onChange={this.handleChange("height")}
                      value={this.state.height}
                      variant="outlined"
                    >
                      {heightValues.map((el) => (
                        <MenuItem value={el}>{el}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {this.state.currentStep === "newStudent" && (
                <Grid item sm={6}>
                  <FormControl variant="outlined" fullWidth margin="dense">
                    <InputLabel id="gender-select">Gender*</InputLabel>
                    <Select
                      id="gender"
                      label="Gender*"
                      labelId="gender-select"
                      name="gender"
                      value={this.state.gender.toUpperCase()}
                      onChange={this.handleChange("gender")}
                    >
                      <MenuItem value={"FEMALE"}>Female</MenuItem>
                      <MenuItem value={"MALE"}>Male</MenuItem>
                      <MenuItem value={"PREFER NOT TO ANSWER"}>
                        Prefer not to answer
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {this.state.currentStep === "newStudent" &&
                this.state.gender !== "" &&
                this.state.height !== "" && (
                  <Grid item sm={6}>
                    <FormControl variant="outlined" fullWidth margin="dense">
                      <InputLabel id="hipWidth-label">Hip Width*</InputLabel>
                      <Select
                        id="hipWidth"
                        label="HipWidth"
                        labelId="hipWidth-label"
                        name="hipWidth"
                        onChange={this.handleChange("hipWidth")}
                        value={this.state.hipWidth}
                        variant="outlined"
                      >
                        {(() => {
                          if (this.state.height) {
                            switch (this.state.gender) {
                              case "MALE":
                                return maleHeightToHipWidth[
                                  this.state.height
                                ].map((el) => (
                                  <MenuItem value={el}>{el}</MenuItem>
                                ));
                              case "FEMALE":
                                return femaleHeightToHipWidth[
                                  this.state.height
                                ].map((el) => (
                                  <MenuItem value={el}>{el}</MenuItem>
                                ));
                              case "PREFER NOT TO ANSWER":
                                return defaultHeightToHipWidth[
                                  this.state.height
                                ].map((el) => (
                                  <MenuItem value={el}>{el}</MenuItem>
                                ));
                              default:
                                break;
                            }
                          }
                        })()}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              {this.state.currentStep === "newStudent" && (
                <Grid item sm={6}>
                  <FormControl variant="outlined" fullWidth margin="dense">
                    <InputLabel id="domHand-select">Dominant Hand*</InputLabel>
                    <Select
                      id="domHand"
                      label="Dominant Hand*"
                      labelId="domHand-select"
                      name="domHand"
                      value={this.state.dominantHand}
                      onChange={this.handleChange("dominantHand")}
                    >
                      <MenuItem value={"Left"}>Left</MenuItem>
                      <MenuItem value={"Right"}>Right</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {(this.state.currentStep === "newStudent" ||
                this.state.currentStep === "upgrade") && (
                <Grid item sm={6}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    disabled={this.props.plan === "free"}
                  >
                    <InputLabel id="gender-select">Account Type*</InputLabel>
                    <Select
                      id="subPlan"
                      label="Account Type*"
                      labelId="subPlan-select"
                      name="subPlan"
                      value={
                        this.props.plan === "free"
                          ? "development_sportsbox_free_student"
                          : this.state.subPlan
                      }
                      onChange={this.handleChange("subPlan")}
                    >
                      {this.props.plan === "free" && (
                        <MenuItem value={"development_sportsbox_free_student"}>
                          Free
                        </MenuItem>
                      )}
                      <MenuItem
                        value={"development_sportsbox_student_lite_monthly"}
                      >
                        3D Practice Monthly
                      </MenuItem>
                      <MenuItem
                        value={"development_sportsbox_student_lite_annually"}
                      >
                        3D Practice Annually
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {(this.state.currentStep === "newStudent" ||
                this.state.currentStep === "upgrade") &&
                this.state.subPlan.includes("lite") &&
                this.props.plan !== "free" && (
                  <Grid item sm={6}>
                    <TextField
                      fullWidth
                      label="Subscription Period*"
                      name="trialPeriod"
                      margin="dense"
                      onChange={this.handleChange("trialPeriod")}
                      value={this.state.trialPeriod}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {this.state.subPlan.includes("annually")
                              ? "years"
                              : "months"}
                          </InputAdornment>
                        ),
                        inputProps: { min: 0 },
                      }}
                      type="number"
                      variant="outlined"
                    />
                    {this.state.trialPeriod !== "" &&
                      this.state.trialPeriod <= 0 && (
                        <Typography style={{ color: "red" }}>
                          Subscription Period has to be greater than 0
                        </Typography>
                      )}
                  </Grid>
                )}
                {(this.state.currentStep === "newStudent" ||
                  this.state.currentStep === "upgrade") &&
                  this.state.subPlan.includes("lite") &&
                  this.props.plan !== "free" && (
                  <Grid item sm={6}>
                    <FormControl variant="outlined" fullWidth margin="dense">
                      <InputLabel id="autoRenewal-select">Auto Renewal*</InputLabel>
                      <Select
                        id="autoRenewal"
                        label="Auto Renewal*"
                        labelId="autoRenewal-select"
                        name="autoRenewal"
                        value={this.state.autoRenewal}
                        onChange={this.handleChange("autoRenewal")}
                      >
                        <MenuItem value={true}>On</MenuItem>
                        <MenuItem value={false}>Off</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClear} color="primary">
            Clear
          </Button>
          <Button
            onClick={this.handleSubmit}
            color="secondary"
            disabled={this.getDisabled()}
          >
            {this.submitButton()}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

CreateStudent.propTypes = {
  item: PropTypes.object,
  open: PropTypes.bool,
  closeCreatePopUp: PropTypes.func,
  submitQuery: PropTypes.func,
  values: PropTypes.object,
};

export default CreateStudent;
