import React, { useEffect, useState } from "react";
import { getWatchlistCsvData } from "../../../api_test/common";
import { EmptySelector } from "../../../styled";
import { FilledWatchlistTrackers } from "../../practiceGuide/FilledWatchlistTrackers";
import { WatchlistModal } from "../../practiceGuide/WatchlistModal";
import { ModalContentTitle } from "../../styled";

export function PracticeGuideWatchlist({
  form,
  handleSelectors,
  handleRemove,
}) {
  const [modal, setModal] = useState({ open: false, init: [] });
  const [watchlistData, setWatchlistData] = useState({
    popularWatchlistsData: [],
    trackersData: [],
    rangesData: [],
    positionsData: [],
  });

  useEffect(() => {
    const fetch = async () => {
      const wl = await getWatchlistCsvData();
      setWatchlistData(wl);
    };
    fetch();
  }, []);

  return (
    <>
      <ModalContentTitle>Watchlist</ModalContentTitle>
      {form.length === 0 ? (
        <EmptySelector onClick={() => setModal({ open: true, init: [] })}>
          Add Watchlist
        </EmptySelector>
      ) : (
        <FilledWatchlistTrackers
          items={form}
          type="watchlist"
          title="Watchlist Trackers"
          onAdd={() => setModal({ open: true, init: [] })}
          onRemove={handleRemove}
        />
      )}
      <WatchlistModal
        open={modal.open}
        init={modal.init}
        watchlistData={watchlistData}
        onClose={() => setModal({ open: false, init: [] })}
        onSave={handleSelectors}
      />
    </>
  );
}
