import React from "react";
import { Select, MenuItem } from "@material-ui/core";

export function PlatformSelect({ value, onChange }) {
    const platformSelectLabels = {
        Android: "Android",
        iPhone: "iPhone",
        Cloud: "Cloud",
    };

    const handleOnChange = (e) => {
        onChange(e.target.value, "platform");
    };

    return (
        <Select
            labelId="platformSelect"
            name="platformSelect"
            onChange={handleOnChange}
            value={value}
        >
            <MenuItem value="Android">{platformSelectLabels.Android}</MenuItem>
            <MenuItem value="iPhone">{platformSelectLabels.iPhone}</MenuItem>
            <MenuItem value="Cloud">{platformSelectLabels.Cloud}</MenuItem>
        </Select>
    );
}
