import React, { useState, useEffect } from 'react';

import { CircularProgress, Dialog, DialogTitle, DialogContent, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { functionBaseUrl, axiosWithToken } from '../../../../common/firebase';
import DisplaySessionTable from '../../../session_details/components/display/DisplaySessionTable';

function DisplayStudentsSessionsTableComponent({ open, student, close }) {
  const [sessions, setSessions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const loadData = async () => {
    if (student?.id) {
      setIsLoading(true);
      const response = await axiosWithToken(`${functionBaseUrl}/api/sessions/${student.id}`, {
        method: 'get',
      });
      setSessions(response.data);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    loadData();
  }, [student]);

  return (<>
    <Dialog
      fullWidth
      open={open}
      onClose={close}
      aria-labelledby='form-dialog-title'>
      <DialogTitle id={'form-dialog-title'}>
        <div>
          {student?.name} sessions
          <CloseIcon style={{ float: 'right', marginTop: '-10px', marginRight: '-15px' }} onClick={close} />
        </div>
      </DialogTitle>
      <DialogContent>
        <Grid container component="main" maxWidth="xs">
          <Grid container spacing={2}>
            {isLoading && (<>
              <Grid style={{ textAlign: "center", padding: 200 }}>
                <CircularProgress />
              </Grid>
            </>)}
            {!isLoading && (<>
              <Grid item xs={12}>
                <DisplaySessionTable sessions={sessions} cameraSetups={[]} />
              </Grid>
            </>)}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  </>);
}

export const DisplayStudentsSessionsTable = DisplayStudentsSessionsTableComponent;