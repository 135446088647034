import React from "react";
import { Dialog, DialogContent, Button } from "@material-ui/core";

export function ChangeStudentPrompt({ open, func, onClose }) {
  const onYes = () => {
    func();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <div>
          Are you sure you want to change a student? If you change the student
          the session will be closed.
        </div>
        <Button onClick={onClose}>No</Button>
        <Button onClick={onYes}>Yes</Button>
      </DialogContent>
    </Dialog>
  );
}
