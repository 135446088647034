import React, { Component } from 'react';
import { createMuiTheme, MuiThemeProvider, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';

import { forwardRef } from 'react';
 
import {AddBox, ArrowDownward, Check, ChevronLeft, ChevronRight, Clear, DeleteOutline, Edit, FilterList, FirstPage, LastPage, Remove, SaveAlt, Search, ViewColumn} from '@material-ui/icons';
import palette from '../../../../theme/palette';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const tableHeads = {labelingResults: [
  {field: 'name', title: 'Name'},
]}

const theme = createMuiTheme({
  typography: {
    h6: {
      fontWeight: 500,
      fontSize: '20px',
      letterSpacing: '-0.05px',
      lineHeight: '20px',
    }
  },
  palette: {
    secondary: {
      main: palette.primary.main,
    },
  },
})

class DisplayLabResTable extends Component {
  constructor (props) {
    super(props);
    this.state = {
      selectedRows: {
        labelingResults: []
      }
    }
  }

  getTableHeader = (name) => {
    return tableHeads[name];
  }

  render(){
    let labResData = [];
    if (this.props.labelingResults) {
      const keys = Object.keys(this.props.labelingResults)
      labResData = keys.map((key) => {
        if(this.props.labelingResults[key]) {
          const labelingResult = this.props.labelingResults[key];
          const out = {
            id: key,
            name: labelingResult.name,
          }
          return out;
        }
        return null;
      })
    }
    const tableElements = [];
    if(labResData.length > 0) {
      tableElements.push(
        <MaterialTable
          style={{marginBottom: '10px'}}
          icons={tableIcons}
          key='labResTable'
          title='Labeling Results'
          columns={this.getTableHeader('labelingResults')}
          data={labResData.map(row => this.state.selectedRows.labelingResults.find(selected => selected.id === row.id) ? {...row, tableData: { checked: true}} : row)}
          options={{
            selection: true
          }}
        />)
    }
    if (tableElements.length < 1) {
      return (<Typography align='center' style={{marginTop: '20px'}}>There are no items to display</Typography>)
    }
    return (
    <MuiThemeProvider theme={theme}>
      {tableElements}
    </MuiThemeProvider>)
  }
}
DisplayLabResTable.propTypes = {
  labelingResults: PropTypes.object,
  searchOrder: PropTypes.string,
  compress: PropTypes.bool
}
export default DisplayLabResTable