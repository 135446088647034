import React, { useState, useEffect } from 'react';

import { Box, Button, Card, CardContent, CircularProgress, Container, IconButton, Grid, TextField, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link as RouterLink } from 'react-router-dom';
import Swal from 'sweetalert2';
import InputMask from 'react-input-mask';
import axios from 'axios';

import firebase, { functionBaseUrl } from '../../../common/firebase';
import { getPlan } from '../../../common/plans';

function UpdatePaymentComponent({ history }) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const [streetName, setStreetName] = useState('');
  const [zip, setZip] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [currentSub, setCurrentSub] = useState({});

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };
  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };
  const handleCardNumberChange = (event) => {
    setCardNumber(event.target.value);
  };
  const handleExpirationDateChange = (event) => {
    setExpirationDate(event.target.value);
  };
  const handleStreetNameChange = (event) => {
    setStreetName(event.target.value);
  };
  const handleZipChange = (event) => {
    setZip(event.target.value);
  };

  const getCurrentUser = async () => {
    try {
      const firebaseUser = firebase.auth().currentUser;
      const user = await firebase.firestore().collection('enterpriseAccounts').where('userId', '==', firebaseUser.uid).get();
      let userData = [];
      user.forEach(function (doc) {
        userData.push({ ...doc.data(), id: doc.id });
      });
      const sub = await firebase.firestore().collection('subscriptions').where('userId', '==', firebaseUser.uid).get();
      let subData = [];
      sub.forEach(function (doc) {
        subData.push({ ...doc.data(), id: doc.id });
      });
      if (userData.length > 0 && subData.length > 0 && subData[0].endDate > Date.now()) {
        setCurrentSub(subData[0]);
      } else {
        alert('You do not have active subscription');
        history.push('/account');
      }
      setIsLoading(false);
    } catch (error) {
      alert('You are not authorized to access this page');
      history.push('/dashboard');
    }
  }

  const checkAutoRenewal = () => {
    if (currentSub.productId?.includes('free')) {
      return "";
    }
    if (JSON.stringify(currentSub) !== undefined && JSON.stringify(currentSub) !== '{}') {
      if (currentSub.autoRenewal) {
        return "On";
      }
    }
    return "Off";
  }

  const checkSubPlatform = () => {
    let platform = ""
    if (JSON.stringify(currentSub) !== undefined && JSON.stringify(currentSub) !== '{}') {
      if (currentSub.platform) {
        platform = currentSub.platform
      }
    }
    return platform;
  }

  const getSubEndDate = () => {
    let date = ""
    if (JSON.stringify(currentSub) !== undefined && JSON.stringify(currentSub) !== '{}') {
      if (currentSub.endDate) {
        date = dateFormatter(currentSub.endDate);
      }
    }
    return date;
  }

  const dateFormatter = (date) => {
    let dd = new Date(date * 1)
    let res = dd.toJSON()
    if (res) {
      res = res.substr(0, 10)
      res = res.replace(/(\d{4})-(\d{1,2})-(\d{1,2})/, function (match, y, m, d) {
        return m + '/' + d + '/' + y;
      });
    }
    return res;
  }

  const updateRecurring = async () => {
    setIsLoading(true);
    try {
      const resp = await axios(`${functionBaseUrl}/api/updateRecurring`, {
        method: 'POST',
        data: {
          ssl_recurring_id: currentSub.transactionId,
          ssl_card_number: cardNumber,
          ssl_exp_date: expirationDate,
          ssl_first_name: firstName,
          ssl_last_name: lastName,
          ssl_avs_address: streetName,
          ssl_avs_zip: zip,
        }
      });

      if (resp.success) {
        await Swal.fire({
          title: '<p style="font-size:70%;">Update processed successfuly</p>',
          icon: 'success',
          confirmButtonText: 'Ok',
          allowOutsideClick: true,
          timer: 3000
        });
      }
      setIsLoading(false);
    } catch (error) {
      Swal.fire({
        title: '<p style="font-size:70%;">Error occurred</p>',
        icon: 'error',
        confirmButtonText: 'Ok',
        allowOutsideClick: true,
      });
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getCurrentUser();
  }, [])

  return (<>
    {isLoading && (
      <Container maxWidth="xs" style={{ textAlign: 'center', paddingTop: '50px' }}>
        <CircularProgress />
      </Container>
    )}
    {!isLoading && (
      <Container
        component="main"
        maxWidth="xs"
        style={{ paddingTop: '50px' }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            style={{ paddingBottom: 20 }}
          >
            <RouterLink to="/account">
              <IconButton
                edge="start"
              >
                <ArrowBackIcon />
              </IconButton>
            </RouterLink>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <Box border={1} borderColor="primary.main" style={{ width: "100%", marginBottom: "5%" }}>
              <Card>
                <CardContent fullWidth>
                  <Grid container justify="space-between">
                    <Typography
                      inline
                      component="h6"
                      variant="h6"
                      align="left"
                    >
                      <b>Current Plan:</b>
                    </Typography>
                    <Typography
                      inline
                      component="h6"
                      variant="h6"
                      align="right"
                    >
                      {getPlan(currentSub.productId)}
                    </Typography>
                  </Grid>
                  <Grid container justify="space-between">
                    <Typography
                      inline
                      component="h6"
                      variant="h6"
                      align="left"
                    >
                      <b>Auto Renewal:</b>
                    </Typography>
                    <Typography
                      inline
                      component="h6"
                      variant="h6"
                      align="right"
                    >
                      {checkAutoRenewal()}
                    </Typography>
                  </Grid>
                  <Grid container justify="space-between">
                    <Typography
                      inline
                      component="h6"
                      variant="h6"
                      align="left"
                    >
                      <b>Subscription Platform:</b>
                    </Typography>
                    <Typography
                      inline
                      component="h6"
                      variant="h6"
                      align="right"
                    >
                      {checkSubPlatform()}
                    </Typography>
                  </Grid>
                  <Grid container justify="space-between">
                    <Typography
                      inline
                      component="h6"
                      variant="h6"
                      align="left"
                    >
                      <b>Subscription End Date:</b>
                    </Typography>
                    <Typography
                      inline
                      component="h6"
                      variant="h6"
                      align="right"
                    >
                      {getSubEndDate()}
                    </Typography>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
          >
            <TextField
              fullWidth
              required
              label="First Name"
              onChange={handleFirstNameChange}
              value={firstName}
              variant="outlined"
              inputProps={{ maxLength: 30 }}
            />
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
          >
            <TextField
              fullWidth
              required
              label="Last Name"
              onChange={handleLastNameChange}
              value={lastName}
              variant="outlined"
              inputProps={{ maxLength: 30 }}
            />
          </Grid>

          <Grid
            item
            xs={12}
          >
            <InputMask
              mask="9999 9999 9999 9999"
              label='Card Number'
              required
              onChange={handleCardNumberChange}
              type="text"
              value={cardNumber}
            >
              {() => <TextField variant="outlined" fullWidth label='Card Number *' />}
            </InputMask>
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
          >
            <InputMask
              mask="99/99"
              fullWidth
              required
              onChange={handleExpirationDateChange}
              value={expirationDate}
              type="text"
              inputProps={{ maxLength: 5 }}
            >
              {() => <TextField fullWidth variant="outlined" label="Expiration Date *" />}
            </InputMask>
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
          >
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
          >
            <TextField
              fullWidth
              required
              label="Street name"
              onChange={handleStreetNameChange}
              value={streetName}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
          >
            <TextField
              fullWidth
              required
              label="Zip Code"
              onChange={handleZipChange}
              value={zip}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <Button
              color="primary"
              fullWidth
              type="button"
              variant="contained"
              onClick={() => { updateRecurring() }}
            >
              Update
            </Button>
          </Grid>
        </Grid>
      </Container>
    )}
  </>);
}

export const UpdatePayment = UpdatePaymentComponent;
