import React, { Component } from 'react';
import { Grid, TextField, Link, Button, InputAdornment, IconButton, Container, FormControlLabel, Checkbox, Typography, MenuItem } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { Google as GoogleIcon } from '../../../icons';
import firebase, { axiosWithToken, functionBaseUrl, loginWithGoogle } from '../../../common/firebase'
import InputMask from 'react-input-mask';
import './calendar.css';
import Swal from 'sweetalert2';

const useStyles = theme => ({
  paper: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(-1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  buttonLink: {  // This is a link component surrounding a button component
    width: '100%'
  },
  linkedButton: {  // This is a button component surrounded by a link
    margin: theme.spacing(2),
  },
  title: {
    textAlign: 'center',
    fontFamily: 'arial, sans-serif'
  }
});


const agreements = {
  tos: (<span>I have read and agree to Sportsbox's <Link onClick={(e) => {
                e.preventDefault();
                window.open(window.location.href.replace('sign-up', 'terms-of-service'))
              }}>Terms of Service
              </Link></span>),
  privacy: (<span>I have read and agree to Sportsbox's <Link onClick={async (e) => {
                e.preventDefault();
                window.open(window.location.href.replace('sign-up', 'privacy'))
              }}>Privacy Policy.
              </Link></span>),
  /*upload: (<span>Sportsbox.ai uses the videos that users upload to improve its 
      3D motion capture and analysis, powered by AI, and thereby provide 
      better coaching. Do you agree to allow Sportsbox.ai to use your 
      uploaded videos to provide better guidance and instruction?
    </span>)*/
}

const passwordRequirements = {
  length: {text: 'At least 8 characters', rule: (password) => password.length >= 8},
  number: {text: 'At least one number', rule: (password) => /[0-9]/.test(password)},
  special: {text: 'A special character (e.g., !@#$%&*)', rule: (password) => /[.!@#$%&*]/.test(password)},
}

class UserInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      agreements: {
        tos: false,
        privacy: false,
        upload: true,
      },
      userInfo: {
        firstName: '',
        lastName: '',
        email: '',
        dob: ''
      },
      loggedInWithGoogle: false,
      password: '',
      confirmPassword: '',
      errors: {}
    }
    this.fields = {
      firstName: { label: 'First name', size: 6, required: true},
      lastName: { label: 'Last name', size: 6, required: true},
      email: { label: 'Email', size: 12, required: true},
      dob: { label: 'Date of Birth', size: 12, required: true, type: 'date'},
      gender: { label: 'Gender', size: 6, showFree: true, type: 'select', options: [['MALE', 'Male'], ['FEMALE', 'Female'], ['PREFER NOT TO ANSWER', 'Prefer not to answer']]},
      dominantHand: { label: 'Dominant Hand', size: 6, showFree: true, type: 'select', options: [['Right', 'Right'], ['Left', 'Left']]},
      height: { label: `Height (e.g. 5'5")`, size: 6, showFree: true, type: 'mask', mask: `9'99"`},
    }
  }
  handleChange = (prop) => (e) => {

    this.setState({ [prop]: e.target.value, errors: {...this.state.errors, [prop]: null} });
  }
  handleUserInfoChange = (prop) => (e) => {
    this.setState({ userInfo: { ...this.state.userInfo, [prop]: e.target.value } });
  }
  validateEmail = (email) => {
    const verify = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    return verify.test(email)
  }
  validatePassword = (password) => {
    // const verify = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    // return verify.test(password);
    return Object.values(passwordRequirements).every(({rule}) => rule(password))
  }
  //Returns true if age is valid
  validateAge = (dob) => {
    const date = new Date()
    date.setFullYear(date.getFullYear() - 13)
    return Date.parse(dob) < date
  }
  handleSubmit = async (e) => {
    e.preventDefault()
    const { password, confirmPassword, userInfo, agreements } = this.state;
    let errors = {};
    if (!this.state.loggedInWithGoogle) {
      if (password !== confirmPassword) {
        errors.password = 'Passwords do not match';
      }
      if (password.length < 8 || !this.validatePassword(password)) {
        errors.password = 'Invalid password';
      }
      if (!this.validateEmail(userInfo.email)) {
        errors.email = 'Invalid email';
      } else {
        const methods = await firebase.auth().fetchSignInMethodsForEmail(userInfo.email);
        if (methods.length > 0) {
          errors.email = 'Email already in use';
        }
      }
    }
    if (!this.validateAge(userInfo.dob)) {
      Swal.fire({
        title: '<p style="font-size:70%;">Sorry, you are not eligible for a Sportsbox.ai account at this time.</p>',
        confirmButtonText: 'Ok',
        allowOutsideClick: false
      })
      .then(() => {
        window.location.href = "/welcome"
      })
    }
    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    } else {
      this.setState({ errors: {}})
    }
    userInfo.userConsent = this.state.agreements.upload
    this.props.handleSubmit(userInfo, password)
  }

  handleGoogleSignIn = async () => {
    try {
      const creds = await loginWithGoogle();
      const displayName = creds.user.displayName.split(' ');
      const firstName = displayName?.[0];
      displayName.splice(0, 1);
      this.setState({
        userInfo: {
          uid: creds.user.uid,
          email: creds.user.email,
          firstName: firstName,
          lastName: displayName.join(' '),
        },
        loggedInWithGoogle: true,
      });
      axiosWithToken(`${functionBaseUrl}/api/users/${creds.user.uid}`, {
        method: 'get',
      }).then(response => {
        this.props.history.push('/dashboard');
      }).catch(err => {
        console.log(err)
      });
    } catch (error) {
      this.handleLoginError(error)
    }
  }

  handleLoginError = (err) => {
    let message = 'An error occured while logging in with Google, please sign up using an email and password'
    if (err.code === 'auth/popup-blocked') {
      message = 'Popup was blocked by browser'
    } else if (err.code === 'auth/popup-closed-by-user') {
      message = 'Popup was closed prematurely'
    } else if (err.code === 'auth/account-exists-with-different-credential') {
      message = 'An account already exists with this email, did you sign up using a different platform?'
    }
    else {
      console.log(err);
    }
    Swal.fire({
      title: '<p style="font-size:70%;">Sign Up Error</p>',
      html: `${message}`,
      confirmButtonText: 'Ok'
    })
  }

  getField = (key, config) => {
    if (config.type === 'date' || !config.type) {
      return (<TextField
        InputLabelProps={{
          shrink: config.type === 'date' ? true : undefined,
        }}
        fullWidth
        label={config.label}
        required={config.required}
        type={config.type}
        variant="outlined"
        value={this.state.userInfo[key] || ''}
        onChange={this.handleUserInfoChange(key)}
        error={Boolean(this.state.errors[key])}
        helperText={this.state.errors[key]}
      />)
    } else if (config.type === 'select') {
      return (<TextField
        fullWidth
        label={config.label}
        required={config.required}
        select
        variant="outlined"
        value={this.state.userInfo[key] || ''}
        onChange={this.handleUserInfoChange(key)}
        error={Boolean(this.state.errors[key])}
        helperText={this.state.errors[key]}
      >
        {config.options.map((option) => (
          <MenuItem value={option[0]} key={option[0]}>{option[1]}</MenuItem>
        ))}
        <MenuItem value={''}>None</MenuItem>
      </TextField>)
    } else if (config.type === 'mask') {
      return (
        <InputMask
          mask={config.mask}
          required={config.required}
          onChange={this.handleUserInfoChange(key)}
          type="text"
          value={this.state.userInfo[key] || ''}
        >
          {() => <TextField variant="outlined" fullWidth label={config.label} />}
        </InputMask>)
    }
  }

  render() {
    return (<Container maxWidth="xs" style={{marginTop: 50, paddingBottom: 100}}>
      <form onSubmit={this.handleSubmit}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            style={{ paddingBottom: 10 }}
          >
            <IconButton
              edge="start"
              onClick={()=>this.props.setStage('plans')}
            >
              <ArrowBackIcon />
            </IconButton>
          </Grid>
          {Object.entries(this.fields).map(([key, value]) => (
            <Grid item xs={value.size} key={key} style={{display: this.props.selectedPlan !== 'free' && value.showFree ? 'none' : ''}}>
              {this.getField(key, value)}
            </Grid>
          ))}
          
          <Grid
            item
            xs={12}
            style={{ display: this.state.loggedInWithGoogle ? 'none' : 'block'}}
          >
            <TextField
              InputProps={{
                endAdornment:(
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                      onClick={() => this.setState({ showPassword: !this.state.showPassword })}
                    >
                      {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              required={!this.state.loggedInWithGoogle}
              label='Password'
              variant="outlined"
              fullWidth
              onChange={this.handleChange('password')}
              type={this.state.showPassword ? 'text' : 'password'}
              value={this.state.password}
              error={Boolean(this.state.errors.password)}
              helperText={this.state.errors.password}
            />
            {Object.entries(passwordRequirements).map(([key, value]) => (
              <Typography 
                key={key} 
                variant="body2" 
                color={this.state.password.length === 0 || value.rule(this.state.password) ? 'textPrimary' : 'error'}>
                  • {value.text}
              </Typography>
            ))}
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: this.state.loggedInWithGoogle ? 'none' : 'block'}}
          >
            <TextField
              InputLabelProps={{
                htmlFor:"outlined-adornment-password"
              }}
              required={!this.state.loggedInWithGoogle}
              label='Confirm Password'
              variant="outlined"
              fullWidth
              onChange={this.handleChange('confirmPassword')}
              type={this.state.showPassword ? 'text' : 'password'}
              value={this.state.confirmPassword}
              error={this.state.confirmPassword.length > 0 && this.state.password !== this.state.confirmPassword}
              helperText={this.state.confirmPassword.length > 0 && this.state.password !== this.state.confirmPassword ? "Passwords do not match" : ""}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: this.state.loggedInWithGoogle ? 'none' : 'block'}}
          >
            <Typography align="center">or</Typography>
            <Button
              size="large"
              variant="contained"
              onClick={this.handleGoogleSignIn}
              fullWidth
            >
              <GoogleIcon style={{marginRight: 5}}/>
              Sign up with Google
            </Button>
          </Grid>
          {Object.entries(agreements).map(([key, value]) => (
            <Grid
              item
              xs={12}
              key={key}
              style={{paddingLeft: 20}}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    style={{padding: 0, paddingRight: 5}}
                    required={key !== 'upload'}
                    color="primary"
                    onClick={() => this.setState({ agreements: { ...this.state.agreements, [key]: !this.state.agreements[key] } })}
                    checked={this.state.agreements[key]}
                  />
                }
                label={value}
              />
            </Grid>
          ))}
          <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              type='submit'
              disabled={!this.state.agreements.privacy || !this.state.agreements.tos}
            >Next</Button>
          </Grid>
          <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
            <Link
              component={RouterLink}
              to="/sign-in"
              variant="body2"
            >
              {'Already have an account? Sign in'}
            </Link>
          </Grid>
        </Grid>
      </form>
    </Container>)
  }
}
UserInfo.propTypes = {
};

export default withRouter((UserInfo));
