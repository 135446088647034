import styled from "styled-components";

export const EqupmentItemContent = styled.div`
  padding: 10px;
  border: 1px solid #c9c9c9;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
`;

export const SummaryItemContainer = styled.div`
  display: flex;
  height: 100px;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.25);
  padding: 0 20px;
`;

export const FilledSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* margin-bottom: 10px; */
`;

export const FilledSummaryItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0 10px 10px;
`;

export const FilledSummaryItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 10px 20px;
  background: #fff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin: 5px 0;
`;

export const FilledSummaryItemsSubContainer = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  justify-content: space-between;
`;

export const FilledSummaryItemReps = styled.div`
  font-weight: bold;
  padding: 5px 10px;
  border: gray 1px solid;
  border-radius: 10%;
`;

export const FilledSummaryTitleContainer = styled.div`
  display: flex;
  margin: 10px 0;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
`;

export const WatchlistItemContainer = styled.div`
  display: flex;
  border-radius: 7px;
  background: #e4e4e4;
  padding: 15px;
  cursor: pointer;
`;

export const WatchlistItemSubContainer = styled.div`
  padding: 0 15px;
  width: 100%;
`;

export const WatchlistFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  margin: 20px 0;
`;

export const WatchlistTrackerContainer = styled.div`
  background: #fff;
  border-radius: 4px;
  display: flex;
  width: 100%;
  padding: 10px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.25);
`;

export const WatchlistSelectedTrackersItem = styled.div``;

export const FilledWatchlistName = styled.div`
  font-weight: bold;
`;
