import React, { useCallback, useEffect, useState } from "react";
import { withStyles } from "@material-ui/styles";
import {
    Container,
    CircularProgress,
    Avatar,
    Typography,
    Tabs,
    Tab,
    Button,
} from "@material-ui/core";
import TocIcon from "@material-ui/icons/Toc";
import MaterialTable from "material-table";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import AddCircleIcon from "@material-ui/icons/Add";

import {
    useStyles,
    theme,
    tableOptions,
    tutorialsTableColumns,
    practiceGuideTableColumns,
} from "./config";
import {
    createPracticeGuide,
    createTipsAndDrillsVideo,
    deletePracticeGuide,
    deleteTipsAndDrillsVideo,
    updatePracticeGuide,
    getPracticeGuides,
    getTipsAndDrills,
    updateTipsAndDrillsVideo,
    getTutorials,
    addTutorial,
    editTutorial,
    deleteTutorial,
    deleteTesting,
} from "./api";
import { FlexWrap, Pad } from "../../components";
import { PracticeGuideModal } from "./components/practiceGuide";
import { TipsAndDrillsModal, TutorialModal } from "./components";
import { DeletePrompt } from "./components/DeletePrompt";
import { test } from "./api_old";

export const ContentManagement = (props) => {
    const [tables, setTables] = useState({
        practiceGuides: null,
        tipsAndDrills: null,
        tutorials: null,
    });
    const [loading, setLoading] = useState(false);
    const [practiceGuideModal, setPracticeGuideModal] = useState({
        open: false,
        type: "create",
        init: null,
    });
    const [tipsAndDrillsModal, setTipsAndDrillsModal] = useState({
        open: false,
        init: null,
        type: "create",
    });
    const [tutorialsModal, setTutorialsModal] = useState({
        open: false,
        init: null,
        type: "create",
    });
    const [deletePrompt, setDeletePrompt] = useState({
        open: false,
        func: () => null,
    });
    const [tab, setTab] = useState("practiceGuides");

    useEffect(() => {
        setLoading(true);
        (async () => {
            const practiceGuides = await getPracticeGuides();
            const tipsAndDrills = await getTipsAndDrills();
            const tutorials = await getTutorials();
            setTables({ practiceGuides, tipsAndDrills, tutorials });
            setLoading(false);
        })();
    }, []);

    const handlePracticeGuideCreate = useCallback(
        async (data) => {
            setPracticeGuideModal((prev) => ({ ...prev, open: false }));
            setLoading(true);
            const newGuides = await createPracticeGuide(data);
            setTables((prev) => ({ ...prev, practiceGuides: newGuides }));
            setLoading(false);
        },
        [setLoading, setTables, setPracticeGuideModal]
    );

    const handlePracticeGuideEdit = useCallback(
        async (id, data) => {
            setPracticeGuideModal((prev) => ({ ...prev, open: false }));
            setLoading(true);
            const newGuides = await updatePracticeGuide(id, data);
            setTables((prev) => ({ ...prev, practiceGuides: newGuides }));
            setLoading(false);
        },
        [setPracticeGuideModal, setLoading, setTables]
    );

    const handlePracticeGuideDelete = useCallback(
        async (id) => {
            const func = async (guideId) => {
                setDeletePrompt((prev) => ({ ...prev, open: false }));
                setLoading(true);
                const newGuides = await deletePracticeGuide(guideId);
                setTables((prev) => ({ ...prev, practiceGuides: newGuides }));
                setLoading(false);
            };
            setDeletePrompt((prev) => ({
                ...prev,
                open: true,
                func: () => func(id),
            }));
        },
        [setTables, setLoading]
    );

    const handleTipsAndDrillsCreate = useCallback(
        async (data) => {
            setTipsAndDrillsModal((prev) => ({ ...prev, open: false }));
            setLoading(true);
            const newVids = await createTipsAndDrillsVideo(data);
            setTables((prev) => ({ ...prev, tipsAndDrills: newVids }));
            setLoading(false);
        },
        [setTipsAndDrillsModal, setTables, setLoading]
    );

    const handleTipsAndDrillsUpdate = useCallback(
        async (id, data) => {
            setTipsAndDrillsModal((prev) => ({ ...prev, open: false }));
            setLoading(true);
            const newVids = await updateTipsAndDrillsVideo(id, data);
            setTables((prev) => ({ ...prev, tipsAndDrills: newVids }));
            setLoading(false);
        },
        [setTipsAndDrillsModal, setTables, setLoading]
    );

    const handleTipsAndDrillsDelete = useCallback(
        async (id) => {
            const func = async (vidId) => {
                setDeletePrompt((prev) => ({ ...prev, open: false }));
                setLoading(true);
                const { tipsAndDrills, practiceGuides } =
                    await deleteTipsAndDrillsVideo(vidId);
                setTables((prev) => ({
                    ...prev,
                    tipsAndDrills,
                    practiceGuides,
                }));
                setLoading(false);
            };
            setDeletePrompt((prev) => ({
                ...prev,
                open: true,
                func: () => func(id),
            }));
        },
        [setTables, setLoading]
    );

    const handleTutorialCreate = useCallback(
        async (data) => {
            setTutorialsModal((prev) => ({ ...prev, open: false }));
            setLoading(true);
            const newTuts = await addTutorial(data);
            setTables((prev) => ({ ...prev, tutorials: newTuts }));
            setLoading(false);
        },
        [setTutorialsModal, setTables, setLoading]
    );

    const handleTutorialUpdate = useCallback(
        async (id, data) => {
            setTutorialsModal((prev) => ({ ...prev, open: false }));
            setLoading(true);
            const newTuts = await editTutorial(id, data);
            setTables((prev) => ({ ...prev, tutorials: newTuts }));
            setLoading(false);
        },
        [setTutorialsModal, setTables, setLoading]
    );

    const handleTutorialDelete = useCallback(
        async (id) => {
            const func = async (tutId) => {
                setDeletePrompt((prev) => ({ ...prev, open: false }));
                setLoading(true);
                const newTuts = await deleteTutorial(tutId);
                setTables((prev) => ({ ...prev, tutorials: newTuts }));
                setLoading(false);
            };
            setDeletePrompt((prev) => ({
                ...prev,
                open: true,
                func: () => func(id),
            }));
        },
        [setTables, setLoading, setDeletePrompt]
    );

    const handleTest = useCallback(async () => await test());

    const TabElements = () => {
        const value = () => {
            switch (tab) {
                case "practiceGuides":
                    return (
                        <MaterialTable
                            actions={[
                                {
                                    icon: () => <BorderColorIcon />,
                                    tooltip: "Edit",
                                    onClick: (event, rowData) => {
                                        setPracticeGuideModal({
                                            type: "edit",
                                            init: rowData,
                                            open: true,
                                        });
                                    },
                                },
                                {
                                    icon: () => <DeleteOutlineIcon />,
                                    tooltip: "Delete",
                                    onClick: (event, rowData) =>
                                        handlePracticeGuideDelete(rowData.uid),
                                },
                                {
                                    icon: () => <AddCircleIcon />,
                                    tooltip: "Add Practice Guide",
                                    isFreeAction: true,
                                    onClick: () =>
                                        setPracticeGuideModal({
                                            open: true,
                                            init: null,
                                            type: "create",
                                        }),
                                },
                            ]}
                            title="Practice guides"
                            style={{ marginTop: "10px", marginBottom: "10px" }}
                            key="coach"
                            columns={practiceGuideTableColumns}
                            data={tables.practiceGuides}
                            options={tableOptions}
                        />
                    );
                case "tipsAndDrills":
                    return (
                        <MaterialTable
                            actions={[
                                {
                                    icon: () => <BorderColorIcon />,
                                    tooltip: "Edit",
                                    onClick: (event, rowData) => {
                                        setTipsAndDrillsModal({
                                            open: true,
                                            init: rowData,
                                            type: "edit",
                                        });
                                    },
                                },
                                {
                                    icon: () => <DeleteOutlineIcon />,
                                    tooltip: "Delete",
                                    onClick: (event, rowData) =>
                                        handleTipsAndDrillsDelete(rowData.uid),
                                },
                                {
                                    icon: () => <AddCircleIcon />,
                                    tooltip: "Add Tips And Drills Video",
                                    isFreeAction: true,
                                    onClick: () =>
                                        setTipsAndDrillsModal({
                                            open: true,
                                            init: null,
                                            type: "create",
                                        }),
                                },
                            ]}
                            title="Tips & Drills"
                            style={{ marginTop: "10px", marginBottom: "10px" }}
                            key="coach"
                            columns={practiceGuideTableColumns}
                            data={tables.tipsAndDrills}
                            options={tableOptions}
                        />
                    );
                case "tutorials":
                    return (
                        <MaterialTable
                            actions={[
                                {
                                    icon: () => <BorderColorIcon />,
                                    tooltip: "Edit",
                                    onClick: (event, rowData) => {
                                        setTutorialsModal({
                                            open: true,
                                            init: rowData,
                                            type: "edit",
                                        });
                                    },
                                },
                                {
                                    icon: () => <DeleteOutlineIcon />,
                                    tooltip: "Delete",
                                    onClick: (event, rowData) =>
                                        handleTutorialDelete(rowData.id),
                                },
                                {
                                    icon: () => <AddCircleIcon />,
                                    tooltip: "Add Tutorial",
                                    isFreeAction: true,
                                    onClick: () =>
                                        setTutorialsModal({
                                            open: true,
                                            init: null,
                                            type: "create",
                                        }),
                                },
                            ]}
                            title="Tutorials"
                            style={{ marginTop: "10px", marginBottom: "10px" }}
                            key="coach"
                            columns={tutorialsTableColumns}
                            data={tables.tutorials}
                            options={tableOptions}
                        />
                    );
            }
        };
        return <>{value()}</>;
    };

    const classes = props.classes;
    return (
        <div>
            <Container className={classes.paper} component="main">
                <div style={{ width: "100%" }} className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <TocIcon />
                    </Avatar>
                    <Typography
                        component="h1"
                        variant="h4"
                        style={{ marginBottom: "10px" }}
                    >
                        CONTENT MANAGEMENT
                    </Typography>
                    <Tabs
                        value={tab}
                        onChange={(e, val) => setTab(val)}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                    >
                        <Tab value="practiceGuides" label="Practice Guides" />
                        <Tab value="tipsAndDrills" label="Tips And Drills" />
                        <Tab value="tutorials" label="Tutorials" />
                    </Tabs>
                    {/* <button onClick={handleTest}>test</button> */}
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <Pad width="100%">
                            <TabElements />
                            <PracticeGuideModal
                                onCreate={handlePracticeGuideCreate}
                                onEdit={handlePracticeGuideEdit}
                                onClose={() =>
                                    setPracticeGuideModal((prev) => ({
                                        ...prev,
                                        open: false,
                                    }))
                                }
                                init={practiceGuideModal.init}
                                open={practiceGuideModal.open}
                                type={practiceGuideModal.type}
                            />
                            <TipsAndDrillsModal
                                onCreate={handleTipsAndDrillsCreate}
                                onEdit={handleTipsAndDrillsUpdate}
                                onClose={() =>
                                    setTipsAndDrillsModal((prev) => ({
                                        ...prev,
                                        open: false,
                                    }))
                                }
                                init={tipsAndDrillsModal.init}
                                open={tipsAndDrillsModal.open}
                                type={tipsAndDrillsModal.type}
                            />
                            <TutorialModal
                                onCreate={handleTutorialCreate}
                                onEdit={handleTutorialUpdate}
                                onClose={() =>
                                    setTutorialsModal((prev) => ({
                                        ...prev,
                                        open: false,
                                    }))
                                }
                                init={tutorialsModal.init}
                                open={tutorialsModal.open}
                                type={tutorialsModal.type}
                            />
                            <DeletePrompt
                                onClose={() =>
                                    setDeletePrompt((prev) => ({
                                        ...prev,
                                        open: false,
                                    }))
                                }
                                {...deletePrompt}
                            />
                        </Pad>
                    )}
                </div>
            </Container>
        </div>
    );
};

export default withStyles(useStyles)(ContentManagement);
