import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid, Dialog, DialogTitle, DialogContent, TextField, DialogActions, 
  Button, FormControlLabel, Checkbox, Link, Card, CardHeader, Divider, CardContent, Box,
  InputAdornment, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import firebase, { functionBaseUrl, axiosWithToken } from '../../common/firebase';
import { getPlan } from '../../common/plans';
import Swal from 'sweetalert2';
import './Dashboard.css';
import { StripeSubscription } from '../../components/stripeSubscription';
import axios from 'axios';
import {
  defaultHeightToDefaultHipWidth,
  defaultHeightToHipWidth,
  femaleHeightToDefaultHipWidth,
  femaleHeightToHipWidth,
  heightValues,
  maleHeightToDefaultHipWidth,
  maleHeightToHipWidth,
} from "../students/misc/hipWidthTable";
import moment from 'moment';

class NewAccountCheck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dob: '',
      email: '',
      height: '',
      gender: '',
      dominantHand: '',
      hipWidth: '',
      subPlan: '',
      trialPeriod: '',
      currentStep: 'agreements',
      tosAgree: false,
      ppAgree: false,
      consentAgree: true,
      invitesReceived: this.props.invitesReceived,
      check: true,
      weeklyPractices: 0,
      averageScore: 0,
      paymentSuccessful: false,
      txToken: '',
      plan: this.props.subscriptionType,
      firstName: this.props.firstName,
      lastName: this.props.lastName,
      cardNumber: '',
      expirationDate: '',
      securityCode: '',
      streetName: '',
      zip: '',
    }
  }

  componentDidMount() {
    this.setState({ invitesReceived: this.props.invitesReceived, plan: this.props.subscriptionType, 
      firstName: this.props.firstName, lastName: this.props.lastName, height: this.props.userData.height,
      gender: this.props.userData.gender, hipWidth: this.props.userData.hipWidth, 
      dominantHand: this.props.userData.dominantHand })
    let tempHeight = this.props.userData.height;
    if (tempHeight && tempHeight.includes('.')) {
      tempHeight =  tempHeight.replace('.', "'");
    }
    if (tempHeight && tempHeight.includes("`")) {
      tempHeight =  tempHeight.replace("`", "'");
    }
    if (tempHeight && tempHeight.includes("``")) {
      tempHeight =  tempHeight.replace("``", '"');
    }
    if (tempHeight && !tempHeight.includes('"')) {
      tempHeight =  tempHeight + '"';
    }
    this.setState({ height: tempHeight });

    if (this.props.userData.dob !== '' && this.props.userData.userConsent !== '') {
      if (this.props.userData.subscriptionType.includes('free')) {
        this.setState({ currentStep: 'invite' });
      } else if (this.props.userData.subscriptionType.includes('student_lite')) {
        this.setState({ currentStep: 'invite' });
      } else {
        this.setState({ currentStep: 'additionalFields' });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.height !== prevState.height ||
      this.state.gender !== prevState.gender
    ) {
      let result = 6;
      if (this.state.height !== "" && this.state.gender !== "") {
        switch (this.state.gender) {
          case "MALE":
            result = maleHeightToDefaultHipWidth[this.state.height];
            break;
          case "FEMALE":
            result = femaleHeightToDefaultHipWidth[this.state.height];
            break;
          default:
            result = defaultHeightToDefaultHipWidth[this.state.height];
            break;
        }
        this.setState({ hipWidth: result });
      }
    }
  }

  handleChange = (prop) => (event) => {
    this.setState({ [prop]: event.target.value })
  }

  handleTOSAgree = () => {
    this.setState({ tosAgree: !this.state.tosAgree })
  }

  handlePPAgree = () => {
    this.setState({ ppAgree: !this.state.ppAgree })
  }

  handleConsentAgree = () => {
    this.setState({ consentAgree: !this.state.consentAgree })
  }

  getInitialState = () => {
    return {
      firstName: '',
      lastName: '',
      email: '',
      height: '',
      gender: '',
      dominantHand: '',
      hipWidth: '',
      subPlan: '',
      trialPeriod: '',
    }
  }

  handleBack = () => {
    if (this.state.currentStep === 'creditCard') {
      this.setState({ currentStep: 'agreements' })
    }
    if (this.state.currentStep === 'invite') {
      this.setState({ currentStep: 'agreements' })
    }
    if (this.state.currentStep === 'additionalFields') {
      this.setState({ currentStep: 'invite' })
    }
  }

  dobFormat = (dob) => {
    let [year, month, day] = dob.split('-');
    return (month + "/" + day + "/" + year)
  }

  validEmail = (email) => {
    const regex = /\S+@\S+\.\S+/;
    return regex.test(email)
  }

  requesterRole = (invite) => {
    let role = ""
    if(invite && invite.role) {
      role = invite.role
    }
    return role
  }

  inviteValidity = (invite) => {
    let date = ""
    if(invite && invite.expiredIn) {
      date = this.dateDisplay(invite.expiredIn)
    }
    return date
  }

  dateDisplay = (date) => {
    return moment(String(date), "x").format("MM/DD/YYYY");
  }

  approveRequest = (requestId) => (event) => {
    Swal.fire({
      title: '<p style="font-size:70%;">Accept Invite</p>',
      html: '<p>Are you sure you want to accept this invite?</p>',
      customClass : { 
        container : 'my-swal' 
      },
      icon: 'warning',
      confirmButtonText: 'Yes',
      showCancelButton: true,
      cancelButtonColor: 'red',
    }).then((result) => {
      if (result.isConfirmed) {
        axiosWithToken(functionBaseUrl + '/api/approveInvite/' + requestId, {
          method: 'POST',
        })
          .then((response) => {
            const newInvReceived = this.state.invitesReceived.filter(item => item.id !== String(requestId));
            this.setState({ invitesReceived: newInvReceived });
            Swal.fire({ title: '<p style="font-size:70%;">Invite Accepted Successfully!</p>', icon: 'success', customClass : { container : 'my-swal'}, timer: 2000 });
          })
          .catch(err => {
            Swal.fire({ icon: 'error', text: err, customClass : { container : 'my-swal'} })
            console.log(err)
          });
      }
    }).catch(err => {
      console.log(err)
    });
  }

  rejectRequest = (requestId) => (event) => {
    Swal.fire({
      title: '<p style="font-size:70%;">Reject Invite</p>',
      html: '<p>Are you sure you want to reject this invite?</p>',
      customClass : { 
        container : 'my-swal' 
      },
      icon: 'warning',
      confirmButtonText: 'Yes',
      showCancelButton: true,
      cancelButtonColor: 'red',
      allowOutsideClick: false,
    })
      .then((result) => {
        if (result.isConfirmed) {
          axiosWithToken(functionBaseUrl + '/api/rejectInvite/' + requestId, {
            method: 'post',
          })
            .then(response => {
              const newInvReceived = this.state.invitesReceived.filter(item => item.id !== String(requestId));
              this.setState({ invitesReceived: newInvReceived });
            })
        }
      })
      .catch(err => {
        console.log(err)
      });
  }

  getButton = () => {
    if (this.state.currentStep === 'agreements' || this.state.currentStep === 'creditCard' || this.state.currentStep === 'invite') {
      return 'Next'
    }
    if (this.state.currentStep === 'additionalFields') {
      return 'Submit'
    }
  }

  pageTitle = () => {
    if (this.state.currentStep === 'agreements') {
      return 'Agreements'
    }
    if (this.state.currentStep === 'creditCard') {
      return 'Enter Credit Card Details'
    }
    if (this.state.currentStep === 'invite') {
      return 'Pending Invites'
    }
    if (this.state.currentStep === 'additionalFields') {
      return 'Enter User Details'
    }
  }

  getDisabled = () => {
    if (this.state.currentStep === 'agreements') {
      return (!this.state.tosAgree || !this.state.ppAgree || this.state.dob.length < 10)
    }
    if (this.state.currentStep === 'creditCard') {
      if (this.state.firstName.length > 0 && this.state.lastName.length > 0 && this.state.cardNumber.length > 0 &&
        this.state.expirationDate.length > 0 && this.state.securityCode.length > 0 && this.state.streetName.length > 0 &&
        this.state.zip.length > 0) {
          return false
        } else {
          return true
        }
    }
    if (this.state.currentStep === 'invite') {
      return !(this.state.invitesReceived.length === 0)
    }
    if (this.state.currentStep === 'additionalFields') {
      if (this.state.height === '' || this.state.gender === '' || this.state.hipWidth === '' || this.state.dominantHand === '') {
        return true
      } else {
        return false
      }
    }
  }

  getAmount = () => {
    if (this.props.subscriptionType.includes('student_lite_monthly')) {
      return '14.39';
    } else if (this.props.subscriptionType.includes('student_lite_annually')) {
      return '99.00';
    }
  }

  createSubscription = async (data) => {
    axiosWithToken(functionBaseUrl + '/api/subscription', {
      method: 'POST',
      data: {
        userId: this.props.uid,
        plan: this.props.subscriptionType,
        planDescription: '',
        planType: this.props.subscriptionType.includes('monthly') ? 'Monthly' : 'Annually',
        transactionId: data.id,
        payload: '',
        packageId: '',
        orderId: '',
        amount: data.amount / 100,
        startDate: this.props.subData.startDate,
        endDate: this.props.subData.endDate,
        lastUpdated: String(Date.now()),
        autoRenewal: true,
        platform: 'Stripe',
        productId: this.props.subscriptionType,
        promoCode: ''
      }
    }).then(() => {
      Swal.fire({
        title: `<p style="font-size:70%;">Thank you for subscribing!</p>`,
        showConfirmButton: false,
        icon: 'success',
        customClass : { 
          container : 'my-swal' 
        },
        timer: 2000,
        showCloseButton: true,
        allowOutsideClick: true
      }).then(() => {
        this.setState({ currentStep: 'invite' })
      })
    })
      .catch(err => {
        console.log(err)
        Swal.fire({
          title: '<p style="font-size:70%;">There was an error processing your subscription, please try again.</p>',
          icon: 'error',
          customClass : { 
            container : 'my-swal' 
          },
        });
      })
  }

  getDate = () => {
    return moment(String(this.props.subData.endDate), "x").format("MM/DD/YYYY");
  }

  handleSubmit = async () => {
    if (this.state.currentStep === 'agreements') {
      const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
      const date = new Date(new Date().setFullYear(new Date().getFullYear() - 13)).toLocaleDateString('en-ZA', options).replaceAll('/', '-');
      if (Date.parse(this.state.dob) > Date.parse(date)) {
        Swal.fire({
          title: '<p style="font-size:70%;">Sorry, you are not eligible for a Sportsbox.ai account at this time.</p>',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          customClass : { 
            container : 'my-swal' 
          },
        })
          .then(() => {
            firebase.auth().signOut().then(() => {
              console.log("logged out" + firebase.auth().currentUser)
            }).catch((error) => {
              console.log(error)
            })
          })
          return false;
      }

      this.setState({ currentStep: 'invite' })
    }

    if (this.state.currentStep === 'invite') {
      this.setState({ currentStep: 'additionalFields' })
    }

    if (this.state.currentStep === 'additionalFields') {
      await axiosWithToken(functionBaseUrl+'/api/users/'+ this.props.uid, {
        method: 'patch',
        data: {    
          userInfo: {
            dob: this.dobFormat(this.state.dob),
            gender: this.state.gender,
            height: this.state.height,
            hipSize: this.state.hipWidth,
            dominantHand: this.state.dominantHand,
            weeklyPractices: this.state.weeklyPractices,
            averageScore: this.state.averageScore,
            userConsent: this.state.consentAgree,
          }
        }
      })
        .then(async () => {
          this.props.onClose();
          const bufferSnap = await firebase.firestore().collection('incompleteUsersBuffer').where('userId', '==', this.props.uid).get();
          bufferSnap.forEach(async function (doc) {
            await firebase.firestore().collection('incompleteUsersBuffer').doc(doc.id).delete();
          });
          if (window.location.href.includes('mobileClient')) {
            Swal.fire({
              title: `<p style="font-size:70%;">Your changes have been saved!</p>`,
              showConfirmButton: false,
              icon: 'success',
              showCloseButton: true,
              allowOutsideClick: true,
              customClass : { 
                container : 'my-swal' 
              },
            }).then(() => {
              window.location = "sportsboxclient://update_password";
            })
          } else {
            Swal.fire({
              title: `<p style="font-size:70%;">Your changes have been saved!<br /><br /> Please click on these links to download the app: </p>
              <a href='https://apps.apple.com/us/app/sportsbox-3dgolf/id1578921026'><img width='40%' alt='Download on the App Store' src='https://miro.medium.com/max/600/1*xqT83bMEz92IBYxS9UQNow.png'></a>
              <a href='https://play.google.com/store/apps/details?id=com.sportsbox.golfai'><img width='40%' alt='Get it on Google Play' src='https://miro.medium.com/max/600/1*nZu0dsnlCQltPT1QMCHFAA.png'/></a>`,
              showConfirmButton: false,
              icon: 'success',
              showCloseButton: true,
              allowOutsideClick: true,
              customClass : { 
                container : 'my-swal' 
              },
            }).then(() => {
              window.location.href = window.location.href.replace('dashboard', 'account');
            })
          }
        })
        .catch(err => {        
          alert('There was an error updating your info')
          console.log(err);
        });
    }
  }

  handleSignOut = async () => {
    await firebase.auth().signOut().then(() => {
      window.location.href = window.location.href.replace('dashboard', 'sign-in');
    }).catch((error) => {
      console.log(error)
    });
  }

  render() {
    return (
      <div style={{ display: 'flex' }}>
        <Dialog
          fullWidth
          open={this.props.open}
          //onClose={this.props.onClose}
          aria-labelledby='form-dialog-title'>
          <DialogTitle id={'form-dialog-title'}>
            <div>
              <Typography
                variant="h5"
                color='secondary'
              >
                User Details
              </Typography>
            </div>
          </DialogTitle>
          <DialogContent>
            <Grid container component="main" maxWidth="xs">
              <Grid
                item
                sm={12}
                style={{ marginBottom: '10px' }}
              >
                <Typography
                  variant="h5"
                  color='primary'
                >
                  {this.pageTitle()}
                </Typography>
              </Grid>
              {this.state.currentStep === 'agreements' && (
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                      fullWidth
                      margin='dense'
                      variant="outlined"
                      id="date"
                      label="Date of Birth"
                      onChange={this.handleChange('dob')}
                      type="date"
                      value={this.state.dob}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          value={this.state.tosAgree}
                          onClick={this.handleTOSAgree}
                          checked={this.state.tosAgree ? 'checked' : null}
                        />
                      }
                      label={
                        <div>
                          <span>I have read and agree to Sportsbox's </span>
                          <Link onClick={(e) => {
                            e.preventDefault();
                            window.open(window.location.href.replace('dashboard', 'terms-of-service?newAccount'));
                          }}>Terms of Service.
                          </Link>
                        </div>
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          value={this.state.ppAgree}
                          onClick={this.handlePPAgree}
                          checked={this.state.ppAgree ? 'checked' : null}
                        />
                      }
                      label={
                        <div>
                          <span>I have read and agree to Sportsbox's </span>
                          <Link onClick={(e) => {
                            e.preventDefault();
                            window.open(window.location.href.replace('dashboard', 'privacy?newAccount'));
                          }}>Privacy Policy.
                          </Link>
                        </div>
                      }
                    />
                  </Grid>
                </Grid>
              )}
              {this.state.currentStep === 'creditCard' && (
                <Grid container spacing={1}>
                  {this.setState({ currentStep: 'invite' })}
                  <Typography
                    variant="h6"
                    style={{ color: "black", marginBottom: '10px' }}
                  >
                    You have been gifted {this.props.subData?.coachDetails?.period} {this.props.subscriptionType.includes('monthly') ? 'month(s)' : 'year(s)'} of 3D Practice by your coach. At the end of your current subscription, your plan will auto-renew and your credit card will be charged. Please enter your credit card details below:
                  </Typography>
                  <Grid item xs={12}>
                    {this.props.subData.endDate ? <div style={{ margin: '0 auto', marginTop: '5%' }}>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          //sm={6}
                          xs={12}
                        >
                          <StripeSubscription
                            email={this.props.email}
                            productId={this.props.subscriptionType} 
                            handleResult={this.createSubscription}
                            trialEnd={this.props.subData.endDate}
                            hideCoupon={true}
                            fullName={`${this.state.firstName} ${this.state.lastName}`}
                          />
                        </Grid>
                      </Grid>
                    </div> : <div><img width="4%" src="images/loading.gif" alt="Loading" /></div>}
                  </Grid>
                </Grid>
              )}
              {this.state.currentStep === 'invite' && (
                <Grid container spacing={1}>
                  {(this.state.invitesReceived.length === 0) ? (this.state.check ? this.setState({ invitesReceived: this.props.invitesReceived, check: false }) : "There are no invites") :
                    this.state.invitesReceived.map((invite) => (
                      <Card style={{ minWidth: "50%" }} key={invite.id}>
                        <CardHeader
                          title={invite.sender.firstName + ' ' + invite.sender.lastName + ' (' + invite.sender.email + ')'}
                          titleTypographyProps={{variant:"h5", color:"primary"}}
                        />
                        <Typography
                          component="h6"
                          variant="h6"
                          justify="center"
                          style={{marginLeft: '15px', marginRight: '15px', marginTop: '-10px'}}
                        >
                          <i>{"is inviting your for the role of " + this.requesterRole(invite)}</i>
                        </Typography>
                        <Typography
                          component="h6"
                          variant="h6"
                          justify="center"
                          style={{marginLeft: '15px', marginRight: '15px', marginBottom: '10px'}}
                        >
                          <b>Valid Till: </b>{" " + this.inviteValidity(invite)}
                        </Typography>
                        <Divider />
                        <CardContent>
                          <Grid container justify="space-between"> 
                            <Button
                              style={{margin: '0 auto', display: "flex"}}  
                              variant="contained"
                              color="primary"
                              justify="center"
                              onClick={this.approveRequest(invite.id)}
                            >
                              Accept
                            </Button>
                            
                            <Button
                              style={{margin: '0 auto', display: "flex"}}  
                              variant="contained"
                              color="secondary"
                              justify="center"
                              onClick={this.rejectRequest(invite.id)}
                            >
                              Reject
                            </Button>
                          </Grid>
                        </CardContent>
                      </Card>
                    )
                  )}
                </Grid>
              )}
              {this.state.currentStep === 'additionalFields' && (
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth margin="dense">
                      <InputLabel id="height-label">Height*</InputLabel>
                      <Select
                        id="height"
                        label="Height"
                        labelId="height-label"
                        name="height"
                        onChange={this.handleChange("height")}
                        value={this.state.height}
                        variant="outlined"
                      >
                        {heightValues.map((el) => (
                          <MenuItem value={el}>{el}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth margin="dense">
                      <InputLabel id="gender-select">Gender*</InputLabel>
                      <Select
                        id="gender"
                        label="Gender*"
                        labelId="gender-select"
                        name="gender"
                        value={this.state.gender.toUpperCase()}
                        onChange={this.handleChange("gender")}
                      >
                        <MenuItem value={"FEMALE"}>Female</MenuItem>
                        <MenuItem value={"MALE"}>Male</MenuItem>
                        <MenuItem value={"PREFER NOT TO ANSWER"}>
                          Prefer not to answer
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {this.state.gender !== "" && this.state.height !== "" && (
                    <Grid item xs={6}>
                      <FormControl variant="outlined" fullWidth margin="dense">
                        <InputLabel id="hipWidth-label">Hip Width*</InputLabel>
                        <Select
                          id="hipWidth"
                          label="HipWidth"
                          labelId="hipWidth-label"
                          name="hipWidth"
                          onChange={this.handleChange("hipWidth")}
                          value={this.state.hipWidth}
                          variant="outlined"
                        >
                          {(() => {
                            if (this.state.height) {
                              switch (this.state.gender) {
                                case "MALE":
                                  return maleHeightToHipWidth[
                                    this.state.height
                                  ]?.map((el) => (
                                    <MenuItem value={el}>{el}</MenuItem>
                                  ));
                                case "FEMALE":
                                  return femaleHeightToHipWidth[
                                    this.state.height
                                  ]?.map((el) => (
                                    <MenuItem value={el}>{el}</MenuItem>
                                  ));
                                case "PREFER NOT TO ANSWER":
                                  return defaultHeightToHipWidth[
                                    this.state.height
                                  ]?.map((el) => (
                                    <MenuItem value={el}>{el}</MenuItem>
                                  ));
                                default:
                                  break;
                              }
                            }
                          })()}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth margin="dense">
                      <InputLabel id="domHand-select">Dominant Hand*</InputLabel>
                      <Select
                        id="domHand"
                        label="Dominant Hand*"
                        labelId="domHand-select"
                        name="domHand"
                        value={this.state.dominantHand}
                        onChange={this.handleChange("dominantHand")}
                      >
                        <MenuItem value={"Left"}>Left</MenuItem>
                        <MenuItem value={"Right"}>Right</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label='Weekly Practices'
                      name='weeklyPractices'
                      margin='dense'
                      onChange={this.handleChange('weeklyPractices')}
                      value={this.state.weeklyPractices}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">days</InputAdornment>,
                        inputProps: { min: 0 }
                      }}
                      type="number"
                      variant='outlined' />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label='Average Score'
                      name='averageScore'
                      margin='dense'
                      onChange={this.handleChange('averageScore')}
                      value={this.state.averageScore}
                      InputProps={{
                        inputProps: { min: 0 }
                      }}
                      type="number"
                      variant='outlined' />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
          <Button onClick={this.handleSignOut} color="secondary">
              Sign Out
            </Button>
            <Button onClick={this.handleBack} disabled={(this.state.currentStep === 'agreements' || this.state.currentStep === 'invite') ? true : false} color="primary">
              Back
            </Button>
            <Button onClick={this.handleSubmit} color="secondary" disabled={this.getDisabled()}>
              {this.getButton()}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

NewAccountCheck.propTypes = {
  item: PropTypes.object,
  open: PropTypes.bool,
  closeCreatePopUp: PropTypes.func,
  submitQuery: PropTypes.func,
  values: PropTypes.object
};

export default NewAccountCheck;
