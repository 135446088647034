import React, {useState} from 'react';
import {Link as RouterLink, withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {Grid, Button, Container, Avatar, TextField, Link, Typography, CssBaseline} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const ResetPassword = (props) => {
  // const { history } = props;

  const classes = useStyles();

  const [values, setValues] = useState({
    email: '',
    password: '',
    error: '',
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleResetPassword = (event) => {
    event.preventDefault();
    // const data = new FormData(event.target);
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
    >
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography
          component="h1"
          variant="h5"
        >
          RESET YOUR PASSWORD
        </Typography>
        <form
          className={classes.form}
          onSubmit={handleResetPassword}
        >
          <TextField
            autoComplete="email"
            autoFocus
            fullWidth
            label="Email Address"
            margin="normal"
            name="email"
            onChange={handleChange}
            required
            value={values.email}
            variant="outlined"
          />
          <TextField
            autoComplete="password"
            fullWidth
            id="password"
            label="Password"
            margin="normal"
            name="password"
            onChange={handleChange}
            required
            type="password"
            value={values.password}
            variant="outlined"
          />
          <TextField
            autoComplete="password-confirm"
            fullWidth
            id="password-confirm"
            label="Password (Confirm)"
            margin="normal"
            name="password-confirm"
            onChange={handleChange}
            required
            type="password"
            value={values.password}
            variant="outlined"
          />
          <Button
            className={classes.submit}
            color="primary"
            fullWidth
            type="submit"
            variant="contained"
          >
            RESET MY PASSWORD
          </Button>
          <Grid container>
            <Grid
              item
              xs
            >
              <Link
                component= {RouterLink}
                to="/sign-in"
                variant="body2"
              >
                {'Go back to login'}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

ResetPassword.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ResetPassword);
