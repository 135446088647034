import React, { Component } from "react";
import PropTypes from "prop-types";
import {
    Typography,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { functionBaseUrl, axiosWithToken } from "../../common/firebase";
import Swal from "sweetalert2";

class AddStudent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            roleCheck: "",
            coach: this.props.uid,
        };
    }

    handleChange = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    };

    getInitialState = () => {
        return {
            name: "",
            email: "",
            roleCheck: "",
            coach: this.props.uid,
        };
    };

    handleClear = () => {
        const confirmVal = window.confirm("Do you want to clear your query?");
        if (confirmVal) {
            this.setState(this.getInitialState());
        }
    };

    validEmail = (email) => {
        //const regex = /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/
        const regex = /\S+@\S+\.\S+/;
        return regex.test(email);
    };

    handleSubmit = () => {
        const output = {
            senderId: this.state.coach || this.props.uid,
            senderName: this.props.subscriptionType.includes('master') ?
                (this.props.coaches
                    ? `${
                        this.props.coaches.find((c) => c.id === this.state.coach) ? this.props.coaches.find((c) => c.id === this.state.coach).firstName : ''
                    } ${
                        this.props.coaches.find((c) => c.id === this.state.coach) ? this.props.coaches.find((c) => c.id === this.state.coach).lastName : ''
                    }`
                    : this.props.name)
                : this.props.name,
            type: "Email",
            invitee: this.state.email,
            inviteeName: this.state.name,
            role: this.props.roleCheck,
            picture: "",
        };
        if (output.inviteeName.length === 0) {
            alert("Invitee name is blank");
            return false;
        }
        if (output.invitee.length === 0) {
            alert("Invitee email is blank");
            return false;
        }
        if (output.invitee.length > 0) {
            if (!this.validEmail(output.invitee)) {
                alert("Please enter a valid email");
                return false;
            }
        }
        if (output.invitee === this.props.email) {
            alert("You cannot invite yourself");
            return false;
        }
        if (output.senderId.length === 0) {
            alert("Coach is blank");
            return false;
        }
        if (
            this.props.subscriptionType.includes("enterprise_master") &&
            this.state.coach.length === 0
        ) {
            alert("Please select a coach");
            return false;
        }
        axiosWithToken(functionBaseUrl + "/api/invite", {
            method: "post",
            data: output,
        })
            .then((response) => {
                if (response.status === 201) {
                    Swal.fire({
                        title:
                            '<p style="font-size:70%;">' +
                            response.data.message +
                            "</p>",
                        icon: "warning",
                        confirmButtonText: "Ok",
                        allowOutsideClick: true,
                    });
                } else {
                    this.setState({ students: response.data, loading: false });
                    Swal.fire({
                        title: '<p style="font-size:70%;">Invite sent successfully</p>',
                        icon: "success",
                        confirmButtonText: "Ok",
                        allowOutsideClick: true,
                        timer: 2000,
                    }).then(() => {
                        this.setState(this.getInitialState());
                        window.location.reload();
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
        this.props.onClose();
    };

    render() {
        return (
            <Dialog
                fullWidth
                open={this.props.open}
                onClose={this.props.onClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id={"form-dialog-title"}>
                    {this.props.roleCheck === "Sportsbox-User" ? (
                        <div>
                            Enter Student Details
                            <CloseIcon
                                style={{
                                    float: "right",
                                    marginTop: "-10px",
                                    marginRight: "-15px",
                                }}
                                onClick={this.props.onClose}
                            />
                        </div>
                    ) : (
                        <div>
                            Enter Instructor Details
                            <CloseIcon
                                style={{
                                    float: "right",
                                    marginTop: "-10px",
                                    marginRight: "-15px",
                                }}
                                onClick={this.props.onClose}
                            />
                        </div>
                    )}
                </DialogTitle>
                <DialogContent>
                    <Grid container component="main" maxWidth="xs">
                        <Grid item sm={12}>
                            {this.props.roleCheck === "Sportsbox-User" ? (
                                <Typography variant="h5" color="primary">
                                    Student Details
                                </Typography>
                            ) : (
                                <Typography variant="h5" color="primary">
                                    Instructor Details
                                </Typography>
                            )}
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    required
                                    label="Name"
                                    name="name"
                                    margin="dense"
                                    onChange={this.handleChange("name")}
                                    value={this.state.name}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <TextField
                                    fullWidth
                                    required
                                    label="Email"
                                    name="email"
                                    margin="dense"
                                    onChange={this.handleChange("email")}
                                    value={this.state.email}
                                    variant="outlined"
                                />
                            </Grid>
                            {this.props.subscriptionType.includes(
                                "enterprise_master"
                            ) && (
                                <Grid item sm={6}>
                                    <TextField
                                        fullWidth
                                        select
                                        label="Coach"
                                        margin="dense"
                                        name="coach"
                                        onChange={this.handleChange("coach")}
                                        required
                                        value={this.state.coach}
                                        variant="outlined"
                                        disabled={
                                            Object.keys(this.props.coaches)
                                                .length === 0
                                        }
                                    >
                                        {Object.keys(this.props.coaches).map(
                                            (id) => (
                                                <MenuItem
                                                    key={id}
                                                    value={
                                                        this.props.coaches[id]
                                                            .id
                                                    }
                                                >
                                                    {this.props.coaches[id]
                                                        .firstName +
                                                        " " +
                                                        this.props.coaches[id]
                                                            .lastName}
                                                </MenuItem>
                                            )
                                        )}
                                    </TextField>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClear} color="primary">
                        Clear
                    </Button>
                    <Button onClick={this.handleSubmit} color="secondary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

AddStudent.propTypes = {
    item: PropTypes.object,
    open: PropTypes.bool,
    closePopUp: PropTypes.func,
    submitQuery: PropTypes.func,
    values: PropTypes.object,
};

export default AddStudent;
