import styled from "styled-components";

export const Container = styled.div`
  padding: 20px;
`;

export const QuestionsTabContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  /* white-space: nowrap; */
  overflow-x: auto;
`;

export const QuestionsTab = styled.div`
  padding: 10px;
  margin: 0 20px;
  width: 200px;
  flex-wrap: wrap;
  border-radius: 5px;
  background-color: #580ce3;
  color: white;
  border: #580ce3 1px solid;
  cursor: pointer;
  &:hover {
    background-color: white;
    color: #580ce3;
  }
`;

export const QuestionsTableRow = styled.div`
  background-color: pink;
`;

export const QuestionsTableRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.25);
  margin-bottom: 12px;
  padding: 14px;
`;

export const QuestionsTableRowItemWrapper = styled.div`
  min-width: ${({ width }) => width};
  max-width: ${({ width }) => width};
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const TableSorter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
`;

export const TableSorterItem = styled.div`
  display: flex;
  align-items: center;
  color: gray;
`;

export const TableSorterItemWrapper = styled.div`
  width: ${({ width }) => width};
  cursor: ${({ sortable }) => (sortable ? "pointer" : "auto")};
  display: flex;
  justify-content: start;
  align-items: center;
  user-select: none;
`;

export const SearchInput = styled.input`
  background: #d9d9d9;
  border-radius: 8px;
  border: none;
  height: 45px;
  padding: 10px;
  &:focus {
    border: none;
    outline: none;
  }
`;

export const SearchButton = styled.div`
  background: #5a5a5a;
  border-radius: 4px;
  padding: 10px;
  padding-bottom: 7px;
  cursor: pointer;
`;
