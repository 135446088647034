import React, { useState, useEffect } from 'react';

import {
    Button, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, Grid,
    FormControl, InputLabel, Select, MenuItem, TextField, Chip
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseIcon from '@material-ui/icons/Close';
import Swal from 'sweetalert2';

import firebase from '../../../../common/firebase';


function LabelDialogComponent({ open, videos, close }) {
    const [label, setLabel] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [labelAction, setLabelAction] = useState("add");
    const [labelOptions, setLabelOptions] = useState([]);

    useEffect(() => {
        loadLabels();
    }, []);

    const loadLabels = async () => {
        const labels = [];
        const snapshot = await firebase.firestore().collection("videosLabels").get();
        snapshot.forEach(function (doc) {
            labels.push(doc.id);
        });
        setLabelOptions(labels);
    }

    const handleChange = (event, labels) => {
        const values = [];
        for (const l of labels) {
            values.push(l.trim().toUpperCase())
        }
        setLabel(values);
    }

    const handleAction = (event) => {
        setLabelAction(event.target.value);
    }

    const handleUpdate = async () => {
        try {
            setIsLoading(true);
            for (const video in videos) {
                switch (labelAction) {
                    case 'add':
                        for (const l of label) {
                            await firebase.firestore().collection("videos").doc(video).update({
                                internalLabel: firebase.firestore.FieldValue.arrayUnion(l),
                            });
                        }
                        break;
                    case 'replace':
                        await firebase.firestore().collection("videos").doc(video).update({
                            internalLabel: label,
                        })
                        break;
                    case 'clear':
                        await firebase.firestore().collection("videos").doc(video).update({
                            internalLabel: [],
                        })
                        break;
                    case 'remove':
                        for (const l of label) {
                            await firebase.firestore().collection("videos").doc(video).update({
                                internalLabel: firebase.firestore.FieldValue.arrayRemove(l),
                            });
                        }
                        break;
                }
            }
            const labels = labelOptions;
            for (const l of label) {
                if (!labels.includes(l)) {
                    await firebase.firestore().collection("videosLabels").doc(l).set({});
                    labels.push(l);
                }
            }
            setLabelOptions(labels);
            setLabel([]);
            setLabelAction('add');
            setIsLoading(false);
            Swal.fire({
                title: '<p style="font-size:70%;">Saved successfully</p>',
                icon: 'success',
                confirmButtonText: 'Ok',
                allowOutsideClick: false,
                timer: 2000
            });
            close();
        } catch (e) {
            setIsLoading(false);
            Swal.fire('<p style="font-size:70%;">Error occured</p>');
        }
    }

    return (<>
        <Dialog
            fullWidth
            open={open}
            onClose={close}
            aria-labelledby='form-dialog-title'>
            <DialogTitle id={'form-dialog-title'}>
                <div>
                    Add Label
                    <CloseIcon style={{ float: 'right', marginTop: '-10px', marginRight: '-15px' }} onClick={close} />
                </div>
            </DialogTitle>
            <DialogContent>
                <Grid container component="main">
                    <Grid container spacing={2}>
                        <Grid
                            item
                            xs={12}
                        >
                            {!isLoading && (
                                <>
                                    <FormControl variant="outlined" margin="normal" fullWidth>
                                        <InputLabel id="labels-action-label">Change labels</InputLabel>
                                        <Select
                                            id="labels-action"
                                            label="Change labels"
                                            labelId="labels-action-label"
                                            onChange={handleAction}
                                            value={labelAction}
                                        >
                                            <MenuItem value="add">Add to existing</MenuItem>
                                            <MenuItem value="replace">Replace all with</MenuItem>
                                            <MenuItem value="clear">Clear field</MenuItem>
                                            <MenuItem value="remove">Find and remove these</MenuItem>
                                        </Select>
                                    </FormControl>
                                    {labelAction != 'clear' && (
                                        <FormControl
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                        >
                                            <Autocomplete
                                                multiple
                                                id="tags-filled"
                                                options={labelOptions}
                                                defaultValue={[]}
                                                freeSolo
                                                onChange={handleChange}
                                                renderTags={(value, getTagProps) =>
                                                    value.map((option, index) => {
                                                        return (
                                                            <Chip
                                                                key={index}
                                                                variant="outlined"
                                                                label={option}
                                                                {...getTagProps({ index })}
                                                            />
                                                        );
                                                    })
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Internal Label"
                                                        placeholder="Add labels"
                                                        helperText="Press enter key after each new label and before clicking SAVE"
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                    )}
                                </>
                            )}
                            {isLoading && (
                                <CircularProgress />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={close} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleUpdate} color="secondary" disabled={label.length === 0}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    </>);
}

export const LabelDialog = LabelDialogComponent;
