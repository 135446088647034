import React, { useState, useEffect } from 'react';

import { Container, FormControl, Grid, InputLabel, Select, MenuItem, Typography } from '@material-ui/core';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';

import { functionBaseUrl, axiosWithToken } from '../../common/firebase';


function ActiveUsersComponent() {
  const [statType, setStatType] = useState('validAuthenticatedUser');
  const [statPeriod, setStatPeriod] = useState('24hours');
  const [data, setData] = useState({});
  const [total, setTotal] = useState(0);

  const handleStatType = (e) => {
    setStatType(e.target.value);
  }
  const handleStatPeriod = (e) => {
    setStatPeriod(e.target.value);
  }

  const getData = async () => {
    if (statType == 'createdSession') {
      const data = await axiosWithToken(`${functionBaseUrl}/api/usageSessions`, { method: 'GET' });
      let total = [];
      let totalCount = 0;
      data.data.forEach(session => {
        if (session.sessionDate) {
          const created = moment(session.sessionDate);
          let startDate = moment().subtract(1, 'd').unix();
          if (statPeriod === '7days') {
            startDate = moment().subtract(7, 'd').unix();
          }
          if (statPeriod === '31days' || created.unix() >= startDate) {
            totalCount++;
            let key = created.format('YYYY-MM-DD HH');
            if (statPeriod === '7days' || statPeriod === '31days') {
              key = created.format('YYYY-MM-DD');
            }
            const totalIndex = total.findIndex(item => item.key === key);
            if (totalIndex < 0) {
              total.push({ key: key, count: 1, users: [session.sessionUserId] });
            } else {
              const frameIndex = total[totalIndex].users.findIndex(item => item === session.sessionUserId);
              if (frameIndex < 0) {
                total[totalIndex].users.push(session.sessionUserId);
                total[totalIndex].count++;
              }
            }
          }
        }
      });

      total.sort((a, b) => a.key.localeCompare(b.key));

      setData({
        labels: total.map(item => item.key),
        datasets: [
          {
            label: 'Overall',
            backgroundColor: 'rgb(155, 99, 132, 0.5)',
            borderColor: 'rgb(155, 99, 132)',
            data: total.map(item => item.count),
          },
        ]
      });
      setTotal(totalCount);
    }
    if (statType == 'createdWatchlist') {
      const data = await axiosWithToken(`${functionBaseUrl}/api/usageWatchlist`, { method: 'GET' });
      let total = [];
      let totalCount = 0;
      data.data.forEach(watchlist => {
        if (watchlist.createdAt) {
          const created = moment(watchlist.createdAt);
          let startDate = moment().subtract(1, 'd').unix();
          if (statPeriod === '7days') {
            startDate = moment().subtract(7, 'd').unix();
          }
          if (statPeriod === '31days' || created.unix() >= startDate) {
            totalCount++;
            let key = created.format('YYYY-MM-DD HH');
            if (statPeriod === '7days' || statPeriod === '31days') {
              key = created.format('YYYY-MM-DD');
            }
            const totalIndex = total.findIndex(item => item.key === key);
            if (totalIndex < 0) {
              total.push({ key: key, count: 1, users: [watchlist.coachId] });
            } else {
              const frameIndex = total[totalIndex].users.findIndex(item => item === watchlist.coachId);
              if (frameIndex < 0) {
                total[totalIndex].users.push(watchlist.coachId);
                total[totalIndex].count++;
              }
            }
          }
        }
      });

      total.sort((a, b) => a.key.localeCompare(b.key));

      setData({
        labels: total.map(item => item.key),
        datasets: [
          {
            label: 'Overall',
            backgroundColor: 'rgb(155, 99, 132, 0.5)',
            borderColor: 'rgb(155, 99, 132)',
            data: total.map(item => item.count),
          },
        ]
      });
      setTotal(totalCount);
    }
    if (statType == 'analyzedSession') {
      const data = await axiosWithToken(`${functionBaseUrl}/api/usageAnalysis`, { method: 'GET' });
      let total = [];
      let totalCount = 0;
      data.data.forEach(analysis => {
        if (analysis.createdDate) {
          const created = moment(analysis.createdDate);
          let startDate = moment().subtract(1, 'd').unix();
          if (statPeriod === '7days') {
            startDate = moment().subtract(7, 'd').unix();
          }
          if (statPeriod === '31days' || created.unix() >= startDate) {
            totalCount++;
            let key = created.format('YYYY-MM-DD HH');
            if (statPeriod === '7days' || statPeriod === '31days') {
              key = created.format('YYYY-MM-DD');
            }
            const totalIndex = total.findIndex(item => item.key === key);
            if (totalIndex < 0) {
              total.push({ key: key, count: 1, users: [analysis.userId] });
            } else {
              const frameIndex = total[totalIndex].users.findIndex(item => item === analysis.userId);
              if (frameIndex < 0) {
                total[totalIndex].users.push(analysis.userId);
                total[totalIndex].count++;
              }
            }
          }
        }
      });

      total.sort((a, b) => a.key.localeCompare(b.key));

      setData({
        labels: total.map(item => item.key),
        datasets: [
          {
            label: 'Overall',
            backgroundColor: 'rgb(155, 99, 132, 0.5)',
            borderColor: 'rgb(155, 99, 132)',
            data: total.map(item => item.count),
          },
        ]
      });
      setTotal(totalCount);
    }
    if (statType == 'invitedAnotherUser') {
      const data = await axiosWithToken(`${functionBaseUrl}/api/usageInvites`, { method: 'GET' });
      let total = [];
      let totalCount = 0;
      data.data.forEach(invite => {
        if (invite.createdAt) {
          const created = moment(invite.createdAt);
          let startDate = moment().subtract(1, 'd').unix();
          if (statPeriod === '7days') {
            startDate = moment().subtract(7, 'd').unix();
          }
          if (statPeriod === '31days' || created.unix() >= startDate) {
            totalCount++;
            let key = created.format('YYYY-MM-DD HH');
            if (statPeriod === '7days' || statPeriod === '31days') {
              key = created.format('YYYY-MM-DD');
            }
            const totalIndex = total.findIndex(item => item.key === key);
            if (totalIndex < 0) {
              total.push({ key: key, count: 1, users: [invite.senderId] });
            } else {
              const frameIndex = total[totalIndex].users.findIndex(item => item === invite.senderId);
              if (frameIndex < 0) {
                total[totalIndex].users.push(invite.senderId);
                total[totalIndex].count++;
              }
            }
          }
        }
      });

      total.sort((a, b) => a.key.localeCompare(b.key));

      setData({
        labels: total.map(item => item.key),
        datasets: [
          {
            label: 'Overall',
            backgroundColor: 'rgb(155, 99, 132, 0.5)',
            borderColor: 'rgb(155, 99, 132)',
            data: total.map(item => item.count),
          },
        ]
      });
      setTotal(totalCount);
    }
  }

  useEffect(() => {
    getData();
  }, [statType, statPeriod]);

  return (<>
    <Container
      component="main"
      style={{ padding: '20px 0' }}
    >
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
        >
          <Typography variant="h2">Active users</Typography>
        </Grid>
        <Grid
          item
          xs={6}
        >
          <FormControl
            fullWidth
            variant="outlined"
          >
            <InputLabel id="stat-type-select">Activity</InputLabel>
            <Select
              label="Statistics"
              labelId="stat-type-select"
              onChange={handleStatType}
              value={statType}
            >
              <MenuItem value={'validAuthenticatedUser'}>Valid authenticated user</MenuItem>
              <MenuItem value={'analyzedSession'}>Analyzed a session</MenuItem>
              <MenuItem value={'createdSession'}>Created a session</MenuItem>
              <MenuItem value={'createdWatchlist'}>Created a watchlist</MenuItem>
              <MenuItem value={'completedPracticeSession'}>Completed a practice session</MenuItem>
              <MenuItem value={'invitedAnotherUser'}>Invited another user</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={6}
        >
          <FormControl
            fullWidth
            variant="outlined"
          >
            <InputLabel id="stat-period-select">Period</InputLabel>
            <Select
              label="Period"
              labelId="stat-period-select"
              onChange={handleStatPeriod}
              value={statPeriod}
            >
              <MenuItem value={'24hours'}>Last 24 hours</MenuItem>
              <MenuItem value={'7days'}>Last 7 days</MenuItem>
              <MenuItem value={'31days'}>Last 31 days</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Typography variant="h4">Total: {total}</Typography>
          <Bar data={data} />
        </Grid>
      </Grid>
    </Container>
  </>);
}

export const ActiveUsers = ActiveUsersComponent;
