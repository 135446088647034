import React from "react";
import {
  TableSorter,
  TableSorterItem,
  TableSorterItemWrapper,
} from "../styled";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";

export function TableSorterRow({ type, sort, onSort }) {
  const sortArrow = (field) => {
    if (sort.field === field) {
      switch (sort.direction) {
        case "asc":
          return <ArrowDropDownIcon />;
        case "desc":
          return <ArrowDropUpIcon />;
        default:
          break;
      }
    }
    return "";
  };

  const handleSort = (field) => {
    if (sort.field === field) {
      const dir = sort.direction === "asc" ? "desc" : "asc";
      onSort(field, dir);
    } else {
      onSort(field, "desc");
    }
  };

  const checkType = () => {
    if (type === "practiceGuides")
      return (
        <TableSorter>
          <TableSorterItemWrapper width="132px">
            <TableSorterItem>Cover Image</TableSorterItem>
          </TableSorterItemWrapper>
          <TableSorterItemWrapper
            sortable
            onClick={() => handleSort("title")}
            width="200px"
          >
            <TableSorterItem>Title {sortArrow("title")}</TableSorterItem>
          </TableSorterItemWrapper>
          <TableSorterItemWrapper width="150px">
            <TableSorterItem>Type</TableSorterItem>
          </TableSorterItemWrapper>
          <TableSorterItemWrapper
            sortable
            onClick={() => handleSort("createdAt")}
            width="100px"
          >
            <TableSorterItem>Date {sortArrow("createdAt")}</TableSorterItem>
          </TableSorterItemWrapper>
          <TableSorterItemWrapper width="150px">
            <TableSorterItem>Status</TableSorterItem>
          </TableSorterItemWrapper>
          <TableSorterItemWrapper width="100px">
            <TableSorterItem>Actions</TableSorterItem>
          </TableSorterItemWrapper>
        </TableSorter>
      );
    else if (type === "tipsAndDrills")
      return (
        <TableSorter>
          <TableSorterItemWrapper width="132px">
            <TableSorterItem>Cover Image</TableSorterItem>
          </TableSorterItemWrapper>
          <TableSorterItemWrapper
            sortable
            onClick={() => handleSort("title")}
            width="200px"
          >
            <TableSorterItem>Title {sortArrow("title")}</TableSorterItem>
          </TableSorterItemWrapper>
          <TableSorterItemWrapper width="200px">
            <TableSorterItem>Type</TableSorterItem>
          </TableSorterItemWrapper>
          <TableSorterItemWrapper width="100px">
            <TableSorterItem>Creator</TableSorterItem>
          </TableSorterItemWrapper>
          <TableSorterItemWrapper
            sortable
            onClick={() => handleSort("createdAt")}
            width="100px"
          >
            <TableSorterItem>Date {sortArrow("createdAt")}</TableSorterItem>
          </TableSorterItemWrapper>
          <TableSorterItemWrapper width="100px">
            <TableSorterItem>Status</TableSorterItem>
          </TableSorterItemWrapper>
          <TableSorterItemWrapper width="100px">
            <TableSorterItem>Actions</TableSorterItem>
          </TableSorterItemWrapper>
        </TableSorter>
      );
    else if (type === "albums")
      return (
        <TableSorter>
          <TableSorterItemWrapper width="132px">
            <TableSorterItem>Cover Image</TableSorterItem>
          </TableSorterItemWrapper>
          <TableSorterItemWrapper
            sortable
            onClick={() => handleSort("title")}
            width="200px"
          >
            <TableSorterItem>Title {sortArrow("title")}</TableSorterItem>
          </TableSorterItemWrapper>
          <TableSorterItemWrapper width="150px">
            <TableSorterItem>Type</TableSorterItem>
          </TableSorterItemWrapper>
          <TableSorterItemWrapper
            sortable
            onClick={() => handleSort("createdAt")}
            width="100px"
          >
            <TableSorterItem>Date {sortArrow("createdAt")}</TableSorterItem>
          </TableSorterItemWrapper>
          <TableSorterItemWrapper width="150px">
            <TableSorterItem>Status</TableSorterItem>
          </TableSorterItemWrapper>
          <TableSorterItemWrapper width="100px">
            <TableSorterItem>Actions</TableSorterItem>
          </TableSorterItemWrapper>
        </TableSorter>
      );
  };
  return <>{checkType()}</>;
}
