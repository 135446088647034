import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid, Dialog, DialogTitle, DialogContent, DialogActions, Button, 
  FormControlLabel, Checkbox, Link, Card, CardContent, Box, Container, TextField } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import firebase, { functionBaseUrl, axiosWithToken } from '../../common/firebase';
import Swal from 'sweetalert2';
import './Dashboard.css';
import { StripePayment } from '../../components/stripePayment';
import axios from 'axios';
import moment from 'moment';
import './bootcamp.css';

class BootcampCheck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dob: "",
      email: this.props.email,
      subPlan: this.props.subscriptionType,
      currentStep: 'agreements',
      tosAgree: false,
      loading: false,
    }
  }

  componentDidMount() {
    this.setState({ dob: this.props.userData.dob });
    
    if (this.props.userData.dob !== "") {
      this.setState({ currentStep: "creditCard" });
    }
  }

  handleChange = (prop) => (event) => {
    this.setState({ [prop]: event.target.value })
  }

  handleTOSAgree = () => {
    this.setState({ tosAgree: !this.state.tosAgree })
  }

  handleBack = () => {
    if (this.state.currentStep === 'creditCard') {
      this.setState({ currentStep: 'agreements' })
    }
  }

  dateDisplay = (date) => {
    return moment(String(date), "x").format("MM/DD/YYYY");
  }

  dobFormat = (dob) => {
    let [year, month, day] = dob.split("-");
    return month + "/" + day + "/" + year;
  };

  getButton = () => {
    if (this.state.currentStep === 'agreements') {
      return 'Next'
    }
    if (this.state.currentStep === 'welcome' || this.state.currentStep === 'creditCard') {
      return 'Submit'
    }
  }

  pageTitle = () => {
    if (this.state.currentStep === 'agreements') {
      return 'Agreements'
    }
    if (this.state.currentStep === 'creditCard') {
      return 'Enter Credit Card Details'
    }
  }

  getDisabled = () => {
    if (this.state.currentStep === 'agreements') {
      return !this.state.tosAgree || this.state.dob.length < 10;
    }
  }

  createSubscription = async (data) => {
    this.setState({ loading: true });
    const bufferSnap = await firebase.firestore().collection('incompleteUsersBuffer').where('userId', '==', this.props.uid).get();
    bufferSnap.forEach(async function (doc) {
      await firebase.firestore().collection('incompleteUsersBuffer').doc(doc.id).delete();
    });

    const stripeSub = await axios(functionBaseUrl + '/api/stripeCreateSubscription', {
      method: 'POST',
      data: {
        email: this.props.email,
        productId: this.props.subData.productId,
        freeTrial: this.props.subData.productId.includes('monthly') ? 61 : (this.props.userData?.bootcampUser ? 426 : 365),
      }
    })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          title: '<p style="font-size:70%;">There was an error processing your subscription, please try again.</p>',
          icon: 'error',
          customClass : { 
            container : 'my-swal' 
          },
        });
      });

    await axiosWithToken(functionBaseUrl + '/api/bootcampTeachableUser', {
      method: 'POST',
      data: {
        email: this.props.email,
        uid: this.props.uid,
        name: this.props.firstName + ' ' + this.props.lastName
      }
    })
      .catch(err => {
        console.log(err)
        Swal.fire({
          title: '<p style="font-size:70%;">There was an error creating your Teachble account, please reach out to customer support for assistance.</p>',
          icon: 'error',
          customClass : { 
            container : 'my-swal' 
          },
        });
      });

    if (!this.props.subData.autoRenewal || this.props.subData.productId.includes('monthly') || this.props.subData.productId.includes('enterprise')) {
      await axiosWithToken(functionBaseUrl + '/api/pauseStripeSubByID/' + stripeSub.data.subscription.id, {
        method: 'POST',
      })
        .catch(err => {
          console.log(err);
        });
    }

    if (this.props.subscriptionType.includes('enterprise') && this.props.userData?.enterpriseAccountId) {
      await firebase.firestore().collection('enterpriseAccounts').doc(this.props.userData?.enterpriseAccountId).update({ status: 'Subscription Paid' });
    }

    await axiosWithToken(functionBaseUrl + '/api/bootcampEmail', {
      method: 'POST',
      data: {
        uid: this.props.uid,
        email: this.props.email,
        bootcampUser: this.props.userData.bootcampUser,
      }
    })
      .catch(err => {
        console.log(err);
      });

    await axiosWithToken(functionBaseUrl + '/api/subscription/' + this.props.subData.id, {
      method: 'PATCH',
      data: {
        transactionId: stripeSub.data.subscription.id,
        endDate: this.props.subData.productId.includes('monthly') ? String(moment().add(2, 'M').valueOf()) : (this.props.subData?.bootcampUser ? String(moment().add(14, 'M').valueOf()) : String(moment().add(12, 'M').valueOf())),
        lastUpdated: String(Date.now()),
      }
    })
      .then(() => {
        this.setState({ currentStep: 'welcome', loading: false });
        if (window.location.href.includes('mobileClient')) {
          if (this.props.userData.dominantHand === '') {
            window.location = "sportsboxclient://update_password";
          } else {
            window.location = "sportsboxclient://home";
          }
        }
      })
      .catch(err => {
        console.log(err)
        Swal.fire({
          title: '<p style="font-size:70%;">There was an error processing your subscription, please try again.</p>',
          icon: 'error',
          customClass : { 
            container : 'my-swal' 
          },
        });
      })
  }

  getDate = () => {
    return moment(String(this.props.subData.endDate), "x").format("MM/DD/YYYY");
  }

  handleSubmit = async () => {
    if (this.state.currentStep === 'agreements') {
      const options = { year: "numeric", month: "numeric", day: "numeric" };
      const date = new Date(
        new Date().setFullYear(new Date().getFullYear() - 13)
      )
        .toLocaleDateString("en-ZA", options)
        .replaceAll("/", "-");
      if (Date.parse(this.state.dob) > Date.parse(date)) {
        Swal.fire({
          title:
            '<p style="font-size:70%;">Sorry, you are not eligible for a Sportsbox.ai account at this time.</p>',
          confirmButtonText: "Ok",
          allowOutsideClick: false,
          customClass: {
            container: "my-swal",
          },
        }).then(async () => {
          await firebase
            .auth()
            .signOut()
            .then(() => {
              console.log("logged out" + firebase.auth().currentUser);
            })
            .catch((error) => {
              console.log(error);
            });
        });
      } else {
        await axiosWithToken(functionBaseUrl + "/api/users/" + this.props.uid, {
          method: "PATCH",
          data: {
            userInfo: {
              dob: this.state.dob.includes("/")
                ? this.state.dob
                : this.dobFormat(this.state.dob),
            },
          },
        })
        this.setState({ currentStep: 'creditCard' })
        return;
      }
    } else if (this.state.currentStep === 'creditCard') {
      this.setState({ currentStep: 'welcome' })
    }
  }

  handleSignOut = async () => {
    await firebase.auth().signOut().then(() => {
      window.location.href = window.location.href.replace('dashboard', 'sign-in');
    }).catch((error) => {
      console.log(error)
    });
  }

  getPlan = (productId) => {
    let plan = '';
    if (productId?.includes('student_lite')) {
      plan = '3D Practice';
    } else if (productId?.includes('pro_lite')) {
      plan = 'Pro Lite';
    } else if (productId?.includes('premium')) {
      plan = '3D Pro';
    } else if (productId?.includes('enterprise_master')) {
      plan = 'Enterprise Master';
    } else if (productId?.includes('enterprise_coach')) {
      plan = 'Enterprise Coach';
    } else {
      plan = 'Free';
    }
  
    if (productId?.includes('monthly')) {
      plan = 'Bootcamp';
    }
    if (productId?.includes('annually')) {
      plan += ' Annually';
    }
    if (!productId?.includes('monthly') && this.props.subData?.bootcampUser) {
      plan += ' + Bootcamp';
    }
  
    return plan;
  }

  render() {
    return (
      <div style={{ display: 'flex' }}>
        <Dialog
          fullWidth
          open={this.props.open}
          aria-labelledby='form-dialog-title'
          PaperProps={{
            style: {
              backgroundColor: this.state.currentStep === 'welcome' ? '#0B115A' : '',
            },
          }}>
          {this.state.currentStep === 'welcome' && <DialogTitle id={'form-dialog-close'}>
            <div>
              <CloseIcon style={{ float: 'right', marginTop: '-10px', marginRight: '-15px', color: 'white' }} onClick={this.props.onClose} />
            </div>
          </DialogTitle>}
          {this.state.currentStep !== 'welcome' && <DialogTitle id={'form-dialog-title'}>
            <div>
              <Typography
                variant="h5"
                color='secondary'
              >
                {this.pageTitle()}
              </Typography>
            </div>
          </DialogTitle>}
          <DialogContent>
            <Grid container component="main" maxWidth="xs">
              {this.state.currentStep === 'agreements' && (
                <Grid container spacing={1}>
                  <>
                    {this.props.userData.dob !== '' && ('amount' in this.props.subData === true) && (
                      this.setState({ currentStep: 'creditCard' })
                    )}
                  </>
                  <Grid item xs={6}>
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                      fullWidth
                      margin="dense"
                      variant="outlined"
                      id="date"
                      label="Date of Birth"
                      onChange={this.handleChange("dob")}
                      type="date"
                      value={this.state.dob}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          value={this.state.tosAgree}
                          onClick={this.handleTOSAgree}
                          checked={this.state.tosAgree ? 'checked' : null}
                        />
                      }
                      label={
                        <div>
                          <span>I have read and agree to Sportsbox's </span>
                          <Link onClick={(e) => {
                            e.preventDefault();
                            window.open(window.location.href.replace('dashboard', 'terms-of-service?newAccount'));
                          }}>Terms of Service
                          </Link>
                          <span> and </span>
                          <Link onClick={(e) => {
                            e.preventDefault();
                            window.open(window.location.href.replace('dashboard', 'privacy?newAccount'));
                          }}>Privacy Policy.
                          </Link>
                        </div>
                      }
                    />
                  </Grid>
                </Grid>
              )}
              {this.state.currentStep === 'creditCard' && ('amount' in this.props.subData === true) && (
                <Grid container spacing={1}>
                  {!this.state.loading ? <>
                    <Grid
                      item
                      xs={12}
                    >
                      <Box border={1} borderColor="primary.main" style={{ width: "100%", marginBottom: "5%" }}>
                        <Card>
                          <CardContent fullWidth>
                            <Grid container justify="space-between">
                              <Typography
                                inline
                                component="h6"
                                variant="h6"
                                align="left"
                              >
                                <b>Subscription Plan:</b>
                              </Typography>
                              <Typography
                                inline
                                component="h6"
                                variant="h6"
                                align="right"
                              >
                                {this.getPlan(this.props.subData.productId)}
                              </Typography>
                            </Grid>
                            <Grid container justify="space-between">
                              <Typography
                                inline
                                component="h6"
                                variant="h6"
                                align="left"
                              >
                                <b>Amount:</b>
                              </Typography>
                              <Typography
                                inline
                                component="h6"
                                variant="h6"
                                align="right"
                              >
                                ${this.props.subData.amount}
                              </Typography>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Box>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        //sm={6}
                        xs={12}
                      >
                        <StripePayment
                          amount={Number(this.props.subData.amount)}
                          email={this.props.email}
                          handleResult={this.createSubscription}
                          buttonText="Pay Now"
                          hideSavedCard={true}
                        />
                      </Grid>
                    </Grid></> :
                    <Container style={{ textAlign: 'center', paddingTop: '50px' }}>
                      <CircularProgress />
                    </Container>
                  }
                </Grid>
              )}
              {this.state.currentStep === 'welcome' && (
                <Grid container spacing={1}>
                  <><div className="cta-welcome">
                    <CheckIcon className="cta-welcome-mark" />
                    <div className="cta-welcome-caption"><span>Welcome to Sportsbox</span></div>
                    <div>
                      A confirmation email has been sent to: <b>{this.props.email}</b>.
                      <br /><br />
                      Complete your Sportsbox journey today by downloading our app. Log into the app with the email you signed up with.
                    </div>
                    <div className="cta-welcome-apps">
                      <a href="https://apps.apple.com/us/app/sportsbox-3dgolf/id1578921026">
                        <img
                          alt="Apple"
                          src="/images/logos/apple.svg"
                          width={170}
                        />
                      </a>
                      <a href="https://play.google.com/store/apps/details?id=com.sportsbox.golfai">
                        <img
                          alt="Google"
                          src="/images/logos/google.svg"
                          width={170}
                        />
                      </a>
                    </div>
                </div></>
                </Grid>
              )}
            </Grid>
          </DialogContent>
          {this.state.currentStep === 'agreements' && <DialogActions>
            <Button onClick={this.handleSignOut} color="secondary">
              Sign Out
            </Button>
            <Button onClick={this.handleSubmit} color="secondary" disabled={this.getDisabled()}>
              {this.getButton()}
            </Button>
          </DialogActions>}
        </Dialog>
      </div>
    )
  }
}

BootcampCheck.propTypes = {
  item: PropTypes.object,
  open: PropTypes.bool,
  closeCreatePopUp: PropTypes.func,
  submitQuery: PropTypes.func,
  values: PropTypes.object
};

export default BootcampCheck;