import React, { useState, useEffect } from 'react';
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Box, Button, Card, CardContent } from '@material-ui/core';

import { axiosWithToken, functionBaseUrl } from '../../common/firebase';
//import { StripeAddressForm } from '../stripeAddressForm';

function StripeSubscriptionFormComponent({ clientSecret, subscriptionId, handleResult, fullName }) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentIntent, setPaymentIntent] = useState();
  const [amount, setAmount] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    await elements.submit();

    //const addressElement = elements.getElement('address');
    //const { value } = await addressElement.getValue();

    if (clientSecret.includes('seti_')) {
      let { error, setupIntent } = await stripe.confirmSetup({
        elements,
        clientSecret,
        redirect: "if_required",
        /*confirmParams: {
          payment_method_data: {
            billing_details: value,
          }
        }*/
      });

      setIsLoading(false);

      if (error) {
        // show error and collect new card details.
        setMessage(error.message);
        return;
      }
      await axiosWithToken(functionBaseUrl + '/api/stripeSub', {
        method: 'post',
        data: { subscriptionId: subscriptionId }
      })
        .then((response) => {
          setAmount(response.data?.plan?.amount);
        })
      setPaymentIntent(setupIntent);
    } else {
      const {error: submitError} = await elements.submit();
      if (submitError) {
        setMessage(submitError.message);
        setIsLoading(false);
        return;
      }
      
      const paymentResult = await stripe.confirmPayment({
        elements,
        clientSecret,
        redirect: "if_required",
        /*confirmParams: {
          payment_method_data: {
            billing_details: value,
          }
        }*/
      });

      setIsLoading(false);

      if (paymentResult?.error) {
        // show error and collect new card details.
        setMessage(paymentResult.error.message);
        return;
      }
      setPaymentIntent(paymentResult.paymentIntent);
    }
  };

  useEffect(() => {
    if (paymentIntent && paymentIntent.status === 'succeeded') {
      handleResult({
        amount: 'amount' in paymentIntent ? paymentIntent.amount : amount,
        id: subscriptionId,
      });
    }
  }, [paymentIntent]);

  return (<>
    <Box>
      <Card>
        <CardContent>
          <form onSubmit={handleSubmit}>
            {/*<StripeAddressForm defaultValues={{ name: fullName }}/>
            <div style={{ marginTop: 20 }}></div>*/}
            <PaymentElement options={{ layout: { type: 'accordion' } }} />
            <Button
              disabled={isLoading || !stripe || !elements || paymentIntent?.status === 'succeeded'}
              id="submit"
              color="primary"
              fullWidth
              type="submit"
              variant="contained"
              style={{ marginTop: 20 }}
            >
              <span id="button-text">
                {isLoading ? <div className="spinner" id="spinner"></div> : "Subscribe"}
              </span>
            </Button>
            {message && <div id="payment-message">{message}</div>}
          </form>
        </CardContent>
      </Card>
    </Box>
  </>);
}

export const StripeSubscriptionForm = StripeSubscriptionFormComponent;