import { Component } from 'react';

//Wrapper in order to reduce re-rendering of the advanced search page on textfield updates
class QueryFieldsToggle extends Component {
    //Only update when the changed item was of the same type
    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.changed === this.props.type
    }
    render() {
        return (this.props.createToggle(this.props.type, this.props.label))
    }
}

export default QueryFieldsToggle