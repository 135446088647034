import React from "react";
import {
  FilledSummaryContainer,
  FilledSummaryItem,
  FilledSummaryItemReps,
  FilledSummaryItemsContainer,
  FilledSummaryItemsSubContainer,
  FilledSummaryTitleContainer,
  FilledWatchlistName,
} from "./styled";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { FlexWrap, Pad } from "../../../../components";
import { watchlistIdMapper } from "../../config";

export function FilledWatchlistTrackers({
  items,
  type,
  title,
  onRemove,
  onAdd,
}) {
  return (
    <FilledSummaryContainer>
      <FilledSummaryTitleContainer>
        <div>{title}</div>
        <AddIcon
          onClick={onAdd}
          style={{ fontSize: "24px", color: "purple", cursor: "pointer" }}
        />
      </FilledSummaryTitleContainer>
      <FilledSummaryItemsContainer>
        {items.map((el) => (
          <div>
            <FlexWrap justify="space-between" style={{ padding: "10px 5px" }}>
              <FilledWatchlistName>{el.name}</FilledWatchlistName>
              <DeleteIcon
                onClick={() => onRemove(el.uuid)}
                style={{ cursor: "pointer", fontSize: "24px" }}
              />
            </FlexWrap>
            <div>
              {el.trackers.map((el) => (
                <>
                  <FilledSummaryItem key={el.watchlistId}>
                    <div>
                      <div>{el.trackerName}</div>
                      <div>
                        Range {el.rangeMin} to {el.rangeMax}
                      </div>
                    </div>
                    <div></div>
                  </FilledSummaryItem>
                  <Pad height="10px" />
                </>
              ))}
            </div>
          </div>
        ))}
      </FilledSummaryItemsContainer>
    </FilledSummaryContainer>
  );
}
