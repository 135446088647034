import React, { forwardRef, Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import firebase, {
  functionBaseUrl,
  axiosWithToken,
} from "../../common/firebase";
import { withStyles } from "@material-ui/styles";
import {
  createMuiTheme,
  MuiThemeProvider,
  Typography,
  CssBaseline,
  Container,
  Avatar,
  Grid,
  CircularProgress,
  IconButton,
  MenuItem,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Select,
} from "@material-ui/core";
import BarChartIcon from "@material-ui/icons/BarChart";
import palette from "../../theme/palette";
import MaterialTable from "material-table";
import { AccountNotFound } from "../account/components";
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
  DateRange,
} from "@material-ui/icons";
import { SummaryCharts } from "./SummaryCharts";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRangePicker } from "react-date-range";
import { CsvBuilder } from "filefy";
import jsPDF from "jspdf";
import "jspdf-autotable";

const useStyles = (theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    marginTop: theme.spacing(4),
  },
  buttonLink: {
    // This is a link component surrounding a button component
    width: "100%",
  },
  linkedButton: {
    // This is a button component surrounded by a link
    margin: theme.spacing(1, 0),
  },
});

const tableIcons = {
  ExportCSV: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  ExportPDF: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const theme = createMuiTheme({
  typography: {
    h6: {
      fontWeight: 500,
      fontSize: "20px",
      letterSpacing: "-0.05px",
      lineHeight: "20px",
    },
  },
  palette: {
    secondary: {
      main: palette.primary.main,
    },
  },
});

const tableHeads = {
  subscriptions: [
    { field: "userName", title: "Name" },
    { field: "userEmail", title: "Email" },
    { field: "vip", title: "VIP Account" },
    { field: "staffCoach", title: "Staff Coach Sub" },
    { field: "bootcampUser", title: "Bootcamp User" },
    { field: "hasACoach", title: "Associated with a Coach?" },
    { field: "platform", title: "Platform" },
    { field: "plan", title: "Plan" },
    { field: "autoRenewal", title: "Auto Renewal" },
    //{field: 'startDate', title: 'Start Date', customSort: (a, b) => new Date(a.startDate) - new Date(b.startDate), defaultSort: 'desc'},
    {
      field: "startDate",
      title: "Start Date",
      render: (rowData) => {
        return moment(String(rowData.startDate), "x").format("MM/DD/YYYY");
      },
      defaultSort: "desc",
    },
    {
      field: "lastUpdated",
      title: "Last Updated",
      render: (rowData) => {
        return moment(String(rowData.lastUpdated), "x").format("MM/DD/YYYY");
      },
    },
    //{field: 'endDate', title: 'End Date', customSort: (a, b) => new Date(a.endDate) - new Date(b.endDate)},
    {
      field: "endDate",
      title: "End Date",
      render: (rowData) => {
        return moment(String(rowData.endDate), "x").format("MM/DD/YYYY");
      },
    },
  ],
  subscriptionsEnd: [
    { field: "userName", title: "Name" },
    { field: "userEmail", title: "Email" },
    { field: "platform", title: "Platform" },
    { field: "plan", title: "Plan" },
    { field: "autoRenewal", title: "Auto Renewal" },
    {
      field: "startDate",
      title: "Start Date",
      customSort: (a, b) => new Date(a.startDate) - new Date(b.startDate),
      defaultSort: "desc",
    },
    {
      field: "lastUpdated",
      title: "Last Updated",
      customSort: (a, b) => new Date(a.endDate) - new Date(b.endDate),
    },
    {
      field: "endDate",
      title: "End Date",
      customSort: (a, b) => new Date(a.endDate) - new Date(b.endDate),
    },
  ],
  subscriptionsCanceled: [
    { field: "userName", title: "Name" },
    { field: "userEmail", title: "Email" },
    { field: "vip", title: "VIP Account" },
    { field: "platform", title: "Platform" },
    { field: "plan", title: "Plan" },
    //{field: 'sessions', title: '# of Sessions'},
    //{field: 'lastSession', title: 'Last Uploaded Session'},
    { field: "autoRenewal", title: "Auto Renewal" },
    {
      field: "startDate",
      title: "Start Date",
      customSort: (a, b) => new Date(a.startDate) - new Date(b.startDate),
      defaultSort: "desc",
    },
    {
      field: "lastUpdated",
      title: "Last Updated",
      customSort: (a, b) => new Date(a.endDate) - new Date(b.endDate),
    },
    {
      field: "endDate",
      title: "End Date",
      customSort: (a, b) => new Date(a.endDate) - new Date(b.endDate),
    },
  ],
  trialSubs: [
    { field: "userName", title: "Name", filtering: false },
    { field: "userEmail", title: "Email", filtering: false },
    { field: "platform", title: "Platform", filtering: false },
    { field: "plan", title: "Plan", filtering: false },
    {
      field: "startDate",
      title: "Start Date",
      render: (rowData) => {
        return moment(String(rowData.startDate), "x").format("MM/DD/YYYY");
      },
      defaultSort: "desc",
      /*customFilterAndSearch: (term, rowData) => {
        const startDate = moment(rowData.startDate, 'MM-DD-YYYY');
        const currentDate = moment();
        const threeWeeksAgo = currentDate.clone().subtract(3, 'weeks');
        return startDate.isSameOrAfter(threeWeeksAgo) && startDate.isSameOrBefore(currentDate);
      },*/
    },
    {
      field: "endDate",
      title: "End Date",
      filtering: false,
      render: (rowData) => {
        return moment(String(rowData.endDate), "x").format("MM/DD/YYYY");
      },
    },
    {
      field: "lastUpdated",
      title: "Last Updated",
      filtering: false,
      render: (rowData) => {
        return moment(String(rowData.lastUpdated), "x").format("MM/DD/YYYY");
      },
    },
    { field: "status", title: "Status", filtering: false },
    { field: "students", title: "# of Students", filtering: false },
    { field: "sessions", title: "# of Sessions", filtering: false },
    { field: "issues", title: "# of S&M", filtering: false },
  ],
  downgradeUsers: [
    { field: "userName", title: "Name" },
    { field: "userEmail", title: "Email" },
    { field: "vip", title: "VIP Account" },
    {
      field: "startDate",
      title: "Start Date",
      customSort: (a, b) => new Date(a.startDate) - new Date(b.startDate),
      defaultSort: "desc",
    },
    {
      field: "endDate",
      title: "End Date",
      customSort: (a, b) => new Date(a.endDate) - new Date(b.endDate),
    },
    { field: "oldPlan", title: "Old Plan" },
    { field: "currentPlan", title: "Current Plan" },
  ],
  nyCampaign: [
    { field: "userName", title: "Name" },
    { field: "email", title: "Email" },
    { field: "paid", title: "Paid" },
    { field: "typicalMiss", title: "Typical Miss" },
    { field: "shotTrajectory", title: "Shot Trajectory" },
    { field: "sessions", title: "# of Sessions" },
    {
      field: "date",
      title: "Account Created",
      customSort: (a, b) => new Date(a.date) - new Date(b.date),
      defaultSort: "desc",
    },
  ],
  freeAccounts: [
    { field: "userName", title: "Name" },
    { field: "userEmail", title: "Email" },
    { field: "dob", title: "DOB" },
    { field: "coaches", title: "Associated with a Coach?" },
    { field: "sessions", title: "# of Sessions" },
    { field: "certification", title: "Certified" },
    {
      field: "date",
      title: "Account Created",
      customSort: (a, b) => new Date(a.date) - new Date(b.date),
      defaultSort: "desc",
    },
  ],
  paidAccounts: [
    { field: "userName", title: "Name" },
    { field: "userEmail", title: "Email" },
    { field: "dob", title: "DOB" },
    //{field: 'vip', title: 'VIP Account'},
    { field: "plan", title: "Plan" },
    { field: "students", title: "# of Students" },
    { field: "sessions", title: "# of Sessions" },
    { field: "certification", title: "Certified" },
    {
      field: "date",
      title: "Account Created",
      customSort: (a, b) => new Date(a.date) - new Date(b.date),
      defaultSort: "desc",
    },
  ],
  staffAccounts: [
    { field: "userEmail", title: "Email" },
    { field: "amount", title: "Annual Price" },
    { field: "status", title: "Status" },
    { field: "onboardingCompleted", title: "Onboarding Completed" },
    { field: "students", title: "Total Students" },
    { field: "freeStudents", title: "# of Free Students" },
    { field: "paidStudents", title: "# of Paid Students" },
    {
      field: "date",
      title: "Account Created",
      customSort: (a, b) => new Date(a.date) - new Date(b.date),
      defaultSort: "desc",
    },
  ],
  invites: [
    { field: "invitee", title: "Invitee" },
    { field: "inviteeName", title: "Invitee Name" },
    { field: "sender", title: "Sender" },
    { field: "role", title: "Role" },
    { field: "status", title: "Status" },
    {
      field: "createdDate",
      title: "Created Date",
      customSort: (a, b) => new Date(a.createdDate) - new Date(b.createdDate),
      defaultSort: "desc",
    },
    {
      field: "expiredDate",
      title: "Expiry Date",
      customSort: (a, b) => new Date(a.expiredDate) - new Date(b.expiredDate),
    },
  ],
  total: [
    { field: "plan", title: "" },
    { field: "elavon", title: "Elavon" },
    { field: "apple", title: "Apple" },
    { field: "android", title: "Android" },
    { field: "stripe", title: "Stripe" },
    /*{ field: 'last24Hours', title: 'New in the last 24 Hours' },
    { field: 'last7Days', title: 'New in the last 7 Days' },
    { field: 'lastMonth', title: 'New in the last Month' },*/
    { field: "overall", title: "Total" },
  ],
  totalActive: [
    { field: "plan", title: "" },
    { field: "elavon", title: "Elavon" },
    { field: "apple", title: "Apple" },
    { field: "android", title: "Android" },
    { field: "stripe", title: "Stripe" },
    { field: "overall", title: "Total" },
  ],
  totalCanceled: [
    { field: "plan", title: "" },
    { field: "elavon", title: "Elavon" },
    { field: "apple", title: "Apple" },
    { field: "android", title: "Android" },
    { field: "stripe", title: "Stripe" },
    /*{ field: 'last24Hours', title: 'New in the last 24 Hours' },
    { field: 'last7Days', title: 'New in the last 7 Days' },
    { field: 'lastMonth', title: 'New in the last Month' },*/
    { field: "overall", title: "Total" },
  ],
  totalStudents: [
    { field: "students", title: "" },
    { field: "last24Hours", title: "New in the last 24 Hours" },
    { field: "last7Days", title: "New in the last 7 Days" },
    { field: "lastMonth", title: "New in the last Month" },
    { field: "overall", title: "Overall" },
  ],
  collCount: [
    { field: "collection", title: "Collection" },
    { field: "total", title: "Total" },
    { field: "lastUpdated", title: "Last Updated" },
  ],
};

class Summary extends Component {
  constructor(props) {
    const firebaseUser = firebase.auth().currentUser;
    super(props);
    this.state = {
      uid: firebaseUser.uid,
      error: "",
      loading: true,
      invites: [],
      subscriptions: [],
      subscriptionsEnd: [],
      subscriptionsCanceled: [],
      instructorsNotSub: [],
      nyCampaignUsers: [],
      freeAccounts: [],
      paidAccounts: [],
      trialSubs: [],
      downgradeUsers: [],
      selectedRows: {
        invites: [],
        subscriptions: [],
        subscriptionsEnd: [],
        subscriptionsCanceled: [],
        instructorsNotSub: [],
        freeAccounts: [],
        nyCampaignUsers: [],
        paidAccounts: [],
        trialSubs: [],
        downgradeUsers: [],
        allStaffEnt: [],
      },
      totalData: [],
      totalDataValues: [],
      activeData: [],
      totalActiveDataValues: [],
      totalCanceledData: [],
      totalCanceledDataValues: [],
      totalStudentsData: [],
      collCounts: [],
      allCoaches: [],
      allStudents: [],
      allStaffEnt: [],
      allCoachesInvites: [],
      allStudentsInvites: [],
      appleSubs: [],
      elavonSubs: [],
      androidSubs: [],
      appleCanceledSubs: [],
      elavonCanceledSubs: [],
      androidCanceledSubs: [],
      allCanceledUsers: [],
      count: 0,
      options: {},
      data: {},
      users: [],
      sessions: [],
      videos: [],
      issues: [],
      analysis: [],
      selectionRange: {
        startDate: new Date(Date.now() - 6 * 24 * 60 * 60 * 1000),
        endDate: new Date().setHours(23, 59, 59),
        key: "range1",
      },
      selectionRangeActive: {
        startDate: new Date(Date.now() - 6 * 24 * 60 * 60 * 1000),
        endDate: new Date().setHours(23, 59, 59),
        key: "range1",
      },
      openRangeDialog: false,
      openRangeDialogActive: false,
      selectedPlans: "ALL",
      selectedPlansActive: "ALL",
      selectionRangeCanceled: {
        startDate: new Date(Date.now() - 6 * 24 * 60 * 60 * 1000),
        endDate: new Date().setHours(23, 59, 59),
        key: "range1",
      },
      openRangeDialogCanceled: false,
      selectedPlansCanceled: "ALL",
    };
  }

  //Tests if an email is associated with a development account using wildcards
  //and a list of development accounts that are not caught by the wildcards
  isTestAccount = (email, deviceLimitExceptionList) => {
    if (!deviceLimitExceptionList)
      deviceLimitExceptionList = this.state.deviceLimitExceptionList;
    // const emailExceptionList = ["accuracy.perf@gmail.com","accuracy.faceon@gmail.com","accuracy.dtl@gmail.com","accuracy.collect1@gmail.com","accuracy.collect2@gmail.com","terryrowles@gmail.com","Sportsbox3dstaff@gmail.com","Jclay@pnwgolfcenters.com","Dan@fiveirongolf.com","wancoachtest1@gmail.com","ryan.crawley@pga.com","troondemo@gmail.com","ryan.crawley@pga.com","nikitadniestrov@tidway.com.ua","nashton0610@gmail.com","richk@aithinktank.com","mmoslenko@lagolfshafts.com","6connoro@gmail.com","noppwonglaw@gmail.com","timashton@frontiernet.net","databases.sb@gmail.com","mizuho.lpga@gmail.com","demo1.lpga@gmail.com","lululemon.3dgolf@gmail.com"]
    const must_not_terms = [/sb3d/, /3dsb/, /sportsbox/, /demo/, /_sb3d/];
    return (
      deviceLimitExceptionList?.includes(email) ||
      must_not_terms.some((term) => term.test(email))
    );
  };

  async componentDidMount() {
    if (!this.state.uid) {
      this.setState({ error: "Please log in to view summary reports" });
      this.setState({ loading: false });
    } else {
      const user = await firebase
        .firestore()
        .collection("users")
        .doc(this.state.uid)
        .get();
      if (!user) {
        console.log("Cannot find the user document");
        this.setState({
          loading: false,
          error: "There was an error retrieving your info",
        });
      }
      let role = user.data().role;
      this.setState({ role: role });
      if (!["admin", "data-admin"].includes(role)) {
        this.setState({
          loading: false,
          error: "You are not authorized to access this page.",
        });
      }

      const today = new Date();
      let allSubs = [];
      let allSubsEndTrial = [];
      let elavonSubs = [];
      let stripeSubs = [];
      let appleSubs = [];
      let androidSubs = [];
      let elavonSubs2 = [];
      let stripeSubs2 = [];
      let appleSubs2 = [];
      let androidSubs2 = [];
      let elavonCanceledSubs = [];
      let stripeCanceledSubs = [];
      let appleCanceledSubs = [];
      let androidCanceledSubs = [];

      //Retrieve list of emails associated with development accounts
      /*const deviceSnap = await firebase
        .firestore()
        .collection("mobileAppSettings")
        .doc("X3X42cbYDIT9t9BL43bz")
        .get();*/
      const deviceLimitExceptionList = [];
        //deviceSnap.data().deviceLimitExceptionList;

      const subsSnap = await firebase
        .firestore()
        .collection("subscriptions")
        .get();
      if (!subsSnap) {
        console.log("Error getting Subscriptions documents");
      }
      await firebase
        .firestore()
        .collection("count")
        .doc("subscriptions")
        .update({ total: subsSnap.size, lastUpdated: String(Date.now()) });
      subsSnap.forEach((doc) => {
        let data = doc.data();
        if (!this.isTestAccount(data?.userEmail, deviceLimitExceptionList)) {
          if (data.platform === "Elavon") {
            if (data.autoRenewal) {
              elavonSubs.push({ ...data, id: doc.id });
            } else {
              if (
                parseInt(data.amount) === 0 ||
                (data.transactionId !== "" &&
                  (parseInt(data.amount) === 100 ||
                    parseInt(data.amount) === 200))
              ) {
                elavonSubs.push({ ...data, id: doc.id });
              }
              if (data.transactionId !== "") {
                elavonCanceledSubs.push({ ...data, id: doc.id });
              }
            }
            if (
              data.endDate > String(Date.now()) &&
              data.transactionId !== "" &&
              !data?.bootcampUser
            ) {
              elavonSubs2.push({ ...data, id: doc.id });
            }
          }
          if (data.platform === "Apple") {
            if (data.autoRenewal) {
              appleSubs.push({ ...data, id: doc.id });
            } else {
              appleCanceledSubs.push({ ...data, id: doc.id });
            }
            if (data.endDate > String(Date.now())) {
              appleSubs2.push({ ...data, id: doc.id });
            }
          }
          if (data.platform === "Android") {
            if (data.autoRenewal) {
              androidSubs.push({ ...data, id: doc.id });
            } else {
              androidCanceledSubs.push({ ...data, id: doc.id });
            }
            if (data.endDate > String(Date.now())) {
              androidSubs2.push({ ...data, id: doc.id });
            }
          }
          if (data.platform === "Stripe") {
            if (data.autoRenewal) {
              stripeSubs.push({ ...data, id: doc.id });
            } else {
              if (
                parseInt(data.amount) === 0 ||
                (data.transactionId !== "" &&
                  (parseInt(data.amount) === 100 ||
                    parseInt(data.amount) === 200))
              ) {
                stripeSubs.push({ ...data, id: doc.id });
              }
              if (data.transactionId !== "") {
                stripeCanceledSubs.push({ ...data, id: doc.id });
              }
            }
            if (
              data.endDate > String(Date.now()) &&
              data.transactionId !== "" &&
              !data?.bootcampUser
            ) {
              stripeSubs2.push({ ...data, id: doc.id });
            }
          }
          allSubs.push({ ...data, id: doc.id });
        }
      });
      this.setState({
        subscriptions: allSubs,
        subscriptionsEnd: allSubsEndTrial,
        elavonSubs: elavonSubs,
        stripeSubs: stripeSubs,
        appleSubs: appleSubs,
        androidSubs: androidSubs,
        elavonCanceledSubs: elavonCanceledSubs,
        stripeCanceledSubs: stripeCanceledSubs,
        appleCanceledSubs: appleCanceledSubs,
        androidCanceledSubs: androidCanceledSubs,
        deviceLimitExceptionList: deviceLimitExceptionList,
        loading: false,
      });

      if (
        elavonSubs.length > 0 &&
        appleSubs.length > 0 &&
        androidSubs.length > 0
      ) {
        Promise.all([elavonSubs, appleSubs, androidSubs, stripeSubs]).then(
          (values) => {
            this.setState({ totalDataValues: values });
            this.handleSubscriptions(
              values,
              this.state.selectionRange,
              this.state.selectedPlans
            );
          }
        );
      }

      if (
        elavonSubs2.length > 0 &&
        appleSubs2.length > 0 &&
        androidSubs2.length > 0
      ) {
        Promise.all([elavonSubs2, appleSubs2, androidSubs2, stripeSubs2]).then(
          (values) => {
            this.setState({ totalActiveDataValues: values });
            this.handleActiveSubscriptions(
              values,
              this.state.selectionRangeActive,
              this.state.selectedPlansActive
            );
          }
        );
      }

      if (
        elavonCanceledSubs.length > 0 &&
        appleCanceledSubs.length > 0 &&
        androidCanceledSubs.length > 0
      ) {
        Promise.all([
          elavonCanceledSubs,
          appleCanceledSubs,
          androidCanceledSubs,
          stripeCanceledSubs,
        ]).then((values) => {
          this.setState({ totalCanceledDataValues: values });
          this.handleSubscriptionsCanceled(
            values,
            this.state.selectionRangeCanceled,
            this.state.selectedPlansCanceled
          );
        });
      }

      if (allSubs.length > 0) {
        let canceledUsers = allSubs.filter(
          (sub) =>
            (sub.autoRenewal === false || sub.endDate < String(Date.now())) &&
            sub.productId.includes("enterprise") === false
        );
        this.setState({ allCanceledUsers: canceledUsers });
      }

      let allCoachesInvites = [];
      let allStudentsInvites = [];
      let allInvites = [];
      const inviteSnap = await firebase.firestore().collection("invites").get();

      if (!inviteSnap) {
        console.log("Error getting Invites documents");
      }
      inviteSnap.forEach((doc) => {
        let data = doc.data();
        if (!this.isTestAccount(data?.invitee)) {
          allInvites.push({ ...doc.data(), id: doc.id });
          if (data.role === "Sportsbox-Instructor") {
            allCoachesInvites.push({ ...doc.data(), id: doc.id });
          }
          if (data.role === "Sportsbox-User") {
            allStudentsInvites.push({ ...doc.data(), id: doc.id });
          }
        }
      });
      await firebase
        .firestore()
        .collection("count")
        .doc("invites")
        .update({ total: inviteSnap.size, lastUpdated: String(Date.now()) });
      this.setState({
        invites: allInvites,
        allCoachesInvites: allCoachesInvites,
        allStudentsInvites: allStudentsInvites,
      });

      let nyUsers = [];
      const nySnap = await firebase
        .firestore()
        .collection("users")
        .where("shotTrajectory", "!=", "")
        .get();
      nySnap.forEach((doc) => {
        if (!this.isTestAccount(doc.data()?.email))
          nyUsers.push({ ...doc.data(), id: doc.id });
      });
      this.setState({ nyCampaignUsers: nyUsers });

      /*await axiosWithToken(functionBaseUrl + "/api/freeTrials", {
        method: "GET",
      })
        .then((response) => {
          this.setState({
            trialSubs: response.data,
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false });
        });*/
      /*let collCounts = [];
      const countSnap = await firebase.firestore().collection("count").get();
      if (!countSnap) {
        console.log("Error getting Count documents");
      }
      countSnap.forEach((doc) => {
        collCounts.push({ ...doc.data(), id: doc.id });
        let data = doc.data();
        if (doc.id === "users") {
          collCounts.push({
            id: "Coaches",
            total: data.coaches,
            lastUpdated: data.lastUpdated,
          });
          collCounts.push({
            id: "Free Students",
            total: data.students,
            lastUpdated: data.lastUpdated,
          });
          collCounts.push({
            id: "Paid Students",
            total: data.paidStudents,
            lastUpdated: data.lastUpdated,
          });
          collCounts.push({
            id: "Free Students/Coach",
            total: data.freeStudentsPerProCoach,
            lastUpdated: data.lastUpdated,
          });
          collCounts.push({
            id: "Paid Students/Coach",
            total: data.paidStudentsPerProCoach,
            lastUpdated: data.lastUpdated,
          });
          collCounts.push({
            id: "Free Students/Staff Coach",
            total: data.freeStudentsPerStaffCoach,
            lastUpdated: data.lastUpdated,
          });
          collCounts.push({
            id: "Paid Students/Staff Coach",
            total: data.paidStudentsPerStaffCoach,
            lastUpdated: data.lastUpdated,
          });
        }
        if (doc.id === "sessions") {
          collCounts.push({
            id: "Sessions/User",
            total: Number(data.sessionsAvg).toFixed(2),
            lastUpdated: data.lastUpdated,
          });
        }
        if (doc.id === "videos") {
          collCounts.push({
            id: "Videos/Session",
            total: Number(data.videosAvg).toFixed(2),
            lastUpdated: data.lastUpdated,
          });
        }
        if (doc.id === "invites") {
          collCounts.push({
            id: "Invites",
            total: Number(data.total),
            lastUpdated: data.lastUpdated,
          });
          collCounts.push({
            id: "Approved Invites",
            total: Number(data.approved),
            lastUpdated: data.lastUpdated,
          });
          collCounts.push({
            id: "Pending Invites",
            total: Number(data.pending),
            lastUpdated: data.lastUpdated,
          });
          collCounts.push({
            id: "Approved/Pending Invites",
            total: Number(data.invitesAvg).toFixed(2),
            lastUpdated: data.lastUpdated,
          });
        }
      });
      this.setState({ collCounts: collCounts, loading: false });*/

      /*await axiosWithToken(functionBaseUrl + "/api/freeTrials", {
        method: "GET",
      })
        .then((response) => {
          this.setState({
            trialSubs: response.data,
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false });
        });*/

      let allUsers = [];
      let allCoaches = [];
      let allStudents = [];
      let allPaidStudents = [];
      const allUsersSnap = await firebase.firestore().collection("users").get();
      allUsersSnap.forEach((doc) => {
        let data = doc.data();
        if (!this.isTestAccount(data?.userEmail)) {
          allUsers.push({ ...data, id: doc.id });
          if (
            data.subscriptionType.length > 0 &&
            !data.subscriptionType.includes("free")
          ) {
            if (data.subscriptionType.includes("student_lite")) {
              allPaidStudents.push({ ...doc.data(), id: doc.id });
            } else {
              allCoaches.push({ ...doc.data(), id: doc.id });
            }
          } else {
            allStudents.push({ ...doc.data(), id: doc.id });
          }
        }
      });

      /*const allStaffEntSnap = await axiosWithToken(
        functionBaseUrl + "/api/staffStudents",
        {
          method: "POST",
        }
      );
      if (allStaffEntSnap) {
        this.setState({ allStaffEnt: allStaffEntSnap.data });
      }*/
      this.setState({
        users: allUsers,
        allCoaches: allCoaches,
        allStudents: allStudents,
      });

      if (
        allStudents.length > 0 &&
        allCoaches.length > 0 &&
        allCoachesInvites.length > 0 &&
        allStudentsInvites.length > 0
      ) {
        Promise.all([
          allStudents,
          allCoaches,
          allCoachesInvites,
          allStudentsInvites,
        ]).then((values) => {
          let allUsersResult = [
            {
              students: "Students",
              last24Hours: 0,
              last7Days: 0,
              lastMonth: 0,
              overall: 0,
            },
            {
              students: "Coaches",
              last24Hours: 0,
              last7Days: 0,
              lastMonth: 0,
              overall: 0,
            },
            {
              students: "Coach Invites Sent",
              last24Hours: 0,
              last7Days: 0,
              lastMonth: 0,
              overall: 0,
            },
            {
              students: "Student Invites Sent",
              last24Hours: 0,
              last7Days: 0,
              lastMonth: 0,
              overall: 0,
            },
            {
              students: "Total Users",
              last24Hours: 0,
              last7Days: 0,
              lastMonth: 0,
              overall: 0,
            },
            {
              students: "Total Invites Sent",
              last24Hours: 0,
              last7Days: 0,
              lastMonth: 0,
              overall: 0,
            },
          ];
          values.forEach((value, index) => {
            value.forEach((user) => {
              let start = 0;
              if (user.userCreated) {
                start = moment(parseInt(user.userCreated));
              } else {
                start = moment(parseInt(user.createdAt));
              }
              let hours = moment.duration(start.diff(today)).asHours();
              let days = moment.duration(start.diff(today)).asDays();
              let months = moment.duration(start.diff(today)).asMonths();

              if (-hours <= 24) {
                allUsersResult[index].last24Hours++;
                if (user.userCreated) {
                  allUsersResult[4].last24Hours++;
                } else {
                  allUsersResult[5].last24Hours++;
                }
              }
              if (-days <= 7) {
                allUsersResult[index].last7Days++;
                if (user.userCreated) {
                  allUsersResult[4].last7Days++;
                } else {
                  allUsersResult[5].last7Days++;
                }
              }
              if (-months <= 1) {
                allUsersResult[index].lastMonth++;
                if (user.userCreated) {
                  allUsersResult[4].lastMonth++;
                } else {
                  allUsersResult[5].lastMonth++;
                }
              }
              allUsersResult[index].overall++;
              if (user.userCreated) {
                allUsersResult[4].overall++;
              } else {
                allUsersResult[5].overall++;
              }
            });
          });
          this.setState({ totalStudentsData: allUsersResult });
        });
      }

      if (allCoaches.length > 0 && allSubs.length > 0) {
        let nonSubUsers = allCoaches.filter(
          (ar) => !allSubs.find((rm) => rm.userId === ar.id)
        );
        this.setState({ instructorsNotSub: nonSubUsers });
      }

      if (allUsers.length > 0 && allSubs.length > 0) {
        let downgradeUsers = allSubs.filter((ar) =>
          allUsers.find(
            (rm) => rm.id === ar.userId && rm.subscriptionType !== ar.productId
          )
        );
        this.setState({ downgradeUsers: downgradeUsers });
      }

      this.setState({ loading: false });

      //await firebase.firestore().collection('count').doc('users').update({ total: allUsersSnap.size, coaches: allCoaches.length, students: allStudents.length, lastUpdated: String(Date.now()) });
      //this.setState({ freeAccounts: allStudents, paidAccounts: allCoaches.concat(allPaidStudents), loading: false });

      /*let issues = [];
      const issSnap = await firebase.firestore().collection('reportIssues').get();
      if (!issSnap) {
        console.log("Error getting Issues documents")
      }
      await firebase.firestore().collection('count').doc('issues').update({ total: issSnap.size, lastUpdated: String(Date.now()) });
      issSnap.forEach(doc => { issues.push({ ...doc.data(), id: doc.id }) });
      this.setState({ issues: issues, loading: false });

      let analysis = [];
      const analysisSnap = await firebase.firestore().collection('analysis').get();
      if (!analysisSnap) {
        console.log("Error getting Analysis documents")
      }
      analysisSnap.forEach(doc => { analysis.push({ ...doc.data(), id: doc.id }) });
      await firebase.firestore().collection('count').doc('analysis').update({ total: analysisSnap.size, lastUpdated: String(Date.now()) });
      this.setState({ analysis: analysis, loading: false });*/

      /*let sessions = [];
      const sessSnap = await firebase.firestore().collection('sessions').get();
      if (!sessSnap) {
        console.log("Error getting Sessions documents")
      }
      await firebase.firestore().collection('count').doc('sessions').update({ total: sessSnap.size, sessionsAvg: sessSnap.size / allUsersSnap.size, lastUpdated: String(Date.now()) });
      sessSnap.forEach(doc => { sessions.push({ ...doc.data(), id: doc.id }) });
      this.setState({ sessions: sessions, loading: false });

      let videos = [];
      const vidSnap = await firebase.firestore().collection('videos').get();
      if (!vidSnap) {
        console.log("Error getting Videos documents")
      }
      await firebase.firestore().collection('count').doc('videos').update({ total: vidSnap.size, videosAvg: vidSnap.size / sessSnap.size, lastUpdated: String(Date.now()) });
      vidSnap.forEach(doc => { videos.push({ ...doc.data(), id: doc.id }) });
      this.setState({ videos: videos, loading: false });*/
    }
  }

  handleSelectRange = (ranges) => {
    this.setState({ selectionRange: ranges.range1 });
    this.handleSubscriptions(
      this.state.totalDataValues,
      ranges.range1,
      this.state.selectedPlans
    );
  };

  handleSubsPlan = (plan) => {
    this.setState({ selectedPlans: plan.target.value });
    this.handleSubscriptions(
      this.state.totalDataValues,
      this.state.selectionRange,
      plan.target.value
    );
  };

  handleSubscriptions = (values, range, plan) => {
    let totalsResult = [
      {
        plan: "Pro Lite - Monthly",
        elavon: 0,
        apple: 0,
        android: 0,
        stripe: 0,
        last24Hours: 0,
        last7Days: 0,
        lastMonth: 0,
        overall: 0,
      },
      {
        plan: "Pro Lite - Annual",
        elavon: 0,
        apple: 0,
        android: 0,
        stripe: 0,
        last24Hours: 0,
        last7Days: 0,
        lastMonth: 0,
        overall: 0,
      },
      {
        plan: "Plus - Monthly",
        elavon: 0,
        apple: 0,
        android: 0,
        stripe: 0,
        last24Hours: 0,
        last7Days: 0,
        lastMonth: 0,
        overall: 0,
      },
      {
        plan: "Plus - Annual",
        elavon: 0,
        apple: 0,
        android: 0,
        stripe: 0,
        last24Hours: 0,
        last7Days: 0,
        lastMonth: 0,
        overall: 0,
      },
      {
        plan: "Premium - Monthly",
        elavon: 0,
        apple: 0,
        android: 0,
        stripe: 0,
        last24Hours: 0,
        last7Days: 0,
        lastMonth: 0,
        overall: 0,
      },
      {
        plan: "Premium - Annual",
        elavon: 0,
        apple: 0,
        android: 0,
        stripe: 0,
        last24Hours: 0,
        last7Days: 0,
        lastMonth: 0,
        overall: 0,
      },
      {
        plan: "Enterprise Master",
        elavon: 0,
        apple: 0,
        android: 0,
        stripe: 0,
        last24Hours: 0,
        last7Days: 0,
        lastMonth: 0,
        overall: 0,
      },
      {
        plan: "Staff Account",
        elavon: 0,
        apple: 0,
        android: 0,
        stripe: 0,
        last24Hours: 0,
        last7Days: 0,
        lastMonth: 0,
        overall: 0,
      },
      {
        plan: "3D Player - Monthly",
        elavon: 0,
        apple: 0,
        android: 0,
        stripe: 0,
        last24Hours: 0,
        last7Days: 0,
        lastMonth: 0,
        overall: 0,
      },
      {
        plan: "3D Player - Annual",
        elavon: 0,
        apple: 0,
        android: 0,
        stripe: 0,
        last24Hours: 0,
        last7Days: 0,
        lastMonth: 0,
        overall: 0,
      },
      {
        plan: "Total Accounts",
        elavon: 0,
        apple: 0,
        android: 0,
        stripe: 0,
        last24Hours: 0,
        last7Days: 0,
        lastMonth: 0,
        overall: 0,
      },
    ];

    let pIndex = {
      sportsbox_pro_lite_monthly: 0,
      sportsbox_pro_lite_annually: 1,
      sportsbox_pro_plus_monthly: 2,
      sportsbox_pro_plus_annually: 3,
      sportsbox_pro_premium_monthly: 4,
      sportsbox_pro_premium_annually: 5,
      sportsbox_enterprise_master: 6,
      sportsbox_enterprise_staff: 7,
      sportsbox_student_lite_monthly: 8,
      sportsbox_student_lite_annually: 9,
    };

    values.forEach((subscriptions, index) => {
      let platformKey = "elavon";
      if (index === 1) {
        platformKey = "apple";
      }
      if (index === 2) {
        platformKey = "android";
      }
      if (index === 3) {
        platformKey = "stripe";
      }
      subscriptions.forEach((subscription) => {
        let planIndex = -1;
        switch (subscription.productId) {
          case "sportsbox_golf_lite_monthly":
            planIndex = 0;
            break;
          case "sportsbox_pro_lite_monthly":
            planIndex = 0;
            break;
          case "sportsbox_golf_lite_yearly":
            planIndex = 1;
            break;
          case "sportsbox_golf_lite_annually":
            planIndex = 1;
            break;
          case "sportsbox_pro_lite_yearly":
            planIndex = 1;
            break;
          case "sportsbox_pro_lite_annually":
            planIndex = 1;
            break;
          case "sportsbox_golf_plus_monthly":
            planIndex = 2;
            break;
          case "sportsbox_golf_plus_yearly":
            planIndex = 3;
            break;
          case "sportsbox_golf_premium_monthly":
            planIndex = 4;
            break;
          case "sportsbox_pro_premium_monthly":
            planIndex = 4;
            break;
          case "sportsbox_golf_premium_yearly":
            planIndex = 5;
            break;
          case "sportsbox_golf_premium_annually":
            planIndex = 5;
            break;
          case "sportsbox_pro_premium_yearly":
            planIndex = 5;
            break;
          case "sportsbox_pro_premium_annually":
            planIndex = 5;
            break;
          case "sportsbox_enterprise_master":
            if (
              (parseInt(subscription.amount) === 0 &&
                subscription.autoRenewal === false) ||
              (subscription.transactionId !== "" &&
                subscription.autoRenewal === false &&
                (parseInt(subscription.amount) === 100 ||
                  parseInt(subscription.amount) === 200))
            ) {
              planIndex = 7;
            }
            if (subscription.autoRenewal) {
              planIndex = 6;
            }
            break;
          case "sportsbox_student_lite_monthly":
            planIndex = 8;
            break;
          case "sportsbox_student_lite_annually":
            planIndex = 9;
            break;
          default:
            break;
        }
        let start = new Date(parseInt(subscription.startDate));
        if (
          planIndex > -1 &&
          start >= range.startDate &&
          start <= moment(range.endDate).endOf("day").valueOf()
        ) {
          totalsResult[planIndex][platformKey]++;
          totalsResult[planIndex].overall++;

          let countTotal = true;
          if (plan == "COACH") {
            if (planIndex > 7) {
              countTotal = false;
            }
          } else if (plan == "STUDENT") {
            if (planIndex < 8) {
              countTotal = false;
            }
          } else if (plan != "ALL" && plan != subscription.productId) {
            countTotal = false;
          }
          if (countTotal) {
            totalsResult[10][platformKey]++;
            totalsResult[10].overall++;
          }
        }
      });
    });

    if (plan == "COACH") {
      totalsResult = [
        totalsResult[0],
        totalsResult[1],
        totalsResult[2],
        totalsResult[3],
        totalsResult[4],
        totalsResult[5],
        totalsResult[6],
        totalsResult[7],
        totalsResult[10],
      ];
    } else if (plan == "STUDENT") {
      totalsResult = [totalsResult[8], totalsResult[9], totalsResult[10]];
    } else if (plan != "ALL") {
      totalsResult = [totalsResult[pIndex[plan]]];
    }

    this.setState({ totalData: totalsResult });
  };

  handleSelectRangeActive = (ranges) => {
    this.setState({ selectionRangeActive: ranges.range1 });
    this.handleActiveSubscriptions(
      this.state.totalActiveDataValues,
      ranges.range1,
      this.state.selectedPlansActive
    );
  };

  handleSubsPlanActive = (plan) => {
    this.setState({ selectedPlansActive: plan.target.value });
    this.handleActiveSubscriptions(
      this.state.totalActiveDataValues,
      this.state.selectionRangeActive,
      plan.target.value
    );
  };

  handleActiveSubscriptions = (values, range, plan) => {
    let totalsResult = [
      {
        plan: "Pro Lite - Monthly",
        elavon: 0,
        apple: 0,
        android: 0,
        stripe: 0,
        last24Hours: 0,
        last7Days: 0,
        lastMonth: 0,
        overall: 0,
      },
      {
        plan: "Pro Lite - Annual",
        elavon: 0,
        apple: 0,
        android: 0,
        stripe: 0,
        last24Hours: 0,
        last7Days: 0,
        lastMonth: 0,
        overall: 0,
      },
      {
        plan: "Plus - Monthly",
        elavon: 0,
        apple: 0,
        android: 0,
        stripe: 0,
        last24Hours: 0,
        last7Days: 0,
        lastMonth: 0,
        overall: 0,
      },
      {
        plan: "Plus - Annual",
        elavon: 0,
        apple: 0,
        android: 0,
        stripe: 0,
        last24Hours: 0,
        last7Days: 0,
        lastMonth: 0,
        overall: 0,
      },
      {
        plan: "Premium - Monthly",
        elavon: 0,
        apple: 0,
        android: 0,
        stripe: 0,
        last24Hours: 0,
        last7Days: 0,
        lastMonth: 0,
        overall: 0,
      },
      {
        plan: "Premium - Annual",
        elavon: 0,
        apple: 0,
        android: 0,
        stripe: 0,
        last24Hours: 0,
        last7Days: 0,
        lastMonth: 0,
        overall: 0,
      },
      {
        plan: "Enterprise Master",
        elavon: 0,
        apple: 0,
        android: 0,
        stripe: 0,
        last24Hours: 0,
        last7Days: 0,
        lastMonth: 0,
        overall: 0,
      },
      {
        plan: "Staff Account",
        elavon: 0,
        apple: 0,
        android: 0,
        stripe: 0,
        last24Hours: 0,
        last7Days: 0,
        lastMonth: 0,
        overall: 0,
      },
      {
        plan: "3D Player - Monthly",
        elavon: 0,
        apple: 0,
        android: 0,
        stripe: 0,
        last24Hours: 0,
        last7Days: 0,
        lastMonth: 0,
        overall: 0,
      },
      {
        plan: "3D Player - Annual",
        elavon: 0,
        apple: 0,
        android: 0,
        stripe: 0,
        last24Hours: 0,
        last7Days: 0,
        lastMonth: 0,
        overall: 0,
      },
      {
        plan: "Total Accounts",
        elavon: 0,
        apple: 0,
        android: 0,
        stripe: 0,
        last24Hours: 0,
        last7Days: 0,
        lastMonth: 0,
        overall: 0,
      },
    ];

    let pIndex = {
      sportsbox_pro_lite_monthly: 0,
      sportsbox_pro_lite_annually: 1,
      sportsbox_pro_plus_monthly: 2,
      sportsbox_pro_plus_annually: 3,
      sportsbox_pro_premium_monthly: 4,
      sportsbox_pro_premium_annually: 5,
      sportsbox_enterprise_master: 6,
      sportsbox_enterprise_staff: 7,
      sportsbox_student_lite_monthly: 8,
      sportsbox_student_lite_annually: 9,
    };

    values.forEach((subscriptions, index) => {
      let platformKey = "elavon";
      if (index === 1) {
        platformKey = "apple";
      }
      if (index === 2) {
        platformKey = "android";
      }
      if (index === 3) {
        platformKey = "stripe";
      }
      subscriptions.forEach((subscription) => {
        let planIndex = -1;
        switch (subscription.productId) {
          case "sportsbox_golf_lite_monthly":
            planIndex = 0;
            break;
          case "sportsbox_pro_lite_monthly":
            planIndex = 0;
            break;
          case "sportsbox_golf_lite_yearly":
            planIndex = 1;
            break;
          case "sportsbox_golf_lite_annually":
            planIndex = 1;
            break;
          case "sportsbox_pro_lite_yearly":
            planIndex = 1;
            break;
          case "sportsbox_pro_lite_annually":
            planIndex = 1;
            break;
          case "sportsbox_golf_plus_monthly":
            planIndex = 2;
            break;
          case "sportsbox_golf_plus_yearly":
            planIndex = 3;
            break;
          case "sportsbox_golf_premium_monthly":
            planIndex = 4;
            break;
          case "sportsbox_pro_premium_monthly":
            planIndex = 4;
            break;
          case "sportsbox_golf_premium_yearly":
            planIndex = 5;
            break;
          case "sportsbox_golf_premium_annually":
            planIndex = 5;
            break;
          case "sportsbox_pro_premium_yearly":
            planIndex = 5;
            break;
          case "sportsbox_pro_premium_annually":
            planIndex = 5;
            break;
          case "sportsbox_enterprise_master":
            if (
              (parseInt(subscription.amount) === 0 &&
                subscription.autoRenewal === false) ||
              (subscription.transactionId !== "" &&
                subscription.autoRenewal === false &&
                (parseInt(subscription.amount) === 100 ||
                  parseInt(subscription.amount) === 200))
            ) {
              planIndex = 7;
            }
            if (subscription.autoRenewal) {
              planIndex = 6;
            }
            break;
          case "sportsbox_student_lite_monthly":
            planIndex = 8;
            break;
          case "sportsbox_student_lite_annually":
            planIndex = 9;
            break;
          default:
            break;
        }
        let start = new Date(parseInt(subscription.startDate));
        if (
          planIndex > -1 &&
          start >= range.startDate &&
          start <= moment(range.endDate).endOf("day").valueOf()
        ) {
          totalsResult[planIndex][platformKey]++;
          totalsResult[planIndex].overall++;

          let countTotal = true;
          if (plan == "COACH") {
            if (planIndex > 7) {
              countTotal = false;
            }
          } else if (plan == "STUDENT") {
            if (planIndex < 8) {
              countTotal = false;
            }
          } else if (plan != "ALL" && plan != subscription.productId) {
            countTotal = false;
          }
          if (countTotal) {
            totalsResult[10][platformKey]++;
            totalsResult[10].overall++;
          }
        }
      });
    });

    if (plan == "COACH") {
      totalsResult = [
        totalsResult[0],
        totalsResult[1],
        totalsResult[2],
        totalsResult[3],
        totalsResult[4],
        totalsResult[5],
        totalsResult[6],
        totalsResult[7],
        totalsResult[10],
      ];
    } else if (plan == "STUDENT") {
      totalsResult = [totalsResult[8], totalsResult[9], totalsResult[10]];
    } else if (plan != "ALL") {
      totalsResult = [totalsResult[pIndex[plan]]];
    }

    this.setState({ activeData: totalsResult });
  };

  handleSelectRangeCanceled = (ranges) => {
    this.setState({ selectionRangeCanceled: ranges.range1 });
    this.handleSubscriptionsCanceled(
      this.state.totalCanceledDataValues,
      ranges.range1,
      this.state.selectedPlansCanceled
    );
  };

  handleSubsPlanCanceled = (plan) => {
    this.setState({ selectedPlansCanceled: plan.target.value });
    this.handleSubscriptionsCanceled(
      this.state.totalCanceledDataValues,
      this.state.selectionRangeCanceled,
      plan.target.value
    );
  };

  handleSubscriptionsCanceled = (values, range, plan) => {
    let totalsCanceledResult = [
      {
        plan: "Pro Lite - Monthly",
        elavon: 0,
        apple: 0,
        android: 0,
        stripe: 0,
        last24Hours: 0,
        last7Days: 0,
        lastMonth: 0,
        overall: 0,
      },
      {
        plan: "Pro Lite - Annual",
        elavon: 0,
        apple: 0,
        android: 0,
        stripe: 0,
        last24Hours: 0,
        last7Days: 0,
        lastMonth: 0,
        overall: 0,
      },
      {
        plan: "Plus - Monthly",
        elavon: 0,
        apple: 0,
        android: 0,
        stripe: 0,
        last24Hours: 0,
        last7Days: 0,
        lastMonth: 0,
        overall: 0,
      },
      {
        plan: "Plus - Annual",
        elavon: 0,
        apple: 0,
        android: 0,
        stripe: 0,
        last24Hours: 0,
        last7Days: 0,
        lastMonth: 0,
        overall: 0,
      },
      {
        plan: "Premium - Monthly",
        elavon: 0,
        apple: 0,
        android: 0,
        stripe: 0,
        last24Hours: 0,
        last7Days: 0,
        lastMonth: 0,
        overall: 0,
      },
      {
        plan: "Premium - Annual",
        elavon: 0,
        apple: 0,
        android: 0,
        stripe: 0,
        last24Hours: 0,
        last7Days: 0,
        lastMonth: 0,
        overall: 0,
      },
      {
        plan: "Enterprise Master",
        elavon: 0,
        apple: 0,
        android: 0,
        stripe: 0,
        last24Hours: 0,
        last7Days: 0,
        lastMonth: 0,
        overall: 0,
      },
      {
        plan: "3D Player - Monthly",
        elavon: 0,
        apple: 0,
        android: 0,
        stripe: 0,
        last24Hours: 0,
        last7Days: 0,
        lastMonth: 0,
        overall: 0,
      },
      {
        plan: "3D Player - Annual",
        elavon: 0,
        apple: 0,
        android: 0,
        stripe: 0,
        last24Hours: 0,
        last7Days: 0,
        lastMonth: 0,
        overall: 0,
      },
      {
        plan: "Total Accounts",
        elavon: 0,
        apple: 0,
        android: 0,
        stripe: 0,
        last24Hours: 0,
        last7Days: 0,
        lastMonth: 0,
        overall: 0,
      },
    ];

    let pIndex = {
      sportsbox_pro_lite_monthly: 0,
      sportsbox_pro_lite_annually: 1,
      sportsbox_pro_plus_monthly: 2,
      sportsbox_pro_plus_annually: 3,
      sportsbox_pro_premium_monthly: 4,
      sportsbox_pro_premium_annually: 5,
      sportsbox_enterprise_master: 6,
      sportsbox_student_lite_monthly: 7,
      sportsbox_student_lite_annually: 8,
    };

    values.forEach((subscriptions, index) => {
      let platformKey = "elavon";
      if (index === 1) {
        platformKey = "apple";
      }
      if (index === 2) {
        platformKey = "android";
      }
      if (index === 3) {
        platformKey = "stripe";
      }
      subscriptions.forEach((subscription) => {
        let planIndex = -1;
        switch (subscription.productId) {
          case "sportsbox_golf_lite_monthly":
            planIndex = 0;
            break;
          case "sportsbox_pro_lite_monthly":
            planIndex = 0;
            break;
          case "sportsbox_golf_lite_yearly":
            planIndex = 1;
            break;
          case "sportsbox_golf_lite_annually":
            planIndex = 1;
            break;
          case "sportsbox_pro_lite_yearly":
            planIndex = 1;
            break;
          case "sportsbox_pro_lite_annually":
            planIndex = 1;
            break;
          case "sportsbox_golf_plus_monthly":
            planIndex = 2;
            break;
          case "sportsbox_golf_plus_yearly":
            planIndex = 3;
            break;
          case "sportsbox_golf_premium_monthly":
            planIndex = 4;
            break;
          case "sportsbox_pro_premium_monthly":
            planIndex = 4;
            break;
          case "sportsbox_golf_premium_yearly":
            planIndex = 5;
            break;
          case "sportsbox_golf_premium_annually":
            planIndex = 5;
            break;
          case "sportsbox_pro_premium_yearly":
            planIndex = 5;
            break;
          case "sportsbox_pro_premium_annually":
            planIndex = 5;
            break;
          case "sportsbox_enterprise_master":
            if (
              parseInt(subscription.amount) !== 0 &&
              parseInt(subscription.amount) !== 1 &&
              parseInt(subscription.amount) !== 100 &&
              parseInt(subscription.amount) !== 200
            ) {
              planIndex = 6;
            }
            break;
          case "sportsbox_student_lite_monthly":
            planIndex = 8;
            break;
          case "sportsbox_student_lite_annually":
            planIndex = 9;
            break;
          default:
            break;
        }
        let start = new Date(parseInt(subscription.startDate));
        if (
          planIndex > -1 &&
          start >= range.startDate &&
          start <= moment(range.endDate).endOf("day").valueOf()
        ) {
          totalsCanceledResult[planIndex][platformKey]++;
          totalsCanceledResult[planIndex].overall++;

          let countTotal = true;
          if (plan == "COACH") {
            if (planIndex > 8) {
              countTotal = false;
            }
          } else if (plan == "STUDENT") {
            if (planIndex < 7) {
              countTotal = false;
            }
          } else if (plan != "ALL" && plan != subscription.productId) {
            countTotal = false;
          }
          if (countTotal) {
            totalsCanceledResult[9][platformKey]++;
            totalsCanceledResult[9].overall++;
          }
        }
      });
    });

    if (plan == "COACH") {
      totalsCanceledResult = [
        totalsCanceledResult[0],
        totalsCanceledResult[1],
        totalsCanceledResult[2],
        totalsCanceledResult[3],
        totalsCanceledResult[4],
        totalsCanceledResult[5],
        totalsCanceledResult[6],
        totalsCanceledResult[9],
      ];
    } else if (plan == "STUDENT") {
      totalsCanceledResult = [
        totalsCanceledResult[7],
        totalsCanceledResult[8],
        totalsCanceledResult[9],
      ];
    } else if (plan != "ALL") {
      totalsCanceledResult = [totalsCanceledResult[pIndex[plan]]];
    }

    this.setState({ totalCanceledData: totalsCanceledResult });
  };

  dateFormatter = (date) => {
    return moment(String(date), "x").format("MM/DD/YYYY");
  };

  planDetails = (str) => {
    if (str) {
      str = str.replaceAll("_", " ");
      var splitStr = str.toLowerCase().split(" ");
      for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      return splitStr.join(" ");
    } else {
      return "";
    }
  };

  getTableHeader = (name) => {
    return tableHeads[name];
  };

  capitalize = (s) => {
    return (s && s[0].toUpperCase() + s.slice(1)) || "";
  };

  render() {
    const classes = this.props.classes;

    let subscriptionsData = [];
    if (this.state.subscriptions) {
      const keys = Object.keys(this.state.subscriptions);
      subscriptionsData = keys.map((key) => {
        if (this.state.subscriptions[key]) {
          const sub = this.state.subscriptions[key];
          const out = {
            id: sub.id,
            userName: sub.userName,
            userEmail: sub.userEmail,
            vip:
              sub.transactionId === "" &&
              !sub.coachId &&
              !sub.productId.includes("enterprise") &&
              !sub?.bootcampUser
                ? "Yes"
                : "No",
            staffCoach: sub.coachId ? "Yes" : "No",
            bootcampUser: sub?.bootcampUser ? "Yes" : "No",
            hasACoach: sub.hasACoach ? (sub.hasACoach ? "Yes" : "No") : "No",
            platform: sub.platform,
            plan: this.planDetails(sub.productId),
            autoRenewal: sub.autoRenewal,
            startDate: sub.startDate,
            lastUpdated: sub.lastUpdated,
            endDate: sub.endDate,
          };
          return out;
        }
        return null;
      });
    }

    let subscriptionsCanceledData = [];
    if (this.state.totalCanceledDataValues) {
      const keys = Object.keys(this.state.allCanceledUsers);
      subscriptionsCanceledData = keys.map((key) => {
        if (this.state.allCanceledUsers[key]) {
          const sub = this.state.allCanceledUsers[key];
          const out = {
            id: sub.id,
            userName: sub.userName,
            userEmail: sub.userEmail,
            vip:
              sub.transactionId === "" &&
              !sub.coachId &&
              !sub.productId.includes("enterprise") &&
              !sub?.bootcampUser
                ? "Yes"
                : "No",
            platform: sub.platform,
            plan: this.planDetails(sub.productId),
            //sessions: sub.sessions,
            //lastSession: this.dateFormatter(sub.lastSession.sessionDate),
            autoRenewal: sub.autoRenewal,
            startDate: this.dateFormatter(sub.startDate),
            lastUpdated: this.dateFormatter(sub.lastUpdated),
            endDate: this.dateFormatter(sub.endDate),
          };
          return out;
        }
        return null;
      });
    }

    let invitesData = [];
    if (this.state.invites) {
      const keys = Object.keys(this.state.invites);
      invitesData = keys.map((key) => {
        if (this.state.invites[key]) {
          const inv = this.state.invites[key];
          const out = {
            id: inv.id,
            invitee: inv.invitee,
            inviteeName: inv.inviteeName,
            sender: inv.senderName,
            role: inv.role,
            status: inv.status,
            createdDate: this.dateFormatter(inv.createdAt),
            expiredDate: this.dateFormatter(inv.expiredIn),
          };
          return out;
        }
        return null;
      });
    }

    let trialSubsData = [];
    if (this.state.trialSubs) {
      const keys = Object.keys(this.state.trialSubs);
      trialSubsData = keys.map((key) => {
        if (this.state.trialSubs[key]) {
          const sub = this.state.trialSubs[key];
          const out = {
            id: sub.id,
            userName: sub.userName,
            userEmail: sub.userEmail,
            platform: sub.platform,
            students: sub.students,
            sessions: sub.sessions,
            issues: sub.issues,
            plan: this.planDetails(sub.productId),
            status: sub.status ? sub.status : "canceled",
            startDate: sub.startDate,
            lastUpdated: sub.lastUpdated,
            endDate: sub.endDate,
          };
          return out;
        }
        return null;
      });
    }

    /*let downgradeUsersData = [];
    if (this.state.downgradeUsers) {
      const keys = Object.keys(this.state.downgradeUsers)
      downgradeUsersData = keys.map((key) => {
        if (this.state.downgradeUsers[key]) {
          const sub = this.state.downgradeUsers[key];
          const out = {
            id: sub.id,
            userName: sub.userName,
            userEmail: sub.userEmail,
            vip: sub.transactionId === '' && !sub?.bootcampUser ? 'Yes' : 'No',
            startDate: this.dateFormatter(sub.startDate),
            endDate: this.dateFormatter(sub.endDate),
            lastUpdated: this.dateFormatter(sub.lastUpdated),
            oldPlan: this.planDetails(sub.productId),
            currentPlan: 'Free'
          }
          return out;
        }
        return null;
      })
    }*/

    let nyData = [];
    if (this.state.nyCampaignUsers) {
      const keys = Object.keys(this.state.nyCampaignUsers);
      nyData = keys.map((key) => {
        if (this.state.nyCampaignUsers[key]) {
          const sub = this.state.nyCampaignUsers[key];
          const out = {
            id: sub.id,
            userName: sub.firstName + " " + sub.lastName,
            email: sub.email,
            paid: sub.subscriptionType.includes("student_lite") ? "Yes" : "No",
            sessions: sub.sessionIds ? sub.sessionIds.length : 0,
            typicalMiss: sub.typicalMiss
              ? sub.typicalMiss.toString().replaceAll(",", ", ")
              : "",
            shotTrajectory: sub.shotTrajectory ? sub.shotTrajectory : "",
            date: this.dateFormatter(sub.userCreated),
          };
          return out;
        }
        return null;
      });
    }

    let freeAccountsData = [];
    if (this.state.freeAccounts) {
      const keys = Object.keys(this.state.freeAccounts);
      freeAccountsData = keys.map((key) => {
        if (this.state.freeAccounts[key]) {
          const sub = this.state.freeAccounts[key];
          const out = {
            id: sub.id,
            userName: sub.firstName + " " + sub.lastName,
            userEmail: sub.email,
            dob: sub.dob ? sub.dob : "",
            coaches: sub.coaches.length === 0 ? "No" : "Yes",
            sessions: sub.sessionIds ? sub.sessionIds.length : 0,
            certification: sub.certification
              ? sub.certification.includes("Sportsbox")
                ? true
                : false
              : false,
            date: this.dateFormatter(sub.userCreated),
          };
          return out;
        }
        return null;
      });
    }

    let paidAccountsData = [];
    if (this.state.paidAccounts) {
      const keys = Object.keys(this.state.paidAccounts);
      paidAccountsData = keys.map((key) => {
        if (this.state.paidAccounts[key]) {
          const sub = this.state.paidAccounts[key];
          const out = {
            id: sub.id,
            userName: sub.firstName + " " + sub.lastName,
            userEmail: sub.email,
            dob: sub.dob ? sub.dob : "",
            //vip: sub.transactionId === '' ? 'Yes' : 'No',
            plan: this.planDetails(sub.subscriptionType),
            sessions: sub.sessionIds ? sub.sessionIds.length : 0,
            students: sub.students ? sub.students.length : 0,
            certification: sub.certification
              ? sub.certification.includes("Sportsbox")
                ? true
                : false
              : false,
            date: this.dateFormatter(sub.userCreated),
          };
          return out;
        }
        return null;
      });
    }

    /*let allStaffEntData = [];
    if (this.state.allStaffEnt && this.state.allStaffEnt.length > 0) {
      const keys = Object.keys(this.state.allStaffEnt)
      allStaffEntData = keys.map((key) => {
        if (this.state.allStaffEnt[key]) {
          const sub = this.state.allStaffEnt[key];
          const out = {
            id: sub.id,
            userEmail: sub.email,
            status: sub.status,
            amount: sub.annualPrice,
            onboardingCompleted: sub.onboardingCompleted,
            students: sub.students.length,
            freeStudents: Math.abs(sub.freeStudents),
            paidStudents: sub.paidStudents,
            date: this.dateFormatter(sub.createdDate),
          }
          return out;
        }
        return null;
      })
    }*/

    /*let collCountsData = [];
    if (this.state.collCounts) {
      const keys = Object.keys(this.state.collCounts);
      collCountsData = keys.map((key) => {
        if (this.state.collCounts[key]) {
          const inv = this.state.collCounts[key];
          const out = {
            id: inv.id,
            collection: this.capitalize(inv.id),
            total: inv.total,
            lastUpdated: this.dateFormatter(inv.lastUpdated),
          };
          return out;
        }
        return null;
      });
    }*/

    const tableElements = [];
    if (this.state.totalData.length > 0) {
      tableElements.push(
        <div style={{ width: "100%" }} key="table1">
          <div
            style={{
              textAlign: "right",
              marginBottom: "-65px",
              zIndex: "999",
              paddingRight: "20px",
              position: "relative",
              marginRight: "50px",
              paddingTop: "20px",
            }}
          >
            From{" "}
            {moment(this.state.selectionRange.startDate).format("MM/DD/YYYY")}{" "}
            to {moment(this.state.selectionRange.endDate).format("MM/DD/YYYY")}
            <IconButton
              onClick={() => this.setState({ openRangeDialog: true })}
            >
              <DateRange />
            </IconButton>
            &nbsp; Plans:&nbsp;
            <Select
              label="Plans"
              labelId="plans-select"
              value={this.state.selectedPlans}
              onChange={this.handleSubsPlan}
            >
              <MenuItem value={"ALL"}>All</MenuItem>
              <MenuItem value={"COACH"}>Coach Plans</MenuItem>
              <MenuItem value={"STUDENT"}>Student Plans</MenuItem>
              <MenuItem value={"sportsbox_student_lite_monthly"}>
                3D Player - Monthly
              </MenuItem>
              <MenuItem value={"sportsbox_student_lite_annually"}>
                3D Player - Annual
              </MenuItem>
              <MenuItem value={"sportsbox_pro_lite_monthly"}>
                Pro Lite - Monthly
              </MenuItem>
              <MenuItem value={"sportsbox_pro_lite_annually"}>
                Pro Lite - Annual
              </MenuItem>
              <MenuItem value={"sportsbox_pro_plus_monthly"}>
                Plus - Monthly
              </MenuItem>
              <MenuItem value={"sportsbox_pro_plus_annually"}>
                Plus - Annual
              </MenuItem>
              <MenuItem value={"sportsbox_pro_premium_monthly"}>
                3D Pro - Monthly
              </MenuItem>
              <MenuItem value={"sportsbox_pro_premium_annually"}>
                3D Pro - Annual
              </MenuItem>
              <MenuItem value={"sportsbox_enterprise_master"}>
                Enterprise Master
              </MenuItem>
              <MenuItem value={"sportsbox_enterprise_staff"}>
                Staff Account
              </MenuItem>
            </Select>
            &nbsp;&nbsp;&nbsp;
          </div>
          <Dialog fullWidth open={this.state.openRangeDialog}>
            <DialogContent>
              <DateRangePicker
                ranges={[this.state.selectionRange]}
                onChange={this.handleSelectRange}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => this.setState({ openRangeDialog: false })}
                color="primary"
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>
          <MaterialTable
            style={{ marginTop: "10px", marginBottom: "10px" }}
            icons={tableIcons}
            key="totalTable"
            title={
              <div style={{ marginTop: "10px" }}>
                <h2>Recurring Subscriptions (Auto Renewal is on)</h2>
                <p style={{ fontSize: "125%" }}>
                  Overall column contains the total number of recurring
                  subscriptions for each plan
                </p>
              </div>
            }
            columns={this.getTableHeader("total")}
            data={this.state.totalData}
            options={{
              selection: false,
              sorting: false,
              search: false,
              paging: false,
              exportAllData: true,
              exportButton: true,
              exportFileName: "Recurring Subscriptions (Auto Renewal is on)",
              rowStyle: (x) => {
                if (x.tableData.id % 2) {
                  return { backgroundColor: "#f2f2f2" };
                }
              },
            }}
            /*components={{
              Toolbar: (toolbarProps) => (
                <div display="flex" alignItems="center">
                  <MTableToolbar {...toolbarProps} />
                  <Box border={1} borderColor="primary.main"><p style={{ fontSize: '125%' }}>From {moment(this.state.selectionRange.startDate).format("MM/DD/YYYY")} to {moment(this.state.selectionRange.endDate).format("MM/DD/YYYY")}</p></Box>
                  <IconButton style={{ float: "right" }} onClick={() => this.setState({ openRangeDialog: true })}>
                    <DateRange />
                  </IconButton>
                </div>
              ),
            }}*/
          />
        </div>
      );
    }

    if (this.state.activeData.length > 0) {
      tableElements.push(
        <div style={{ width: "100%" }} key="table2">
          <p
            style={{
              textAlign: "right",
              marginBottom: "-65px",
              zIndex: "999",
              paddingRight: "20px",
              position: "relative",
              marginRight: "50px",
              paddingTop: "20px",
            }}
          >
            From{" "}
            {moment(this.state.selectionRangeActive.startDate).format(
              "MM/DD/YYYY"
            )}{" "}
            to{" "}
            {moment(this.state.selectionRangeActive.endDate).format(
              "MM/DD/YYYY"
            )}
            <IconButton
              onClick={() => this.setState({ openRangeDialogActive: true })}
            >
              <DateRange />
            </IconButton>
            &nbsp; Plans:&nbsp;
            <Select
              label="Plans"
              labelId="plans-select"
              value={this.state.selectedPlansActive}
              onChange={this.handleSubsPlan}
            >
              <MenuItem value={"ALL"}>All</MenuItem>
              <MenuItem value={"COACH"}>Coach Plans</MenuItem>
              <MenuItem value={"STUDENT"}>Student Plans</MenuItem>
              <MenuItem value={"sportsbox_student_lite_monthly"}>
                3D Player - Monthly
              </MenuItem>
              <MenuItem value={"sportsbox_student_lite_annually"}>
                3D Player - Annual
              </MenuItem>
              <MenuItem value={"sportsbox_pro_lite_monthly"}>
                Pro Lite - Monthly
              </MenuItem>
              <MenuItem value={"sportsbox_pro_lite_annually"}>
                Pro Lite - Annual
              </MenuItem>
              <MenuItem value={"sportsbox_pro_plus_monthly"}>
                Plus - Monthly
              </MenuItem>
              <MenuItem value={"sportsbox_pro_plus_annually"}>
                Plus - Annual
              </MenuItem>
              <MenuItem value={"sportsbox_pro_premium_monthly"}>
                3D Pro - Monthly
              </MenuItem>
              <MenuItem value={"sportsbox_pro_premium_annually"}>
                3D Pro - Annual
              </MenuItem>
              <MenuItem value={"sportsbox_enterprise_master"}>
                Enterprise Master
              </MenuItem>
              <MenuItem value={"sportsbox_enterprise_staff"}>
                Staff Account
              </MenuItem>
            </Select>
            &nbsp;&nbsp;&nbsp;
          </p>
          <Dialog fullWidth open={this.state.openRangeDialogActive}>
            <DialogContent>
              <DateRangePicker
                ranges={[this.state.selectionRangeActive]}
                onChange={this.handleSelectRangeActive}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => this.setState({ openRangeDialogActive: false })}
                color="primary"
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>
          <MaterialTable
            style={{ marginTop: "10px", marginBottom: "10px" }}
            icons={tableIcons}
            key="totalTable"
            title={
              <div style={{ marginTop: "10px" }}>
                <h2>Active Paid Subscriptions (End Date &gt; Today's Date)</h2>
                <p style={{ fontSize: "125%" }}>
                  Overall column contains the total number of active
                  subscriptions for each plan
                </p>
              </div>
            }
            columns={this.getTableHeader("totalActive")}
            data={this.state.activeData}
            options={{
              selection: false,
              sorting: false,
              search: false,
              paging: false,
              exportAllData: true,
              exportButton: true,
              exportFileName: "Active Paid Subscriptions",
              rowStyle: (x) => {
                if (x.tableData.id % 2) {
                  return { backgroundColor: "#f2f2f2" };
                }
              },
            }}
          />
        </div>
      );
    }

    if (this.state.totalCanceledData.length > 0) {
      tableElements.push(
        <div style={{ width: "100%" }} key="table3">
          <p
            style={{
              textAlign: "right",
              marginBottom: "-65px",
              zIndex: "999",
              paddingRight: "20px",
              position: "relative",
              marginRight: "50px",
              paddingTop: "20px",
            }}
          >
            From{" "}
            {moment(this.state.selectionRangeCanceled.startDate).format(
              "MM/DD/YYYY"
            )}{" "}
            to{" "}
            {moment(this.state.selectionRangeCanceled.endDate).format(
              "MM/DD/YYYY"
            )}
            <IconButton
              onClick={() => this.setState({ openRangeDialogCanceled: true })}
            >
              <DateRange />
            </IconButton>
            &nbsp; Plans:&nbsp;
            <Select
              label="Plans"
              labelId="plans-select"
              value={this.state.selectedPlansCanceled}
              onChange={this.handleSubsPlanCanceled}
            >
              <MenuItem value={"ALL"}>All</MenuItem>
              <MenuItem value={"COACH"}>Coach Plans</MenuItem>
              <MenuItem value={"STUDENT"}>Student Plans</MenuItem>
              <MenuItem value={"sportsbox_student_lite_monthly"}>
                3D Player - Monthly
              </MenuItem>
              <MenuItem value={"sportsbox_student_lite_annually"}>
                3D Player - Annual
              </MenuItem>
              <MenuItem value={"sportsbox_pro_lite_monthly"}>
                Pro Lite - Monthly
              </MenuItem>
              <MenuItem value={"sportsbox_pro_lite_annually"}>
                Pro Lite - Annual
              </MenuItem>
              <MenuItem value={"sportsbox_pro_plus_monthly"}>
                Plus - Monthly
              </MenuItem>
              <MenuItem value={"sportsbox_pro_plus_annually"}>
                Plus - Annual
              </MenuItem>
              <MenuItem value={"sportsbox_pro_premium_monthly"}>
                3D Pro - Monthly
              </MenuItem>
              <MenuItem value={"sportsbox_pro_premium_annually"}>
                3D Pro - Annual
              </MenuItem>
              <MenuItem value={"sportsbox_enterprise_master"}>
                Enterprise Master
              </MenuItem>
            </Select>
            &nbsp;&nbsp;&nbsp;
          </p>
          <Dialog fullWidth open={this.state.openRangeDialogCanceled}>
            <DialogContent>
              <DateRangePicker
                ranges={[this.state.selectionRangeCanceled]}
                onChange={this.handleSelectRangeCanceled}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() =>
                  this.setState({ openRangeDialogCanceled: false })
                }
                color="primary"
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>
          <MaterialTable
            style={{ marginTop: "10px", marginBottom: "10px" }}
            icons={tableIcons}
            key="totalCanceledTable"
            title={
              <div style={{ marginTop: "10px" }}>
                <h2>
                  All Canceled Subscriptions (Excluding manually created
                  subscriptions)
                </h2>
                <p style={{ fontSize: "125%" }}>
                  Overall column contains the total number of canceled
                  subscriptions for each plan
                </p>
              </div>
            }
            columns={this.getTableHeader("totalCanceled")}
            data={this.state.totalCanceledData}
            options={{
              selection: false,
              sorting: false,
              search: false,
              paging: false,
              exportAllData: true,
              exportButton: true,
              exportFileName:
                "All Canceled Subscriptions (Excluding manually created subscriptions)",
              rowStyle: (x) => {
                if (x.tableData.id % 2) {
                  return { backgroundColor: "#f2f2f2" };
                }
              },
            }}
          />
        </div>
      );
    }

    /*if (this.state.totalStudentsData.length > 0) {
      tableElements.push(
        <div style={{width: '100%'}} key="table4">
          <MaterialTable
            style={{ marginTop: '10px', marginBottom: '10px' }}
            icons={tableIcons}
            key='totalStudentsTable'
            title={<div style={{ marginTop: '10px' }}>
              <h2>All Users</h2>
              <p style={{ fontSize: '125%' }}>This table contains the data for students and coaches accounts created and invites sent in different time period</p>
              <p style={{ fontSize: '125%' }}>Overall column contains the total number of students and coaches and invites in our database</p>
            </div>}
            columns={this.getTableHeader('totalStudents')}
            data={this.state.totalStudentsData}
            options={{
              selection: false,
              sorting: false,
              search: false,
              paging: false,
              exportAllData: true,
              exportButton: true,
              exportFileName: "All Users",
              rowStyle: x => {
                if ( x.tableData.id % 2 ) {
                  return { backgroundColor: '#f2f2f2' }
                }
              }
            }}
          />
        </div>
      )
    }*/

    /*if (collCountsData.length > 0) {
      tableElements.push(
        <div style={{ width: "100%" }} key="table5">
          <MaterialTable
            style={{ marginTop: "10px", marginBottom: "10px" }}
            icons={tableIcons}
            key="collCountTable"
            title={
              <div style={{ marginTop: "10px" }}>
                <h2>Collection Count</h2>
                <p style={{ fontSize: "125%" }}>
                  This table contains the count for different collections in our
                  database
                </p>
              </div>
            }
            columns={this.getTableHeader("collCount")}
            data={collCountsData}
            options={{
              selection: false,
              sorting: false,
              search: false,
              paging: false,
              exportAllData: true,
              exportButton: true,
              exportFileName: "Collection Count",
              rowStyle: (x) => {
                if (x.tableData.id % 2) {
                  return { backgroundColor: "#f2f2f2" };
                }
              },
            }}
          />
        </div>
      );
    }*/

    if (subscriptionsData.length > 0) {
      tableElements.push(
        <div style={{ width: "100%" }} key="table7">
          <MaterialTable
            style={{ marginTop: "10px", marginBottom: "10px" }}
            icons={tableIcons}
            key="subscriptionsTable"
            title={
              <div style={{ marginTop: "10px" }}>
                <h2>Subscriptions</h2>
                <p style={{ fontSize: "125%" }}>
                  Contains subscriptions data purchased by a user
                </p>
              </div>
            }
            columns={this.getTableHeader("subscriptions")}
            data={subscriptionsData.map((row) =>
              this.state.selectedRows.subscriptions.find(
                (selected) => selected.id === row.id
              )
                ? { ...row, tableData: { checked: true } }
                : row
            )}
            options={{
              selection: true,
              sorting: true,
            }}
            actions={[
              {
                position: "toolbarOnSelect",
                icon: tableIcons.ExportCSV,
                tooltip: "Export selected as CSV",
                onClick: (e, rowData) => {
                  const columnTitles = this.getTableHeader("subscriptions").map(
                    (columnDef) => columnDef.title
                  );
                  const csvData = rowData.map((data) =>
                    this.getTableHeader("subscriptions").map((columnDef) => {
                      if (columnDef.render) {
                        return columnDef.render(data);
                      }
                      return data[columnDef.field];
                    })
                  );
                  const builder = new CsvBuilder("Subscriptions.csv")
                    .setColumns(columnTitles)
                    .addRows(csvData)
                    .exportFile();
                },
              },
              {
                position: "toolbarOnSelect",
                icon: tableIcons.ExportPDF,
                tooltip: "Export selected as PDF",
                onClick: (e, rowData) => {
                  const doc = new jsPDF();
                  const columnTitles = this.getTableHeader("subscriptions").map(
                    (columnDef) => columnDef.title
                  );
                  const pdfData = rowData.map((data) =>
                    this.getTableHeader("subscriptions").map((columnDef) => {
                      if (columnDef.render) {
                        return columnDef.render(data);
                      }
                      return data[columnDef.field];
                    })
                  );
                  doc.autoTable({
                    head: [columnTitles],
                    body: pdfData,
                  });
                  doc.save(`Subscriptions.pdf`);
                },
              },
            ]}
          />
        </div>
      );
    }

    if (subscriptionsCanceledData.length > 0) {
      tableElements.push(
        <div style={{ width: "100%" }} key="table13">
          <MaterialTable
            style={{ marginTop: "10px", marginBottom: "10px" }}
            icons={tableIcons}
            key="subscriptionsCanceledTable"
            title="Canceled Subscriptions"
            columns={this.getTableHeader("subscriptionsCanceled")}
            data={subscriptionsCanceledData.map((row) =>
              this.state.selectedRows.subscriptionsCanceled.find(
                (selected) => selected.id === row.id
              )
                ? { ...row, tableData: { checked: true } }
                : row
            )}
            options={{
              selection: true,
              sorting: true,
              exportAllData: true,
              exportButton: true,
              exportFileName: "Canceled Subscriptions",
            }}
          />
        </div>
      );
    }

    if (invitesData.length > 0) {
      tableElements.push(
        <div style={{ width: "100%" }} key="table6">
          <MaterialTable
            style={{ marginTop: "10px", marginBottom: "10px" }}
            icons={tableIcons}
            key="invitesTable"
            title="Invites"
            columns={this.getTableHeader("invites")}
            data={invitesData.map((row) =>
              this.state.selectedRows.invites.find(
                (selected) => selected.id === row.id
              )
                ? { ...row, tableData: { checked: true } }
                : row
            )}
            options={{
              selection: true,
              sorting: true,
              exportAllData: true,
              exportButton: true,
              exportFileName: "Invites",
            }}
          />
        </div>
      );
    }

    /*if (trialSubsData.length > 0) {
      tableElements.push(
        <div style={{ width: "100%" }} key="table8">
          <MaterialTable
            style={{ marginTop: "10px", marginBottom: "10px" }}
            icons={tableIcons}
            key="trialSubsTable"
            title={
              <div style={{ marginTop: "10px" }}>
                <h2>Free Trial Users</h2>
                <p style={{ fontSize: "125%" }}>
                  Users that are/were enrolled in a Free Trial
                </p>
                <p style={{ fontSize: "125%" }}>
                  The # of Students/Sessions/S&M is during the 14-day trial
                  period and not overall
                </p>
              </div>
            }
            columns={this.getTableHeader("trialSubs")}
            data={
              this.state.trialFilterCheckmark
                ? trialSubsData.filter(
                    (row) =>
                      moment(new Date(+row.startDate)).valueOf() >=
                      moment().subtract(3, "weeks").valueOf()
                  )
                : trialSubsData.map((row) =>
                    this.state.selectedRows.trialSubs.find(
                      (selected) => selected.id === row.id
                    )
                      ? { ...row, tableData: { checked: true } }
                      : row
                  )
            }
            // data={trialSubsData}
            options={{
              selection: true,
              sorting: true,
              //filtering: true,
            }}
            actions={[
              {
                isFreeAction: true,
                icon: tableIcons.Filter,
                tooltip: "Filter Dates By 3 Weeks",
                onClick: (event, rowData) => {
                  console.log(trialSubsData);
                  this.setState({
                    trialFilterCheckmark: !this.state.trialFilterCheckmark,
                  });
                },
              },
              {
                position: "toolbarOnSelect",
                icon: tableIcons.ExportCSV,
                tooltip: "Export selected as CSV",
                onClick: (e, rowData) => {
                  const columnTitles = this.getTableHeader("trialSubs").map(
                    (columnDef) => columnDef.title
                  );
                  const csvData = rowData.map((data) =>
                    this.getTableHeader("trialSubs").map((columnDef) => {
                      if (columnDef.render) {
                        return columnDef.render(data);
                      }
                      return data[columnDef.field];
                    })
                  );
                  const builder = new CsvBuilder("Free Trial Users.csv")
                    .setColumns(columnTitles)
                    .addRows(csvData)
                    .exportFile();
                },
              },
              {
                position: "toolbarOnSelect",
                icon: tableIcons.ExportPDF,
                tooltip: "Export selected as PDF",
                onClick: (e, rowData) => {
                  const doc = new jsPDF();
                  const columnTitles = this.getTableHeader("trialSubs").map(
                    (columnDef) => columnDef.title
                  );
                  const pdfData = rowData.map((data) =>
                    this.getTableHeader("trialSubs").map((columnDef) => {
                      if (columnDef.render) {
                        return columnDef.render(data);
                      }
                      return data[columnDef.field];
                    })
                  );
                  doc.autoTable({
                    head: [columnTitles],
                    body: pdfData,
                  });
                  doc.save(`Free Trial Users.pdf`);
                },
              },
            ]}
          />
        </div>
      );
    } else {
      tableElements.push(<CircularProgress />);
    }*/

    /*if (freeAccountsData.length > 0) {
      tableElements.push(
        <div style={{width: '100%'}} key="table9">
          <MaterialTable
            style={{ marginTop: '10px', marginBottom: '10px' }}
            icons={tableIcons}
            key='freeAccountsTable'
            title={<div style={{ marginTop: '10px' }}>
              <h2>Free Plan Users</h2>
              <p style={{ fontSize: '125%' }}>All Users that are subscribed to the Free plan</p>
            </div>}
            columns={this.getTableHeader('freeAccounts')}
            data={freeAccountsData.map(row => this.state.selectedRows.freeAccounts.find(selected => selected.id === row.id) ? { ...row, tableData: { checked: true } } : row)}
            options={{
              selection: true,
              sorting: true,
              exportAllData: true,
              exportButton: true,
              exportFileName: "Free Plan Users",
            }}
          />
        </div>
      )
    }*/

    /*if (paidAccountsData.length > 0) {
      tableElements.push(
        <div style={{width: '100%'}} key="table10">
          <MaterialTable
            style={{ marginTop: '10px', marginBottom: '10px' }}
            icons={tableIcons}
            key='paidAccountsTable'
            title={<div style={{ marginTop: '10px' }}>
              <h2>Paid Plan Users</h2>
              <p style={{ fontSize: '125%' }}>All Users that are currently subscribed to the Paid plans</p>
            </div>}
            columns={this.getTableHeader('paidAccounts')}
            data={paidAccountsData.map(row => this.state.selectedRows.paidAccounts.find(selected => selected.id === row.id) ? { ...row, tableData: { checked: true } } : row)}
            options={{
              selection: true,
              sorting: true,
              exportAllData: true,
              exportButton: true,
              exportFileName: "Paid Plan Users",
            }}
          />
        </div>
      )
    }*/

    /*if (allStaffEntData.length > 0) {
      tableElements.push(
        <div style={{width: '100%'}} key="table11">
          <MaterialTable
            style={{ marginTop: '10px', marginBottom: '10px' }}
            icons={tableIcons}
            key='staffAccountsTable'
            title='Staff Account Users'
            columns={this.getTableHeader('staffAccounts')}
            data={allStaffEntData.map(row => this.state.selectedRows.allStaffEnt.find(selected => selected.id === row.id) ? { ...row, tableData: { checked: true } } : row)}
            options={{
              selection: true,
              sorting: true,
              exportAllData: true,
              exportButton: true,
              exportFileName: "Staff Account Users",
            }}
          />
        </div>
      )
    }*/

    /*if (downgradeUsersData.length > 0) {
      tableElements.push(
        <div style={{width: '100%'}} key="table12">
          <MaterialTable
            style={{ marginTop: '10px', marginBottom: '10px' }}
            icons={tableIcons}
            key='downgradeUsersTable'
            title={<div style={{ marginTop: '10px' }}>
              <h2>Instructors Who Downgraded To The Free Plan</h2>
              <p style={{ fontSize: '125%' }}>All Users that downgraded from paid subscription to the free plan, including manually created VIP accounts that did not purchase a subscription after the trial ended</p>
            </div>}
            columns={this.getTableHeader('downgradeUsers')}
            data={downgradeUsersData.map(row => this.state.selectedRows.downgradeUsers.find(selected => selected.id === row.id) ? { ...row, tableData: { checked: true } } : row)}
            options={{
              selection: true,
              sorting: true,
              exportAllData: true,
              exportButton: true,
              exportFileName: "Instructors Who Downgraded To The Free Plan",
            }}
          />
        </div>
      )
    }*/

    if (nyData.length > 0) {
      tableElements.push(
        <div style={{ width: "100%" }} key="table9">
          <MaterialTable
            style={{ marginTop: "10px", marginBottom: "10px" }}
            icons={tableIcons}
            key="nyCampaignTable"
            title={
              <div style={{ marginTop: "10px" }}>
                <h2>NY Campaign Users</h2>
                <p style={{ fontSize: "125%" }}>
                  All Users that signed up for the NY Campaign
                </p>
              </div>
            }
            columns={this.getTableHeader("nyCampaign")}
            data={nyData.map((row) =>
              this.state.selectedRows.nyCampaignUsers.find(
                (selected) => selected.id === row.id
              )
                ? { ...row, tableData: { checked: true } }
                : row
            )}
            options={{
              selection: true,
              sorting: true,
              exportAllData: true,
              exportButton: true,
              exportFileName: "NY Campaign Users",
            }}
          />
        </div>
      );
    }

    return (
      <div>
        {this.state.role === "admin" ? (
          <Container className={classes.paper} component="main">
            <CssBaseline />
            <div className={classes.paper}>
              <Avatar className={classes.avatar}>
                <BarChartIcon />
              </Avatar>
              <Typography
                component="h1"
                variant="h4"
                style={{ marginBottom: "10px" }}
              >
                SUMMARY
              </Typography>
            </div>
            <div style={{ width: "100%" }}>
              <MuiThemeProvider theme={theme}>
                <SummaryCharts
                  users={this.state.users}
                  subscriptions={this.state.subscriptions}
                  recurringSubscriptions={this.state.subscriptions}
                  canceledSubscriptions={this.state.subscriptions}
                  sessions={this.state.sessions}
                  videos={this.state.videos}
                  issues={this.state.issues}
                  analysis={this.state.analysis}
                  staffAccounts={this.state.allStaffEnt}
                  deviceLimitExceptionList={this.state.deviceLimitExceptionList}
                />
                {tableElements}

                {/* Loading screen*/}
                <Grid
                  style={{
                    margin: "auto",
                    padding: 100,
                    display: this.state.loading ? "block" : "none",
                  }}
                >
                  <CircularProgress />
                </Grid>
                {/* Error screen*/}
                <Grid
                  item
                  lg={12}
                  md={12}
                  xl={12}
                  xs={12}
                  style={{
                    display:
                      this.state.error && !this.state.loading
                        ? "block"
                        : "none",
                  }}
                >
                  <AccountNotFound error={{ error: this.state.error }} />
                </Grid>
              </MuiThemeProvider>
            </div>
          </Container>
        ) : (
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
            style={{
              display:
                this.state.error && !this.state.loading ? "block" : "none",
            }}
          >
            <AccountNotFound error={{ error: this.state.error }} />
          </Grid>
        )}
      </div>
    );
  }
}

Summary.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
};

export default withStyles(useStyles)(Summary);
