import React, { Component, useState, useEffect } from 'react';
import admin from 'firebase/app';
import 'firebase/firestore';
import firebase from '../../common/firebase'
import {functionBaseUrl, axiosWithToken} from '../../common/firebase';
import {Typography, Button, Grid, Paper, Switch } from '@material-ui/core';
import students from '../students';

function sendNoti(token, message, receiverId, title, body, notificationType) {
    axiosWithToken(functionBaseUrl+'/api/sendNoti', {
        method: 'post',
        data: {
            token: token, 
            message: message, 
            receiverId: receiverId, 
            title: title, 
            body: body, 
            notificationType: notificationType, 
    }}).then(response => {
        alert('Success');
    })
    .catch(err => {
        console.log(err)
    });
    
}

const Devices = (props) => {
    const [stage, setStage] = useState('initial')
    const [devices, setDevices] = useState([])

    const setUpRemote = () => { //set up for remote pairing page, move to remote pairing page by updating setPairing state
        props.setConnectedDev(props.connectedDev)
        var print = async() => {
            console.log('device length ', props.connectedDev.length)
            for (let i = 0; i <  props.connectedDev.length; i++) {
                console.log( props.connectedDev[i]['deviceName'])
            }
        }
        props.setPairing(1)
        return print
        
    }

    const sendConnection = (event, device) => { //send connection event to mobile application when user chooses device to connect to 
        if (event.target.checked == true) {
            sendNoti(device['fcmToken'], "Device now connected", "connected", "Device Connected", "This device is now connected to Sportsbox studio", '1')
            props.connectedDev.push(device)
            console.log(props.connectedDev.length)
            var text = document.getElementById(device['deviceId'])
            text.textContent = "connected"
            console.log(device)
        } else if (event.target.checked == false) {
            var text = document.getElementById(device['deviceId'])
            text.textContent = "not connected"
        }
    }

    const readDeviceData = () => { //gets device date for users and displays users devices 
        const userId = firebase.auth().currentUser.uid;
        console.log(userId);
        
        admin.firestore().collection("users").doc(userId).get().then(result =>{ 
            console.log('students length ', result.data().students.length)
            if (result.data().students.length > 0) {
                
                for(let i = 0; i < students.length; i++) { //checks if user is a teacher and adds student devices to teachers devices list
                    admin.firestore().collection("users").doc(students[i]).get().then(result => {
                        for (let i = 0; i < result.data().devices.length; i++) {
                            devices.push(result.data().devices[i])
                        }
                    })
                }
            }

            if (result.data().devices.length != 0) { 
                console.log("Device number: ", result.data().devices.length);
                console.log("Choose which devices to connect to: ");
                
                for (let i = 0; i < result.data().devices.length; i++) { //adds users devices to devices list
                    console.log(devices.length);
                    devices.push(result.data().devices[i])
                }

                var title = 
                <div style = {{backgroundColor: '#F4F6F8'}}>
                    <Typography 
                            id = 'connected devices'
                            style = {{fontWeight: '700',
                                    marginTop: '100px', marginBottom: '25px', 
                                    fontSize: '34px', display: stage==="initial" ? "block": "none"}}>Connected Devices
                    </Typography>
                </div>


                const deviceElements = devices.map(b=> (  
                    <div style = {{backgroundColor: '#FFFFFF', width: '800px'}}>
                        <Paper variant="outlined" elevation={0} align = 'center' style = {{marginBottom: '5px'}} />
                            <Typography
                                align="left"
                                style = {{borderRadius: 0, marginTop: '10px', marginLeft: '15px', fontWeight: 'bold', display: 'inline-block', width: '200px', marginRight: '150px'}}
                                >{b['deviceName']}
                            </Typography>
                            
                            <Typography id = {b['deviceId']} align='center' style = {{ fontWeight: 'bold', display: 'inline-block', marginRight: '250px'}}>Not Connected</Typography>

                            <Switch 
                                defaultUnchecked color="secondary" size = 'small' 
                                position = 'fixed'
                                style = {{display: 'inline-block'}}
                                onChange =  {event => {(sendConnection(event, b))}}>
                            </Switch>
                        <Paper/>  
                    </div> 
                ))
                
                var setUp = 
                <Button
                    id = 'setUp'
                    align = 'center'
                    style = {{width: '300px', marginLeft: '250px', marginBottom: '100px', color: "white", backgroundColor: '#580CE3', marginTop: '100px', display: stage==="initial" ? "block": "none"}}
                    variant="contained" 
                    onClick = {setUpRemote}
                >SET IT UP NOW</Button>

                deviceElements.unshift(title)
                deviceElements.push(setUp)
                setDevices(deviceElements)
                setStage('pairing')
                console.log(devices.length)
                console.log('connected devices', props.connectedDev.length)
            }  
        })
    }

    return (
            <div style ={{backgroundColor: '#F4F6F8', display: 'flex', justifyContent: 'center'}} >
                <div style = {{display: stage==="initial" ? "block": "none"}}>
                    <img src = "/images/PairingImage.PNG" id = 'pairing' width = "750px" height = "auto" style = {{marginLeft: '250px', backgroundColor: '#F4F6F8', marginBottom: '50px', marginTop: '100px'}} />

                    <Button 
                        id = 'Pbutton'
                        style = {{width: '25%', marginLeft: '460px', marginBottom: '100px', backgroundColor: '#580CE3', color: "white"}}
                        variant="contained" 
                        onClick={readDeviceData}>Start Pairing
                    </Button>
                </div>

                <div style={{display: stage==="pairing" ? "block": "none"}}>
                    {devices}    
                </div>  
                
            </div>
    );
};

export default Devices;