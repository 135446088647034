import React from "react";
import styles from "./styles.module.css";

const Privacy = () => (
  <>
    <div className={styles.container}>
      <div className={`${styles.bold} ${styles.centered}`}>
        Sportsbox Privacy Policy
      </div>

      <div className={styles.spacer}></div>

      <div>
        SPORTSBOX.AI INC. (“Sportsbox.ai,” “we,” “our,” and/or “us”) values the
        privacy of individuals who use our application, website, and related
        services (collectively, our “Services”). This privacy policy (the
        “Privacy Policy”) explains how we collect, use, and share information
        from users of our Services (“Users”) By using our Services, you agree to
        the collection, use, disclosure, and procedures this Privacy Policy
        describes. Beyond the Privacy Policy, your use of our Services is also
        subject to our Terms of Service
        (https://3dbaseball.sportsbox.ai/terms-of-service)
      </div>

      <div className={styles.spacer}></div>

      <div>
        <span className={styles.boldUnderline}>Information We Collect </span>
      </div>

      <div className={styles.spacer}></div>

      <div>
        We may collect a variety of information from or about you or your
        devices from various sources, as described below.
      </div>

      <div className={styles.spacer}></div>

      <div>
        <span className={styles.bold}>A. Information You Provide to Us. </span>
      </div>

      <div className={styles.spacer}></div>

      <div>
        <span className={styles.bold}>
          A. Registration and Profile Information{" "}
        </span>{" "}
        When you sign up for an account, we ask you for your name, email
        address, phone number, gender, location and date of birth. We also
        collect certain user preferences, characteristics, such as body
        measurement data, baseball and softball skills, and baseball and
        softball behavior, and information about your coach.
      </div>

      <div className={styles.spacer}></div>

      <div>
        <span className={styles.bold}>A. Communications</span> If you contact us
        directly, we may receive additional information about you. For example,
        if you contact us for technical support, we may receive your name, email
        address, phone number, the contents of your message, attachments that
        you may send to us, and other information you choose to provide. If you
        sign up for beta access or subscribe to our newsletter, we will collect
        information such as your email address. When we send you emails, we may
        track whether you open them to learn how to deliver a better customer
        experience and improve our Services.
      </div>
      <div className={styles.spacer}></div>

      <div>
        <span className={styles.bold}>Payment Information.</span> If you make a
        purchase through our Service, your payment-related information, such as
        credit card or other financial information, is collected by our third
        party payment processor on our behalf.
      </div>
      <div className={styles.spacer}></div>

      <div>
        <span className={styles.bold}>
          Motion Capture and Video Information.
        </span>{" "}
        We collect photos and videos (including audio) you choose to share,
        including from your device’s camera, screen recordings, and other apps
        and services, such as YouTube, Instagram, and TikTok, to measure,
        analyze, and understand your baseball and softball swing. We capture the
        date and time of the photos and videos you choose to share, for example
        to track when and how you practiced, and your camera settings, in order
        to better understand and process the photos and videos you share. With
        your permission, we use your uploaded videos to improve our AI models to
        provide better guidance and instruction.
      </div>
      <div className={styles.spacer}></div>

      <div>
        <span className={styles.bold}>Equipment and Products.</span> We collect
        information about the baseball and softball equipment and products you
        use in order to recommend other equipment you may be interested in.
      </div>
      <div className={styles.spacer}></div>

      <div>
        <span className={styles.bold}>
          B. Information We Collect When You Use Our Services.
        </span>{" "}
      </div>
      <div className={styles.spacer}></div>

      <div>
        <span className={styles.bold}>Location Information.</span> When you use
        our Services, we receive your precise location information. We use your
        location information for a variety of reasons, including to obtain
        relevant local weather information, and to understand where a lesson
        takes place (including the type of facility). We also infer your general
        location information, for example by using your internet protocol (IP)
        address.
      </div>
      <div className={styles.spacer}></div>

      <div>
        <span className={styles.bold}>Device Information.</span> We receive
        information about the device and software you use to access our
        Services, including IP address, web browser type, operating system
        version, phone carrier and manufacturer, device identifiers, mobile
        advertising identifiers, and push notification tokens.
      </div>
      <div className={styles.spacer}></div>

      <div>
        <span className={styles.bold}>
          Activity Information and Wearables:{" "}
        </span>
        We collect activity information or logs from connected devices, such as
        wearables. For example, if you choose to connect the Services to a
        baseball and softball launch monitor, we collect information to better
        understand each baseball and softball shot and track performance, and if
        you choose to connect the Services to a smart watch, we may collect
        gyroscope data for more detailed swing data.
      </div>
      <div className={styles.spacer}></div>

      <div>
        <span className={styles.bold}>Usage Information. </span>To help us
        understand how you use our Services and to help us improve them, we
        automatically receive information about your interactions with our
        Services, like the pages or other content you view, purchases you make,
        your comments, any content you post, and the dates and times of your
        visits.
      </div>
      <div className={styles.spacer}></div>

      <div>
        <span className={styles.bold}>Your Contacts. </span>If you permit us to
        access the address book on your device, we may access and store names
        and contact information from your address book to facilitate invitations
        and social interactions that you initiate through our Services and for
        other purposes described in this privacy policy or at the time of
        consent or collection. We also collect your contacts if you invite them
        to use our Services or share information or content with them from our
        Services.
      </div>

      <div className={styles.spacer}></div>

      <div>
        <span className={styles.bold}>
          Information from Cookies and Similar Technologies.{" "}
        </span>
        We and third party partners collect information using cookies, pixel
        tags, or similar technologies. Our third party partners, such as
        analytics and advertising partners, may use these technologies to
        collect information about your online activities over time and across
        different services. Cookies are small text files containing a string of
        alphanumeric characters. We may use both session cookies and persistent
        cookies. A session cookie disappears after you close your browser. A
        persistent cookie remains after you close your browser and may be used
        by your browser on subsequent visits to our Services.
      </div>

      <div className={styles.spacer}></div>

      <div>
        Please review your web browser’s “Help” file to learn the proper way to
        modify your cookie settings. Please note that if you delete or choose
        not to accept cookies from the Service, you may not be able to utilize
        the features of the Service to their fullest potential.
      </div>

      <div className={styles.spacer}></div>

      <div>
        <span className={styles.bold}>
          C. Information We Receive from Third Parties{" "}
        </span>
      </div>

      <div className={styles.spacer}></div>

      <div>
        <span className={styles.bold}>Other third parties. </span>We may receive
        additional information about you, such as demographic data, from third
        parties such as data or marketing partners and combine it with other
        information we have about you.
      </div>

      <div className={styles.spacer}></div>

      <div>
        <span className={styles.boldUnderline}>
          How We Use the Information We Collect
        </span>
      </div>
      <div className={styles.spacer}></div>

      <div>We use the information we collect:</div>
      <div className={styles.spacer}></div>

      <ul>
        <li>To provide, maintain, improve, and enhance our Services;</li>
        <li>
          To personalize your experience on our Services such as by providing
          tailored content and recommendations;
        </li>
        <li>
          To understand and analyze how you use our Services and develop new
          products, services, features, and functionality;
        </li>
        <li>
          To communicate with you, provide you with updates and other
          information relating to our Services, provide information that you
          request, respond to comments and questions, and otherwise provide
          customer support;
        </li>
        <li>
          For marketing and advertising purposes, such as developing and
          providing promotional and advertising materials that may be relevant,
          valuable or otherwise of interest to you;
        </li>
        <li>To send you text messages and push notifications;</li>
        <li>To facilitate transactions and payments;</li>
        <li>
          To find and prevent fraud, and respond to trust and safety issues that
          may arise;{" "}
        </li>
        <li>
          For compliance purposes, including enforcing our Terms of Service or
          other legal rights, or as may be required by applicable laws and
          regulations or requested by any judicial process or governmental
          agency; and
        </li>
        <li>
          For other purposes for which we provide specific notice at the time
          the information is collected.
        </li>
      </ul>
      <div className={styles.spacer}></div>

      <div>
        <span className={styles.boldUnderline}>
          How We Share the Information We Collect{" "}
        </span>
      </div>
      <div className={styles.spacer}></div>

      <div>
        <span className={styles.bold}>Vendors and Service Providers. </span>We
        may share any information we receive with vendors and service providers
        retained in connection with the provision of our Services.
      </div>
      <div className={styles.spacer}></div>

      <div>
        <span className={styles.bold}>User Content. </span>The Services allow
        users to share information with each other and coaches. For example, a
        user may share videos and information, including notes about a training
        session with a coach, and a coach may share such videos and information
        with a user. Users may share their swing scores, swing data, and other
        information with other users, such as pros and friends. If you agree,
        your coach may share videos of you, and related coaching content,
        publicly. Such videos and content may be used in promotional material
        for your coach, for coaching other users, or for other purposes. We are
        not responsible for the other users’ use of available information, so
        you should carefully consider whether and what to post or how you
        identify yourself on the Services.
      </div>

      <div className={styles.spacer}></div>

      <div>
        <span className={styles.bold}>Third Party App Integrations. </span>If
        you connect a third-party application to our Services, such as baseball
        and softball launch monitors and other game improvement apps, we may
        share information, such as information regarding your swings and
        training, with those third parties.
      </div>

      <div className={styles.spacer}></div>

      <div>
        <span className={styles.bold}>
          Social Networks and Other Online Services.{" "}
        </span>
        Our Services allow you to, upon your direction, share information with
        social networking services, such as Twitter, Facebook and Instagram. You
        understand and agree that the use of your information by any social
        networking websites will be governed by the privacy policies of these
        third-party platforms and your settings on that platform. We encourage
        you to review their privacy policies.
      </div>

      <div className={styles.spacer}></div>

      <div>
        <span className={styles.bold}>Marketing. </span>We do not rent, sell, or
        share information about you with nonaffiliated companies for their
        direct marketing purposes, unless we have your permission.
      </div>

      <div className={styles.spacer}></div>

      <div>
        <span className={styles.bold}>Analytics Partners. </span>We use
        analytics services such as Google Analytics to collect and process
        certain analytics data. These services may also collect information
        about your use of other websites, apps, and online resources. You can
        learn more about Google’s practices by visiting
        https://www.google.com/policies/privacy/partners/. To help us understand
        how you use our Services and to help us improve them, we automatically
        receive information about your interactions with our Services, like the
        pages or other content you view, the searches you conduct, purchases you
        make, your comments, any content you post, and the dates and times of
        your visits.
      </div>
      <div className={styles.spacer}></div>

      <div>
        <span className={styles.bold}>Advertising Partners. </span>We work with
        third party advertising partners to show you ads that we think may
        interest you. Some of our advertising partners are members of the
        Network Advertising Initiative
        (http://optout.networkadvertising.org/?c=1#!/) or the Digital
        Advertising Alliance (http://optout.aboutads.info/?c=2&lang=EN). If you
        do not wish to receive personalized ads, please visit their opt-out
        pages to learn about how you may opt out of receiving web-based
        personalized ads from member companies. You can access any settings
        offered by your mobile operating system to limit ad tracking, or you can
        install the AppChoices mobile app to learn more about how you may opt
        out of personalized ads in mobile apps.
      </div>

      <div className={styles.spacer}></div>

      <div>
        <span className={styles.bold}>
          As Required By Law and Similar Disclosures.{" "}
        </span>
        We may access, preserve, and disclose your information if we believe
        doing so is required or appropriate to: (a) comply with law enforcement
        requests and legal process, such as a court order or subpoena; (b)
        respond to your requests; or (c) protect your, our, or others’ rights,
        property, or safety. For the avoidance of doubt, the disclosure of your
        information may occur if you post any objectionable content on or
        through the Services.
      </div>

      <div className={styles.spacer}></div>

      <div>
        <span className={styles.bold}>
          Merger, Sale, or Other Asset Transfers.{" "}
        </span>
        We may transfer your information to service providers, advisors,
        potential transactional partners, or other third parties in connection
        with the consideration, negotiation, or completion of a corporate
        transaction in which we are acquired by or merged with another company
        or we sell, liquidate, or transfer all or a portion of our assets. The
        use of your information following any of these events will be governed
        by the provisions of this Privacy Policy in effect at the time the
        applicable information was collected.
      </div>

      <div className={styles.spacer}></div>

      <div>
        <span className={styles.bold}>Consent. </span>We may also disclose your
        information with your permission.
      </div>

      <div className={styles.spacer}></div>

      <div>
        <span className={styles.boldUnderline}>Your Choices</span>
      </div>

      <div className={styles.spacer}></div>

      <div>
        <span className={styles.bold}>Sharing Preferences. </span>We provide you
        with settings to allow you to set your sharing preferences for content
        you post to the Services. Please review your settings to view and change
        these sharing options.
      </div>

      <div className={styles.spacer}></div>

      <div>
        <span className={styles.bold}>Location Information. </span>You can
        prevent your device from sharing precise location information at any
        time through your device’s operating system settings.
      </div>

      <div className={styles.spacer}></div>

      <div>
        <span className={styles.bold}>Marketing Communications. </span>You can
        unsubscribe from our promotional emails via the link provided in the
        emails. Even if you opt-out of receiving promotional messages from us,
        you will continue to receive administrative messages from us.
      </div>

      <div className={styles.spacer}></div>

      <div>
        <span className={styles.boldUnderline}>Third Parties </span>
      </div>
      <div className={styles.spacer}></div>

      <div>
        Our Services may contain links to other websites, products, or services
        that we do not own or operate. We are not responsible for the privacy
        practices of these third parties. Please be aware that this Privacy
        Policy does not apply to your activities on these third party services
        or any information you disclose to these third parties. We encourage you
        to read their privacy policies before providing any information to them.
      </div>

      <div className={styles.spacer}></div>
      <div>
        <span className={styles.boldUnderline}>Security </span>
      </div>

      <div className={styles.spacer}></div>

      <div>
        We make reasonable efforts to protect your information by using physical
        and electronic safeguards designed to improve the security of the
        information we maintain. However, as no electronic transmission or
        storage of information can be entirely secure, we can make no guarantees
        as to the security or privacy of your information.
      </div>
      <div className={styles.spacer}></div>

      <div>
        <span className={styles.boldUnderline}>Children’s Privacy </span>
      </div>
      <div className={styles.spacer}></div>

      <div>
        We do not knowingly collect, maintain, or use personal information from
        children under 13 years of age, and no part of our Services are directed
        to children. If you learn that a child has provided us with personal
        information in violation of this Privacy Policy, then you may alert us
        at contact@sportsbox.ai.
      </div>
      <div className={styles.spacer}></div>

      <div>
        If you are a California resident under the age of 18 and you wish to
        remove content that you publicly posted, you may submit a request by
        contacting us at contact@sportsbox.ai. Removing the public content does
        not ensure complete or comprehensive removal of the content or
        information.
      </div>
      <div className={styles.spacer}></div>

      <div>
        <span className={styles.boldUnderline}>
          Your California Privacy Rights{" "}
        </span>
      </div>
      <div className={styles.spacer}></div>

      <div>
        <span className={styles.boldUnderline}>International Visitors </span>
      </div>

      <div className={styles.spacer}></div>
      <div>
        Our Services are hosted in the United States and intended for visitors
        located within the United States. If you choose to use the Services from
        the European Union or other regions of the world with laws governing
        data collection and use that may differ from U.S. law, then please note
        that you are transferring your personal information outside of those
        regions to the United States for storage and processing. Also, we may
        transfer your data from the U.S. to other countries or regions in
        connection with storage and processing of data, fulfilling your
        requests, and operating the Services. By providing any information,
        including personal information, on or to the Services, you consent to
        such transfer, storage, and processing.{" "}
      </div>

      <div className={styles.spacer}></div>

      <div>
        <span className={styles.boldUnderline}>
          Update Your Information or Pose a Question{" "}
        </span>
      </div>

      <div className={styles.spacer}></div>

      <div>
        You can update your account and profile information or close your
        account through settings on your profile page. If you have questions
        about your privacy on the Services or this privacy policy, please
        contact us at contact@sportsbox.ai.
      </div>

      <div className={styles.spacer}></div>

      <div>
        <span className={styles.boldUnderline}>
          Changes to this Privacy Policy{" "}
        </span>
      </div>
      <div className={styles.spacer}></div>

      <div>
        We will post any adjustments to the Privacy Policy on this page, and the
        revised version will be effective when it is posted. If we materially
        change the ways in which we use or share personal information previously
        collected from you through the Services, we will notify you through the
        Services, by email, or other communication
      </div>

      <div className={styles.spacer}></div>

      <div>
        <span className={styles.boldUnderline}>Contact Information </span>
      </div>

      <div className={styles.spacer}></div>

      <div>
        If you have any questions, comments, or concerns about our processing
        activities, please email us at contact@sportsbox.ai or write to us at
        Sportsbox AI, 1229 120th Ave NE, Suite A, Bellevue, WA 98005.
      </div>

      <div className={styles.spacer}></div>

      <div>
        <span className={styles.bold}>Last Updated: </span>5/6/2024
      </div>
    </div>
  </>
);

export default Privacy;
