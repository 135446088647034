import React, { useState, useEffect } from 'react';

import {
    Button, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, Grid,
    FormControl, InputLabel, Select, MenuItem, TextField, Chip, Slider, Typography,
    Input
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseIcon from '@material-ui/icons/Close';
import Swal from 'sweetalert2';
import axios from 'axios';

import firebase from '../../../../common/firebase';

function AutoLabelDialogComponent({ open, videos, close }) {
    const [confidence, setConfidence] = useState(0.8);
    const [confidenceClub, setConfidenceClub] = useState(0.7);
    const [isLoading, setIsLoading] = useState(false);

    const handleConfidenceChange = (event, newValue) => {
        setConfidence(newValue);
    };

    const handleConfidenceInputChange = (event) => {
        setConfidence(event.target.value === '' ? '' : Number(event.target.value));
    };

    const handleConfidenceClubChange = (event, newValue) => {
        setConfidenceClub(newValue);
    };

    const handleConfidenceClubInputChange = (event) => {
        setConfidenceClub(event.target.value === '' ? '' : Number(event.target.value));
    };

    const handleStart = async () => {
        try {
            setIsLoading(true);
            const temp1 = {};
            const names = Object.entries(videos).map(([id, v])=>{
                temp1[id] = 0
                return v.videos?.videoOrigName
            });
            const temp2 = {};
            const links = Object.entries(videos).map(([id, v])=>{
                temp2[id] = 0
                return v.videos?.videoPath
            });
            
            const response = await axios("http://68.64.52.146:40040/api/run_autolabeling", {
                method: 'POST',
                data: {
                    "confidence": confidence,
                    "confidence_club": confidenceClub,
                    "videos_names": names,
                    "video_links": links
                },
            });

            setIsLoading(false);
            Swal.fire({
                title: '<p style="font-size:70%;">Started successfully</p>',
                icon: 'success',
                confirmButtonText: 'Ok',
                allowOutsideClick: false,
                timer: 2000
            });
            close();
        } catch (e) {
            setIsLoading(false);
            Swal.fire('<p style="font-size:70%;">Error occured</p>');
        }
    }

    return (<>
        <Dialog
            fullWidth
            open={open}
            onClose={close}
            aria-labelledby='form-dialog-title'>
            <DialogTitle id={'form-dialog-title'}>
                <div>
                    Start Auto-Labeling
                    <CloseIcon style={{ float: 'right', marginTop: '-10px', marginRight: '-15px' }} onClick={close} />
                </div>
            </DialogTitle>
            <DialogContent>
                <Grid container component="main">
                    <Grid container spacing={2}>
                        <Grid
                            item
                            xs={12}
                        >
                            {!isLoading && (
                                <>
                                    <Typography id="input-slider" gutterBottom>
                                        Total Videos: <b>{Object.keys(videos).length}</b>
                                    </Typography>
                                    <Typography id="input-slider" gutterBottom>
                                        Confidence Threshold
                                    </Typography>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs>
                                        <Slider
                                            value={typeof confidence === 'number' ? confidence : 0}
                                            onChange={handleConfidenceChange}
                                            aria-labelledby="input-slider"
                                            defaultValue={0.8}
                                            step={0.05}
                                            marks
                                            min={0}
                                            max={1}
                                        />
                                        </Grid>
                                        <Grid item>
                                        <Input
                                            value={confidence}
                                            margin="dense"
                                            onChange={handleConfidenceInputChange}
                                            inputProps={{
                                                step: 0.05,
                                                min: 0,
                                                max: 1,
                                                type: 'number',
                                                'aria-labelledby': 'input-slider',
                                            }}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Typography id="input-slider" gutterBottom>
                                        Confidence Club Threshold
                                    </Typography>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs>
                                        <Slider
                                            value={typeof confidenceClub === 'number' ? confidenceClub : 0}
                                            onChange={handleConfidenceClubChange}
                                            aria-labelledby="input-slider"
                                            defaultValue={0.7}
                                            step={0.05}
                                            marks
                                            min={0}
                                            max={1}
                                        />
                                        </Grid>
                                        <Grid item>
                                        <Input
                                            value={confidenceClub}
                                            margin="dense"
                                            onChange={handleConfidenceClubInputChange}
                                            inputProps={{
                                                step: 0.05,
                                                min: 0,
                                                max: 1,
                                                type: 'number',
                                                'aria-labelledby': 'input-slider',
                                            }}
                                        />
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                            {isLoading && (
                                <CircularProgress />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={close} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleStart} color="secondary">
                    Start
                </Button>
            </DialogActions>
        </Dialog>
    </>);
}

export const AutoLabelDialog = AutoLabelDialogComponent;
