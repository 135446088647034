import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid, TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Swal from 'sweetalert2';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import admin, { functionBaseUrl, axiosWithToken } from '../../common/firebase';
import './Invites.css';

const userGroups = [
  { value: 'all', label: 'All users' },
  { value: 'free', label: 'Free' },
  { value: 'coach', label: 'Coach Plans' },
  { value: 'student', label: 'Student Plans' },
  { value: '3dpractice', label: '3D Practice' },
  { value: 'lite', label: 'Pro Lite' },
  { value: 'premium', label: '3D Pro' },
  { value: 'enterprise', label: 'Enterprise' },
  { value: 'staff', label: 'Staff' },
];


function BroadcastMessageComponent({ open, close }) {
  const [message, setMessage] = useState('');
  const [files, setFiles] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [usersGroup, setUsersGroup] = useState('all');


  const handleFiles = async (event) => {
    let filesData = [...files];
    let fileNamesData = [...fileNames];
    for (const file of event.target.files) {
      fileNamesData.push(file.name);
      let storageRef = admin.storage().ref();
      let fileRef = storageRef.child(`broadcast/${Date.now()}_${file.name}`);
      const uploadTaskSnapshot = await fileRef.put(file);
      const filePath = await uploadTaskSnapshot.ref.getDownloadURL();
      filesData.push(filePath);
    }
    setFiles(filesData);
    setFileNames(fileNamesData);
  }

  const deleteFile = (index) => {
    const filesData = [...files];
    const fileNamesData = [...fileNames];
    filesData.splice(index, 1);
    fileNamesData.splice(index, 1);
    setFiles(filesData);
    setFileNames(fileNamesData);
  }

  const handleUsersGroup = (e) => {
    setUsersGroup(e.target.value);
  }

  const handleSend = async () => {
    if (!message) {
      close();
      Swal.fire('<p style="font-size:70%;">Please fill in message field</p>');
      return;
    }
    const resultConfirmation = await Swal.fire({
      title: `<p style="font-size:70%;">Are you sure you want to send broadcast message to <strong>${userGroups.find(group => group.value == usersGroup).label}</strong>?</p>`,
      icon: 'warning',
      confirmButtonText: 'Yes',
      showCancelButton: true,
      cancelButtonText: 'No',
      cancelButtonColor: 'red',
      allowOutsideClick: false
    });
    if (!resultConfirmation.isConfirmed) {
      return;
    }
    try {
      await axiosWithToken(`${functionBaseUrl}/api/broadcastMessage`, {
        method: 'post',
        data: {
          message,
          files,
          usersGroup
        }
      });
      Swal.fire({
        title: '<p style="font-size:70%;">Broadcast message successfully sent</p>',
        icon: 'success',
        confirmButtonText: 'Ok',
        allowOutsideClick: false,
        customClass: {
          container: 'my-swal'
        },
      });
      close();
    } catch (e) {
      Swal.fire({
        title: '<p style="font-size:70%;">Error occured</p>',
        icon: 'warning',
        confirmButtonText: 'Ok',
        allowOutsideClick: true,
        customClass: {
          container: 'my-swal'
        },
      });
    }
  }

  return (<>
    <Dialog
      fullWidth
      open={open}
      onClose={close}
      aria-labelledby='form-dialog-title'>
      <DialogTitle id={'form-dialog-title'}>
        <div>
          New broadcast message
          <CloseIcon style={{ float: 'right', marginTop: '-10px', marginRight: '-15px' }} onClick={close} />
        </div>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <CKEditor
              editor={ClassicEditor}
              data={message}
              onChange={(event, editor) => {
                const data = editor.getData();
                setMessage(data);
              }}
              config={{
                removePlugins: ["Bold", "Italic", "CKFinder", "Table", "BlockQuote", "Indent", "List", "EasyImage", "ImageUpload", "MediaEmbed"]
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <input type="file" id="fileElem" onChange={handleFiles} multiple="multiple" style={{ display: "none" }} />
            <label htmlFor="fileElem">
              <Button variant="contained" color="primary" component="span">
                Upload
              </Button>
              &nbsp;&nbsp;&nbsp;
              <span>{files.length} files</span>
            </label>
          </Grid>
          <Grid
            item
            xs={12}
          >
            {fileNames.map((name, index) => (
              <div>
                {name}
                <CloseIcon style={{ marginTop: '-10px', cursor: 'pointer', width: '15px', verticalAlign: 'bottom' }} onClick={() => deleteFile(index)} />
              </div>
            ))}
          </Grid>
          <Grid
            item
            xs={12}
          >
            <FormControl
              fullWidth
              variant="outlined"
            >
              <InputLabel id="user-group-select">Users group</InputLabel>
              <Select
                label="Users group"
                labelId="user-group-select"
                onChange={handleUsersGroup}
                value={usersGroup}
              >
                {userGroups.map((group) => <MenuItem value={group.value}>{group.label}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSend}
        >
          SEND
        </Button>
      </DialogActions>
    </Dialog>
  </>);
}

export const BroadcastMessage = BroadcastMessageComponent;
