import { axiosWithToken, functionBaseUrl } from "../../../common/firebase";

export const getAllUsersReport = async () => {
  const reportData = await axiosWithToken(`${functionBaseUrl}/api/report`, {
    method: "get",
  });

  return {
    ...reportData.data,
    users: reportData.data.users.filter((el) => {
      if (el.subscriptionType) {
        return el.subscriptionType.includes("student");
      }
    }),
  };
};

export const getUsersReport = async ({ dateRange, dateUnit }) => {
  const res = await axiosWithToken(
    `${functionBaseUrl}/api/elastic/reports/users`,
    {
      method: "post",
      data: { dateRange, dateUnit },
    }
  );

  return res.data;
};

export const getFreeUsersReport = async ({ dateRange, dateUnit }) => {
  const res = await axiosWithToken(
    `${functionBaseUrl}/api/elastic/reports/free-users`,
    {
      method: "post",
      data: { dateRange, dateUnit },
    }
  );

  return res.data;
};

export const getInvitesReport = async ({ dateRange, dateUnit, approved }) => {
  const res = await axiosWithToken(
    `${functionBaseUrl}/api/elastic/reports/invites`,
    {
      method: "post",
      data: { dateRange, dateUnit, approved },
    }
  );

  return res.data;
};
