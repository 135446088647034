import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Button,
  CircularProgress,
  Input,
  TextField,
  withStyles,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useStyles, theme } from "./config";
import {
  getQuestionnairesStatistics,
  getQuestionsCsv,
  getQuestionsList,
} from "./api";
import {
  Container,
  QuestionsTab,
  QuestionsTabContainer,
  QuestionsTableRow,
  QuestionsTableRowContainer,
  QuestionsTableRowItemWrapper,
  SearchButton,
  SearchInput,
  TableSorter,
  TableSorterItem,
  TableSorterItemWrapper,
} from "./styles";
import { format } from "date-fns";
import { FlexWrap, Pad } from "../../components";
import SearchIcon from "@material-ui/icons/Search";
import { CustomSelect } from "./CustomSelect";
import { CSVLink } from "react-csv";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { Pagination } from "@material-ui/lab";

const QuestionnairesStatistics = (props) => {
  const [questionsList, setQuestionsList] = useState([]);
  const [tableData, setTableData] = useState(null);
  const [csvData, setCsvData] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState("");
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState({ field: "createdAt", direction: "desc" });
  const [currentPage, setCurrentPage] = useState(null);
  const [paginationTotal, setPaginationTotal] = useState(0);
  const paginationCount = Math.ceil(paginationTotal / 10);

  const excelRef = useRef();

  useEffect(() => {
    (async () => {
      const qstLst = await getQuestionsList();
      setQuestionsList(qstLst.map((el) => ({ label: el.title, value: el.id })));
      setSelectedQuestion(qstLst[0].id);
    })();
  }, []);

  useEffect(() => {
    if (selectedQuestion) {
      setCurrentPage(1);
    }
  }, [selectedQuestion]);

  useEffect(() => {
    if (currentPage && selectedQuestion) {
      (async () => {
        const tblData = await getQuestionnairesStatistics({
          questionId: selectedQuestion,
          limit: 10,
          offset: 10 * (currentPage - 1),
          sort: sort.field,
          order: sort.direction,
        });
        setTableData(tblData.tableData);
        setPaginationTotal(tblData.total);
      })();
    }
  }, [currentPage, selectedQuestion, sort]);

  useEffect(() => {
    if (csvData.length > 0) {
      excelRef.current.link.click();
    }
  }, [csvData]);

  const mappedTable = () => {
    return tableData ? (
      tableData.map((el) => (
        <QuestionsTableRowContainer>
          <QuestionsTableRowItemWrapper width="150px">
            {el?.user?.firstName
              ? el.user.firstName + " " + el.user.lastName
              : ""}
          </QuestionsTableRowItemWrapper>
          <QuestionsTableRowItemWrapper width="250px">
            {el?.user?.email}
          </QuestionsTableRowItemWrapper>
          <QuestionsTableRowItemWrapper width="100px">
            {format(new Date(el.createdAt), "MM/dd/yyyy")}
          </QuestionsTableRowItemWrapper>
          <QuestionsTableRowItemWrapper width="100px">
            {el?.user?.userCreated
              ? format(new Date(el?.user?.userCreated), "MM/dd/yyyy")
              : ""}
          </QuestionsTableRowItemWrapper>
          <QuestionsTableRowItemWrapper width="200px">
            {el?.user?.subscriptionType}
          </QuestionsTableRowItemWrapper>
          <QuestionsTableRowItemWrapper width="200px">
            {el.answer}
          </QuestionsTableRowItemWrapper>
        </QuestionsTableRowContainer>
      ))
    ) : (
      <></>
    );
  };

  const csvExportHeaders = [
    { label: "First Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Answer Date", key: "createdAt" },
    { label: "Account Created Date", key: "accountCreatedDate" },
    { label: "Subscription", key: "subscription" },
    { label: "Answer", key: "answer" },
  ];

  const csvFetchFunc = useCallback(
    async (e) => {
      const csv = await getQuestionsCsv({ questionId: selectedQuestion });
      setCsvData(csv);
    },
    [selectedQuestion]
  );

  const handleSort = (field) => {
    if (sort.field === field) {
      if (sort.direction === "asc") {
        setSort({ field, direction: "desc" });
      } else {
        setSort({ field, direction: "asc" });
      }
    } else {
      setSort({ field, direction: "desc" });
    }
  };

  const sortArrow = (field) => {
    if (sort.field === field) {
      switch (sort.direction) {
        case "asc":
          return <ArrowDropDownIcon />;
        case "desc":
          return <ArrowDropUpIcon />;
        default:
          break;
      }
    }
    return "";
  };

  const handlePaginationChange = async (event, value) => setCurrentPage(value);

  return (
    <Container>
      {tableData ? (
        <div>
          <div>
            <FlexWrap justify="space-between">
              <FlexWrap width="fit-content">
                <CustomSelect
                  value={questionsList.find(
                    (el) => el.value === selectedQuestion
                  )}
                  isSearchable={false}
                  width="500px"
                  label="Questions"
                  onChange={(e) => setSelectedQuestion(e.value)}
                  options={questionsList}
                />
                <Pad width="10px" />
                <Button
                  style={{ marginTop: "30px" }}
                  variant="contained"
                  color="primary"
                  onClick={csvFetchFunc}
                >
                  Generate Csv Data
                </Button>
                <CSVLink
                  ref={excelRef}
                  filename={`${selectedQuestion}.csv`}
                  data={csvData}
                  headers={csvExportHeaders}
                  asyncOnClick={true}
                  style={{ marginTop: "27px", display: "none" }}
                >
                  Export Table Data
                </CSVLink>
              </FlexWrap>
              <FlexWrap width="fit-content">
                <div>Total: {paginationTotal}</div>
                {/* <SearchInput
                  placeholder="Search"
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                  type="text"
                />
                <Pad width="10px" />
                <SearchButton onClick={searchTable}>
                  <SearchIcon style={{ color: "white" }} />
                </SearchButton> */}
              </FlexWrap>
            </FlexWrap>
          </div>
          <TableSorter>
            <TableSorterItemWrapper width="150px">
              <TableSorterItem>Name</TableSorterItem>
            </TableSorterItemWrapper>
            <TableSorterItemWrapper width="250px">
              <TableSorterItem>Email</TableSorterItem>
            </TableSorterItemWrapper>
            <TableSorterItemWrapper
              onClick={() => handleSort("createdAt")}
              sortable
              width="100px"
            >
              <TableSorterItem>
                Answer Date {sortArrow("createdAt")}
              </TableSorterItem>
            </TableSorterItemWrapper>
            <TableSorterItemWrapper width="100px">
              <TableSorterItem>Account Created Date</TableSorterItem>
            </TableSorterItemWrapper>
            <TableSorterItemWrapper width="200px">
              <TableSorterItem>Subscription</TableSorterItem>
            </TableSorterItemWrapper>
            <TableSorterItemWrapper
              width="200px"
              onClick={() => handleSort("answer")}
              sortable
            >
              <TableSorterItem>Answer {sortArrow("answer")}</TableSorterItem>
            </TableSorterItemWrapper>
          </TableSorter>

          {mappedTable()}
          <Pagination
            count={paginationCount}
            page={currentPage}
            onChange={handlePaginationChange}
          />
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            height: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      )}
    </Container>
  );
};

QuestionnairesStatistics.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
};

export default withStyles(useStyles)(QuestionnairesStatistics);
