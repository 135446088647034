import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button, Grid, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
import { functionBaseUrl } from '../../common/firebase'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  content: {
    paddingTop: 150,
    textAlign: 'center',
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560,
  },
}));

const PaymentResult = () => {
  const classes = useStyles();
  const [session, setSession] = useState(null);

  const query = new URLSearchParams(window.location.search);

  const getSession = async (sessionId) => {
    try {
      const response = await axios(`${functionBaseUrl}/api/stripeSession/${sessionId}`);
      setSession(response.data);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (query.get("session_id")) {
      getSession(query.get("session_id"));
    }
  }, []);

  return (
    <div className={classes.root}>
      <Grid
        container
        justify="center"
        spacing={4}
      >
        <Grid
          item
          lg={6}
          xs={12}
        >
          <div className={classes.content}>
            <Typography variant="h3">
              {query.get("success") && (<>
                Payment processed successfully!
              </>)}
              {query.get("canceled") && (<>
                Payment canceled
              </>)}
            </Typography>
            <Typography variant="subtitle">
              {session && (<>
                <div>Total: {session.amount_total}</div>
              </>)}
              <RouterLink to="/account">
                <Button fullWidth variant="contained" color="primary">Account</Button>
              </RouterLink>
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default PaymentResult;
