import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Snackbar from '@material-ui/core/Snackbar';

import { Topbar } from './components';
//import { onMessageListener } from '../../common/firebase';

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 56,
    height: '100%',
  },
  content: {
    height: '100%',
  },
}));

const Minimal = (props) => {
  const { children } = props;

  const classes = useStyles();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [notification, setNotification] = useState('');

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  /*onMessageListener().then(payload => {
    setNotification(`"${payload.notification.title}" ${payload.notification.body}`)
    setOpenSnackbar(true);
  }).catch(err => console.log('failed: ', err));*/

  return (
    <div className={classes.root}>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={notification}
      />
      <Topbar />
      <main className={classes.content}>{children}</main>
    </div>
  );
};

Minimal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Minimal;
