import { Dialog, IconButton, TextField, Typography } from "@material-ui/core"
import React, {useState} from 'react';
import { Grid, Paper, Select, MenuItem, Button, Fab} from '@material-ui/core';
import {functionBaseUrl, axiosWithToken} from '../../common/firebase';
import DeleteIcon from '@material-ui/icons/Delete';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {useHistory} from 'react-router-dom';

function sendNoti(token, message, receiverId, title, body, notificationType) {
    axiosWithToken(functionBaseUrl+'/api/sendNoti', {
        method: 'post',
        data: {
            token: token, 
            message: message, 
            receiverId: receiverId, 
            title: title, 
            body: body, 
            notificationType: notificationType, 
    }}).then(response => {
        alert('Success');
    })
    .catch(err => {
        console.log(err)
    });
    
}

const ManageSession = (props) => {
    const clubs = ['Driver', '3W', '5W', '7W', '9W', '2H', '3H', '4H', 
    '5H', '6H', '3i', '4i', '5i', '6i', '7i', '8i', '9i', 'PW', 'GW', 'SW', 'LW']
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    let [selectedClub, setSelectedClub] = useState('')
    let [selectedDevice, setSelectedDevice] = useState('')
    let [sessionNumber, setSessionNumber] = useState(1)
    let [sessionClicks, setSessionClicks] = useState(0)
    let [seconds, setSeconds] = useState(0)
    let [minutes, setMinutes] = useState(0)
    let [timerId, setTimerId] = useState('')
    let [sessions, setSessions] = useState([])
    let [favoriteSessions, setFavoriteSessions] = useState([])
    let [endDialogueOpen, setEndDialogueOpen] = useState(endDialogueOpen ? true : false)
    let [saveSessionDialogueOpen, setSaveSessionDialogueOpen] = useState(saveSessionDialogueOpen ? true : false)
    let [currentTimeStart, setCurrentTimeStart] = useState('')
    let [currentTimeEnd, setCurrentTimeEnd] = useState('')
    let [currentDate, setCurrentDate] = useState('')
    let [sessionName, setSessionName] = useState('')
    let [purposeName, setPurposeName] = useState('')
    const history = useHistory()
    
    const updateTime = () => { //updates time for session recordings
        console.log('time', seconds)
        setSeconds(seconds += 1)
        if (seconds >= 59) {
            setSeconds(seconds = 0)
            setMinutes(minutes += 1)
        }
        var recordButton = document.getElementById('record')
        if (seconds < 10) {
            recordButton.innerText = ('Swing ' + sessionNumber + '\n' + minutes + ':0' + seconds)
        } else {
            recordButton.innerText = ('Swing ' + sessionNumber + '\n' + minutes + ':' + seconds)
        }
        
    }

    const deviceChange = (event) => { //manages device changes in connected device menu 
            for (let i = 0; i < props.connectedDev.length; i++) {
                if (event.target.value['deviceName'] == props.connectedDev[i]['deviceName']) {
                    setSelectedDevice(selectedDevice = event.target.value)
                    setSessionNumber(sessionNumber = 1)
                    setSessionClicks(sessionClicks = 0)
                    setSessions(sessions = [])
                }
            }
    }

    const clubChange = (event) => { //manages club changes from user selected menu 
        setSelectedClub(event.target.value)
    }

    const recordSession = (event) => { //manages recording sessions from controller
        if (sessionNumber == 1) { //sets start time when first session begins recording 
            const current = new Date();
            const currEndStart = current.toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
              });
            setCurrentTimeStart(currentTimeStart = currEndStart)
        }

        setSessionClicks(sessionClicks += 1)
        console.log(sessionClicks)
        var recordButton = document.getElementById('record')
       
        if ((sessionClicks != 0) && ((sessionClicks % 2) == 0)) { //when session begins recording 
            var sessionTime = document.getElementById('sessionTime ' + sessionNumber)
            if (seconds < 10) {
                sessionTime.outerText= (minutes + ':0' + seconds)
            } else {
                sessionTime.outerText= (minutes + ':' + seconds)
            }
            
            sessionTime.style.fontWeight = '700'
            document.getElementById('sessionDeleteButton ' + sessionNumber).style.visibility = 'visible'
            document.getElementById('sessionFavoriteButton ' + sessionNumber).style.visibility = 'visible'
            sendNoti(selectedDevice['fcmToken'], 'finish session', 'end', 'Swing ' + sessionNumber, 'Swing ' + sessionNumber + ' recorded', '1')
            setSessionNumber(sessionNumber += 1)
            console.log('session num updated' + sessionNumber)
            recordButton.innerText = 'Tap to RECORD'
            setMinutes(minutes = 0)
            setSeconds(seconds = 0)
            clearInterval(timerId)
            
        } else { //When session finishes recording 
            sendNoti(selectedDevice['fcmToken'], 'start session', 'start', 'Swing ' + sessionNumber, 'Swing: ' + sessionNumber + ' Club: ' + selectedClub, '1')
            setTimerId(setInterval(updateTime, 1000))
            const d = new Date();
            const timeM = d.getMilliseconds()
            let session = //Creates session papers for each recorded session
            {
                format:              
                <Paper id = {sessionNumber} elevation={5} style = {{display: 'visible', width: '370px', height: '30px', marginTop: '10px', marginLeft: '20px'}}>
                    <Grid container direction = 'row' style = {{width: '350px', height: '40px'}}>
                        <Grid id = {'sessionSwing ' + sessionNumber} item style = {{display: 'inline-block', width: '30px', height: '40px', marginLeft: '10px', fontWeight: '700'}}>{sessionNumber}</Grid>
                        <Grid id = {'sessionClub ' + sessionNumber} item style = {{display: 'inline-block', width: '183px', height: '40px', marginLeft: '18px', fontWeight: '700'}}>{selectedClub}</Grid>
                        <Grid id = {'sessionTime ' + sessionNumber} item style = {{display: 'inline-block', width: '50px', height: '40px', fontWeight: '700'}}>Recording...</Grid>
                        <Grid id = {'sessionFavorite ' + sessionNumber} item style = {{display: 'inline-block', width: '30px', height: '40px', marginLeft: '25px'}}><IconButton variant = 'outlined' id = {'sessionFavoriteButton ' + sessionNumber} size = "small" onClick={favoriteSession(timeM)} style = {{visibility: 'hidden', borderColor: '#808080'}} aria-label = "favorite" > <FavoriteBorderIcon/> </IconButton></Grid>
                        <Grid id = {'sessionDelete ' + sessionNumber} item style = {{display: 'inline-block', width: '20px', height: '40px'}}><IconButton id = {'sessionDeleteButton ' + sessionNumber} size = "small" onClick={deleteSession(timeM)} style = {{visibility: 'hidden'}} aria-label = "delete" color = "#C9C9C9"> <DeleteIcon/> </IconButton></Grid>
                    </Grid>
                </Paper>,

                timeId: timeM,
            }
            
            setSessions(sessions => [...sessions, session]) //updates sessions state    
        }
    }

    const disableFunctionEndSession = () => { //handles disabled end session button until at least 1
        if (sessionNumber > 1) {
            return false;
        } else {
            return true;
        }
    }

    const disableFunctionRecord = () => { //handles disabled record button until device and club are selected 
        console.log(selectedDevice)
        console.log(selectedClub)
        if ((selectedDevice != '') && (selectedClub != '')) {
            var recordButton = document.getElementById('record')
            recordButton.style.backgroundColor = '#A12AEE'
            return false
        } else {
            return true
        }
    }

    const endSaveSession = () => { //handles saving session, onclick function for end save session button
        setEndDialogueOpen(true) //opens dialogue box for save session
        const current = new Date(); //sets current date and times 
        setCurrentDate(currentDate = (months[current.getMonth()] + ' ' + current.getDate() + ', ' + current.getFullYear()))
        const currEndTime = current.toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
          });
        setCurrentTimeEnd(currentTimeEnd = currEndTime)
    }

    const deleteSession = (id) => { //delete session functionality needs to be implemented still
        if (sessionNumber == 1) {
            var set = async() => {
                setSessionNumber(sessionNumber = 1)
                setSessionClicks(sessionClicks = 0)
                setSessions(sessions = [])
            }
            return set
        }

        console.log('ID ', id)
        console.log('sessionNumber ', sessionNumber)
        // for (let i = 0; i < (sessions.length); i++) {
        //     if (sessions[i].timeId != id) {
        //         console.log('Looping')
        //         temp.push(sessions[i])
        //         console.log('temp length updated ', temp.length)
        //     }
        // }
        var occur = async() => {
            setSessions(sessions.filter((session) => session.timeId !== id))
            setSessionNumber(sessionNumber = (sessions.length + 1))
            setSessionClicks(sessionClicks = (sessions.length * 2))
        }

        return occur    
    }

    const favoriteSession = (id) => { //handles favorite session functionality, animation for favoriting sessions needs to implemented still 
        for (let i = 0; i < sessions.length; i++) {
            if (sessions[i].timeId == id) {
                setFavoriteSessions(favoriteSessions => [...favoriteSessions, sessions[i]])
            }
        }
    }
    
    const saveSession = () => { //handles saving session
        var send = async() => { 
            setSaveSessionDialogueOpen(true) //opens confirmation dialogue box and sends noti event to mobile application
            sendNoti(selectedDevice['fcmToken'], "session saved", "save", "session saved", currentDate + "\n" + "Session: " + sessionName + "\n" + "Purpose Name: " + purposeName, '1')
        }

        return send
    }

    const recordAgain = () => { //handles record again option, resets states for session control 
        var record = async() => {
            setSaveSessionDialogueOpen(false)
            setEndDialogueOpen(false)
            setSessionClicks(0)
            setSessionNumber(1)
            setSessions([])
        }
        return record
    }

    const goToSessions = () => { //handles go to sessions option, needs to be implemented when clicked user is take to sessions page on website where they will see their saved session 
        var move = async() => {
            history.push('/session-details'); //moves user to session details page
            //need to add functionality/implementation for adding saved session from here to sessions list on session details page 
        }
        return move
    }

    console.log('session after delete length ', sessions.length)
    return ( //visual renders
        <div style = {{backgroundColor: '#F4F6F8', display: 'flex', justifyContent: 'center'}}>
            <Paper style = {{width: '800px', height: '550px', backgroundColor: '#FFFFFF', marginTop: '100px', marginBottom: '200px'}}>
                <Grid container direction = 'row'>
                    <Grid item direction = 'column' style = {{display: 'inline-block', width: '390px', height: '550px'}}>
                        <Typography style = {{fontWeight: '700', fontSize: '30px', marginLeft: '20px', marginTop: '30px'}}>Remote control</Typography>
                        <Typography style = {{fontSize: '14px', marginTop: '10px', marginLeft: '20px'}}>Pair your device with the website to start a session remotely.</Typography>
                        
                        <Grid item style = {{marginLeft: '20px', marginTop: '20px'}}>
                            <Grid item style = {{display: 'inline-block', width: '250px'}}>
                                <Typography>CONNECTED DEVICE</Typography>
                                <Select onChange={event => {deviceChange(event)}} variant = 'outlined' style = {{width: '230px', height: '40px', borderColor: 'black', marginTop: '10px'}}>
                                    {props.connectedDev.map(b=>(
                                        <MenuItem value = {b}>{b['deviceName']}</MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                                
                            <Grid item style = {{display: 'inline-block', width: '100px', marginLeft: '10px'}}>
                                <Typography>CLUB</Typography>
                                <Select onChange = {event => {clubChange(event)}} variant = 'outlined' style = {{width: '90px', height: '40px', borderColor: 'black', marginTop: '10px'}}>
                                    {clubs.map(g => (
                                        <MenuItem value = {g}>{g}</MenuItem>
                                    ))}
                                </Select>
                            </Grid>

                            <Grid item>
                                    <Fab id = 'record' onClick = {event => {recordSession(event)}} disabled = {disableFunctionRecord()} style = {{backgroundColor: '#C9C9C9', fontSize: '20px', width: '150px', height: '150px', marginLeft: '100px', marginTop: '100px', color: 'white'}}>Tap to RECORD</Fab>
                            </Grid>

                        </Grid>
                        
                    </Grid>

                     <hr style = {{marginTop: '20px', height: '500px', backgroundColor: '#C9C9C9', opacity: '40%'}}></hr>

                    <Grid item direction = 'column' style = {{display: 'inline-block', width: '390px', height: '550px'}}>
                        <Typography style = {{fontSize: '15px', fontWeight: '900', marginLeft: '20px', marginTop: '35px'}}>Recorded sessions</Typography>

                        <Grid item direction = 'row' style = {{marginTop: '10px'}}>
                            <Grid item style = {{display: 'inline-block', marginLeft: '20px', color: '#808080'}}><Typography>Swing</Typography></Grid>
                            <Grid item style = {{display: 'inline-block', marginLeft: '20px', color: '#808080'}}><Typography>Club</Typography></Grid>
                            <Grid item style = {{display: 'inline-block', marginLeft: '150px', color: '#808080'}}><Typography>Time</Typography></Grid>
                        </Grid>

                        <Grid item direction = 'column'>
                            {sessions.map(s => (
                                s.format
                            ))}
                        </Grid>

                        <Grid item style = {{marginLeft: '20px', marginTop: '20px'}}>
                            <Button position = 'fixed' id = 'endSession' onClick = {() => {endSaveSession()}} variant = 'outlined' style = {{color: '#A12AEE', width: '350px', height: '30px', borderColor: '#A12AEE'}} disabled = {disableFunctionEndSession()}>END AND SAVE SESSION</Button>
                            <Dialog open = {endDialogueOpen}>
                                <Paper style = {{width: "450px", height: "450px"}}>
                                    <Typography style = {{fontWeight: '1000', fontSize: '30px', marginLeft: '20px', marginTop: '30px'}}>SAVE SESSION</Typography>
                                    <Typography style = {{marginLeft: '20px', marginTop: '10px'}}>{currentDate}  •  {currentTimeStart} - {currentTimeEnd}  •  {sessionNumber - 1} swings</Typography>
                                    
                                    <Typography style = {{marginLeft: '20px', marginTop: '20px'}}>SESSION NAME</Typography>
                                    <TextField size = "small" onChange = {(newValue) => setSessionName(newValue.target.value)} variant="outlined" style = {{width: '400px', height: '', marginTop: '10px', marginLeft: '20px'}}></TextField>

                                    <Typography style = {{marginLeft: '20px', marginTop: '10px'}}>PURPOSE</Typography>
                                    <TextField size = "small" onChange = {(newValue) => setPurposeName(newValue.target.value)} variant="outlined" style = {{width: '400px', height: '', marginTop: '10px', marginLeft: '20px'}}></TextField>

                                    <Typography style = {{marginLeft: '20px', marginTop: '10px'}}>DEVICE</Typography>
                                    <TextField size = "small" defaultValue = {selectedDevice['deviceName']} variant="outlined" style = {{width: '400px', height: '', marginTop: '10px', marginLeft: '20px'}} disabled = {true}></TextField>

                                    <Button variant = 'contained' onClick = {() => setEndDialogueOpen(false)} style = {{display: 'inline-block', backgroundColor: '#808080', color: 'white', width: '195px', height: '60px', marginTop: '30px', marginLeft: '20px'}}>CANCEL</Button>
                                    <Button variant = 'contained' onClick = {saveSession()} style = {{display: 'inline-block', backgroundColor: '#580CE3', color: 'white', width: '195px',height: '60px', marginLeft: '10px', marginTop: '30px'}}>SAVE</Button>
                                    
                                    <Dialog open = {saveSessionDialogueOpen}>
                                        <Paper style = {{width: "450px", height: "450px"}}>
                                            <CheckCircleIcon style = {{width: '200px', height: '200px', color: '#2AE30C', marginLeft: '125px', marginTop: '30px'}}></CheckCircleIcon>
                                            <Typography style = {{fontSize: '25px', fontWeight: '900', marginLeft: '118px', marginTop: '20px'}}>SESSION SAVED</Typography>
                                            <Typography style = {{fontSize: '12px', fontWeight: '100', marginLeft: '95px', marginTop: '10px'}}>Saved sessions can be found on the Sessions page</Typography>
                                            <Button variant = 'outlined' onClick = {recordAgain()} style = {{display: 'inline-block', borderColor: '#580CE3', color: '#580CE3', width: '195px', height: '60px', marginTop: '20px', marginLeft: '30px'}}>RECORD AGAIN</Button>
                                            <Button variant = 'contained' onClick = {goToSessions()} style = {{display: 'inline-block', backgroundColor: '#580CE3', color: 'white', width: '195px',height: '60px', marginLeft: '10px', marginTop: '30px'}}>GO TO SESSION</Button>
                                        </Paper>
                                    </Dialog>
                                </Paper>
                            </Dialog>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
}

export default ManageSession;