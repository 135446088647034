import admin from "../../../common/firebase";
import { fbMapper } from "../../../helpers/fbMapper";
import { dateMapper, firestorageExistCheck } from "./helper";

const db = admin.firestore().collection("practiceGuides");

export const getPracticeGuides = async () => {
  const guides = fbMapper(await db.get()).map(dateMapper);

  return guides;
};

export const createPracticeGuide = async (data) => {
  const {
    name,
    description,
    platform,
    accessLevel,
    isPremium,
    tags,
    file,
    summary,
  } = data;

  const userId = admin.auth().currentUser.uid;

  const userData = (
    await admin.firestore().collection("users").doc(userId).get()
  ).data();

  var storageRef = admin.storage().ref();

  const extension = file.name.split(".").pop();

  var fileRef = storageRef.child(`practice_guides/${Date.now()}.${extension}`);

  const uploadTaskSnapshot = await fileRef.put(file);

  const filePath = await uploadTaskSnapshot.ref.getDownloadURL();

  const parsed = {
    name,
    descriptions: description,
    platform,
    tags,
    creator: {
      name: `${userData.firstName} ${userData.lastName}`,
      userId,
    },
    bannerImageUrl: filePath,
    accessLevel,
    isPremium,
    likes: 0,
    summary,
    createdAt: Date.now(),
  };
  await db.add(parsed);

  const guides = fbMapper(await db.get()).map(dateMapper);

  return guides;
};

export const updatePracticeGuide = async (id, data) => {
  const {
    name,
    description,
    platform,
    accessLevel,
    isPremium,
    tags,
    newFile,
    summary,
  } = data;

  const prev = (await db.doc(id).get()).data();

  const parsed = {
    ...prev,
    accessLevel,
    isPremium,
    name,
    descriptions: description,
    platform,
    tags,
    summary,
  };

  if (newFile) {
    if (firestorageExistCheck(prev.bannerImageUrl)) {
      admin.storage().refFromURL(prev.bannerImageUrl).delete();
    }

    var storageRef = admin.storage().ref();

    const thumbExtension = newFile.name.split(".").pop();
    var thumbFileRef = storageRef.child(
      `practice_guides/${Date.now()}.${thumbExtension}`
    );
    const thumbUploadTaskSnapshot = await thumbFileRef.put(newFile);
    const thumbDownloadURL = await thumbUploadTaskSnapshot.ref.getDownloadURL();

    parsed.bannerImageUrl = thumbDownloadURL;
  }

  await db.doc(id).set(parsed);

  const guides = fbMapper(
    await admin.firestore().collection("practiceGuides").get()
  ).map(dateMapper);

  return guides;
};

export const deletePracticeGuide = async (id) => {
  const prev = (await db.doc(id).get()).data();

  if (firestorageExistCheck(prev.bannerImageUrl)) {
    admin.storage().refFromURL(prev.bannerImageUrl).delete();
  }

  await db.doc(id).delete();

  const guides = fbMapper(await db.get()).map(dateMapper);

  return guides;
};
